import React, { useState } from "react";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Row, Col, Container, Card } from "react-bootstrap";
import PureBreadcrumbs from "../../breadcrums";
import * as Yup from "yup";
import { connect } from "react-redux";
import {
  getAllFleetName,
  fetchFleetVehiclebyOperator,
  createFleetOwnerAction,
  deleteSingleVehicle,
  UpdateFleetStatus,
} from "../../../../store/actions/fleetAction";
import { FaEdit, FaEye } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import "../../reacttable/reactTable.css";
import Table from "../../reacttable/table";
import Popup from "reactjs-popup";
import moment from "moment";
import { ImCross } from "react-icons/im";
import { allOrgTariffAction } from "../../../../store/actions/organisationAction";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Bounce, ToastContainer, toast } from "react-toastify";
import { TiTick } from "react-icons/ti";
import { AiOutlineCloseCircle } from "react-icons/ai";

function FleetOnBoardingCard(props) {
  const [isToastVisible, setIsToastVisible] = useState(false);
  const [fleetDropDown, SetFleetDropDown] = useState([]);
  const [fleet, setFleet] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    props.all_fleet_name();
    props.allOrg();
  }, []);
  const data = props.organisationlist && props.organisationlist.organisations;
  const totalFleet = props.fleet_list && props.fleet_list.data;
  // console.log("data",data)
  const isFleetOrg = data && data.filter((item) => item.isfleet === "Yes");
  // console.log("isFleetOrg",isFleetOrg)

  const ownerSchema = Yup.object().shape({
    ownername: Yup.string().required("Fleet Owner name is required"),
    organisations: Yup.string().required("Organisation is required"),
    status: Yup.string(),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(ownerSchema) });

  useEffect(() => {
    SetFleetDropDown(props.fleet_list?.data?.filter((item) => item.status === "Active"));
  }, [props.fleet_list]);

  useEffect(() => {
    props.vehicle_byoperator(fleetDropDown?.[0]?.ownername);
    setFleet(fleetDropDown?.[0]?.ownername);
  }, [fleetDropDown]);

  const onSubmit = async (data) => {
    props.save_owner(data);
    reset();
  };

  const handleRedirect = () => {
    return <Redirect to="/dashboard/save_vehicle" />;
  };

  // if (props.fleet_owner.statuscode === 200) {
  //   alert("Fleet member has been successfully registered");
  //   window.location.reload();
  // }

  useEffect(() => {
    if (props.fleet_owner.statuscode === 200) {
      setIsToastVisible(true);
      toast.success("Fleet member has been successfully registered", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        onClose: () => window.location.reload(),
      });
    }
  }, [props.fleet_owner]);

  useEffect(() => {
    if (props.deleteVehicleRes.statuscode === 200) {
      setIsToastVisible(true);
      toast.success("Vehicle has been successfully deleted", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        onClose: () => window.location.reload(),
      });
    }
  }, [props.deleteVehicleRes]);

  // console.log(props.fleet.save_owner);
  const memberList = props.fleet_list && props.fleet_list.data;
  // console.log("memberList", memberList);
  const columns = useMemo(
    () => [
      {
        Header: "Fleet Name",
        accessor: "ownername",
      },
      {
        Header: "Role",
        accessor: "role",
      },
      {
        Header: "Operator",
        accessor: "organisation",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell: { value } }) => {
          return (
            <span
              style={{
                color: value === "Active" ? "green" : "red",
              }}
            >
              {value}
            </span>
          );
        },
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: (item) => {
          const sections = item.cell;
          return (
            <div>
              <span style={{ cursor: "pointer" }}>
                <TiTick onClick={() => activateStation(sections.value)} title="Activate" />
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <span style={{ cursor: "pointer" }}>
                <AiOutlineCloseCircle onClick={() => deactivateStation(sections.value)} title="De-Activate" />
              </span>
            </div>
          );
        },
      },
    ],
    []
  );
  const vehicleData = props.vehicle_list && props.vehicle_list.data;
  // console.log(vehicleData, "vehicleData");
  const Vehicle_columns = useMemo(
    () => [
      {
        Header: "Vin",
        accessor: "vin",
      },
      {
        Header: "Model",
        accessor: "vehicle_model",
        Cell: ({ row }) => (
          <div>
            <img width={20} src={row.original.vehicle_make_image} alt="icon" /> {row.original.vehicle_model}
          </div>
        ),
      },
      {
        Header: "Owner Name",
        accessor: "v_owner",
      },
      {
        Header: "Registration Date",
        accessor: "registration_date",
        Cell: ({ value }) => {
          return <span>{moment(value).format("DD-MM-YYYY")}</span>;
        },
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: (id) => {
          return (
            <div>
              <span>
                <a href={`/dashboard/view_single/${id.value}`} rel="tooltip" title="View">
                  <FaEye />
                </a>
              </span>
              &nbsp;&nbsp;
              <span>
                <a href={`/dashboard/update_vehicle/${id.value}`} rel="tooltip" title="Edit">
                  <FaEdit />
                </a>
              </span>
              {/* &nbsp;&nbsp;  &nbsp;&nbsp;
                      <span style={{ cursor: "pointer" }}>
                          <TiTick
                          onClick={() => activateStation(id.value)}
                          title="Activate"
                          />
                      </span>
                      &nbsp;&nbsp;
                      <span style={{ cursor: "pointer" }}>
                          <AiOutlineCloseCircle
                              // onClick={() => deactivateStation(id.value)}
                              title="De-Activate"
                          />
                      </span> */}
              &nbsp;&nbsp;
              <span style={{ cursor: "pointer", color: "red", fontSize: "18px" }}>
                <MdDelete onClick={() => deleteVehicle(id.value)} title="Delete" />
              </span>
            </div>
          );
        },
      },
    ],
    []
  );
  const deleteVehicle = (id) => {
    props.deleteVehicleApi(id);
  };

  const changeFleet = (e) => {
    const value = e.target.value;
    props.vehicle_byoperator(value);
    setFleet(value);
  };
  const deactivateStation = (id) => {
    props.updateFleetStatusApi(id, "Inactive");
  };
  const activateStation = (id) => {
    props.updateFleetStatusApi(id, "Active");
  };

  useEffect(() => {
    if (props.updateFleetStatusRes.statuscode === 200) {
      setIsToastVisible(true);
      toast.success(`Fleet Status has been Successfully ${props.updateFleetStatusRes.result}`, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        onClose: () => window.location.reload(),
      });
    }
  }, [props.updateFleetStatusRes]);

  return (
    <Container fluid>
      <PureBreadcrumbs />
      {isToastVisible && <div className="overlay" />}
      <ToastContainer
        position="top-center"
        autoClose={20}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{ top: "10%", width: "auto", zIndex: "9999" }}
      />
      <br />

      <Row className="mt-3" style={{ display: "flex" }}>
        <Col xs={12} md={3}>
          <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>TOTAL NUMBER OF FLEET</Card.Text>
              <Card.Title>
                <b>{totalFleet ? totalFleet.length : 0}</b>
              </Card.Title>
              <span
                style={{
                  float: "right",
                  marginTop: "-60px",
                  marginRight: "-8px",
                }}
              >
                <div style={{ width: "20%" }}>
                  <img src={process.env.PUBLIC_URL + "/images/fleet/car1blue.svg"} className="fleet-ico1" alt="" />
                </div>
              </span>
            </div>
          </Card>
          <br />
        </Col>
        <Col xs={12} md={3}>
          <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>TOTAL NUMBER OF ACTIVE FLEET</Card.Text>
              <Card.Title>
                <b>{fleetDropDown ? fleetDropDown?.length : 0}</b>
              </Card.Title>
              <span
                style={{
                  float: "right",
                  marginTop: "-60px",
                  marginRight: "-8px",
                }}
              >
                <div style={{ width: "20%" }}>
                  <img src={process.env.PUBLIC_URL + "/images/fleet/car1blue.svg"} className="fleet-ico1" alt="" />
                </div>
              </span>
            </div>
          </Card>
          <br />
        </Col>
        <Col xs={12} md={3}>
          <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>TOTAL NUMBER OF INACTIVE FLEET</Card.Text>
              <Card.Title>
                <b>{props.fleet_list?.data ? props.fleet_list?.data?.length - fleetDropDown?.length : 0}</b>
              </Card.Title>
              <span
                style={{
                  float: "right",
                  marginTop: "-60px",
                  marginRight: "-8px",
                }}
              >
                <div style={{ width: "20%" }}>
                  <img src={process.env.PUBLIC_URL + "/images/fleet/car1blue.svg"} className="fleet-ico1" alt="" />
                </div>
              </span>
            </div>
          </Card>
          <br />
        </Col>
        <Col xs={12} md={3}>
          <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>TOTAL NUMBER OF VEHICLES IN {fleet?.toUpperCase()}</Card.Text>
              <Card.Title>
                <b>{vehicleData ? vehicleData.length : 0}</b>
              </Card.Title>
              <span
                style={{
                  float: "right",
                  marginTop: "-60px",
                  marginRight: "-8px",
                }}
              >
                <div style={{ width: "50%" }}>
                  <img src={process.env.PUBLIC_URL + "/images/fleet/car2green.svg"} className="fleet-ico1" alt="" />
                </div>
              </span>
            </div>
          </Card>
          <br />
        </Col>
      </Row>
      <br />
      <Row className="p-3">
        <Col xs={12}>
          <Row>
            <Card className="tariffcard table">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="table-header">Fleet Member List</div>
                <div>
                  {/* <Col xs={12} md={1} className="third-column me-2"> */}
                  <Row className="" style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Popup
                      trigger={
                        <button
                          className="lgn-btn btn"
                          type="button"
                          variant="primary"
                          style={{
                            marginRight: "43px",
                            whiteSpace: "nowrap",
                            marginBottom: "15px",
                            paddingRight: "25px",
                          }}
                        >
                          + Onboard fleet member
                        </button>
                      }
                      modal
                      contentStyle={{
                        borderRadius: "10px",
                        padding: "20px",
                        width: "50%",
                        margin: "auto",
                      }}
                      className="popup-content"
                    >
                      {(close) => (
                        <>
                          <ToastContainer
                            position="top-center"
                            autoClose={500}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="light"
                            style={{ top: "10%", width: "auto" }}
                          />
                          <div style={{ textAlign: "right" }}>
                            <button className="close-btn" onClick={close}>
                              <span>
                                <ImCross></ImCross>
                              </span>
                            </button>
                          </div>
                          <div>
                            <h2 style={{ textAlign: "center" }}>Onboard Fleet Member</h2>
                            <form className="p-3" onSubmit={(e) => e.preventDefault()}>
                              <div className="form-floating">
                                <input
                                  type="text"
                                  className={`form-control gray-border ${errors.ownername ? "is-invalid" : ""}`}
                                  name="ownername"
                                  id="floatingInputGridname"
                                  placeholder="Fleet Owner Name"
                                  {...register("ownername")}
                                />
                                <div className="invalid-feedback">{errors.ownername?.message}</div>
                              </div>
                              <br />
                              <div className="col-lg-6 col-sm-12">
                                <div className="form-floating">
                                  <select
                                    className={`form-control form-select selectOrg ${
                                      errors.organisations ? "is-invalid" : ""
                                    }`}
                                    name="unit"
                                    id="floatingSelectGridorganisation"
                                    {...register("organisations")}
                                  >
                                    <option value="">Select Organisation</option>
                                    {isFleetOrg &&
                                      isFleetOrg.map((v, k) => (
                                        <option key={v.name} value={v.name}>
                                          {v.name}
                                        </option>
                                      ))}
                                  </select>
                                  <div className="invalid-feedback">{errors.organisations?.message}</div>
                                </div>
                              </div>
                              <br />
                              <div className="popup-btn">
                                {props.loading ? (
                                  <button className="btn lgn-btn second-button" type="button" disabled>
                                    <span
                                      className="spinner-grow spinner-grow-sm"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                    &nbsp;
                                    {t("Saving...")}
                                  </button>
                                ) : (
                                  <button
                                    className="lgn-btn second-button"
                                    type="submit"
                                    onClick={handleSubmit(onSubmit)}
                                  >
                                    Save
                                  </button>
                                )}
                              </div>
                              <br />
                            </form>
                          </div>
                        </>
                      )}
                    </Popup>
                  </Row>
                  {/* </Col> */}
                </div>
              </div>

              {props.loading ? (
                <h3
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "250px",
                  }}
                >
                  Loading data...
                </h3>
              ) : memberList && memberList.length > 0 ? (
                <div>
                  <Table data={memberList} columns={columns} style={{ width: "100%" }}></Table>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3 className="loader">No data found</h3>
                </div>
              )}
            </Card>
          </Row>
        </Col>
      </Row>
      <Row className="p-3">
        <Col xs={12}>
          <Row>
            <Card className="tariffcard table">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                  <div className="table-header">View Vehicles For</div>
                  <select
                    onChange={(e) => changeFleet(e)}
                    style={{ width: "170px", border: "1px solid #9f9f9f", borderRadius: "5px" }}
                  >
                    {fleetDropDown?.map((item, index) => (
                      <option key={index} value={item.ownername}>
                        {item.ownername}
                      </option>
                    ))}
                  </select>
                </div>
                {/* <Row className="pb-2" style={{ display: "flex", justifyContent: "flex-end" }}> */}
                <div style={{ marginRight: "25px", display: "flex", marginBottom: "15px" }}>
                  <a
                    href={`/dashboard/save_vehicle`}
                    className="btn lgn-btn"
                    type="button"
                    onClick={handleRedirect}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textDecoration: "none",
                      marginRight: "10px",
                    }}
                  >
                    <p className="" style={{ fontSize: "17px", margin: 0 }}>
                      {" "}
                      + Add Vehicle
                    </p>
                  </a>
                  {/* </Row> */}
                </div>
              </div>
              {props.loading ? (
                <h3
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "250px",
                  }}
                >
                  Loading data...
                </h3>
              ) : vehicleData && vehicleData.length > 0 ? (
                <div>
                  <Table data={vehicleData} columns={Vehicle_columns} style={{ width: "100%" }}></Table>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3 className="loader">No data found</h3>
                </div>
              )}
            </Card>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.campaign.isLoading,
    fleet_list: state.fleet.all_fleet_name,
    vehicle_list: state.fleet.vehicle_byoperator,
    fleet_owner: state.fleet.save_owner,
    organisationlist: state.operator.organisationsList,
    deleteVehicleRes: state.fleet.delete_single_vehicle,
    updateFleetStatusRes: state.fleet.update_fleet_status,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    all_fleet_name: () => dispatch(getAllFleetName()),
    vehicle_byoperator: (fleet) => dispatch(fetchFleetVehiclebyOperator(fleet)),
    save_owner: (data) => dispatch(createFleetOwnerAction(data)),
    allOrg: () => dispatch(allOrgTariffAction()),
    deleteVehicleApi: (id) => dispatch(deleteSingleVehicle(id)),
    updateFleetStatusApi: (id, status) => dispatch(UpdateFleetStatus(id, status)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FleetOnBoardingCard);
