import { Container, Card, Row, Col } from "react-bootstrap";
import PureBreadcrumbs from "../../breadcrums";
import "../customer.css";
import AccountList from "./accountList";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  allCutomersAction,
  allCutomersCardCountAction,
  customerFeedback,
  getPercentageChangeData,
} from "../../../../store/actions/customerActions";
import CustomerFeedbackTable from "./customerFeedbackTable";
import { useTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom/cjs/react-router-dom.min";

function AccountCard(props) {
  const { t } = useTranslation();
  useEffect(() => {
    props.getCustomerCount(1, 1000000);
    props.getPercentageChangeData();
    props.getCustomerFeedbackTable();
    // props.fetchAdminStatus();
  }, []);

  const customerFeed = props.customerFeedback && props.customerFeedback;
  // console.log(customerFeed)
  const allCustomerData = props.list && props.list.customers;

  const total = props.list && props.list.customers && props.list.customers.length;
  const activec = props.list && props.list.customers && props.list.customers.filter((lc) => lc.status === true);
  const inactive = props.list && props.list.customers && props.list.customers.filter((lc) => lc.status === false);

  const Total_user_percentage = props.percentchange && props.percentchange.percentage_change;
  const Total_user_change_symbol = props.percentchange && props.percentchange.change_symbol;
  // console.log(Total_user_percentage)
  // console.log(Total_user_change_symbol)

  const Total_active_percentage = props.percentchange && props.percentchange.active_percentage_change;
  const Total_active_symbol = props.percentchange && props.percentchange.active_change_symbol;
  // console.log(Total_active_symbol)
  // console.log(Total_active_percentage)

  const Total_inactive_percentage = props.percentchange && props.percentchange.inactive_percentage_change;
  const Total_inactive_symbol = props.percentchange && props.percentchange.inactive_change_symbol;
  // console.log(Total_inactive_symbol)
  // console.log(Total_inactive_percentage)
  // console.log("props.customerFeedback.data", props.customerFeedback)

  return (
    <Container fluid className="pb-4">
      <PureBreadcrumbs />
      <br />
      <div className="row">
        <div className="col-lg-4 col-sm-12">
          {/* <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>
                {t("TOTAL CUSTOMERS")}
              </Card.Text>
              <Card.Title>
                <b>{total ? total : 0}</b>
              </Card.Title>
              <span style={{ fontSize: "12px" }}>
                {Total_user_change_symbol === "+" ? <img src={process.env.PUBLIC_URL + "/images/admin/profit.svg"} className="profitloss" alt="" /> : <img src={process.env.PUBLIC_URL + "/images/admin/loss.svg"} className="profitloss" alt="" />}
                &nbsp;&nbsp;{Total_user_percentage}% &nbsp;{t(" change in last 30 days")}
              </span>
              <span
                style={{
                  float: "right",
                  marginTop: "-60px",
                  marginRight: "-8px",
                }}
              >
                <div style={{ width: "20%" }}>
                  <img
                    // className="CustomerImage "
                    src={
                      process.env.PUBLIC_URL +
                      "/images/customer/totalcustomer.svg"
                    }
                    alt="customer"
                  />
                </div>
              </span>
            </div>
          </Card> */}
          <Card className="customercard card-custom total-rules cards">
            <div className="diagonal-line"></div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p
                style={{
                  backgroundColor: "blue",
                  padding: "3px",
                  borderRadius: "50%",
                  width: "8px",
                  height: "8px",
                  marginRight: "8px",
                }}
              ></p>
              <p style={{ fontWeight: "700" }}>{t("Total customers")}</p>
            </div>
            <Card.Title>
              <b>{total ? total : 0}</b>
            </Card.Title>
            <span style={{ fontSize: "12px" }}>
              {Total_user_change_symbol === "+" ? (
                <img src={process.env.PUBLIC_URL + "/images/admin/profit.svg"} className="profitloss" alt="" />
              ) : (
                <img src={process.env.PUBLIC_URL + "/images/admin/loss.svg"} className="profitloss" alt="" />
              )}
              &nbsp;&nbsp;{Total_user_percentage}% &nbsp;{t(" change in last 30 days")}
            </span>
          </Card>
          <br />
        </div>
        <div className="col-lg-4 col-sm-12">
          {/* <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>{t("TOTAL ACTIVE CUSTOMERS")}</Card.Text>
              <Card.Title>
                <b>{activec ? activec.length : 0}</b>
              </Card.Title>
              <span style={{ fontSize: "12px" }}>
                {Total_active_symbol === "+" ? (
                  <img src={process.env.PUBLIC_URL + "/images/admin/profit.svg"} className="profitloss" alt="" />
                ) : (
                  <img src={process.env.PUBLIC_URL + "/images/admin/loss.svg"} className="profitloss" alt="" />
                )}
                &nbsp;&nbsp;{Total_active_percentage}% &nbsp;{t(" change in last 30 days")}
              </span>
              <span
                style={{
                  float: "right",
                  marginTop: "-60px",
                  marginRight: "-8px",
                }}
              >
                <div style={{ width: "20%" }}>
                  <img
                    className="CustomerImage "
                    src={process.env.PUBLIC_URL + "/images/customer/activecustomer.svg"}
                    alt="customer"
                  />
                </div>
              </span>
            </div>
          </Card> */}
          <Card className="customercard card-custom total-active-rules cards">
            <div className="diagonal-line"></div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p
                style={{
                  backgroundColor: "orange",
                  padding: "3px",
                  borderRadius: "50%",
                  width: "8px",
                  height: "8px",
                  marginRight: "8px",
                }}
              ></p>
              <p style={{ fontWeight: "500" }}>{t("Total active customers")}</p>
            </div>
            <Card.Title>
              <b>{activec ? activec.length : 0}</b>
            </Card.Title>
            <span style={{ fontSize: "12px" }}>
              {Total_active_symbol === "+" ? (
                <img src={process.env.PUBLIC_URL + "/images/admin/profit.svg"} className="profitloss" alt="" />
              ) : (
                <img src={process.env.PUBLIC_URL + "/images/admin/loss.svg"} className="profitloss" alt="" />
              )}
              &nbsp;&nbsp;{Total_active_percentage}% &nbsp;{t(" change in last 30 days")}
            </span>
          </Card>
          <br />
        </div>
        <div className="col-lg-4 col-sm-12">
          {/* <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>{t("TOTAL INACTIVE CUSTOMERS")}</Card.Text>
              <Card.Title>
                <b>{inactive ? inactive.length : 0}</b>
              </Card.Title>
              <span style={{ fontSize: "12px" }}>
                {Total_inactive_symbol === "+" ? (
                  <img src={process.env.PUBLIC_URL + "/images/admin/profit.svg"} className="profitloss" alt="" />
                ) : (
                  <img src={process.env.PUBLIC_URL + "/images/admin/loss.svg"} className="profitloss" alt="" />
                )}
                &nbsp;&nbsp;{Total_inactive_percentage}% &nbsp;{t(" change in last 30 days")}
              </span>
              <span
                style={{
                  float: "right",
                  marginTop: "-60px",
                  marginRight: "-8px",
                }}
              >
                <div style={{ width: "20%" }}>
                  <img
                    className="CustomerImage "
                    src={process.env.PUBLIC_URL + "/images/customer/inactivecustomer.svg"}
                    alt="customer"
                  />
                </div>
              </span>
            </div>
          </Card> */}
          <Card className="customercard card-custom total-inactive cards">
            <div className="diagonal-line"></div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p
                style={{
                  backgroundColor: "red",
                  padding: "3px",
                  borderRadius: "50%",
                  width: "8px",
                  height: "8px",
                  marginRight: "8px",
                }}
              ></p>
              <p style={{ fontWeight: "500" }}>{t("Total inactive customers")}</p>
            </div>
            <Card.Title>
              <b>{inactive ? inactive.length : 0}</b>
            </Card.Title>
            <span style={{ fontSize: "12px" }}>
              {Total_inactive_symbol === "+" ? (
                <img src={process.env.PUBLIC_URL + "/images/admin/profit.svg"} className="profitloss" alt="" />
              ) : (
                <img src={process.env.PUBLIC_URL + "/images/admin/loss.svg"} className="profitloss" alt="" />
              )}
              &nbsp;&nbsp;{Total_inactive_percentage}% &nbsp;{t(" change in last 30 days")}
            </span>
          </Card>
          <br />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12 col-sm-12 pad_t2">
          <Card className="customercard">
            <Card.Header>
              <Row className="align-items-center">
                <Col xs={6} md={6} className="rfid-test">
                  {t("Customer List")}
                </Col>
                <Col xs={6} md={6} className="text-right">
                  <Link to="/dashboard/accountcard/guestSignup" className="lgn-btn btn position-relative w-auto">
                    {t("Customer Signup")}
                  </Link>
                </Col>
              </Row>
            </Card.Header>
            <AccountList {...props} allCustomerData={allCustomerData} />
          </Card>
        </div>
      </div>

      <Card className="customercard mt-3">
        <div className="table-header">{t("Customer Feedback Report")}</div>
        {props.loading ? (
          <h3
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "250px",
            }}
          >
            Loading data...
          </h3>
        ) : props.customerFeedback?.data && props.customerFeedback.data.length !== null ? (
          <div>
            <CustomerFeedbackTable {...props} />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h3>{t("No Data Found")}</h3>
          </div>
        )}
      </Card>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.customer.isLoading,
    list: state.customer.allCustomerCardCount,
    percentchange: state.customer.customerPercentageChange,
    customerFeedback: state.customer.customerFeedback,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerCount: (currentPage, pageSize) => dispatch(allCutomersCardCountAction(currentPage, pageSize)),
    getPercentageChangeData: () => dispatch(getPercentageChangeData()),
    getCustomerFeedbackTable: () => dispatch(customerFeedback()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AccountCard);
