import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationPT from './components/locales/pt/translation.json';
import translationFR from './components/locales/fr/translation.json';
import translationES from './components/locales/es/translation.json';
import translationAR from './components/locales/ar/translation.json';
import translationID from './components/locales/id/translation.json';
import translationEN from './components/locales/en/translation.json'; // Fallback translation file
const defaultLanguage = process.env.REACT_APP_DEFAULT_LANGUAGE;
const selectedLanguage = localStorage.getItem("LocalLanguage");
// console.log("selectedLanguage",selectedLanguage)
i18n
    .use(initReactI18next)
    .init({
        debug: false,
        lng: selectedLanguage || defaultLanguage,
        fallbackLng: 'en',
        // backend: {
        //     loadPath: '/locales/{{lng}}/{{translation}}.json', // path to language files
        //   },
        resources: {
            en: { translation: translationEN }, //english
            // pt: { translation: translationPT }, //  portugeese
            // fr: { translation: translationFR }, // french
            // es: { translation: translationES }, //spanish
            // ar: { translation: translationAR } // Arabic
        },
        // Other configuration options...
    });

export default i18n;