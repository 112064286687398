import { Container, Row, Col, Card } from "react-bootstrap";
import React, { useEffect } from "react";
import { useParams, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PureBreadcrumbs from "../../breadcrums";
import ReactJson from 'react-json-view';
import { fetchInternalEmspPartnerListAction } from "../../../../store/actions/ocpiAction";

function ViewSingleEmspPartner(props) {
    const { party_id } = useParams();
    // console.log("party_id", party_id);
    useEffect(() => {
        props.getEmspList(party_id);
    }, [party_id]);

    const data = props.fetchSingleDetails && props.fetchSingleDetails.data
    const businessName = data && data?.[0].roles?.[0]?.business_details.name;
    const partyId = data && data?.[0]?.roles?.[0].party_id;
    const countrycode = data && data?.[0].roles?.[0].country_code;
    const version = data && data?.[0]?.endpoint_details?.version;
    const endpoints = data && data?.[0]?.endpoint_details?.endpoints;

    return (
        <Container fluid>
            <PureBreadcrumbs />
            <Row>
                <Col className="mt-4">
                    <span style={{ color: "var(--text-primary)", fontWeight: "bold" }}>Partner Details</span>
                </Col>
            </Row>

            <Row className="mt-3">
                <Col>
                    <Card className="tariffcard">
                        <Card.Body>
                            <Row>
                                <Col xs={12} md={6}>
                                    <div>
                                        <label htmlFor="floatingInputGridName">Partner Name</label>
                                        <input className="input gray-border" type="text" name="partner_name" id="floatingInputGridName" placeholder="Firstname" defaultValue={businessName} disabled />
                                    </div>
                                </Col>
                                <Col xs={12} md={6}>
                                    <div>
                                        <label htmlFor="floatingInputGridPID">Party ID</label>
                                        <input className="input gray-border" type="text" name="party_id" id="floatingInputGridparty_id" placeholder="Party ID" defaultValue={partyId} disabled />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mt-4">

                                <Col xs={12} md={6}>
                                    <div>
                                        <label htmlFor="floatingInputGridCC">Country Code</label>
                                        <input className="input gray-border" type="text" name="country_code" id="floatingInputGridcountry_code" placeholder="Country Code" defaultValue={countrycode} disabled />
                                    </div>
                                </Col>
                                <Col xs={12} md={6}>
                                    <div>
                                        <label htmlFor="floatingInputGridName">URL</label>
                                        <input className="input gray-border" type="text" name="url" id="floatingInputGridURL" placeholder="URL" defaultValue={data?.[0] ? data[0].url : ''} disabled />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col className="">
                                    <span style={{ color: "var(--text-primary)", fontWeight: "bold", fontSize: 18 }}>Endpoint Version - {version} </span>
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col className="">
                                    <span style={{ color: "var(--text-primary)", fontWeight: "bold", fontSize: 18 }}>Endpoint Details  </span>
                                    <ReactJson src={endpoints} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>

    )

}


const mapStateToProps = function (state) {
    return {
        loading: state.ocpi.isLoading,
        fetchSingleDetails: state.ocpi.fetchEmspPartner,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getEmspList: (party_id) => dispatch(fetchInternalEmspPartnerListAction(party_id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ViewSingleEmspPartner));