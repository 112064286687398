import React, { useState } from 'react'
import { useEffect } from "react";
import { Card, Row, Col, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import PureBreadcrumbs from "../../breadcrums";
import "../ocpi.css"
import { useTranslation } from 'react-i18next';
import { fetchExternalViewSingleSessionCdrPartnerDataAction, fetchExternalViewSingleSessionCdrSelfDataAction, fetchSingleOcpiStationAction } from '../../../../store/actions/ocpiAction';
import Table from '../../reacttable/table';
import moment from 'moment';

const ViewSingleCdrReport = (props) => {
    const { t } = useTranslation();
    const { id, party_id } = useParams();
    // console.log("id", id)
    const [activeTab, setActiveTab] = useState('Self');

    useEffect(() => {
        if (activeTab === 'Self') {
            props.fetchSelfCdrApi(id, party_id) // Fetch Self data
        } else if (activeTab === 'Partner') {
            props.fetchPartnerCdrApi(id, party_id) // Fetch partner data
        }
    }, [activeTab, id, party_id]);

    const selfData = props.fetchSelfCdrRes && props.fetchSelfCdrRes.data &&
        props.fetchSelfCdrRes.data?.[0] ? props.fetchSelfCdrRes.data?.[0] : {};

    const partnerData = props.fetchPartnerCdrRes && props.fetchPartnerCdrRes.data &&
        props.fetchPartnerCdrRes.data?.[0] ? props.fetchPartnerCdrRes.data?.[0] : {};
    const data = activeTab === 'Self' ? selfData : partnerData;

    const { latitude = null, longitude = null } = data?.coordinates || {};
    // console.log("data", data);

    const renderForm = (isPartner) => (
        <Row className='mt-3'>
            <Col xs={12} md={12}>
                <form onSubmit={e => e.preventDefault()}>
                    <Card>
                        <div>
                            <h4 className='text-center p-3'>View Single Session Details</h4>
                        </div>
                        <Card.Body>

                            {/* Basic Details */}
                            <h5 className="mt-4">Basic Details</h5>
                            <div className="row g-2">
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputCountryCode'>Country Code</label>
                                        <input type="text" className="form-control gray-border" id="floatingInputCountryCode" placeholder='Country Code' value={data?.country_code || 'N/A'} disabled />
                                    </div>
                                </div>
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputPartyId'>Party ID</label>
                                        <input type="text" className="form-control gray-border" id="floatingInputPartyId" placeholder='Party ID' value={data?.party_id || 'N/A'} disabled />
                                    </div>
                                </div>
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputId'>ID</label>
                                        <input type="text" className="form-control gray-border" id="floatingInputId" placeholder='ID' value={data?.id || 'N/A'} disabled />
                                    </div>
                                </div>
                            </div>

                            {/* Session Time Details */}
                            <h5 className="mt-4">Session Time Details</h5>
                            <div className="row g-2">
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputStartTime'>Start Date</label>
                                        <input type="text" className="form-control gray-border" id="floatingInputStartTime" placeholder='Start Date' value={data?.last_updated ? moment(data?.start_date_time).format('DD/MM/YYYY') : 'NA'} disabled />
                                    </div>
                                </div>
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputEndTime'>End Date</label>
                                        <input type="text" className="form-control gray-border" id="floatingInputEndTime" placeholder='End Date' value={data?.last_updated ? moment(data?.end_date_time).format('DD/MM/YYYY') : 'NA'} disabled />
                                    </div>
                                </div>
                            </div>

                            {/* CDR Token Details */}
                            <h5 className="mt-4">CDR Token Details</h5>
                            <div className="row g-2">
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputTokenCountryCode'>Token Country Code</label>
                                        <input type="text" className="form-control gray-border" id="floatingInputTokenCountryCode" placeholder='Token Country Code' value={data?.cdr_token?.country_code || 'N/A'} disabled />
                                    </div>
                                </div>
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputTokenPartyId'>Token Party ID</label>
                                        <input type="text" className="form-control gray-border" id="floatingInputTokenPartyId" placeholder='Token Party ID' value={data?.cdr_token?.party_id || 'N/A'} disabled />
                                    </div>
                                </div>
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputTokenUid'>Token UID</label>
                                        <input type="text" className="form-control gray-border" id="floatingInputTokenUid" placeholder='Token UID' value={data?.cdr_token?.uid || 'N/A'} disabled />
                                    </div>
                                </div>
                            </div>

                            <div className="row g-2">
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputTokenType'>Token Type</label>
                                        <input type="text" className="form-control gray-border" id="floatingInputTokenType" placeholder='Token Type' value={data?.cdr_token?.type || 'N/A'} disabled />
                                    </div>
                                </div>
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputContractId'>Contract ID</label>
                                        <input type="text" className="form-control gray-border" id="floatingInputContractId" placeholder='Contract ID' value={data?.cdr_token?.contract_id || 'N/A'} disabled />
                                    </div>
                                </div>
                            </div>

                            {/* CDR Location */}
                            <h5 className="mt-4">CDR Location</h5>
                            <div className="row g-2">
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputCdrLocationId'>Location ID</label>
                                        <input type="text" className="form-control gray-border" id="floatingInputCdrLocationId" placeholder='Location ID' value={data?.cdr_location?.id || 'N/A'} disabled />
                                    </div>
                                </div>
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputCdrLocationName'>Location Name</label>
                                        <input type="text" className="form-control gray-border" id="floatingInputCdrLocationName" placeholder='Location Name' value={data?.cdr_location?.name || 'N/A'} disabled />
                                    </div>
                                </div>
                            </div>

                            <div className="row g-2">
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputAddress'>Address</label>
                                        <input type="text" className="form-control gray-border" id="floatingInputAddress" placeholder='Address' value={data?.cdr_location?.address || 'N/A'} disabled />
                                    </div>
                                </div>
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputCity'>City</label>
                                        <input type="text" className="form-control gray-border" id="floatingInputCity" placeholder='City' value={data?.cdr_location?.city || 'N/A'} disabled />
                                    </div>
                                </div>
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputPostalCode'>Postal Code</label>
                                        <input type="text" className="form-control gray-border" id="floatingInputPostalCode" placeholder='Postal Code' value={data?.cdr_location?.postal_code || 'N/A'} disabled />
                                    </div>
                                </div>
                            </div>

                            <div className="row g-2">
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputCountry'>Country</label>
                                        <input type="text" className="form-control gray-border" id="floatingInputCountry" placeholder='Country' value={data?.cdr_location?.country || 'N/A'} disabled />
                                    </div>
                                </div>
                            </div>

                            <div className="row g-2">
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputCoordinatesLat'>Latitude</label>
                                        <input type="text" className="form-control gray-border" id="floatingInputCoordinatesLat" placeholder='Latitude' value={data?.cdr_location?.coordinates?.latitude || 'N/A'} disabled />
                                    </div>
                                </div>
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputCoordinatesLon'>Longitude</label>
                                        <input type="text" className="form-control gray-border" id="floatingInputCoordinatesLon" placeholder='Longitude' value={data?.cdr_location?.coordinates?.longitude || 'N/A'} disabled />
                                    </div>
                                </div>
                            </div>

                            <div className="row g-2">
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputEvseUid'>EVSE UID</label>
                                        <input type="text" className="form-control gray-border" id="floatingInputEvseUid" placeholder='EVSE UID' value={data?.cdr_location?.evse_uid || 'N/A'} disabled />
                                    </div>
                                </div>
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputEvseId'>EVSE ID</label>
                                        <input type="text" className="form-control gray-border" id="floatingInputEvseId" placeholder='EVSE ID' value={data?.cdr_location?.evse_id || 'N/A'} disabled />
                                    </div>
                                </div>
                            </div>

                            <div className="row g-2">
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputConnectorId'>Connector ID</label>
                                        <input type="text" className="form-control gray-border" id="floatingInputConnectorId" placeholder='Connector ID' value={data?.cdr_location?.connector_id || 'N/A'} disabled />
                                    </div>
                                </div>
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputConnectorStandard'>Connector Standard</label>
                                        <input type="text" className="form-control gray-border" id="floatingInputConnectorStandard" placeholder='Connector Standard' value={data?.cdr_location?.connector_standard || 'N/A'} disabled />
                                    </div>
                                </div>
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputConnectorFormat'>Connector Format</label>
                                        <input type="text" className="form-control gray-border" id="floatingInputConnectorFormat" placeholder='Connector Format' value={data?.cdr_location?.connector_format || 'N/A'} disabled />
                                    </div>
                                </div>
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputConnectorPowerType'>Connector Power Type</label>
                                        <input type="text" className="form-control gray-border" id="floatingInputConnectorPowerType" placeholder='Connector Power Type' value={data?.cdr_location?.connector_power_type || 'N/A'} disabled />
                                    </div>
                                </div>
                            </div>

                            {/* Tariffs and Charging Periods as Table */}
                            <h5 className="mt-4">Tariffs</h5>
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Tariff ID</th>
                                        <th>Price Component Type</th>
                                        <th>Price</th>
                                        <th>Step Size</th>
                                        <th>VAT</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.tariffs?.map((tariff, index) => (
                                        tariff?.elements?.map((element, elIndex) => (
                                            element?.price_components?.map((component, compIndex) => (
                                                <tr key={`${index}-${elIndex}-${compIndex}`}>
                                                    <td>{tariff?.id || 'N/A'}</td>
                                                    <td>{component?.type || 'N/A'}</td>
                                                    <td>{component?.price || 'N/A'}</td>
                                                    <td>{component?.step_size || 'N/A'}</td>
                                                    <td>{component?.vat || 'N/A'}</td>
                                                </tr>
                                            ))
                                        ))
                                    ))}
                                </tbody>
                            </table>

                            {/* Charging Periods Table */}
                            <h5 className="mt-4">Charging Periods</h5>
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Start Time</th>
                                        <th>Dimension Type</th>
                                        <th>Volume</th>
                                        <th>Tariff ID</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.charging_periods?.map((period, index) => (
                                        period?.dimensions?.map((dimension, dimIndex) => (
                                            <tr key={`${index}-${dimIndex}`}>
                                                <td>{period?.start_date_time || 'N/A'}</td>
                                                <td>{dimension?.type || 'N/A'}</td>
                                                <td>{dimension?.volume || 'N/A'}</td>
                                                <td>{period?.tariff_id || 'N/A'}</td>
                                            </tr>
                                        ))
                                    ))}
                                </tbody>
                            </table>

                            {/* Signed Data Section */}
                            <h5 className="mt-4">Signed Data Details</h5>
                            <div className="row g-2">
                                <div className="col-md p-2">
                                    <label htmlFor="floatingInputEncodingMethod">Encoding Method</label>
                                    <div className="form-floating">
                                        <input
                                            type="text"
                                            className="form-control gray-border"
                                            id="floatingInputEncodingMethod"
                                            placeholder="Encoding Method"
                                            value={data?.signed_data?.encoding_method || 'N/A'}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor="floatingInputEncodingMethodVersion">Encoding Method Version</label>
                                        <input
                                            type="number"
                                            className="form-control gray-border"
                                            id="floatingInputEncodingMethodVersion"
                                            placeholder="Encoding Method Version"
                                            value={data?.signed_data?.encoding_method_version || 'N/A'}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row g-2">
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor="floatingInputPublicKey">Public Key</label>
                                        <input
                                            type="text"
                                            className="form-control gray-border"
                                            id="floatingInputPublicKey"
                                            placeholder="Public Key"
                                            value={data?.signed_data?.public_key || 'N/A'}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor="floatingInputSignedDataUrl">URL</label>
                                        <input
                                            type="text"
                                            className="form-control gray-border"
                                            id="floatingInputSignedDataUrl"
                                            placeholder="Signed Data URL"
                                            value={data?.signed_data?.url || 'N/A'}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>

                            <h5 className="mt-4">Signed Values</h5>
                            <div className="row g-2">
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor="floatingInputNature">Nature</label>
                                        <input
                                            type="text"
                                            className="form-control gray-border"
                                            id="floatingInputNature"
                                            placeholder="Nature"
                                            value={data?.signed_data?.signed_values?.nature || 'N/A'}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor="floatingInputPlainData">Plain Data</label>
                                        <input
                                            type="text"
                                            className="form-control gray-border"
                                            id="floatingInputPlainData"
                                            placeholder="Plain Data"
                                            value={data?.signed_data?.signed_values?.plain_data || 'N/A'}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row g-2">
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor="floatingInputSignedData">Signed Data</label>
                                        <input
                                            type="text"
                                            className="form-control gray-border"
                                            id="floatingInputSignedData"
                                            placeholder="Signed Data"
                                            value={data?.signed_data?.signed_values?.signed_data || 'N/A'}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* Total Costs */}
                            <h5 className="mt-4">Total Cost Details</h5>
                            <div className="row g-2">
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputTotalCostExclVat'>Total Cost Excl. VAT</label>
                                        <input type="number" className="form-control gray-border" id="floatingInputTotalCostExclVat" placeholder='Excl. VAT' value={data?.total_cost?.excl_vat || 'N/A'} disabled />
                                    </div>
                                </div>
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputTotalCostInclVat'>Total Cost Incl. VAT</label>
                                        <input type="number" className="form-control gray-border" id="floatingInputTotalCostInclVat" placeholder='Incl. VAT' value={data?.total_cost?.incl_vat || 'N/A'} disabled />
                                    </div>
                                </div>
                            </div>
                            {/* Total Fixed Costs */}
                            <h5 className="mt-4">Total Fixed Cost Details</h5>
                            <div className="row g-2">
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputTotalCostExclVat'>Total Fixed Cost Excl. VAT</label>
                                        <input type="number" className="form-control gray-border" id="floatingInputTotalCostExclVat" placeholder='Excl. VAT' value={data?.total_fixed_cost?.excl_vat || 'N/A'} disabled />
                                    </div>
                                </div>
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputTotalCostInclVat'>Total Fixed Cost Incl. VAT</label>
                                        <input type="number" className="form-control gray-border" id="floatingInputTotalCostInclVat" placeholder='Incl. VAT' value={data?.total_fixed_cost?.incl_vat || 'N/A'} disabled />
                                    </div>
                                </div>
                            </div>


                            {/* Total Energy Costs */}
                            <h5 className="mt-4">Total Energy Cost Details</h5>
                            <div className="row g-2">
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputTotalCostExclVat'>Total Energy Cost Excl. VAT</label>
                                        <input type="number" className="form-control gray-border" id="floatingInputTotalCostExclVat" placeholder='Excl. VAT' value={data?.total_energy_cost?.excl_vat || 'N/A'} disabled />
                                    </div>
                                </div>
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputTotalCostInclVat'>Total Energy Cost Incl. VAT</label>
                                        <input type="number" className="form-control gray-border" id="floatingInputTotalCostInclVat" placeholder='Incl. VAT' value={data?.total_energy_cost?.incl_vat || 'N/A'} disabled />
                                    </div>
                                </div>
                            </div>
                            {/* Total Parking Costs */}
                            <h5 className="mt-4">Total Parking Cost Details</h5>
                            <div className="row g-2">
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputTotalCostExclVat'>Total Parking Cost Excl. VAT</label>
                                        <input type="number" className="form-control gray-border" id="floatingInputTotalCostExclVat" placeholder='Excl. VAT' value={data?.total_parking_cost?.excl_vat || 'N/A'} disabled />
                                    </div>
                                </div>
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputTotalCostInclVat'>Total Parking Cost Incl. VAT</label>
                                        <input type="number" className="form-control gray-border" id="floatingInputTotalCostInclVat" placeholder='Incl. VAT' value={data?.total_parking_cost?.incl_vat || 'N/A'} disabled />
                                    </div>
                                </div>
                            </div>
                            {/* Total Reservation Costs */}
                            <h5 className="mt-4">Total Reservation Cost Details</h5>
                            <div className="row g-2">
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputTotalCostExclVat'>Total Reservation Cost Excl. VAT</label>
                                        <input type="number" className="form-control gray-border" id="floatingInputTotalCostExclVat" placeholder='Excl. VAT' value={data?.total_reservation_cost?.excl_vat || 'N/A'} disabled />
                                    </div>
                                </div>
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputTotalCostInclVat'>Total Reservation Cost Incl. VAT</label>
                                        <input type="number" className="form-control gray-border" id="floatingInputTotalCostInclVat" placeholder='Incl. VAT' value={data?.total_reservation_cost?.incl_vat || 'N/A'} disabled />
                                    </div>
                                </div>
                            </div>

                            {/* Additional Cost Fields */}
                            <h5 className="mt-4">Additional Cost Details</h5>
                            <div className="row g-2">
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputTotalEnergy'>Total Energy</label>
                                        <input type="number" className="form-control gray-border" id="floatingInputTotalEnergy" placeholder='Total Energy' value={data?.total_energy || 'N/A'} disabled />
                                    </div>
                                </div>
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputTotalTime'>Total Time</label>
                                        <input type="number" className="form-control gray-border" id="floatingInputTotalTime" placeholder='Total Time' value={data?.total_time || 'N/A'} disabled />
                                    </div>
                                </div>
                            </div>

                            <h5 className="mt-4">Total Time Cost</h5>
                            <div className="row g-2">
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputTotalTimeCostExclVat'>Total Time Cost Excl. VAT</label>
                                        <input type="number" className="form-control gray-border" id="floatingInputTotalTimeCostExclVat" placeholder='Excl. VAT' value={data?.total_time_cost?.excl_vat || 'N/A'} disabled />
                                    </div>
                                </div>
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputTotalTimeCostInclVat'>Total Time Cost Incl. VAT</label>
                                        <input type="number" className="form-control gray-border" id="floatingInputTotalTimeCostInclVat" placeholder='Incl. VAT' value={data?.total_time_cost?.incl_vat || 'N/A'} disabled />
                                    </div>
                                </div>
                            </div>

                            {/* Last Updated */}
                            <h5 className="mt-4">Last Updated</h5>
                            <div className="form-floating">
                                <input type="text" className="form-control gray-border" id="floatingInputLastUpdated" placeholder='Last Updated' value={data?.last_updated ? moment(data?.last_updated).format('DD/MM/YYYY') : 'NA'} disabled />
                            </div>

                        </Card.Body>
                    </Card>
                </form>
            </Col>
        </Row>
    );

    return (
        <Container fluid>
            <PureBreadcrumbs />
            <br />

            <Row className="mt-3">
                <Col xs={12}>
                    <div style={{
                        border: "1px solid #d9d9d9",
                        borderRadius: "50px",
                        padding: "0px",
                        backgroundColor: "#f3f3f3", // Light background for the switch container
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "20%",
                        margin: "0 auto",
                    }}>
                        <button
                            onClick={() => setActiveTab('Self')}
                            className={`tab-button ${activeTab === 'Self' ? 'active' : ''}`}
                            style={{
                                backgroundColor: activeTab === 'Self' ? '#000000' : '#f3f3f3',
                                color: activeTab === 'Self' ? '#f3f3f3' : '#000000',
                                border: "none",
                                padding: '10px 20px',
                                borderRadius: "50px",
                                fontWeight: "bold",
                                fontSize: "16px",
                                cursor: "pointer",
                                transition: "all 0.3s ease",
                                width: "55%",
                            }}
                        >
                            Self
                        </button>
                        <button
                            onClick={() => setActiveTab('Partner')}
                            className={`tab-button ${activeTab === 'Partner' ? 'active' : ''}`}
                            style={{
                                backgroundColor: activeTab === 'Partner' ? '#000000' : '#f3f3f3',
                                color: activeTab === 'Partner' ? '#f3f3f3' : '#000000',
                                border: "none",
                                padding: '10px 20px',
                                borderRadius: "50px",
                                fontWeight: "bold",
                                fontSize: "16px",
                                cursor: "pointer",
                                transition: "all 0.3s ease",
                                width: "55%",
                            }}
                        >
                            Partner
                        </button>
                    </div>
                </Col>
            </Row>
            {props.loading ? (
                <Row >
                    <Col lg={12} className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden"></span>
                        </div>
                    </Col>
                </Row>
            ) : (
                <>
                    {activeTab === 'Self' && renderForm(false)}
                    {activeTab === 'Partner' && renderForm(true)}
                </>
            )}

        </Container>
    )
}

const mapStateToProps = (state) => {
    return {
        loading: state.ocpi.isLoading,
        fetchSelfCdrRes: state.ocpi.fetchExternalViewSingleCdrSelfData,
        fetchPartnerCdrRes: state.ocpi.fetchExternalViewSingleCdrPartnerData,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchSelfCdrApi: (id, party_id) => dispatch(fetchExternalViewSingleSessionCdrSelfDataAction(id, party_id)),
        fetchPartnerCdrApi: (id, party_id) => dispatch(fetchExternalViewSingleSessionCdrPartnerDataAction(id, party_id)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewSingleCdrReport)