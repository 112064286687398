import React from "react";
import "react-dates/initialize";
import { useMemo } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Table from "../../reacttable/table";
import moment from "moment";
import { FaEdit } from "react-icons/fa";
import { connect } from "react-redux";


function CustomerFeedbackTable(props) {

  const feedbackTableData = props.customerFeedback && props.customerFeedback.data;
  // console.log(feedbackTableData);
  const columns = useMemo(
    () => [

      {
        Header: "Station Name",
        accessor: "stationid",
      },
      {
        Header: "User Name",
        accessor: "userid",
        id: "useridd",
      },
      {
        Header: "Operator",
        accessor: "operator",
      },
      {
        Header: "Created Date",
        id: "createdat",
        accessor: (d) => {
          return moment(d.createdat).format("DD-MM-YYYY");
        },
      },
      {
        Header: "Comments",
        accessor: "comment",
      },
      {
        Header: "Rating",
        accessor: "rating",
      },
      {
        Header: "Status",
        id: "status",
        accessor: (d) => {
          let statusColorClass = "";
          if (d.status === "Open") {
            statusColorClass = "text-success";
          } else if (d.status === "In-review") {
            statusColorClass = "text-primary";
          } else if (d.status === "Closed") {
            statusColorClass = "text-danger";
          }

          return d.status ? <span className={`${statusColorClass} pallet`} style={{ userSelect: "none" }} ><b>{d.status}</b></span> : "";
        },
      },

      {
        Header: "Admin Feedback",
        accessor: "admin_comment",
      },
      {
        Header: 'Actions',
        accessor: 'id',
        Cell: (id) => {
          return (
            <div>
              <span>
                <a
                  href={`/dashboard/accountcard/adminstatus/${id.value}`}
                  rel="tooltip"
                  title="Edit"
                >
                  <FaEdit />
                </a>
              </span>

            </div>

          );
        },
      }

    ],
    []
  );

  return (
    <>
      <div className="customercard card mt-3">
        <Table data={feedbackTableData} columns={columns} ></Table>
      </div>
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    loading: state.customer.isLoading,

  };
};
const mapDispatchToProps = (dispatch) => {
  return {
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CustomerFeedbackTable);