const initState = {
    isLoading: false,
    isRevenueGraphLoading: false,
    isCityGraphLoading: false,
    isStationGraphLoading: false,
    evse_charger_status: '',
    charging_total_status: '',
    hrs_session: '',
    city_total_session: '',
    session_t_t: '',
    session_p_f_session: '',
    city_comparision: '',
    station_total_session: '',
    station_comparision: '',
    week_session: '',
    month_session: '',
    lifetime_energy_revenue: '',
    daterange_session_type: '',
    daterange_session_status: '',
    customer_top5_searches: '',
    customer_all_searches: '',
    lifetime_daily_list: '',
    lifetime_weekly_list: '',
    lifetime_monthly_list: '',
    energy_revenue_insight: '',
    heatmap_insight: '',
    percentageChange: '',
    notification: '',
    post_notifications: '',
    // fetchnotificationApiCount: '',
    // addNotificationCount: '',
    // removeNotificationCount: '',
    saveMarkasRead: '',
    getCountryCode: '',
    getTranslation: '',
    fetchCo2Count: '',
    fetchCo2List: '',
}

const insightReducer = (state = initState, action) => {
    switch (action.type) {
        case 'INSIGHT_LOADING':
            return { ...state, isLoading: true }
        case 'INSIGHT_REVENUE_GRAPH_LOADING':
            return { ...state, isRevenueGraphLoading: true }
        case 'INSIGHT_CITY_GRAPH_LOADING':
            return { ...state, isCityGraphLoading: true }
        case 'INSIGHT_STATION_GRAPH_LOADING':
            return { ...state, isStationGraphLoading: true }
        case 'LOAD_CHARGER_EVSE_STATUS':
            return { ...state, evse_charger_status: action.payload, isLoading: false };
        case 'LOAD_TOTAL_CHARGING_STATUS':
            return { ...state, charging_total_status: action.payload, isLoading: false };
        case 'LOAD_HRS_SESSION':
            return { ...state, hrs_session: action.payload, isLoading: false };
        case 'LOAD_TOTAL_CITY_CONSUMPTION':
            return { ...state, city_total_session: action.payload, isCityGraphLoading: false };
        case 'LOAD_SESSION_TYPE':
            return { ...state, session_t_t: action.payload, isLoading: false };
        case 'LOAD_TOTAL_SESSION':
            return { ...state, session_p_f_session: action.payload, isLoading: false };
        case 'FETCH_CITY_COMPARISION':
            return { ...state, city_comparision: action.payload, isCityGraphLoading: false };
        case 'LOAD_TOTAL_STATION_CONSUMPTION':
            return { ...state, station_total_session: action.payload, isStationGraphLoading: false };
        case 'FETCH_STATION_COMPARISION':
            return { ...state, station_comparision: action.payload, isStationGraphLoading: false };
        case 'LOAD_WEEK_SESSION':
            return { ...state, week_session: action.payload, isLoading: false };
        case 'LOAD_MONTH_SESSION':
            return { ...state, month_session: action.payload, isLoading: false };
        case 'LOAD_LIFETIME_ENERGY_REVENUE':
            return { ...state, lifetime_energy_revenue: action.payload, isRevenueGraphLoading: false };
        case 'FETCH_DATERANGE_SESSION_TYPE':
            return { ...state, daterange_session_type: action.payload, isLoading: false };
        case 'FETCH_DATERANGE_SESSION_STATUS':
            return { ...state, daterange_session_status: action.payload, isLoading: false };
        case 'FETCH_CUSTOMER_TOP5_SEARCH_PREDICTION':
            return { ...state, customer_top5_searches: action.payload, isLoading: false };
        case 'FETCH_CUSTOMER_ALL_SEARCH_PREDICTION':
            return { ...state, customer_all_searches: action.payload, isLoading: false };
        case 'FETCH_LIFETIME_DAILY_LIST':
            return { ...state, lifetime_daily_list: action.payload, isLoading: false };
        case 'FETCH_LIFETIME_WEEKLY_LIST':
            return { ...state, lifetime_Weekly_list: action.payload, isLoading: false };
        case 'FETCH_LIFETIME_MONTHLY_LIST':
            return { ...state, lifetime_monthly_list: action.payload, isLoading: false };
        case 'FETCH_ENERGY_REVENUE_INSIGHT_GRAPH':
            return { ...state, energy_revenue_insight: action.payload, isLoading: false };
        case 'FETCH_HEATMAP_GRAPH':
            return { ...state, heatmap_insight: action.payload, isLoading: false };
        case 'INSIGHT_PERCENTAGE_CHANGE':
            return { ...state, percentageChange: action.payload, isLoading: false };
        case 'FETCH_NOTIFICATION_API':
            return { ...state, notification: action.payload, isLoading: false };
        case 'POST_NOTIFICATION_API':
            return { ...state, post_notifications: action.payload, isLoading: false };
        case 'MARK_AS_READ_API':
            return { ...state, saveMarkasRead: action.payload, isLoading: false };
        case 'FETCH_COUNTRY_CODE':
            return { ...state, getCountryCode: action.payload, isLoading: false };
        case 'FETCH_TRANSLATION_JSON':
            return { ...state, getTranslation: action.payload, isLoading: false };
        case 'FETCH_CO2_COUNT_INSIGHT_API':
            return { ...state, fetchCo2Count: action.payload, isLoading: false };
        case 'FETCH_CO2_LIST_INSIGHT_API':
            return { ...state, fetchCo2List: action.payload, isLoading: false };
        // case 'FETCH_NOTIFICATION_COUNT':
        //     const ncount = localStorage.getItem("addnotificationCount")
        //     return { ...state, fetchnotificationApiCount: ncount, isLoading: false };
        // case 'ADD_NOTIFICATION_COUNT':
        //     console.log(action.payload)
        //     localStorage.setItem("addnotificationCount", action.payload)
        //     return { ...state, addNotificationCount: action.payload, isLoading: false };

        // case 'REMOVE_NOTIFICATION_COUNT':
        //     console.log(localStorage.getItem("addnotificationCount"))
        //     localStorage.removeItem("addnotificationCount")
        //     return { ...state, removeNotificationCount: 0, isLoading: false };

        default:
            return state;
    }
}
export default insightReducer;