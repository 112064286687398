import { BASE_URL, GET_CITY_API, GET_STATE_API, GET_COUNTRY_API, GET_AMENITY_API, GET_ROLE_API, GET_PRIVILEGE_API, GET_SUBPRIVILEGE_API, FETCH_ALL_CITIES, FETCH_ALL_MAKER, FETCH_ALL_MODEL, GET_NEW_PRIVILAGE } from "../../components/config/config";
import { ApiHandler } from "./apiHandler";


export const fetchCoutryAction = () => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'fetchCoutryAction'
    return async dispatch => {
        dispatch({ type: 'LOAD', payload: '' })
        const data = await ApiHandler(BASE_URL + GET_COUNTRY_API, {
            functionName,
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'LOAD_COUNTRY', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const fetchStateAction = (cc) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'fetchStateAction'
    return async dispatch => {
        dispatch({ type: 'LOAD', payload: '' })
        // console.log(BASE_URL + GET_STATE_API + "?country_code=" + cc)
        const data = await ApiHandler(BASE_URL + GET_STATE_API + "?country_code=" + cc, {
            functionName,
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'LOAD_STATE', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const fetchCityAction = (sc) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'fetchCityAction'
    return async dispatch => {
        dispatch({ type: 'LOAD', payload: '' })
        const data = await ApiHandler(BASE_URL + GET_CITY_API + "?state_code=" + sc, {
            functionName,
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'LOAD_CITY', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const fetchAmenityAction = () => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'fetchAmenityAction'
    return async dispatch => {
        dispatch({ type: 'LOAD', payload: '' })
        const data = await ApiHandler(BASE_URL + GET_AMENITY_API, {
            functionName,
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'LOAD_AMENITY', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const fetchRoleAction = () => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'fetchRoleAction'
    return async dispatch => {
        dispatch({ type: 'LOAD', payload: '' })
        const data = await ApiHandler(BASE_URL + GET_ROLE_API, {
            functionName,
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'LOAD_ROLE', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const fetchPrivilegeAction = (role) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'fetchPrivilegeAction'
    return async dispatch => {
        //  console.log(BASE_URL + GET_PRIVILEGE_API + "?role_id=" + role)
        dispatch({ type: 'LOAD', payload: '' })
        const data = await ApiHandler(BASE_URL + GET_PRIVILEGE_API + "?role_id=" + role, {
            functionName,
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'LOAD_PRIVILEGE', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const fetchNewPrivilegeAction = (role) => {
    // console.log('role',role)
    const { token } = JSON.parse(localStorage.getItem('user'));
    // console.log('token', token)
    const bearer = 'basic ' + token
    const functionName = 'fetchNewPrivilegeAction'
    return async dispatch => {
        dispatch({ type: 'LOAD', payload: '' })
        const data = await ApiHandler(BASE_URL + GET_NEW_PRIVILAGE + "?role_id=" + role, {
            functionName,
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'LOAD_NEW_PRIVILEGES', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const fetchSubPrivilegeAction = (role) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'fetchSubPrivilegeAction'
    return async dispatch => {
        dispatch({ type: 'LOAD', payload: '' })
        const data = await ApiHandler(BASE_URL + GET_SUBPRIVILEGE_API + "?parent_key=" + role, {
            functionName,
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'LOAD_SUB_PRIVILEGE', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const fetchAllCitiesAction = () => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'fetchAllCitiesAction'
    return async dispatch => {
        dispatch({ type: 'LOAD', payload: '' })
        const data = await ApiHandler(BASE_URL + FETCH_ALL_CITIES, {
            functionName,
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'LOAD_SUB_PRIVILEGE', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const fetchAllMaker = () => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'fetchAllMaker'
    return async dispatch => {
        dispatch({ type: 'LOAD', payload: '' })
        const data = await ApiHandler(BASE_URL + FETCH_ALL_MAKER, {
            functionName,
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'FETCH_ALL_MAKER', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const fetchAllModel = (maker) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'fetchAllModel'
    return async dispatch => {
        dispatch({ type: 'LOAD', payload: '' })
        const data = await ApiHandler(BASE_URL + FETCH_ALL_MODEL + "?make_code=" + maker, {
            functionName,
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'FETCH_ALL_MODEL', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}