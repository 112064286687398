import { Container } from "react-bootstrap";
import PureBreadcrumbs from "../breadcrums";
import React, { useEffect } from "react"
import { connect } from "react-redux";
import { allOrgTariffAction } from "../../../store/actions/organisationAction";
import AllReportComponent from "./adminReport/allReport";
import EnterpriseReportAll from "./enterpriseReport/enterpriseReport";

function ReportCard(props) {
    const { role } = JSON.parse(localStorage.getItem('user') || '{}');

    useEffect(() => {
        props.getOrg()
    }, []);

    return (
        <Container fluid className="pb-4">
            <PureBreadcrumbs />
            <br />
            {
                role !== "Enterprise" && role !== "Network Operator" && role !== "Regional Manager" ? <>
                    {props.orgdata && <AllReportComponent  {...props}></AllReportComponent>}</> :
                    <>
                        <EnterpriseReportAll {...props}></EnterpriseReportAll>
                    </>
            }
        </Container>
    )

}

const mapStateToProps = (state) => {
    return {
        loading: state.report.isLoading,
        orgdata: state.operator.organisationsList
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getOrg: () => dispatch(allOrgTariffAction())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ReportCard)