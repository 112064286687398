// import React, { useEffect, useState } from "react";
// import "react-dates/initialize";
// import { useMemo } from "react";
// import "react-datepicker/dist/react-datepicker.css";
// import Table from "../../reacttable/table";
// import { useTranslation } from "react-i18next";
// import { connect } from "react-redux";
// import { fetchChargerReport } from "../../../../store/actions/reportActions";
// import CustomReportPaginationTable from "./customReportPaginationTable";

// function ChargerReportTable(props) {
//   const { t } = useTranslation();

//   const { allData } = props;

//   useEffect(() => {
//     props.chargerReportApi(currentPage, pageSize);
//   }, [currentPage, pageSize])

//   const data = props.charger_report && props.charger_report.data;
//   // console.log(data);



//   return (
//     <>
//       {props.loading ? <h3 style={{
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//         height: "250px",
//       }}>Loading data...</h3> :
//         props.charger_report && props.charger_report?.data && props.charger_report?.data?.length !== null ? (
//           <div>
//             {/* <Table data={data} columns={columns} ></Table> */}
//             <CustomReportPaginationTable
//               data={data}
//               allData={allData}
//               pageCount={pageCount}
//               columns={columns}
//               search={search}
//               setSearch={setSearch}
//               currentPage={currentPage}
//               setCurrentPage={setCurrentPage}
//               pageSize={pageSize}
//               setPageSize={setPageSize}
//               filteredData={filteredData}
//               setFilteredData={setFilteredData}
//               loading={props.loading}
//               initialVisibleColumns={initialVisibleColumns}
//               t={t}
//             />
//           </div>
//         ) : (
//           <div
//             style={{
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "center",
//             }}
//           >
//             <h3>{t("No Data Found")}</h3>
//           </div>
//         )}
//     </>
//   )

// }
// const mapStateToProps = (state) => {
//   return {
//     loading: state.report.isLoading,
//     charger_report: state.report.chargerReport,
//   };
// };
// const mapDispatchToProps = (dispatch) => {
//   return {
//     chargerReportApi: (currentPage, pageSize) => dispatch(fetchChargerReport(currentPage, pageSize)),
//   };
// };
// export default connect(mapStateToProps, mapDispatchToProps)(ChargerReportTable);
