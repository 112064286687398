import React from 'react';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useTranslation } from 'react-i18next';

const DownloadCo2ReportPdf = (props) => {
    const { t } = useTranslation();

    const generatePDF = () => {
        console.log("props", props);
        const unit = "pt";
        const size = "A4";
        const orientation = "landscape";
        const marginLeft = 40;
        const marginTop = 40;
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(12);

        // Define headers with formatted names
        const headers = [
            "Station Name", "Total kWh", "Life time Co2 reduction",
        ];

        // Map data from props to match headers
        const data = props.data?.map(obj => {
            return [
                obj.stationid,
                obj.total_kwh,
                `${obj.total_co2} kg`,
            ];
        });


        let content = {
            startY: 50,
            head: [headers],
            body: data
        };

        doc.text("Co2 Report", marginLeft, 40);
        doc.autoTable(content);
        doc.save("Co2.pdf");
    };

    const handleButtonClick = () => {
        generatePDF();
    };

    return (
        <div>
            <button className='pdfbutton' onClick={handleButtonClick} disabled={props.data === null} type="button">{t("Download Co2 Report")}</button>
        </div>
    );
};

export default DownloadCo2ReportPdf;
