import { Container, Row, Col, Card } from "react-bootstrap";
import PureBreadcrumbs from "../../breadcrums";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { allEvseAction } from "../../../../store/actions/evseAction";
import DiscoveryEVSEList from "./discoveryEvseList";
import { useTranslation } from "react-i18next";
import DiscoveryMap from "./viewDiscoveryMap";


function ViewDiscoveryEVSE(props) {
  const { t } = useTranslation();
  const [userLocation, setUserLocation] = useState(null);
  const [zoom, setZoom] = useState(4.8);
  const latitude = process.env.REACT_APP_COUNTRY_LATITUDE;
  const longitude = process.env.REACT_APP_COUNTRY_LONGITUDE;
  // console.log("latitude: " + latitude + ", longitude: " + longitude);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        // console.log(position)
        const { latitude, longitude } = position.coords;
        setUserLocation({ lat: latitude, lng: longitude });
        setZoom(4.3);
      },
      (error) => {
        // console.error('Error getting user location:', error);
        let myposition = {
          lat: Number(latitude),
          lng: Number(longitude)
        }
        setUserLocation(myposition);
        setZoom(4.3);
      }
    )
  }, [])

  useEffect(() => {
    props.all();
  }, []);

  return (
    <Container fluid className="pb-4">
      <PureBreadcrumbs />
      <br />
      <Row className="mt-1">
        <Col>
          <Card className="customercard">
            <Card.Text style={{ fontSize: "18px", fontWeight: "700" }}>{t("DISCOVERY LOCATION")}</Card.Text>
            <div style={{ height: "81vh", width: "100%" }}>
              <DiscoveryMap stations={props.list?.data} center={userLocation} zoom={zoom} />
            </div>
          </Card>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <Card className="customercard">
            <div className="table-header">{t("Discovery EVSE List")}</div>
            {props.loading ? <h3 style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "250px",
            }}>Loading data...</h3> :
              props.list && <DiscoveryEVSEList {...props}></DiscoveryEVSEList>}
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.evse.isEvseLoading,
    list: state.evse.allevse,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    all: () => dispatch(allEvseAction()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewDiscoveryEVSE);
