
export const ApiHandler = async (url, options, timeout = 30000, maxRetries = 1) => {
    let attempts = 0;

    while (attempts <= maxRetries) {
        const controller = new AbortController();
        const { signal } = controller;
        const fetchOptions = { ...options, signal };
        const functionName = options.functionName;
        // console.log("options", options)

        const timeoutId = setTimeout(() => controller.abort(), timeout);

        try {
            const response = await fetch(url, fetchOptions);

            if (!response.ok) {
                throw new Error(`Error: ${response.status} ${response.statusText}`);
            }

            return await response.json();
        } catch (error) {
            if (error.name === 'AbortError') {
                console.error(`API Error: Request timed out - for this Api : ${functionName}`);
            } else {
                console.error('API Error:', error);
            }

            // Retry logic
            if (attempts < maxRetries) {
                console.log(`Retrying... Attempt ${attempts + 1} of ${maxRetries}`);
            } else {
                return null; // Return null if max retries reached
            }
        } finally {
            clearTimeout(timeoutId);
        }

        attempts++;
    }
};

