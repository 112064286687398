import React from "react";
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Switch from 'react-bootstrap/esm/Switch';
import 'bootstrap/dist/css/bootstrap.min.css';
import AuthComponent from "./components/auth/login/Login";
import Geocode from "react-geocode";
import ChangePassword from "./components/auth/changePass";
import "./App.css"
import ForgetPassword from "./components/auth/forgetPassword/forget_password";
import Forget_Otp from "./components/auth/forgetPassword/forget_otp";
import NewDashboardLayout from "./components/dashboard/newLayout";


// console.log("geocode", Geocode)
// Private Route
Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY)
Geocode.setLanguage("en");
Geocode.setLocationType("ROOFTOP");
Geocode.enableDebug();

// GetTranslation()
export const PrivateRoute = ({ component: Component, ...rest }) => (

  <Route {...rest} render={props => (
    localStorage.getItem('user')
      ? <Component {...props} />
      : <Redirect to={{ pathname: '/', state: { from: props.location } }} />
  )} />
)
function App(props) {

  return (
    <div>
      <Switch style={{ padding: "0%" }}>
        <Route exact path="/" component={AuthComponent} />
        <Route exact path="/changepassword" component={ChangePassword} />
        <Route exact path="/forget_password" component={ForgetPassword} />
        <Route exact path="/forget_otp" component={Forget_Otp} />
        <PrivateRoute path="/dashboard" component={NewDashboardLayout} />
      </Switch>
    </div>

  )
}


const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.user
  }
}
export default connect(mapStateToProps)(App)

