import React, { useEffect } from 'react'
import { useForm } from "react-hook-form";
import { Card, Row, Col, Container } from "react-bootstrap";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { connect } from "react-redux";
import { useState } from "react";
import PureBreadcrumbs from "../../../breadcrums";
import { geocodeByAddress, getLatLng, } from 'react-places-autocomplete';
import Geocode from "react-geocode";
import { saveRoamingPartner } from "../../../../../store/actions/ocpiAction";
import { useTranslation } from 'react-i18next';
import { Bounce, ToastContainer, toast } from 'react-toastify';

const AddOCPIPartner = (props) => {
    const [address, setAddress] = useState("")
    const { t } = useTranslation();
    const [isToastVisible, setIsToastVisible] = useState(false);

    const handleAddressChange = address => {
        setAddress(address);
    };
    // GeoLocation logic
    const handleSelect = address => {
        geocodeByAddress(address).then(results => getLatLng(results[0])).then(latLng => {
            const { lat, lng } = latLng
            const res = latiLongi(lat, lng)
            res.then(respon => {
                //const address = respon.results[0].formatted_address;
                setAddress(address)
                setValue("address", address);
                setValue("latitude", lat);
                setValue("longitude", lng);
                let city, state, country, postCode;
                for (let i = 0; i < respon.results[0].address_components.length; i++) {
                    for (let j = 0; j < respon.results[0].address_components[i].types.length; j++) {
                        switch (respon.results[0].address_components[i].types[j]) {
                            case "locality":
                                city = respon.results[0].address_components[i].long_name;
                                setValue("city", city)
                                break;
                            case "administrative_area_level_1":
                                state = respon.results[0].address_components[i].long_name;
                                setValue("state", state)
                                break;
                            case "country":
                                country = respon.results[0].address_components[i].long_name;
                                // console.log(country)
                                setValue("country", country)
                                break;
                            case "postal_code":
                                postCode = respon.results[0].address_components[i].long_name;
                                setValue("Zipcode", postCode)
                                break;
                            default:
                        }
                    }
                }
            })

        })
    }

    const addPartnerSchema = Yup.object().shape({
        partner_name: Yup.string().required('Name is required'),
        // country_code: Yup.string(),
        // address: Yup.string(),
        // city: Yup.string(),
        // state: Yup.string(),
        // country: Yup.string(),
        party_id: Yup.string(),
        url: Yup.string().required('URL ID is required'),
        token_a: Yup.string().required('TokenA is required'),
    });

    const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm({ resolver: yupResolver(addPartnerSchema) });


    const onSubmit = async data => {
        // console.log(data);
        props.savePartner(data)
    }

    useEffect(() => {
        if (props.save.statuscode === 1000) {
            setIsToastVisible(true);
            toast.success('New Roaming partner has been added.', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    window.location.reload();
                },
            });
        } else if (props.save.statuscode === 405 || props.save.statuscode === 2000 || props.save.statuscode === 201 || props.save.statuscode === 2001) {
            setIsToastVisible(true);
            toast.error(`❌ Error while creating roaming partner`, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    // window.location.reload();
                },
            });
        }
    }, [props.save]);

    // if (props.save.statuscode === 200) {
    //     alert("New Roaming partner has been added.")
    //     window.location.reload();
    // }

    return (
        <>
            <Container fluid>
                {isToastVisible && <div className="overlay" />}
                <ToastContainer
                    position="top-center"
                    autoClose={20}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                    style={{ top: "10%", width: "auto", zIndex: "9999" }}
                />
                <PureBreadcrumbs />
                <br />
                <Row>
                    <Col xs={12} md={1}>
                    </Col>
                    <Col xs={12} md={10}>
                        <br />
                        <Card className="tariffcard">
                            <Card.Text style={{ fontSize: "14px", padding: "10px", paddingLeft: "15px" }}><b>Create New Roaming Partner</b></Card.Text>
                            <Card.Body>
                                <form onSubmit={e => e.preventDefault()}>
                                    <div className="form-floating">
                                        <label>Partner Name <span className='mandatory-field'>*</span></label>
                                        <input type="text" autoFocus className={`form-control gray-border ${errors.partner_name ? 'is-invalid' : ''}`} name="partner_name" id="floatingInputGridpartner_name" placeholder="Enter Name" {...register("partner_name")} />
                                        <div className="invalid-feedback">{errors.partner_name?.message}</div>
                                    </div>


                                    <div className="form-floating py-2">
                                        <label>URL <span className='mandatory-field'>*</span></label>
                                        <input type="text" className={`form-control gray-border ${errors.url ? 'is-invalid' : ''}`} name="url" id="floatingInputGridurl" placeholder="Enter OCPI URL" {...register("url")} />
                                        <div className="invalid-feedback">{errors.url?.message}</div>
                                    </div>
                                    <div className="form-floating py-2">
                                        <label>Party ID</label>
                                        <input type="text" className={`form-control gray-border ${errors.party_id ? 'is-invalid' : ''}`} name="party_id" id="floatingInputGridurl" placeholder="Enter Party ID" {...register("party_id")} />
                                        <div className="invalid-feedback">{errors.party_id?.message}</div>
                                    </div>
                                    <div className="form-floating py-2">
                                        <label>Token<span className='mandatory-field'> *</span></label>
                                        <input type="text" className={`form-control gray-border ${errors.token_a ? 'is-invalid' : ''}`} name="token_a" id="floatingInputGridurl" placeholder="Enter Token" {...register("token_a")} />
                                        <div className="invalid-feedback">{errors.token_a?.message}</div>
                                    </div><br />
                                    <div >
                                        <Row >
                                            {/* <Col>
                                                <button className="lgn-btn btn" style={{ width: "13rem", height: "auto" }} type="button" onClick={() => {
                                                    reset({
                                                        partner_name: '', country_code: '', address: setAddress(''), city: '', state: '', country: '',
                                                        party_id: '', url: '',token_a:''
                                                    });
                                                }}> Reset </button>
                                            </Col><br /> */}
                                            <Col className='d-flex justify-content-end'>
                                                {props.loading ? <button className="btn lgn-btn" style={{ width: "auto", height: "auto" }} type="button" disabled>
                                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                    &nbsp;
                                                    {t("Saving...")}
                                                </button> :
                                                    <button className="lgn-btn btn" style={{ width: "auto", height: "auto" }} type="submit" onClick={handleSubmit(onSubmit)}>Save Roaming partner</button>
                                                }
                                            </Col>
                                        </Row>

                                    </div><br />
                                </form>
                            </Card.Body>
                            <br /> <br />
                        </Card>

                    </Col>
                    <Col xs={12} md={1}>
                    </Col>
                </Row>

            </Container>
        </>
    )
}


const mapStateToProps = (state) => {
    return {
        loading: state.ocpi.isLoading,
        save: state.ocpi.savepartner
    }
}
const mapDispatchToProps = dispatch => {
    return {
        savePartner: (data) => dispatch(saveRoamingPartner(data))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddOCPIPartner)

const latiLongi = (a, b) => {
    return Geocode.fromLatLng(a, b).then(res => { return res })
    // console.log("Gaadin",res); 
}