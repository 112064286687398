import React from 'react';
import { Card, Row, Col, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { useEffect } from "react";
import PureBreadcrumbs from "../../breadcrums";
import "react-datepicker/dist/react-datepicker.css";
import "../loyalty.css";
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { fetchSingleProgramApi } from '../../../../store/actions/loyaltyAction';
import moment from 'moment';

const ViewSingleProgram = (props) => {
    const { t } = useTranslation();
    const { program_name } = useParams();

    useEffect(() => {
        props.fetchSingle(program_name);
    }, [program_name, props]);

    const data = props.fetchSingleRes?.data?.[0] || {};

    return (
        <Container fluid>
            <PureBreadcrumbs />
            <br />
            <Row>
                <Col xs={12} md={1}></Col>
                <Col xs={12} md={10}>
                    <Card className="tariffcard" style={{ height: "30rem" }}>
                        <Card.Text style={{ fontSize: "14px", padding: "10px", paddingLeft: "15px" }}>
                            <b>{t("View Single Program")}</b>
                        </Card.Text>
                        <Card.Body>
                            <form onSubmit={e => e.preventDefault()}>
                                <Row>
                                    <Col>
                                        <div className="form-floating">
                                            <label>{t("Program Name")}  <span className='mandatory-field'>*</span></label>
                                            <input
                                                type="text"
                                                className="form-control gray-border"
                                                name="program_name"
                                                id="floatingInputGridProgramName"
                                                placeholder={t("Enter the program name")}
                                                value={data.program_name || ''}
                                                disabled
                                            />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="form-floating">
                                            <label>{t("Rule Name")}  <span className='mandatory-field'>*</span></label>
                                            <input
                                                type="text"
                                                className="form-control gray-border"
                                                name="rule_name"
                                                id="floatingInputGridrule_name"
                                                placeholder={t("Enter rule name")}
                                                value={data.rule_name || ''}
                                                disabled
                                            />
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="mt-5">
                                    <Col className="text-center">
                                        {t("Selected Start Date")} :
                                    </Col>
                                    <Col>
                                        <input
                                            className='gray-border'
                                            value={data.startdate ? moment(data.startdate).format("DD/MM/YYYY") : ''}
                                            disabled
                                            style={{ width: "max-content" }}
                                        />
                                    </Col>
                                </Row><br />
                                <Row>
                                    <Col className="text-center">
                                        {t("Selected End Date")} :
                                    </Col>
                                    <Col>
                                        <input
                                            className='gray-border'
                                            value={data.enddate ? moment(data.enddate).format("DD/MM/YYYY") : ''}
                                            disabled
                                            style={{ width: "max-content" }}
                                        />
                                    </Col>
                                </Row><br />
                            </form>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={1}></Col>
            </Row>
        </Container>
    );
};

const mapStateToProps = (state) => {
    return {
        loading: state.loyalty.isLoading,
        fetchSingleRes: state.loyalty.fetchSingleProgram,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchSingle: (program_name) => dispatch(fetchSingleProgramApi(program_name)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewSingleProgram);
