import React from 'react'
import { connect } from "react-redux"
import { fetNetworkAction, fetchStationV2EvseAction, allChargerStatusAction, allConnectorTypeAction, allParkingTypeAction, allPowerRangeAction } from "../../../../store/actions/evseAction"
import { fetchAmenityAction } from "../../../../store/actions/mainAction"
import { allTariffAction, fetchAllTimeSlotAction } from "../../../../store/actions/tariffAction"
import { allOrgTariffAction } from "../../../../store/actions/organisationAction"
import UpdateStationv2Component from "../viewevse/update_station"
import { useParams } from "react-router-dom"
import { useEffect } from "react"

const EVSEUpdateMasterComponent = (props) => {
    const { stationid } = useParams()
    useEffect(() => {
        props.fetch(stationid);
        props.getamenity()
        props.all()
        props.getNetwork()
        props.getOrg()
        props.getAllParkingType();
        props.getAllConnector();
        props.getAllPowerRange();
        props.getAllTimeofUseList();
        props.getAllChargerStatus();
    }, [stationid]);
    return (
        <>
            {props.fetchstation && props.amenities && props.tarifflist && props.list.organisations && props.nlist && stationid &&

                // <UpdateEVSE {...props}></UpdateEVSE>
                <UpdateStationv2Component {...props}></UpdateStationv2Component>
            }
        </>
    )

}

const mapStateToProps = (state) => {
    return {
        loadingevse: state.evse.isEvseLoading,
        fetchstation: state.evse.viewstation,
        amenities: state.main.amenities,
        tarifflist: state.tariff.list,
        list: state.operator.organisationsList,
        nlist: state.evse.network,
        allParkingtype: state.evse.fetchparkingtype,
        allConnectortype: state.evse.fetchconnectortype,
        allPowerrange: state.evse.fetchpowerrange,
        allChargerStatus: state.evse.fetchchargerstatus,
        fetchTariffList: state.tariff.timeSlotFetch,
    }
}


const mapDispatchToProps = dispatch => {
    return {
        fetch: (stationid) => dispatch(fetchStationV2EvseAction(stationid)),
        getamenity: () => dispatch(fetchAmenityAction()),
        all: () => dispatch(allTariffAction()),
        getOrg: () => dispatch(allOrgTariffAction()),
        getNetwork: () => dispatch(fetNetworkAction()),
        getAllConnector: () => dispatch(allConnectorTypeAction()),
        getAllParkingType: () => dispatch(allParkingTypeAction()),
        getAllPowerRange: () => dispatch(allPowerRangeAction()),
        getAllChargerStatus: () => dispatch(allChargerStatusAction()),
        getAllTimeofUseList: () => dispatch(fetchAllTimeSlotAction()),

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EVSEUpdateMasterComponent)