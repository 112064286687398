import React from 'react';
import jsPDF from "jspdf";
import "jspdf-autotable";

// Create Document Component
const MyOwnerDocument = (props, operator) => {
  const gstNumber = process.env.REACT_APP_CLIENT_GST_NO;

  const generatePDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(12);
    const { organisations } = props.orgdata
    // console.log(organisations)
    // console.log(props)
    //  console.log(operator)
    const organisation = organisations && organisations.filter(or => or.name == props.operator)
    const { address, city, email, phone } = organisation[0]
    const range = "Date range: " + props.start.toLocaleString() + " to " + props.end.toLocaleString()
    const title = "Organisation: " + props.operator;
    const org_add = "Address: " + address;
    const org_city = "City: " + city
    const org_email = "Email: " + email
    const org_ph = "Phone: " + phone.toString()
    const gst_number = "GST Number: " + gstNumber;
    const consumed_kwh = "Consumed kWh: " + props.energy
    const rfid_free_charging_energy = "Free charging consumed kWh: " + props.reportCountRes?.free_charging_total_energy
    const free_charging_energy = props.reportCountRes?.free_charging_total_energy;
    const total_kwh = `Total kWh ${(props.reportCountRes?.total_energy - free_charging_energy).toFixed(2)}`;
    const headers = [["ID",
      "Date",
      "Tag ID",
      "Station Name",
      "City",
      "Start time",
      "End time",
      "Charging time (HH:MM:SS)",
      "Is free charging?",
      "Total kWh"
    ]];
    const data = props.csvDataRes.data.map(elt =>
      [elt.charging_id,
      elt.end_date,
      elt.idtag,
      elt.stationid,
      elt.city,
      elt.start_time,
      elt.stop_time,
      elt.consume_time,
      elt.free_charging ? "Yes" : "No",
      elt.kwh
      ]);
    let content = {
      startY: 200,
      head: headers,
      body: data
    };
    doc.text(title, marginLeft, 40);
    doc.text(org_add, marginLeft, 55);
    doc.text(org_city, marginLeft, 70);
    doc.text(org_email, marginLeft, 85);
    doc.text(org_ph, marginLeft, 100);
    doc.text(range, marginLeft, 120);
    doc.text(consumed_kwh, marginLeft, 140);
    doc.text(rfid_free_charging_energy, marginLeft, 160);
    doc.text(total_kwh, marginLeft, 180);
    doc.text(gst_number, marginLeft, 25);
    doc.autoTable(content);
    doc.save(props.operator + "_" + props.start.toISOString().substr(0, 10) + "_" + props.end.toISOString().substr(0, 10) + `.pdf`)
  }
  const handleButtonClick = async () => {
    // Generate the PDF
    generatePDF();
    // props.onClick();
  };

  return (
    <div>
      <button className='pdfbutton' onClick={handleButtonClick} type="button">Download PDF</button>
    </div>
  )
}

export default MyOwnerDocument;