import React, { useState } from 'react';
import { connect } from "react-redux";
import "./login.css";
import ReCAPTCHA from "react-google-recaptcha"
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Row, Card } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import { authAction } from '../../../store/actions/authAction';
import { useTranslation } from 'react-i18next';


function AuthComponent(props) {

    const { t } = useTranslation();
    function onChange(value) {
        //console.log('Captcha value:', value);
    }
    const [passwordType, setPasswordType] = useState("password");

    const loginSchema = Yup.object().shape({
        username: Yup.string().required('Username is required').min(6, 'username must be at least 6 characters').max(20, 'username must not exceed 20 characters'),
        password: Yup.string().required('Password is required').min(6, 'Password must be at least 6 characters').max(40, 'Password must not exceed 40 characters')
    });

    const { register, handleSubmit, reset, formState: { errors } } = useForm({ resolver: yupResolver(loginSchema), mode: "all" });
    const onSubmit = async data => {
        props.signIn(data.username, data.password)
    };
    if (props.response.statuscode === 200 && (props.response.ispasswordupdated === false)) {
        return <Redirect to='/changepassword' />;
    }
    // console.log("response",props.response)

    if (props.response && props.response.statuscode === 200 && (props.response.ispasswordupdated && props.response.error === '')) {
        return <Redirect to='/dashboard' />;
    }

    if (props.response.statuscode === 404) {
        alert(props.response.error);
        window.location.reload();
    }

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }

    return (

        <div className='login-background'>
            <Card className='box-form'>
                <Card.Body>
                    <Row className='d-flex justify-content-center'>
                        <span className='login'>
                            {t("Login")}
                            <hr />
                        </span>
                    </Row>
                    <br />
                    <br />
                    <form onSubmit={e => e.preventDefault()}>
                        <div className="input_box">
                            <div className="form-floating mb-4">
                                <input type="text" autoFocus className={`form-control ${errors.username ? 'is-invalid' : ''}`} id="floatingloginInputUsername" name="username" placeholder={t("Enter Username")} aria-label={t("Enter Username")} {...register("username")} />
                                <div className="invalid-feedback">{errors.username?.message}</div>
                            </div>

                            <div className="form-floating">
                                <input type={passwordType} className={`form-control ${errors.password ? 'is-invalid' : ''}`} id="floatingloginInputPassword" placeholder={t("Enter Password")} name="password" aria-label={t("Enter Password")} {...register("password")} />
                                <div className="invalid-feedback">{errors.password?.message}</div>
                            </div>

                            <div className='mt-3 d-flex justify-content-end '>
                                <a href="/forget_otp" className="forgetpass_style">{t("Forget Password")}?</a>
                            </div>
                            <br />
                            <Row className='d-flex justify-content-center'>
                                {props.loading ? <button className="lgn-btn" disabled type="submit" >{t("Loading")}</button>
                                    : <button className="lgn-btn" type="submit" onClick={handleSubmit(onSubmit)}>{t("Login")}</button>
                                }
                            </Row>
                            <Row className='logo-css'>
                                <img className='logofooter' src={process.env.PUBLIC_URL + '/images/c_logo.png'} alt='Gaadin logo' />
                            </Row>
                        </div>
                    </form>
                </Card.Body>
            </Card>

            <ReCAPTCHA
                sitekey="6LdV_tIjAAAAAJV3kh4BTKbwWaS7ydA-Iqoy2-1N"
                onChange={onChange}
                size="invisible"
            />
        </div>


    )
}


const mapStateToProps = (state) => {
    return {
        loading: state.auth.isLoading,
        response: state.auth.user,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        signIn: (username, password) => dispatch(authAction(username, password))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthComponent)