const initState = {
    isLoading: false,
    org_save: '',
    organisationsList: '',
    singleOrgTariff: '',
    updateorg: '',
    savepromotion: '',
    allpromotion: '',
    savecorporatecode: '',
    allcorporatecode: '',
    revenueShare: '',
    allOrganisation: '',
    allOrg: '',
    allOrgPercentage:'',
    activePromotion: '',
    deactivePromotion: '',
    activateCorpCode: '',
    deactivateCorpCode: '',
    updatePromotion: '',
    updateCorpCode: '',
}

const organisationReducer = (state = initState, action) => {
    switch (action.type) {
        case 'LOAD_ORG':
            return { ...state, isLoading: true }
        case 'SAVE_ORG':
            return { ...state, org_save: action.payload, isLoading: false };
        case 'LOAD_ACTIVE_ORG_LIST':
            return { ...state, organisationsList: action.payload, isLoading: false };
        case 'LOAD_ALL_ORG_LIST':
            return { ...state, allOrg: action.payload, isLoading: false };
        case 'SINGLE_ORG_TARIFF':
            return { ...state, singleOrgTariff: action.payload, isLoading: false };
        case 'UPDATE_ORG':
            return { ...state, updateorg: action.payload, isLoading: false };
        case 'SAVE_ORG_PROMOTION_CODE':
            return { ...state, savepromotion: action.payload, isLoading: false };
        case 'SAVE_ORG_CORPORATE_CODE':
            return { ...state, savecorporatecode: action.payload, isLoading: false };
        case 'FETCH_ORG_PROMOTION_CODE':
            return { ...state, allpromotion: action.payload, isLoading: false };
        case 'FETCH_ORG_CORPORATE_CODE':
            return { ...state, allcorporatecode: action.payload, isLoading: false };
        case 'FETCH_REVENUE_SHARE':
            return { ...state, revenueShare: action.payload, isLoading: false };
        case 'FETCH_ALL_ORG_NAME':
            return { ...state, allOrganisation: action.payload, isLoading: false };
        case 'FETCH_ALL_ORG_Percentage':
            return { ...state, allOrgPercentage: action.payload, isLoading: false };
        case 'ACTIVATE_PROMOTIONS':
            return { ...state, activePromotion: action.payload, isLoading: false };
        case 'DEACTIVATE_PROMOTION':
            return { ...state, deactivePromotion: action.payload, isLoading: false };
        case 'ACTIVATE_CORPORATE_CODE':
            return { ...state, activateCorpCode: action.payload, isLoading: false };
        case 'DEACTIVATE_CORPORATE_CODE':
            return { ...state, deactivateCorpCode: action.payload, isLoading: false };
        case 'UPDATE_PROMOTION':
            return { ...state, updatePromotion: action.payload, isLoading: false };
        case 'UPDATE_CORPCODE':
            return { ...state, updateCorpCode: action.payload, isLoading: false };
        default:
            return state;
    }
}
export default organisationReducer;