import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import CustomerGroupingList from './customerGroupingList';
import { useTranslation } from 'react-i18next';
import PureBreadcrumbs from "../../breadcrums";


export default function ViewALlCustomerGroup(props) {
    const { t } = useTranslation()
    return (
        <Container fluid style={{ paddingBottom: "1.5rem" }} >
            <PureBreadcrumbs />
            <div className="row ">
                <div className="col-lg-12 col-sm-12 pad_t2">
                    <Card className="customercard">
                        <Row>
                            <Col lg={12}>
                                <div className="table-header">{t("Customer Group Table")}</div>
                                <CustomerGroupingList />
                            </Col>
                        </Row>
                    </Card>
                </div>
            </div>
        </Container>
    )
}