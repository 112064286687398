import { Container, Row, Col, Card } from "react-bootstrap";
import PureBreadcrumbs from "../../breadcrums";
import React, { useEffect, useState } from "react"
import { connect } from "react-redux";
import {allCustomerWalletAction} from "../../../../store/actions/walletActions";
import WalletList from "./walletList";
import { CSVLink } from "react-csv";

function WalletCard(props){
 
    const [startDate, setStartDate] = useState(new Date(Date.now() - (3600 * 1000 * 360)));
    const [endDate, setEndDate] = useState(new Date(Date.now() + (3600 * 1000 * 24)));
    const [org, setOrg] = useState("All")
    useEffect(() => {
        props.getWallet(startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10))
    }, [])

    return(
        <Container fluid>
        <PureBreadcrumbs />
        <br />
        <div class="row">
            <div class="col-lg-3 col-sm-12">
                <Card className="customercard">
                    <div style={{ textAlign: "left" }}>
                        <Card.Text  style={{fontSize:"14px"}}>CUSTOMERS</Card.Text>
                        <Card.Title>
                            <b>{props.wallets.total_customer}</b>
                        </Card.Title>
                        <span  style={{fontSize:"11px"}}>0.0% change in last 30 days</span>
                        <span style={{ float: "right", marginTop: "-45px" ,marginRight:"-15px"}}>
                            <div style={{width:"50%"}} >
                            <img src={process.env.PUBLIC_URL + '/images/customer/totalcustomer.svg'} alt="customer" />
                            </div>
                        </span>
                    </div>
                </Card><br />
            </div>
            <div class="col-lg-3 col-sm-12">
            <Card className="customercard">
                    <div style={{ textAlign: "left" }}>
                        <Card.Text  style={{fontSize:"14px"}}>WALLET CREDIT</Card.Text>
                        <Card.Title>
                            <b>{props.wallets.total_credit}</b>
                        </Card.Title>
                        <span style={{fontSize:"11px"}}>0.0% change in last 30 days</span>
                        <span style={{ float: "right", marginTop: "-45px" ,marginRight:"-15px"}}>
                            <div style={{width:"50%"}} >
                            <img src={process.env.PUBLIC_URL + '/images/customer/walletcredit.svg'} alt="customer" />
                            </div>
                        </span>
                    </div>
                </Card><br />
            </div>
            <div class="col-lg-3 col-sm-12">
            <Card className="customercard">
                    <div style={{ textAlign: "left" }}>
                        <Card.Text  style={{fontSize:"14px"}}>DEBIT</Card.Text>
                        <Card.Title>
                            <b>{props.wallets.total_debit}</b>
                        </Card.Title>
                        <span style={{fontSize:"11px"}}>0.0% change in last 30 days</span>
                        <span style={{ float: "right", marginTop: "-45px" ,marginRight:"-15px"}}>
                            <div style={{width:"50%"}} >
                            <img src={process.env.PUBLIC_URL + '/images/customer/walletdebit.svg'} alt="customer" />
                            </div>
                        </span>
                    </div>
                </Card><br />
            </div>
            <div class="col-lg-3 col-sm-12">
            <Card className="customercard">
                    <div style={{ textAlign: "left" }}>
                        <Card.Text style={{fontSize:"14px"}}>AVAILABLE</Card.Text>
                        <Card.Title>
                            <b>{props.wallets.total_available}</b>
                        </Card.Title>
                        <span style={{fontSize:"11px"}}>0.0% change in last 30 days</span>
                        <span style={{ float: "right", marginTop: "-45px" ,marginRight:"-15px"}}>
                            <div style={{width:"50%"}} >
                            <img src={process.env.PUBLIC_URL + '/images/customer/walletavailable.svg'} alt="customer" />
                            </div>

                        </span>
                    </div>
                </Card>
            </div>
        </div>

        <Row className="mt-4">
            <Col>
          
            {/* <div class="d-flex justify-content-between w-100">
            <p>Customer Wallet List</p> 
            <p>
            <div>
                {props.transaction && props.transaction.data != null ? <CSVLink data={data} 
                    filename={org + "_" + startDate.toISOString().substr(0, 10) + "_" + endDate.toISOString().substr(0, 10) + `.csv`}
                target="_blank"
                >
                 <i className="fas fa-2x fa-file-csv"></i>
                </CSVLink> : <i className="fas fa-2x fa-file-csv"></i>
               }
            </div></p>
            </div> */}
            <div className="spacer"></div>
           
                <WalletList />
            </Col>
        </Row>
    </Container>

    )

}


const mapStateToProps = (state) => {
    return {
     wallets:state.wallet.fetchAll,
     loading: state.wallet.isLoading,
    }
  }
  const mapDispatchToProps = dispatch => {
    return {
      getWallet:(start, end)=>dispatch(allCustomerWalletAction(start, end))
    
      }
  }
  export default connect(mapStateToProps, mapDispatchToProps)(WalletCard)
