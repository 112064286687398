import React from 'react';
import './ticketManagement.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const steps = [
  { id: '1', title: 'Open', status: 'Open' },
  { id: '2', title: 'In Progress', status: 'In Progress' },
  { id: '3', title: 'Closed', status: 'Closed' },
];
const StepProgress = ({ currentStatus }) => {

  return (
    <div className="step-progress-container">
      <div className="step-progress-bar">
        {steps.map((step, index) => (
          <div
            key={index}
            className={`step ${step.status === currentStatus ? 'active' : ''} `}
          >

            <div className="step-circle">
              <b>{step.id}</b>
              <div className={`step-line ${step.status === 'Closed' ? 'closed-step' : ''}`}> </div>
            </div>

            <span className="step-title">{step.title}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StepProgress;
