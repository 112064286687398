import React, { useEffect, useMemo, useState } from "react"
import { connect } from "react-redux";
import "../reacttable/reactTable.css"
import Table from "../reacttable/table";
import Popup from 'reactjs-popup';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { fetchChargerChargingProfileKindLoadForAction, fetchChargerChargingProfileLimitLoadForAction, fetchChargerChargingProfileLisLoadForAction, updateChargingProfileLoadAction } from "../../../store/actions/loadAction";
import { Col } from "react-bootstrap";
import { useParams, Redirect } from "react-router-dom";
import { ImCross } from "react-icons/im";

function StationLoadList(props) {
  const [open, setOpen] = useState(false);
  const [cp, setCP] = useState('')

  const chargingProfileSchema = Yup.object().shape({
    chargingrateunit: Yup.string().required('Charging rate per unit is required'),
    charging_profile_purpose: Yup.string().required('Charging Purpose is required'),
    charging_profile_kind: Yup.string().required('Charging profile kind is required'),
    start_period: Yup.number().required('Time period is a required').typeError('Time period is a required'),
    limit: Yup.number().required('Charging Limit is a required').typeError('Charging Limit is a required'),
  });

  const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({ resolver: yupResolver(chargingProfileSchema) });
  const onSubmit = async data => {
    props.updateChargingProfileLoad(data)
    setOpen(false)
  };

  useEffect(() => {
    // props.fetchLoadChargingProfileList()
    // props.fetchLoadChargingProfileKindList()
    // props.fetchLoadChargingProfileLimitList()
  }, [])

  // console.log("props", props)

  const handleShow = (cpdata) => {
    setOpen(true);
    setValue("evseid", cpdata.evse_id, { shouldValidate: true });
    setValue("portno", cpdata.portno, { shouldValidate: true });
    register('evseid', cpdata.evse_id)
    register('portno', cpdata.portno)
  }
  const closeModal = () => {
    window.location.reload();
  };
  const data = props.loadChargerListRes && props.loadChargerListRes.stations.charger;
  // console.log(data)

  if (props.chargingProfileUpdate.statuscode === 200) {
    alert("Charging Profile has been Updated successfully")
  }

  const Connector = ({ values }) => {
    return (
      <>
        {values?.map((cpn, idx) => {
          return (
            <span key={idx} className="badge rounded-pill">
              <button type="button" className="btn btn-outline-dark" onClick={() => handleShow(cpn)}>{cpn.portno}</button>
            </span>
          );
        })}
      </>
    );
  };

  const columns = useMemo(

    () => [
      {
        Header: 'Charge Box Id',
        accessor: 'evse_id'
      },
      {
        Header: 'Total Connector',
        accessor: 'total_connector',
      },
      {
        Header: 'Connector Type',
        accessor: 'powertype',
        // Cell: ({ cell: { value } }) => <Connector values={value} />
      },
      {
        Header: 'Charger Max Capacity',
        accessor: 'chargercapacity',

      },
      {
        Header: 'Charger Current Load',
        accessor: 'current_load',
      },
      {
        Header: 'Charger Define Load',
        accessor: 'define_load',
      },
      {
        Header: 'Charger Status',
        accessor: 'status'
      },
    ],
    []
  );

  // console.log(props.loading);
  return (
    <div>
      {props.loading ? <h3 style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "250px",
      }}>Loading data...</h3> :
        data && data.length > 0 ?
          (<div className="">
            <Table data={data} columns={columns}></Table>
          </div>)
          :
          (<div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "200px" }}>
            <h3 className="loader">No data found</h3>
          </div>)
      }

      <Popup
        open={open}
        onClose={closeModal}
        contentStyle={{
          borderRadius: "10px",
          // padding: "20px",
          width: "50%",
          // margin: "auto",
          height: "95%",
        }}
        closeOnDocumentClick={false}
      >
        {(close) => (
          <>
            <div style={{ textAlign: "right" }}>
              <button className="close-btn" onClick={closeModal}>
                <span><ImCross></ImCross></span>
              </button>
            </div>

            <form onSubmit={e => e.preventDefault()}>
              <div className="container">
                <div className="row">

                  <div className="col-12">
                    <h2 className="text-center p-3">Update Charger Charging Profile</h2>
                    <div className="form-floating mb-3">
                      <input type="text" className={`form-control gray-border ${errors.evseid ? 'is-invalid' : ''}`} id="floatingInput" name="evseid" disabled  {...register("evseid")} />
                      <div className="invalid-feedback">{errors.evseid?.message}</div>
                    </div>
                    <div className="form-floating mb-3">
                      <input type="number" className={`form-control gray-border ${errors.portno ? 'is-invalid' : ''}`} id="floatingInput" name="portno" disabled {...register("portno")} />
                      <div className="invalid-feedback">{errors.portno?.message}</div>
                    </div>
                    <div className="form-floating mb-3">
                      <input type="text" className={`form-control gray-border ${errors.chargingrateunit ? 'is-invalid' : ''}`} id="floatingInput" name="chargingrateunit" placeholder="Enter The Charging Rate Per Unit" {...register("chargingrateunit")} />
                      <div className="invalid-feedback">{errors.chargingrateunit?.message}</div>
                    </div>
                    <div className="form-floating mb-3">

                      <select className={`form-select form-control ${errors.charging_profile_purpose ? 'is-invalid' : ''}`} name="charging_profile_purpose" id="floatingSelectGridProfile" {...register("charging_profile_purpose")}>
                        <option value="">Select Charging Profile</option>
                        {props.chargingProfileMaster.data && props.chargingProfileMaster.data.map((v) =>
                          <option value={v.profiletype}>{v.profiletype}</option>
                        )}
                      </select>

                      <div className="invalid-feedback">{errors.charging_profile_purpose?.message}</div>
                    </div>
                    <div className="form-floating mb-3">
                      <select className={`form-select form-control ${errors.charging_profile_kind ? 'is-invalid' : ''}`} name="charging_profile_kind" id="floatingSelectGridKind" {...register("charging_profile_kind")}>
                        <option value="">Select Charging Profile</option>
                        {props.chargingProfileKindMaster.data && props.chargingProfileKindMaster.data.map((v) =>
                          <option value={v.profiletype}>{v.profiletype}</option>
                        )}
                      </select>
                      <div className="invalid-feedback">{errors.charging_profile_kind?.message}</div>
                    </div>

                    <div className="form-floating mb-3">
                      <select className={`form-select form-control ${errors.start_period ? 'is-invalid' : ''}`} name="start_period" id="floatingSelectGridKind" {...register("start_period")}>
                        <option value="">Select Charging Profile</option>
                        {props.chargingProfileLimitMaster.limit && props.chargingProfileLimitMaster.limit.map((v) =>
                          <option value={v.sec}>{v.minute}</option>
                        )}
                      </select>
                      <div className="invalid-feedback">{errors.start_period?.message}</div>
                    </div>

                    <div className="form-floating mb-3">
                      <input type="number" className={`form-control gray-border ${errors.limit ? 'is-invalid' : ''}`} id="floatingInput" name="chargingrateunit" placeholder="Enter The Charging Limit Price" {...register("limit")} />
                      <div className="invalid-feedback">{errors.limit?.message}</div>
                    </div>

                    <div style={{ position: "relative" }} className="d-grid gap-2 d-md-flex justify-content-md-between ">
                      <Col >

                        <button className="btn lgn-btn" type="button" onClick={() => { window.location.reload() }}>Close</button>
                      </Col>
                      <Col className='d-flex justify-content-end'>

                        <button className="btn lgn-btn" type="submit" onClick={handleSubmit(onSubmit)}>Save Changes</button>
                      </Col>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </>
        )}
      </Popup>

    </div>
  )
}


const mapStateToProps = (state) => {
  return {
    chargingProfileMaster: state.load.profileList,
    chargingProfileKindMaster: state.load.kindList,
    chargingProfileLimitMaster: state.load.limitList,
    chargingProfileUpdate: state.load.updateChargingProfile,
    loading: state.load.isFleetLoading,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    fetchLoadChargingProfileList: () => dispatch(fetchChargerChargingProfileLisLoadForAction()),
    fetchLoadChargingProfileKindList: () => dispatch(fetchChargerChargingProfileKindLoadForAction()),
    fetchLoadChargingProfileLimitList: () => dispatch(fetchChargerChargingProfileLimitLoadForAction()),
    updateChargingProfileLoad: (data) => dispatch(updateChargingProfileLoadAction(data))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(StationLoadList)