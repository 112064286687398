import { Container, Row, Col, Card } from "react-bootstrap";
import PureBreadcrumbs from "../../breadcrums";
import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { activateCORPCODE, allCorporateCodeAction, deactivateCORPCODE } from "../../../../store/actions/organisationAction";
import "../../reacttable/reactTable.css";
import Table from "../../reacttable/table";
import moment from "moment";
import { FaEdit } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useTranslation } from "react-i18next";

function ViewCorporateCode(props) {
  const {t} = useTranslation();
  useEffect(() => {
    props.all();
  }, []);


  const data = props.allcorporatecode && props.allcorporatecode.code;
  // console.log(data);

  const activateStation = (corporate_code) => {
    // console.log(corporate_code);
    props.activateCorp(corporate_code);
  };

  if(props.activeCorp.statuscode === 200){
    window.location.reload();
  }

  const deactivateStation = (corporate_code) => {
    // console.log(corporate_code);
    props.deactivateCorp(corporate_code);
  };

  if(props.deactiveCorp.statuscode === 200){
    window.location.reload();
  }

  const columns = useMemo(
    () => [
      {
        Header: "Corporate Code",
        accessor: "corporate_code",
        id:"corporate_code1"
      },
      {
        Header: "Corporate Code Name",
        accessor: "code_name",
      },
      {
        Header: "Start Date",
        id: "startdate",
        accessor: (d) => {
          return moment(d.startdate).local().format("DD-MM-YYYY");
        },
      },
      {
        Header: "End Date",
        id: "enddate",
        accessor: (d) => {
          return moment(d.enddate).local().format("DD-MM-YYYY");
        },
      },
      {
        Header: "Tariff Name",
        accessor: "tariff",
      },
      {
        Header: "Organisation",
        accessor: "assignedoperator",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: (status) => {
          // console.log(status.value)
          return (
            <div>
              <span>
                {status.value == "Active" ? (
                  <span className="text-success">Active</span>
                ) : (
                  <span className="text-danger">Inactive</span>
                )}
              </span>
            </div>
          );
        },
      },
      {
        Header: "Edit Corporate Code",
        accessor: "id",
        Cell: (id) => {
          return (
            <div>
              &nbsp;&nbsp;
              <span>
                <a
                  href={`/dashboard/updateCorporateCode/${id.value}`}
                  rel="tooltip"
                  title="Edit"
                >
                  <FaEdit />
                </a>
              </span>
            </div>
          );
        },
      },
      {
        Header: "Activate/Deactivate",
        accessor: "corporate_code",
        Cell: (corporate_code) => {
          return (
            <div>
              &nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;
              <span style={{ cursor: "pointer" }}>
                <TiTick
                  onClick={() => activateStation(corporate_code.value)}
                  title="Activate"
                />
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;
              <span style={{ cursor: "pointer" }}>
                <AiOutlineCloseCircle
                  onClick={() => deactivateStation(corporate_code.value)}
                  title="De-Activate"
                />
              </span>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <Container fluid>
      <PureBreadcrumbs />

      <Row className="mt-4">
        <Col>
          <Card className="customercard">
            <div className="table-header">{t("Corporate Code List")}</div>

            <div>
              {props.loading ? <h3 style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "250px",
              }}>Loading data...</h3> :
                data && data.length > 0 ? (
                  <div>
                    {" "}
                    <Table data={data} columns={columns}></Table>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <h3 className="loader">{t("No data found")}</h3>
                  </div>
                )}
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.operator.isLoading,
    allcorporatecode: state.operator.allcorporatecode,
    activeCorp: state.operator.activateCorpCode,
    deactiveCorp: state.operator.deactivateCorpCode,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    all: () => dispatch(allCorporateCodeAction()),
    activateCorp:(corporate_code)=> dispatch(activateCORPCODE(corporate_code)),
    deactivateCorp:(corporate_code)=> dispatch(deactivateCORPCODE(corporate_code)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewCorporateCode);
