import React, { useEffect, useState } from "react";
import ChargingImage from "../../../assets/images/liveCharging.gif";
import NotAvailable from "../../../assets/images/notCharging.png";
import { connect } from "react-redux";
import { fetchLiveChargingAction } from "../../../../store/actions/evseAction";
import "../../fleet/fleet.css";
import { LiveCharging } from "../../../../store/actions/fleetAction";
function FleetCharging(props) {
  const [data, setData] = useState([]);
  const array = Array(6).fill("");

  useEffect(() => {
    props.LiveChargingApi("All");
    const interval = setInterval(() => {
      props.LiveChargingApi("All");
    }, 300000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const liveData = props.LiveChargingRes?.data ?? [];
    setData([...liveData, ...array.slice(liveData.length)]);
  }, [props.LiveChargingRes]);

  console.log(props.LiveChargingRes, "live charging data");

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "50px",
          flexWrap: "wrap",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {data?.map((item, index) => {
          if (index <= 5) {
            if (item?.status === "Charging") {
              return (
                <>
                  <div className="tarrifcard" key={index} style={{ width: "25%" }}>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                      <p style={{ fontSize: "20px", fontWeight: "bold" }}>
                        {item.evse_id}/{item.portno}
                      </p>
                      <div
                        style={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "0px" }}
                      >
                        {item.soc !== 0 && (
                          <div style={{ fontSize: "20px", fontWeight: "bold", marginLeft: "50px" }}>{item.soc}%</div>
                        )}
                        <img src={ChargingImage} alt="Charging Stations" style={{ width: "180px" }} />
                      </div>
                      <br />
                      <div className="spinner-container">
                        <div className="spinner"></div>
                        <div className="spinner-text"> {item.kwh}kWh</div>
                      </div>
                    </div>
                  </div>
                </>
              );
            } else {
              return (
                <div key={index} style={{ width: "25%", display: "flex", justifyContent: "center" }}>
                  <img src={NotAvailable} alt="Non-Charging Stations" style={{ width: "110px" }} />
                </div>
              );
            }
          }
        })}
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.fleet.isLoading,
    LiveChargingRes: state.fleet.fleet_live_charging,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    LiveChargingApi: (fleet) => dispatch(LiveCharging(fleet)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FleetCharging);
