import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import PureBreadcrumbs from "../../breadcrums";
import { connect } from "react-redux";
import {
  activateTariff,
  deActivateTariff,
  fetchAllStaticTariffDataAction,
  fetchAllTimeSlotAction,
  percentageChangeData,
} from "../../../../store/actions/tariffAction";
import TimeTariffListComponent from "./timofuse_tariff_list";
import { useTranslation } from "react-i18next";

const TimeOfUseTariffListComponent = (props) => {
  const { t } = useTranslation();

  useEffect(() => {
    props.getAllTariffData(1, 10000000);
    props.percentageChangeData();
  }, []);

  // console.log(props.fetchAllTariffDataList);
  // console.log(props.loading)

  const data = props.fetchAllTariffDataList && props.fetchAllTariffDataList.data;
  const allTariffData = props.fetchAllTariffDataList && props.fetchAllTariffDataList.data;
  // console.log("data",data );
  const total =
    data &&
    data.map((item) => {
      return item;
    });
  const totalTariff = total && total.length;
  // console.log("total",totalTariff);
  const active =
    total &&
    total.filter((item) => {
      return item.status === "active";
    });
  // console.log("active",active)
  const draft =
    total &&
    total.filter((item) => {
      return item.status === "draft";
    });
  // console.log("draft",draft)
  const draftLength = draft && draft.length;
  const activeLength = active && active.length;
  // console.log("activeLength",activeLength)
  const inActive =
    total &&
    total.filter((item) => {
      return item.status === "inactive";
    });
  // console.log("inActive",inActive)
  const inActiveLength = inActive && inActive.length;
  // console.log("inActiveLength",inActiveLength)

  const Total_tariff_percentage = props.percentageChange && props.percentageChange.percentage_change;
  const Total_tariff_change_symbol = props.percentageChange && props.percentageChange.change_symbol;
  // console.log("total tariff", props.percentageChange)

  const Total_active_percentage = props.percentageChange && props.percentageChange.active_percentage_change;
  const Total_active_symbol = props.percentageChange && props.percentageChange.active_change_symbol;

  const Total_draft_percentage = props.percentageChange && props.percentageChange.draft_percentage_change;
  const Total_draft_symbol = props.percentageChange && props.percentageChange.draft_change_symbol;

  const Total_inactive_percentage = props.percentageChange && props.percentageChange.inactive_percentage_change;
  const Total_inactive_symbol = props.percentageChange && props.percentageChange.inactive_change_symbol;

  return (
    <Container fluid className="pb-4">
      <PureBreadcrumbs />
      <br />
      <Row>
        <Col className="col-md-3 col-12">
          {/* <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>{t("TOTAL TARIFF")}</Card.Text>
              <Card.Title>
                <b>{totalTariff != undefined ? totalTariff : 0}</b>
              </Card.Title>
              <span style={{ fontSize: "12px" }}>
                {Total_tariff_change_symbol === "+" ? <img src={process.env.PUBLIC_URL + "/images/admin/profit.svg"} className="profitloss" alt="" /> : <img src={process.env.PUBLIC_URL + "/images/admin/loss.svg"} className="profitloss" alt="" />}
                &nbsp;&nbsp;{Total_tariff_percentage}% &nbsp;{t(" change in last 30 days")}
              </span>
              <span

                style={{
                  float: "right",
                  marginTop: "-60px",
                  marginRight: "-8px",
                }}
              >
                <div style={{ width: "20%" }}>
                  <img
                    src={
                      process.env.PUBLIC_URL + "/images/tariff/totalTariff.svg"
                    }
                    alt="customer"
                  />
                </div>
              </span>
            </div>
          </Card> */}
          <Card className="customercard card-custom total-rules cards">
            <div className="diagonal-line"></div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p
                style={{
                  backgroundColor: "blue",
                  padding: "3px",
                  borderRadius: "50%",
                  width: "8px",
                  height: "8px",
                  marginRight: "8px",
                }}
              ></p>
              <p>{t("Total tarriff")}</p>
            </div>
            <Card.Title>
              <b>{totalTariff != undefined ? totalTariff : 0}</b>
            </Card.Title>
            <span style={{ fontSize: "12px" }}>
              {Total_tariff_change_symbol === "+" ? (
                <img src={process.env.PUBLIC_URL + "/images/admin/profit.svg"} className="profitloss" alt="" />
              ) : (
                <img src={process.env.PUBLIC_URL + "/images/admin/loss.svg"} className="profitloss" alt="" />
              )}
              &nbsp;&nbsp;{Total_tariff_percentage}% &nbsp;{t(" change in last 30 days")}
            </span>
          </Card>
        </Col>
        <Col className="col-md-3 col-12">
          {/* <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>{t("TOTAL ACTIVE TARIFF")}</Card.Text>
              <Card.Title>
                <b>{activeLength != undefined ? activeLength : 0}</b>
              </Card.Title>
              <span style={{ fontSize: "12px" }}>
                {Total_active_symbol === "+" ? (
                  <img src={process.env.PUBLIC_URL + "/images/admin/profit.svg"} className="profitloss" alt="" />
                ) : (
                  <img src={process.env.PUBLIC_URL + "/images/admin/loss.svg"} className="profitloss" alt="" />
                )}
                &nbsp;&nbsp;{Total_active_percentage}% &nbsp;{t(" change in last 30 days")}
              </span>
              <span
                style={{
                  float: "right",
                  marginTop: "-60px",
                  marginRight: "-8px",
                }}
              >
                <div style={{ width: "20%" }}>
                  <img src={process.env.PUBLIC_URL + "/images/tariff/activeTariff.svg"} alt="customer" />
                </div>
              </span>
            </div>
          </Card> */}
          <Card className="customercard card-custom total-active-rules cards">
            <div className="diagonal-line"></div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p
                style={{
                  backgroundColor: "orange",
                  padding: "3px",
                  borderRadius: "50%",
                  width: "8px",
                  height: "8px",
                  marginRight: "8px",
                }}
              ></p>
              <p>{t("Total active tarriff")}</p>
            </div>
            <Card.Title>
              <b>{activeLength != undefined ? activeLength : 0}</b>
            </Card.Title>
            <span style={{ fontSize: "12px" }}>
              {Total_active_symbol === "+" ? (
                <img src={process.env.PUBLIC_URL + "/images/admin/profit.svg"} className="profitloss" alt="" />
              ) : (
                <img src={process.env.PUBLIC_URL + "/images/admin/loss.svg"} className="profitloss" alt="" />
              )}
              &nbsp;&nbsp;{Total_active_percentage}% &nbsp;{t(" change in last 30 days")}
            </span>
          </Card>
        </Col>

        <Col className="col-md-3 col-12">
          {/* <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>{t("TOTAL DRAFT TARIFF")}</Card.Text>
              <Card.Title>
                <b>{draftLength !== undefined ? draftLength : 0}</b>
              </Card.Title>
              <span style={{ fontSize: "12px" }}>
                {Total_draft_symbol === "+" ? (
                  <img src={process.env.PUBLIC_URL + "/images/admin/profit.svg"} className="profitloss" alt="" />
                ) : (
                  <img src={process.env.PUBLIC_URL + "/images/admin/loss.svg"} className="profitloss" alt="" />
                )}
                &nbsp;&nbsp;{Total_draft_percentage}% &nbsp;{t(" change in last 30 days")}
              </span>
              <span
                style={{
                  float: "right",
                  marginTop: "-60px",
                  marginRight: "-8px",
                }}
              >
                <div style={{ width: "20%" }}>
                  <img src={process.env.PUBLIC_URL + "/images/tariff/activeTariff.svg"} alt="customer" />
                </div>
              </span>
            </div>
          </Card>
          <br />*/}
          <Card className="customercard card-custom total-inactive cards">
            <div className="diagonal-line"></div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p
                style={{
                  backgroundColor: "red",
                  padding: "3px",
                  borderRadius: "50%",
                  width: "8px",
                  height: "8px",
                  marginRight: "8px",
                }}
              ></p>
              <p>{t("Total draft tarriff")}</p>
            </div>
            <Card.Title>
              <b>{draftLength !== undefined ? draftLength : 0}</b>
            </Card.Title>
            <span style={{ fontSize: "12px" }}>
              {Total_draft_symbol === "+" ? (
                <img src={process.env.PUBLIC_URL + "/images/admin/profit.svg"} className="profitloss" alt="" />
              ) : (
                <img src={process.env.PUBLIC_URL + "/images/admin/loss.svg"} className="profitloss" alt="" />
              )}
              &nbsp;&nbsp;{Total_draft_percentage}% &nbsp;{t(" change in last 30 days")}
            </span>
          </Card>
        </Col>

        <Col className="col-md-3 col-12">
          {/* <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>{t("TOTAL INACTIVE TARIFF")}</Card.Text>
              <Card.Title>
                <b>{inActiveLength != undefined ? inActiveLength : 0}</b>
              </Card.Title>
              <span style={{ fontSize: "12px" }}>
                {Total_inactive_symbol === "+" ? (
                  <img src={process.env.PUBLIC_URL + "/images/admin/profit.svg"} className="profitloss" alt="" />
                ) : (
                  <img src={process.env.PUBLIC_URL + "/images/admin/loss.svg"} className="profitloss" alt="" />
                )}
                &nbsp;&nbsp;{Total_inactive_percentage}% &nbsp;{t(" change in last 30 days")}
              </span>
              <span
                style={{
                  float: "right",
                  marginTop: "-60px",
                  marginRight: "-8px",
                }}
              >
                <div style={{ width: "20%" }}>
                  <img src={process.env.PUBLIC_URL + "/images/tariff/InactiveTariff.svg"} alt="customer" />
                </div>
              </span>
            </div>
          </Card> */}
          <Card className="customercard card-custom total-draft cards">
            <div className="diagonal-line"></div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p
                style={{
                  backgroundColor: "grey",
                  padding: "3px",
                  borderRadius: "50%",
                  width: "8px",
                  height: "8px",
                  marginRight: "8px",
                }}
              ></p>
              <p>{t("Total inactive tarriff")}</p>
            </div>
            <Card.Title>
              <b>{inActiveLength != undefined ? inActiveLength : 0}</b>
            </Card.Title>
            <span style={{ fontSize: "12px" }}>
              {Total_inactive_symbol === "+" ? (
                <img src={process.env.PUBLIC_URL + "/images/admin/profit.svg"} className="profitloss" alt="" />
              ) : (
                <img src={process.env.PUBLIC_URL + "/images/admin/loss.svg"} className="profitloss" alt="" />
              )}
              &nbsp;&nbsp;{Total_inactive_percentage}% &nbsp;{t(" change in last 30 days")}
            </span>
          </Card>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <Card className="customercard">
            {/* {props.loading ? <h3 style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "50px",
            }}>{t("Loading data...")}</h3> :
              props.fetchAllTariffDataList &&  */}
            <TimeTariffListComponent
              allTariffData={allTariffData}
              loading={props.loading}
              {...props}
            ></TimeTariffListComponent>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.tariff.isLoading,
    fetchAllTariffDataList: state.tariff.fetchAllStaticTariffData,
    percentageChange: state.tariff.tariffpercentagechange,
    activate_tariff_api: state.tariff.activateTariff,
    deactivate_tariff_api: state.tariff.deactivateTariff,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAllTariffData: (currentPage, pageSize) => dispatch(fetchAllStaticTariffDataAction(currentPage, pageSize)),
    percentageChangeData: () => dispatch(percentageChangeData()),
    activateTariffAPI: (id) => dispatch(activateTariff(id)),
    deActivateTariff: (id) => dispatch(deActivateTariff(id)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TimeOfUseTariffListComponent);
