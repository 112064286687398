import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import Table from '../../reacttable/table';
import { FaEdit, FaEye } from 'react-icons/fa';
import { TiTick } from 'react-icons/ti';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { activateStationGroup, deactivateStationGroup, fetchAllStationGroup } from '../../../../store/actions/evseAction';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PureBreadcrumbs from "../../breadcrums";


function StationGroupList(props) {
    const{t}=useTranslation();
    useEffect(() => {
        props.all();
    }, [])

    const data = props.getAll && props.getAll.data
    // console.log(data)

    const activateGroup = (id) => {
        // console.log(id)
        props.activateAPI(id)
    }
    const deactivateGroup = (id) => {
        // console.log(id)
        props.deactivateAPI(id)
    }

    if(props.activate.statuscode === 200){
        window.location.reload()
    }
    if(props.deactivate.statuscode === 200){
        window.location.reload()
    }
    const columns = useMemo(() => [
        {
            Header: ' Customer Group Name',
            accessor: 'customer_group'
        },
        {
            Header: 'Station Group Name',
            accessor: 'station_group_name'
        },
        {
            Header: 'Status',
            id: "status",
            accessor: d => {
                return d.status ? <span className="text-success">Active</span> : <span className="text-danger">Inactive</span>
            }
        },
        {
            Header: 'Actions',
            accessor: 'id',
            Cell: (cell) => {
                const { row } = cell
                const { original } = row
                const station_group_name = original.station_group_name
                const id = original.id
                // console.log(row)
                // console.log(original)
                return (
                    <div>
                        <span>
                            <a href={`/dashboard/view_evse_customer_group/viewSingleStationGroup/${id}`}
                                rel="tooltip"
                                title="View"
                            >
                                <FaEye />
                            </a></span>&nbsp;&nbsp;
                        <span>
                            <a href={`/dashboard/view_evse_customer_group/updateSingleStationGroup/${id}`}
                                rel="tooltip"
                                title="Edit"
                            >
                                <FaEdit />
                            </a>
                        </span>
                        &nbsp;&nbsp;
                        <span>
                            <TiTick
                                style={{ cursor: 'pointer' }}
                                onClick={() => activateGroup(id)}
                                title="Activate"
                            />
                        </span>
                        &nbsp;&nbsp;
                        <span>
                            <AiOutlineCloseCircle
                                style={{ cursor: 'pointer' }}
                                onClick={() => deactivateGroup(id)}
                                title="De-Activate"
                            />
                        </span>
                    </div>
                );
            },
        }
    ], [data])
    return (
        <Container fluid className=''>
            <PureBreadcrumbs />
            <Row className='pt-4'>
                <Col lg={12}>
                    <Card className='customercard'>
                        <div className='table-header'>{t("Station Group Details")}</div>
                        <Card.Body>
                            {props.loading ? <h3 style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "250px",
                            }}>Loading data...</h3> :
                                data && data.length > 0 ?
                                    (<div> <Table data={data} columns={columns}></Table></div>)
                                    :
                                    (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}><h3 className="loader">No data found</h3></div>)
                            }
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

        </Container>
    )
}

const mapStateToProps = (state) => {
    return {
        loading: state.evse.isLoading,
        getAll: state.evse.fetchAllStationGroups,
        activate: state.evse.activateStationGroups,
        deactivate: state.evse.deactivateStationGroups,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        all: () => dispatch(fetchAllStationGroup()),
        activateAPI: (id) => dispatch(activateStationGroup(id)),
        deactivateAPI: (id) => dispatch(deactivateStationGroup(id)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(StationGroupList)