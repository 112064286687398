import React from 'react'
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import PureBreadcrumbs from "../../breadcrums";
import { fetchEvseStationAction } from "../../../../store/actions/evseAction";
import { triggerChangeAvailability, triggerResetCharger, triggerClearCache, triggerUnlockConnector, triggerGetDiagnostics, triggerMessage, triggerClearChargingProfile, triggerlocalListVersion } from "../../../../store/actions/commandAction";
import UpdateFirmwareModal from "./updateFirmware";
import CompositeScheduleModal from "./compositeSchedule";
import ReserveNowModal from "./reserveNow";
import GetConfiguration from "./getConfiguration";
import ChangeAvailabilityComponent from './change_availibility';
import ResetChargerComponent from './reset';
import ClearAuthorizationCacheComponent from './clear_cache';
import UnloackChargerComponent from './unlock_connector';
import TriggerChargerMessageComponent from './trigger_message';
import GetChargerDiagnosticComponent from './diagnostics';
import ChargingProfileComponent from './charging_profile';
import { useTranslation } from 'react-i18next';


function CommandsCard(props) {
    const { t } = useTranslation();
    const { stationid } = useParams()
    useEffect(() => {
        props.fetch(stationid);
    }, []);

    const [startdate, setstartdate] = useState(new Date(Date.now() + (3600 * 1000 * 1)));
    const [enddate, setenddate] = useState(new Date(Date.now() + (3600 * 1000 * 720)));

    return (
        <Container fluid>
            <Row>
                <Col>
                    <PureBreadcrumbs />
                </Col>
                <Col>
                    <div className="text-right">
                        <span className="text-magenta" style={{ fontSize: "10px" }}></span>&nbsp;&nbsp;
                        <a className="refresh"

                            style={{
                                fontSize: "13px",
                                color: "var(--button-color)",
                                fontWeight: "bold",
                                cursor: "pointer"
                            }} onClick={() => window.location.reload()}>
                            Refresh
                            &nbsp;
                            <img style={{ height: "16px" }}
                                src={process.env.PUBLIC_URL + '/images/insight/refresh.svg'}
                                alt="" />
                        </a>
                    </div>
                </Col>
            </Row>
            <Row className='mt-3'>
                <Col>
                    <div className="nav nav pills">
                        <div className="nav-item">
                            <input type="radio" name="css-tabs" id="tab-11" defaultChecked className="tab-switch-command" />
                            <label htmlFor="tab-11" className="tab-label-command">{t("Configuration")}</label>
                            <div className="tab-content-command">  <GetConfiguration></GetConfiguration></div>
                        </div>
                        <div className="nav-item">
                            <input type="radio" name="css-tabs" id="tab-22" className="tab-switch-command" />
                            <label htmlFor="tab-22" className="tab-label-command">{t("Availablity")}</label>
                            <div className="tab-content-command">
                                <ChangeAvailabilityComponent {...props}></ChangeAvailabilityComponent>
                            </div>
                        </div>

                        <div className="nav-item">
                            <input type="radio" name="css-tabs" id="tab-33" className="tab-switch-command" />
                            <label htmlFor="tab-33" className="tab-label-command">{t("Reset")}</label>
                            <div className="tab-content-command">
                                <ResetChargerComponent {...props}></ResetChargerComponent>
                            </div>
                        </div>
                        <div className="nav-item">
                            <input type="radio" name="css-tabs" id="tab-44" className="tab-switch-command" />
                            <label htmlFor="tab-44" className="tab-label-command">{t("Clear Cache")}</label>
                            <div className="tab-content-command">
                                <ClearAuthorizationCacheComponent {...props}></ClearAuthorizationCacheComponent>
                            </div>
                        </div>
                        <div className="nav-item">
                            <input type="radio" name="css-tabs" id="tab-55" className="tab-switch-command" />
                            <label htmlFor="tab-55" className="tab-label-command">{t("Unlock")}</label>
                            <div className="tab-content-command">
                                <UnloackChargerComponent {...props}></UnloackChargerComponent>
                            </div>
                        </div>
                        <div className="nav-item">
                            <input type="radio" name="css-tabs" id="tab-66" className="tab-switch-command" />
                            <label htmlFor="tab-66" className="tab-label-command">{t("Message")}</label>
                            <div className="tab-content-command">
                                <TriggerChargerMessageComponent {...props}></TriggerChargerMessageComponent>
                            </div>
                        </div>
                        <div className="nav-item">
                            <input type="radio" name="css-tabs" id="tab-77" className="tab-switch-command" />
                            <label htmlFor="tab-77" className="tab-label-command">{t("Diagnostic")}</label>
                            <div className="tab-content-command">
                                <GetChargerDiagnosticComponent {...props}></GetChargerDiagnosticComponent>
                            </div>
                        </div>
                        <div className="nav-item">
                            <input type="radio" name="css-tabs" id="tab-88" className="tab-switch-command" />
                            <label htmlFor="tab-88" className="tab-label-command">{t("Profile")}</label>
                            <div className="tab-content-command">
                                <ChargingProfileComponent {...props}></ChargingProfileComponent>
                            </div>
                        </div>
                        <div className="nav-item">
                            <input type="radio" name="css-tabs" id="tab-99" className="tab-switch-command" />
                            <label htmlFor="tab-99" className="tab-label-command">{t("Firmware")}</label>
                            <div className="tab-content-command">
                                <UpdateFirmwareModal></UpdateFirmwareModal>
                            </div>
                        </div>
                        <div className="nav-item">
                            <input type="radio" name="css-tabs" id="tab-100" className="tab-switch-command" />
                            <label htmlFor="tab-100" className="tab-label-command">{t("Composite")}</label>
                            <div className="tab-content-command">
                                <CompositeScheduleModal></CompositeScheduleModal>
                            </div>
                        </div>
                        <div className="nav-item">
                            <input type="radio" name="css-tabs" id="tab-110" className="tab-switch-command" />
                            <label htmlFor="tab-110" className="tab-label-command">{t("Reservation")}</label>
                            <div className="tab-content-command">
                                <ReserveNowModal></ReserveNowModal>
                            </div>
                        </div>
                    </div>


                </Col>
            </Row>


        </Container>

    )
}


const mapStateToProps = (state) => {
    return {
        loadingevse: state.evse.isEvseLoading,
        fetchstation: state.evse.viewstation,
        changeavailability: state.command.changeAvailability,
        reset: state.command.resetCharger,
        clearcache: state.command.clearCache,
        unlock: state.command.unlockConnector,
        getDiagnostic: state.command.getDiagnostic,
        triggerMessagecmd: state.command.triggerMessage,
        clearChargingProfile: state.command.clearChargingProfile,
        localListVersion: state.command.getLocalListVersion,

    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetch: (stationid) => dispatch(fetchEvseStationAction(stationid)),
        changeAvailability: (evse_id, connectorId, type) => dispatch(triggerChangeAvailability(evse_id, connectorId, type)),
        triggerReset: (evse_id, connectorId, reset_type) => dispatch(triggerResetCharger(evse_id, connectorId, reset_type)),
        triggerClearCache: (evse_id) => dispatch(triggerClearCache(evse_id)),
        unlockConnector: (evse_id, connectorId) => dispatch(triggerUnlockConnector(evse_id, connectorId)),
        triggerGetDiagnostic: (data, startdate, enddate) => dispatch(triggerGetDiagnostics(data, startdate, enddate)),
        triggerMessage: (evse_id, portno, requestedMessage) => dispatch(triggerMessage(evse_id, portno, requestedMessage)),
        clearChargingProfile: (data) => dispatch(triggerClearChargingProfile(data)),
        triggerLocalListVersion: (evse_id) => dispatch(triggerlocalListVersion(evse_id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommandsCard)