import React, { useState } from 'react'
import ReactApexChart from 'react-apexcharts';
function FleetDiagnostic() {
    const colors = ['#008FFB', '#00E396', '#775DD0', '#FF4560']
    const [series] = useState([{
        name: 'Total',
        data: [15, 8, 12, 5, 20]
    }]);
    const options = {
        chart: {
            height: 350,
            type: 'bar',
            events: {
                click: (chart, w, e) => {
                    console.log(chart, w, e);
                }
            }
        },
        colors: colors,
        plotOptions: {
            bar: {
                columnWidth: '45%',
                distributed: true,
            }
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            show: false
        },
        xaxis: {
            categories: [
                ["Engine Overheat"],
                ['Brake Failure'],
                ['Low Oil Pressure'],
                ['Battery Low'],
                ['Transmission Fault']
            ],
            labels: {
                style: {
                    colors: colors,
                    fontSize: '12px'
                }
            }

        },
    };
    return (
        <div>
            <div id="chart">
                <ReactApexChart options={options} series={series} type="bar" height={350} />
            </div>
        </div >
    )
}

export default FleetDiagnostic