import React, { useMemo, useEffect } from "react";
import Table from "../../reacttable/table";
import "../../reacttable/reactTable.css";
import { FaDownload, FaEdit, FaEye } from "react-icons/fa";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import { Card, Col, Row } from "react-bootstrap";
import { activateTimeSlotTariff, deActivateTariffTimeSlot, fetchAllTimeSlot } from "../../../../store/actions/tariffAction";
import { TiTick } from "react-icons/ti";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import PureBreadcrumbs from "../../breadcrums";


const ViewTimeSlot = (props) => {
  const { t } = useTranslation();
  useEffect(() => {
    props.getList();
  }, [])


  const data = props.fetchList && props.fetchList.data;
  // console.log(data)

  const activateStation = (id) => {
    console.log(id);
    props.activateTariffTimeSlotApi(id);
  };

  if (props.activateTimeSlot.statuscode === 200) {
    window.location.reload();
  }

  const deactivateStation = (id) => {
    console.log(id);
    props.deactivateTariffTimeSlotApi(id);
  };

  if (props.deactivateTimeSlot.statuscode === 200) {
    window.location.reload();
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Plan Name',
        accessor: 'plan_name',
      },
      {
        Header: 'Day',
        accessor: 'timeslot[0].day',
      },
      {
        Header: 'Start Time',
        accessor: 'timeslot[0].start_time',
      },
      {
        Header: 'End Time',
        accessor: 'timeslot[0].end_time',
      },
      {
        Header: 'Tariff Name',
        accessor: 'timeslot[0].tariff_name',
      },
      {
        Header: 'Station Name',
        accessor: 'station_name',
      },
      {
        Header: 'Role',
        accessor: 'role',
      },
      {
        Header: 'Operator',
        accessor: 'operator',
      },
      {
        Header: "Active/Inactive",
        accessor: "status",
        Cell: (status) => {
          // console.log(publish.value)
          return (
            <div>
              <span>
                {status.value === "Active" ? (
                  <span className="text-success">Active</span>
                ) : (
                  <span className="text-danger">Inactive</span>
                )}
              </span>
            </div>
          );
        },
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: (id) => {
          return (
            <div>
              <span>
                <a
                  href={`/dashboard/view_time_slot/view_single_time_slot/${id.value}`}
                  rel="tooltip"
                  title="View"
                >
                  <FaEye />
                </a>
              </span>
              &nbsp;&nbsp;
              <span>
                <a
                  href={`/dashboard/update_time_slot/${id.value}`}
                  rel="tooltip"
                  title="Edit"
                >
                  <FaEdit />
                </a>
              </span>
              &nbsp;&nbsp;  &nbsp;&nbsp;
              <span style={{ cursor: "pointer" }}>
                <TiTick
                  onClick={() => activateStation(id.value)}
                  title="Activate"
                />
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <span style={{ cursor: "pointer" }}>
                <AiOutlineCloseCircle
                  onClick={() => deactivateStation(id.value)}
                  title="De-Activate"
                />
              </span>
            </div>
          );
        },
      },
    ],
    []
  );

  const headers = [

    { label: 'Plan Name', key: 'plan_name' },
    { label: 'Tariff Name', key: 'timeslot[0].tariff_name' },
    { label: 'Day', key: 'timeslot[0].day' },
    { label: 'Time From', key: 'timeslot[0].start_time' },
    { label: 'Time To', key: 'timeslot[0].end_time' },
    { label: 'Role', key: 'role' },
    { label: 'Operator', key: 'operator' },

  ];

  return (
    <div className="container-fluid">
      <Row>
        <PureBreadcrumbs />
      </Row>
      <Row className="mt-5">
        <Col>
          <Card className="customercard">
            <div className="table-header">{t("Tariff Time Slot List")}</div>
            <Card.Body>
              {props.loading ? <h3 style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "250px",
              }}>Loading data...</h3> :
                data && data.length > 0 ? (
                  <div>
                    <div style={{ display: 'flex', justifyContent: 'end' }}>

                      <button className="csv-file-btn">
                        <CSVLink
                          className="fas"
                          data={data}
                          headers={headers}
                          filename="Time-SlotTariff-List.csv"
                          style={{ textDecoration: 'none' }}
                        >
                          <FaDownload className="download-icon" />
                        </CSVLink>
                      </button>
                    </div>
                    <Table data={data} columns={columns}></Table>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  > <h3>{t("No Data Found")}</h3>
                  </div>
                )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

    </div>
  );

}
const mapStateToProps = (state) => {
  return {
    loading: state.tariff.isLoading,
    fetchList: state.tariff.fetchAllTimeSlot,
    activateTimeSlot: state.tariff.activateTariffTimeSlot,
    deactivateTimeSlot: state.tariff.deactivateTariffTimeSlot,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    getList: () => dispatch(fetchAllTimeSlot()),
    activateTariffTimeSlotApi: (id) => dispatch(activateTimeSlotTariff(id)),
    deactivateTariffTimeSlotApi: (id) => dispatch(deActivateTariffTimeSlot(id)),

  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewTimeSlot)
