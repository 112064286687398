import React from 'react'
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import * as Yup from 'yup';
import { connect } from "react-redux";
import { createPushNotifyCampaignAction } from "../../../../store/actions/campaignAction";
import { useTranslation } from 'react-i18next';
import { Bounce, ToastContainer, toast } from 'react-toastify';

function CreateNotificationCampaign(props) {
    const { t } = useTranslation();
    const [isToastVisible, setIsToastVisible] = useState(false);

    const notificationSchema = Yup.object().shape({
        message: Yup.string().required('Message is required'),
        recipients: Yup.string().required('Recipients is required'),
        title: Yup.string().required('Title is required'),
    });
    const [startDate, setStartDate] = useState(new Date(Date.now()));

    const { register, handleSubmit, reset, formState: { errors } } = useForm({ resolver: yupResolver(notificationSchema) });

    const onSubmit = async data => {
        props.pushcreate(data, startDate)
    }

    useEffect(() => {
        if (props.pushnotify.statuscode === 200) {
            setIsToastVisible(true);
            toast.success('Push notification campaign has been created.', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    window.location.reload();
                },
            });
        } else if (props.pushnotify.statuscode === 405) {
            setIsToastVisible(true);
            toast.error(`❌ ${props.pushnotify.error}`, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    window.location.reload();
                },
            });
        }
    }, [props.pushnotify]);

    // if (props.pushnotify.statuscode === 200) {
    //     alert("Push notification campaign has been created.")
    //     window.location.reload();
    // }


    return (
        <div className='container-fluid'>
            {isToastVisible && <div className="overlay" />}
            <ToastContainer
                position="top-center"
                autoClose={20}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ top: "10%", width: "auto", zIndex: "9999" }}
            />
            <form>
                <div className="form-floating">
                    <select autoFocus className={`form-select form-control ${errors.unit ? 'is-invalid' : ''}`} name="recipients" id="floatingSelectGridU" {...register("recipients")}>
                        <option value="" disabled>Select Recipients</option>
                        <option value="All">All</option>
                    </select>
                    {/* <label for="floatingSelectGridU">Charging Unit</label> */}
                    <div className="invalid-feedback">{errors.recipients?.message}</div>
                </div><br />

                <div className="form-floating">
                    <label>Notification Title <span className='mandatory-field'>*</span></label>
                    <input type="description" className={`form-control gray-border ${errors.title ? 'is-invalid' : ''}`} name="title" id="floatingInputGridtitle" placeholder="Enter Notification Title" {...register("title")} />
                    <div className="invalid-feedback">{errors.title?.message}</div>
                </div><br />

                <div className="form-floating">
                    <label>Notification Message <span className='mandatory-field'>*</span></label>
                    <input type="description" className={`form-control gray-border ${errors.message ? 'is-invalid' : ''}`} name="message" id="floatingInputGridmessage" placeholder="Message" {...register("message")} />
                    <div className="invalid-feedback">{errors.message?.message}</div>
                </div><br />

                <div className="form-floating">
                    <Row>
                        <Col md={12}>
                            Select Date  <span className='mandatory-field'>*</span> :
                            <DatePicker
                                className='gray-border ml-3 w-75'
                                selected={startDate}
                                name="start"
                                onChange={(date) => setStartDate(date)}
                                selectsStart
                                startDate={startDate}
                                dateFormat="dd/MM/yyyy"
                            />
                            {startDate === null && <div className='invalid-feedback fa-1x'>{t("Date is required")}</div>}

                        </Col>
                    </Row>
                </div>  <br />
                <Row className="mt-3">
                    <Col lg={6} md={3} sm={12} xs={12} className="d-flex justify-content-center justify-content-md-start mt-2">
                        <button className="lgn-btn btn" type="button" onClick={() => {
                            reset({
                                message: '', recipients: '', title: '',
                                startDate: setStartDate(new Date(Date.now() + (3600 * 1000 * 1))),
                            });
                        }}> Reset </button>
                    </Col><br />
                    <Col lg={6} md={9} sm={12} xs={12} className='d-flex justify-content-center justify-content-md-end mt-2'>
                        {props.loading ?
                            <button className="btn lgn-btn" type="button" disabled>
                                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                &nbsp;
                                {t("Saving...")}
                            </button>
                            :
                            <button className="lgn-btn btn " type="submit" disabled={startDate === null} onClick={handleSubmit(onSubmit)}>CREATE NEW PUSH NOTIFICATION</button>
                        }

                    </Col>
                </Row>
            </form>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {

        loading: state.campaign.isLoading,
        pushnotify: state.campaign.pushnotify
    }
}
const mapDispatchToProps = dispatch => {
    return {

        pushcreate: (data, startDate) => dispatch(createPushNotifyCampaignAction(data, startDate))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateNotificationCampaign)
