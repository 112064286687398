import React, { useEffect } from "react";
import PureBreadcrumbs from "../../breadcrums";
import { connect } from "react-redux";
import { Container, Card } from "react-bootstrap";
import CustomerBookingGraph from "./customerBookingGraph";
import {
  DeleteBookingAPI,
  fetchAllCustomerBookingCardCountAction,
  fetchBookingPercentage,
} from "../../../../store/actions/evseAction";
import CustomerBookingList from "./customerTable";
import { useTranslation } from "react-i18next";
function CustomerBookingCard(props) {
  const { t } = useTranslation();
  useEffect(() => {
    props.customerBookingCardCountApi(1, 10000000);
    // props.bookingPercentage();
  }, []);

  /////////////////////////////////////////////////////////////////////////////////////////////////
  const allData = props.customerBookingCardCountRes && props.customerBookingCardCountRes.data;
  // console.log(allData);

  const bookingRecieved = allData && allData.filter((item) => item.status === "Booking Scheduled");
  const bookingRecievedCount = bookingRecieved && bookingRecieved.length;

  const bookingCancelled = allData && allData.filter((item) => item.status === "Booking Cancelled by User");
  const bookingCancelledCount = bookingCancelled && bookingCancelled.length;

  const bookingUsed = allData && allData.filter((item) => item.status === "Booking Used");
  const bookingUsedCount = bookingUsed && bookingUsed.length;
  // console.log(bookingUsed)

  const bookingExpired = allData && allData.filter((item) => item.status === "Booking Expired - Unused");
  const bookingExpiredCount = bookingExpired && bookingExpired.length;

  /////////////////////////////////////////////////////////////////////////////////////////////////

  // const Total_booking_percentage = props.bookingCardPercentageRes && props.bookingCardPercentageRes.total_booking_percentage
  // const Total_booking_change_symbol = props.bookingCardPercentageRes && props.bookingCardPercentageRes.total_booking_change_symbol

  // const Total_booking_received_percentage = props.bookingCardPercentageRes && props.bookingCardPercentageRes.booking_received_percentage
  // const Total_booking_received_change_symbol = props.bookingCardPercentageRes && props.bookingCardPercentageRes.booking_received_change_symbol

  // const Total_booking_cancelled_percentage = props.bookingCardPercentageRes && props.bookingCardPercentageRes.bookingcanceled_percentage
  // const Total_booking_cancelled_change_symbol = props.bookingCardPercentageRes && props.bookingCardPercentageRes.booking_cancelled_change_symbol

  // const Total_booking_used_percentage = props.bookingCardPercentageRes && props.bookingCardPercentageRes.booking_used_percentage
  // const Total_booking_used_change_symbol = props.bookingCardPercentageRes && props.bookingCardPercentageRes.booking_used_change_symbol

  // const Total_booking_expired_percentage = props.bookingCardPercentageRes && props.bookingCardPercentageRes.booking_expired_percentage
  // const Total_booking_expired_change_symbol = props.bookingCardPercentageRes && props.bookingCardPercentageRes.booking_expired_change_symbol

  return (
    <Container fluid className="pb-4">
      <div className="row">
        <div className="col-lg-6 col-sm-12">
          <PureBreadcrumbs />
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-lg-3 col-sm-12">
          {/* <Card className="customercard">
                        <div style={{ textAlign: "left" }}>
                            <Card.Text style={{ fontSize: "14px" }}>
                                {t("BOOKING RESERVE")}
                            </Card.Text>
                            <Card.Title>
                                <b>{bookingRecievedCount !== undefined ? bookingRecievedCount : 0}</b>
                            </Card.Title> */}
          {/* <span style={{ fontSize: "12px" }}>
                                {Total_booking_received_change_symbol === "+" ? <img src={process.env.PUBLIC_URL + "/images/admin/profit.svg"} className="profitloss" alt="" /> : <img src={process.env.PUBLIC_URL + "/images/admin/loss.svg"} className="profitloss" alt="" />}
                                &nbsp;&nbsp;{Total_booking_received_percentage}% &nbsp;{t(" change in last 30 days")}
                            </span> */}
          {/* <span
                                style={{
                                    float: "right",
                                    marginTop: "-60px",
                                    marginRight: "-8px",
                                }}
                            >
                                <div style={{ width: "20%" }}>
                                    <img
                                        className="CustomerImage "
                                        src={
                                            process.env.PUBLIC_URL +
                                            "/images/customer/activecustomer.svg"
                                        }
                                        alt="customer"
                                    />
                                </div>
                            </span>
                        </div>
                    </Card> */}
          <Card className="customercard card-custom total-rules cards">
            <div className="diagonal-line"></div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p
                style={{
                  backgroundColor: "blue",
                  padding: "3px",
                  borderRadius: "50%",
                  width: "8px",
                  height: "8px",
                  marginRight: "8px",
                }}
              ></p>
              <p>{t("Booking Recieved")}</p>
            </div>
            <Card.Title>
              <b>{bookingRecievedCount !== undefined ? bookingRecievedCount : 0}</b>
            </Card.Title>
          </Card>

          <br />
        </div>
        <div className="col-lg-3 col-sm-12">
          {/* <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>{t("BOOKING CANCELLED")}</Card.Text>
              <Card.Title>
                <b>{bookingCancelledCount !== undefined ? bookingCancelledCount : 0}</b>
              </Card.Title> */}
          {/* <span style={{ fontSize: "12px" }}>
                                {Total_booking_cancelled_change_symbol === "+" ? <img src={process.env.PUBLIC_URL + "/images/admin/profit.svg"} className="profitloss" alt="" /> : <img src={process.env.PUBLIC_URL + "/images/admin/loss.svg"} className="profitloss" alt="" />}
                                &nbsp;&nbsp;{Total_booking_cancelled_percentage}% &nbsp;{t(" change in last 30 days")}
                            </span> */}
          {/* <span
                style={{
                  float: "right",
                  marginTop: "-60px",
                  marginRight: "-8px",
                }}
              >
                <div style={{ width: "20%" }}>
                  <img
                    className="CustomerImage "
                    src={process.env.PUBLIC_URL + "/images/customer/inactivecustomer.svg"}
                    alt="customer"
                  />
                </div>
              </span>
            </div>
          </Card> */}
          <Card className="customercard card-custom total-inactive cards">
            <div className="diagonal-line"></div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p
                style={{
                  backgroundColor: "red",

                  padding: "3px",
                  borderRadius: "50%",
                  width: "8px",
                  height: "8px",
                  marginRight: "8px",
                }}
              ></p>
              <p>{t("Booking Cancelled")}</p>
            </div>
            <Card.Title>
              <b>{bookingCancelledCount !== undefined ? bookingCancelledCount : 0}</b>
            </Card.Title>
          </Card>
          <br />
        </div>
        <div className="col-lg-3 col-sm-12">
          {/* <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>{t("BOOKING USED")}</Card.Text>
              <Card.Title>
                <b>{bookingUsedCount !== undefined ? bookingUsedCount : 0}</b>
              </Card.Title> */}
          {/* <span style={{ fontSize: "12px" }}>
                                {Total_booking_used_change_symbol === "+" ? <img src={process.env.PUBLIC_URL + "/images/admin/profit.svg"} className="profitloss" alt="" /> : <img src={process.env.PUBLIC_URL + "/images/admin/loss.svg"} className="profitloss" alt="" />}
                                &nbsp;&nbsp;{Total_booking_used_percentage}% &nbsp;{t(" change in last 30 days")}
                            </span> */}
          {/* <span
                style={{
                  float: "right",
                  marginTop: "-60px",
                  marginRight: "-8px",
                }}
              >
                <div style={{ width: "20%" }}>
                  <img
                    className="CustomerImage "
                    src={process.env.PUBLIC_URL + "/images/customer/inactivecustomer.svg"}
                    alt="customer"
                  />
                </div>
              </span>
            </div>
          </Card> */}
          <Card className="customercard card-custom total-active-rules cards">
            <div className="diagonal-line"></div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p
                style={{
                  backgroundColor: "orange",
                  padding: "3px",
                  borderRadius: "50%",
                  width: "8px",
                  height: "8px",
                  marginRight: "8px",
                }}
              ></p>
              <p>{t("Booking Used")}</p>
            </div>
            <Card.Title>
              <b>{bookingUsedCount !== undefined ? bookingUsedCount : 0}</b>
            </Card.Title>
          </Card>
          <br />
        </div>
        <div className="col-lg-3 col-sm-12">
          {/* <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>{t("BOOKING EXPIRED")}</Card.Text>
              <Card.Title>
                <b>{bookingExpiredCount !== undefined ? bookingExpiredCount : 0}</b>
              </Card.Title> */}
          {/* <span style={{ fontSize: "12px" }}>
                                {Total_booking_expired_change_symbol === "+" ? <img src={process.env.PUBLIC_URL + "/images/admin/profit.svg"} className="profitloss" alt="" /> : <img src={process.env.PUBLIC_URL + "/images/admin/loss.svg"} className="profitloss" alt="" />}
                                &nbsp;&nbsp;{Total_booking_expired_percentage}% &nbsp;{t(" change in last 30 days")}
                            </span> */}
          {/* <span
                style={{
                  float: "right",
                  marginTop: "-60px",
                  marginRight: "-8px",
                }}
              >
                <div style={{ width: "20%" }}>
                  <img
                    className="CustomerImage "
                    src={process.env.PUBLIC_URL + "/images/customer/inactivecustomer.svg"}
                    alt="customer"
                  />
                </div>
              </span>
            </div>
          </Card> */}
          <Card className="customercard card-custom total-draft cards">
            <div className="diagonal-line"></div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p
                style={{
                  backgroundColor: "grey",
                  padding: "3px",
                  borderRadius: "50%",
                  width: "8px",
                  height: "8px",
                  marginRight: "8px",
                }}
              ></p>
              <p>{t("Booking Expired")}</p>
            </div>
            <Card.Title>
              <b>{bookingExpiredCount !== undefined ? bookingExpiredCount : 0}</b>
            </Card.Title>
          </Card>
          <br />
        </div>
      </div>

      <div className="row mt-2">
        <div className="col-lg-12 col-sm-12">
          <Card className="statuscard mb-4 mt-3" style={{ width: "98%" }}>
            <Card.Header>{t("Booking Statistics")} </Card.Header>
            <CustomerBookingGraph />
          </Card>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-12 col-sm-12">
          <CustomerBookingList {...props} allData={allData} />
        </div>
      </div>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.evse.isEvseLoading,
    // bookingCardPercentageRes: state.evse.fetchBookingPercentage,
    customerBookingCardCountRes: state.evse.fetchAllCustomerBookingCardCount,
    delete: state.evse.deleteBooking,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    // bookingPercentage: () => dispatch(fetchBookingPercentage()),
    customerBookingCardCountApi: (currentPage, pageSize) =>
      dispatch(fetchAllCustomerBookingCardCountAction(currentPage, pageSize)),
    BookingDelete: (reserveid) => dispatch(DeleteBookingAPI(reserveid)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CustomerBookingCard);
