const initState = {
  isLoading: false,
  save_ticket: '',
  fetch_all_ticket: '',
  fetch_single_ticket: '',
  update_ticket: '',

}
const ticketReducer = (state = initState, action) => {
  switch (action.type) {
    case 'LOAD_TICKET':
      return { ...state, isLoading: true };
    case 'SAVE_NEW_TICKET':
      return { ...state, save_ticket: action.payload, isLoading: false };
    case 'FETCH_ALL_TICKET':
      return { ...state, fetch_all_ticket: action.payload, isLoading: false };
    case 'FETCH_SINGLE_VIEW_TICKET':
      return { ...state, fetch_single_ticket: action.payload, isLoading: false };
    case 'UPDATE_TICKET_REDUCER':
      return { ...state, update_ticket: action.payload, isLoading: false };
    default:
      return state;
  }
}
export default ticketReducer;

