import React from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useState, useEffect } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import PureBreadcrumbs from "../../breadcrums";
import "../loyalty.css";
import { fetchRuleDropdownApi, saveRule } from "../../../../store/actions/loyaltyAction";
import { Bounce, ToastContainer, toast } from "react-toastify";
import { HandleKeyPress } from "../../resuableComponent/handleKeyPress";

const CreateRule = (props) => {
    const { t } = useTranslation();
    const [isMatching, setIsMatching] = useState(false);
    const [isToastVisible, setIsToastVisible] = useState(false);

    useEffect(() => {
        props.fetchDropdown();
    }, []);

    const ruleSchema = Yup.object().shape({
        rule_name: Yup.string().required("Rule Name is required"),
        // status: Yup.boolean().required("Status is required"),
        ruleGroup: Yup.array().of(
            Yup.object().shape({
                ruletype: Yup.string().required("Rule type is required"),
                rulevalue: Yup.number().required("Rule value is required").typeError("Rule value is required"),
                points: Yup.number().required("Input value is required").typeError("Input value is required"),
            })
        ),
    });

    const { register, control, getValues, setValue, watch, handleSubmit, reset, formState: { errors, isValid }, } = useForm({ resolver: yupResolver(ruleSchema), mode: "all" });
    const { fields: fieldsList1, remove: removeList1, append: appendList1 } = useFieldArray({ control, name: "ruleGroup" });
    const elementsTime = watch("ruleGroup");
    // console.log("elementsTime", elementsTime);
    const extractedRuleType = elementsTime && elementsTime.map(item => item.ruletype);
    // console.log(errors)

    useEffect(() => {
        // Update the watch function whenever the form state changes.
        // This ensures that the watch function reflects the latest state of the form.
        // This is for dynamic rule value lables.
        watch();
    }, [fieldsList1]);

    useEffect(() => {
        appendList1({ ruletype: "", rulevalue: "", points: "" });
    }, []);

    // const handleRuleType = (e, index) => {
    //     // const previousRuleType = currentRuleTypes[index - 1];
    //     const selectedRuleType = e.target.value;
    //     if (extractedRuleType.includes(selectedRuleType)) {
    //         setIsMatching(true);
    //     } else {
    //         setIsMatching(false);
    //     }
    // };

    const onSubmit = async (data) => {
        // console.log(data)
        props.saveRule(data);
    };

    useEffect(() => {
        if (props.saveRuleRes.statuscode === 200) {
            setIsToastVisible(true);
            toast.success('New loyalty rule created successfully', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    window.location.reload();
                },
            });
        } else if (props.saveRuleRes.statuscode === 405) {
            setIsToastVisible(true);
            toast.error(`❌ ${props.saveRuleRes.error}`, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    window.location.reload();
                },
            });
        }
    }, [props.saveRuleRes]);

    return (
        <>
            <div className="container-fluid">
                {isToastVisible && <div className="overlay" />}
                <ToastContainer
                    position="top-center"
                    autoClose={20}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                    style={{ top: "10%", width: "auto", zIndex: "9999" }}
                />
                <PureBreadcrumbs />
                <Row className="d-flex justify-content-center align-items-center pt-5">
                    <Col xs={12} md={12} lg={12}>
                        <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                            <div>
                                <Card className="d-flex justify-content-center align-items-center">
                                    <Card.Body>
                                        <h6 className="text-center"> {t("Add New Rules")}</h6>
                                    </Card.Body>
                                </Card>
                                <Row className="tariffcard ml-0 mr-0">
                                    <Col lg={12}>
                                        <div className="col-lg">
                                            <div className="form-floating">
                                                <label>
                                                    {t("Rule Name")}
                                                    <span className="mandatory-field">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className={`form-control gray-border ${errors.rule_name ? "is-invalid" : ""
                                                        }`}
                                                    name={`rule_name`}
                                                    id="floatingInputplan_name"
                                                    placeholder={t("Rule Name")}
                                                    {...register(`rule_name`)}
                                                />
                                                <div className="invalid-feedback">
                                                    {errors.rule_name?.message}
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                {fieldsList1.map((item, i) => (
                                    <Card className="" key={i + 1}>
                                        {i === 0 ? (
                                            <h6 className="text-start p-3">
                                                <b>{t("Add New Rules")}</b>
                                            </h6>
                                        ) : (
                                            ""
                                        )}
                                        <Card.Body>
                                            <div className="row g-2 mt-3 ">
                                                <div className="col-md">
                                                    <div className="form-floating">
                                                        <label>
                                                            {t("Rule Type")}
                                                            <span className="mandatory-field">*</span>
                                                        </label>
                                                        <select
                                                            className={`form-select form-control ${errors.ruleGroup?.[i]?.ruletype ? "is-invalid" : ""}`}
                                                            name={`ruleGroup[${i}].ruletype`}
                                                            id="floatingSelectGridruletype"
                                                            {...register(`ruleGroup[${i}].ruletype`)}
                                                        // onChange={(e) => handleRuleType(e, i)}
                                                        >
                                                            <option value={""}>{t("Select Rule Type")}</option>
                                                            {props.dropDownRes && props.dropDownRes.ruletype?.map((v, k) => {
                                                                const unitSelected = fieldsList1.slice(0, i).some(field => field.ruletype === v.value);
                                                                return !unitSelected && <option key={v.key} value={v.value}>{t(v.key)}</option>;
                                                            })}

                                                        </select>
                                                    </div>
                                                    <div className="invalid-feedback">
                                                        {errors.ruleGroup?.[i]?.ruletype?.message}
                                                    </div>

                                                </div>


                                                <div className="col-md">
                                                    <div className="form-floating">
                                                        {watch(`ruleGroup.${i}.ruletype`) === "" && <label>{t("Rule Value")}<span className='mandatory-field'>*</span></label>}
                                                        {watch(`ruleGroup.${i}.ruletype`) === "kwh" && <label>{t("Rule Value ( kWh )")}<span className='mandatory-field'>*</span></label>}
                                                        {watch(`ruleGroup.${i}.ruletype`) === "time" && <label>{t("Rule Value ( Hours )")}<span className='mandatory-field'>*</span></label>}
                                                        {watch(`ruleGroup.${i}.ruletype`) === "flat" && <label>{t("Rule Value ( Flat )")}<span className='mandatory-field'>*</span></label>}
                                                        <input
                                                            type="number"
                                                            className={`form-control gray-border ${errors.rulevalue ? "is-invalid" : ""
                                                                }`}
                                                            placeholder={t("Rule Value")}
                                                            name={`ruleGroup[${i}].rulevalue`}
                                                            id="floatingSelectGridrulevalue"
                                                            min={1}
                                                            {...register(`ruleGroup[${i}].rulevalue`)}
                                                            onKeyPress={HandleKeyPress}
                                                        />
                                                    </div>
                                                    <div className="invalid-feedback">
                                                        {errors.ruleGroup && errors.ruleGroup[0]?.rulevalue?.message}                                                    </div>
                                                </div>
                                                <div className="col-sm mt-4" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <div className="form-floating" >
                                                        {t("Contains")}:
                                                    </div>

                                                </div>
                                                <div className="col-md">
                                                    <div className="form-floating">
                                                        <label>
                                                            {t("Input value points")}
                                                            <span className="mandatory-field">*</span>
                                                        </label>
                                                        <input
                                                            type="number"
                                                            className={`form-control gray-border ${errors.ruleGroup?.[i]?.points ? "is-invalid" : ""
                                                                }`}
                                                            placeholder={t("Input Value")}
                                                            name={`ruleGroup[${i}].points`}
                                                            id="floatingSelectGridpoints"
                                                            min={0}
                                                            {...register(`ruleGroup[${i}].points`)}
                                                            onKeyPress={HandleKeyPress}
                                                        />
                                                    </div>
                                                    <div className="invalid-feedback">
                                                        {errors.ruleGroup?.[i]?.points?.message}
                                                    </div>
                                                </div>
                                            </div>
                                        </Card.Body>
                                        <div className={`d-grid gap-2 p-1 ${isMatching ? "ml-3 d-flex justify-content-between" : "d-flex justify-content-end"}`}>
                                            {/* {isMatching && (
                                                <p className="text-danger">{t("Rule type is already define.Please choose another rule type.")}</p>
                                            )} */}
                                            <button
                                                className="btn  btn-lg"
                                                type="button"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    removeList1(i);
                                                    setIsMatching(false);
                                                }}
                                            >
                                                {" "}
                                                <i
                                                    className="far fa-trash-alt"
                                                    style={{ color: "red" }}
                                                ></i>
                                            </button>
                                        </div>
                                    </Card>
                                ))}

                                <Card.Footer className="row ml-0 mr-0 d-flex justify-content-between">
                                    {elementsTime && elementsTime.length <= 2 && (
                                        <Col lg={6} md={6} className="d-flex justify-content-center justify-content-md-start mt-2">
                                            <button
                                                className="btn btn-dark"
                                                type="button"
                                                disabled={isMatching}
                                                onClick={() => appendList1({ ruletype: "", rulevalue: "", points: "" })}
                                            >
                                                (+) {t("Add Another Condition")}
                                            </button>
                                        </Col>
                                    )}

                                    {/* Submit Button */}
                                    {
                                        props.loading ?
                                            (
                                                <Col lg={6} md={6} sm={12} xs={12} className="d-flex justify-content-center justify-content-md-end mt-2">
                                                    <button className="lgn-btn" type="button" disabled>
                                                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                        {t("Saving...")}
                                                    </button>
                                                </Col>
                                            )
                                            :
                                            (
                                                <Col lg={6} md={6} sm={12} xs={12} className="d-flex justify-content-center justify-content-md-end mt-2">
                                                    <button
                                                        className="lgn-btn"
                                                        type="submit"
                                                        disabled={elementsTime && elementsTime.length === 0}
                                                        onClick={handleSubmit(onSubmit)}
                                                    >
                                                        {t("CREATE RULE")}
                                                    </button>
                                                </Col>
                                            )}
                                </Card.Footer>
                            </div>
                        </form>
                    </Col>
                </Row>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        loading: state.loyalty.isLoading,
        saveRuleRes: state.loyalty.createRule,
        dropDownRes: state.loyalty.fetchRuleDropdown,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        fetchDropdown: () => dispatch(fetchRuleDropdownApi()),
        saveRule: (record) => dispatch(saveRule(record)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateRule);
