import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { fetchAllStationStatusDetailAction, fetchStationStatusDetailAction } from "../../../../store/actions/evseAction";
import { Container, Row, Col, Card } from "react-bootstrap";
import PureBreadcrumbs from "../../breadcrums";
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { FaEye } from 'react-icons/fa';
import CustomPaginationTable from './customPaginationTable';
import MyDocument from '../../pdf/invoice';
import ChargerPdf from './chargerStatusPdf';
// import CustomPaginationTable from '';

function InsightChargerStatus(props) {
    const { t } = useTranslation();
    const { status } = useParams();
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [search, setSearch] = useState('');
    const [filteredData, setFilteredData] = useState([]);


    const data = props.list && props.list.data;
    const reportCount = props.list && props.list.station_count;
    const defaultPageSize = 10;
    const pageCount = Math.ceil(reportCount / defaultPageSize);

    useEffect(() => {
        props.all(status, currentPage, pageSize);
        props.allStationStatusList(status, currentPage, reportCount);
    }, [status, currentPage, pageSize, reportCount]);


    const allData = props.allStationStatusListRes && props.allStationStatusListRes.data;


    const columns = useMemo(
        () => [
            {
                Header: 'Evse ID / Connector ID / Status',
                accessor: 'evse_id',
                Cell: (cell) => {
                    const item = cell
                    const evses = item.item?.evses;
                    // console.log("evses", evses);

                    if (!evses || evses.length === 0) {
                        return <>{t("No data available")}</>;
                    }

                    return (
                        <div className="evse-list">
                            {evses?.map((evse, evseIndex) => {
                                // Filter connectors based on the status condition
                                const filteredConnectors = evse?.connectors?.filter(connector => status === "All" || status === connector.status);

                                // Only render evse-item if there are filtered connectors
                                return filteredConnectors?.length > 0 && (
                                    <div key={evseIndex} className="evse-item">
                                        {filteredConnectors?.map((connector, connectorIndex) => (
                                            <div key={connectorIndex} className="connector-item">
                                                <div className="evse-id">{connector.evse_id}</div>
                                                <div className="connector-id">{connector.id}</div>
                                                <div className={`status ${connector.status.replace(/\s+/g, '-').toLowerCase()}`}>
                                                    {t(connector.status)}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                );
                            })}
                        </div>
                    );
                },
            },
            { Header: 'Station Name', accessor: 'name' },
            { Header: 'Address', accessor: 'address' },
            { Header: 'City', accessor: 'city' },
            { Header: 'State', accessor: 'state' },
            // { Header: 'Status', accessor: 'status' },
            {
                Header: 'Actions',
                accessor: 'stationid',
                Cell: (cell) => {
                    const item = cell
                    // console.log("item:", item);

                    return (
                        <a href={`/dashboard/viewsingleevse/${item.cell}`} title="View">
                            <FaEye />
                        </a>
                    );
                },
            }
        ],
        []
    );

    return (
        <Container fluid>
            <PureBreadcrumbs />

            <br />
            <Row className="mt-4">
                <Col>
                    <Card className="customercard">
                        <Card.Header className='d-flex justify-content-between'>
                            <strong>
                                {t("View")} {status} {t("EVSE Details")}
                            </strong>
                            {!props.loading &&
                                <ChargerPdf
                                    {...props}
                                    status={status}
                                    data={allData}
                                ></ChargerPdf>
                            }
                        </Card.Header>
                        <CustomPaginationTable
                            data={data}
                            searchData={allData}
                            pageCount={pageCount}
                            columns={columns}
                            search={search}
                            setSearch={setSearch}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            pageSize={pageSize}
                            setPageSize={setPageSize}
                            filteredData={filteredData}
                            setFilteredData={setFilteredData}
                            loading={props.loading}
                            t={t}
                        />
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

const mapStateToProps = (state) => ({
    loading: state.evse.isEvseLoading,
    list: state.evse.allEvseStatus,
    allStationStatusListRes: state.evse.fetchAllStationStatus
});

const mapDispatchToProps = (dispatch) => ({
    all: (status, currentPage, pageSize) => dispatch(fetchStationStatusDetailAction(status, currentPage, pageSize)),
    allStationStatusList: (status, currentPage, pageCount) => dispatch(fetchAllStationStatusDetailAction(status, currentPage, pageCount)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InsightChargerStatus);
