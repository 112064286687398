// TranslationLoader.js

import { useEffect } from "react";
import i18n from "./i18n";
import { connect } from "react-redux";
import { fetchTranslation } from "./store/actions/insightAction";
import { useTranslation } from "react-i18next";
const TranslationLoader = (props) => {
  const { i18n } = useTranslation();

  useEffect(() => {
    props.getTranslationData();
  }, [i18n.language]);

  const TranslationData = props.fetchTranslationJson && props.fetchTranslationJson.data;
  // console.log("translation data: " ,TranslationData)

  useEffect(() => {
    if (Array.isArray(TranslationData)) {
      TranslationData &&
        TranslationData.map((translations) =>
          Object?.keys(translations)?.forEach((language) => {
            i18n.addResourceBundle(language, "translation", translations[language].translation, true, true);
          })
        );
    }
  }, [TranslationData]);

  return null;
};
const mapStateToProps = (state) => {
  return {
    loading: state.insight.isLoading,
    fetchTranslationJson: state.insight.getTranslation,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getTranslationData: () => dispatch(fetchTranslation()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TranslationLoader);
