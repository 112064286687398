import React from "react";
import { GoogleMap, Marker, MarkerClusterer, useLoadScript } from "@react-google-maps/api";
import available_icon from '../../../assets/images/available_icon.png';
import in_use_icon from '../../../assets/images/in_use.svg';
import cs_icon from '../../../assets/images/commingsoon.svg';
import maintenance_icon from '../../../assets/images/maintenance.svg';

// Define the libraries array as a constant outside the component
const libraries = ["places"];

function CustomMarker({ location, clusterer }) {
    const { status, stationid, name } = location;
    const { latitude, longitude } = location.coordinates;

    const iconUrls = {
        "Available": available_icon,
        "In use": in_use_icon,
        "Coming soon": cs_icon,
        "Maintenance": maintenance_icon,
        "Inoperative": maintenance_icon,
    };

    const iconUrl = iconUrls[status];
    if (status !== "Discovery") {
        return (
            <Marker
                key={stationid}
                position={{ lat: latitude, lng: longitude }}
                icon={{
                    url: iconUrl,
                    scaledSize: new window.google.maps.Size(30, 35),
                }}
                title={name}
                onClick={() => window.location.href = `/dashboard/viewsingleevse/${stationid}`}
                clusterer={clusterer}
            />
        );
    }
    return null;
}

function EVSEMap({ stations, center, zoom }) {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries, // Reference the static `libraries` array
    });

    // console.log("isLoaded", isLoaded);
    // console.log("loadError", loadError);

    const mapContainerStyle = {
        height: "81vh",
        width: "100%",
    };

    if (loadError) return <div>Error loading map. Please try again later.</div>;
    if (!isLoaded) return <div>Loading map...</div>;

    return (
        <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={center}
            zoom={zoom}
        >
            <MarkerClusterer
                options={{ imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m' }}
            >
                {(clusterer) => (
                    <>
                        {stations && stations.map((location) => (
                            <CustomMarker
                                key={location.stationid}
                                location={location}
                                clusterer={clusterer}
                            />
                        ))}
                    </>
                )}
            </MarkerClusterer>
        </GoogleMap>
    );
}

export default EVSEMap;
