import React, { useMemo, useEffect, useState } from "react";
import { connect } from "react-redux";
import "../../reacttable/reactTable.css";
import Table from "../../reacttable/table";
import { activateRfid, allRFIDAction, deactivateRfid } from "../../../../store/actions/customerActions";
import { FaEdit, FaEye } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import moment from "moment";
import CustomReportPaginationTable from "../../report/adminReport/customReportPaginationTable";

function RFIDList(props) {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const document_count = props.list && props.list.document_count;
  const defaultPageSize = 10;
  const pageCount = Math.ceil(document_count / defaultPageSize)
  const { allData } = props;


  useEffect(() => {
    props.all(currentPage, pageSize);
  }, [currentPage, pageSize]);


  const data = props.list && props.list.rfids;
  // console.log("rfid list props", data);

  const activateStation = (id) => {
    // console.log(id);
    props.activateRFID(id);
  };

  if (props.activeRfid.statuscode === 200) {
    window.location.reload();
  }

  const deactivateStation = (id) => {
    // console.log(id);
    props.deactivateRFID(id);

  };

  if (props.deactivateRfid.statuscode === 200) {
    window.location.reload();
  }


  const columns = useMemo(
    () => [
      {
        Header: "RFID Serial Number",
        accessor: "rfid",
      },
      {
        Header: "Commission Date",
        accessor: "commission",
        // accessor: (d) => {
        //   return moment(d.commission).local().format("DD-MM-YYYY");
        // },
      },
      // {
      //   Header: "Station Name",
      //   accessor: "station",
      //   Cell: (station) => {
      //     return (
      //       <div>
      //         <span>
      //           {station.value ? (
      //             <span >{station.value}</span>
      //           ) : (
      //             <span >Open single view</span>
      //           )}
      //         </span>
      //       </div>
      //     );
      //   }
      // },
      {
        Header: "Amount",
        accessor: "amount",
      },
      {
        Header: "kWh",
        accessor: "kwh",
      },
      {
        Header: "Time",
        accessor: "time",
      },
      {
        Header: "Currency",
        accessor: "currency",
      },
      {
        Header: "Is payment collected offline ?",
        accessor: "offline_payment",
        Cell: (item) => {
          const offline_payment = item.cell
          return (
            <div>
              <span>
                {offline_payment ? (
                  <span className="text-success">Yes</span>
                ) : (
                  <span className="text-danger">No</span>
                )}
              </span>
            </div>
          );
        },
      },
      {
        Header: "Is Free Charging?",
        accessor: "free_charging",
        Cell: (item) => {
          const free_charging = item.cell
          return (
            <div>
              <span>
                {free_charging ? (
                  <span className="text-success">Yes</span>
                ) : (
                  <span className="text-danger">No</span>
                )}
              </span>
            </div>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: (item) => {
          const status = item.cell
          return (
            <div>
              <span>
                {status === "Active" ? (
                  <span className="text-success">Active</span>
                ) : (
                  <span className="text-danger">Inactive</span>
                )}
              </span>
            </div>
          );
        },
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: (item) => {
          const id = item.cell
          return (
            <div>
              {<span>
                <a
                  href={`/dashboard/viewSingleRfid/${id}`}
                  rel="tooltip"
                  title="Edit"
                >
                  <FaEye />
                </a>
              </span>}
              &nbsp;&nbsp;
              &nbsp;&nbsp;
              {<span>
                <a
                  href={`/dashboard/updaterfid/${id}`}
                  rel="tooltip"
                  title="Edit"
                >
                  <FaEdit />
                </a>
              </span>}
              &nbsp;&nbsp;
              &nbsp;&nbsp;
              <span style={{ cursor: "pointer" }}>
                <TiTick
                  onClick={() => activateStation(id)}
                  title="Activate"
                />
              </span>
              &nbsp;&nbsp;
              &nbsp;&nbsp;
              <span style={{ cursor: "pointer" }}>
                <AiOutlineCloseCircle
                  onClick={() => deactivateStation(id)}
                  title="De-Activate"
                />
              </span>

            </div>
          );
        },
      },
    ],
    [data]
  );

  const initialVisibleColumns =
    [
      'rfid', 'commission', 'amount', 'kwh', 'time',
      'currency', 'offline_payment', 'free_charging', 'status', 'id'
    ];

  return (
    <div>
      {props.loading ? <h3 style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "350px",
      }}>Loading data...</h3> :
        data && data.length > 0 ? (
          <div>
            {/* <Table data={data} columns={columns}></Table> */}
            <CustomReportPaginationTable
              data={data}
              allData={allData}
              pageCount={pageCount}
              columns={columns}
              search={search}
              setSearch={setSearch}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              filteredData={filteredData}
              setFilteredData={setFilteredData}
              loading={props.loading}
              initialVisibleColumns={initialVisibleColumns}
              t={t}
            />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h3 className="loader">{(" No data found")}</h3>
          </div>
        )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.customer.isLoading,
    list: state.customer.fetchAllRFID,
    activeRfid: state.customer.activateRfid,
    deactivateRfid: state.customer.deactivateRfid,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    all: (currentPage, pageSize) => dispatch(allRFIDAction(currentPage, pageSize)),
    activateRFID: (id) => dispatch(activateRfid(id)),
    deactivateRFID: (id) => dispatch(deactivateRfid(id)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RFIDList);
