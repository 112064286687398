const initState = {
    isEvseLoading: false,
    allevse: '',
    allEvseCardCount: '',
    subnetwork: '',
    network: '',
    saveevse: '',
    allEvseStatus: '',
    ocpplist: '',
    viewstation: '',
    updatestation: '',
    bootnotify: '',
    activatestation: '',
    deactivatestation: '',
    livecharging: '',
    fetchparkingtype: '',
    fetchconnectortype: '',
    fetchpowerrange: '',
    fetchchargerstatus: '',
    fetchalluserbooking: '',
    chargerslots: '',
    savechargerslots: '',
    fetchBookingCount: '',
    fetchBookingPercentage: '',
    saveV2station: '',
    updateV2station: '',
    livecharginggraph: '',
    evsepercentagechange: '',
    timemaster: '',
    fetchNameAndEvseId: '',
    fetchEvseId: '',
    saveRoamingStation: '',
    fetchEmailDuration: '',
    saveConfigured: '',
    fetchEmailConfig: '',
    deleteBooking: '',
    getEvseID: '',
    updateBooking: '',
    deleteBookingChargerSlots: '',
    saveStationGroups: '',
    fetchAllStationGroups: '',
    fetchSingleStationGroup: '',
    updateStationGroups: '',
    activateStationGroups: '',
    deactivateStationGroups: '',
    saveStationGroupWithTariff: '',
    activateStationGroupWithTariff: '',
    deactivateStationGroupWithTariff: '',
    fetchAllStationGroupWithTariff: '',
    fetchSingleStationGroupWithTariff: '',
    updateStationGroupWithTariff: '',
    fetchAllDaysMaster: '',
    stationDelete: '',
    clearCache: '',
    fetchAllStationStatus: '',
    fetchAllOcppNotificationDetails: '',
    fetchAllCustomerBookingCardCount: '',
}
const evseReducer = (state = initState, action) => {
    switch (action.type) {
        case 'LOAD_EVSE':
            return { ...state, isEvseLoading: true }
        case 'SAVE_EVSE':
            return { ...state, saveevse: action.payload, isEvseLoading: false };
        case 'SAVE_V2_STATION':
            return { ...state, saveV2station: action.payload, isEvseLoading: false };
        case 'UPDATE_V2_STATION':
            return { ...state, updateV2station: action.payload, isEvseLoading: false };
        case 'LOAD_EVSE_LIST':
            return { ...state, allevse: action.payload, isEvseLoading: false };
        case 'FETCH_EVSE_CARD_COUNT_REDUCER':
            return { ...state, allEvseCardCount: action.payload, isEvseLoading: false };
        case 'LOAD_SUB_NETWORK':
            return { ...state, subnetwork: action.payload, isEvseLoading: false };
        case 'LOAD_NETWORK':
            return { ...state, network: action.payload, isEvseLoading: false };
        case 'LOAD_STATION_EVSE_STATUS_ALL':
            return { ...state, allEvseStatus: action.payload, isEvseLoading: false };
        case 'LOAD_OCPP_NOTI_DATA':
            return { ...state, ocpplist: action.payload, isEvseLoading: false };
        case 'LOAD_EVSE_SINGLE':
            return { ...state, viewstation: action.payload, isEvseLoading: false };
        case 'UPDATE_STATION':
            return { ...state, updatestation: action.payload, isEvseLoading: false };
        case 'BOOT_NOTIFY':
            return { ...state, bootnotify: action.payload, isEvseLoading: false };
        case 'ACTIVATE_STATION':
            return { ...state, activatestation: action.payload, isEvseLoading: false };
        case 'DEACTIVATE_STATION':
            return { ...state, deactivatestation: action.payload, isEvseLoading: false };
        case 'LIVE_CHARGING':
            return { ...state, livecharging: action.payload, isEvseLoading: false };
        case 'FETCH_ALL_PARKING_TYPE':
            return { ...state, fetchparkingtype: action.payload, isEvseLoading: false };
        case 'FETCH_ALL_CONNECTOR_TYPE':
            return { ...state, fetchconnectortype: action.payload, isEvseLoading: false };
        case 'FETCH_ALL_POWER_RANGE':
            return { ...state, fetchpowerrange: action.payload, isEvseLoading: false };
        case 'FETCH_ALL_CHARGER_STATUS':
            return { ...state, fetchchargerstatus: action.payload, isEvseLoading: false };
        case 'FETCH_CUSTOMER_ALL_BOOKING_DATA':
            return { ...state, fetchalluserbooking: action.payload, isEvseLoading: false };
        case 'FETCH_CHARGER_BOOKING_SLOTS':
            return { ...state, chargerslots: action.payload, isEvseLoading: false };
        case 'SAVE_CHARGER_BOOKING_SLOTS':
            return { ...state, savechargerslots: action.payload, isEvseLoading: false };
        case 'FETCH_BOOKING_DATA':
            return { ...state, fetchBookingCount: action.payload, isEvseLoading: false };
        case 'FETCH_BOOKING_PERCENTAGE':
            return { ...state, fetchBookingPercentage: action.payload, isEvseLoading: false };
        case 'FETCH_CHARGING_GRAPH':
            return { ...state, livecharginggraph: action.payload, isEvseLoading: false };
        case 'EVSE_PERCENTAGE_CHANGE':
            return { ...state, evsepercentagechange: action.payload, isEvseLoading: false };
        case 'TIME_MASTER':
            return { ...state, timemaster: action.payload, isEvseLoading: false };
        case 'FETCH_LOCATION_NAME_AND_EVSEID':
            return { ...state, fetchNameAndEvseId: action.payload, isEvseLoading: false };
        case 'FETCH_EVSEID':
            return { ...state, fetchEvseId: action.payload, isEvseLoading: false };
        case 'SAVE_ROAMING_STATIONS':
            return { ...state, saveRoamingStation: action.payload, isEvseLoading: false };
        case 'FETCH_EMAIL_DURATION':
            return { ...state, fetchEmailDuration: action.payload, isEvseLoading: false };
        case 'SAVE_CONFIGURED_API':
            return { ...state, saveConfigured: action.payload, isEvseLoading: false };
        case 'FETCH_EMAIL_CONFIG_API':
            return { ...state, fetchEmailConfig: action.payload, isEvseLoading: false };
        case 'BOOKING_DELETE_API':
            return { ...state, deleteBooking: action.payload, isEvseLoading: false };
        case 'FETCH_EVSE_ID':
            return { ...state, getEvseID: action.payload, isEvseLoading: false };
        case 'UPDATE_BOOKING_DATA':
            return { ...state, updateBooking: action.payload, isEvseLoading: false };
        case 'DELETE_BOOKING_CHARGER_SLOTS':
            return { ...state, deleteBookingChargerSlots: action.payload, isEvseLoading: false };
        case 'SAVE_STATION_GROUP_API':
            return { ...state, saveStationGroups: action.payload, isEvseLoading: false };
        case 'FETCH_ALL_STATION_GROUP_API':
            return { ...state, fetchAllStationGroups: action.payload, isEvseLoading: false };
        case 'FETCH_SINGLE_STATION_GROUP_API':
            return { ...state, fetchSingleStationGroup: action.payload, isEvseLoading: false };
        case 'UPDATE_STATION_GROUP':
            return { ...state, updateStationGroups: action.payload, isEvseLoading: false };
        case 'ACTIVATE_STATION_GROUP':
            return { ...state, activateStationGroups: action.payload, isEvseLoading: false };
        case 'DEACTIVATE_STATION_GROUP':
            return { ...state, deactivateStationGroups: action.payload, isEvseLoading: false };
        case 'SAVE_STATION_GROUP_WITH_TARIFF':
            return { ...state, saveStationGroupWithTariff: action.payload, isEvseLoading: false };
        case 'ACTIVATE_STATION_GROUP_WITH_TARIFF':
            return { ...state, activateStationGroupWithTariff: action.payload, isEvseLoading: false };
        case 'DEACTIVATE_STATION_GROUP_WITH_TARIFF':
            return { ...state, deactivateStationGroupWithTariff: action.payload, isEvseLoading: false };
        case 'FETCH_ALL_STATION_GROUP_WITH_TARIFF':
            return { ...state, fetchAllStationGroupWithTariff: action.payload, isEvseLoading: false };
        case 'FETCH_SINGLE_STATION_GROUP_WITH_TARIFF':
            return { ...state, fetchSingleStationGroupWithTariff: action.payload, isEvseLoading: false };
        case 'UPDATE_STATION_GROUP_WITH_TARIFF':
            return { ...state, updateStationGroupWithTariff: action.payload, isEvseLoading: false };
        case 'FETCH_ALL_DAY_MASTER_API':
            return { ...state, fetchAllDaysMaster: action.payload, isEvseLoading: false };
        case 'STATION_DELETE_API':
            return { ...state, stationDelete: action.payload, isEvseLoading: false };
        case 'CLEARED_CACHE_API':
            return { ...state, clearCache: action.payload, isEvseLoading: false };
        case 'FETCH_ALL_STATION_STATUS':
            return { ...state, fetchAllStationStatus: action.payload, isEvseLoading: false };
        case 'FETCH_ALL_OCPP_NOTIFICATION_STATUS_DETAILS':
            return { ...state, fetchAllOcppNotificationDetails: action.payload, isEvseLoading: false };
        case 'FETCH_ALL_CUSTOMER_BOOKING_CARD_COUNT_REDUCER':
            return { ...state, fetchAllCustomerBookingCardCount: action.payload, isEvseLoading: false };

        default:
            return state;
    }
}
export default evseReducer;