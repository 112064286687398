import React, { useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";
import "react-dates/initialize";
import { useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";
import MyOwnerDocument from "../../pdf/own_invoice";
import {
  fetchMyReportCardCountAction,
  fetchMyReportReport,
  fetchReportCsvData,
  reportPercentageChange,
} from "../../../../store/actions/reportActions";
import { useTranslation } from "react-i18next";
import CustomPaginationTable from "../../evse/status/customPaginationTable";
import { FaEye } from "react-icons/fa";
import CustomReportPaginationTable from "../adminReport/customReportPaginationTable";

function EnterpriseReportAll(props) {
  const { t } = useTranslation();
  const currency = process.env.REACT_APP_CURRENCY;
  // const [startDate, setStartDate] = useState(new Date(Date.now() - (3600 * 1000 * 720)));
  // const [endDate, setEndDate] = useState(new Date(Date.now() + (3600 * 1000 * 24)));
  const oneWeekInMilliseconds = 6 * 24 * 60 * 60 * 1000; // Number of milliseconds in a week
  const currentDate = new Date(); // Get the current date and time
  const tomorrowDate = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000); // Get tomorrow's date
  const [startDate, setStartDate] = useState(new Date(currentDate.getTime() - oneWeekInMilliseconds)); // Set startDate to one week before currentDate
  const [endDate, setEndDate] = useState(tomorrowDate);
  const { role, organisation } = JSON.parse(localStorage.getItem("user"));
  const operator = role === "Admin" ? "All" : organisation;
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const newCardCount = props.reportCountRes && props.reportCountRes;
  // console.log("newCardCount", newCardCount);
  const data = props.all && props.all.data;
  // console.log("data", data)
  const reportCount = newCardCount && newCardCount.report_count;
  const defaultPageSize = 10;
  const pageCount = Math.ceil(reportCount / defaultPageSize);

  useEffect(() => {
    props.fetchPercentageChange();
    props.list(
      startDate.toISOString().substr(0, 10),
      endDate.toISOString().substr(0, 10),
      operator,
      currentPage,
      pageSize
    );
    props.csvDataList(
      startDate.toISOString().substr(0, 10),
      endDate.toISOString().substr(0, 10),
      operator,
      1,
      10000000
    );
    props.fetchReportCardCount(startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10), operator);
  }, [currentPage, pageSize, reportCount]);

  // const { role } = JSON.parse(localStorage.getItem('user'));
  const columns = useMemo(
    () => [
      { Header: "ID", accessor: "charging_id", id: "charging_idd" },
      {
        Header: "Date",
        id: "start_date",
        accessor: "start_date",
      },
      { Header: "Name", accessor: "customer_name" },
      { Header: "Tag ID", accessor: "idtag" },
      { Header: "Customer No.", accessor: "mobile" },
      { Header: "Station Name", accessor: "location" },
      { Header: "ChargeBox ID", accessor: "evse_id" },
      { Header: "Connector ID", accessor: "portno" },
      { Header: "Operator", accessor: "operator" },
      { Header: "City", accessor: "city" },
      { Header: "Start time", accessor: "start_time" },
      { Header: "End time", accessor: "stop_time" },
      { Header: "Charging time", accessor: "consume_time" },
      { Header: "Status", accessor: "status" },
      {
        Header: "Units",
        accessor: "unit",
        Cell: (cell) => {
          // console.log("item:", item);
          return (
            <>
              <div>kWh/minute/flat</div>
            </>
          );
        },
      },
      {
        Header: "Unit Price",
        accessor: "unitprice",
        Cell: (cell) => {
          const d = cell?.item;
          // console.log("d:", d);
          return (
            <>
              <div>
                {d.kwh_price}/{d.time_price}/{d.flat_price}
              </div>
            </>
          );
        },
      },
      { Header: "Total kWh", accessor: "kwh" },
      { Header: "Idle Time", accessor: "idle_consumedTime" },
      { Header: "Idle Price", accessor: "idle_calculated_price" },
      {
        Header: "Is Free charging?",
        accessor: "free_charging",
        Cell: (cell) => {
          const d = cell?.item;
          return (
            <>
              <div>{d.free_charging ? "Yes" : "No"}</div>
            </>
          );
        },
      },
      { Header: "Charging Amount", accessor: "charging_amount" },
      { Header: "Tax Amount", accessor: "tax_amount" },
      { Header: "Total Amount", accessor: "total_amount" },
      {
        Header: "Actions",
        accessor: "charging_id",
        Cell: (cell) => {
          const charging_id = cell.cell;
          const tag_id = cell.item?.idtag;
          return (
            <div>
              <span>
                <a href={`/dashboard/report/single/${charging_id}/${tag_id}`} rel="tooltip" title="View">
                  <FaEye />
                </a>
              </span>
            </div>
          );
        },
      },
    ],
    []
  );

  const initialVisibleColumns = [
    "start_date",
    "customer_name",
    "mobile",
    "free_charging",
    "idtag",
    "stationid",
    "evse_id",
    "portno",
    "start_time",
    "stop_time",
    "consume_time",
    "tax_amount",
    "total_amount",
    "charging_amount",
    "charging_id",
    "status",
  ];

  const headers = [
    { label: "ID", key: "charging_id" },
    { label: "Date", key: "start_date" },
    { label: "Name", key: "customer_name" },
    { label: "Tag ID", key: "idtag" },
    { label: "Customer No.", key: "mobile" },
    { label: "Location", key: "location" },
    { label: "ChargeBox ID", key: "evse_id" },
    { label: "Connector ID", key: "portno" },
    { label: "Operator", key: "operator" },
    { label: "City", key: "city" },
    { label: "Start time", key: "start_time" },
    { label: "End time", key: "stop_time" },
    { label: "Charging time (HH:MM:SS)", key: "consume_time" },
    { label: "Unit (kWh)", key: "kwh_unit" },
    { label: "Unit kWh Price", key: "kwh_price" },
    { label: "Unit (Minute)", key: "time_unit" },
    { label: "Unit Minute Price", key: "time_price" },
    { label: "Unit (Flat)", key: "flat_unit" },
    { label: "Unit Flat Price", key: "flat_price" },
    { label: "Total kWh", key: "kwh" },
    { label: "Free charging?", key: "free_charging" },
    { label: "Charging Amount", key: "charging_amount" },
    { label: "Tax Amount", key: "tax_amount" },
    { label: "Total Amount", key: "total_amount" },
  ];

  const loadNewData = () => {
    props.list(
      startDate.toISOString().substr(0, 10),
      endDate.toISOString().substr(0, 10),
      operator,
      currentPage,
      pageSize
    );
    props.fetchReportCardCount(startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10), operator);
    props.csvDataList(
      startDate.toISOString().substr(0, 10),
      endDate.toISOString().substr(0, 10),
      operator,
      1,
      10000000
    );
  };

  const Total_session_percentage = props.percentagechange && props.percentagechange.total_session_percentage;
  const Total_session_change_symbol = props.percentagechange && props.percentagechange.session_change_symbol;

  const Total_energy_percentage = props.percentagechange && props.percentagechange.total_energy_percentage;
  const Total_energy_change_symbol = props.percentagechange && props.percentagechange.energy_change_symbol;

  const Total_revenue_percentage = props.percentagechange && props.percentagechange.total_revenue_percentage;
  const Total_revenue_change_symbol = props.percentagechange && props.percentagechange.revenue_change_symbol;

  const Total_completed_session_percentage =
    props.percentagechange && props.percentagechange.total_session_completed_percentage;
  const Total_completed_session_symbol =
    props.percentagechange && props.percentagechange.total_session_completed_change_symbol;

  const csvData = props.csvDataRes && props.csvDataRes.data;
  // console.log("csvData: ", csvData);

  return (
    <>
      <Row>
        <Col xs={12} md={3}>
          <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>{t("Session")}</Card.Text>
              <Card.Title>
                <b>{newCardCount.total_session !== 0 ? newCardCount.total_session : 0}</b>
              </Card.Title>
              <span style={{ fontSize: "12px" }}>
                {Total_session_change_symbol === "+" ? (
                  <img src={process.env.PUBLIC_URL + "/images/admin/profit.svg"} className="profitloss" alt="" />
                ) : (
                  <img src={process.env.PUBLIC_URL + "/images/admin/loss.svg"} className="profitloss" alt="" />
                )}
                &nbsp;&nbsp;{Total_session_percentage}% &nbsp;{t(" change in last 30 days")}
              </span>
              <span style={{ float: "right", marginTop: "-60px", marginRight: "-5px" }}>
                <div style={{ width: "50%" }}>
                  <img src={process.env.PUBLIC_URL + "/images/reports/sessions.svg"} alt="" />
                </div>
              </span>
            </div>
          </Card>
        </Col>
        <Col xs={12} md={3}>
          <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>{t("Energy")}</Card.Text>
              <Card.Title>
                <b>{newCardCount && newCardCount.total_energy}</b>
              </Card.Title>
              <span style={{ fontSize: "12px" }}>
                {Total_energy_change_symbol === "+" ? (
                  <img src={process.env.PUBLIC_URL + "/images/admin/profit.svg"} className="profitloss" alt="" />
                ) : (
                  <img src={process.env.PUBLIC_URL + "/images/admin/loss.svg"} className="profitloss" alt="" />
                )}
                &nbsp;&nbsp;{Total_energy_percentage}% &nbsp;{t(" change in last 30 days")}
              </span>
              <span style={{ float: "right", marginTop: "-60px", marginRight: "-5px" }}>
                <div style={{ width: "50%" }}>
                  <img src={process.env.PUBLIC_URL + "/images/reports/energy.svg"} alt="" />
                </div>
              </span>
            </div>
          </Card>
        </Col>
        <Col xs={12} md={3}>
          <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>{t("Revenue")}</Card.Text>
              <Card.Title>
                <b>{newCardCount && newCardCount.total_revenue}</b>
              </Card.Title>
              <span style={{ fontSize: "12px" }}>
                {Total_revenue_change_symbol === "+" ? (
                  <img src={process.env.PUBLIC_URL + "/images/admin/profit.svg"} className="profitloss" alt="" />
                ) : (
                  <img src={process.env.PUBLIC_URL + "/images/admin/loss.svg"} className="profitloss" alt="" />
                )}
                &nbsp;&nbsp;{Total_revenue_percentage}% &nbsp;{t(" change in last 30 days")}
              </span>
              <span style={{ float: "right", marginTop: "-60px", marginRight: "-15px" }}>
                {
                  <div style={{ fontSize: "4rem", paddingRight: "11px", color: "#2ea8cc80", marginTop: "-7px" }}>
                    {currency}
                  </div>
                }
              </span>
            </div>
          </Card>
        </Col>
        <Col xs={12} md={3}>
          <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>{t("Sessions Completed")}</Card.Text>
              <Card.Title>
                <b>{newCardCount && newCardCount.total_completed}</b>
              </Card.Title>
              <span style={{ fontSize: "12px" }}>
                {Total_completed_session_symbol === "+" ? (
                  <img src={process.env.PUBLIC_URL + "/images/admin/profit.svg"} className="profitloss" alt="" />
                ) : (
                  <img src={process.env.PUBLIC_URL + "/images/admin/loss.svg"} className="profitloss" alt="" />
                )}
                &nbsp;&nbsp;{Total_completed_session_percentage}% &nbsp;{t(" change in last 30 days")}
              </span>
              <span style={{ float: "right", marginTop: "-60px", marginRight: "-5px" }}>
                <div style={{ width: "50%" }}>
                  <img src={process.env.PUBLIC_URL + "/images/reports/sessionsstats.svg"} alt="" />
                </div>
              </span>
            </div>
          </Card>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <Card className="customercard">
            <Card.Header>
              {role} {t("Report List")}
            </Card.Header>
            <div className="evse">
              <div className="row justify-content-end mt-3">
                <div className="col-auto me-auto"></div>

                <div className="col-auto">
                  <DatePicker
                    className="gray-border"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    dateFormat="dd/MM/yyyy"
                    maxDate={new Date()}
                  />
                </div>

                <span style={{ paddingTop: "7px" }}> To </span>
                <div className="col-auto">
                  <DatePicker
                    className="gray-border"
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    showDisabledMonthNavigation
                    dateFormat="dd/MM/yyyy"
                    maxDate={tomorrowDate}
                  />
                </div>

                <div className="csvbutton ml-3 ">
                  <button className="btn btn-sm" onClick={loadNewData}>
                    <span
                      style={{
                        fontSize: "16px",
                        color: "#676767",
                        fontWeight: "600",
                      }}
                    >
                      GO
                    </span>
                  </button>
                </div>

                <div className="mt-1 ">
                  {props.csvDataRes && props.csvDataRes.data != null ? (
                    <CSVLink
                      data={csvData}
                      headers={headers}
                      filename={
                        operator +
                        "_" +
                        startDate?.toISOString()?.substr(0, 10) +
                        "_" +
                        endDate?.toISOString()?.substr(0, 10) +
                        `.csv`
                      }
                      target="_blank"
                      // onClick={loadNewData}
                    >
                      <i className="fas fa-2x fa-file-csv"></i>
                    </CSVLink>
                  ) : (
                    <i className="fas fa-2x fa-file-csv"></i>
                  )}
                </div>

                <div className="col-auto">
                  {props.csvDataRes && props.csvDataRes.data != null ? (
                    <MyOwnerDocument
                      {...props}
                      operator={operator}
                      start={startDate}
                      end={endDate}
                      energy={props.reportCountRes?.total_energy}
                      // onClick={handleCSVLinkClick}
                    ></MyOwnerDocument>
                  ) : (
                    <i className="fas fa-file-pdf enterprise-pdf"></i>
                  )}
                </div>
              </div>

              {props.loading ? (
                <h3
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "250px",
                  }}
                >
                  Loading data...
                </h3>
              ) : props.all && props.all.data != null ? (
                <div>
                  <CustomReportPaginationTable
                    data={data}
                    pageCount={pageCount}
                    columns={columns}
                    search={search}
                    setSearch={setSearch}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    filteredData={filteredData}
                    setFilteredData={setFilteredData}
                    loading={props.loading}
                    initialVisibleColumns={initialVisibleColumns}
                    t={t}
                  />
                </div>
              ) : (
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <h3>{t("No Data Found")}</h3>
                </div>
              )}
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.report.isLoading,
    all: state.report.all,
    csvDataRes: state.report.fetchReportCsv,
    percentagechange: state.report.percentageChange,
    reportCountRes: state.report.fetchMyReportCount,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    list: (start, end, operator, currentPage, pageSize) =>
      dispatch(fetchMyReportReport(start, end, operator, currentPage, pageSize)),
    csvDataList: (start, end, operator, currentPage, reportCount) =>
      dispatch(fetchReportCsvData(start, end, operator, currentPage, reportCount)),
    fetchReportCardCount: (start, end, org) => dispatch(fetchMyReportCardCountAction(start, end, org)),
    fetchPercentageChange: () => dispatch(reportPercentageChange()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EnterpriseReportAll);
