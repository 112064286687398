import { Container, Row, Col, Card } from "react-bootstrap";
import React, { useMemo, useEffect, useState } from "react";
import { useParams, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { SingleCustomerWalletAction, SingleCustomerWalletHistoryAction } from "../../../../store/actions/walletActions";
import PureBreadcrumbs from "../../breadcrums";
import Table from "../../reacttable/table";
import { TiTick } from "react-icons/ti";
import { AiOutlineCloseCircle } from "react-icons/ai";
import moment from "moment";
import { CSVLink } from "react-csv";
import TransactionPDFDoc from "./transaction_pdf";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function SingleWallet(props) {
    const { userid } = useParams()
    const [startDate, setStartDate] = useState(new Date(Date.now() - (3600 * 1000 * 2880)));
    const [endDate, setEndDate] = useState(new Date(Date.now() + (3600 * 1000 * 24)));
    //console.log(userid);
    useEffect(() => {
        // console.log(userid);
        props.getSingleWalletData(userid, startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10));
        props.getWalletBalance(userid)
    }, [userid]);
    const data = props.fetchSinglWalletDetails && props.fetchSinglWalletDetails
    const wallet = props.walletHistory && props.walletHistory

    const activateUserWallet = (userid) => {
        props.activateDeactivateWallet(userid, "active");
        alert("Wallet has been activated")
        window.location.reload()

    }

    const deactivateUserWallet = (userid) => {
        // console.log(userid);
        props.activateDeactivateWallet(userid, "inactive");
        alert("Wallet has been Deactivated")
        window.location.reload()
    };

    const loadNewData = () => {
        props.getSingleWalletData(userid, startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10))

    }

    const columns = useMemo(

        () => [
            {
                Header: 'Date',
                id: 'date',
                accessor: d => {
                    return moment(d.date).local().format("DD-MM-YYYY hh:mm:ss");
                }
            },
            {
                Header: 'User ID',
                accessor: 'userid'
            },
            {
                Header: 'Transaction ID',
                accessor: 'txnId'
            },
            {
                Header: 'Old Balance',
                accessor: 'old_balance'
            },

            {
                Header: 'Amount',
                accessor: 'amount'
            },
            {
                Header: 'Refund',
                accessor: ''
            },
            {
                Header: 'Status',
                accessor: 'status'
            },
            {
                Header: 'Merchant Reference',
                accessor: 'merchantReference'
            },
            {
                Header: 'Vendor ID',
                accessor: 'vendorId'
            },

        ],
        []
    );



    return (
        <Container fluid>
            <PureBreadcrumbs />
            <Row className="mt-4">
                <Col>
                    <div className="address p-5">
                        <strong>Name :{wallet && wallet.data.firstName + wallet.data.lastName}</strong> <br></br>
                        Phone : {wallet && wallet.data.phoneNumber}<br></br>
                        Creation Date: {moment(wallet && wallet.data.registerat).local().format("DD-MM-YYYY")}
                        <br></br>
                    </div>
                </Col>
                <Col className="text-right">

                    <button className="close-btn" onClick={() => activateUserWallet(userid)}> <TiTick
                        style={{ cursor: 'pointer' }}
                        title="Activate"

                    />Activate Account</button>

                    <br /> <br />
                    <button className="close-btn" onClick={() => deactivateUserWallet(userid)}> <AiOutlineCloseCircle
                        style={{ cursor: 'pointer' }}
                        title="Deactivate"

                    />Deactivate Account</button>
                </Col>
            </Row>

            <Row>
                <Col xs={12} md={3}>
                    <Card className="customercard">
                        <div style={{ textAlign: "left" }}>
                            <Card.Text style={{ fontSize: "14px" }}>WALLET AVAILABLE BALANCE</Card.Text>
                            <Card.Title>
                                <b>{wallet && wallet.data.amount}</b>
                            </Card.Title>
                            <span style={{ fontSize: "11px" }}>0.0% change in last 30 days</span>
                            <span style={{ float: "right", marginTop: "-45px", marginRight: "-15px" }}>
                                <div style={{ width: "50%" }} >
                                    <img src={process.env.PUBLIC_URL + '/images/customer/walletcredit.svg'} alt="customer" />
                                </div>
                            </span>
                        </div>
                    </Card><br />
                </Col>
                <Col xs={12} md={3}>
                    <Card className="customercard">
                        <div style={{ textAlign: "left" }}>
                            <Card.Text style={{ fontSize: "14px" }}>WALLET CREDIT AMOUNT</Card.Text>
                            <Card.Title>
                                <b>{data.data != undefined && data.data ? data.total_credit : ''}</b>
                            </Card.Title>
                            <span style={{ fontSize: "11px" }}>0.0% change in last 30 days</span>
                            <span style={{ float: "right", marginTop: "-45px", marginRight: "-15px" }}>
                                <div style={{ width: "50%" }} >
                                    <img src={process.env.PUBLIC_URL + '/images/customer/walletcredit.svg'} alt="customer" />
                                </div>
                            </span>
                        </div>
                    </Card><br />
                </Col>
                <Col xs={12} md={3}>
                    <Card className="customercard">
                        <div style={{ textAlign: "left" }}>
                            <Card.Text style={{ fontSize: "14px" }}>WALLET DEBIT AMOUNT</Card.Text>
                            <Card.Title>
                                <b>{data.data != undefined && data.data ? data.total_debit : ''}</b>
                            </Card.Title>
                            <span style={{ fontSize: "11px" }}>0.0% change in last 30 days</span>
                            <span style={{ float: "right", marginTop: "-45px", marginRight: "-15px" }}>
                                <div style={{ width: "50%" }} >
                                    <img src={process.env.PUBLIC_URL + '/images/customer/walletdebit.svg'} alt="customer" />
                                </div>
                            </span>
                        </div>
                    </Card><br />
                </Col>
                <Col xs={12} md={3}>
                    <Card className="customercard">
                        <div style={{ textAlign: "left" }}>
                            <Card.Text style={{ fontSize: "14px" }}>WALLET REFUND AMOUNT</Card.Text>
                            <Card.Title>
                                <b>{data.data != undefined && data.data ? data.total_refund : ''}</b>
                            </Card.Title>
                            <span style={{ fontSize: "11px" }}>0.0% change in last 30 days</span>
                            <span style={{ float: "right", marginTop: "-45px", marginRight: "-15px" }}>
                                <div style={{ width: "50%" }} >
                                    <img src={process.env.PUBLIC_URL + '/images/customer/walletavailable.svg'} alt="customer" />
                                </div>

                            </span>
                        </div>
                    </Card>
                </Col>
            </Row>

            <Row className="mt-4">
                <Col>

                    <Row>

                        <Col md={12} sm={12}>
                            <div className="row justify-content-between float-right">
                                <div className="row justify-content-center mt-3">
                                    <div className="col-auto claender">
                                        <DatePicker
                                            selected={startDate}
                                            onChange={(date) => setStartDate(date)}
                                            selectsStart
                                            startDate={startDate}
                                            dateFormat="dd/MM/yyyy"
                                        />
                                    </div>
                                    <div className="col-auto">
                                        <DatePicker
                                            selected={endDate}
                                            onChange={(date) => setEndDate(date)}
                                            selectsEnd
                                            startDate={startDate}
                                            endDate={endDate}
                                            minDate={startDate}
                                            showDisabledMonthNavigation
                                            dateFormat="dd/MM/yyyy"

                                        />
                                    </div>
                                </div>
                                <div className="mt-4 p-2">
                                    <button className="btn btn-sm" onClick={loadNewData}><span style={{ fontSize: "16px", color: "#676767", fontWeight: "600" }}>GO</span></button>
                                </div>
                                <div className="mt-4 p-1">
                                    {data.data != undefined && data.data != null ? <CSVLink data={data.data}
                                        filename={startDate.toISOString().substr(0, 10) + "_" + endDate.toISOString().substr(0, 10) + `.csv`}
                                        target="_blank"
                                    >
                                        <i className="fas fa-2x fa-file-csv"></i>
                                    </CSVLink> : <i className="fas fa-2x fa-file-csv"></i>
                                    }
                                </div>
                                <p className="p-4">
                                    <div style={{ paddingRight: "15px" }}>
                                        {data.data != undefined && data.data != null ? <TransactionPDFDoc {...props}></TransactionPDFDoc> : <i className="fas fa-2x fa-file-pdf"></i>}
                                    </div>
                                </p>


                            </div>
                            {data.data == undefined ?
                                (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>No Data Found</div>)
                                :
                                (<div className="App">

                                    <Table data={data.data} columns={columns} /></div>
                                )}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}


const mapStateToProps = function (state) {
    return {
        fetchSinglWalletDetails: state.wallet.fetchSingleWallet,
        walletHistory: state.wallet.customer_history,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getSingleWalletData: (userid, start, end) => dispatch(SingleCustomerWalletAction(userid, start, end)),
        getWalletBalance: (userid) => dispatch(SingleCustomerWalletHistoryAction(userid))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SingleWallet));