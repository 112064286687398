const initState = {
    isLoadLoading: false,
    stationList: '',
    stationStatus: '',
    stationLiveLoad: '',
    stationIndividualChargerLoad: '',
    chargerList: '',
    loadTypeList: '',
    profileList: '',
    kindList: '',
    limitList: '',
    updateChargingProfile: '',
    saveDynamicStationLoad: '',
    fetchSingleStationLoad: '',
    fetchChargerLoadList: '',
    fetchCurrentLoadGraph: '',
}
const loadReducer = (state = initState, action) => {
    switch (action.type) {
        case 'LOAD_MANAGEMENT':
            return { ...state, isLoadLoading: true }
        case 'LOAD_MANAGEMENT_STATION_LIST':
            return { ...state, stationList: action.payload, isLoadLoading: false };
        case 'LOAD_MANAGEMENT_STATION_STATUS':
            return { ...state, stationStatus: action.payload, isLoadLoading: false };
        case 'LOAD_MANAGEMENT_STATION_LIVE_LOAD':
            return { ...state, stationLiveLoad: action.payload, isLoadLoading: false };
        case 'LOAD_MANAGEMENT_STATION_INDIVIDUAL_LIVE_LOAD':
            return { ...state, stationIndividualChargerLoad: action.payload, isLoadLoading: false };
        case 'LOAD_MANAGEMENT_STATION_CHARGER_LIST':
            return { ...state, chargerList: action.payload, isLoadLoading: false };
        case 'LOAD_MANAGEMENT_STATION_LOAD_TYPE_LIST':
            return { ...state, loadTypeList: action.payload, isLoadLoading: false };
        case 'LOAD_MANAGEMENT_CHARGING_PROFILE_LIST':
            return { ...state, profileList: action.payload, isLoadLoading: false };
        case 'LOAD_MANAGEMENT_CHARGING_PROFILE_KIND_LIST':
            return { ...state, kindList: action.payload, isLoadLoading: false };
        case 'LOAD_MANAGEMENT_CHARGING_PROFILE_LIMT_LIST':
            return { ...state, limitList: action.payload, isLoadLoading: false };
        case 'LOAD_MANAGEMENT_CHARGING_PROFILE_UPDATE_DATA':
            return { ...state, updateChargingProfile: action.payload, isLoadLoading: false };

        // New Load Management Api's
        case 'SAVE_DYNAMIC_STATION_LOAD_API':
            return { ...state, saveDynamicStationLoad: action.payload, isLoadLoading: false };
        case 'FETCH_SINGLE_STATION_LOAD_API':
            return { ...state, fetchSingleStationLoad: action.payload, isLoadLoading: false };
        case 'FETCH_ALL_LOAD_CHARGER_LIST_API':
            return { ...state, fetchChargerLoadList: action.payload, isLoadLoading: false };
        case 'FETCH_CURRENT_LOAD_GRAPH_API':
            return { ...state, fetchCurrentLoadGraph: action.payload, isLoadLoading: false };

        default:
            return state;
    }
}
export default loadReducer;

