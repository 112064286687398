const initState = {
    isLoading: false,
    emailcreate: '',
    pushnotify: '',
    smscampaign: '',
    allemail: '',
    allsms: '',
    allpushnotify: '',
    monthlyemailcount: '',
    monthlysmscount: '',
    monthlypushcount: '',
    percentageChange: '',
    activateSms: '',
    deactivateSms: '',
    activateNotifications: '',
    deactivateNotifications: '',
    viewSms: '',
    updateSms: '',
    viewNotifications: '',
    updateNotifications: '',
    viewSingleEmail: '',
    updateEmail: '',
    activateEmail: '',
    deactivateEmail: '',
}

const campaignReducer = (state = initState, action) => {
    switch (action.type) {
        case 'LOAD_CAMPAIGN':
            return { ...state, isLoading: true }
        case 'CREATE_EMAIL_CAMPAIGN':
            return { ...state, emailcreate: action.payload, isLoading: false };
        case 'CREATE_PUSH_NOTIFY_CAMPAIGN':
            return { ...state, pushnotify: action.payload, isLoading: false };
        case 'CREATE_SMS_CAMPAIGN':
            return { ...state, smscampaign: action.payload, isLoading: false };
        case 'ALL_EMAIL_CAMPAIGN':
            return { ...state, allemail: action.payload, isLoading: false };
        case 'ALL_SMS_CAMPAIGN':
            return { ...state, allsms: action.payload, isLoading: false };
        case 'ALL_PUSH_NOTIFY_CAMPAIGN':
            return { ...state, allpushnotify: action.payload, isLoading: false };
        case 'GET_MONTHLY_EMAIL_API':
            return { ...state, monthlyemailcount: action.payload, isLoading: false };
        case 'GET_MONTHLY_SMS_API':
            return { ...state, monthlysmscount: action.payload, isLoading: false };
        case 'GET_MONTHLY_PUSH_API':
            return { ...state, monthlypushcount: action.payload, isLoading: false };
        case 'CAMPAIGN_PERCENTAGE_CHANGE':
            return { ...state, percentageChange: action.payload, isLoading: false };
        case 'ACTIVATE_SMS':
            return { ...state, activateSms: action.payload, isLoading: false };
        case 'DEACTIVATE_SMS':
            return { ...state, deactivateSms: action.payload, isLoading: false };
        case 'ACTIVATE_NOTIFICATION_API':
            return { ...state, activateNotifications: action.payload, isLoading: false };
        case 'DEACTIVATE_NOTIFICATION_API':
            return { ...state, deactivateNotifications: action.payload, isLoading: false };
        case 'VIEW_SINGLE_SMS_API':
            return { ...state, viewSms: action.payload, isLoading: false };
        case 'UPDATE_SMS_API':
            return { ...state, updateSms: action.payload, isLoading: false };
        case 'VIEW_SINGLE_NOTIFICATION_API':
            return { ...state, viewNotifications: action.payload, isLoading: false };
        case 'UPDATE_NOTIFICATION_API':
            return { ...state, updateNotifications: action.payload, isLoading: false };
        case 'VIEW_SINGLE_EMAIL_API':
            return { ...state, viewSingleEmail: action.payload, isLoading: false };
        case 'UPDATE_EMAIL_API':
            return { ...state, updateEmail: action.payload, isLoading: false };
        case 'ACTIVATION_EMAIL':
            return { ...state, activateEmail: action.payload, isLoading: false };
        case 'DEACTIVATION_EMAIL':
            return { ...state, deactivateEmail: action.payload, isLoading: false };
        default:
            return state;
    }
}
export default campaignReducer;