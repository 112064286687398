import React from "react";
import { GoogleMap, Marker, MarkerClusterer, useLoadScript } from "@react-google-maps/api";
import discovery_icon from '../../../assets/images/dicovery_icon.svg';


// Define the libraries array as a constant outside the component
const libraries = ["places"];

function CustomMarker({ location, clusterer }) {
    const { status, stationid, name } = location;
    const { latitude, longitude } = location.coordinates;

    const iconUrls = {
        "Discovery": discovery_icon,
    };

    const iconUrl = iconUrls[status];
    if (status === "Discovery") {
        return (
            <Marker
                position={{ lat: latitude, lng: longitude }}
                icon={{
                    url: iconUrl,
                    scaledSize: new window.google.maps.Size(30, 35),
                }}
                title={name}
                onClick={() => {
                    window.location.href = `/dashboard/viewsingleevse/${stationid}`;
                }}
                clusterer={clusterer}
            />
        );
    }

    return null;
}

function DiscoveryMap({ stations, center, zoom }) {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries, // Include any libraries you need
    });

    const mapContainerStyle = {
        height: "81vh",
        width: "100%",
    };

    // Handle loading and error states
    if (loadError) return <div>Error loading map. Please try again later.</div>;
    if (!isLoaded) return <div>Loading map...</div>;

    return (
        <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={center}
            zoom={zoom}
        >
            <MarkerClusterer
                options={{ imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m' }}
            >
                {(clusterer) => (
                    <>
                        {stations && stations.map((location) => (
                            <CustomMarker
                                key={location.stationid}
                                location={location}
                                clusterer={clusterer} // Pass the clusterer prop to CustomMarker
                            />
                        ))}
                    </>
                )}
            </MarkerClusterer>
        </GoogleMap>
    );
}

export default DiscoveryMap;
