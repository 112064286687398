import React, { useState } from "react";
import { useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Row, Col, Container, Card } from "react-bootstrap";
import PureBreadcrumbs from "../../breadcrums";
import * as Yup from "yup";
import { connect } from "react-redux";
import {
    CreateSchedule,
    fetchFleetName,
    fetchVinDataApi,
    fetchVinNo,
    getAllFleetName,
} from "../../../../store/actions/fleetAction";
import { useTranslation } from "react-i18next";
import { Bounce, ToastContainer, toast } from "react-toastify";
import {
    allEvseAction,
    fetchaAllDaysListAction,
    fetchEvseStationAction,
    fetchTimeMasterListAction,
} from "../../../../store/actions/evseAction";

function ScheduleVehicle(props) {
    const { t } = useTranslation();
    const [isToastVisible, setIsToastVisible] = useState(false);
    const [isMatching, setIsMatching] = useState(false);
    const [selectedDays, setSelectedDays] = useState("");
    const [selectedStartTimeIndex, setSelectedStartTimeIndex] = useState([]);
    const [selectedStartTime, setSelectedStartTime] = useState([]);
    const [type, setType] = useState("");
    const [minDate, setMinDate] = useState("");
    const [fleetDropDown, SetFleetDropDown] = useState([]);
    useEffect(() => {
        const today = new Date().toISOString().split("T")[0];
        setMinDate(today);
        props.getAllFleetNameApi();
        props.all();
    }, []);


    useEffect(() => {
        if (props.saveScheduleRes.statuscode === 200) {
            setIsToastVisible(true);
            toast.success('New Schedule Has Been Added', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    window.location.reload();
                },
            });
        } else if (props.saveScheduleRes.statuscode === 405) {
            setIsToastVisible(true);
            toast.error(`❌ ${props.save.error}`, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    window.location.reload();
                },
            });
        }
    }, [props.saveScheduleRes]);
    // const handleManufactureChange = (e) => {
    //   const value = e.target.value;
    //   // console.log("manufacture value",);
    // };

    useEffect(() => {
        SetFleetDropDown(props.getAllFleetNameRes?.data?.filter((item) => item.status === "Active"))
    }, [props.getAllFleetNameRes])

    const handleFleetChange = (e) => {
        const value = e.target.value;
        // console.log("fleet name", value);
        props.allVin(value);
    };
    const handleVinChange = (e) => {
        const value = e.target.value;
        props.getVinData(value);
    };


    const vehicleSchema = Yup.object().shape({
        vin: Yup.string().required("VIN number is required"),
        fleet_name: Yup.string().required("Fleet name is required"),
        station_name: Yup.string().required("Station name is required"),
        evse_id: Yup.string().required("Evse id is required"),
        calendar_type: Yup.string()
            .required("Calendar type is required")
            .typeError("Calendar type is required"),
        emailid: Yup.string()
            .email("Enter correct email")
            .required("Email is required"),
        // Conditionally require 'date' when 'calendar_type' is "single"
        date: Yup.string().when('calendar_type', {
            is: 'single',
            then: Yup.string().required("Date is required"),
            otherwise: Yup.string().notRequired()
        }),
        // Conditionally require 'day' when 'calendar_type' is not "single"
        day: Yup.string().when('calendar_type', {
            is: (val) => val !== 'single',
            then: Yup.string().required("Day is required").typeError("Day is required"),
            otherwise: Yup.string().notRequired()
        }),
        start: Yup.string().required("Start time is required").typeError("Start time is required"),
        end: Yup.string().required("End time is required").typeError("End time is required"),
    });

    const {
        register,
        control,
        setValue,
        getValues,
        watch,
        handleSubmit,
        reset,
        setError,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(vehicleSchema),
        mode: "all"
    });

    // console.log("errors", errors)
    // console.log("calendar type", getValues('calendar_type'))
    // if (props.save.statuscode === 200) {
    //   alert("Charging priority has been updated");
    //   window.location.reload();
    // }

    const {
        fields: fieldsList1,
        remove: removeList1,
        append: appendList1,
    } = useFieldArray({ control, name: "timeslot" });
    const elementsTime = watch("timeslot");


    useEffect(() => {
        props.getAllDayMaster();
        props.getTimeMaster();
    }, []);


    const handleChange = (e, i) => {
        const value = e.target.value;
        // console.log(value);
        if (i > 0) {
            setSelectedStartTime(value);
        }
        const selectedIndex = e.target.selectedIndex;
        setSelectedStartTimeIndex((prev) => {
            const newStartTimes = [...prev];
            newStartTimes[i] = selectedIndex;
            return newStartTimes;
        });
        setIsMatching(false)
    };

    const handleDayChange = (e, i) => {
        const value = e.target.value;
        // console.log("day change", value)
        setSelectedDays(value)
        setValue(`day`, value);
        setIsMatching(false)
    }

    const extractedDays = elementsTime && elementsTime.map(item => item.day);
    useEffect(() => {

        //  Logic for Monday day
        if (((selectedDays === 'Monday') && (extractedDays?.includes('All') || extractedDays?.includes('WD')))) {
            // console.log("Monday if condition")
            const timeMatching = elementsTime.some((time) => {
                return selectedStartTime >= time.start_time &&
                    selectedStartTime < time.end_time;
            });
            // console.log("Monday",timeMatching)
            setIsMatching(timeMatching);
        }

        //  Logic for Tuesday day
        if (((selectedDays === 'Tuesday') && (extractedDays?.includes('All') || extractedDays?.includes('WD')))) {
            // console.log("Tuesday if condition")
            const timeMatching = elementsTime.some((time) => {
                return selectedStartTime >= time.start_time &&
                    selectedStartTime < time.end_time;
            });
            // console.log("Tuesday",timeMatching)
            setIsMatching(timeMatching);
        }

        //  Logic for Wednesday day
        if (((selectedDays === 'Wednesday') && (extractedDays?.includes('All') || extractedDays?.includes('WD')))) {
            // console.log("Wednesday if condition")
            const timeMatching = elementsTime.some((time) => {
                return selectedStartTime >= time.start_time &&
                    selectedStartTime < time.end_time;
            });
            // console.log("Wednesday",timeMatching)
            setIsMatching(timeMatching);
        }

        //  Logic for Thursday day
        if (((selectedDays === 'Thursday') && (extractedDays?.includes('All') || extractedDays?.includes('WD')))) {
            // console.log("Thursday if condition")
            const timeMatching = elementsTime.some((time) => {
                return selectedStartTime >= time.start_time &&
                    selectedStartTime < time.end_time;
            });
            // console.log("Thursday",timeMatching)
            setIsMatching(timeMatching);
        }

        //  Logic for Friday day
        if (((selectedDays === 'Friday') && (extractedDays?.includes('All') || extractedDays?.includes('WD')))) {
            // console.log("Friday if condition")
            const timeMatching = elementsTime.some((time) => {
                return selectedStartTime >= time.start_time &&
                    selectedStartTime < time.end_time;
            });
            // console.log("Friday",timeMatching)
            setIsMatching(timeMatching);
        }

        // Logic for Weekend
        if (((selectedDays === 'Saturday' || selectedDays === 'Sunday') && (extractedDays?.includes('All') || extractedDays?.includes('WE')))) {
            // console.log("2nd if")
            const timeMatching = elementsTime.some((time) => {
                return selectedStartTime >= time.start_time &&
                    selectedStartTime < time.end_time;
            });
            setIsMatching(timeMatching);
        }
        // Logic for All
        if (((selectedDays === 'All') && (extractedDays?.includes('All') || extractedDays?.includes('WE') || extractedDays?.includes('Monday') || extractedDays?.includes('Tuesday') || extractedDays?.includes('Wednesday') || extractedDays?.includes('Thursday') || extractedDays?.includes('Friday')))) {
            // console.log("3rd if")
            const timeMatching = elementsTime.some((time) => {
                return selectedStartTime >= time.start_time &&
                    selectedStartTime < time.end_time;
            });
            setIsMatching(timeMatching);
        }
        if (((selectedDays === 'WE') && ((extractedDays?.includes('All') || (extractedDays?.includes('Saturday') || (extractedDays?.includes('Sunday'))))))) {
            // console.log("4th if")
            // console.log((selectedDays === 'WE'))
            // console.log((extractedDays.includes('WE') || (extractedDays.includes('All') || (extractedDays.includes('Saturday') || (extractedDays.includes('Sunday'))))))
            const timeMatching = elementsTime.some((time) => {
                return selectedStartTime >= time.start_time &&
                    selectedStartTime < time.end_time;
            });
            setIsMatching(timeMatching);
        }
        if (((selectedDays === 'WD') && (extractedDays?.includes('WE') || extractedDays?.includes('Monday') || (extractedDays?.includes('All'))))) {
            // console.log("5th if")
            const timeMatching = elementsTime.some((time) => {
                return selectedStartTime >= time.start_time &&
                    selectedStartTime < time.end_time;
            });
            setIsMatching(timeMatching);
        }

    }, [elementsTime, selectedStartTime, selectedDays, extractedDays]);

    const handleStationChange = (e) => {
        const selectedOption = e.target.options[e.target.selectedIndex];
        const stationId = selectedOption.getAttribute('stationid');
        setValue('station_id', stationId);
        props.fetch(stationId);

    }

    const registerData = watch();
    useEffect(() => {
        setType(registerData.calendar_type)
        if (registerData.calendar_type) {
            setValue('timeslot', [{ day: '', start: '', end: '', date: '' }]);
            setValue("day", "")
            setValue("end", "")
            setValue("start", "")
            setValue("date", "")
        }
    }, [registerData.calendar_type, setValue])


    // console.log(registerData.calendar_type, "calendar_type");
    // console.log(registerData.calendar_type, "calendar_type");


    const onSubmit = async (data) => {
        // const { timeslot } = data;
        // console.log(timeslot, "timeslot");
        // if (timeslot[0].end < timeslot[0].start && type == "single") {
        //     setError("timeslot[0].end", {
        //         type: "manual",
        //         message: "End time must be grater than start time",
        //     });
        //     return;
        // }
        // if (timeslot[0].date == "" && type == "single") {
        //     setError("timeslot[0].date", {
        //         type: "manual",
        //         message: "Date is required",
        //     });
        //     return;
        // }
        // if (timeslot[0].day == "" && type == "recurring") {
        //     setError("timeslot.day", {
        //         type: "manual",
        //         message: "Day is required",
        //     });
        //     return;
        // }
        props.saveScheduleApi(data)
        reset();
        // console.log(data, "data");

    };
    return (
        <Container fluid className="pb-4">
            {isToastVisible && <div className="overlay" />}
            <ToastContainer
                position="top-center"
                autoClose={20}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ top: "10%", width: "auto", zIndex: "9999" }}
            />
            <PureBreadcrumbs />
            <br />
            <Row>
                <Col xs={12} md={1}></Col>
                <Col xs={12} md={10}>
                    <br />
                    <Card className="tariffcard">
                        <Card.Text
                            style={{ fontSize: "14px", padding: "10px", paddingLeft: "15px" }}
                        >
                            <b style={{ fontSize: "1.5rem" }}>Create Schedule</b>
                            {isMatching && (
                                <p className="text-danger">{t("Time is already exist in the given days.Please select the days and time wisely.")}</p>
                            )}
                        </Card.Text>
                        <Card.Body>
                            <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                                <div className="form-floating">
                                    <label>
                                        Fleet Name <span className="mandatory-field">*</span>
                                    </label>
                                    <select
                                        autoFocus
                                        className={`form-select form-control ${errors.fleet_name ? "is-invalid" : ""
                                            }`}
                                        name="fleet_name"
                                        id="floatingSelectGridfleet_name"
                                        {...register("fleet_name")}
                                        onChange={(e) => handleFleetChange(e)}
                                    >
                                        <option value="">Select Fleet Name</option>
                                        {fleetDropDown?.map((v, k) => (
                                            <option key={k} value={v.ownername}>
                                                {v.ownername}
                                            </option>
                                        ))}
                                    </select>
                                    <div className="invalid-feedback">
                                        {errors.fleet_name?.message}
                                    </div>
                                </div>
                                <br />
                                <div className="form-floating">
                                    <label>
                                        VIN No. <span className="mandatory-field">*</span>
                                    </label>
                                    <select
                                        className={`form-select form-control ${errors.vin ? "is-invalid" : ""
                                            }`}
                                        name="vin"
                                        id="floatingSelectGridvin"
                                        {...register("vin")}
                                        onChange={(e) => handleVinChange(e)}
                                    >
                                        <option value="">Select VIN</option>
                                        {props.fetchVinRes &&
                                            props.fetchVinRes.data?.map((v, k) => (
                                                <option key={v.vin} value={v.vin}>
                                                    {v.vin}
                                                </option>
                                            ))}
                                    </select>
                                    <div className="invalid-feedback">{errors.vin?.message}</div>
                                </div>
                                <br />
                                <div className="form-floating">
                                    <label>
                                        Email <span className="mandatory-field">*</span>
                                    </label>
                                    <input type="email" className={`form-select form-control ${errors.emailid ? "is-invalid" : ""
                                        }`} placeholder="Enter Email" {...register("emailid")} />
                                    <div className="invalid-feedback ">{errors.emailid?.message}</div>
                                </div>
                                <br />

                                <div className="form-floating">
                                    <label>
                                        Select Station Name <span className="mandatory-field">*</span>
                                    </label>
                                    <select className={`form-select form-control ${errors.station_name ? 'is-invalid' : ''}`} name="stationid" id="floatingSelectGridstationid" {...register("station_name")} onChange={(e) => handleStationChange(e)}>
                                        <option value="">{t("Select Station")}</option>
                                        {props.list && props.list.data?.filter((item) => item.status !== 'Discovery' && item.publish !== false)?.map((station) =>
                                            <option key={station.stationid} value={station.name} stationid={station.stationid}>{station.name}</option>
                                        )}
                                    </select>
                                    <div className="invalid-feedback">{errors.station_name?.message}</div>
                                </div>
                                <br />
                                <div className="form-floating">
                                    <label>
                                        Select EVSE ID <span className="mandatory-field">*</span>
                                    </label>
                                    <select className={`form-select form-control ${errors.evse_id ? 'is-invalid' : ''}`} name="evse_id" id="floatingSelectGridevse_id" {...register("evse_id")}>
                                        <option value="">{t("Select EVSE ID")}</option>
                                        {props.fetchstation && props.fetchstation.evses.map((charger) =>
                                            <option key={charger.evse_id} value={charger.evse_id}>{charger.evse_id}</option>
                                        )}
                                    </select>
                                    <div className="invalid-feedback">{errors.evse_id?.message}</div>
                                </div>
                                <br />
                                <div>
                                    <label>
                                        Select Scheduling Calendar Type <span className="mandatory-field">*</span>
                                    </label>
                                    <div style={{ display: "flex", width: "170px", alignContent: "center", justifyContent: "center" }}>
                                        <label style={{ whiteSpace: "nowrap" }}>Single Day</label>
                                        <input type="radio" name="calendar_type" value={"single"} {...register("calendar_type")} />
                                    </div>
                                    <div style={{ display: "flex", width: "150px", alignContent: "center", justifyContent: "center" }}>
                                        <label style={{ whiteSpace: "nowrap" }}>Multiple Days</label>
                                        <input type="radio" name="calendar_type" value={"recurring"} {...register("calendar_type")} />
                                    </div>
                                    <div className="invalid-feedback">
                                        {errors?.calendar_type?.message}
                                    </div>
                                    <br />
                                </div>
                                <br />
                                <div>
                                    {
                                        type === "recurring" &&
                                        (<>
                                            {fieldsList1.slice(0).map((item, i) => (
                                                <Card.Body key={i}>
                                                    <div className="row g-2 mt-3 ">
                                                        <div className="col-md">
                                                            <div className="form-floating">
                                                                <label>
                                                                    {t("Select days")}
                                                                    <span className="mandatory-field">*</span>
                                                                </label>
                                                                <select
                                                                    className={`form-select form-control ${errors?.day ? "is-invalid" : ""}`}
                                                                    name={`timeslot[${i}].day`}
                                                                    id="floatingSelectGridday"
                                                                    {...register(`day`)}
                                                                    onChange={(e) => handleDayChange(e, i)}

                                                                >
                                                                    <option value="">{t("Select days")}</option>
                                                                    {props.fetchDayMaster &&
                                                                        props.fetchDayMaster.days?.map((day, idx) => (
                                                                            <option key={idx} value={day.value}>
                                                                                {t(day.key)}
                                                                            </option>
                                                                        ))}
                                                                </select>
                                                            </div>
                                                            <div className="invalid-feedback">
                                                                {errors?.day?.message}
                                                            </div>
                                                        </div>

                                                        <div className="col-md">
                                                            <div className="form-floating">
                                                                <label>
                                                                    {t("From")}
                                                                    <span className="mandatory-field">*</span>
                                                                </label>
                                                                <select
                                                                    className={`form-select form-control ${errors.timeslot?.[i]?.start ? "is-invalid" : ""}`}
                                                                    name={`start`}
                                                                    id="floatingSelectGridperiod_end"
                                                                    {...register(`start`)}
                                                                    onChange={(e) => handleChange(e, i)}
                                                                >
                                                                    <option value="">{t("Select Start Time")}</option>
                                                                    {props.mastertimelist &&
                                                                        props.mastertimelist.hours &&
                                                                        props.mastertimelist.hours.map((v, k) => (
                                                                            <option key={v.key} value={v.value}>
                                                                                {v.value}
                                                                            </option>
                                                                        ))}
                                                                </select>
                                                            </div>
                                                            <div className="invalid-feedback">
                                                                {errors?.start?.message}
                                                            </div>
                                                        </div>

                                                        <div className="col-md">
                                                            <div className="form-floating">
                                                                <label>
                                                                    {t("To")}
                                                                    <span className="mandatory-field">*</span>
                                                                </label>
                                                                <select
                                                                    className={`form-select form-control ${errors.timeslot?.[i]?.end ? "is-invalid" : ""}`}
                                                                    name={`timeslot[${i}].end`}
                                                                    id="floatingSelectGridperiod_end"
                                                                    {...register(`end`)}
                                                                >
                                                                    <option value="">{t("Select End Time")}</option>
                                                                    {props.mastertimelist &&
                                                                        props.mastertimelist?.hours &&
                                                                        props.mastertimelist?.hours
                                                                            ?.slice(selectedStartTimeIndex[i])
                                                                            .map((v, k) => (
                                                                                <option key={v.key} value={v.value}>
                                                                                    {v.value}
                                                                                </option>
                                                                            ))}
                                                                </select>
                                                            </div>
                                                            <div className="invalid-feedback">
                                                                {errors?.end?.message}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="d-grid gap-2 d-flex justify-content-end p-1">
                                                        <button
                                                            className="btn btn-lg"
                                                            type="button"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                removeList1(i);
                                                                setIsMatching(false);
                                                            }}
                                                        >
                                                            <i className="far fa-trash-alt" style={{ color: "red" }}></i>
                                                        </button>
                                                    </div> */}
                                                </Card.Body>
                                            ))}

                                        </>
                                        )}

                                    {type === "single"
                                        && (
                                            <>
                                                <div style={{ display: "flex", justifyContent: "space-between", gap: "10px" }}>
                                                    <div style={{ width: "25vw" }}>
                                                        <label>
                                                            Select Date
                                                            <span className="mandatory-field">*</span>
                                                        </label>
                                                        <input
                                                            type="date"
                                                            {...register("date")}
                                                            className={`form-select form-control ${errors?.date ? "is-invalid" : ""}`}
                                                            name="date"
                                                            min={minDate}
                                                        />
                                                        <div style={{ color: "red", marginTop: "5px" }}>{errors?.date?.message}</div>
                                                    </div>
                                                    <div style={{ width: "25vw" }}>
                                                        <label>
                                                            From
                                                            <span className="mandatory-field">*</span>
                                                        </label>
                                                        <input
                                                            type="time"
                                                            {...register("start")}
                                                            className={`form-select form-control ${errors?.start ? "is-invalid" : ""}`}
                                                            name="start"
                                                        />
                                                        <div style={{ color: "red", marginTop: "5px" }}>{errors?.start?.message}</div>
                                                    </div>
                                                    <div style={{ width: "25vw" }}>
                                                        <label>
                                                            To
                                                            <span className="mandatory-field">*</span>
                                                        </label>
                                                        <input
                                                            type="time"
                                                            {...register("end")}
                                                            className={`form-select form-control ${errors?.end ? "is-invalid" : ""}`}
                                                            name="end"
                                                        />
                                                        <div style={{ color: "red", marginTop: "5px" }}>{errors?.end?.message}</div>
                                                    </div>
                                                </div>
                                            </>

                                        )
                                    }
                                </div>
                                <br />
                                <br />
                                <div>
                                    <Card.Footer className="d-grid gap-2 ">
                                        <Row className="d-flex justify-content-end">
                                            {/* <Col lg={3} md={3} sm={4} xs={12} className="mt-2">
                                                {type == "recurring" && <button
                                                    className="btn btn-dark"
                                                    type="button"
                                                    disabled={isMatching}
                                                    onClick={() =>
                                                        appendList1({
                                                            day: "",
                                                            start_time: "",
                                                            end_time: "",
                                                        })
                                                    }
                                                >
                                                    {" "}
                                                    (+) {t("Add Time Slots")}
                                                </button>}
                                            </Col> */}
                                            <Col lg={3} md={3} sm={4} xs={12} className="d-md-flex justify-content-end mt-2">
                                                <button
                                                    className="lgn-btn btn"
                                                    type="submit"
                                                    disabled={isMatching}
                                                    onClick={handleSubmit(onSubmit)}
                                                >
                                                    {t("Submit")}
                                                </button>

                                            </Col>

                                        </Row>
                                    </Card.Footer>
                                </div>
                                {/* <div>
                                    <Row className="d-flex justify-content-between">
                                        <Col lg={5} md={3} sm={4} xs={12} className="mt-2">
                                            <button
                                                className="lgn-btn btn"
                                                type="button"
                                                onClick={() => reset()}
                                            >
                                                Reset
                                            </button>
                                        </Col>
                                        <br />
                                        <Col
                                            lg={5}
                                            md={3}
                                            sm={4}
                                            xs={12}
                                            className="d-md-flex justify-content-end mt-2"
                                        >
                                            {props.loading ? (
                                                <button className="btn lgn-btn" type="button" disabled>
                                                    <span
                                                        className="spinner-grow spinner-grow-sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    ></span>
                                                    &nbsp;
                                                    {t("Saving...")}
                                                </button>
                                            ) : (
                                                <button
                                                    className="lgn-btn btn"
                                                    type="submit"
                                                    onClick={handleSubmit(onSubmit)}
                                                >
                                                    SUBMIT
                                                </button>
                                            )}
                                        </Col>
                                    </Row>
                                </div> */}
                                <br />
                            </form>
                        </Card.Body>

                        <br />
                        <br />
                    </Card>
                </Col>
                <Col xs={12} md={1}></Col>
            </Row>
        </Container>
    );
}

const mapStateToProps = (state) => {
    return {
        loading: state.fleet.isLoading,
        fleetNameRes: state.fleet.fetchFleetName,
        fetchVinRes: state.fleet.fetchVinNumber,
        fetchVinDataRes: state.fleet.fetchVinData,
        save: state.fleet.save_vehicle_charging_priority,
        fetchDayMaster: state.evse.fetchAllDaysMaster,
        mastertimelist: state.evse.timemaster,
        getAllFleetNameRes: state.fleet.all_fleet_name,
        list: state.evse.allevse,
        fetchstation: state.evse.viewstation,
        saveScheduleRes: state.fleet.save_fleet_schedule
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        allFleetName: () => dispatch(fetchFleetName()),
        allVin: (fleet_name) => dispatch(fetchVinNo(fleet_name)),
        getVinData: (vin) => dispatch(fetchVinDataApi(vin)),
        getAllDayMaster: () => dispatch(fetchaAllDaysListAction()),
        getTimeMaster: () => dispatch(fetchTimeMasterListAction()),
        getAllFleetNameApi: () => dispatch(getAllFleetName()),
        all: () => dispatch(allEvseAction()),
        fetch: (stationid) => dispatch(fetchEvseStationAction(stationid)),
        saveScheduleApi: (data) => dispatch(CreateSchedule(data)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ScheduleVehicle);
