import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Line } from "react-chartjs-2";
import Moment from "moment";
import ApexCharts from "react-apexcharts";
import { Chart, registerables } from "chart.js";
import { getMonthlyPushCount } from "../../../../../store/actions/campaignAction";

function NotificationGraph(props) {
  Chart.register(...registerables);

  useEffect(() => {
    const today = Moment().add(1, "days");
    // console.log(Moment(today));
    var monthlycurrent = Moment(today).subtract(6, "M").format("YYYY-MM-DD");
    var monthlylastDay = Moment(today).format("YYYY-MM-DD");
    props.MonthlyData(monthlycurrent, monthlylastDay);
  }, []);

  let monthpush = [];

  const totalpush = props.insightmonthly && props.insightmonthly.List?.map((cu) => cu.total_push);
// console.log( props.insightmonthly && props.insightmonthly)
  // console.log(props.insightmonthly && props.insightmonthly);
  // console.log( props.insightmonthly && props.insightmonthly.List && props.insightmonthly.List)

  const monthlytime = props.insightmonthly && props.insightmonthly.List?.map((month) => month._id);

  const monthlylabel =
    monthlytime &&
    monthlytime.map((label) => {
      let month = label;
      if (month === 12) {
        monthpush.push("December");
      } else if (month === 11) {
        monthpush.push("November");
      } else if (month === 10) {
        monthpush.push("October");
      } else if (month === 9) {
        monthpush.push("September");
      } else if (month === 8) {
        monthpush.push("August");
      } else if (month === 7) {
        monthpush.push("July");
      } else if (month === 6) {
        monthpush.push("June");
      } else if (month === 5) {
        monthpush.push("May");
      } else if (month === 4) {
        monthpush.push("April");
      } else if (month === 3) {
        monthpush.push("March");
      } else if (month === 2) {
        monthpush.push("February");
      } else if (month === 1) {
        monthpush.push("January");
      }
    });

  const sms = {
    options: {
      chart: {
        id: "monthly-sms-chart",
      },
      xaxis: {
        categories: monthpush && monthpush.slice(-totalpush && -totalpush.length),
      },
      fill: {
        // colors: ["#1A73E8", "#B32824"],
        type: "gradient",
        gradient: {
          shade: "dark",
          gradientToColors: ["#FDD835"],
          shadeIntensity: 1,
          type: "horizontal",
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100, 100, 100],
        },
      },
    },
    series: [
      {
        name: "Monthly SMS",
        data: totalpush,
      },
    ],
  };
  // props.insightmonthly &&
  //   props.insightmonthly.List
  return (
    <>      
    {props.loading  ? <h3  style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "250px",
    }}> Loading data...</h3> :  
     props.insightmonthly && props.insightmonthly.List &&
    props.insightmonthly.List.length > 0   ? (

    <ApexCharts
            options={sms.options}
            series={sms.series}
            type="line"
            height={235}
    />) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "250px",
            }}
          >
          <h3>No data found </h3>
          </div>
    )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    insightmonthly: state.campaign.monthlypushcount,
    loading: state.campaign.isLoading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    MonthlyData: (current, lastDay) =>
      dispatch(getMonthlyPushCount(current, lastDay)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NotificationGraph);
