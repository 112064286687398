import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import ChargingImage from "../../../assets/images/liveCharging.gif";
import NotAvailable from "../../../assets/images/notCharging.png";
import PureBreadcrumbs from "../../breadcrums";
import { connect } from "react-redux";
import {
  fetchAllStationStatusDetailAction,
  fetchLiveChargingAction,
} from "../../../../store/actions/evseAction";

function FleetAllCharger(props) {
  const [data, setData] = useState([]);
  const [liveChargingData, setLiveChargingData] = useState([]);
  useEffect(() => {
    props.allStationStatusList("All", 1, 100000000);
    props.LiveChargingApi();
  }, []);

  useEffect(() => {
    setData(props.allStationStatusListRes.data);
  }, [props.allStationStatusListRes]);

  useEffect(() => {
    setLiveChargingData(props.LiveChargingRes.data);
  }, [props.LiveChargingRes]);

  return (
    <Container fluid>
      <PureBreadcrumbs />
      <br />
      <Col style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        {data?.map((item, index) => {
          return (
            <Card
              key={index}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                gap: "20px",
              }}
            >
              <div>
                <Card.Header style={{ fontSize: "15px", fontWeight: "bold" }}>
                  {item.name}
                </Card.Header>
              </div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "start",
                  gap: "20px",
                }}
              >
                {item.evses.map((evse, index) => {
                  return evse.connectors.map((connector, index) => {
                    const liveData = liveChargingData?.find(
                      (data) =>
                        data.evse_id === connector.evse_id &&
                        data.portno == connector.id
                    );
                    if (liveData) {
                      return (
                        <Card
                          className="tarrifcard"
                          key={index}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            textAlign: "center",
                            margin: "10px",
                            alignItems: "center",
                          }}
                        >
                          <Card.Header
                            style={{
                              fontSize: "15px",
                              fontWeight: "bold",
                              width: "100%",
                            }}
                          >
                            {connector.evse_id}/{connector.id}
                          </Card.Header>
                          <br />
                          {liveData.soc != 0 && (
                            <div
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                marginLeft: "50px",
                              }}
                            >
                              {liveData.soc}%
                            </div>
                          )}
                          <img src={ChargingImage} width={180} />
                          <div class="spinner-container">
                            <div class="spinner"></div>
                            <div class="spinner-text"> {liveData.kwh}kWh</div>
                          </div>
                        </Card>
                      );
                    } else {
                      return (
                        <Card
                          className="tarrifcard"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            margin: "10px",
                            width: "180px",
                          }}
                        >
                          <Card.Header
                            style={{
                              fontSize: "15px",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            {connector.evse_id}/{connector.id}
                          </Card.Header>
                          <div
                            style={{
                              padding: "10px",
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img src={NotAvailable} width={70} />
                          </div>
                        </Card>
                      );
                    }
                  });
                })}
              </div>
            </Card>
          );
        })}
      </Col>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  loading: state.evse.isEvseLoading,
  allStationStatusListRes: state.evse.fetchAllStationStatus,
  LiveChargingRes: state.evse.livecharging,
});

const mapDispatchToProps = (dispatch) => ({
  allStationStatusList: (status, currentPage, pageCount) =>
    dispatch(fetchAllStationStatusDetailAction(status, currentPage, pageCount)),
  LiveChargingApi: () => dispatch(fetchLiveChargingAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FleetAllCharger);
