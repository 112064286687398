import React, { useMemo } from 'react'
import { Card, Col, Container } from 'react-bootstrap'
import PureBreadcrumbs from "../../breadcrums";
import Table from "../../reacttable/table"
function AllDiagnostic() {
    const diagnostic = [
        {
            dtc_code: "9876543210",
            description: "Engine Overheat",
            count: 15,
            severity: "Severe"
        },
        {
            dtc_code: "5432167890",
            description: "Brake Failure",
            count: 8,
            severity: "Moderate"
        },
        {
            dtc_code: "1122334455",
            description: "Low Oil Pressure",
            count: 12,
            severity: "Severe"
        },
        {
            dtc_code: "5566778899",
            description: "Battery Low",
            count: 5,
            severity: "Mild"
        },
        {
            dtc_code: "9988776655",
            description: "Transmission Fault",
            count: 20,
            severity: "Severe"
        }
    ];

    const diagnostic_col = useMemo(
        () => [
            {
                Header: "DTC Code",
                accessor: "dtc_code",
            },
            {
                Header: "Description",
                accessor: "description",
            },
            {
                Header: "Count",
                accessor: "count",
            },
            {
                Header: "Severity",
                accessor: "severity",
                Cell: ({ value }) => (
                    <span style={{ color: getSeverityColor(value) }}>
                        {value}
                    </span>
                ),
            },
        ],
        []
    );
    const getSeverityColor = (severity) => {
        switch (severity) {
            case "Severe":
                return "red";
            case "Moderate":
                return "orange";
            case "Mild":
                return "green";
            default:
                return "black";
        }
    };
    return (
        <Container fluid>
            <PureBreadcrumbs />
            <br />
            <Col>
                <Card className='tariffcard'>
                    <Table data={diagnostic} columns={diagnostic_col}></Table>
                </Card>
            </Col>
        </Container>
    )
}

export default AllDiagnostic