import moment from "moment";
import { WALLET_URL, FETCH_STRIPE_BALANCE, FETCH_STRIPE_COUNTRY, FETCH_ALL_CONNECT_ACCOUNT, VIEW_SINGLE_CONNECT_ACCOUNT, DELETE_SINGLE_CONNECT_ACCOUNT, CREATE_STRIPE_CUSTOMER, CREATE_STRIPE_CUSTOMER_TOKEN, CREATE_STRIPE_CUSTOMER_EXT_ACCOUNT, GET_ALL_TRANSFER_DETAILS, GET_SINGLE_TRANSFER_DETAILS, SAVE_STRIPE_PASSWORD_API, BASE_URL, TRIGGER_ONBOARDING_EMAIL, VALIDATE_STRIPE_PASSWORD_API, STRIPE_TRANSFER_API, STRIPE_PAYMENT_INTENT_API, STRIPE_CONFIRM_PAYMENT_INTENT_API, CREATE_MANUAL_RECONCILATION_API, FETCH_ALL_RECONCILATION_API, FETCH_SINGLE_RECONCILATION_API, UPDATE_MANUAL_RECONCILATION_API } from "../../components/config/config"
import { ApiHandler } from "./apiHandler";


export const fetchStripeBalance = () => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'fetchStripeBalance'
    return async dispatch => {
        dispatch({ type: 'STRIPE_LOADING', payload: '' })
        const data = await ApiHandler(WALLET_URL + FETCH_STRIPE_BALANCE, {
            functionName,
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'LOAD_STRIPE_BALANCE', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const fetchCountryCurrency = () => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'fetchCountryCurrency'
    return async dispatch => {
        dispatch({ type: 'STRIPE_LOADING', payload: '' })
        const data = await ApiHandler(WALLET_URL + FETCH_STRIPE_COUNTRY, {
            functionName,
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'GET_COUNTRY_CURRENCY', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const fetchAllConnectAccountList = () => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'fetchAllConnectAccountList';

    return async (dispatch) => {
        dispatch({ type: 'STRIPE_LOADING', payload: '' });

        try {
            const response = await fetch(WALLET_URL + FETCH_ALL_CONNECT_ACCOUNT, {
                functionName,
                method: "GET",
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            });

            if (!response.ok) {
                // If the response is not OK, throw an error with the status
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            // console.log("EWFEWFEWFEW", data);
            dispatch({ type: 'GET_ALL_CONNECT_ACCOUNT', payload: data });
        } catch (error) {
            console.error('Error fetching all connect account list:', error);
            // Optionally, dispatch an error action to handle errors in the UI
            // dispatch({ type: 'GET_ALL_CONNECT_ACCOUNT_ERROR', payload: error.message });
        }
    };
};


export const fetchSingleConnectAccount = (id) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'fetchSingleConnectAccount'
    return async dispatch => {
        dispatch({ type: 'STRIPE_LOADING', payload: '' })
        const data = await ApiHandler(WALLET_URL + VIEW_SINGLE_CONNECT_ACCOUNT + "?id=" + id, {
            functionName,
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'GET_SINGLE_CONNECT_ACCOUNT', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const deleteSingleConnectAccount = (id) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'deleteSingleConnectAccount'
    return async dispatch => {
        dispatch({ type: 'STRIPE_LOADING', payload: '' })
        const data = await ApiHandler(WALLET_URL + DELETE_SINGLE_CONNECT_ACCOUNT + "?id=" + id, {
            functionName,
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'DELETE_CONNECT_ACCOUNT', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const createStripeCustomerAction = (data) => {
    // console.log("data", data)
    const { name, country, email, type, business_type, url, personal_details } = data
    // console.log("data personal_details", data.personal_details)
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'createStripeCustomerAction'
    return async dispatch => {
        dispatch({ type: 'STRIPE_LOADING', payload: '' })
        const data = await ApiHandler(WALLET_URL + CREATE_STRIPE_CUSTOMER, {
            functionName,
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "name": name,
                'country': country,
                'email': email,
                "type": type,
                "business_type": business_type,
                "url": url,
                "personal_details": personal_details,
            })
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'CREATE_STRIPE_CUSTOMER', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const createStripeCustomerTokenAction = (country, currency, routing_number, account_number, account_holder_name, account_holder_type) => {
    // console.log(country, currency, routing_number, account_number, account_holder_name, account_holder_type)
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'createStripeCustomerTokenAction'

    return async dispatch => {
        dispatch({ type: 'STRIPE_LOADING', payload: '' })
        const data = await ApiHandler(WALLET_URL + CREATE_STRIPE_CUSTOMER_TOKEN, {
            functionName,
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "country": country,
                'currency': currency,
                'routing_number': routing_number,
                "account_number": account_number,
                "account_holder_name": account_holder_name,
                "account_holder_type": account_holder_type
            })
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'CREATE_STRIPE_CUSTOMER_TOKEN', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const createStripeExtAccountAction = (account, id) => {
    console.log("account, id", account, id)
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'createStripeExtAccountAction'

    return async dispatch => {
        dispatch({ type: 'STRIPE_LOADING', payload: '' })
        const data = await ApiHandler(WALLET_URL + CREATE_STRIPE_CUSTOMER_EXT_ACCOUNT, {
            functionName,
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "account": account,
                'id': id,
            })
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'CREATE_STRIPE_CUSTOMER_EXT_ACCOUNT', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const fetchAllConnectTransactionData = () => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'fetchAllConnectTransactionData'
    return async dispatch => {
        dispatch({ type: 'STRIPE_LOADING', payload: '' })
        const data = await ApiHandler(WALLET_URL + GET_ALL_TRANSFER_DETAILS, {
            functionName,
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'GET_ALL_TRANSFER_DETAILS', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const fetchSingleConnectTransactionInfo = (id) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'fetchSingleConnectTransactionInfo'
    return async dispatch => {
        dispatch({ type: 'STRIPE_LOADING', payload: '' })
        const data = await ApiHandler(WALLET_URL + GET_SINGLE_TRANSFER_DETAILS + "?id=" + id, {
            functionName,
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'GET_SINGLE_TRANSFER_DETAILS', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const saveStripePassword = (data) => {
    const { token, userid, phone, email } = JSON.parse(localStorage.getItem('user'));
    const { stripe_password } = data;
    // console.log(stripe_password)
    const bearer = 'basic ' + token
    const functionName = 'saveStripePassword'
    return async dispatch => {
        dispatch({ type: 'STRIPE_LOADING', payload: '' })
        const data = await ApiHandler(BASE_URL + SAVE_STRIPE_PASSWORD_API, {
            functionName,
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "username": userid,
                "mobile": phone,
                "stripe_password": stripe_password,
                "email": email,
            })
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'SAVE_STRIPE_PASSWORD_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const stripeOnboardingEmailAction = (first_name, last_name, email, account) => {
    // console.log("first_name,last_name,email,account", first_name, last_name, email, account)
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'stripeOnboardingEmailAction'
    return async dispatch => {
        dispatch({ type: 'STRIPE_LOADING', payload: '' })
        const data = await ApiHandler(WALLET_URL + TRIGGER_ONBOARDING_EMAIL, {
            functionName,
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "first_name": first_name.trim(),
                "last_name": last_name.trim(),
                "email": email,
                "account": account
            })
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'TRIGGER_ONBOARDING_EMAIL', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}
export const stripePasswordValidateApi = (data) => {
    // console.log("data", data)
    const { stripe_password } = data;
    const { token, userid } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'stripePasswordValidateApi'
    return async dispatch => {
        dispatch({ type: 'STRIPE_LOADING', payload: '' })
        const data = await ApiHandler(BASE_URL + VALIDATE_STRIPE_PASSWORD_API, {
            functionName,
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "username": userid,
                "stripe_password": stripe_password.trim(),
            })
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'VALIDATE_STRIPE_PASSWORD_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}
export const stripeTransferApi = (amount, currency, stripe_account, source_transaction) => {
    // console.log("transfer data", amount,currency,stripe_account,source_transaction)
    let newAmount = amount.toString().split('.')[0];
    const { token, userid } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'stripeTransferApi'
    return async dispatch => {
        dispatch({ type: 'STRIPE_LOADING', payload: '' })
        const data = await ApiHandler(WALLET_URL + STRIPE_TRANSFER_API, {
            functionName,
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "amount": Number(newAmount),
                "currency": currency,
                "destination": stripe_account,
                "source_transaction": source_transaction,
                "transfer_group": "ORDER01",
            })
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'STRIPE_TRANSFER_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}
export const stripePaymentIntentsApi = (amount, currency, on_behalf_of) => {
    // console.log(amount, currency, on_behalf_of)
    let newAmount = amount.toString().split('.')[0];
    const { token, userid } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'stripePaymentIntentsApi'
    return async dispatch => {
        dispatch({ type: 'STRIPE_LOADING', payload: '' })
        const data = await ApiHandler(WALLET_URL + STRIPE_PAYMENT_INTENT_API, {
            functionName,
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "amount": Number(newAmount),
                "currency": currency,
                "payment_method": "",
                "on_behalf_of": on_behalf_of,
            })
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'STRIPE_PAYMENT_INTENTS_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}
export const stripeConfirmPaymentIntentsApi = (id) => {
    // console.log("id", id)
    const { token, userid } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'stripeConfirmPaymentIntentsApi'
    return async dispatch => {
        dispatch({ type: 'STRIPE_LOADING', payload: '' })
        const data = await ApiHandler(WALLET_URL + STRIPE_CONFIRM_PAYMENT_INTENT_API, {
            functionName,
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "id": id,
                "payment_method": "pm_card_visa",
                "return_url": "https://csms.elevmobility.com/"

            })
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'STRIPE_CONFIRM_PAYMENT_INTENTS_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const createManualReconcilationAction = (data) => {
    // console.log("data", data)
    const { transferredTo, amount, gst, cgst, igst, sgst, paymentMode, clientName, clientAccountNo, utr, transferDate, paymentDetail, description } = data
    // console.log("data personal_details", data.personal_details)
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'createManualReconcilationAction'
    return async dispatch => {
        dispatch({ type: 'STRIPE_LOADING', payload: '' })
        const data = await ApiHandler(WALLET_URL + CREATE_MANUAL_RECONCILATION_API, {
            functionName,
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "transfered_to": transferredTo,
                "amount": amount,
                "gst": Number(gst),
                "cgst": Number(cgst),
                "igst": Number(igst),
                "sgst": Number(sgst),
                "payment_mode": paymentMode,
                "utr": utr,
                "transfer_date": moment(transferDate).format('DD-MM-YYYY'),
                "payment_details": paymentDetail,
                "description": description,
                "client_name": clientName,
                "client_account_number": clientAccountNo,

            })
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'CREATE_MANUAL_RECONCILATION_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}
export const updateManualReconcilationAction = (id, data) => {
    // console.log("data", data)
    const { transferredTo, amount, gst, cgst, igst, sgst, paymentMode, clientName, clientAccountNo, utr, transferDate, paymentDetail, description } = data
    // console.log("data personal_details", data.personal_details)
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'updateManualReconcilationAction'
    return async dispatch => {
        dispatch({ type: 'STRIPE_LOADING', payload: '' })
        const data = await ApiHandler(WALLET_URL + UPDATE_MANUAL_RECONCILATION_API, {
            functionName,
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "id": id,
                "transfered_to": transferredTo,
                "amount": amount,
                "gst": Number(gst),
                "cgst": Number(cgst),
                "igst": Number(igst),
                "sgst": Number(sgst),
                "payment_mode": paymentMode,
                "utr": utr,
                "transfer_date": moment(transferDate).format('DD-MM-YYYY'),
                "payment_details": paymentDetail,
                "description": description,
                "client_name": clientName,
                "client_account_number": clientAccountNo,

            })
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'UPDATE_MANUAL_RECONCILATION_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const fetchAllReconcilationApi = () => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'fetchAllReconcilationApi'
    return async dispatch => {
        dispatch({ type: 'STRIPE_LOADING', payload: '' })
        const data = await ApiHandler(WALLET_URL + FETCH_ALL_RECONCILATION_API, {
            functionName,
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'FETCH_ALL_MANUAL_RECONCILATION_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const fetchSingleReconcilationApi = (id) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'fetchSingleReconcilationApi'
    return async dispatch => {
        dispatch({ type: 'STRIPE_LOADING', payload: '' })
        const data = await ApiHandler(WALLET_URL + FETCH_SINGLE_RECONCILATION_API + "?id=" + id, {
            functionName,
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'FETCH_SINGLE_RECONCILATION_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}