const initState = {
    isLoading: false,
    saveuser: '',
    alluser: '',
    singleuser: '',
    updateaccess: '',
    changePassword: '',
    percentageChange: '',
    userStatus: '',
    validateStatus: '',
    getState: '',
    saveState: '',
    fetchAllStateGroups: '',
    updateState: '',
    deleteUser: '',
    allAssignToUser: '',
}

const customerReducer = (state = initState, action) => {
    switch (action.type) {
        case 'LOAD_USER':
            return { ...state, isLoading: true }
        case 'LOAD_ALL_USER':
            return { ...state, alluser: action.payload, isLoading: false };
        case 'SAVE_USER':
            return { ...state, saveuser: action.payload, isLoading: false };
        case 'LOAD_ORG_USER':
            return { ...state, allorg: action.payload, isLoading: false };
        case 'LOAD_SINGLE_USER':
            return { ...state, singleuser: action.payload, isLoading: false };
        case 'UPDATE_SINGLE_USER':
            return { ...state, updateaccess: action.payload, isLoading: false };
        case 'CHANGE_USER_PASSWORD_API':
            return { ...state, changePassword: action.payload, isLoading: false };
        case 'FETCH_ADMIN_PERCENTAGE_CHANGE':
            return { ...state, percentageChange: action.payload, isLoading: false };
        case 'FETCH_USER_ADMIN_STATUS':
            return { ...state, userStatus: action.payload, isLoading: false };
        case 'UPDATE_VALIDATE_USER_STATUS':
            return { ...state, validateStatus: action.payload, isLoading: false };
        case 'GET_COUNTRY_STATE':
            return { ...state, getState: action.payload, isLoading: false };
        case 'SAVE_STATE_API':
            return { ...state, saveState: action.payload, isLoading: false };
        case 'FETCH_ALL_STATE_GROUP_API':
            return { ...state, fetchAllStateGroups: action.payload, isLoading: false };
        case 'UPDATE_STATE_API':
            return { ...state, updateState: action.payload, isLoading: false };
        case 'DELETE_USER_API':
            return { ...state, deleteUser: action.payload, isLoading: false };
        case 'ALL_ASSIGNTO_USER':
            return { ...state, allAssignToUser: action.payload, isLoading: false };
        default:
            return state;
    }
}
export default customerReducer;