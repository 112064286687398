import React, { useState } from 'react'
import { useEffect } from "react";
import { Card, Row, Col, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import PureBreadcrumbs from "../../breadcrums";
import "../ocpi.css"
import { useTranslation } from 'react-i18next';
import { fetchExternalViewSingleSessionAction, fetchExternalViewSingleSessionPartnerAction } from '../../../../store/actions/ocpiAction';
import moment from 'moment';

const ViewSingleOcpiSessionReport = (props) => {
    const { t } = useTranslation();
    const { id, party_id } = useParams();
    // console.log("id,party_id", id, party_id)
    const [activeTab, setActiveTab] = useState('Self');

    useEffect(() => {
        if (activeTab === 'Self') {
            props.fetchSessionSelfData(id, party_id) // Fetch Self data
        } else if (activeTab === 'Partner') {
            props.fetchSessionPartnerData(id, party_id) // Fetch partner data
        }
    }, [activeTab, id, party_id]);

    const selfData = (props.fetchExternalViewSingleSessionSelfDataRes && props.fetchExternalViewSingleSessionSelfDataRes.data) || {};

    const partnerData = (props.fetchExternalViewSingleSessionPartnerDataRes && props.fetchExternalViewSingleSessionPartnerDataRes.data) || {};
    const data = activeTab === 'Self' ? selfData : partnerData;

    const { latitude = null, longitude = null } = data?.coordinates || {};
    console.log("data", data);

    const renderForm = (isPartner) => (
        <Row className='mt-2'>
            <Col xs={12} md={12}>
                <form onSubmit={e => e.preventDefault()}>
                    <Card>
                        <div>
                            <h4 className='text-center p-3'>View Single Session Details</h4>
                        </div>
                        <Card.Body>
                            {/* Row for country_code, party_id, id */}
                            <div className="row g-2">
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputCountryCode'>Country Code</label>
                                        <input type="text" className="form-control gray-border" id="floatingInputCountryCode" placeholder='Country Code' value={data?.country_code} disabled />
                                    </div>
                                </div>
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputPartyId'>Party ID</label>
                                        <input type="text" className="form-control gray-border" id="floatingInputPartyId" placeholder='Party ID' value={data?.party_id} disabled />
                                    </div>
                                </div>
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputId'>ID</label>
                                        <input type="text" className="form-control gray-border" id="floatingInputId" placeholder='ID' value={data?.id} disabled />
                                    </div>
                                </div>
                            </div>

                            {/* Row for start_date_time, end_date_time, kwh */}
                            <div className="row g-2">
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputStartTime'>Start Date</label>
                                        <input type="text" className="form-control gray-border" id="floatingInputStartTime" placeholder='Start Date' value={data?.start_date_time ? moment(data?.start_date_time).format('DD/MM/YYYY') : 'NA'} disabled />
                                    </div>
                                </div>
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputEndTime'>End Date</label>
                                        <input type="text" className="form-control gray-border" id="floatingInputEndTime" placeholder='End Date' value={data?.end_date_time ? moment(data?.end_date_time).format('DD/MM/YYYY') : 'NA'} disabled />
                                    </div>
                                </div>
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputKwh'>KWh</label>
                                        <input type="number" className="form-control gray-border" id="floatingInputKwh" placeholder='kWh' value={data?.kwh} disabled />
                                    </div>
                                </div>
                            </div>

                            {/* CDR Token Data */}
                            <h5 className="mt-4">CDR Token Details</h5>
                            <div className="row g-2">
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputCdrCountry'>Token Country Code</label>
                                        <input type="text" className="form-control gray-border" id="floatingInputCdrCountry" placeholder='Token Country Code' value={data?.cdr_token?.country_code} disabled />
                                    </div>
                                </div>
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputCdrPartyId'>Token Party ID</label>
                                        <input type="text" className="form-control gray-border" id="floatingInputCdrPartyId" placeholder='Token Party ID' value={data?.cdr_token?.party_id} disabled />
                                    </div>
                                </div>
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputCdrUid'>Token UID</label>
                                        <input type="text" className="form-control gray-border" id="floatingInputCdrUid" placeholder='Token UID' value={data?.cdr_token?.uid} disabled />
                                    </div>
                                </div>
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputCdrContractId'>Token Contract ID</label>
                                        <input type="text" className="form-control gray-border" id="floatingInputCdrContractId" placeholder='Token Contract ID' value={data?.cdr_token?.contract_id} disabled />
                                    </div>
                                </div>
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputCdrType'>Token Type</label>
                                        <input type="text" className="form-control gray-border" id="floatingInputCdrType" placeholder='Token Type' value={data?.cdr_token?.type} disabled />
                                    </div>
                                </div>
                            </div>

                            {/* Other Data */}
                            <div className="row g-2">
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputAuthMethod'>Authorization Method</label>
                                        <input type="text" className="form-control gray-border" id="floatingInputAuthMethod" placeholder='Authorization Method' value={data?.auth_method} disabled />
                                    </div>
                                </div>
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputLocationId'>Location ID</label>
                                        <input type="text" className="form-control gray-border" id="floatingInputLocationId" placeholder='Location ID' value={data?.location_id} disabled />
                                    </div>
                                </div>
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputEvseUid'>EVSE UID</label>
                                        <input type="text" className="form-control gray-border" id="floatingInputEvseUid" placeholder='EVSE UID' value={data?.evse_uid} disabled />
                                    </div>
                                </div>
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputConnectorId'>Connector ID</label>
                                        <input type="text" className="form-control gray-border" id="floatingInputConnectorId" placeholder='Connector ID' value={data?.connector_id} disabled />
                                    </div>
                                </div>
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputChargingPeriods'>Charging Periods</label>
                                        <input type="text" className="form-control gray-border" id="floatingInputChargingPeriods" placeholder='Charging Periods' value={data?.charging_periods ?? 'None'} disabled />
                                    </div>
                                </div>
                            </div>

                            {/* Cost Data */}
                            <h5 className="mt-4">Cost Details</h5>
                            <div className="row g-2">
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputExclVat'>Excl. VAT</label>
                                        <input type="number" className="form-control gray-border" id="floatingInputExclVat" placeholder='Excl. VAT' value={data?.total_cost?.excl_vat} disabled />
                                    </div>
                                </div>
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputInclVat'>Incl. VAT</label>
                                        <input type="number" className="form-control gray-border" id="floatingInputInclVat" placeholder='Incl. VAT' value={data?.total_cost?.incl_vat} disabled />
                                    </div>
                                </div>
                            </div>

                            {/* Currency, Status, Authorization Reference, Last Updated */}
                            <div className="row g-2">
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputCurrency'>Currency</label>
                                        <input type="text" className="form-control gray-border" id="floatingInputCurrency" placeholder='Currency' value={data?.currency} disabled />
                                    </div>
                                </div>
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputStatus'>Status</label>
                                        <input type="text" className="form-control gray-border" id="floatingInputStatus" placeholder='Status' value={data?.status} disabled />
                                    </div>
                                </div>
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputAuthRef'>Authorization Reference</label>
                                        <input type="text" className="form-control gray-border" id="floatingInputAuthRef" placeholder='Authorization Reference' value={data?.authorization_reference} disabled />
                                    </div>
                                </div>
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputLastUpdated'>Last Updated</label>
                                        <input type="text" className="form-control gray-border" id="floatingInputLastUpdated" placeholder='Last Updated' value={data?.last_updated ? moment(data?.last_updated).format('DD/MM/YYYY') : 'NA'} disabled />
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </form>
            </Col>
        </Row>

    );

    return (
        <Container fluid>
            <PureBreadcrumbs />
            <br />

            <Row className="mt-3">
                <Col xs={12}>
                    <div style={{
                        border: "1px solid #d9d9d9",
                        borderRadius: "50px",
                        padding: "0px",
                        backgroundColor: "#f3f3f3", // Light background for the switch container
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "20%",
                        margin: "0 auto",
                    }}>
                        <button
                            onClick={() => setActiveTab('Self')}
                            className={`tab-button ${activeTab === 'Self' ? 'active' : ''}`}
                            style={{
                                backgroundColor: activeTab === 'Self' ? '#000000' : '#f3f3f3',
                                color: activeTab === 'Self' ? '#f3f3f3' : '#000000',
                                border: "none",
                                padding: '10px 20px',
                                borderRadius: "50px",
                                fontWeight: "bold",
                                fontSize: "16px",
                                cursor: "pointer",
                                transition: "all 0.3s ease",
                                width: "55%",
                            }}
                        >
                            Self
                        </button>
                        <button
                            onClick={() => setActiveTab('Partner')}
                            className={`tab-button ${activeTab === 'Partner' ? 'active' : ''}`}
                            style={{
                                backgroundColor: activeTab === 'Partner' ? '#000000' : '#f3f3f3',
                                color: activeTab === 'Partner' ? '#f3f3f3' : '#000000',
                                border: "none",
                                padding: '10px 20px',
                                borderRadius: "50px",
                                fontWeight: "bold",
                                fontSize: "16px",
                                cursor: "pointer",
                                transition: "all 0.3s ease",
                                width: "55%",
                            }}
                        >
                            Partner
                        </button>
                    </div>
                </Col>
            </Row>
            {props.loading ? (
                <Row >
                    <Col lg={12} className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden"></span>
                        </div>
                    </Col>
                </Row>
            ) : (
                <>
                    {activeTab === 'Self' && renderForm(false)}
                    {activeTab === 'Partner' && renderForm(true)}
                </>
            )}

        </Container>
    )
}

const mapStateToProps = (state) => {
    return {
        loading: state.ocpi.isLoading,
        fetchExternalViewSingleSessionSelfDataRes: state.ocpi.fetchExternalViewSingleSessionData,
        fetchExternalViewSingleSessionPartnerDataRes: state.ocpi.fetchExternalViewSingleSessionPartnerData,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchSessionSelfData: (id, party_id) => dispatch(fetchExternalViewSingleSessionAction(id, party_id)),
        fetchSessionPartnerData: (id, party_id) => dispatch(fetchExternalViewSingleSessionPartnerAction(id, party_id)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewSingleOcpiSessionReport)