import React from "react"
import { Container, Row, Col } from "react-bootstrap";
import PureBreadcrumbs from "../../breadcrums";
import "../campaign.css"
import CreateEmailCampaign from "./email";
import CreateSMSCampaign from "./sms";
import CreateNotificationCampaign from "./notification";

function CapmaignCard() {

    return (
        <Container fluid>
            <PureBreadcrumbs />
            <br />
            {/* <div class="row">
                <div class="col-lg-4 col-sm-6">
                        <Card className='statuscard p-3'>
                            <div class="row">
                                <div class="col-lg-8 col-sm-8">
                                    Email
                                </div>
                            </div>
                            <br></br>
                            <div class="row">
                                <div class="col-lg-2 col-sm-2"></div>
                                <div class="col-lg-8 col-sm-8">
                                    <a href="/dashboard/campaign/new_campaign/notification" style={{ textDecoration: "none" }}>  <button style={{ width: "100%", backgroundColor: "#0bcf6b", color: "black" }} class="tab-label">Add</button></a>  
                                    </div>
                                <div class="col-lg-2 col-sm-2"></div>
                            </div>
                        </Card><br />
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <Card className='statuscard p-3'>
                            <div class="row">
                                <div class="col-lg-8 col-sm-8">
                                    SMS
                                </div>
                                <div class="col-lg-4 col-sm-4">
                                </div>
                            </div>
                            <br></br>
                            <div class="row">
                                <div class="col-lg-2 col-sm-2"></div>
                                <div class="col-lg-8 col-sm-8">
                                    <a href="/dashboard/campaign/new_campaign/sms" style={{ textDecoration: "none" }}><button style={{ width: "100%", backgroundColor: "#0bcf6b", color: "black" }} class="tab-label">Add</button>
                                    </a>  </div>
                                <div class="col-lg-2 col-sm-2"></div>

                            </div>
                        </Card><br />
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <Card className='statuscard p-3'>
                            <div class="row">
                                <div class="col-lg-8 col-sm-8">
                                    Notification
                                </div>
                            </div>
                            <br></br>
                            <div class="row">
                                <div class="col-lg-2 col-sm-2"></div>
                                <div class="col-lg-8 col-sm-8">
                                    <a href="/dashboard/campaign/new_campaign/notification" style={{ textDecoration: "none" }}>  <button style={{ width: "100%", backgroundColor: "#0bcf6b", color: "black" }} class="tab-label">Add</button>
                                    </a>  </div>
                                <div class="col-lg-2 col-sm-2"></div>
                            </div>
                        </Card><br />
                    </div>
                </div> */}
            <Row>
                <Col>
                    <div className="wrappers">
                        {/* <div className="tab-content" id="campaign_tab_content">
                            <div className="tab-pane fade show active" id="tab_email" role="tabpanel" aria-labelledby="Email">
                                <CreateEmailCampaign />
                            </div>
                            <div className="tab-pane fade " id="tab_notification" role="tabpanel" aria-labelledby="Notifacition">
                                <CreateEmailCampaign />
                            </div>
                            <div className="tab-pane fade " id="tab_sms" role="tabpanel" aria-labelledby="Email">
                                <CreateEmailCampaign />
                            </div>
                        </div> */}

                        <div className="tabs" >
                            <div className="tab">
                                <input type="radio" name="campaign-tabs" id="tab-1" defaultChecked className="tab-switch" />
                                <label htmlFor="tab-1" className="tab-label">Email</label>
                                <div className="tab-content">
                                    <CreateEmailCampaign />
                                </div>
                            </div>
                            <div className="tab">
                                <input type="radio" name="campaign-tabs" id="tab-2" className="tab-switch" />
                                <label htmlFor="tab-2" className="tab-label">SMS</label>
                                <div className="tab-content"><CreateSMSCampaign /> </div>
                            </div>
                            <div className="tab">
                                <input type="radio" name="campaign-tabs" id="tab-3" className="tab-switch" />
                                <label htmlFor="tab-3" className="tab-label">Notification</label>
                                <div className="tab-content"><CreateNotificationCampaign /> </div>
                            </div>
                        </div>
                    </div>

                </Col>
            </Row>

        </Container>

    )


}

export default CapmaignCard;