import React, { useEffect, useMemo, useState } from 'react'
import PureBreadcrumbs from "../../breadcrums";
import { Card, Col, Container, Row } from 'react-bootstrap';
import { getAllFleetName } from '../../../../store/actions/fleetAction';
import { connect } from 'react-redux';
import Table from "../FleetTable"
import FleetDiagnostic from './FleetDiagnostic';
import FleetMainteance from './FleetMainteance';


function FleetAlert(props) {
    const [fleetDropDown, SetFleetDropDown] = useState([]);
    useEffect(() => {
        props.getAllFleetNameApi()
    }, [])
    const diagnostic = [
        {
            dtc_code: "9876543210",
            description: "Engine Overheat",
            count: 15,
            severity: "Severe"
        },
        {
            dtc_code: "5432167890",
            description: "Brake Failure",
            count: 8,
            severity: "Moderate"
        },
        {
            dtc_code: "1122334455",
            description: "Low Oil Pressure",
            count: 12,
            severity: "Severe"
        },
        {
            dtc_code: "5566778899",
            description: "Battery Low",
            count: 5,
            severity: "Mild"
        },
        {
            dtc_code: "9988776655",
            description: "Transmission Fault",
            count: 20,
            severity: "Severe"
        }
    ];

    const diagnostic_col = useMemo(
        () => [
            {
                Header: "DTC Code",
                accessor: "dtc_code",
            },
            {
                Header: "Description",
                accessor: "description",
            },
            {
                Header: "Count",
                accessor: "count",
            },
            {
                Header: "Severity",
                accessor: "severity",
                Cell: ({ value }) => (
                    <span style={{ color: getSeverityColor(value) }}>
                        {value}
                    </span>
                ),
            },
        ],
        []
    );
    const getSeverityColor = (severity) => {
        switch (severity) {
            case "Severe":
                return "red";
            case "Moderate":
                return "orange";
            case "Mild":
                return "green";
            default:
                return "black";
        }
    };
    const health = [
        {
            source_type: "Engine Trouble Codes",
            fit_vehicles: "15",
            issue_vehicles: "8",
            no_data: "0",
        },
        {
            source_type: "Low Battery",
            fit_vehicles: "21",
            issue_vehicles: "1",
            no_data: "0",
        },
        {
            source_type: "High Coolant",
            fit_vehicles: "12",
            issue_vehicles: "2",
            no_data: "0",
        },
        {
            source_type: "Change Fuel Filter",
            fit_vehicles: "21",
            issue_vehicles: "1",
            no_data: "0",
        },
        {
            source_type: "Change Coolant",
            fit_vehicles: "12",
            issue_vehicles: "2",
            no_data: "0",
        },
    ]

    const health_col = useMemo(
        () => [
            {
                Header: "Source Type",
                accessor: "source_type",
            },
            {
                Header: "No. of Fit Vehicles",
                accessor: "fit_vehicles",
            },
            {
                Header: "No. of Issue Vehicles",
                accessor: "issue_vehicles",
            },
            {
                Header: "Data Not Available",
                accessor: "no_data",
            },
        ],
        []
    );

    const behaviour = [
        {
            source_type: "Safe Driving",
            meeting_goal: "15",
            not_meeting_goal: "8",
        },
        {
            source_type: "Speeding",
            meeting_goal: "10",
            not_meeting_goal: "5",
        },
        {
            source_type: "Harsh Braking",
            meeting_goal: "12",
            not_meeting_goal: "7",
        },
        {
            source_type: "Rapid Acceleration",
            meeting_goal: "18",
            not_meeting_goal: "6",
        },
        {
            source_type: "Distracted Driving",
            meeting_goal: "20",
            not_meeting_goal: "4",
        },
        {
            source_type: "Lane Departure",
            meeting_goal: "14",
            not_meeting_goal: "9",
        }
    ];

    const behaviour_col = useMemo(
        () => [
            {
                Header: "Source Type",
                accessor: "source_type",
            },
            {
                Header: "Vehicle Meeting Goal",
                accessor: "meeting_goal",
            },
            {
                Header: "Vehicle Not Meeting Goal",
                accessor: "not_meeting_goal",
            },
        ],
        []
    );

    const maintenance = [
        {
            service_type: "Change Coolant",
            service_overdue: "4",
            service_overdue_next_month: "5",
        },
        {
            service_type: "Change Fuel Filter",
            service_overdue: "3",
            service_overdue_next_month: "5",
        },
        {
            service_type: "Low Battery",
            service_overdue: "4",
            service_overdue_next_month: "5",
        },
        {
            service_type: "Transmission Fault",
            service_overdue: "3",
            service_overdue_next_month: "5",
        },
    ];

    const maintenance_col = useMemo(
        () => [
            {
                Header: "Service Type",
                accessor: "service_type",
            },
            {
                Header: "No. of Vehicles Service Overdue",
                accessor: "service_overdue",
            },
            {
                Header: "No. of Vehicles Service Overdue Next Month",
                accessor: "service_overdue_next_month",
            },
        ],
        []
    );
    useEffect(() => {
        SetFleetDropDown(props.getAllFleetNameRes?.data?.filter((item) => item.status === "Active"))
    }, [props.getAllFleetNameRes])

    return (
        <Container fluid>
            <PureBreadcrumbs />
            <br />
            <section className="customercard" style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                <label style={{ marginTop: "5px" }}>
                    Select Fleet :
                </label>
                <select style={{ width: "170px", border: "1px solid #9f9f9f", borderRadius: "5px" }}>
                    {fleetDropDown?.map((item, index) => (
                        <option key={index} value={item.ownername}>{item.ownername}</option>
                    ))}
                </select>
            </section>
            <Row className="mt-3" style={{ display: "flex", justifyContent: "center", gap: "100px" }} >
                <Col xs={12} md={12} style={{ cursor: "pointer", display: "flex", justifyContent: "space-between", gap: "20px" }} >
                    <Card className="tariffcard">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <Card.Title>
                                <b>
                                    TOP DIAGNOSTIC TROUBLE CODES
                                </b>
                            </Card.Title>
                            <a
                                href={`/dashboard/fleet_All_Diagnostic`}
                                className="btn lgn-btn"
                                type="button"
                                style={{ display: "flex", justifyContent: "center", alignItems: "center", textDecoration: 'none', marginRight: "10px", width: "20px" }}
                            >
                                <p className="" style={{ fontSize: "17px", margin: 0 }}>View All</p>
                            </a>
                        </div>
                        <Card.Body>
                            <FleetDiagnostic />
                        </Card.Body>
                    </Card>
                    <Card className="tariffcard">
                        <Card.Title>
                            <b>
                                VEHICLE HEALTH DASHBOARD
                            </b>
                        </Card.Title>
                        <Card.Body>
                            <Table data={health} columns={health_col}></Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="mt-3" style={{ display: "flex", justifyContent: "center", gap: "100px" }} >
                <Col xs={12} md={12} style={{ cursor: "pointer", display: "flex", justifyContent: "space-between", gap: "20px" }} >
                    <Card className="tariffcard">
                        <Card.Title>
                            <b>
                                DRIVING BEHAVIOUR
                            </b>
                        </Card.Title>
                        <Card.Body>
                            <Table data={behaviour} columns={behaviour_col}></Table>
                        </Card.Body>
                    </Card>
                    <Card className="tariffcard">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <Card.Title>
                                <b>
                                    VEHICLE MAINTENANCE DASHBOARD
                                </b>
                            </Card.Title>
                            <a
                                href={`/dashboard/fleet_All_Maintenance`}
                                className="btn lgn-btn"
                                type="button"
                                style={{ display: "flex", justifyContent: "center", alignItems: "center", textDecoration: 'none', marginRight: "10px", width: "20px" }}
                            >
                                <p className="" style={{ fontSize: "17px", margin: 0 }}>View All</p>
                            </a>
                        </div>
                        <Card.Body>
                            <FleetMainteance />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

const mapStateToProps = (state) => {
    return {
        loading: state.fleet.isLoading,
        getAllFleetNameRes: state.fleet.all_fleet_name,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getAllFleetNameApi: () => dispatch(getAllFleetName()),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(FleetAlert);