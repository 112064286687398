import React, { useMemo } from "react";
import { connect } from "react-redux";
import "../../reacttable/reactTable.css";
import { FaEdit, FaEye } from "react-icons/fa";
import Table from "../../reacttable/table";
import moment from "moment";
import { useTranslation } from "react-i18next";

function OrganisationList(props) {
  const { t } = useTranslation();
  const data = props.list && props.list.organisations;
  // console.log(data);
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Address",
        accessor: "address",
      },
      {
        Header: "Phone no",
        accessor: "phone",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "IsFleet",
        accessor: "isfleet",
      },
      {
        Header: "City",
        accessor: "city",
      },
      {
        Header: "State",
        accessor: "state",
      },
      {
        Header: "Last Updated",
        id: "updatedat",
        accessor: (d) => {
          return moment(d.updatedat).local().format("DD-MM-YYYY");
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: (item) => {
          const value = item.value
          // console.log(publish.value)
          return (
            <div>
              <span>
                {value === "active" ? (
                  <span className="text-success">{t("Active")}</span>
                ) : value === "inactive" ? (
                  <span className="text-danger">{t("Inactive")}</span>
                ) : (
                  <span className="text-warning">{t("Draft")}</span>
                )}
              </span>
            </div>
          );
        },
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: (id) => {
          return (
            <div>
              <span>
                <a
                  href={`/dashboard/view_organisation/single/${id.value}`}
                  rel="tooltip"
                  title="View"
                >
                  <FaEye />
                </a>
              </span>
              &nbsp;&nbsp;
              <span>
                <a
                  href={`/dashboard/updateorganisation/${id.value}`}
                  rel="tooltip"
                  title="Edit"
                >
                  <FaEdit />
                </a>
              </span>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <div>

      {data && data != undefined &&
        <div>
          <Table data={data} columns={columns}></Table>
        </div>
      }
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    // res: state.tariff.list,
    // loading: state.tariff.isLoading
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    // all: () => dispatch(allTariffAction())
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(OrganisationList);
