import React, { useEffect, useMemo, useState } from "react";
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useRowSelect
} from "react-table";
import "../../reacttable/reactTable.css";
import { useTranslation } from 'react-i18next';
import GlobalFilter from "../../reacttable/GlobalFilter";
import { connect } from "react-redux";
import { saveCustomerGrouping } from "../../../../store/actions/customerActions";
import { Row } from "react-bootstrap";
import { Bounce, ToastContainer, toast } from "react-toastify";

function CustomerGroupingTable(props) {
  const { columns, data, tariffName, groupName, isChecked, sub_name } = props;
  const { t } = useTranslation();
  const [isToastVisible, setIsToastVisible] = useState(false);

  // console.log(props)
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    state,
    prepareRow,
    selectedFlatRows,
    setGlobalFilter,
    allColumns,
    getToggleHideAllColumnsProps,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 50 }
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );
  const flatRowData = useMemo(() => selectedFlatRows && selectedFlatRows.map((row) => row.original),
    [selectedFlatRows]
  );
  const { globalFilter, pageIndex, pageSize } = state;

  const handleSave = () => {
    props.saveCG(flatRowData, isChecked, sub_name, tariffName, groupName)
    // console.log("data is saved")
  }

  useEffect(() => {
    if (props.saveCustomer.statuscode === 200) {
      setIsToastVisible(true);
      toast.success('Customer Grouping saved successfully!', {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        onClose: () => {
          setIsToastVisible(false);
          window.location.reload();
        },
      });
    } else if (props.saveCustomer.statuscode === 405) {
      setIsToastVisible(true);
      toast.error(`❌ A group with same name already exists.Please choose different group name.`, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        onClose: () => {
          setIsToastVisible(false);
          window.location.reload();
        },
      });
    }
  }, [props.saveCustomer]);

  // if (props.saveCustomer.statuscode === 200) {
  //   alert("Customer Grouping saved successfully!")
  //   window.location.reload();
  // }
  // if (props.saveCustomer.statuscode === 405) {
  //   alert("A group with same name already exists.Please choose different group name.")
  //   window.location.reload();
  // }

  return (
    <>
      {isToastVisible && <div className="overlay" />}
      <ToastContainer
        position="top-center"
        autoClose={20}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{ top: "10%", width: "auto", zIndex: "9999" }}
      />
      <div className="container-fluid ">

        <div className="row mt-2 mb-2 d-flex justify-content-end">
          <div className="col-lg-5  mb-2">
            <div className="row d-flex justify-content-end">
              <div className=" mr-4">
                <GlobalFilter
                  filter={globalFilter}
                  setFilter={setGlobalFilter}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row ">
          <div className="col-md-12 d-flex justify-content-between" >
            <div className="collapse" id="collapseExample" >
              <div className="d-inline-flex checkbox-container" >
                <span className=" mr-3 filterall">
                  <input
                    type="checkbox"
                    className="custom-checkbox"
                    {...getToggleHideAllColumnsProps()}
                  />
                  <span style={{ fontSize: '12px' }}>Filter All </span>
                </span>
                {allColumns.map((column) => (
                  <span key={column.id} className="pr-3 mt-2 ">
                    <label>
                      <input
                        className="custom-checkbox d-flex mb-2"
                        type="checkbox"
                        {...column.getToggleHiddenProps()}
                      />
                      <span className="column-header mt-2">{column.Header}</span>
                    </label>
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="table-responsive">
          <div className="customtable">
            <table {...getTableProps()} id="User_Details">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    <th>
                      Select
                    </th>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {t(column.render("Header"))}
                        {column.canGroupBy ? (
                          <span {...column.getGroupByToggleProps()}>
                            {column.isGrouped ? "🛑 " : "👊 "}
                          </span>
                        ) : null}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? " 🔽"
                              : " 🔼"
                            : ""}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  // console.log("row",row)
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      <td>
                        <input
                          type="checkbox"
                          checked={row.isSelected}
                          disabled={groupName === '' || tariffName === ''}
                          onChange={() => row.toggleRowSelected()}
                        />
                      </td>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>

            </table>

          </div>
        </div>

        <div className="row mt-4">
          <div className="col-md-4 text-left">
            <span style={{ fontSize: "14px", color: "var(--text-primary)" }}>
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{" "}
            </span>
          </div>
          <div className="col-md-4 text-center">
            <div className="row">
              <div className="col-md-6 text-right ">
                <span style={{ fontSize: "14px", color: "var(--text-primary)" }}>
                  Page: {""}
                  <input
                    className="inputPage"
                    type="number"
                    defaultValue={pageIndex + 1}
                    onChange={(e) => {
                      const pageNumber = e.target.value
                        ? Number(e.target.value) - 1
                        : 0;
                      gotoPage(pageNumber);
                    }}
                    style={{ width: "30px", height: "25px" }}
                  />
                </span>
              </div>
              <div className="col-md-6 text-left " >
                <select
                  className="inputPage"
                  value={pageSize}
                  onChange={(e) => setPageSize(Number(e.target.value))}
                  style={{ height: "25px" }}
                >
                  {[50, 200, 500, 1000].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize} Row
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="col-md-4 text-right">
            <button
              className=" columnsBtn btn-sm mr-2"
              onClick={() => {
                gotoPage(0);
              }}
              disabled={!canPreviousPage}
            >
              {"<<"}
            </button>
            <button
              className=" columnsBtn btn-sm mr-2"
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              Previous
            </button>
            <button
              className="columnsBtn btn-sm mr-2"
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              Next
            </button>
            <button
              className="columnsBtn btn-sm mr-2"
              onClick={() => {
                gotoPage(pageCount - 1);
              }}
              disabled={!canNextPage}
            >
              {">>"}
            </button>
          </div>

        </div>
        <Row style={{ marginBottom: "3rem", marginTop: "1rem" }}>
          <div className="col-lg-12" style={{ display: "flex", justifyContent: "end" }}>
            {props.loading ?
              <button className="btn lgn-btn" type="button" disabled>
                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                &nbsp;
                {t("Saving...")}
              </button>
              :
              <button
                disabled={isChecked ? sub_name === '' : '' || groupName === '' || tariffName === '' || (flatRowData && flatRowData.length <= 0)}
                type="submit"
                style={{ width: "10rem" }}
                className="btn lgn-btn"
                onClick={handleSave}
              >
                {t("Save Group")}
              </button>
            }
          </div>
        </Row>

      </div>

    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.customer.isLoading,
    saveCustomer: state.customer.saveCustomerGrouping,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    saveCG: (flatRowData, isChecked, sub_name, tariffName, groupName) => dispatch(saveCustomerGrouping(flatRowData, isChecked, sub_name, tariffName, groupName)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CustomerGroupingTable)
