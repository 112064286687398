import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { allEvseAction, fetchSingleStationGroup } from '../../../../store/actions/evseAction';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PureBreadcrumbs from "../../breadcrums";
import UpdateCustomerGroupingTable from './updateStationGroupTable';
import { getAllCustomerGroupingList } from '../../../../store/actions/customerActions';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const UpdateStationGroup = (props) => {
    const { id } = useParams();
    const { t } = useTranslation();
    const [customerGroupName, setCustomerGroupName] = useState('');

    useEffect(() => {
        props.all();
        props.getallCustomerGroup();
        props.singleData(id)
    }, [])


    const singleData = props.getSingle && props.getSingle.data && props.getSingle.data
    const station_list = singleData && singleData.station_list
    // console.log(singleData)

    const handleSelectGroupName = (event) => {
        setCustomerGroupName(event.target.value);
    };

    const data = props.list && props.list.data?.filter((item) => item.status !== 'Discovery' && item.publish !== false)
    // console.log("data", data);

    const filteredData = data && data.map(item => ({
        name: item.name,
        address: item.address,
        city: item.city,
    }));
    // console.table(filteredData)

    const columns = useMemo(
        () => [
            {
                Header: 'Station Name',
                accessor: 'name'
            },
            {
                Header: 'City',
                accessor: 'city'
            },
            {
                Header: 'Address',
                accessor: 'address'
            },

        ],
        []
    );


    return (

        <div className="container-fluid pt-2">
            <PureBreadcrumbs />
            <Row className="">
                <Col lg={12} className="">
                    <div className="customercard mt-2">
                        <div className=""><h4>{t("Update Station Grouping")}</h4></div>
                        <Row className="mt-3">
                            <Col className="col-lg-6">
                                <div>
                                    <label>{t("Enter Station Group Name")} <span className='mandatory-field'>*</span></label>
                                    <input
                                        autoComplete='off'
                                        type="text"
                                        disabled
                                        className="form-control gray-border"
                                        value={singleData?.station_group_name || ''}
                                        placeholder="Enter Station Group Name"
                                    />
                                </div>
                            </Col>
                            <Col className="col-lg-6">
                                <div>
                                    <div className="">
                                        <label>{t("Select Group")} <span className='mandatory-field'>*</span></label>
                                        <select
                                            autoComplete='off'
                                            className={`form-select form-control`}
                                            name={"tariff"}
                                            id={"floatingSelectGridAdminStatus"}
                                            onChange={handleSelectGroupName}
                                        >
                                            <option disabled hidden>{singleData?.customer_group ? singleData?.customer_group : 'Select Customer Group'}</option>
                                            {props.getAll && props.getAll.data && props.getAll.data.map((v, k) =>
                                                <option key={v.id} value={v.group_name}>{v.group_name}</option>
                                            )}

                                        </select>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        {props.loading ? <h3 style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "250px",
                        }}>Loading data...</h3> :
                            filteredData && filteredData.length > 0 ?
                                (<div> <UpdateCustomerGroupingTable {...props} customerGroupName={customerGroupName || singleData?.customer_group} station_group_name={singleData?.station_group_name} station_list={station_list} data={filteredData} columns={columns}></UpdateCustomerGroupingTable></div>)
                                :
                                (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}><h3 className="loader">No data found</h3></div>)
                        }
                    </div>

                </Col>
            </Row>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        loading: state.evse.isEvseLoading,
        list: state.evse.allevse,
        getAll: state.customer.fetchAllCustomerGroups,
        getSingle: state.evse.fetchSingleStationGroup,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        all: () => dispatch(allEvseAction()),
        getallCustomerGroup: () => dispatch(getAllCustomerGroupingList()),
        singleData: (id) => dispatch(fetchSingleStationGroup(id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateStationGroup);
