import { fetchAllConnectTransactionData } from "../../../store/actions/stripeAction";
import { Container, Row, Col, Card } from "react-bootstrap";
import React, { useEffect, useMemo } from "react"
import PureBreadcrumbs from "../breadcrums";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Table from "../reacttable/table";
import "../reacttable/reactTable.css"
import moment from "moment";

function TransactionList(props) {
    const { t } = useTranslation();
    const currency = process.env.REACT_APP_CURRENCY;
    useEffect(() => {
        props.fetchAllTransactionList();
    }, []);

    const data = props.allTransactionList && props.allTransactionList.data
    // console.log(data)
    const totalAccount = data && data.length
    const payoutReversed = data && data.filter(active => active.reversed === true).length
    const payoutAmount = data && data.map(amount=> amount.amount).reduce((partialSum, a) => partialSum + a, 0);

    const columns = useMemo(
        () => [
            {
                Header: 'Account',
                id: "account",
                accessor: d => {
                    return d.destination.id
                }
            },
            {
                Header: 'Amount',
                accessor: 'amount'
            },

            {
                Header: 'Currency',
                accessor: 'currency'
            },
            //  {
            //     Header: 'Description',
            //     accessor: 'description'
            // },
            {
                Header: 'Payout Reversed',
                id: "reversed",
                accessor: d => {
                    return d.reversed ? <span className="text-success">Yes</span> : <span className="text-danger">No</span>
                }
            },
            {
                Header: 'Payout Date',
                id: "created",
                accessor: d => {
                    return moment(d.created * 1000).format('DD/MM/YYYY');
                }
            },

        ],
        []
    );
    return (
        <Container fluid>
            <PureBreadcrumbs />
            <br />

            <Row>
                <Col xs={12} md={4}>
                    <Card className="customercard">
                        <div style={{ textAlign: "left" }}>
                            <Card.Text style={{ fontSize: "14px" }}>{t("ALL TRANSFER")}</Card.Text>
                            <Card.Title>
                                <b>{totalAccount != undefined ? totalAccount : 0}</b>
                            </Card.Title>
                            <span
                                style={{
                                    float: "right",
                                    marginTop: "-60px",
                                    marginRight: "-8px",
                                }}
                            >
                                <div style={{ width: "20%" }}>
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            "/images/evse/availablelocation.svg"
                                        }
                                        alt="evse"
                                    />
                                </div>
                            </span>
                        </div>
                    </Card>
                    <br />
                </Col>
                <Col xs={12} md={4}>
                    <Card className="customercard">
                        <div style={{ textAlign: "left" }}>
                            <Card.Text style={{ fontSize: "14px" }}>{t("TOTAL PAYOUT AMOUNT")}</Card.Text>
                            <Card.Title>
                            <b>{currency} {payoutAmount != undefined ? payoutAmount : 0}</b>
                            </Card.Title>
                            <span
                                style={{
                                    float: "right",
                                    marginTop: "-60px",
                                    marginRight: "-8px",
                                }}
                            >
                                <div style={{ width: "20%" }}>
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            "/images/evse/commingssoonlocation.svg"
                                        }
                                        alt="evse"
                                    />
                                </div>
                            </span>
                        </div>
                    </Card>
                    <br />
                </Col>
                <Col xs={12} md={4}>
                    <Card className="customercard">
                        <div style={{ textAlign: "left" }}>
                            <Card.Text style={{ fontSize: "14px" }}>{t("PAYOUT REVERSED")}</Card.Text>
                            <Card.Title>
                                <b>{payoutReversed != undefined ? payoutReversed : 0}</b>

                            </Card.Title>

                            <span
                                style={{
                                    float: "right",
                                    marginTop: "-60px",
                                    marginRight: "-8px",
                                }}
                            >
                                <div style={{ width: "20%" }}>
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            "/images/evse/inoperativelocation.svg"
                                        }
                                        alt="evse"
                                    />
                                </div>
                            </span>
                        </div>
                    </Card>
                    <br />
                </Col>
            </Row>

            <Row className="mt-4">
                <Col>
                    <Card className="customercard">
                        <div className="table-header">{t("All Payout Details")}</div>
                        <div>
                            {props.loading ? <h3 style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "550px",
                            }}>Loading data...</h3> :
                                data && data.length > 0 ?
                                    (<div> <Table data={data} columns={columns}></Table></div>)
                                    :
                                    (<div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            height: "550px",
                                        }}
                                    >
                                        <h3>{t("No data found")}</h3>
                                    </div>)
                            }
                        </div>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}



const mapStateToProps = (state) => {
    return {
        allTransactionList: state.stripe.getAllTransactionList,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        fetchAllTransactionList: () => dispatch(fetchAllConnectTransactionData()),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(TransactionList);
