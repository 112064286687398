import React, { useMemo, useEffect } from "react";
import { Card, Container } from "react-bootstrap";
import "../../../reacttable/reactTable.css";
import Table from "../../../reacttable/table";
import PureBreadcrumbs from "../../../breadcrums";
import { connect } from "react-redux";
import { FaEdit, FaEye } from "react-icons/fa";
import { DeleteBookingCharger, fetchBookingSlot } from "../../../../../store/actions/evseAction";
import { useTranslation } from "react-i18next";
import { MdDelete } from "react-icons/md";


function ChargerSlotList(props) {
    const { t } = useTranslation();
    useEffect(() => {
        props.fetchChargerSlotList();
    }, []);
    const data = props.list && props.list.evses;
    // console.log(data);

    const handleDelete = (evse_id) => {
        const isConfirm = window.confirm('Are you sure you want to delete the booking slots ?');
        if (isConfirm) {
            props.deleteBookingAPI(evse_id)
        } else {
            return;
        }
    }

    if (props.DeleteBooking.statuscode === 200) {
        window.location.reload();
    }

    const columns = useMemo(
        () => [
            { Header: "Evse ID", id: "evseid header", accessor: "evse_id" },
            {
                Header: "Actions",
                accessor: "evse_id",
                Cell: (evse_id) => {
                    return (
                        <div>
                            <span>
                                <a
                                    href={`/dashboard/viewchargerslots/${evse_id.value}`}
                                    rel="tooltip"
                                    title="View"

                                >
                                    <FaEye />
                                </a>
                            </span>
                            &nbsp;&nbsp;
                            &nbsp;&nbsp;
                            <span>
                                <a
                                    href={`/dashboard/updateChargerSlots/${evse_id.value}`}
                                    rel="tooltip"
                                    title="View"

                                >
                                    <FaEdit />
                                </a>
                            </span>
                            &nbsp;&nbsp;
                        </div>
                    );
                },
            },
            {
                Header: "Delete charger slot",
                id: "evseid2",
                accessor: "evse_id",
                Cell: (evse_id) => {
                    return (
                        <div>
                            <span
                                onClick={() => handleDelete(evse_id.value)}
                                style={{ color: "red", cursor: "pointer" }}
                                rel="tooltip"
                            >

                                <MdDelete style={{ fontSize: "1.5rem" }} />
                            </span>
                            &nbsp;&nbsp;
                        </div>
                    );
                },
            },
        ],
        []
    );
    return (
        <Container fluid>
            <div className="row">
                <div className="col-lg-6 col-sm-12">
                    <PureBreadcrumbs />
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-lg-12 col-sm-12">
                    <Card className="customercard">
                        <div className="table-header">{t("View Charger Booking Slots")}</div>
                        {props.loading ? <h3 style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "550px",
                        }}>Loading data...</h3> :
                            data && data.length > 0 ? (
                                <div>
                                    <Table data={data} columns={columns}></Table>
                                </div>
                            ) : (
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        height: "550px",
                                    }}
                                >
                                    <h3 className="loader">{t("No data found")}</h3>
                                </div>
                            )}
                    </Card>
                </div>
            </div>

        </Container>
    )
}

const mapStateToProps = (state) => {
    return {
        loading: state.evse.isEvseLoading,
        list: state.evse.getEvseID,
        DeleteBooking: state.evse.deleteBookingChargerSlots,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        fetchChargerSlotList: () => dispatch(fetchBookingSlot()),
        deleteBookingAPI: (evse_id) => dispatch(DeleteBookingCharger(evse_id)),

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ChargerSlotList);
