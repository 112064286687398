import { Container, Row, Col, Card } from "react-bootstrap";
import PureBreadcrumbs from "../../breadcrums";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { allUserAction, fetchAdminPercentageChange } from "../../../../store/actions/accessAction";
import AccessList from "./accessList";
import { useTranslation } from "react-i18next";

function ViewAccess(props) {
  const { t } = useTranslation();
  useEffect(() => {
    props.getUsers();
    props.percentage_change();
  }, []);

  const approved = props.userlist.users && props.userlist.users.filter((active) => active.status === "Approved");
  const pending = props.userlist.users && props.userlist.users.filter((active) => active.status === "Pending");

  const Total_user_percentage = props.percent_change && props.percent_change.Total_user_percentage;
  const Total_user_change_symbol = props.percent_change && props.percent_change.Total_user_change_symbol;

  const Total_approved_percentage = props.percent_change && props.percent_change.Total_approved_percentage;
  const Total_approved_symbol = props.percent_change && props.percent_change.Total_approved_symbol;

  const Total_pending_percentage = props.percent_change && props.percent_change.Total_pending_percentage;
  const Total_pending_symbol = props.percent_change && props.percent_change.Total_pending_symbol;

  return (
    <Container fluid>
      <PureBreadcrumbs />
      <br />
      {/* <Row>
                <Col xs={12} md={4}>
                    <Card className="customercard">
                        <div style={{ textAlign: "left" }}>
                            <Card.Text style={{ fontSize: "14px" }}>{t("TOTAL USERS")}</Card.Text>
                            <Card.Title>
                                <b>{props.userlist.users != undefined ? props.userlist.users.length : 0}</b>
                            </Card.Title>
                            <span style={{ fontSize: "12px" }}>
                                {Total_user_change_symbol === "+" ? <img src={process.env.PUBLIC_URL + "/images/admin/profit.svg"} className="profitloss" alt="" /> : <img src={process.env.PUBLIC_URL + "/images/admin/loss.svg"} className="profitloss" alt="" />}
                                &nbsp;&nbsp;{Total_user_percentage}% &nbsp;{t(" change in last 30 days")}
                            </span>
                            <span style={{ float: "right", marginTop: "-60px", marginRight: "-8px" }}>
                                <div style={{ width: "20%" }} >
                                    <img src={process.env.PUBLIC_URL + '/images/admin/totaluser.svg'} alt="evse" />
                                </div>

                            </span>
                        </div>
                    </Card><br />
                </Col>
                <Col xs={12} md={4}>
                    <Card className="customercard">
                        <div style={{ textAlign: "left" }}>
                            <Card.Text style={{ fontSize: "14px" }}>{t("APPROVED USERS")}</Card.Text>
                            <Card.Title>
                                <b>{approved != undefined ? approved.length : 0}</b>
                            </Card.Title>
                            <span style={{ fontSize: "12px" }}>
                                {Total_approved_symbol === "+" ? <img src={process.env.PUBLIC_URL + "/images/admin/profit.svg"} className="profitloss" alt="" /> : <img src={process.env.PUBLIC_URL + "/images/admin/loss.svg"} className="profitloss" alt="" />}
                                &nbsp;&nbsp;{Total_approved_percentage}% &nbsp;{t(" change in last 30 days")}
                            </span>
                            <span style={{ float: "right", marginTop: "-60px", marginRight: "-8px" }}>
                                <div style={{ width: "50%" }} >
                                    <img src={process.env.PUBLIC_URL + '/images/admin/approveduser.svg'} alt="evse" />
                                </div>

                            </span>
                        </div>
                    </Card><br />
                </Col>
                <Col xs={12} md={4}>
                    <Card className="customercard">
                        <div style={{ textAlign: "left" }}>
                            <Card.Text style={{ fontSize: "14px" }}>{t("PENDING USERS")}</Card.Text>
                            <Card.Title>
                                <b>{pending != undefined ? pending.length : 0}</b>
                            </Card.Title>
                            <span style={{ fontSize: "12px" }}>
                                {Total_pending_symbol === "+" ? <img src={process.env.PUBLIC_URL + "/images/admin/profit.svg"} className="profitloss" alt="" /> : <img src={process.env.PUBLIC_URL + "/images/admin/loss.svg"} className="profitloss" alt="" />}
                                &nbsp;&nbsp;{Total_pending_percentage}% &nbsp;{t(" change in last 30 days")}
                            </span>
                            <span style={{ float: "right", marginTop: "-60px", marginRight: "-8px" }}>
                                <div style={{ width: "50%" }} >
                                    <img src={process.env.PUBLIC_URL + '/images/admin/pendinguser.svg'} alt="evse" />
                                </div>

                            </span>
                        </div>
                    </Card><br />
                </Col>
            </Row> */}
      <Row className="mt-2">
        <Col className="col-md-4 col-12">
          <Card className="customercard card-custom total-rules cards">
            <div className="diagonal-line"></div>
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>{t("Total users")}</Card.Text>
              <Card.Title>
                <b>{props.userlist.users != undefined ? props.userlist.users.length : 0}</b>
              </Card.Title>
              <span style={{ fontSize: "12px" }}>
                {Total_user_change_symbol === "+" ? (
                  <img src={process.env.PUBLIC_URL + "/images/admin/profit.svg"} className="profitloss" alt="" />
                ) : (
                  <img src={process.env.PUBLIC_URL + "/images/admin/loss.svg"} className="profitloss" alt="" />
                )}
                &nbsp;&nbsp;{Total_user_percentage}% &nbsp;{t(" change in last 30 days")}
              </span>
            </div>
          </Card>
          <br />
        </Col>
        <Col className="col-md-4 col-12">
          <Card className="customercard card-custom total-active-rules cards">
            <div className="diagonal-line"></div>
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>{t("Approved users")}</Card.Text>
              <Card.Title>
                <b>{approved != undefined ? approved.length : 0}</b>
              </Card.Title>
              <span style={{ fontSize: "12px" }}>
                {Total_approved_symbol === "+" ? (
                  <img src={process.env.PUBLIC_URL + "/images/admin/profit.svg"} className="profitloss" alt="" />
                ) : (
                  <img src={process.env.PUBLIC_URL + "/images/admin/loss.svg"} className="profitloss" alt="" />
                )}
                &nbsp;&nbsp;{Total_approved_percentage}% &nbsp;{t(" change in last 30 days")}
              </span>
            </div>
          </Card>
          <br />
        </Col>
        <Col className="col-md-4 col-12">
          <Card className="customercard card-custom total-inactive cards">
            <div className="diagonal-line"></div>
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>{t("Pending users")}</Card.Text>
              <Card.Title>
                <b>{pending != undefined ? pending.length : 0}</b>
              </Card.Title>
            </div>
            <span style={{ fontSize: "12px" }}>
              {Total_pending_symbol === "+" ? (
                <img src={process.env.PUBLIC_URL + "/images/admin/profit.svg"} className="profitloss" alt="" />
              ) : (
                <img src={process.env.PUBLIC_URL + "/images/admin/loss.svg"} className="profitloss" alt="" />
              )}
              &nbsp;&nbsp;{Total_pending_percentage}% &nbsp;{t(" change in last 30 days")}
            </span>
          </Card>
          <br />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <Card className="customercard">
            <div className="table-header">{t("Access User List")}</div>
            <AccessList></AccessList>
          </Card>
        </Col>
      </Row>
      <br />
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    userlist: state.access.alluser,
    loading: state.access.isAccessLoading,
    percent_change: state.access.percentageChange,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getUsers: () => dispatch(allUserAction()),
    percentage_change: () => dispatch(fetchAdminPercentageChange()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewAccess);
