import React from 'react'
import { useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { connect } from "react-redux";
import PureBreadcrumbs from "../../../breadcrums";
import { viewSingleEmailAction } from '../../../../../store/actions/campaignAction';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';
import DatePicker from "react-datepicker";

function ViewSingleEmail(props) {
    const { id } = useParams()
    // console.log(id);
    useEffect(() => {
        props.viewEmail(id)
    }, [])

    const data = props.viewEmailRes && props.viewEmailRes.data[0];
    // console.log(data)

    const date = moment.utc(data?.date).format('DD-MM-YYYY');
    // console.log(date); 

    return (
        <div className='container-fluid'>
            <PureBreadcrumbs />
            <Row className='mt-5'>
                <Col lg={12}>
                    <Card className='tariffcard'>
                        <b>View Email details</b>
                        <Card.Body >
                            <form>
                                <div className="form-floating">
                                    <label htmlFor="floatingSelectGridU">Selected Recipients</label>
                                    <input disabled value={data?.recipients || ''} type="recipients" className={`form-control gray-border `} name="recipients" id="floatingInputGridrecipients" placeholder="Enter SMS recipients" />
                                </div><br />

                                <div className="form-floating">
                                    <label>Email Subject<span className='mandatory-field'>*</span></label>
                                    <input disabled value={data?.subject || ''} type="description" className={`form-control gray-border `} name="title" id="floatingInputGridtitle" placeholder="Enter SMS Notification Title" />
                                </div><br />

                                <div className="form-floating">
                                    <label>Email Message<span className='mandatory-field'>*</span></label>
                                    <input disabled value={data?.message || ''} type="description" className={`form-control gray-border `} name="message" id="floatingInputGridmessage" placeholder="Message" />
                                </div><br />

                                <div className="form-floating">
                                    <Row>
                                        <Col md={6} className='d-flex'>
                                            <span className=''>
                                                Selected Date <span className='mandatory-field'>*</span> :
                                            </span>
                                            <input disabled value={date || ''} type="text" className={`form-control gray-border ml-3 w-50`} name="date" id="floatingInputGriddate" placeholder="date" />
                                        </Col>
                                    </Row>
                                </div>
                            </form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {

        loading: state.campaign.isLoading,
        viewEmailRes: state.campaign.viewSingleEmail
    }
}
const mapDispatchToProps = dispatch => {
    return {

        viewEmail: (id) => dispatch(viewSingleEmailAction(id))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewSingleEmail)
