import { Container, Row, Col, Card } from "react-bootstrap";
import PureBreadcrumbs from "../../breadcrums";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { allOrgPercentageApi, allOrglist } from "../../../../store/actions/organisationAction";
import OrganisationList from "./organisationList";
import { useTranslation } from "react-i18next";

function ViewOrganisation(props) {
  const { t } = useTranslation();
  useEffect(() => {
    props.getAllOrg();
    props.getOrgPercentage();
  }, []);

  const total = props.list && props.list.organisations && props.list.organisations.length;
  const activec =
    props.list && props.list.organisations && props.list.organisations.filter((lc) => lc.status == "active");
  const comingsoon =
    props.list && props.list.organisations && props.list.organisations.filter((lc) => lc.status == "draft");
  const pending =
    props.list && props.list.organisations && props.list.organisations.filter((lc) => lc.status == "inactive");

  // console.log(pending);

  const Total_ORG_percentage = props.orgPercent && props.orgPercent.total_organisation_percentage;
  const Total_ORG_change_symbol = props.orgPercent && props.orgPercent.change_symbol;
  // console.log("total tariff", props.orgPercent)

  const Total_active_percentage = props.orgPercent && props.orgPercent.active_percentage_change;
  const Total_active_symbol = props.orgPercent && props.orgPercent.active_change_symbol;

  const Total_draft_percentage = props.orgPercent && props.orgPercent.draft_percentage_change;
  const Total_draft_symbol = props.orgPercent && props.orgPercent.draft_change_symbol;

  const Total_inactive_percentage = props.orgPercent && props.orgPercent.inactive_percentage_change;
  const Total_inactive_symbol = props.orgPercent && props.orgPercent.inactive_change_symbol;

  // console.log(props.orgPercent)
  return (
    <Container fluid className="pb-4">
      <PureBreadcrumbs />
      <br />
      <Row>
        <Col xs={12} md={3}>
          {/* <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>
                {t("TOTAL ORGANISATION")}
              </Card.Text>
              <Card.Title>
                <b>{total != undefined ? total : 0}</b>
              </Card.Title>
              <span style={{ fontSize: "11px" }}>
                {Total_ORG_change_symbol === "+" ? <img src={process.env.PUBLIC_URL + "/images/admin/profit.svg"} className="profitloss" alt="" /> : <img src={process.env.PUBLIC_URL + "/images/admin/loss.svg"} className="profitloss" alt="" />}
                &nbsp;&nbsp;{Total_ORG_percentage}% &nbsp;{t(" change in last 30 days")}
              </span>
              <span
                style={{
                  float: "right",
                  marginTop: "-60px",
                  marginRight: "-8px",
                }}
              >
                <div style={{ width: "20%" }}>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/organisation/totalorganisation.svg"
                    }
                    alt="evse"
                  />
                </div>
              </span>
            </div>
          </Card> */}
          <Card className="customercard card-custom total-rules cards">
            <div className="diagonal-line"></div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p
                style={{
                  backgroundColor: "blue",
                  padding: "3px",
                  borderRadius: "50%",
                  width: "8px",
                  height: "8px",
                  marginRight: "8px",
                }}
              ></p>
              <p>{t("Total organisation")}</p>
            </div>
            <Card.Title>
              <b>{total != undefined ? total : 0}</b>
            </Card.Title>
            <span style={{ fontSize: "11px" }}>
              {Total_ORG_change_symbol === "+" ? (
                <img src={process.env.PUBLIC_URL + "/images/admin/profit.svg"} className="profitloss" alt="" />
              ) : (
                <img src={process.env.PUBLIC_URL + "/images/admin/loss.svg"} className="profitloss" alt="" />
              )}
              &nbsp;&nbsp;{Total_ORG_percentage}% &nbsp;{t(" change in last 30 days")}
            </span>
          </Card>
          <br />
        </Col>
        <Col xs={12} md={3}>
          {/* <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>{t("ACTIVE")}</Card.Text>
              <Card.Title>
                <b>{activec != undefined ? activec.length : 0}</b>
              </Card.Title>
              <span style={{ fontSize: "11px" }}>
                {Total_active_symbol === "+" ? (
                  <img src={process.env.PUBLIC_URL + "/images/admin/profit.svg"} className="profitloss" alt="" />
                ) : (
                  <img src={process.env.PUBLIC_URL + "/images/admin/loss.svg"} className="profitloss" alt="" />
                )}
                &nbsp;&nbsp;{Total_active_percentage}% &nbsp;{t(" change in last 30 days")}
              </span>
              <span
                style={{
                  float: "right",
                  marginTop: "-60px",
                  marginRight: "-8px",
                }}
              >
                <div style={{ width: "20%" }}>
                  <img src={process.env.PUBLIC_URL + "/images/organisation/available.svg"} alt="evse" />
                </div>
              </span>
            </div>
          </Card> */}
          <Card className="customercard card-custom total-active-rules cards">
            <div className="diagonal-line"></div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p
                style={{
                  backgroundColor: "orange",
                  padding: "3px",
                  borderRadius: "50%",
                  width: "8px",
                  height: "8px",
                  marginRight: "8px",
                }}
              ></p>
              <p>{t("Active")}</p>
            </div>
            <Card.Title>
              <b>{activec != undefined ? activec.length : 0}</b>
            </Card.Title>
            <span style={{ fontSize: "11px" }}>
              {Total_active_symbol === "+" ? (
                <img src={process.env.PUBLIC_URL + "/images/admin/profit.svg"} className="profitloss" alt="" />
              ) : (
                <img src={process.env.PUBLIC_URL + "/images/admin/loss.svg"} className="profitloss" alt="" />
              )}
              &nbsp;&nbsp;{Total_active_percentage}% &nbsp;{t(" change in last 30 days")}
            </span>
          </Card>
          <br />
        </Col>
        <Col xs={12} md={3}>
          {/* <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>{t("DRAFT")}</Card.Text>
              <Card.Title>
                <b>{comingsoon != undefined ? comingsoon.length : 0}</b>
              </Card.Title>
              <span style={{ fontSize: "11px" }}>
                {Total_draft_symbol === "+" ? (
                  <img src={process.env.PUBLIC_URL + "/images/admin/profit.svg"} className="profitloss" alt="" />
                ) : (
                  <img src={process.env.PUBLIC_URL + "/images/admin/loss.svg"} className="profitloss" alt="" />
                )}
                &nbsp;&nbsp;{Total_draft_percentage}% &nbsp;{t(" change in last 30 days")}
              </span>
              <span
                style={{
                  float: "right",
                  marginTop: "-60px",
                  marginRight: "-8px",
                }}
              >
                <div style={{ width: "20%" }}>
                  <img src={process.env.PUBLIC_URL + "/images/organisation/comingsoon.svg"} alt="evse" />
                </div>
              </span>
            </div>
          </Card> */}
          <Card className="customercard card-custom total-inactive cards">
            <div className="diagonal-line"></div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p
                style={{
                  backgroundColor: "red",
                  padding: "3px",
                  borderRadius: "50%",
                  width: "8px",
                  height: "8px",
                  marginRight: "8px",
                }}
              ></p>
              <p>{t("Draft")}</p>
            </div>
            <Card.Title>
              <b>{comingsoon != undefined ? comingsoon.length : 0}</b>
            </Card.Title>
            <span style={{ fontSize: "11px" }}>
              {Total_draft_symbol === "+" ? (
                <img src={process.env.PUBLIC_URL + "/images/admin/profit.svg"} className="profitloss" alt="" />
              ) : (
                <img src={process.env.PUBLIC_URL + "/images/admin/loss.svg"} className="profitloss" alt="" />
              )}
              &nbsp;&nbsp;{Total_draft_percentage}% &nbsp;{t(" change in last 30 days")}
            </span>
          </Card>
          <br />
        </Col>
        <Col xs={12} md={3}>
          {/* <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>{t("INACTIVE")}</Card.Text>
              <Card.Title>
                <b>{pending != undefined ? pending.length : 0}</b>
              </Card.Title>
              <span style={{ fontSize: "11px" }}>
                {Total_inactive_symbol === "+" ? (
                  <img src={process.env.PUBLIC_URL + "/images/admin/profit.svg"} className="profitloss" alt="" />
                ) : (
                  <img src={process.env.PUBLIC_URL + "/images/admin/loss.svg"} className="profitloss" alt="" />
                )}
                &nbsp;&nbsp;{Total_inactive_percentage}% &nbsp;{t(" change in last 30 days")}
              </span>
              <span
                style={{
                  float: "right",
                  marginTop: "-60px",
                  marginRight: "-8px",
                }}
              >
                <div style={{ width: "20%" }}>
                  <img src={process.env.PUBLIC_URL + "/images/organisation/inoperative.svg"} alt="evse" />
                </div>
              </span>
            </div>
          </Card> */}
          <Card className="customercard card-custom total-draft cards">
            <div className="diagonal-line"></div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p
                style={{
                  backgroundColor: "grey",
                  padding: "3px",
                  borderRadius: "50%",
                  width: "8px",
                  height: "8px",
                  marginRight: "8px",
                }}
              ></p>
              <p>{t("Inactive")}</p>
            </div>
            <Card.Title>
              <b>{pending != undefined ? pending.length : 0}</b>
            </Card.Title>
            <span style={{ fontSize: "11px" }}>
              {Total_inactive_symbol === "+" ? (
                <img src={process.env.PUBLIC_URL + "/images/admin/profit.svg"} className="profitloss" alt="" />
              ) : (
                <img src={process.env.PUBLIC_URL + "/images/admin/loss.svg"} className="profitloss" alt="" />
              )}
              &nbsp;&nbsp;{Total_inactive_percentage}% &nbsp;{t(" change in last 30 days")}
            </span>
          </Card>
          <br />
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <Card className="customercard">
            <div className="table-header">{t("Organisation List")}</div>
            {props.loading ? (
              <h3
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "650px",
                }}
              >
                Loading data...
              </h3>
            ) : props.list.organisations ? (
              <OrganisationList {...props}></OrganisationList>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h3 className="loader">{t("No data found")}</h3>
              </div>
            )}
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.operator.isLoading,
    list: state.operator.allOrg,
    orgPercent: state.operator.allOrgPercentage,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAllOrg: () => dispatch(allOrglist()),
    getOrgPercentage: () => dispatch(allOrgPercentageApi()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewOrganisation);
