// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.statuscard {
    background: var(--sidebar-topbar);
    /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25); */
    border-radius: 10px;
    padding: 5px;
}

.count {
    font-weight: 500;
    font-size: 24px;
    color: var(--text-primary);
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/insights/chargerStats/chargerStats.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,iDAAiD;IACjD,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,0BAA0B;AAC9B","sourcesContent":[".statuscard {\n    background: var(--sidebar-topbar);\n    /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25); */\n    border-radius: 10px;\n    padding: 5px;\n}\n\n.count {\n    font-weight: 500;\n    font-size: 24px;\n    color: var(--text-primary);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
