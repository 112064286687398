import React from 'react'
import { Card, Row, Col, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { useParams, Redirect } from "react-router-dom";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useEffect, useState } from "react";
import PureBreadcrumbs from "../../breadcrums";
import "../loyalty.css";
import DatePicker from "react-datepicker";
import { useTranslation } from 'react-i18next';
import { fetchAllRuleApi, fetchSingleProgramApi, updateProgramApi } from '../../../../store/actions/loyaltyAction';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import ReactSelect from 'react-select';
import DarkThemeStyles from '../../resuableComponent/reactSelectSingleValueStyle';


const UpdateProgram = (props) => {
    const { t } = useTranslation();
    const { program_name } = useParams();
    const [redirect, setRedirect] = useState(false);
    const [isToastVisible, setIsToastVisible] = useState(false);

    useEffect(() => {
        props.fetchSingle(program_name);
        props.fetch_all_rule();
    }, [])
    const data = props.fetchSingleRes && props.fetchSingleRes.data;
    // console.log(data)
    const prevStatus = data && data[0] && data[0].status

    const programSchema = Yup.object().shape({
        program_name: Yup.string().required('program name is required'),
        rule_name: Yup.string().required('rule name is required'),
    });
    const { register, setValue, getValues, handleSubmit, reset, formState: { errors } } = useForm({ resolver: yupResolver(programSchema), mode: "all" });

    useEffect(() => {
        if (data[0]) {
            setValue("rule_name", data[0]?.rule_name);
            setValue("program_name", data[0]?.program_name);
        }
    }, [data]);

    const previousStartDate = data && data[0]?.startdate;
    const previousEndDate = data && data[0]?.enddate;
    // console.log("previousEndDate",previousEndDate)
    const PrevStartDate = previousStartDate ? new Date(previousStartDate) : null;
    const PrevEndDate = previousEndDate ? new Date(previousEndDate) : null;

    const [startdate, setstartdate] = useState(null);
    const [enddate, setenddate] = useState(null);

    const onSubmit = async data => {
        if (startdate === null && enddate === null) {
            // console.log("data saved with both previous date successfully",data,prevStatus,PrevStartDate, PrevEndDate);
            props.updatePro(data, prevStatus, PrevStartDate, PrevEndDate)
        } else if (startdate !== null && enddate === null) {
            // console.log("data saved with  startdate, PrevEndDate successfully",data,prevStatus,startdate, PrevEndDate);
            props.updatePro(data, prevStatus, startdate, PrevEndDate)
        } else if (startdate === null && enddate !== null) {
            // console.log("data saved with  PrevStartDate, enddate successfully",data,prevStatus,PrevStartDate, enddate);
            props.updatePro(data, prevStatus, PrevStartDate, enddate)
        }
        else if (PrevStartDate !== null && PrevEndDate !== null) {
            // console.log("data saved with both selected date successfully",data,prevStatus,startdate, enddate);     
            props.updatePro(data, prevStatus, startdate, enddate)
        }
    }


    useEffect(() => {
        if (props.updateProRes.statuscode === 200) {
            setIsToastVisible(true);
            toast.success('Program has been updated successfully!', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    setRedirect(true)
                },
            });
        }
    }, [props.updateProRes]);

    if (redirect) {
        return <Redirect to='/dashboard/view_program' />;
    }

    const handleRuleName = (e) => {
        const code = e.value;
        // console.log("rule_name", code);
        setValue("rule_name", code);
    };

    const ruleNameOptions = props.allRuleRes && props.allRuleRes.data?.filter((status) => status.status !== false)?.map(item => ({
        value: item.rule_name,
        label: item.rule_name
    }));


    return (
        <Container fluid className="pb-4">
            {isToastVisible && <div className="overlay" />}
            <ToastContainer
                position="top-center"
                autoClose={20}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ top: "10%", width: "auto", zIndex: "9999" }}
            />
            <PureBreadcrumbs />
            <br />
            <Row>
                <Col xs={12} md={1}>
                </Col>
                <Col xs={12} md={10}>
                    <Card className="tariffcard">
                        <Card.Text style={{ fontSize: "14px", padding: "10px", paddingLeft: "15px" }}><b>{t("Update Program")}</b></Card.Text>
                        <Card.Body>
                            <form autoComplete="off" onSubmit={e => e.preventDefault()}>

                                <Row>
                                    <Col lg={6} >
                                        <div className="form-floating">
                                            <label>{t("Program Name")}  <span className='mandatory-field'>*</span></label>
                                            <input disabled type="text" autoFocus className={`form-control gray-border ${errors.program_name ? 'is-invalid' : ''}`} name="program_name" id="floatingInputGridProgramName" placeholder={t("Enter the program name")} {...register("program_name")} />
                                            <div className="invalid-feedback">{errors.program_name?.message}</div>
                                        </div>
                                    </Col>

                                    <Col lg={6}>
                                        <div className="form-floating">
                                            <label>{t("Select Rule Name")}  <span className='mandatory-field'>*</span></label>
                                            <ReactSelect
                                                options={ruleNameOptions ? ruleNameOptions : []}
                                                className={`${errors.rule_name ? 'is-invalid' : ''}`}
                                                name="rule_name"
                                                id="floatingInputGridrule_name"
                                                placeholder={data?.[0]?.rule_name || "Select Rule Name"}
                                                styles={DarkThemeStyles}
                                                menuPortalTarget={document.body}
                                                onChange={handleRuleName}
                                            />
                                            <div className="invalid-feedback">{errors.rule_name?.message}</div>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="mt-3">
                                    <Col className="text-center">
                                        {t("Select Start Date")} :
                                    </Col>
                                    <Col>
                                        <DatePicker
                                            className='gray-border'
                                            selected={startdate || PrevStartDate}
                                            name="start"
                                            onChange={(date) => setstartdate(date)}
                                            selectsStart
                                            startdate={startdate || PrevStartDate}
                                            dateFormat="dd/MM/yyyy"
                                        />
                                    </Col>
                                </Row><br />
                                <Row>
                                    <Col className="text-center">
                                        {t("Select End Date")} :
                                    </Col>
                                    <Col>
                                        <DatePicker
                                            className='gray-border'
                                            selected={enddate || PrevEndDate}
                                            onChange={(date) => setenddate(date)}
                                            selectsEnd
                                            startdate={startdate || PrevStartDate}
                                            enddate={enddate || PrevEndDate}
                                            minDate={startdate || PrevStartDate}
                                            showDisabledMonthNavigation
                                            dateFormat="dd/MM/yyyy"
                                        />
                                    </Col>
                                </Row>

                                <Row className="mt-5 d-flex justify-content-md-end">
                                    <Col lg={6} className='d-flex justify-content-center justify-content-md-end'>
                                        {props.loading ?
                                            <button className="btn lgn-btn" type="button" disabled>
                                                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                {t("Saving...")}
                                            </button>
                                            :
                                            <button className="lgn-btn btn" type="submit" onClick={handleSubmit(onSubmit)}>{t("UPDATE PROGRAM")}</button>
                                        }
                                    </Col>
                                </Row>
                            </form>
                        </Card.Body>
                        <br /><br />
                    </Card>

                </Col>
                <Col xs={12} md={1}>
                </Col>

            </Row>
        </Container>
    )
}



const mapStateToProps = (state) => {
    return {
        loading: state.loyalty.isLoading,
        fetchSingleRes: state.loyalty.fetchSingleProgram,
        updateProRes: state.loyalty.updateProgram,
        allRuleRes: state.loyalty.fetchAllRule,

    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetch_all_rule: () => dispatch(fetchAllRuleApi()),
        fetchSingle: (program_name) => dispatch(fetchSingleProgramApi(program_name)),
        updatePro: (data, prevStatus, startdate, enddate) => dispatch(updateProgramApi(data, prevStatus, startdate, enddate))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UpdateProgram)
