import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getAllCustomerGroupingList, getSingleCustomerGroupingList } from "../../../../store/actions/customerActions";
import PureBreadcrumbs from "../../breadcrums";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const ViewCustomerGroup = (props) => {
    const { id } = useParams();
    const { t } = useTranslation();
    const group_name = id;


    const data = props.getSingle && props.getSingle.data && props.getSingle.data[0]
    const customer = data && data.customer
    // console.log(data)

    useEffect(() => {
        props.all();
        props.singleData(group_name)
    }, [group_name])


    return (
        <Container fluid className="pb-4">
            <PureBreadcrumbs />
            <Row className="" >
                <Col lg={12} className="">
                    <div className="tariffcard mt-2">
                        <div className="table-header pl-0">{t("View Customer Group Details")}</div>
                        <Row className="mt-3">
                            <Col className="col-lg-6">
                                <div>
                                    <label>{t("Group Name")} <span className='mandatory-field'>*</span></label>
                                    <input
                                        disabled
                                        type="text"
                                        className="form-control gray-border"
                                        value={data?.group_name || ''}
                                        placeholder="Enter Customer Group Name"
                                        readOnly
                                    />
                                </div>
                            </Col>
                            <Col className="col-lg-6">
                                <div>
                                    <div className="">
                                        <label>{t("Selected Tariff")} <span className='mandatory-field'>*</span></label>
                                        <select
                                            disabled
                                            className={`form-select form-control`}
                                            name={"tariff"}
                                            id={"floatingSelectGridAdminStatus"}
                                            readOnly
                                        >
                                            <option value={""}>{data?.tariff}</option>
                                        </select>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col className="col-lg-6">
                                <div>
                                    <label>{t("Is Subscription Valid ?")}</label>
                                    <input
                                        disabled
                                        type="text"
                                        className="form-control gray-border"
                                        value={data?.is_valid_subscription ? "true" : "false"}
                                        placeholder="Enter Customer Group Name"
                                        readOnly
                                    />
                                </div>
                            </Col>
                            <Col className="col-lg-6">
                                <div>
                                    <div className="">
                                        <label>{t("Selected Subscription Name")}</label>
                                        <select
                                            disabled
                                            className={`form-select form-control`}
                                            readOnly
                                        >
                                            <option value={""}>{data?.subscription_name ? data?.subscription_name : "Not available"}</option>
                                        </select>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <fieldset disabled className="mt-3">
                            {customer && customer.map((item, i) => (
                                <div key={i}>
                                    <Card className='tariff-card mb-2' >
                                        <Card.Body>
                                            <div className="row g-2 mt-3 ">
                                                <div className="col-md">
                                                    <div className="form-floating">
                                                        <label>{t("First Name")} </label>
                                                        <select className={`form-select form-control`} readOnly name={`firstname`} id="floatingSelectfirstname" value={item.firstname || ''}>
                                                            <option >{item.firstname}</option>
                                                        </select>
                                                    </div>

                                                </div>

                                                <div className="col-md">
                                                    <div className="form-floating">
                                                        <label>{t("Last Name")} </label>
                                                        <select className={`form-select form-control`} readOnly name={`lastname`} id="floatingSelectGridlast_name" value={item.lastname || ''}>
                                                            <option >{item.lastname}</option>
                                                        </select>
                                                    </div>

                                                </div>
                                                <div className="col-md">
                                                    <div className="form-floating">
                                                        <label>{t("User Name")} </label>
                                                        <select className={`form-select form-control`} readOnly name={`userid`} id="floatingSelectGriduserid" value={item.userid || ''}>
                                                            <option >{item.userid}</option>
                                                        </select>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="row g-2 mt-3">
                                                <div className="col-md">
                                                    <div className="form-floating">
                                                        <label>{t("Mobile")}</label>
                                                        <select className={`form-select form-control`} readOnly name={`mobile`} id="floatingSelectGridmobile" >
                                                            <option >{item.mobile}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md">
                                                    <div className="form-floating">
                                                        <label>{t("Email Id")}</label>
                                                        <select className={`form-select form-control`} readOnly name={`email`} id="floatingSelectGridemail" value={item.email}>
                                                            <option >{item.email}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                            ))}
                        </fieldset>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}
const mapStateToProps = (state) => {
    return {
        loading: state.customer.isLoading,
        getAll: state.customer.fetchAllCustomerGroups,
        getSingle: state.customer.fetchSingleCustomerGroupData,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        all: () => dispatch(getAllCustomerGroupingList()),
        singleData: (groupName) => dispatch(getSingleCustomerGroupingList(groupName))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewCustomerGroup)