import { Container } from "react-bootstrap";
import FleetCard from "./view";
import React, { useEffect } from 'react'
import "./fleet.css"
function Fleet(props) {
  return (
    <Container fluid>
      <FleetCard />
    </Container>
  );
}

export default Fleet;
