import { fetchSingleCustomerApi } from "../actions/loyaltyAction";

const initState = {
    isLoading: false,
    createRule: '',
    fetchAllRule:'',
    fetchSingleRule:'',
    updateRule: '',
    activateRule: '',
    deactivateRule: '',
    saveProgram:'',
    fetchAllProgram:'',
    fetchSingleProgram:'',
    updateProgram: '',
    activateProgram: '',
    deactivateProgram: '',
    saveRedeem:'',
    fetchAllRedeem:'',
    fetchSingleRedeem:'',
    updateRedeem: '',
    activateRedeem: '',
    deactivateRedeem: '',
    fetchAllCard: '',
    monthlyGraph: '',
    allCustomerList: '',
    fetchSingleGraph: '',
    fetchTotalConsumptionCardApi: '',
    fetchRuleDropdown: '',
    fetchRedeemRuleTypeDropdown: '',
    fetchSingleCustomerApi:'',
}
const loyaltyReducer = (state = initState, action) => {
    switch (action.type) {
        case 'LOAD_LOYALTY':
            return { ...state, isLoading: true };
        case 'SAVE_LOYALTY_RULE':
            return { ...state, createRule: action.payload, isLoading: false };
        case 'FETCH_ALL_LOYALTY_RULE':
            return { ...state, fetchAllRule: action.payload, isLoading: false };
        case 'FETCH_SINGLE_LOYALTY_RULE':
            return { ...state, fetchSingleRule: action.payload, isLoading: false };
        case 'UPDATE_LOYALTY_RULE':
            return { ...state, updateRule: action.payload, isLoading: false };
        case 'ACTIVATE_LOYALTY_RULE':
            return { ...state, activateRule: action.payload, isLoading: false };
        case 'DEACTIVATE_LOYALTY_RULE':
            return { ...state, deactivateRule: action.payload, isLoading: false };
        case 'SAVE_LOYALTY_PROGRAM':
            return { ...state, saveProgram: action.payload, isLoading: false };
        case 'FETCH_ALL_LOYALTY_PROGRAM':
            return { ...state, fetchAllProgram: action.payload, isLoading: false };
        case 'FETCH_SINGLE_LOYALTY_PROGRAM':
            return { ...state, fetchSingleProgram: action.payload, isLoading: false };
        case 'UPDATE_LOYALTY_PROGRAM':
            return { ...state, updateProgram: action.payload, isLoading: false };
        case 'ACTIVATE_LOYALTY_PROGRAM':
            return { ...state, activateProgram: action.payload, isLoading: false };
        case 'DEACTIVATE_LOYALTY_PROGRAM':
            return { ...state, deactivateProgram: action.payload, isLoading: false };
        case 'SAVE_LOYALTY_REDEEM':
            return { ...state, saveRedeem: action.payload, isLoading: false };
        case 'FETCH_ALL_REDEEM':
            return { ...state, fetchAllRedeem: action.payload, isLoading: false };
        case 'FETCH_SINGLE_REDEEM':
            return { ...state, fetchSingleRedeem: action.payload, isLoading: false };
        case 'UPDATE_LOYALTY_REDEEM':
            return { ...state, updateRedeem: action.payload, isLoading: false };
        case 'ACTIVATE_LOYALTY_REDEEM':
            return { ...state, activateRedeem: action.payload, isLoading: false };
        case 'DEACTIVATE_LOYALTY_REDEEM':
            return { ...state, deactivateRedeem: action.payload, isLoading: false };
        case 'FETCH_ALL_CARD_DATA':
            return { ...state, fetchAllCard: action.payload, isLoading: false };
        case 'FETCH_MONTHLY_GRAPH_DATA':
            return { ...state, monthlyGraph: action.payload, isLoading: false };
        case 'FETCH_ALL_CUSTOMER_API':
            return { ...state, allCustomerList: action.payload, isLoading: false };
        case 'FETCH_SINGLE_GRAPH_API':
            return { ...state, fetchSingleGraph: action.payload, isLoading: false };
        case 'FETCH_TOTAL_CONSUMPTION_CARD_API':
            return { ...state, fetchTotalConsumptionCardApi: action.payload, isLoading: false };
        case 'FETCH_RULE_TYPE_DROPDOWN_API':
            return { ...state, fetchRuleDropdown: action.payload, isLoading: false };
        case 'FETCH_REDEEM_RULE_DROPDOWN_API':
            return { ...state, fetchRedeemRuleTypeDropdown: action.payload, isLoading: false };
        case 'FETCH_SINGLE_CUSTOMER_DATA_API':
            return { ...state, fetchSingleCustomerApi: action.payload, isLoading: false };
        default:
            return state;
    }
}
export default loyaltyReducer;