import React, { memo } from 'react';
import { connect } from "react-redux";
import { Row, Col, Card } from 'react-bootstrap';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import "./chargerStats.css";
import { useTranslation } from 'react-i18next';

const ChargerCard = memo(({ link, icon, label, count, connectorsCount, percentage, color }) => (
    <Card className="statuscard">
        <a href={link} style={{ textDecoration: "none", color: "var(--text-primary)" }}>
            <div className="card-content">
                <p>
                    <img src={`${process.env.PUBLIC_URL}/images/insight/chargerStatus/${icon}.svg`} alt={label} />
                    &nbsp;{label}
                </p>
                <span className="count"> {count} / {connectorsCount} </span>
                <span style={{ float: "right", marginTop: "-20px" }}>
                    <div style={{ width: "50px" }} >
                        <CircularProgressbar value={percentage} text={`${percentage}%`} strokeWidth={5}
                            styles={buildStyles({
                                textColor: color,
                                pathColor: color,
                            })}
                        />
                    </div>
                </span>
            </div>
        </a>
    </Card>
));

function ChargerStatus(props) {
    const { t } = useTranslation();

    // Function to calculate the percentage
    const percentage = (partialValue, totalValue) => Math.round((100 * partialValue) / totalValue);

    // Array of charger statuses
    const chargerStats = [
        {
            link: `/dashboard/insight/evse/chargerstats/All`,
            icon: "TotalCharger",
            label: t("Total Chargers/Connectors"),
            count: props.evse_charger_status?.total,
            connectorsCount: props.evse_charger_status?.connectors_total,
            percentage: percentage(props.evse_charger_status?.total, props.evse_charger_status?.total),
            color: "#0D6EFD"
        },
        {
            link: `/dashboard/insight/evse/chargerstats/Available`,
            icon: "AvailableCharger",
            label: t("Available Chargers/Connectors"),
            count: props.evse_charger_status?.available,
            connectorsCount: props.evse_charger_status?.connectors_available_count,
            percentage: percentage(props.evse_charger_status?.available, props.evse_charger_status?.total),
            color: "#198754"
        },
        {
            link: `/dashboard/insight/evse/chargerstats/In use`,
            icon: "InUseCharger",
            label: t("In Use Chargers/Connectors"),
            count: props.evse_charger_status?.in_use,
            connectorsCount: props.evse_charger_status?.connectors_in_use_count,
            percentage: percentage(props.evse_charger_status?.in_use, props.evse_charger_status?.total),
            color: "#6C757D"
        },
        {
            link: `/dashboard/insight/evse/chargerstats/Coming soon`,
            icon: "ComingsoonCharger",
            label: t("Coming Soon Chargers/Connectors"),
            count: props.evse_charger_status?.coming_soon,
            connectorsCount: props.evse_charger_status?.connectors_coming_soon_count,
            percentage: percentage(props.evse_charger_status?.coming_soon, props.evse_charger_status?.total),
            color: "#0DCAF0"
        },
        {
            link: `/dashboard/insight/evse/chargerstats/Maintenance`,
            icon: "OutofOrderCharger",
            label: t("Out of order Chargers/Connectors"),
            count: props.evse_charger_status?.out_of_service,
            connectorsCount: props.evse_charger_status?.connectors_out_of_service_count,
            percentage: percentage(props.evse_charger_status?.out_of_service, props.evse_charger_status?.total),
            color: "#FFC107"
        },
        {
            link: `/dashboard/insight/evse/chargerstats/Discovery`,
            icon: "unavailableCharger",
            label: t("Discovery Chargers/Connectors"),
            count: props.evse_charger_status?.unavailable,
            connectorsCount: props.evse_charger_status?.connectors_unavailable_count,
            percentage: percentage(props.evse_charger_status?.unavailable, props.evse_charger_status?.total),
            color: "#DC3545"
        }
    ];

    return (
        <>
            <Row>
                {chargerStats?.map((stat, index) => (
                    <Col xs={12} md={6} lg={6} key={index} className="mb-3">
                        <ChargerCard {...stat} />
                    </Col>
                ))}
            </Row>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        // loading: state.insight.isLoading,
        // evse_charger_status: state.insight.evse_charger_status,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        // getEvseStatus: () => dispatch(fetchEVSEStatusAction()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ChargerStatus)

