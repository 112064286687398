const initState = {
    isLoading: false,
    savepartner: '',
    fetchsinglepartner: '',
    allpartner: '',
    updatepartner: '',
    fetchLocationByPartyid: '',
    fetchSingleConsumedLocation: '',
    activateRoamingPartner: '',
    deactivateRoamingPartner: '',
    fetchPartnerTariff: '',
    allpartyid: '',
    fetchPartnerTariffByID: '',
    locationRefresh: '',
    tariffRefresh: '',
    generateToken: '',
    fetchOcpiStation: '',
    fetchSingleOcpiStation: '',
    fetchExternalTransactionList: '',
    fetchAllExternalTransactionList: '',
    fetchInternalTransactionList: '',
    fetchAllInternalTransactionList: '',
    fetchEmspPartner: '',
    fetchAllEmspPartyId: '',
    fetchOcpiLocationStatusList: '',
    fetchOcpiLocationCount: '',
    fetchInternalTransactionDropdownList: '',
    ocpiExternalRefreshLocation: '',
    externalViewSinglePartnerTokens: '',
    externalViewSinglePartner: '',
    fetchPartnerFromExternalPartnerTariff: '',
    fetchPartnerServerDataFromExternalStation: '',
    fetchExternalViewSingleSessionData: '',
    fetchExternalViewSingleSessionPartnerData: '',
    fetchExternalViewSingleCdrSelfData: '',
    fetchExternalViewSingleCdrPartnerData: '',
    fetchExternalViewCdrSelfReportData: '',
    fetchExternalViewCdrPartnerReportData: '',
    fetchExternalViewSessionSelfReportData: '',
    fetchExternalViewSessionPartnerReportData: '',
};

const ocpiReducer = (state = initState, action) => {
    switch (action.type) {
        case 'OCPI_LOADING':
            return { ...state, isLoading: true }
        case 'SAVE_ROAMING_PARTNER':
            return { ...state, savepartner: action.payload, isLoading: false };
        case 'VIEW_ALL_ROAMING_PARTNER':
            return { ...state, allpartner: action.payload, isLoading: false };
        case 'VIEW_SINGLE_ROAMING_PARTNER':
            return { ...state, fetchsinglepartner: action.payload, isLoading: false };
        case 'UPDATE_ROAMING_PARTNER':
            return { ...state, updatepartner: action.payload, isLoading: false };
        case 'VIEW_LOCATION_BY_PARTY_ID':
            return { ...state, fetchLocationByPartyid: action.payload, isLoading: false };
        case 'FETCH_SINGLE_CONSUMED_LOCATION':
            return { ...state, fetchSingleConsumedLocation: action.payload, isLoading: false };
        case 'ACTIVATE_ROAMING_PARTNER':
            return { ...state, activateRoamingPartner: action.payload, isLoading: false };
        case 'DEACTIVATE_ROAMING_PARTNER':
            return { ...state, deactivateRoamingPartner: action.payload, isLoading: false };
        case 'FETCH_PARTNER_TARIFF':
            return { ...state, fetchAllPartnerTariff: action.payload, isLoading: false };
        case 'FETCH_PARTNER_ID':
            return { ...state, allpartyid: action.payload, isLoading: false };
        case 'FETCH_PARTNER_TARIFF_BY_ID':
            return { ...state, fetchPartnerTariffByID: action.payload, isLoading: false };
        case 'REFRESH_PARTNER_STATION_BY_ID':
            return { ...state, locationRefresh: action.payload, isLoading: false };
        case 'REFRESH_PARTNER_TARIFF_BY_ID':
            return { ...state, tariffRefresh: action.payload, isLoading: false };
        case 'GENERATE_TOKEN':
            return { ...state, generateToken: action.payload, isLoading: false };
        case 'FETCH_OCPI_STATION_API':
            return { ...state, fetchOcpiStation: action.payload, isLoading: false };
        case 'FETCH_SINGLE_OCPI_STATION_API':
            return { ...state, fetchSingleOcpiStation: action.payload, isLoading: false };
        case 'FETCH_EXTERNAL_TRANSACTION_LIST':
            return { ...state, fetchExternalTransactionList: action.payload, isLoading: false };
        case 'FETCH_ALL_EXTERNAL_TRANSACTION_LIST':
            return { ...state, fetchAllExternalTransactionList: action.payload, isLoading: false };
        case 'FETCH_INTERNAL_TRANSACTION_LIST':
            return { ...state, fetchInternalTransactionList: action.payload, isLoading: false };
        case 'FETCH_ALL_INTERNAL_TRANSACTION_LIST':
            return { ...state, fetchAllInternalTransactionList: action.payload, isLoading: false };
        case 'FETCH_INTERNAL_EMSP_PARTNER_API':
            return { ...state, fetchEmspPartner: action.payload, isLoading: false };
        case 'FETCH_ALL_EMSP_PARTY_ID':
            return { ...state, fetchAllEmspPartyId: action.payload, isLoading: false };
        case 'FETCH_OCPI_LOCATION_STATUS_API':
            return { ...state, fetchOcpiLocationStatusList: action.payload, isLoading: false };
        case 'FETCH_OCPI_LOCATION_COUNT_API':
            return { ...state, fetchOcpiLocationCount: action.payload, isLoading: false };
        case 'FETCH_INTERNAL_TRANSACTION_DROPDOWN_LIST_REDUCER':
            return { ...state, fetchInternalTransactionDropdownList: action.payload, isLoading: false };
        case 'OCPI_EXTERNAL_REFRESH_LOCATION_CACHE_REDUCER':
            return { ...state, ocpiExternalRefreshLocation: action.payload, isLoading: false };
        case 'OCPI_EXTERNAL_VIEW_SINGLE_PARTNER_TOKEN_REDUCER':
            return { ...state, externalViewSinglePartnerTokens: action.payload, isLoading: false };
        case 'OCPI_EXTERNAL_VIEW_SINGLE_PARTNER_REDUCER':
            return { ...state, externalViewSinglePartner: action.payload, isLoading: false };
        case 'FETCH_PARTNER_FROM_EXTERNAL_PARTNER_TARIFF_REDUCER':
            return { ...state, fetchPartnerFromExternalPartnerTariff: action.payload, isLoading: false };
        case 'FETCH_PARTNER_SERVER_DATA_FROM_EXTERNAL_STATION_REDUCER':
            return { ...state, fetchPartnerServerDataFromExternalStation: action.payload, isLoading: false };
        case 'FETCH_EXTERNAL_VIEW_SINGLE_SESSION_DATA_REDUCER':
            return { ...state, fetchExternalViewSingleSessionData: action.payload, isLoading: false };
        case 'FETCH_EXTERNAL_VIEW_SINGLE_SESSION_PARTNER_DATA_REDUCER':
            return { ...state, fetchExternalViewSingleSessionPartnerData: action.payload, isLoading: false };
        case 'FETCH_EXTERNAL_VIEW_SINGLE_CDR_SELF_DATA_REDUCER':
            return { ...state, fetchExternalViewSingleCdrSelfData: action.payload, isLoading: false };
        case 'FETCH_EXTERNAL_VIEW_SINGLE_CDR_PARTNER_DATA_REDUCER':
            return { ...state, fetchExternalViewSingleCdrPartnerData: action.payload, isLoading: false };
        case 'FETCH_EXTERNAL_VIEW_CDR_SELF_REPORT_REDUCER':
            return { ...state, fetchExternalViewCdrSelfReportData: action.payload, isLoading: false };
        case 'FETCH_EXTERNAL_VIEW_CDR_PARTNER_REPORT_REDUCER':
            return { ...state, fetchExternalViewCdrPartnerReportData: action.payload, isLoading: false };
        case 'FETCH_EXTERNAL_VIEW_SESSION_SELF_REPORT_REDUCER':
            return { ...state, fetchExternalViewSessionSelfReportData: action.payload, isLoading: false };
        case 'FETCH_EXTERNAL_VIEW_SESSION_PARTNER_REPORT_REDUCER':
            return { ...state, fetchExternalViewSessionPartnerReportData: action.payload, isLoading: false };

        default:
            return state;
    }
}
export default ocpiReducer;