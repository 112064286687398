const initState = {
    isLoading: false,
    create: '',
    fetchall: '',
    fetch_six_month_graph_api: '',
    fetchSingle: '',
    activate: '',
    deactivate: '',
    updateAPI: '',
    fetch_overview_data: '',
    fetch_advertisement_dropdown_api: '',
    fetch_advertise_click_count_api: '',
    fetch_all_advertise_report:'',
}

const advertisementReducer = (state = initState, action) => {
    switch (action.type) {
        case 'LOAD_ADVERTISE':
            return { ...state, isLoading: true }
        case 'SAVE_ADVERTISE':
            return { ...state, create: action.payload, isLoading: false };
        case 'LOAD_ADVERTISE_LIST':
            return { ...state, fetchall: action.payload, isLoading: false };
        case 'FETCH_SIX_MONTH_GRAPH_API':
            return { ...state, fetch_six_month_graph_api: action.payload, isLoading: false };
        case 'FETCH_SINGLE_ADVERTISE_DATA':
            return { ...state, fetchSingle: action.payload, isLoading: false };
        case 'FETCH_ACTIVATE_DATA':
            return { ...state, activate: action.payload, isLoading: false };
        case 'FETCH_DEACTIVATE_DATA':
            return { ...state, deactivate: action.payload, isLoading: false };
        case 'UPDATE_ADVERTISE_API_DATA':
            return { ...state, updateAPI: action.payload, isLoading: false };
        case 'FETCH_OVERVIEWDATA_API':
            return { ...state, fetch_overview_data: action.payload, isLoading: false };
        case 'FETCH_DROPDOWN_DATA':
            return { ...state, fetch_advertisement_dropdown_api: action.payload, isLoading: false };
        case 'FETCH_ADVERTISE_CLICK_COUNT_API':
            return { ...state, fetch_advertise_click_count_api: action.payload, isLoading: false };
        case 'FETCH_ALL_ADVERTISE_API':
            return { ...state, fetch_all_advertise_report: action.payload, isLoading: false };
        default:
            return state;
    }
}
export default advertisementReducer;