import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

function FleetMainteance() {
    const [series] = useState([
        {
            name: 'No. of Vehicles Service Overdue',
            data: [4, 3, 4, 3],
        },
        {
            name: 'No. of Vehicles Service Overdue Next Month',
            data: [5, 5, 5, 5],
        },
    ]);

    const options = {
        chart: {
            type: 'bar',
            height: 350,
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded',
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
        },
        xaxis: {
            categories: ['Change Coolant', 'Change Fuel Filter', 'Low Battery', 'Transmission Fault'],
        },
        yaxis: {
            title: {
                text: '$ (thousands)',
            },
        },
        fill: {
            opacity: 1,
        },
        // tooltip: {
        //     y: {
        //         formatter: function (val) {
        //             return "$ " + val + " thousands";
        //         },
        //     },
        // },
    };
    return (
        <div>
            <div id="chart">
                <ReactApexChart options={options} series={series} type="bar" height={350} />
            </div>
        </div>
    )
}

export default FleetMainteance