import React, { useState } from 'react'
import { useEffect } from "react";
import { Card, Row, Col, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import PureBreadcrumbs from "../../../breadcrums";
import "../../ocpi.css"
import { useTranslation } from 'react-i18next';
import { fetchExternalViewSingleStationPartnerServerAction, viewSingleConsumedStation } from '../../../../../store/actions/ocpiAction';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const ViewSingleConsumedLocation = (props) => {
    const { t } = useTranslation();
    const { name, party_id, id } = useParams();
    const [activeTab, setActiveTab] = useState('Self');
    const history = useHistory();


    useEffect(() => {
        if (activeTab === 'Self') {
            props.fetch(name, party_id) // Fetch Self data
        } else if (activeTab === 'Partner') {
            props.fetchExternalViewSingleStationPartnerServerApi(id, party_id) // Fetch partner data
        }
    }, [activeTab, name, id, party_id]);

    const selfData = (props.singleConsumedLocation && props.singleConsumedLocation?.data?.[0]) || {};
    const partnerData = (props.fetchPartnerServerDataFromExternalStationRes && props.fetchPartnerServerDataFromExternalStationRes?.data?.[0]) || {};
    const data = activeTab === 'Self' ? selfData : partnerData;
    const { latitude = null, longitude = null } = data?.coordinates || {};
    // console.log("data", data);
    const array = data && data.evses;
    // console.log("array", array);

    function getStepSize(data) {
        if (data.evses && data.evses.length > 0) {
            const evse = data.evses[0];
            if (evse.connectors && evse.connectors.length > 0) {
                const connector = evse.connectors[0];
                if (connector.tariff && connector.tariff.length > 0) {
                    const tariff = connector.tariff[0];
                    if (tariff.elements && tariff.elements.length > 0) {
                        const element = tariff.elements[0];
                        if (element.price_components && element.price_components.length > 0) {
                            const priceComponent = element.price_components[0];
                            return priceComponent.step_size;
                        }
                    }
                }
            }
        }
        return null; // Return null or some default value if step_size is not found
    }

    const stepSize = getStepSize(data);

    function getTariffPrice(data) {
        if (data.evses && data.evses.length > 0) {
            const evse = data.evses[0];
            if (evse.connectors && evse.connectors.length > 0) {
                const connector = evse.connectors[0];
                if (connector.tariff && connector.tariff.length > 0) {
                    const tariff = connector.tariff[0];
                    if (tariff.elements && tariff.elements.length > 0) {
                        const element = tariff.elements[0];
                        if (element.price_components && element.price_components.length > 0) {
                            const priceComponent = element.price_components[0];
                            return priceComponent.price;
                        }
                    }
                }
            }
        }
        return null; // Return null or some default value if step_size is not found
    }

    const tariffPrice = getTariffPrice(data);

    function getTariffType(data) {
        if (data.evses && data.evses.length > 0) {
            const evse = data.evses?.[0];
            if (evse.connectors && evse.connectors.length > 0) {
                const connector = evse.connectors?.[0];
                if (connector.tariff && connector.tariff.length > 0) {
                    const tariff = connector.tariff[0];
                    if (tariff.elements && tariff.elements.length > 0) {
                        const element = tariff.elements[0];
                        if (element.price_components && element.price_components.length > 0) {
                            const priceComponent = element.price_components[0];
                            return priceComponent.type;
                        }
                    }
                }
            }
        }
        return null; // Return null or some default value if step_size is not found
    }

    const tariffType = getTariffType(data);

    const handleUnlockConnector = (uid, connectorId) => {
        console.log(`Unlocking connector with UID: ${uid} and Connector ID: ${connectorId} with location id ${data.id}`);
        // Add your logic to unlock the connector here, like calling an API or updating the state
    };
    const handleViewTariff = (id) => {
        // console.log("tariff id & party id", id, party_id)
        history.push(`/dashboard/view_consumed_tariff/singleocpitariff/${id}/${party_id}`);
    };

    const renderForm = (isPartner) => (
        <Row className='mt-2'>
            <Col xs={12} md={12}>
                <form onSubmit={e => e.preventDefault()}>
                    <Card>
                        <div>
                            <h4 className='text-center p-3'>{t("Stations Details")}</h4>
                        </div>
                        <Card.Body>
                            <div className="row g-2">
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputGridname'>{t("Location Name")}</label>
                                        <input type="text" className={`form-control gray-border `} name="location" id="floatingInputGridname" placeholder={t("Location Name")} value={data?.name || 'Not Available'} disabled />
                                    </div>
                                </div>
                            </div>
                            <div className="row g-2">
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputGridAddress'>{t("Address")}</label>
                                        <input type="text" className={`form-control gray-border `} name="location" id="floatingInputGridAddress" placeholder={t("Address")} value={data?.address || 'Not Available'} disabled />
                                    </div>
                                </div>
                            </div>

                            <div className="row g-2">
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputCountry'>{t("Country")}</label>
                                        <input type="text" className={`form-control gray-border `} name="country" id="floatingInputCountry" placeholder={t("Country")} value={data?.country || 'Not Available'} disabled />
                                    </div>
                                </div>

                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <div className="form-floating">
                                            <label htmlFor='floatingInputState'>{t("State")}</label>
                                            <input type="text" className={`form-control gray-border `} name="state" id="floatingInputState" placeholder="Enter State/Province" value={data?.state || 'Not Available'} disabled />
                                        </div>

                                    </div>
                                </div>
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputcity'>{t("City")}</label>
                                        <input type="text" className={`form-control gray-border `} name="city" id="floatingInputcity" placeholder="Enter City" value={data?.city || 'Not Available'} disabled />

                                    </div>
                                </div>
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputzip'>{t("Zipcode")}</label>
                                        <input type="text" className={`form-control gray-border `} name="postal_code" id="floatingInputzip" value={data?.postal_code || 'Not Available'} placeholder="Enter location zip code" disabled />

                                    </div>
                                </div>
                            </div>
                            <div className="row g-2">
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputGridlat'>{t("Latitude")}</label>
                                        <input type="number" className={`form-control gray-border `} name="latitude" id="floatingInputGridlat" placeholder="Enter latitude" value={latitude || 'Not Available'} disabled />

                                    </div>
                                </div>
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputlong'>{t("Longitude")}</label>
                                        <input type="number" className={`form-control gray-border`} name="longitude" id="floatingInputlong" placeholder="Enter longitude" value={longitude || 'Not Available'} disabled />
                                    </div>
                                </div>
                            </div>

                            <div className="row g-2">
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputoperator'>{t("Select Organisation")}</label>
                                        <input type="input" className={`form-control gray-border `} name="operator" id="floatingInputoperator" placeholder="Enter Organisation" value={data?.operator?.name || 'Not Available'} disabled />
                                    </div>
                                </div>

                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputparkingtype'>{t("Parking Type")}</label>
                                        <input type="input" className={`form-control gray-border `} name="parkingtype" id="floatingInputparkingtype" placeholder="Enter parkingtype" value={data?.parking_type || 'Not Available'} disabled />
                                    </div>
                                </div>
                                {/* <div className="col-md p-2">
                                <div className="form-floating">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputocpi'>{t("OCPI Enabled")} ?</label>
                                        <input type="input" className={`form-control gray-border `} name="ocpi" id="floatingInputocpi" placeholder="Enter OCPI" value={''} disabled />
                                    </div>
                                </div>
                            </div> */}
                            </div>

                        </Card.Body>
                    </Card>

                    {/* ------------Charger rendering------------- */}

                    <div>
                        <Row className="mt-2">
                            <Col>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item active" aria-current="page">{t("View Chargers")}</li>
                                    </ol>
                                </nav>
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 col-lg-12 mx-auto">
                                        <div className="table-responsive">
                                            <table className="table table-sm table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">{t("Charge Box ID")}</th>
                                                        <th scope="col">{t("UID")}</th>
                                                        <th scope="col">{t("Status")}</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {array && array.map((charger, index) =>
                                                        <tr key={index}>
                                                            <td> {charger.evse_id}</td>
                                                            <td> {charger.uid}</td>
                                                            <td> {charger.status}</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item active" aria-current="page">{t("Connector List")}</li>
                                    </ol>
                                </nav>
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 col-lg-12 mx-auto">
                                        <div className="table-responsive">
                                            <table className="table table-sm table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">{t("Charge Box ID")}</th>
                                                        <th scope="col">{t("Connector ID ")}</th>
                                                        <th scope="col">{t("Connector Type")}</th>
                                                        <th scope="col">{t("Max Ampere")}</th>
                                                        <th scope="col">{t("Max Electric power")}</th>
                                                        <th scope="col">{t("Max Voltage")}</th>
                                                        <th scope="col">{t("Power Type")}</th>
                                                        {!isPartner && (<th scope="col">{t("Action")}</th>)}

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {array && array.map((item) => (
                                                        item && item.connectors && item.connectors.map((connector) => (
                                                            <tr key={item.evse_id + connector.id}>
                                                                <td>{item.evse_id}</td>
                                                                <td>{connector.id}</td>
                                                                <td>{connector.standard}</td>
                                                                <td>{connector.max_amperage}</td>
                                                                <td>{connector.max_electric_power}</td>
                                                                <td>{connector.max_voltage}</td>
                                                                <td>{connector.power_type}</td>
                                                                {!isPartner &&
                                                                    (
                                                                        <>
                                                                            <td>
                                                                                <button
                                                                                    onClick={() => handleUnlockConnector(item.uid, connector.id)}
                                                                                    className="btn lgn-btn"
                                                                                >
                                                                                    Unlock Connector
                                                                                </button>
                                                                            </td>
                                                                            <td>
                                                                                <button
                                                                                    onClick={() => handleViewTariff(connector.tariff_ids?.[0])}
                                                                                    className="btn lgn-btn"
                                                                                >
                                                                                    View Tariff
                                                                                </button>
                                                                            </td>
                                                                        </>
                                                                    )}
                                                            </tr>
                                                        ))
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                            </Col>
                        </Row>

                        {/* <Row>
                            <Col>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item active" aria-current="page">{t("Tariff List")}</li>
                                    </ol>
                                </nav>
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 col-lg-12 mx-auto">
                                        <div className="table-responsive">
                                            <table className="table table-sm table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">{t("Tariff Price")}</th>
                                                        <th scope="col">{t("Tariff Step Size")}</th>
                                                        <th scope="col">{t("Tariff Type")}</th>
                                                        <th scope="col">{t("Tariff Currency")}</th>
                                                        <th scope="col">{t("Country Code")}</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {array && array.map((item) => (
                                                        item && item.connectors && item.connectors.map((connector, index) => (
                                                            <tr key={item.evse_id + connector.id + index}>
                                                                <td>
                                                                    {connector.tariff && connector.tariff.map((tariffItem, index) => (
                                                                        <div key={index}>
                                                                            <p>{tariffPrice || "Not available"}</p>
                                                                        </div>
                                                                    ))}
                                                                </td>
                                                                <td>
                                                                    {connector.tariff && connector.tariff.map((tariffItem, index) => (
                                                                        <div key={index}>
                                                                            <p>{stepSize || "Not available"}</p>
                                                                        </div>
                                                                    ))}
                                                                </td>
                                                                <td>
                                                                    {connector.tariff && connector.tariff.map((tariffItem, index) => (
                                                                        <div key={index}>
                                                                            <p>{tariffType || "Not available"}</p>
                                                                        </div>
                                                                    ))}
                                                                </td>
                                                                <td>
                                                                    {connector.tariff && connector.tariff.map((tariffItem, index) => (
                                                                        <div key={index}>
                                                                            <p>{tariffItem.currency || "Not available"}</p>
                                                                        </div>
                                                                    ))}
                                                                </td>
                                                                <td>
                                                                    {connector.tariff && connector.tariff.map((tariffItem, index) => (
                                                                        <div key={index}>
                                                                            <p>{tariffItem.country_code || "Not available"}</p>
                                                                        </div>
                                                                    ))}
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                            </Col>
                        </Row> */}
                    </div>
                </form>
            </Col>
        </Row>
    );

    return (
        <Container fluid>
            <PureBreadcrumbs />
            <Row>
                <Col className="mt-2">
                    <span style={{ color: "var(--text-primary)", fontWeight: "bold" }}>Partner Details</span>
                </Col>
            </Row>

            <Row className="mt-3">
                <Col xs={12}>
                    <div style={{
                        border: "1px solid #d9d9d9",
                        borderRadius: "50px",
                        padding: "0px",
                        backgroundColor: "#f3f3f3", // Light background for the switch container
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "20%",
                        margin: "0 auto",
                    }}>
                        <button
                            onClick={() => setActiveTab('Self')}
                            className={`tab-button ${activeTab === 'Self' ? 'active' : ''}`}
                            style={{
                                backgroundColor: activeTab === 'Self' ? '#000000' : '#f3f3f3',
                                color: activeTab === 'Self' ? '#f3f3f3' : '#000000',
                                border: "none",
                                padding: '10px 20px',
                                borderRadius: "50px",
                                fontWeight: "bold",
                                fontSize: "16px",
                                cursor: "pointer",
                                transition: "all 0.3s ease",
                                width: "55%",
                            }}
                        >
                            Self
                        </button>
                        <button
                            onClick={() => setActiveTab('Partner')}
                            className={`tab-button ${activeTab === 'Partner' ? 'active' : ''}`}
                            style={{
                                backgroundColor: activeTab === 'Partner' ? '#000000' : '#f3f3f3',
                                color: activeTab === 'Partner' ? '#f3f3f3' : '#000000',
                                border: "none",
                                padding: '10px 20px',
                                borderRadius: "50px",
                                fontWeight: "bold",
                                fontSize: "16px",
                                cursor: "pointer",
                                transition: "all 0.3s ease",
                                width: "55%",
                            }}
                        >
                            Partner
                        </button>
                    </div>
                </Col>
            </Row>
            {props.loading ? (
                <Row >
                    <Col lg={12} className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden"></span>
                        </div>
                    </Col>
                </Row>
            ) : (
                <>
                    {activeTab === 'Self' && renderForm(false)}
                    {activeTab === 'Partner' && renderForm(true)}
                </>
            )}

        </Container>
    )
}

const mapStateToProps = (state) => {
    return {
        loading: state.ocpi.isLoading,
        singleConsumedLocation: state.ocpi.fetchSingleConsumedLocation,
        fetchPartnerServerDataFromExternalStationRes: state.ocpi.fetchPartnerServerDataFromExternalStation,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetch: (name, party_id) => dispatch(viewSingleConsumedStation(name, party_id)),
        fetchExternalViewSingleStationPartnerServerApi: (id, party_id) => dispatch(fetchExternalViewSingleStationPartnerServerAction(id, party_id)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewSingleConsumedLocation)