import React from 'react';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useTranslation } from 'react-i18next';

const DownloadRfid = (props) => {
    const { t } = useTranslation();
    const { allData } = props;

    const generatePDF = () => {
        const unit = "pt";
        const size = "A4";
        const orientation = "landscape";
        const marginLeft = 40;
        const marginTop = 40;
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(12);

        // Define headers with formatted names
        const headers = [
            "Rfid No.",
            "Commission Date",
            "Amount",
            "Kwh",
            "Time",
            "Currency",
            "Offline Payment",
            "Free Charging",
            "Type",
            "Sub Duration",
            "Station",
            "Status",
        ];

        // Map data from props to match headers
        const data = allData?.map(obj => {
            return [
                obj.rfid,
                obj.commission,
                obj.amount,
                obj.kwh,
                obj.time,
                obj.currency,
                obj.offline_payment ? "Yes" : "No",
                obj.free_charging ? "Yes" : "No",
                obj.type,
                obj.sub_duration,
                obj.station,
                obj.status,
            ];
        });


        let content = {
            startY: 50,
            head: [headers],
            body: data
        };

        doc.text("RFID List", marginLeft, 40);
        doc.autoTable(content);
        doc.save("Rfid_List.pdf");
    };

    const handleButtonClick = () => {
        generatePDF();
    };

    return (
        <div>
            <button className='pdfbutton' onClick={handleButtonClick} disabled={!allData?.length > 0} type="button">{t("Download RFID List")}</button>
        </div>
    );
};

export default DownloadRfid;
