import { Container, Card } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useParams, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { SingleCustomerWalletAction, updateCustomerWalletAction } from "../../../../store/actions/walletActions";
import PureBreadcrumbs from "../../breadcrums";
import moment from "moment";
import { t } from "i18next";
import { Bounce, ToastContainer, toast } from "react-toastify";
function UpdateWallet(props) {
    const currency = process.env.REACT_APP_CURRENCY;
    const { userid } = useParams()
    const [amt, setAmt] = useState('')
    const [isToastVisible, setIsToastVisible] = useState(false);

    // console.log(amt)
    const Add = (num, num1) => {
        const n1 = parseInt(num);
        const n2 = parseInt(num1);
        let ans = n1 + n2;
        return ans;
    }

    useEffect(() => {
        props.getSingleWalletData(userid);
    }, []);

    const data = props.fetchSinglWalletDetails && props.fetchSinglWalletDetails.wallet
    const updatedAmount = Add(data?.amount, amt)
    const updateWalletAmount = (userid, amt, id) => {
        props.updateCustomer(userid, amt, id)
    }

    useEffect(() => {
        if (props.cusres.statuscode === 201) {
            setIsToastVisible(true);
            toast.success('Customer wallet has been updated successfully', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    window.location.reload();
                },
            });
        }
    }, [props.cusres]);


    return (
        <Container fluid>
            {isToastVisible && <div className="overlay" />}
            <ToastContainer
                position="top-center"
                autoClose={20}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ top: "10%", width: "auto", zIndex: "9999" }}
            />
            <PureBreadcrumbs />
            <div className="row">
                <div className="col pad_t2">
                    <span style={{ color: "var(--text-primary)", fontWeight: "bold" }}> {t("Edit Wallet Details")} </span>
                </div>
            </div>
            <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>

                <div className="row pad_t2">
                    <div className="col">
                        <Card className="tariffcard">
                            <Card.Body>
                                <div className="row">
                                    <div className="col-lg-4 col-sm-12">
                                        <div>
                                            <label htmlFor="floatingInputGridName">{t("Username")}</label>
                                            <input className="input  gray-border" type="text" name="UserID" id="floatingInputGridName" placeholder={t("Username")} defaultValue={data.userid} disabled />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-12">
                                        <div>
                                            <label htmlFor="floatingInputGridName">{t("Firstname")}</label>
                                            <input className="input  gray-border" type="text" name="firstname" id="floatingInputGridName" placeholder={t("Firstname")} defaultValue={data.firstname} disabled />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-12">
                                        <div>
                                            <label htmlFor="floatingInputGridName">{t("Lastname")}</label>
                                            <input className="input  gray-border" type="text" name="lastname" id="floatingInputGridName" placeholder={t("Lastname")} defaultValue={data.lastname} disabled />
                                        </div>
                                    </div>
                                </div>

                                <div className="row pad_t2">
                                    {/* <div class="col-lg-4 col-sm-12">
                                    <div>
                                        <label htmlFor="floatingInputGridName">Amount</label>
                                        <input className="input" type="text" name="amount" id="floatingInputGridName" placeholder="amount" defaultValue={data.amount} disabled />
                                    </div>
                                </div> */}
                                    <div className="col-lg-4 col-sm-12">
                                        <div>
                                            <label htmlFor="floatingInputGridName">{t("Account Type")}</label>
                                            <input className="input  gray-border" type="text" name="type" id="floatingInputGridName" placeholder={t("Account Type")} defaultValue={data.type} disabled />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-12">
                                        <div>
                                            <label htmlFor="floatingInputGridName">{t("Created Date")}</label>
                                            <input className="input  gray-border" type="text" name="registerat" id="floatingInputGridName" placeholder={t("Created Date")} defaultValue={moment(data.registerat).local().format("DD-MM-YYYY")} disabled />
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <Card className="tariffcard" style={{ borderColor: "var(--button-color", padding: "15px" }}>
                                    <div className="row ">
                                        <div className="col-lg-4 col-sm-12 ">
                                            <div>
                                                <label htmlFor="floatingInputGridName">{t("Current Amount")}</label>
                                                <input className="input  gray-border" type="text" name="amount" id="floatingInputGridName" placeholder={t("Current Amount")} defaultValue={data.amount} disabled />
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-sm-12">
                                            <div>
                                                <label htmlFor="floatingInputGridName">{t("Credit/Debit Amount")}</label>
                                                <input className="input gray-border" autoFocus type="number" name="amount" id="floatingInputGridAmountUpdate" placeholder={t("Credit/Debit Amount")} value={amt || ''} onInput={e => setAmt(e.target.value)} />
                                            </div>

                                        </div>
                                        <div className="col-lg-4 col-sm-12">
                                            {
                                                amt !== 0 ?
                                                    <div>
                                                        <label htmlFor="floatingInputGridName">{t("Total Updated Amount")}</label>
                                                        <h2 className="fw-bold"><span className="me-2">{currency}</span>{updatedAmount ? updatedAmount : 0}</h2>
                                                    </div> : ''
                                            }
                                        </div>

                                    </div>
                                    <br />
                                    <div className="row">
                                        <div className="col">
                                            {/* <button className="lgn-btn first-button" type="button" onClick={() => reset()}> Reset </button> */}
                                        </div>
                                        {amt !== '' &&
                                            <div className="col d-lg-flex justify-content-end">
                                                {props.loading ?
                                                    <button className="btn lgn-btn" type="button" disabled>
                                                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                        &nbsp;
                                                        {t("Saving...")}
                                                    </button>
                                                    :
                                                    <button type="button" className="lgn-btn btn" onClick={(e) => updateWalletAmount(userid, amt, data.id)}>{t("updatedAmount")}</button>
                                                }
                                            </div>
                                        }
                                    </div>
                                    <br /> <br />
                                </Card>

                            </Card.Body>

                        </Card>

                    </div>
                </div >
            </form>

        </Container >
    )

}


const mapStateToProps = function (state) {
    return {
        loading: state.wallet.isLoading,
        fetchSinglWalletDetails: state.wallet.fetchSingleWallet,
        cusres: state.wallet.updatecust,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getSingleWalletData: (userid) => dispatch(SingleCustomerWalletAction(userid)),
        updateCustomer: (userid, amount, id) => dispatch(updateCustomerWalletAction(userid, amount, id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UpdateWallet));