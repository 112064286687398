import React from 'react'
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchEvseStationAction } from "../../../../store/actions/evseAction";
import { triggerReserveNow } from "../../../../store/actions/commandAction";
import { useTranslation } from 'react-i18next';
import { Bounce, ToastContainer, toast } from 'react-toastify';


const ReserveNowModal = (props) => {
    const { t } = useTranslation();
    const { stationid } = useParams()
    useEffect(() => {
        props.fetch(stationid);
    }, []);

    const evsedata = props.fetchstation && props.fetchstation
    const conn = evsedata && evsedata.evses
    const zxc = conn && conn.map(en => en.connectors)
    const data = Object.values(zxc).flatMap(arr => arr)
    //Firmware modal view//
    const [showFirmwareModel, setShowFirmwareModel] = useState(false);
    const handleClose = () => setShowFirmwareModel(false);
    const handleShow = () => setShowFirmwareModel(true);
    const [isToastVisible, setIsToastVisible] = useState(false);


    // const [startdate, setstartdate] = useState(new Date(Date.now() + (3600 * 1000 * 1)));
    // const [enddate, setenddate] = useState(new Date(Date.now() + (3600 * 1000 * 720)));

    //logic for reservenow start//    
    const disablePastDate = () => {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        const yyyy = today.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
    };

    const [reserve, set_reserve] = useState();
    const handleReserve = (e) => {
        set_reserve({
            ...reserve,
            [e.target.name]: e.target.value.trim(),
        });
    }
    const handleReserveSubmit = (e) => {
        e.preventDefault();
        // console.log(data);
        props.triggerReserveNow(reserve);
    }
    //logic for reservenow end //

    // if (props.reservenow.statuscode === 200) {
    //     alert("Success!!! ")
    //     // window.location.reload();
    // }


    useEffect(() => {
        if (props.reservenow.statuscode === 200) {
            setIsToastVisible(true);
            toast.success('Success!!!', {
                position: "top-center",
                autoClose: 300,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    window.location.reload();
                },
            });
        }
    }, [props.reservenow]);


    return (
        <div className='container'>
            {isToastVisible && <div className="overlay" />}
            <ToastContainer
                position="top-center"
                autoClose={20}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ top: "10%", width: "auto", zIndex: "9999" }}
            />

            <form onSubmit={handleReserveSubmit} autoComplete="off">

                <div className="form-label-group required">
                    <select
                        className="form-control border-radius"
                        name="evse_id"
                        onChange={handleReserve}
                        required
                    >
                        <option value="">{t("Select Charger")} (*)</option>
                        {evsedata && evsedata.evses.map((own) => (
                            <option key={own.evse_id} value={own.evse_id}>
                                {own.evse_id}
                            </option>
                        ))}
                    </select>
                </div><br />


                <div className="form-label-group required">
                    <select
                        className="form-control border-radius"
                        name="connectorId"
                        onChange={handleReserve}
                        required
                    >
                        <option value="">{t("Select Connector")} (*)</option>
                        {data && data.map((own) => (
                            <option key={own.id} value={own.id}>
                                {own.id}
                            </option>
                        ))}
                    </select>
                </div><br />


                <div className="form-label-group">
                    <input className="form-control gray-border" type="date" id="retrieveDate" name="retrieveDate" min={disablePastDate()} onChange={handleReserve} required />
                </div><br />



                <div className="form-label-group">
                    <input type="text" id="idtag" name="idtag" className="form-control gray-border control-label" placeholder={t("ID Tag")} onChange={handleReserve} />
                </div><br />


                <div className="form-label-group">
                    <input type="number" id="reservationid" name="reservationid" className="form-control gray-border control-label" placeholder={t("Reservation ID")} onChange={handleReserve} />
                </div><br />

                <div className="col-xl-6 col-sm-6 col-12 float-right">
                    {/* <button className="lgn-btn second-button">
                        {!props.loading ? "Reserve Now" : "Please wait..."}
                    </button> */}
                    <button className="lgn-btn btn">
                        {t("Reserve Now")}
                    </button>
                </div>

            </form>
        </div>
    )

}

const mapStateToProps = (state) => {
    return {
        loadingevse: state.evse.isEvseLoading,
        fetchstation: state.evse.viewstation,
        reservenow: state.command.reservenow,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetch: (stationid) => dispatch(fetchEvseStationAction(stationid)),
        triggerReserveNow: (r_now) => dispatch(triggerReserveNow(r_now)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReserveNowModal)