import React, { useEffect } from "react";
import { Row, Col, Card, Container, Spinner } from "react-bootstrap";
import "react-dates/initialize";
import PureBreadcrumbs from "../../breadcrums";
import { useState, useMemo } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  allRoamingPartnerPartnerID,
  fetchAllExternalTransactionDataAction,
  fetchExternalTransactionListAction,
} from "../../../../store/actions/ocpiAction";
import Table from "../../reacttable/table";
import moment from "moment";
import CustomOcpiPaginationTable from "../customOcpiPaginationTable";
import { CSVLink } from "react-csv";
import TransactionReportPdf from "./transactionReportPdf";
import { FaEye } from "react-icons/fa";

function RoamingReport(props) {
  const { t } = useTranslation();
  const [org, setOrg] = useState("All");
  const currency = process.env.REACT_APP_CURRENCY;
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const document_count = props.list && props.list.total_session;
  const defaultPageSize = 10;
  const pageCount = Math.ceil(document_count / defaultPageSize);

  useEffect(() => {
    props.allPartnerPartyId();
    props.getTransactionList(org, currentPage, pageSize);
    props.getAllTransactionListApi(org, 1, 10000000);
  }, [org, currentPage, pageSize]);

  const handleChange = (e) => {
    const data = e.target.value;
    setOrg(data);
  };
  const data = props.list && props.list.data;
  console.log("data: ", data);
  const allData = props.allList && props.allList.data;
  const processedData =
    allData &&
    allData.map((item) => ({
      ...item,
      date: item.date ? moment(item.date).format("DD-MM-YYYY") : "NA",
      start_date_time: item.start_date_time ? moment(item.start_date_time).format("HH:mm:ss") : "NA",
      end_date_time: item.end_date_time ? moment(item.end_date_time).format("HH:mm:ss") : "NA",
    }));

  const columns = useMemo(
    () => [
      { Header: "ID", accessor: "charging_id" },
      { Header: "First Name", accessor: "firstname" },
      { Header: "Last Name", accessor: "lastname" },
      { Header: "User Name", accessor: "userid" },
      { Header: "EVSE UID", accessor: "evse_uid" },
      { Header: "Location", accessor: "station_name", id: "station_name" },
      {
        Header: "Start Date",
        accessor: "date",
        Cell: (item) => {
          const value = item.cell;
          // console.log(item)
          return (
            <>
              <p>{value ? value : "NA"}</p>
            </>
          );
        },
      },
      {
        Header: "Start Time",
        accessor: "start_date_time",
        Cell: (item) => {
          const value = item.cell;
          // console.log(item)
          return (
            <>
              <p>{value ? moment(value).format("HH:mm:ss") : "NA"}</p>
            </>
          );
        },
      },
      {
        Header: "End Time",
        accessor: "end_date_time",
        Cell: (item) => {
          const value = item.cell;
          return (
            <>
              <p>{value ? moment(value).format("HH:mm:ss") : "NA"}</p>
            </>
          );
        },
      },
      { Header: "Connector ID", accessor: "connector_id" },
      { Header: "kWh", accessor: "kwh" },
      { Header: "Cost Excluding Vat", accessor: "excl_vat" },
      { Header: "Cost Including Vat", accessor: "incl_vat" },
      { Header: "Status", accessor: "status" },
      {
        Header: "Actions",
        id: "viewSingle",
        accessor: "session_id",
        Cell: (cell) => {
          const { item } = cell;
          // console.log(item)
          const { session_id, cdr_id, status, party_id } = item;
          return (
            <div>
              <button
                className="btn lgn-btn mr-2"
                onClick={() =>
                  (window.location.href = `/dashboard/viewSingleOcpiSessionReport/${session_id}/${party_id}`)
                }
                title="View Single Session"
                style={{ height: "auto", width: "25%" }}
              >
                View Single Session
              </button>
              <button
                className="btn lgn-btn my-2 mr-2"
                disabled={status !== "COMPLETED"}
                onClick={() => (window.location.href = `/dashboard/viewSingleOcpiCdrsReport/${cdr_id}/${party_id}`)}
                title="View Single CDR's"
                style={{ height: "auto", width: "25%" }}
              >
                View Single CDR's
              </button>
            </div>
          );
        },
      },
    ],
    []
  );
  const headers = [
    { label: "ID", key: "charging_id" },
    { label: "First Name", key: "firstname" },
    { label: "Last Name", key: "lastname" },
    { label: "User Name", key: "userid" },
    { label: "EVSE ID", key: "evse_uid" },
    { label: "Location", key: "location_id" },
    { label: "Date", key: "date" },
    { label: "Start Time", key: "start_date_time" },
    { label: "End Time", key: "end_date_time" },
    { label: "Connector ID", key: "connector_id" },
    { label: "kWh", key: "kwh" },
    { label: "Cost Excluding Vat", key: "excl_vat" },
    { label: "Cost Including Vat", key: "incl_vat" },
    { label: "Status", key: "status" },
  ];
  const initialVisibleColumns = [
    "charging_id",
    "firstname",
    "lastname",
    "evse_uid",
    "station_name",
    "date",
    "start_date_time",
    "end_date_time",
    "userid",
    "connector_id",
    "kwh",
    "excl_vat",
    "incl_vat",
    "status",
    "session_id",
  ];

  return (
    <Container fluid>
      <PureBreadcrumbs />
      {/* Ev Report Cards Start here */}
      <Row className="mt-2">
        <Col className="mt-2" xs={12} md={3}>
          {/* <Card className="customercard">
                        <div style={{ textAlign: "left" }}>
                            <Card.Text style={{ fontSize: "14px" }}>Total Session</Card.Text>
                            <Card.Title>
                                <b>
                                    {props.list.total_session ? props.list.total_session : 0}
                            
                                </b>
                            </Card.Title>
                            <span
                                style={{
                                    float: "right",
                                    marginTop: "-60px",
                                    marginRight: "-8px",
                                }}
                            >
                                <div style={{ width: "15%" }}>
                                    <img
                                        src={
                                            process.env.PUBLIC_URL + "/images/reports/sessions.svg"
                                        }
                                        style={{ "width": "60px" }}
                                        alt=""
                                    />
                                </div>
                            </span>
                        </div>
                    </Card> */}
          <Card className="customercard card-custom total-rules cards">
            <div className="diagonal-line"></div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p
                style={{
                  backgroundColor: "blue",
                  padding: "3px",
                  borderRadius: "50%",
                  width: "8px",
                  height: "8px",
                  marginRight: "8px",
                }}
              ></p>
              <p>{t("Total sessions")}</p>
            </div>
            <Card.Title>
              <b>{props.list.total_session ? props.list.total_session : 0}</b>
            </Card.Title>
          </Card>
          <br />
        </Col>
        <Col className="mt-2" xs={12} md={3}>
          {/* <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>Total Energy</Card.Text>
              <Card.Title>
                <b>{props.list.total_energy ? props.list.total_energy : 0}</b>
              </Card.Title>
              <span
                style={{
                  float: "right",
                  marginTop: "-60px",
                  marginRight: "-8px",
                }}
              >
                <div style={{ width: "10%" }}>
                  <img src={process.env.PUBLIC_URL + "/images/reports/energy.svg"} alt="" style={{ width: "40px" }} />
                </div>
              </span>
            </div>
          </Card> */}
          <Card className="customercard card-custom total-active-rules cards">
            <div className="diagonal-line"></div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p
                style={{
                  backgroundColor: "orange",
                  padding: "3px",
                  borderRadius: "50%",
                  width: "8px",
                  height: "8px",
                  marginRight: "8px",
                }}
              ></p>
              <p>{t("Total Energy")}</p>
            </div>
            <Card.Title>
              <b>{props.list.total_energy ? props.list.total_energy : 0}</b>
            </Card.Title>
          </Card>
          <br />
        </Col>
        <Col className="mt-2" xs={12} md={3}>
          {/* <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>Total Revenue</Card.Text>
              <Card.Title>
                <b>{props.list.total_revenue ? props.list.total_revenue : 0}</b>
              </Card.Title>
              <span
                style={{
                  float: "right",
                  marginTop: "-65px",
                  marginRight: "-8px",
                }}
              >
                {
                  <div style={{ fontSize: "3.5rem", paddingRight: "11px", color: "#2ea8cc80", marginTop: "-7px" }}>
                    {currency}
                  </div>
                }
              </span>
            </div>
          </Card> */}
          <Card className="customercard card-custom total-inactive cards">
            <div className="diagonal-line"></div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p
                style={{
                  backgroundColor: "red",
                  padding: "3px",
                  borderRadius: "50%",
                  width: "8px",
                  height: "8px",
                  marginRight: "8px",
                }}
              ></p>
              <p>{t("Total revenue")}</p>
            </div>
            <Card.Title>
              <b>{props.list.total_revenue ? props.list.total_revenue : 0}</b>
            </Card.Title>
          </Card>
        </Col>
        <Col className="mt-2" xs={12} md={3}>
          {/* <Card className="customercard ">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>Total Sessions Completed</Card.Text>
              <Card.Title>
                <b>{props.list.total_completed ? props.list.total_completed : 0}</b>
              </Card.Title>
              <span
                style={{
                  float: "right",
                  marginTop: "-50px",
                  marginRight: "-8px",
                }}
              >
                <div style={{ width: "10%" }}>
                  <img
                    src={process.env.PUBLIC_URL + "/images/reports/sessionsstats.svg"}
                    alt=""
                    style={{ width: "70px" }}
                  />
                </div>
              </span>
            </div>
          </Card> */}
          <Card className="customercard card-custom total-draft cards">
            <div className="diagonal-line"></div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p
                style={{
                  backgroundColor: "grey",
                  padding: "3px",
                  borderRadius: "50%",
                  width: "8px",
                  height: "8px",
                  marginRight: "8px",
                }}
              ></p>
              <p>{t("Total sessions completed")}</p>
            </div>
            <Card.Title>
              <b>{props.list.total_completed ? props.list.total_completed : 0}</b>
            </Card.Title>
          </Card>
          <br />
        </Col>
      </Row>
      {/* Ev Report Card Ends here */}

      <Row className="mt-2">
        <Col>
          <Card className="customercard">
            <Card.Header>
              <Row className="align-items-center my-3">
                <Col md={2} sm={12} className="font-weight-bold text-left">
                  Transaction Report
                </Col>
                <Col md={8} sm={12}>
                  <div className="row justify-content-end">
                    <div style={{ paddingRight: "10px" }}>
                      <select className="custom-select" onChange={handleChange}>
                        <option value="All">{t("All")}</option>
                        {props.partyidlist &&
                          props.partyidlist.data?.map((item) => (
                            <option key={item.id} value={item.party_id}>
                              {item.partner_name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </Col>
                <Col md={2} sm={12} className="d-flex justify-content-end align-items-center">
                  <div className="mt-1 p-3">
                    {props.allList && props.allList.data ? (
                      <CSVLink
                        data={processedData}
                        headers={headers}
                        filename={`${org}report.csv`}
                        target="_blank"
                        style={{
                          textDecoration: "none",
                          color: "#007bff",
                        }}
                      >
                        <i className="fas fa-2x fa-file-csv" style={{ color: "#28a745" }} title="Download CSV"></i>
                      </CSVLink>
                    ) : (
                      <i className="fas fa-2x fa-file-csv" style={{ color: "#dc3545" }} title="CSV Unavailable"></i>
                    )}
                  </div>
                  <div className="p-3">
                    <TransactionReportPdf {...props} processedData={processedData} org={org} />
                  </div>
                </Col>
              </Row>
            </Card.Header>
            {props.loading ? ( // Show the spinner when loading
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "400px", // Adjust height as needed
                }}
              >
                <Spinner animation="border" role="status">
                  {/* <span className="visually-hidden"></span> */}
                </Spinner>
              </div>
            ) : data && data.length > 0 ? (
              <CustomOcpiPaginationTable
                data={data}
                allData={processedData}
                pageCount={pageCount}
                columns={columns}
                search={search}
                setSearch={setSearch}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                filteredData={filteredData}
                setFilteredData={setFilteredData}
                initialVisibleColumns={initialVisibleColumns}
                t={t}
              />
            ) : (
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <h3 className="loader">{t("No data found")}</h3>
              </div>
            )}
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.ocpi.isLoading,
    list: state.ocpi.fetchExternalTransactionList,
    allList: state.ocpi.fetchAllExternalTransactionList,
    partyidlist: state.ocpi.allpartyid,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTransactionList: (party_id, currentPage, pageSize) =>
      dispatch(fetchExternalTransactionListAction(party_id, currentPage, pageSize)),
    getAllTransactionListApi: (party_id, currentPage, pageSize) =>
      dispatch(fetchAllExternalTransactionDataAction(party_id, currentPage, pageSize)),
    allPartnerPartyId: () => dispatch(allRoamingPartnerPartnerID()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RoamingReport);
