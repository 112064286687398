import React from "react";
import { Card, Row, Col, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { allTariffAction } from "../../../../store/actions/tariffAction";
import {
  fetchOrganisationName,
  fetchRevenueShare,
  saveOrganisationAction,
} from "../../../../store/actions/organisationAction";
import PureBreadcrumbs from "../../breadcrums";
import "../organisation.css";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import Geocode from "react-geocode";
import { useTranslation } from "react-i18next";
import { fetchAllConnectAccountList } from "../../../../store/actions/stripeAction";
import { Bounce, ToastContainer, toast } from "react-toastify";
import { MdGpsFixed } from "react-icons/md";
import { HandleKeyPress } from "../../resuableComponent/handleKeyPress";
import { useLoadScript } from "@react-google-maps/api";
const libraries = ["places"];

const CreateOrganisationTariff = (props) => {
  const { t } = useTranslation();
  const [address, setAddress] = useState("");
  const [isToastVisible, setIsToastVisible] = useState(false);
  const [partnerRevenueSharing, setPartnerRevenueSharing] = useState(0);
  const [regionalRevenueSharing, setRegionalRevenueSharing] = useState(0);
  // const [apiLoaded, setApiLoaded] = useState(false);
  const [error, setError] = useState(null);

  // useEffect(() => {
  //     // Define the initMap function that the Google Maps script will call
  //     window.initMap = () => {
  //         setApiLoaded(true);
  //     };

  //     // Check if the script is already loaded
  //     if (window.google && window.google.maps) {
  //         setApiLoaded(true);
  //     }
  // }, []);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries, // Reference the static `libraries` array
  });

  const fleetSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      .matches(
        /^[A-Z][a-zA-Z\s0-9]*$/,
        "Partner name must start with a capital letter and cannot contain special characters"
      ),
    email: Yup.string().required("Email is required").email("Email is invalid"),
    address: Yup.string().required("Address is required"),
    isfleet: Yup.string().required("Fleet selection is required"),
    per_unit_price: Yup.number()
      .required("Electricity Per unit price is required")
      .typeError("Electricity Per unit can only be a number"),
    city: Yup.string().required("City  is required"),
    state: Yup.string().required("State/Province  is required"),
    country: Yup.string().required("Country is required"),
    created_by: Yup.string(),
    status: Yup.string().required("Status is required"),
    client_revenue_share: Yup.number()
      .required("Client revenue share is required")
      .typeError("Client revenue share is required"),
    regional_revenue_share: Yup.number()
      .required("Regional revenue share is required")
      .typeError("Regional revenue share is required"),
    self_revenue_share: Yup.number().required("self_revenue_share is required"),
    Zipcode: Yup.string().required("Zip code is a required"),
    phone: Yup.number().required("Phone number is a required").typeError("Phone number is a required field"),
    stripe_account: Yup.string(),
  });
  const {
    register,
    getValues,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(fleetSchema) });

  useEffect(() => {
    props.fetchRevenueShareData();
    props.all();
    props.fetchAllConnectAccount();
    reset();
  }, []);

  const data = props.revenue_share && props.revenue_share.data;
  // console.log("revenue share", data)

  const handleLocationBlur = () => {
    const enteredName = getValues("name");
    // console.log("enteredName",enteredName);
    if (enteredName !== "") {
      props.allOrgName(enteredName);
    }
  };

  // Geolocation placeAutoComplete logic
  const handleSelect = async (address) => {
    try {
      const results = await geocodeByAddress(address);
      if (!results || results.length === 0) {
        throw new Error("No results found");
      }

      const latLng = await getLatLng(results[0]);
      const { lat, lng } = latLng;

      const res = await latiLongi(lat, lng);
      if (!res || !res.results || res.results.length === 0) {
        throw new Error("No response from latiLongi");
      }

      setError("");
      setAddress(address);
      setValue("address", address);
      setValue("latitude", lat);
      setValue("longitude", lng);

      let city, state, country, postCode, countryCode;
      res.results[0].address_components.forEach((component) => {
        component.types.forEach((type) => {
          switch (type) {
            case "locality":
              city = component.long_name;
              setValue("city", city);
              break;
            case "administrative_area_level_1":
              state = component.long_name;
              setValue("state", state);
              break;
            case "country":
              country = component.long_name;
              setValue("country", country);
              break;
            case "postal_code":
              postCode = component.long_name;
              console.log("postal code", postCode);
              setValue("Zipcode", postCode);
              break;
            default:
              break;
          }
        });
      });
    } catch (error) {
      console.error("Error:", error);
      setError("Failed to retrieve location details. Please try again.");
    }
  };

  // console.log("data",data)
  const onSubmit = async (data) => {
    props.saveorg(data);
    // console.log("onSubmit data", data);
  };

  useEffect(() => {
    if (props.org_save.statuscode === 201) {
      setIsToastVisible(true);
      toast.success("New Partner has been added", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        onClose: () => {
          setIsToastVisible(false);
          window.location.reload();
        },
      });
    } else if (props.org_save.statuscode === 405) {
      setIsToastVisible(true);
      toast.error(`❌ ${props.org_save.error}`, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        onClose: () => {
          setIsToastVisible(false);
          window.location.reload();
        },
      });
    }
  }, [props.org_save]);

  const handlePartnerRevenueSharingChange = (e) => {
    const selectedValue = Number(e.target.value);
    // console.log("partner value: ", selectedValue)
    setPartnerRevenueSharing(selectedValue);
  };

  const handleRegionalRevenueSharingChange = (e) => {
    const selectedValue = Number(e.target.value);
    // console.log("regional head value: ", selectedValue)
    setRegionalRevenueSharing(selectedValue);
  };

  useEffect(() => {
    const calculatedResult = 100 - (partnerRevenueSharing + regionalRevenueSharing);
    setValue("self_revenue_share", calculatedResult);
  }, [partnerRevenueSharing, regionalRevenueSharing]);

  const HandleKeyPartnerPress = (e) => {
    const value = e.target.value;
    const newValue = value + e.key;

    if (e.key === "." && value === "") {
      e.preventDefault();
    }

    if (e.key === "-" || e.key === "e") {
      e.preventDefault();
    }

    // Prevent entering a value greater than 100
    if (parseFloat(newValue) > 100) {
      e.preventDefault();
    }
  };

  // console.log("partner sharing", partnerRevenueSharing);
  // console.log("address", getValues('address'));

  return (
    <Container fluid className="pb-4">
      {isToastVisible && <div className="overlay" />}
      <ToastContainer
        position="top-center"
        autoClose={20}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{ top: "10%", width: "auto", zIndex: "9999" }}
      />
      <PureBreadcrumbs />
      <br />
      <Row>
        <Col xs={12} md={1}></Col>
        <Col xs={12} md={10}>
          <Card className="tariffcard">
            <Card.Text style={{ fontSize: "14px", padding: "10px", paddingLeft: "15px" }}>
              <b>{t("Add New Organisation")}</b>
            </Card.Text>
            <Card.Body>
              <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                <div className="form-floating py-1">
                  <label>
                    {t("Organisation Name")} <span className="mandatory-field">*</span>
                  </label>
                  <input
                    type="text"
                    autoFocus
                    className={`form-control gray-border ${errors.name ? "is-invalid" : ""}`}
                    name="name"
                    id="floatingInputGridname"
                    placeholder={t("Organisation Name")}
                    {...register("name")}
                    onBlur={handleLocationBlur}
                  />
                  <div className="invalid-feedback">{errors.name?.message}</div>
                  {props.fetchOrgName.statuscode === 200 ? (
                    <p className="org-name-warning">{t("Organisation name is already exists")}</p>
                  ) : (
                    ""
                  )}
                </div>

                <div className="form-floating py-1">
                  <label>
                    {t("Phone No.")} <span className="mandatory-field">*</span>
                  </label>
                  <input
                    type="number"
                    className={`form-control gray-border ${errors.phone ? "is-invalid" : ""}`}
                    name="phone"
                    id="floatingInputGridnumber"
                    placeholder={t("Phone No.")}
                    {...register("phone")}
                  />
                  <div className="invalid-feedback">{errors.phone?.message}</div>
                </div>

                <div className="form-floating py-1">
                  <label>
                    {t("Email")} <span className="mandatory-field">*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control gray-border ${errors.email ? "is-invalid" : ""}`}
                    name="email"
                    id="floatingInputGridemail"
                    placeholder={t("Email")}
                    {...register("email")}
                  />
                  <div className="invalid-feedback">{errors.email?.message}</div>
                </div>

                {/*Address */}
                <div className="row g-2">
                  <div className="col-md p-2">
                    <div className="form-floating">
                      <label>{t("Enter Address")}</label>
                      {error && <div className="alert alert-danger">{error}</div>}
                      {isLoaded ? (
                        <PlacesAutocomplete
                          value={address}
                          onChange={(value) => (setValue("address", value), setAddress(value))}
                          onSelect={handleSelect}
                        >
                          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div>
                              <input
                                {...getInputProps({
                                  placeholder: "Search Places ...",
                                  className: `location-search-input gray-border ${errors.address ? "is-invalid" : ""}`,
                                  style: { fontSize: 14, borderRadius: 2, height: 37 },
                                })}
                                name="address"
                              />
                              <div className="invalid-feedback">{errors.address?.message}</div>
                              <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}

                                {suggestions.map((suggestion, index) => {
                                  const className = suggestion.active ? "suggestion-item--active" : "suggestion-item";
                                  const style = suggestion.active
                                    ? { backgroundColor: "#fafafa", cursor: "pointer" }
                                    : { backgroundColor: "#ffffff", cursor: "pointer" };
                                  return (
                                    <div
                                      key={index}
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                      })}
                                    >
                                      <span>{suggestion.description}</span>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                      ) : (
                        <div>Loading Google Maps...</div>
                      )}
                    </div>
                  </div>
                </div>
                {/*Address */}

                <div className="row g-2 py-3">
                  <div className="col-md py-1">
                    <div className="form-floating">
                      <label>
                        {t("Is Fleet ?")} <span className="mandatory-field">*</span>
                      </label>
                      <select
                        className={`form-select form-control ${errors.isfleet ? "is-invalid" : ""}`}
                        name="isfleet"
                        id="floatingInputGridIsFleet"
                        {...register("isfleet")}
                      >
                        <option value={""}> {t("Is Fleet ?")}</option>
                        <option value={"Yes"}> Yes </option>
                        <option value={"No"}> No </option>
                      </select>
                      <div className="invalid-feedback">{errors.isfleet?.message}</div>
                    </div>
                  </div>

                  <div className="col-md py-1">
                    <div className="form-floating">
                      <label>
                        {t("Per Unit Price")} <span className="mandatory-field">*</span>
                      </label>
                      <input
                        type="text"
                        className={`form-control gray-border ${errors.per_unit_price ? "is-invalid" : ""}`}
                        name="per_unit_price"
                        id="floatingInputUnitPrice"
                        placeholder={t("Per Unit Price")}
                        {...register("per_unit_price")}
                      />
                      <div className="invalid-feedback">{errors.per_unit_price?.message}</div>
                    </div>
                  </div>
                  <div className="col-md py-1">
                    <div className="form-floating">
                      <label>{t("Select Stripe Account")} </label>
                      <select
                        className={`form-select  form-control ${errors.stripe_account ? "is-invalid" : ""}`}
                        name="stripe_account"
                        id="floatingSelectGridstripe_account"
                        {...register("stripe_account")}
                      >
                        <option value="">{t("Select Stripe Account")} </option>
                        {props.getAllConnectAccount &&
                          props.getAllConnectAccount.data &&
                          props.getAllConnectAccount.data.map((v, k) => (
                            <option key={v.id} value={v.id}>
                              {t(v.business_profile.name)}
                            </option>
                          ))}
                      </select>
                      <div className="invalid-feedback">{errors.stripe_account?.message}</div>
                    </div>
                  </div>
                </div>

                <div className="row g-2">
                  <div className="col-md py-1">
                    <div className="form-floating">
                      <label>
                        {t("Country")} <span className="mandatory-field">*</span>
                      </label>
                      <input
                        type="text"
                        className={`form-control gray-border ${errors.country ? "is-invalid" : ""}`}
                        name="country"
                        id="floatingInputzip"
                        placeholder={t("Country")}
                        {...register("country")}
                      />
                      <div className="invalid-feedback">{errors.country?.message}</div>
                    </div>
                  </div>

                  <div className="col-md py-1">
                    <div className="form-floating">
                      <div className="form-floating">
                        <label>
                          {t("State")} <span className="mandatory-field">*</span>
                        </label>
                        <input
                          type="text"
                          className={`form-control gray-border  ${errors.state ? "is-invalid" : ""}`}
                          name="state"
                          id="floatingInputzip"
                          placeholder={t("State")}
                          {...register("state")}
                        />
                        <div className="invalid-feedback">{errors.state?.message}</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md py-1">
                    <div className="form-floating">
                      <label>
                        {t("City")} <span className="mandatory-field">*</span>
                      </label>
                      <input
                        type="text"
                        className={`form-control gray-border  ${errors.city ? "is-invalid" : ""}`}
                        name="city"
                        id="floatingInputzip"
                        placeholder={t("City")}
                        {...register("city")}
                      />
                      {/* <select className={`form-select ${errors.city ? 'is-invalid' : ''}`} id="floatingSelectGridCity" name="city" aria-label="Floating label select example" {...register("city")}>
                                                            <option value={""} selected>Select City</option>
                                                            {props.cities && props.cities.city.map((v, k) =>
                                                                <option value={v.city_name}>{v.city_name}</option>
                                                            )}
                                                        </select> */}
                      <div className="invalid-feedback">{errors.city?.message}</div>
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="form-floating">
                      <label>
                        {t("Zipcode")} <span className="mandatory-field">*</span>
                      </label>
                      <input
                        type="text"
                        className={`form-control  gray-border ${errors.Zipcode ? "is-invalid" : ""}`}
                        name="Zipcode"
                        id="floatingInputGridnumber"
                        placeholder={t("Zipcode")}
                        {...register("Zipcode")}
                      />
                      <div className="invalid-feedback">{errors.Zipcode?.message}</div>
                    </div>
                  </div>
                </div>

                <Row className="mt-3">
                  <Col lg={3} md={12} className="col-md py-1 py-1">
                    <div className="form-floating">
                      <label>
                        {t("Partner Revenue Sharing")}(In %) <span className="mandatory-field">*</span>
                      </label>
                      <input
                        type="number"
                        className={`form-control  gray-border ${errors.client_revenue_share ? "is-invalid" : ""}`}
                        name="client_revenue_share"
                        id="floatingSelectGridClientRevenueSharing"
                        placeholder="Enter partner revenue share"
                        {...register("client_revenue_share")}
                        onKeyPress={HandleKeyPartnerPress}
                        onChange={(e) => handlePartnerRevenueSharingChange(e)}
                      />
                      <div className="invalid-feedback">{errors.client_revenue_share?.message}</div>
                    </div>
                  </Col>
                  <Col lg={3} md={12} className="col-md py-1 py-1">
                    <div className="form-floating">
                      <label>
                        {t("Regional Revenue Sharing")}(In %) <span className="mandatory-field">*</span>
                      </label>
                      <input
                        type="number"
                        className={`form-control  gray-border ${errors.regional_revenue_share ? "is-invalid" : ""}`}
                        name="regional_revenue_share"
                        id="floatingSelectGridRegionalRevenueSharing"
                        placeholder="Enter Regional revenue share"
                        {...register("regional_revenue_share")}
                        onChange={(e) => handleRegionalRevenueSharingChange(e)}
                        onKeyPress={HandleKeyPartnerPress}
                      />
                      <div className="invalid-feedback">{errors.regional_revenue_share?.message}</div>
                    </div>
                  </Col>
                  <br />
                  <Col lg={3} md={12} className="col-md py-1 py-1">
                    <label>
                      {t("Self Revenue Sharing")}(In %) <span className="mandatory-field">*</span>
                    </label>
                    <div className="form-floating mb-3 d-flex">
                      <input
                        type="number"
                        className={`form-control gray-border ${errors.self_revenue_share ? "is-invalid" : ""}`}
                        name="self_revenue_share"
                        id="floatingInputSelfRevenueShare"
                        placeholder={t("Self Revenue Sharing")}
                        {...register("self_revenue_share")}
                        disabled
                      />
                    </div>
                  </Col>
                  <Col lg={3} md={12} className="col-md py-1 py-1">
                    <div className="form-floating ">
                      <label>
                        {t("Status")} <span className="mandatory-field">*</span>
                      </label>
                      <select
                        className={`form-select form-control  ${errors.status ? "is-invalid" : ""}`}
                        name="status"
                        id="floatingSelectGridS"
                        {...register("status")}
                      >
                        <option value=""> {t("Status")}</option>
                        <option value="draft">Draft</option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </select>
                      {/* <label for="floatingSelectGridS">Status</label> */}
                      <div className="invalid-feedback">{errors.status?.message}</div>
                    </div>
                  </Col>
                </Row>

                <Row className="mt-4 d-md-flex justify-content-between">
                  <Col lg={5} md={5} sm={5} xs={12} className="mt-2">
                    <button
                      className="lgn-btn btn"
                      type="button"
                      onClick={() => {
                        reset({
                          name: "",
                          email: "",
                          city: "",
                          isfleet: "",
                          state: "",
                          country: "",
                          Zipcode: "",
                          phone: "",
                          address: setAddress(""),
                          client_revenue_share: "",
                          self_revenue_share: "",
                          regional_revenue_share: "",
                        });
                      }}
                    >
                      {" "}
                      {t("Reset")}{" "}
                    </button>
                  </Col>
                  <Col lg={5} md={7} sm={7} xs={12} className="d-md-flex justify-content-end mt-2">
                    {props.loading ? (
                      <button className="btn lgn-btn" type="button" disabled>
                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        &nbsp;
                        {t("Saving...")}
                      </button>
                    ) : (
                      <button
                        className="lgn-btn btn"
                        type="submit"
                        disabled={props.fetchOrgName.statuscode === 200 ? true : false}
                        onClick={handleSubmit(onSubmit)}
                      >
                        {t("ADD ORGANISATION")}
                      </button>
                    )}
                  </Col>
                </Row>
              </form>
            </Card.Body>
            <br />
            <br />
          </Card>
        </Col>
        <Col xs={12} md={1}></Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.operator.isLoading,
    org_save: state.operator.org_save,
    revenue_share: state.operator.revenueShare,
    fetchOrgName: state.operator.allOrganisation,
    getAllConnectAccount: state.stripe.getAllConnectAcount,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    all: () => dispatch(allTariffAction()),
    fetchRevenueShareData: () => dispatch(fetchRevenueShare()),
    saveorg: (org) => dispatch(saveOrganisationAction(org)),
    allOrgName: (name) => dispatch(fetchOrganisationName(name)),
    fetchAllConnectAccount: () => dispatch(fetchAllConnectAccountList()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateOrganisationTariff);

const latiLongi = (a, b) => {
  return Geocode.fromLatLng(a, b).then((res) => {
    // console.log("Gaadin", res);
    return res;
  });
};
