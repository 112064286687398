import { Container, Row, Col, Card } from "react-bootstrap";
import PureBreadcrumbs from "../../breadcrums";
import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { activatePromotion, allOrgpromotionAction, deactivatePromotion } from "../../../../store/actions/organisationAction";
import "../../reacttable/reactTable.css";
import Table from "../../reacttable/table";
import moment from "moment";
import { FaEdit } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useTranslation } from "react-i18next";

function ViewPromotion(props) {
  const {t} = useTranslation();
  useEffect(() => {
    props.all();
  }, []);

  const data = props.allpromotion && props.allpromotion.promotion;
  // console.log(data);

  const activateStation = (promotion_code) => {
    // console.log(promotion_code);
    props.activatePromotion(promotion_code);

  };

  if (props.activePro.statuscode === 200) {
    window.location.reload();
  }

  const deactivateStation = (promotion_code) => {
    // console.log(promotion_code);
    props.deactivatePromotion(promotion_code);

  };

  if (props.deactivatePro.statuscode === 200) {
    window.location.reload();
  }

  const columns = useMemo(
    () => [
      {
        Header: "Promotion Code",
        accessor: "promotion_code",
        id: "promotion_code1"
      },
      {
        Header: "Promotion Name",
        accessor: "promotion_name",
      },
      {
        Header: "Start Date",
        id: "startdate",
        accessor: (d) => {
          return moment(d.startdate).local().format("DD-MM-YYYY");
        },
      },
      {
        Header: "End Date",
        id: "enddate",
        accessor: (d) => {
          return moment(d.enddate).local().format("DD-MM-YYYY");
        },
      },
      {
        Header: "Discount (in %)",
        accessor: "discount",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: (status) => {
          // console.log(publish.value)
          return (
            <div>
              <span>
                {status.value == true ? (
                  <span className="text-success">Active</span>
                ) : (
                  <span className="text-danger">Inactive</span>
                )}
              </span>
            </div>
          );
        },
      }, {
        Header: "Edit Promotion",
        accessor: "id",
        Cell: (id) => {
          return (
            <div>
              &nbsp;&nbsp;
              <span>
                <a
                  href={`/dashboard/updatepromotion/${id.value}`}
                  rel="tooltip"
                  title="Edit"
                >
                  <FaEdit />
                </a>
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;
            </div>
          );
        },
      },
      {
        Header: "Activate/Deactivate",
        accessor: "promotion_code",
        Cell: (promotion_code) => {
          return (
            <div>
              &nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;
              <span style={{ cursor: "pointer" }}>
                <TiTick
                  onClick={() => activateStation(promotion_code.value)}
                  title="Activate"
                />
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;
              <span style={{ cursor: "pointer" }}>
                <AiOutlineCloseCircle
                  onClick={() => deactivateStation(promotion_code.value)}
                  title="De-Activate"
                />
              </span>
            </div>
          );
        },
      },

    ],
    []
  );

  return (
    <Container fluid>
      <PureBreadcrumbs />

      <Row className="mt-4">
        <Col>
          <Card className="customercard">
            <div className="table-header">{t("Promotion List")}</div>
            <div>
              {props.loading ? <h3 style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "250px",
              }}>Loading data...</h3> :
                data && data.length > 0 ? (
                  <div>
                    <Table data={data} columns={columns}></Table>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <h3 className="loader">{t("No data found")}</h3>
                  </div>
                )}
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.operator.isLoading,
    allpromotion: state.operator.allpromotion,
    activePro: state.operator.activePromotion,
    deactivatePro: state.operator.deactivePromotion,

  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    all: () => dispatch(allOrgpromotionAction()),
    activatePromotion: (promotion_code) => dispatch(activatePromotion(promotion_code)),
    deactivatePromotion: (promotion_code) => dispatch(deactivatePromotion(promotion_code)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewPromotion);
