import React, { useState } from 'react'
import { useEffect } from "react";
import { Card, Row, Col, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import PureBreadcrumbs from "../../../breadcrums";
import "../../ocpi.css"
import { useTranslation } from 'react-i18next';
import { fetchSingleOcpiStationAction } from '../../../../../store/actions/ocpiAction';

const ViewSingleOcpiStation = (props) => {
    const { t } = useTranslation();
    const { name } = useParams();
    const [activeTab, setActiveTab] = useState('Self');

    useEffect(() => {
        if (activeTab === 'Self') {
            props.fetch(name) // Fetch Self data
        } else if (activeTab === 'Partner') {
            // props.fetch(name) // Fetch partner data
        }
    }, [activeTab, name]);

    const selfData = props.singleConsumedStationRes && props.singleConsumedStationRes.data &&
        props.singleConsumedStationRes.data?.[0] ? props.singleConsumedStationRes.data?.[0] : {};

    const partnerData = props.singleConsumedStationRes && props.singleConsumedStationRes.data &&
        props.singleConsumedStationRes.data?.[0] ? props.singleConsumedStationRes.data?.[0] : {};
    const data = activeTab === 'Self' ? selfData : partnerData;

    const { latitude = null, longitude = null } = data?.coordinates || {};
    // console.log("data", data);

    const array = data && data.evses;
    // console.log("array", array);

    const handleUnlockConnector = (uid, connectorId) => {
        console.log(`Unlocking connector with UID: ${uid} and Connector ID: ${connectorId} with location id ${data.id}`);
        // Add your logic to unlock the connector here, like calling an API or updating the state
    };

    const renderForm = (isPartner) => (
        <Row>
            <Col xs={12} md={12}>
                <form onSubmit={e => e.preventDefault()}>
                    <Card>
                        <div>
                            <h4 className='text-center p-3'>{t("OCPI CPO Station/Location Details")}</h4>
                        </div>
                        <Card.Body>
                            <div className="row g-2">
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputGridname'>{t("Location Name")}</label>
                                        <input type="text" className={`form-control gray-border `} name="location" id="floatingInputGridname" placeholder={t("Location Name")} value={data?.name || 'Not Available'} disabled />
                                    </div>
                                </div>
                            </div>
                            <div className="row g-2">
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputGridAddress'>{t("Address")}</label>
                                        <input type="text" className={`form-control gray-border `} name="location" id="floatingInputGridAddress" placeholder={t("Address")} value={data?.address || 'Not Available'} disabled />
                                    </div>
                                </div>
                            </div>

                            <div className="row g-2">
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputCountry'>{t("Country")}</label>
                                        <input type="text" className={`form-control gray-border `} name="country" id="floatingInputCountry" placeholder={t("Country")} value={data?.country || 'Not Available'} disabled />
                                    </div>
                                </div>

                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <div className="form-floating">
                                            <label htmlFor='floatingInputState'>{t("State")}</label>
                                            <input type="text" className={`form-control gray-border `} name="state" id="floatingInputState" placeholder="Enter State/Province" value={data?.state || 'Not Available'} disabled />
                                        </div>

                                    </div>
                                </div>
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputcity'>{t("City")}</label>
                                        <input type="text" className={`form-control gray-border `} name="city" id="floatingInputcity" placeholder="Enter City" value={data?.city || 'Not Available'} disabled />

                                    </div>
                                </div>
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputzip'>{t("Zipcode")}</label>
                                        <input type="text" className={`form-control gray-border `} name="postal_code" id="floatingInputzip" value={data?.postal_code || 'Not Available'} placeholder="Enter location zip code" disabled />

                                    </div>
                                </div>
                            </div>
                            <div className="row g-2">
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputGridlat'>{t("Latitude")}</label>
                                        <input type="number" className={`form-control gray-border `} name="latitude" id="floatingInputGridlat" placeholder="Enter latitude" value={latitude || 'Not Available'} disabled />

                                    </div>
                                </div>
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputlong'>{t("Longitude")}</label>
                                        <input type="number" className={`form-control gray-border`} name="longitude" id="floatingInputlong" placeholder="Enter longitude" value={longitude || 'Not Available'} disabled />
                                    </div>
                                </div>
                            </div>

                        </Card.Body>
                    </Card>

                    {/* ------------Charger rendering------------- */}

                    <div>
                        {/* View chargers */}
                        <Row className="mt-2">
                            <Col>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item active" aria-current="page">{t("View Chargers")}</li>
                                    </ol>
                                </nav>
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 col-lg-12 mx-auto">
                                        <div className="table-responsive">
                                            <table className="table table-sm table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">{t("Charge Box ID")}</th>
                                                        <th scope="col">{t("UID")}</th>
                                                        <th scope="col">{t("Status")}</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {array && array.map((charger, index) =>
                                                        <tr key={index}>
                                                            <td> {charger.evse_id}</td>
                                                            <td> {charger.uid}</td>
                                                            <td> {charger.status}</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        {/* Connector List */}
                        <Row>
                            <Col>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item active" aria-current="page">{t("Connector List")}</li>
                                    </ol>
                                </nav>
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 col-lg-12 mx-auto">
                                        <div className="table-responsive">
                                            <table className="table table-sm table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">{t("Charge Box ID")}</th>
                                                        <th scope="col">{t("Connector ID ")}</th>
                                                        <th scope="col">{t("Connector Type")}</th>
                                                        <th scope="col">{t("Max Ampere")}</th>
                                                        <th scope="col">{t("Max Voltage")}</th>
                                                        <th scope="col">{t("Power Type")}</th>
                                                        {!isPartner && (<th scope="col">{t("Action")}</th>)}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {array && array.map((item) => (
                                                        item && item.connectors && item.connectors.map((connector) => (
                                                            <tr key={item.evse_id + connector.id}>
                                                                <td>{item.evse_id}</td>
                                                                <td>{connector.id}</td>
                                                                <td>{connector.standard}</td>
                                                                <td>{connector.max_amperage}</td>
                                                                <td>{connector.max_voltage}</td>
                                                                <td>{connector.power_type}</td>
                                                                {!isPartner && (<td>
                                                                    <button
                                                                        onClick={() => handleUnlockConnector(item.uid, connector.id)}
                                                                        className="btn lgn-btn"
                                                                    >
                                                                        Unlock Connector
                                                                    </button>
                                                                </td>)}
                                                            </tr>
                                                        ))
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                            </Col>
                        </Row>
                        {/* Tariff List */}
                        <Row>
                            <Col>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item active" aria-current="page">{t("Tariff List")}</li>
                                    </ol>
                                </nav>
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 col-lg-12 mx-auto">
                                        <div className="table-responsive">
                                            <table className="table table-sm table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">{t("Tariff Name")} </th>
                                                        <th scope="col">{t("Tariff Price")}</th>
                                                        <th scope="col">{t("Tariff Unit")}</th>
                                                        <th scope="col">{t("Tariff Currency")}</th>
                                                        <th scope="col">{t("Tariff Status")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {array && array.map((item) => (
                                                        item && item.connectors && item.connectors.map((connector, index) => (
                                                            <tr key={item.evse_id + connector.id + index}>
                                                                <td>
                                                                    {connector.tariff && connector.tariff.map((tariffItem, index) => (
                                                                        <div key={index}>
                                                                            <p>{tariffItem.name || "Not available"}</p>
                                                                        </div>
                                                                    ))}
                                                                </td>
                                                                <td>
                                                                    {connector.tariff && connector.tariff.map((tariffItem, index) => (
                                                                        <div key={index}>
                                                                            <p>{tariffItem.amount || "Not available"}</p>
                                                                        </div>
                                                                    ))}
                                                                </td>
                                                                <td>
                                                                    {connector.tariff && connector.tariff.map((tariffItem, index) => (
                                                                        <div key={index}>
                                                                            <p>{tariffItem.unit || "Not available"}</p>
                                                                        </div>
                                                                    ))}
                                                                </td>
                                                                <td>
                                                                    {connector.tariff && connector.tariff.map((tariffItem, index) => (
                                                                        <div key={index}>
                                                                            <p>{tariffItem.currency || "Not available"}</p>
                                                                        </div>
                                                                    ))}
                                                                </td>
                                                                <td>
                                                                    {connector.tariff && connector.tariff.map((tariffItem, index) => (
                                                                        <div key={index}>
                                                                            <p>{tariffItem.status || "Not available"}</p>
                                                                        </div>
                                                                    ))}
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </form>
            </Col>
        </Row>
    );


    return (
        <Container fluid>
            <PureBreadcrumbs />
            <br />

            <Row className="my-3">
                <Col xs={12}>
                    <div style={{
                        border: "1px solid #d9d9d9",
                        borderRadius: "50px",
                        padding: "0px",
                        backgroundColor: "#f3f3f3", // Light background for the switch container
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "20%",
                        margin: "0 auto",
                    }}>
                        <button
                            onClick={() => setActiveTab('Self')}
                            className={`tab-button ${activeTab === 'Self' ? 'active' : ''}`}
                            style={{
                                backgroundColor: activeTab === 'Self' ? '#000000' : '#f3f3f3',
                                color: activeTab === 'Self' ? '#f3f3f3' : '#000000',
                                border: "none",
                                padding: '10px 20px',
                                borderRadius: "50px",
                                fontWeight: "bold",
                                fontSize: "16px",
                                cursor: "pointer",
                                transition: "all 0.3s ease",
                                width: "55%",
                            }}
                        >
                            Self
                        </button>
                        <button
                            onClick={() => setActiveTab('Partner')}
                            className={`tab-button ${activeTab === 'Partner' ? 'active' : ''}`}
                            style={{
                                backgroundColor: activeTab === 'Partner' ? '#000000' : '#f3f3f3',
                                color: activeTab === 'Partner' ? '#f3f3f3' : '#000000',
                                border: "none",
                                padding: '10px 20px',
                                borderRadius: "50px",
                                fontWeight: "bold",
                                fontSize: "16px",
                                cursor: "pointer",
                                transition: "all 0.3s ease",
                                width: "55%",
                            }}
                        >
                            Partner
                        </button>
                    </div>
                </Col>
            </Row>

            {activeTab === 'Self' && renderForm(false)}
            {activeTab === 'Partner' && renderForm(true)}

        </Container>
    )
}

const mapStateToProps = (state) => {
    return {
        loading: state.ocpi.isLoading,
        singleConsumedStationRes: state.ocpi.fetchSingleOcpiStation,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetch: (name) => dispatch(fetchSingleOcpiStationAction(name)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewSingleOcpiStation)