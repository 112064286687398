import React, { useEffect, useState } from 'react'
import "../evse.css"
import { useTranslation } from 'react-i18next';
import { Bounce, ToastContainer, toast } from 'react-toastify';


const TriggerChargerMessageComponent = (props) => {
    const { t } = useTranslation();
    const [isToastVisible, setIsToastVisible] = useState(false);

    const evsedata = props.fetchstation && props.fetchstation
    const conn = evsedata && evsedata.evses
    const zxc = conn && conn.map(en => en.connectors)
    const data = Object.values(zxc).flatMap(arr => arr)
    // console.log(props.triggerMessagecmd.statuscode)

    const handletriggerMessage = (evse_id, connectorId, RequestedMessage) => {
        props.triggerMessage(evse_id, connectorId, RequestedMessage);

    }


    useEffect(() => {
        if (props.triggerMessagecmd.statuscode === 200) {
            setIsToastVisible(true);
            toast.success('Success!!!', {
                position: "top-center",
                autoClose: 300,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    window.location.reload();
                },
            });
        }
    }, [props.triggerMessagecmd]);

    return (
        <div className='container'>
            {isToastVisible && <div className="overlay" />}
            <ToastContainer
                position="top-center"
                autoClose={20}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ top: "10%", width: "auto", zIndex: "9999" }}
            />
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 mx-auto">
                    <div className="table-responsive">
                        <table className="table table-sm table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">{t("Charge Box ID")}</th>
                                    <th scope="col">{t("Connector ID ")}</th>
                                    <th scope="col">{t("Connector Type")}</th>
                                    <th scope="col">{t("Actions")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data && data.map((subItems) => {
                                    return (
                                        <tr key={subItems.id}>
                                            <td>{subItems.evse_id} </td>
                                            <td> {subItems.id}</td>
                                            <td> {subItems.standard}</td>
                                            <td>
                                                <button className="btn commandButtons m-1" type="button" onClick={() => { handletriggerMessage(subItems.evse_id, subItems.id, "BootNotification"); }}>{t("Boot Notification")}</button>&nbsp;&nbsp;&nbsp;
                                                <button className="btn commandButtons m-1" type="button" onClick={() => { handletriggerMessage(subItems.evse_id, subItems.id, "DiagnosticsStatusNotification"); }}>{t("Diagnostics Status Notification")}</button>&nbsp;&nbsp;&nbsp;
                                                <button className="btn commandButtons m-1" type="button" onClick={() => { handletriggerMessage(subItems.evse_id, subItems.id, "FirmwareStatusNotification"); }}>{t("Firmware Status Notification")}</button>&nbsp;&nbsp;&nbsp;
                                                <button className="btn commandButtons m-1" type="button" onClick={() => { handletriggerMessage(subItems.evse_id, subItems.id, "StatusNotification"); }}>{t("Status Notification")}</button>&nbsp;&nbsp;&nbsp;
                                                <button className="btn commandButtons m-1" type="button" onClick={() => { handletriggerMessage(subItems.evse_id, subItems.id, "MeterValues"); }}>{t("Meter Values")}</button>&nbsp;&nbsp;&nbsp;
                                                <button className="btn commandButtons m-1" type="button" onClick={() => { handletriggerMessage(subItems.evse_id, subItems.id, "HeartBeat"); }}>{t("HeartBeat")}</button>&nbsp;&nbsp;&nbsp;
                                            </td>
                                        </tr>
                                    )
                                })}


                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default TriggerChargerMessageComponent;