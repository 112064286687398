import React from 'react';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useTranslation } from 'react-i18next';

// Create Document Component
const InternalTransactionReportPdf = (props) => {
    const { t } = useTranslation();
    // const gstNumber = process.env.REACT_APP_CLIENT_GST_NO;
    // console.log("props: ", props)
    const generatePDF = () => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape
        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(12);
        const org_name = props.org === "All" ? "All" : props.org
        const Organisation = "Organisation: " + org_name;

        const headers = [[
            "ID",
            "EVSE ID",
            "Location",
            "Date",
            "Start Time",
            "End Time",
            "Connector ID",
            "kWh",
            "Cost Excluding Vat",
            "Cost Including Vat",
            "Status"
        ]];

        const data = props.processedData?.map(elt =>
            [
                elt.id,
                elt.evse_uid,
                elt.location_id,
                elt.date,
                elt.start_date_time,
                elt.end_date_time,
                elt.connector_id,
                elt.kwh,
                elt.total_cost.excl_vat,
                elt.total_cost.incl_vat,
                elt.status
            ]);

        let content = {
            startY: 60,
            head: headers,
            body: data
        };

        doc.text(Organisation, marginLeft, 40);
        doc.autoTable(content);
        doc.save(props.org + ` OCPI Transaction Report.pdf`)
    }

    const handleButtonClick = async () => {
        // Generate the PDF
        generatePDF();
        // props.onClick();
    };

    return (
        <div>
            <button onClick={handleButtonClick} className='pdfbutton' disabled={!props.processedData?.length > 0} type="button">{t("PDF(Partner)")}</button>
        </div>
    )
}

export default InternalTransactionReportPdf;