import { Container, Card } from "react-bootstrap";
import React, { useEffect, useMemo } from 'react'
import { connect } from "react-redux";
import Table from "../reacttable/table";
import "../reacttable/reactTable.css"
import { getLightVehicleAction } from "../../../store/actions/fleetAction";

function LightVehicles(props) {
    useEffect(() => {
        props.all_Light_Vehicle()
    }, []);

    const data = props.h_list && props.h_list.result
    // console.log(data)

    const columns = useMemo(
        () => [
            {
                Header: 'Category Name', accessor: 'category_name'
            },
            {
                Header: 'Drive Train', accessor: 'drivetrain'
            },
            {
                Header: 'Electric Range', accessor: 'electric_range'
            },
            {
                Header: 'Electric Cylinder Count', accessor: 'engine_cylinder_count'
            },
            {
                Header: 'Engine Description', accessor: 'engine_description'
            },
            {
                Header: 'Engine Size', accessor: 'engine_size'
            },
            {
                Header: 'Engine Type', accessor: 'engine_type'
            },
            {
                Header: 'Fuel Id', accessor: 'fuel_id'
            },
            {
                Header: 'Fuel Name', accessor: 'fuel_name'
            },
            {
                Header: 'Fuel Configuration Name', accessor: 'light_duty_fuel_configuration_name'
            },
            {
                Header: 'Manufacturer Name', accessor: 'manufacturer_name'
            },
            {
                Header: 'Model', accessor: 'model'
            },
            {
                Header: 'Model Year', accessor: 'model_year'
            },
            {
                Header: 'Transmission Type', accessor: 'transmission_type'
            },
        ],
        []
    );

    return (
        <Container fluid>
            <div class="row">
                <div class="col-lg-12 col-sm-12 pad_t2">
                    <Card className="customercard">
                        <Card.Header>Light Vehicle List</Card.Header>
                        <div>
                        {data && data.length > 0 ?
                                (<div className="App"> <Table data={data} columns={columns}></Table></div>)
                                :
                                (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}><h3 className="loader">No data found</h3></div>)
                            }
                        </div>
                    </Card>
                </div>
            </div>
        </Container>
    )
}

const mapStateToProps = (state) => {
    return {
        h_list: state.fleet.light_vehicle
    }
}
const mapDispatchToProps = dispatch => {
    return {
        all_Light_Vehicle: () => dispatch(getLightVehicleAction())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(LightVehicles)
