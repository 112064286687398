import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DeleteBookingCharger, fetchChargerSlots, fetchEvseStationAction, fetchTimeMasterListAction, updateBookingCharger } from "../../../../../store/actions/evseAction";
import { connect } from "react-redux";
import PureBreadcrumbs from "../../../breadcrums";
import { Container, Col, Button, Row } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import * as Yup from 'yup';
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { Bounce, ToastContainer, toast } from "react-toastify";


function UpdateChargerSlots(props) {
    // console.log(props)
    const { t } = useTranslation();
    const { evse_id } = useParams();
    const [selectedStartTimeIndex, setSelectedStartTimeIndex] = useState([]);
    const [isToastVisible, setIsToastVisible] = useState(false);

    useEffect(() => {
        props.chargerSlotsData(evse_id);
    }, [evse_id]);


    const bookingSchema = Yup.object().shape({
        evse_id: Yup.string().required('EVSE ID is required'),
        regular_hours: Yup.array().of(
            Yup.object().shape({
                weekday: Yup.number().required('Weekday is required'),
                period_begin: Yup.string().required('Start Time is required'),
                period_end: Yup.string().required('End Time is required'),
            }))
    });
    const { register, control, setValue, watch, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(bookingSchema), mode: "all" });
    const { fields, append, remove } = useFieldArray({ name: 'regular_hours', control });
    const elements = watch("regular_hours");
    // console.log("elements", elements);

    const evsedata = props.chargerSlots && props.chargerSlots
    // console.log("evsedata", evsedata)

    useEffect(() => {
        props.getTimeMaster();
        props.fetch(evsedata?.stationid);
    }, [evsedata]);

    const evseDataOperator = props.fetchstation && props.fetchstation
    // console.log(evseDataOperator);
    const selectedEvseIdOperator = evseDataOperator?.operator?.name;

    useEffect(() => {
        setValue("evse_id", evsedata.evse_id, { shouldValidate: true });
        setValue("regular_hours", evsedata.regular_hours)
    }, [evsedata])

    const onSubmit = async data => {
        // console.log("save", data,)
        // const removeDuplicates = (array) => {
        //     const seen = new Set();
        //     return array.filter((item) => {
        //         const key = `${item.weekday}-${item.period_begin}-${item.period_end}`;
        //         if (seen.has(key)) {
        //             return false;
        //         } else {
        //             seen.add(key);
        //             return true;
        //         }
        //     });
        // };
        props.updateBookingAPI(data, selectedEvseIdOperator)

    };

    const handleChange = (e, i) => {
        const selectedIndex = e.target.selectedIndex;
        // console.log("selectedIndex",selectedIndex)
        setSelectedStartTimeIndex((prev) => {
            const newStartTimes = [...prev];
            // console.log("newStartTimes",newStartTimes)
            newStartTimes[i] = selectedIndex;
            return newStartTimes;
        });
    }

    useEffect(() => {
        if (props.update.statuscode === 200) {
            setIsToastVisible(true);
            toast.success('Booking slots has been Updated', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    window.location.reload();
                },
            });
        } else if (props.update.statuscode === 405) {
            setIsToastVisible(true);
            toast.error(`❌ ${props.update.error}`, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    window.location.reload();
                },
            });
        }
    }, [props.update]);


    return (
        <Container fluid>
            {isToastVisible && <div className="overlay" />}
            <ToastContainer
                position="top-center"
                autoClose={20}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ top: "10%", width: "auto", zIndex: "9999" }}
            />
            <div className="row">
                <div className="col-lg-6 col-sm-12">
                    <PureBreadcrumbs />
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-lg-8 col-sm-12">
                    <h3><b>
                        {t("Update Charger booking slot for")} {evse_id}
                    </b></h3>
                </div>
            </div>
            <form onSubmit={e => e.preventDefault()}>
                <Row className="row mt-3">
                    <Col lg={6} xs={10} className="">
                        <div className="form-floating">
                            <select disabled className={`form-select form-control`} name="evse_id" id="floatingSelectGridevse_id" {...register("evse_id")}>
                                <option value={evse_id}>{evse_id}</option>
                            </select>
                        </div>
                    </Col>
                    <Col xs={2} className="">
                        <div> <Button
                            onClick={() => { append({ weekday: '', period_begin: '', period_end: '' }) }} > + </Button></div>
                    </Col>
                </Row>
                <div className="row">
                    <div className="col-lg-6 col-sm-10 col-xs-6">
                        <div className="table-responsive">
                            <table className="table table-sm table-bordered">
                                <thead>
                                    <tr >
                                        <th scope="col">{t("Weekday")} (*)</th>
                                        <th scope="col">{t("Start Time")} (*)</th>
                                        <th scope="col">{t("End Time")} (*)</th>
                                        <th scope="col">{t("Action")}</th>
                                    </tr>
                                </thead>
                                {fields.map((item, i) => (
                                    <tbody key={i}>
                                        <tr>
                                            <td>
                                                <div className="form-floating">
                                                    <select className={`form-select form-control ${errors.regular_hours?.[i]?.weekday ? 'is-invalid' : ''}`} name={`regular_hours[${i}].weekday`} id="floatingSelectGridweekday" {...register(`regular_hours.${i}.weekday`)}
                                                    >
                                                        <option value="">{t("Select Weekday")}</option>
                                                        <option value="1">Monday</option>
                                                        <option value="2">Tuesday</option>
                                                        <option value="3">Wednesday</option>
                                                        <option value="4">Thursday</option>
                                                        <option value="5">Friday</option>
                                                        <option value="6">Saturday</option>
                                                        <option value="7">Sunday</option>

                                                    </select>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="form-floating">
                                                    <select className={`form-select form-control ${errors.regular_hours?.[i]?.period_begin ? 'is-invalid' : ''}`} name={`regular_hours[${i}].period_begin`} id="floatingSelectGridUpdateperiod_begin"  {...register(`regular_hours.${i}.period_begin`)}
                                                        onChange={(e) => handleChange(e, i)}>
                                                        <option value={""}>{t("Select Start Time")}</option>
                                                        {props.mastertimelist && props.mastertimelist.hours.map((v, k) =>
                                                            <option key={v.key} value={v.value}>{v.value}</option>
                                                        )}
                                                    </select>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="form-floating">
                                                    <select className={`form-select form-control ${errors.regular_hours?.[i]?.period_end ? 'is-invalid' : ''}`} name={`regular_hours[${i}].period_end`} id="floatingSelectGridUpdateperiod_end"  {...register(`regular_hours.${i}.period_end`)}>
                                                        <option value={""}>{t("Select End Time")}</option>
                                                        {/* {console.log("[i]",[i])} */}
                                                        {/* {console.log("selectedStartTimeIndex",selectedStartTimeIndex)}
                                                        {console.log("selectedStartTimeIndex[i]",selectedStartTimeIndex[i])}
                                                        {console.log("time list",props.mastertimelist && props.mastertimelist.hours)} */}
                                                        {props.mastertimelist && props.mastertimelist.hours.slice(selectedStartTimeIndex[i],).map((v, k) =>
                                                            <option key={v.key} value={v.value}>{v.value}</option>
                                                        )}
                                                    </select>
                                                </div>
                                            </td>

                                            <td>
                                                <Button disabled={elements && elements.length <= 1} onClick={(e) => { e.preventDefault(); remove(i); }}> -</Button>
                                            </td>
                                        </tr>

                                    </tbody>
                                ))}

                            </table>
                            <br />
                            <div className="d-flex justify-content-center">
                                {fields.length > 0 && props.loading ?
                                    <button className="btn lgn-btn" type="button" disabled>
                                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                        &nbsp;
                                        {t("Saving...")}
                                    </button>
                                    : <button
                                        className="lgn-btn btn "
                                        type="submit"
                                        onClick={handleSubmit(onSubmit)}
                                        disabled={
                                            fields.some(
                                                (item) =>
                                                    item.weekday == null && item.period_begin == null && item.period_end == null
                                            )
                                        }
                                    >
                                        UPDATE
                                    </button>
                                }
                            </div>
                        </div>

                    </div>
                </div>

            </form>
        </Container>
    )

}


const mapStateToProps = (state) => {
    return {
        loading: state.evse.isEvseLoading,
        chargerSlots: state.evse.chargerslots,
        mastertimelist: state.evse.timemaster,
        update: state.evse.updateBooking,
        DeleteBookingAPI: state.evse.deleteBookingChargerSlots,
        fetchstation: state.evse.viewstation,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetch: (stationid) => dispatch(fetchEvseStationAction(stationid)),
        getTimeMaster: () => dispatch(fetchTimeMasterListAction()),
        chargerSlotsData: (evse_id) => dispatch(fetchChargerSlots(evse_id)),
        updateBookingAPI: (data, selectedEvseIdOperator) => dispatch(updateBookingCharger(data, selectedEvseIdOperator)),
        deleteBookingAPI: (evse_id) => dispatch(DeleteBookingCharger(evse_id)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UpdateChargerSlots)

