import React, { useState } from 'react';
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";

const TransactionPDFDoc=(props)=>{
      const [startDate, setStartDate] = useState(new Date(Date.now() - (3600 * 1000 * 720)));
      const [endDate, setEndDate] = useState(new Date(Date.now() + (3600 * 1000 * 24)));
      const [org, setOrg] = useState("All")
 
      const generatePDF = () => {
            const unit = "pt";
            const size = "A4"; // Use A1, A2, A3 or A4
            const orientation = "landscape"; // portrait or landscape
            const marginLeft = 40;
            const doc = new jsPDF(orientation, unit, size);
            doc.setFontSize(12);
            const org_name="Name: "+props.walletHistory.data.firstName + props.walletHistory.data.lastName;
            const org_mobile="Mobile: "+ props.walletHistory.data.phoneNumber;
            const org_balance="Current Wallet Balance: "+ props.walletHistory.data.amount;
            const org_date="Wallet Created At : "+moment(props.walletHistory.data.registerat).local().format("DD-MM-YYYY");
            const headers = [["Date", "User ID","Transaction ID","Old Balance","Amount","Status","Merchant Reference","Vendor ID"]];
            const data = props.fetchSinglWalletDetails.data.map(elt=> [moment(elt.date).local().format("DD-MM-YYYY"), elt.userid,elt.txnId,elt.old_balance,elt.amount,elt.status,elt.merchantReference,elt.vendorId]);
      
            let content = {
              startY: 160,
              head: headers,
              body:data
            };
            
            doc.text(org_name, marginLeft, 40);
            doc.text(org_mobile, marginLeft, 55);
            doc.text(org_balance, marginLeft, 70);
            doc.text(org_date, marginLeft, 85);
            doc.autoTable(content);
            doc.save(org+"_"+startDate.toISOString().substr(0, 10)+"_"+endDate.toISOString().substr(0, 10)+`.pdf`)
                }   
      
       return(
            <div>
                  <i className="fas fa-2x fa-file-pdf" onClick={generatePDF} typeof='button'></i> 
               </div>
       )
}
export default TransactionPDFDoc;