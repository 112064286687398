import { FETCH_ORGANISATION_EXISTS, PRICE_URL, FETCH_REVENUE_SHARE, SAVE_ORG_API, FETCH_ALL_ORG_API, FETCH_SINGLE_ORG_TARIFF, UPDATE_ORG_API, SAVE_ORG_PROMOTION_CODE, FETCH_ORG_PROMOTION_CODE, SAVE_ORG_CORPORATE_CODE, FETCH_ORG_CORPORATE_CODE, FETCH_ACTIVE_ORG_API, FETCH_ALL_ORG_PERCENTAGE_API, ACTIVATE_PROMOTION_API, ACTIVATE_PROMOTION, DEACTIVATE_PROMOTION, ACTIVATE_CORPORATE_CODE, DEACTIVATE_CORPORATE_CODE, UPDATE_PROMOTION_CODE, UPDATE_CORPORATE_CODE } from "../../components/config/config";
import { ApiHandler } from "./apiHandler";

export const activatePromotion = (promotion_code) => {
    // console.log("promotion_code", promotion_code);
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'activatePromotion'
    return async dispatch => {
        dispatch({ type: 'LOAD_ORG', payload: '' })
        const data = await ApiHandler(PRICE_URL + ACTIVATE_PROMOTION + "?promotion_code=" + promotion_code, {
            functionName,
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'ACTIVATE_PROMOTIONS', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const activateCORPCODE = (corporate_code) => {
    console.log("activateCORPCODE", corporate_code)
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'activateCORPCODE'
    return async dispatch => {
        dispatch({ type: 'LOAD_ORG', payload: '' })
        const data = await ApiHandler(PRICE_URL + ACTIVATE_CORPORATE_CODE + "?corporate_code=" + corporate_code, {
            functionName,
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'ACTIVATE_CORPORATE_CODE', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const deactivatePromotion = (promotion_code) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'deactivatePromotion'
    return async dispatch => {
        dispatch({ type: 'LOAD_ORG', payload: '' })
        const data = await ApiHandler(PRICE_URL + DEACTIVATE_PROMOTION + "?promotion_code=" + promotion_code, {
            functionName,
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'DEACTIVATE_PROMOTION', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const deactivateCORPCODE = (corporate_code) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'deactivateCORPCODE'
    return async dispatch => {
        dispatch({ type: 'LOAD_ORG', payload: '' })
        const data = await ApiHandler(PRICE_URL + DEACTIVATE_CORPORATE_CODE + "?corporate_code=" + corporate_code, {
            functionName,
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'DEACTIVATE_CORPORATE_CODE', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const saveOrganisationAction = (data) => {
    const { name, address, client_revenue_share, regional_revenue_share, self_revenue_share, phone, status, isfleet, per_unit_price, stripe_account, email, city, state, country, Zipcode } = data;
    const { token, role, userid, organisation } = JSON.parse(localStorage.getItem('user'));
    // console.log(per_unit_price)
    const bearer = 'basic ' + token
    const functionName = 'saveOrganisationAction'
    return async dispatch => {
        dispatch({ type: 'LOAD_ORG', payload: '' })
        const data = await ApiHandler(PRICE_URL + SAVE_ORG_API, {
            functionName,
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "name": name.trim(),
                'address': address,
                'phone': phone,
                "email": email,
                "isfleet": isfleet,
                "per_unit_price": per_unit_price,
                "stripe_account": stripe_account,
                "city": city,
                "state": state,
                "country": country,
                "created_by": userid,
                "postal_code": Zipcode,
                "status": status,
                "role": role,
                "organisation": organisation,
                "client_revenue_share": client_revenue_share.toString(),
                "regional_revenue_share": regional_revenue_share.toString(),
                "self_revenue_share": self_revenue_share.toString(),
            })
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'SAVE_ORG', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const updateOrganisationAction = (id, data) => {
    const { name, per_unit_price, stripe_account, address, phone, isfleet, client_revenue_share, self_revenue_share, regional_revenue_share, status, email, city, state, country, Zipcode, createdat, role, organisation } = data;
    const { token } = JSON.parse(localStorage.getItem('user'));
    // console.log("address from action", address)
    const bearer = 'basic ' + token
    const functionName = 'updateOrganisationAction'
    return async dispatch => {
        dispatch({ type: 'LOAD_ORG', payload: '' })
        const data = await ApiHandler(PRICE_URL + UPDATE_ORG_API, {
            functionName,
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "id": id,
                "name": name.trim(),
                'address': address,
                'phone': phone,
                "email": email,
                "isfleet": isfleet,
                "per_unit_price": per_unit_price,
                "stripe_account": stripe_account,
                "city": city,
                "state": state,
                "country": country,
                "client_revenue_share": client_revenue_share,
                "self_revenue_share": self_revenue_share,
                "regional_revenue_share": regional_revenue_share,
                "status": status,
                "postal_code": Zipcode,
                "createdat": createdat,
                "role": role,
                "organisation": organisation

            })
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'UPDATE_ORG', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const fetchRevenueShare = () => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'fetchRevenueShare'
    return async dispatch => {
        dispatch({ type: 'LOAD_ORG', payload: '' })
        const data = await ApiHandler(PRICE_URL + FETCH_REVENUE_SHARE, {
            functionName,
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'FETCH_REVENUE_SHARE', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const allOrgTariffAction = () => {
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'allOrgTariffAction';
    return async dispatch => {
        dispatch({ type: 'LOAD_ORG', payload: '' })
        const data = await ApiHandler(PRICE_URL + FETCH_ACTIVE_ORG_API + "?role=" + role + "&organisation=" + organisation, {
            functionName,
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'LOAD_ACTIVE_ORG_LIST', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const allOrglist = () => {
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'allOrglist'
    return async dispatch => {
        dispatch({ type: 'LOAD_ORG', payload: '' })
        const data = await ApiHandler(PRICE_URL + FETCH_ALL_ORG_API + "?role=" + role + "&organisation=" + organisation, {
            functionName,
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'LOAD_ALL_ORG_LIST', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const allOrgPercentageApi = () => {
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'allOrgPercentageApi'
    return async dispatch => {
        dispatch({ type: 'LOAD_ORG', payload: '' })
        const data = await ApiHandler(PRICE_URL + FETCH_ALL_ORG_PERCENTAGE_API + "?role=" + role + "&organisation=" + organisation, {
            functionName,
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'FETCH_ALL_ORG_Percentage', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const SingleOrgTariffAction = (id) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'SingleOrgTariffAction'
    return async dispatch => {
        dispatch({ type: 'LOAD_ORG', payload: '' })
        const data = await ApiHandler(PRICE_URL + FETCH_SINGLE_ORG_TARIFF + "?id=" + id, {
            functionName,
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'SINGLE_ORG_TARIFF', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const saveOrganisationPromotionCode = (data, startdate, enddate) => {
    const { promotion_code, discount, promotion_name } = data;
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'saveOrganisationPromotionCode'
    return async dispatch => {
        dispatch({ type: 'LOAD_ORG', payload: '' })
        const data = await ApiHandler(PRICE_URL + SAVE_ORG_PROMOTION_CODE, {
            functionName,
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "promotion_code": promotion_code,
                'startdate': startdate,
                'enddate': enddate,
                "discount": discount,
                "promotion_name": promotion_name,
                'role': role,
                "organisation": organisation
            })
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'SAVE_ORG_PROMOTION_CODE', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const updatePromotion = (data, startdate, enddate) => {
    const { promotion_code, discount, promotion_name } = data;
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'updatePromotion'
    return async dispatch => {
        dispatch({ type: 'LOAD_ORG', payload: '' })
        const data = await ApiHandler(PRICE_URL + UPDATE_PROMOTION_CODE, {
            functionName,
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "promotion_code": promotion_code,
                'startdate': startdate,
                'enddate': enddate,
                "discount": discount,
                "promotion_name": promotion_name,
                'role': role,
                "organisation": organisation
            })
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'UPDATE_PROMOTION', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const allOrgpromotionAction = () => {
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'allOrgpromotionAction'
    return async dispatch => {
        dispatch({ type: 'LOAD_ORG', payload: '' })
        const data = await ApiHandler(PRICE_URL + FETCH_ORG_PROMOTION_CODE + "?role=" + role + "&organisation=" + organisation, {
            functionName,
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'FETCH_ORG_PROMOTION_CODE', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const saveCorporateCode = (data, startdate, enddate, amount, unit) => {
    // console.log(startdate,enddate)
    const { corporate_code, code_name, tariff, assignedoperator } = data;
    const { token, organisation, role } = JSON.parse(localStorage.getItem('user'));
    // console.log(PRICE_URL + SAVE_ORG_CORPORATE_CODE)
    const bearer = 'basic ' + token
    const functionName = 'saveCorporateCode'
    return async dispatch => {
        dispatch({ type: 'LOAD_ORG', payload: '' })
        const data = await ApiHandler(PRICE_URL + SAVE_ORG_CORPORATE_CODE, {
            functionName,
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "corporate_code": corporate_code,
                'startdate': startdate,
                'enddate': enddate,
                "code_name": code_name,
                'tariff': tariff,
                "amount": Number(amount),
                "unit": unit,
                'role': role,
                "organisation": organisation,
                "assignedoperator": assignedoperator
            })
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'SAVE_ORG_CORPORATE_CODE', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const updateCorporateCode = (data, startdate, enddate) => {
    // console.log("start date and end date from action",startdate,enddate)
    const { corporate_code, code_name, tariff, assignedoperator } = data;
    const { token, organisation, role } = JSON.parse(localStorage.getItem('user'));
    // console.log(PRICE_URL + SAVE_ORG_CORPORATE_CODE)
    const bearer = 'basic ' + token
    const functionName = 'updateCorporateCode'
    return async dispatch => {
        dispatch({ type: 'LOAD_ORG', payload: '' })
        const data = await ApiHandler(PRICE_URL + UPDATE_CORPORATE_CODE, {
            functionName,
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "corporate_code": corporate_code,
                'startdate': startdate,
                'enddate': enddate,
                "code_name": code_name,
                'tariff': tariff,
                'role': role,
                "organisation": organisation,
                "assignedoperator": assignedoperator
            })
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'UPDATE_CORPCODE', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const allCorporateCodeAction = () => {
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'allCorporateCodeAction'
    return async dispatch => {
        dispatch({ type: 'LOAD_ORG', payload: '' })
        const data = await ApiHandler(PRICE_URL + FETCH_ORG_CORPORATE_CODE + "?role=" + role + "&organisation=" + organisation, {
            functionName,
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'FETCH_ORG_CORPORATE_CODE', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const fetchOrganisationName = (name) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'fetchOrganisationName'
    return async dispatch => {
        dispatch({ type: 'LOAD_ORG', payload: '' })
        const data = await ApiHandler(PRICE_URL + FETCH_ORGANISATION_EXISTS + "?name=" + name, {
            functionName,
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'FETCH_ALL_ORG_NAME', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}