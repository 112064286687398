import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Card } from "react-bootstrap";
import Chart from "react-apexcharts";
import { fetchChargingGraphAction } from "../../../store/actions/evseAction";
import { useTranslation } from "react-i18next";

function LiveChargingGraph(props) {
  const { t } = useTranslation();
  useEffect(() => {
    props.graph();
    const interval = setInterval(() => {
      props.graph();
    }, 180000);
    return () => clearInterval(interval);
  }, []);

  const consumption = props.graphData && props.graphData.data && props.graphData.data.map((en) => en.energy);
  const time = props.graphData && props.graphData.data && props.graphData.data.map((en) => en.time);
  // console.log(consumption)
  // console.log(time)

  const options = {
    chart: {
      type: "bar",
      height: 350,
    },
    xaxis: {
      categories: time,
      labels: {
        style: {
          colors: "var(--text-primary)", // Replace with the color you want for X-axis content
        },
      },
    },
    yaxis: [
      {
        title: {
          text: "Consumption",
          style: {
            color: "var(--text-primary)",
            fontSize: "13px",
            fontWeight: 600,
          },
        },
        labels: {
          style: {
            colors: "var(--text-primary)", // Replace with the color you want for Y-axis content
          },
          formatter: function (value) {
            return value.toFixed(2);
          },
        },
      },
    ],
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return parseFloat(val).toFixed(2);
      },
    },
    colors: ["#5559B2", "#0BCF6B"],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100],
      },
    },
    series: [
      {
        name: "Energy",
        data: consumption,
      },
    ],
    tooltip: {
      y: {
        formatter: function (val, { series, seriesIndex }) {
          val = parseFloat(val).toFixed(2);
          if (seriesIndex === 0) {
            return val + " kWh";
          } else {
            return val;
          }
        },
      },
      // enabled: true,
      // enabledOnSeries: undefined,
      // followCursor: true,
      // // fillSeriesColor: true,
      // theme: false,
      // style: {
      //   fontSize: '12px',
      //   background: '#333', // Explicitly setting the background color
      //   color: '#fff', // Text color in the tooltip
      // },
    },
  };

  return (
    <Card className="mt-4 customercard">
      <div className="table-header">{t("Real time charging graph")}</div>
      <div>
        {props.loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "400px",
            }}
          >
            <h3>Loading data...</h3>
          </div>
        ) : props.graphData && props.graphData.data && props.graphData.data.length > 0 ? (
          <Chart options={options} series={options.series} type="area" height={550} />
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "400px",
            }}
          >
            <h3>{t("No Ongoing Charging")} </h3>
          </div>
        )}
      </div>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    graphData: state.evse.livecharginggraph,
    loading: state.evse.isEvseLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    graph: () => dispatch(fetchChargingGraphAction()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LiveChargingGraph);
