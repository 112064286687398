import { t } from "i18next";
import React from "react";
import ApexCharts from "react-apexcharts";
import { Card } from "react-bootstrap";

function CustomStationRevenue(props) {
  const currency = process.env.REACT_APP_CURRENCY;

  const data = props.date_range_revenue && props.date_range_revenue.data;
  // console.log(data) 
  const sortedData = [...data] && [...data].sort((a, b) => b.total - a.total);
  // console.log(sortedData)
  const top5Station = sortedData && sortedData.slice(0,5);
  // console.log("top5",top5Station)

  const monthdata = {
    chart: {
      type: "area",
      height: 350,
    },
    xaxis: {
      categories: top5Station && top5Station.map((item)=>( item.stationid)) ,
    },
    yaxis: [
      {
        title: {
          text: "Monthly Revenue",
        },
        labels: {
          formatter: function (val) {
            return `${currency} ${val}`;
          },
        },
      },
    ],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    colors: ["#0BCF6B"],
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100],
      },
    },
    series: [
      {
        name: "Monthly Revenue",
        data: top5Station && top5Station.map((item)=>( item.total)),
        yaxis: 1,
      },
    ],
    tooltip: {
      y: {
        formatter: function (val) {
          return `${currency} ${val.toFixed(2)}`;
        },
      },
    },
  };

    return (
        <Card className="statuscard">
            <Card.Title className="text-center">
             {t("Station Revenue Graph")}
            </Card.Title>
            <Card.Body>
            {props.loading  ? <h3  style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "250px",
                }}>Loading data...</h3> :  
                top5Station && top5Station.length > 0   ? (
 
                <ApexCharts
                     options={monthdata}
                     series={monthdata.series}
                     type="area"
                     height={250}
                />) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "250px",
                        }}
                      >
                      <h3>{t("No data found")} </h3>
                      </div>
                )}
            </Card.Body>
          </Card>
    )
}

export default CustomStationRevenue