import React, { useMemo, useState } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import PureBreadcrumbs from "../../breadcrums";
import ReactApexChart from 'react-apexcharts';
import { PiEngineBold } from "react-icons/pi";
import { PiBatteryVerticalHighThin } from "react-icons/pi";
import { LiaCarBatterySolid } from "react-icons/lia";
import Table from '../../reacttable/table';
function ViewSingle(props) {
    const [series, setSeries] = useState([75]);
    const [options, setOptions] = useState({
        chart: {
            height: 350,
            type: 'radialBar',
            toolbar: {
                show: false
            }
        },
        plotOptions: {
            radialBar: {
                startAngle: -135,
                endAngle: 225,
                hollow: {
                    margin: 0,
                    size: '70%',
                    background: '#fff',
                    image: undefined,
                    imageOffsetX: 0,
                    imageOffsetY: 0,
                    position: 'front',
                    dropShadow: {
                        enabled: true,
                        top: 3,
                        left: 0,
                        blur: 4,
                        opacity: 0.24
                    }
                },
                track: {
                    background: '#fff',
                    strokeWidth: '67%',
                    margin: 0,
                    dropShadow: {
                        enabled: true,
                        top: -3,
                        left: 0,
                        blur: 4,
                        opacity: 0.35
                    }
                },
                dataLabels: {
                    show: true,
                    name: {
                        offsetY: -10,
                        show: true,
                        color: '#888',
                        fontSize: '17px'
                    },
                    value: {
                        formatter: function (val) {
                            return parseInt(val);
                        },
                        color: '#111',
                        fontSize: '36px',
                        show: true
                    }
                }
            }
        },
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'dark',
                type: 'horizontal',
                shadeIntensity: 0.5,
                gradientToColors: ['#ABE5A1'],
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100]
            }
        },
        stroke: {
            lineCap: 'round'
        },
        labels: ['Percent']
    });

    const data = [
        {
            name: "Tesla",
            year: "2018",
            model: "Model S",
            color: "Silver",
            vin: "4Y1SL65848Z411439",
        },
    ];
    return (
        <Container fluid>
            <PureBreadcrumbs />
            <br />
            <Row className="p-3">
                <Col xs={12} style={{ display: "flex", justifyContent: "space-between", gap: "20px" }}>
                    <Row style={{ flexGrow: "1", marginRight: "10px" }}>
                        <Card className="tariffcard">
                            <div style={{ display: "flex", gap: "20px" }}>
                                <img src={"/images/bus1.svg"} alt="vehicle" style={{ width: "50px" }} />
                                <div>
                                    <Card.Title style={{ fontSize: "16px" }}>Tesla</Card.Title>
                                    <Card.Title style={{ fontSize: "12px" }}>VIN: 123456789</Card.Title>
                                </div>
                            </div>
                            <div style={{ marginTop: "20px", display: "flex", justifyContent: "space-between" }}>
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    <Card.Title style={{ fontSize: "14px" }}>STATUS</Card.Title>
                                    <Card.Title style={{ fontSize: "12px" }}>Active</Card.Title>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    <Card.Title style={{ fontSize: "14px" }}>TYPE</Card.Title>
                                    <Card.Title style={{ fontSize: "12px" }}>2 Wheeler</Card.Title>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    <Card.Title style={{ fontSize: "14px" }}>Average speed</Card.Title>
                                    <Card.Title style={{ fontSize: "12px" }}>50 km/hr</Card.Title>
                                </div>
                            </div>
                            <div style={{ marginTop: "20px", display: "flex", gap: "70px", justifyContent: "center" }}>
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    <Card.Title style={{ fontSize: "14px" }}>DOMAIN</Card.Title>
                                    <Card.Title style={{ fontSize: "12px" }}>RETAIL</Card.Title>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    <Card.Title style={{ fontSize: "14px" }}>DRIVER</Card.Title>
                                    <Card.Title style={{ fontSize: "12px" }}>John Doe</Card.Title>
                                </div>
                            </div>
                        </Card>
                    </Row>
                    <Row>
                        <Card className="tariffcard" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <Card.Title style={{ fontSize: "16px" }}>Vehicle Condition</Card.Title>
                            <div>
                                <div id="card">
                                    <div id="chart">
                                        <ReactApexChart options={options} series={series} type="radialBar" height={350} />
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </Row>
                    <Row style={{ flexGrow: "1", marginLeft: "10px" }}>
                        <Card className="tariffcard" style={{ display: "flex", flexDirection: "column" }}>
                            <Card.Title style={{ fontSize: "16px" }}>Key Indicators</Card.Title>
                            <div style={{ display: "flex", gap: "20px", justifyContent: "space-between", alignItems: "center", height: "100%" }}>
                                <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
                                    <div style={{ fontSize: "50px" }}>
                                        <PiEngineBold />
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                        <Card.Text style={{ fontSize: "16px", fontWeight: "bold", margin: "0" }}>OK</Card.Text>
                                        <Card.Text style={{ fontSize: "16px", }}>Engine</Card.Text>
                                    </div>
                                </div>
                                <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
                                    <div style={{ fontSize: "50px" }}>
                                        <LiaCarBatterySolid />
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                        <Card.Text style={{ fontSize: "16px", fontWeight: "bold", margin: "0" }}>OK</Card.Text>
                                        <Card.Text style={{ fontSize: "16px", }}>Engine</Card.Text>
                                    </div>
                                </div>
                                <div style={{ display: "flex", gap: "20px", alignItems: "center", justifyContent: "center" }}>
                                    <div style={{ fontSize: "50px" }}>
                                        <PiBatteryVerticalHighThin />
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                        <Card.Text style={{ fontSize: "16px", fontWeight: "bold", margin: "0" }}>35%</Card.Text>
                                        <Card.Text style={{ fontSize: "16px", }}>Battery Percent</Card.Text>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </Row>
                </Col>
                <Row className="mt-4" style={{ width: "420px" }}>
                    <Col>
                        <Card className="tariffcard">
                            <Card.Title style={{ fontSize: "16px" }}>Vehicle Details</Card.Title>
                            <div style={{ display: "flex", flexDirection: "column", gap: "5px", alignItems: "center" }}>
                                <div style={{ display: "flex" }}>
                                    <Card.Text style={{ fontWeight: "bold", marginRight: "5px" }}>Name:</Card.Text><Card.Text>{data[0].name}</Card.Text>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <Card.Text style={{ fontWeight: "bold", marginRight: "5px" }}>Model:</Card.Text><Card.Text>{data[0].model}</Card.Text>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <Card.Text style={{ fontWeight: "bold", marginRight: "5px" }}>Year:</Card.Text><Card.Text>{data[0].year}</Card.Text>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <Card.Text style={{ fontWeight: "bold", marginRight: "5px" }}>Color:</Card.Text><Card.Text>{data[0].color}</Card.Text>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <Card.Text style={{ fontWeight: "bold", marginRight: "5px" }}>VIN:</Card.Text><Card.Text>{data[0].vin}</Card.Text>
                                </div>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </Row>
        </Container>
    )
}

export default ViewSingle