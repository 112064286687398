// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.custom-btn {
    background: var(--background);
    color: var(----text-primary);
    position: absolute;
    width: 70%;
    height: 42px;
    border-radius: 13px;
    border-color: var(--background);
}


.custom-btn:hover {
    background: var(--button-color);
    color: var(----text-primary);
    position: absolute;
    width: 70%;
    height: 42px;
    border-radius: 13px;
    border-color: var(--background);
}
.p-viewer-resetpass {
    z-index: 9999;
    position: absolute;
    top: 8% ;
    right: 30px;
    cursor: pointer;
  }
.p-viewer-stripepass {
    z-index: 9999;
    position: absolute;
    top: 76% ;
    right: 30px;
    cursor: pointer;
  }
  /* @media (min-width: 981) {
    .p-viewer-login {
      z-index: 9999;
      position: absolute;
      top: 26%;
      right: 70px;
      cursor: pointer;
    }
  } */`, "",{"version":3,"sources":["webpack://./src/components/dashboard/settings/settings.css"],"names":[],"mappings":";AACA;IACI,6BAA6B;IAC7B,4BAA4B;IAC5B,kBAAkB;IAClB,UAAU;IACV,YAAY;IACZ,mBAAmB;IACnB,+BAA+B;AACnC;;;AAGA;IACI,+BAA+B;IAC/B,4BAA4B;IAC5B,kBAAkB;IAClB,UAAU;IACV,YAAY;IACZ,mBAAmB;IACnB,+BAA+B;AACnC;AACA;IACI,aAAa;IACb,kBAAkB;IAClB,QAAQ;IACR,WAAW;IACX,eAAe;EACjB;AACF;IACI,aAAa;IACb,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,eAAe;EACjB;EACA;;;;;;;;KAQG","sourcesContent":["\n.custom-btn {\n    background: var(--background);\n    color: var(----text-primary);\n    position: absolute;\n    width: 70%;\n    height: 42px;\n    border-radius: 13px;\n    border-color: var(--background);\n}\n\n\n.custom-btn:hover {\n    background: var(--button-color);\n    color: var(----text-primary);\n    position: absolute;\n    width: 70%;\n    height: 42px;\n    border-radius: 13px;\n    border-color: var(--background);\n}\n.p-viewer-resetpass {\n    z-index: 9999;\n    position: absolute;\n    top: 8% ;\n    right: 30px;\n    cursor: pointer;\n  }\n.p-viewer-stripepass {\n    z-index: 9999;\n    position: absolute;\n    top: 76% ;\n    right: 30px;\n    cursor: pointer;\n  }\n  /* @media (min-width: 981) {\n    .p-viewer-login {\n      z-index: 9999;\n      position: absolute;\n      top: 26%;\n      right: 70px;\n      cursor: pointer;\n    }\n  } */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
