import React from "react";
import { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { fetchLiveChargingAction } from "../../../store/actions/evseAction";
import { Container, Card } from "react-bootstrap";
import PureBreadcrumbs from "../breadcrums";
import Table from "../reacttable/table";
import ProgressBar from "react-bootstrap/ProgressBar";
import LiveChargingGraph from "./chargingGraph";
import { useTranslation } from "react-i18next";
import { releaseTransaction } from "../../../store/actions/reportActions";
import moment from "moment";

function LiveCharging(props) {
  const { t } = useTranslation();


  useEffect(() => {
    props.all();

    const interval = setInterval(() => {
      props.all();
    }, 180000);
    return () => clearInterval(interval);
  }, []);

  const data = props.list && props.list.data;
  // console.log(data);

  const handleRelease = (id) => {
    // console.log(id)
    props.releaseTransaction(id);
  };

  if (props.release.statuscode === 200) {
    window.location.reload();
  }

  const columns = useMemo(
    () => [
      {
        Header: "Station Name",
        id: "3",
        accessor: "stationid",
      },
      {
        Header: "EVSE ID",
        id: "1",
        accessor: "evse_id",
      },
      {
        Header: "Tag ID",
        accessor: "idtag",
      },
      {
        Header: "Charging date",
        id: "start_date",
        accessor: (d) => {
          return moment(d.start_date).format("DD-MM-YYYY");
        },
      },
      {
        Header: "Port No",
        accessor: "portno",
      },
      {
        Header: "Customer Name",
        accessor: "customer_name",
      },
      {
        Header: "Customer Number",
        accessor: "mobile",
      },
      {
        Header: "Units consumed",
        accessor: "kwh",
      },
      {
        Header: "Total Amount",
        accessor: "total_amount",
      },
      {
        Header: "State of Charge",
        accessor: "soc",
        Cell: ({ value }) => {
          // console.log(soc);
          return (
            <div>
              {value < 25 ? (
                <ProgressBar
                  variant="danger"
                  animated
                  now={value}
                  label={`${value}%`}
                />
              ) : (
                <ProgressBar
                  variant="success"
                  animated
                  now={value}
                  label={`${value}%`}
                />
              )}
            </div>
          );
        },
      },
      {
        Header: "Charged Time",
        accessor: "consume_time",
      },
      {
        Header: "Release",
        accessor: "charging_id",
        Cell: ({ value }) => {
          // console.log("evse_id", evse_id.value)
          return (
            <span>
              <button className="btn commandButtons" type="button" onClick={() => handleRelease(value)}>
                {t("Release")}
              </button>
            </span>
          )
        }
      },
    ],
    []
  );

  return (
    <Container fluid className="pb-4">
      <PureBreadcrumbs />
      <br />

      <div className="row mb-4">
        <div className="col-lg-12 col-sm-12">
          <LiveChargingGraph />
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-12 col-sm-12">
          <Card className="customercard">
            <div className="table-header">{t("Live Charging Data")}</div>
            {props.loading ? <div style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
              <h3>Loading Data...</h3>
            </div> :
              data && data.length > 0 ? (
                <div>
                  <Table data={data} columns={columns}></Table>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3 className="loader">{t("No data found")}</h3>
                </div>
              )}
          </Card>
        </div>
      </div>


    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.evse.isEvseLoading,
    list: state.evse.livecharging,
    release: state.report.releasetransaction,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    all: () => dispatch(fetchLiveChargingAction()),
    releaseTransaction: (charging_id) => dispatch(releaseTransaction(charging_id)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LiveCharging);
