import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Container, Card, Row, Col } from "react-bootstrap";
import PureBreadcrumbs from "../../breadcrums";
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { fetchRuleDropdownApi } from '../../../../store/actions/loyaltyAction';
import { fetchSingleSubscriptionApi, updateSubscriptionApi } from '../../../../store/actions/walletActions';
import { Redirect, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import { HandleKeyPress } from '../../resuableComponent/handleKeyPress';

export const UpdateSubscription = (props) => {
    const { t } = useTranslation();
    const { name } = useParams();
    const [redirect, setRedirect] = useState(false);
    const [isToastVisible, setIsToastVisible] = useState(false);

    // console.log("name: " ,name);

    useEffect(() => {
        props.fetchDropdown();
        props.fetchSingle(name);
    }, []);

    const prevData = props.fetchSingleRes && props.fetchSingleRes.data;
    // console.log(prevData);

    const subscriptionSchema = Yup.object().shape({
        subscriptionName: Yup.string().required('Subscription Name is required.'),
        subscriptionType: Yup.string().required('Subscription Type is required.'),
        subscriptionValue: Yup.number().required('Subscription Value is required.').typeError("Subscription Value is required"),
        subscriptionDuration: Yup.string().required('Subscription Duration is required.'),
        rechargeAmount: Yup.number().required('Recharge Amount is required.').typeError("Recharge amount is required"),
        description: Yup.string(),
    });
    const { register, handleSubmit, reset, watch, setValue, formState: { errors } } = useForm({ resolver: yupResolver(subscriptionSchema), mode: "all" });

    useEffect(() => {
        const { sub_name, sub_type, sub_value, sub_duration, recharge_amount, description } = prevData;
        if (prevData) {
            setValue("subscriptionName", sub_name)
            setValue("subscriptionType", sub_type)
            setValue("subscriptionValue", sub_value)
            setValue("subscriptionDuration", sub_duration)
            setValue("rechargeAmount", recharge_amount)
            setValue("description", description)
        }

    }, [setValue, prevData])

    const onSubmit = async (data) => {
        props.updateSubApi(data);
        // console.log(data)
    };

    useEffect(() => {
        if (props.updateSubRes.statuscode === 200) {
            setIsToastVisible(true);
            toast.success(`${props.updateSubRes.result}`, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    setRedirect(true)
                },
            });
        } else if (props.updateSubRes.statuscode === 405) {
            setIsToastVisible(true);
            toast.error(`❌ ${props.updateSubRes.error}`, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    window.location.reload();
                },
            });
        }
    }, [props.updateSubRes]);

    if (redirect) {
        return <Redirect to='/dashboard/view_subscription' />;
    }

    return (
        <Container fluid >
            {isToastVisible && <div className="overlay" />}
            <ToastContainer
                position="top-center"
                autoClose={20}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ top: "10%", width: "auto", zIndex: "9999" }}
            />
            <PureBreadcrumbs />
            <Row className='mt-3'>
                <Col>
                    <Card className='tariffcard'>
                        <div className='table-header'>{t("Update Subscription")}</div>
                        <Card.Body>
                            <form autoComplete='off' onSubmit={(e) => e.preventDefault()}>
                                <Row>
                                    <Col lg={12}>
                                        <div className="form-floating">
                                            <label>
                                                {t("Subscription Name")}
                                                <span className="mandatory-field">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className={`form-control gray-border ${errors.subscriptionName ? "is-invalid" : ""
                                                    }`}
                                                name={`subscriptionName`}
                                                id="floatingInputsubscriptionName"
                                                placeholder={t("Subscription Name")}
                                                {...register(`subscriptionName`)}
                                                disabled
                                            />
                                            <div className="invalid-feedback">
                                                {errors.subscriptionName?.message}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="row g-2 mt-3 ">
                                    <div className="col-md">
                                        <div className="form-floating">
                                            <label>
                                                {t("Subscription Type")}
                                                <span className="mandatory-field">*</span>
                                            </label>
                                            <select
                                                className={`form-select form-control ${errors.subscriptionType ? "is-invalid" : ""}`}
                                                name={`subscriptionType`}
                                                id="floatingSelectGridsubscriptionType"
                                                {...register(`subscriptionType`)}
                                            >
                                                <option value={""}>{t("Select Subscription Type")}</option>
                                                {props.dropDownRes && props.dropDownRes.ruletype?.map((v, k) => (
                                                    <option key={v.key} value={v.value}>{v.key}</option>
                                                ))}

                                            </select>
                                        </div>
                                        <div className="invalid-feedback">
                                            {errors.subscriptionType?.message}
                                        </div>
                                    </div>
                                    <div className='col-md'>
                                        <div className="form-floating">
                                            <label>
                                                {t("Subscription Value")}
                                                <span className="mandatory-field">*</span>
                                            </label>
                                            <input
                                                type="number"
                                                className={`form-control gray-border ${errors.subscriptionValue ? "is-invalid" : ""
                                                    }`}
                                                name={`subscriptionValue`}
                                                id="floatingInputSubscriptionValue"
                                                placeholder={t("Subscription Value")}
                                                {...register(`subscriptionValue`)}
                                                onKeyPress={HandleKeyPress}
                                            />
                                            {watch(`subscriptionType`) === "" && (
                                                <small className="form-text text-muted">
                                                    {t("")}
                                                </small>
                                            )}
                                            {watch(`subscriptionType`) === "kwh" && (
                                                <small className="form-text text-muted">
                                                    {t("Free Energy")}
                                                </small>
                                            )}
                                            {watch(`subscriptionType`) === "time" && (
                                                <small className="form-text text-muted">
                                                    {t("Free No. Of Hours")}
                                                </small>
                                            )}
                                            {watch(`subscriptionType`) === "flat" && (
                                                <small className="form-text text-muted">
                                                    {t("Discount in ( % ) / Charging")}
                                                </small>
                                            )}
                                            <div className="invalid-feedback">
                                                {errors.subscriptionValue?.message}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md">
                                        <div className="form-floating">
                                            <label>
                                                {t("Subscription Duration")}
                                                <span className="mandatory-field">*</span>
                                            </label>
                                            <select
                                                className={`form-select form-control ${errors.subscriptionDuration ? "is-invalid" : ""}`}
                                                name={`subscriptionDuration`}
                                                id="floatingSelectGridsubscriptionDuration"
                                                {...register(`subscriptionDuration`)}
                                            >
                                                <option value={""}>{t("Select Subscription Duration")}</option>
                                                <option value={"weekly"}>{t("Weekly")}</option>
                                                <option value={"monthly"}>{t("Monthly")}</option>
                                                <option value={"yearly"}>{t("Yearly")}</option>
                                                {/* {props.dropDownRes && props.dropDownRes.ruletype?.map((v, k) => (
                                            <option key={v.key} value={v.key}>{v.key}</option>
                                        ))} */}

                                            </select>
                                        </div>
                                        <div className="invalid-feedback">
                                            {errors.subscriptionDuration?.message}
                                        </div>
                                    </div>
                                </div>
                                <Row className='mt-3 '>
                                    <Col lg={12}>
                                        <div className="form-floating">
                                            <label>
                                                {t("Recharge Amount")}
                                                <span className="mandatory-field">*</span>
                                            </label>
                                            <input
                                                type="number"
                                                className={`form-control gray-border ${errors.rechargeAmount ? "is-invalid" : ""
                                                    }`}
                                                name={`rechargeAmount`}
                                                id="floatingInputrechargeAmount"
                                                placeholder={t("Recharge Amount")}
                                                {...register(`rechargeAmount`)}
                                                onKeyPress={HandleKeyPress}

                                            />
                                            <div className="invalid-feedback">
                                                {errors.rechargeAmount?.message}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <div className='col-12 mt-2'>
                                        <div className="form-floating">
                                            <label>{t("Description")}</label>
                                            <textarea
                                                className={`form-control`}
                                                placeholder={t("Description...")}
                                                aria-label={t("Description")}
                                                name="description"
                                                id="floatingTextarea"
                                                style={{ "height": "120px" }}
                                                {...register("description")}>
                                            </textarea>
                                        </div>
                                    </div>
                                </Row>
                            </form>
                        </Card.Body>
                    </Card>
                    <Card.Footer className='mb-4'>
                        <Row className='d-flex justify-content-end'>
                            <Col lg={3} md={6} sm={6} xs={12} className="mb-2">
                                {props.loading ?
                                    <button className="btn lgn-btn" type="button" disabled>
                                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                        &nbsp;
                                        {t("Saving...")}
                                    </button>
                                    :
                                    <button
                                        className="lgn-btn btn w-100"
                                        type="submit"
                                        onClick={handleSubmit(onSubmit)}
                                    >
                                        {t("UPDATE")}
                                    </button>
                                }
                            </Col>
                        </Row>
                    </Card.Footer>

                </Col>
            </Row>
        </Container>
    )
}

const mapStateToProps = (state) => {
    return {
        loading: state.wallet.isLoading,
        updateSubRes: state.wallet.updateSub,
        dropDownRes: state.loyalty.fetchRuleDropdown,
        fetchSingleRes: state.wallet.fetchSingleSub,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchDropdown: () => dispatch(fetchRuleDropdownApi()),
        updateSubApi: (data) => dispatch(updateSubscriptionApi(data)),
        fetchSingle: (name) => dispatch(fetchSingleSubscriptionApi(name)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateSubscription)