import React from "react";
import { useEffect } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import PureBreadcrumbs from "../../breadcrums";
import "../loyalty.css";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { fetchSingleRuleApi } from "../../../../store/actions/loyaltyAction";

const ViewSingleRule = (props) => {
    const { rule_name } = useParams();
    const { t } = useTranslation();
    // console.log(rule_name);

    useEffect(() => {
        props.fetchSingle(rule_name)
    }, [])

    const data = props.fetchSingleRes && props.fetchSingleRes.data[0];
    // console.log(data);

    return (
        <fieldset disabled>
            <div className="container-fluid">
                <PureBreadcrumbs />
                <Row className="d-flex justify-content-center align-items-center pt-5">
                    <Col xs={12} md={12} lg={12}>
                        <form onSubmit={(e) => e.preventDefault()}>
                            <div>
                                <Card className="d-flex justify-content-center align-items-center">
                                    <Card.Body>
                                        <h6 className="text-center"> {t("View Single Rules")}</h6>
                                    </Card.Body>
                                </Card>
                                <Row className="tariffcard ml-0 mr-0">
                                    <Col lg={12}>
                                        <div className="col-lg">
                                            <div className="form-floating">
                                                <label>
                                                    {t("Rule Name")}
                                                    <span className="mandatory-field">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className={`form-control gray-border`}
                                                    name={`rule_name`}
                                                    id="floatingInputplan_name"
                                                    placeholder={t("Rule Name")}
                                                    value={data?.rule_name || ''}
                                                    readOnly
                                                />

                                            </div>
                                        </div>
                                    </Col>

                                </Row>

                                {data?.components?.map((item, i) => (
                                    <Card className="mb-1" key={i + 1}>
                                        <Card.Body>
                                            <div className="row g-2 mt-3 ">
                                                <div className="col-md">
                                                    <div className="form-floating">
                                                        <label>
                                                            {t("Rule Type")}
                                                            <span className="mandatory-field">*</span>
                                                        </label>
                                                        <select
                                                            className={`form-select form-control`}
                                                            name={`ruleGroup[${i}].rule_type`}
                                                            id="floatingSelectGridrule_type"
                                                        >
                                                            <option>{t(item.ruletype)}</option>

                                                        </select>
                                                    </div>
                                                </div>


                                                <div className="col-md">
                                                    <div className="form-floating">
                                                        {item?.ruletype && item?.ruletype === '' ? <label>
                                                            {t("Rule Value")}
                                                            <span className="mandatory-field">*</span>
                                                        </label>
                                                            : item?.ruletype && item?.ruletype === 'kwh' ?
                                                                <label>
                                                                    {t("Rule Value ( kWh )")}
                                                                    <span className="mandatory-field">*</span>
                                                                </label>
                                                                : item?.ruletype && item?.ruletype === 'time' ?
                                                                    <label>
                                                                        {t("Rule Value ( Hours )")}
                                                                        <span className="mandatory-field">*</span>
                                                                    </label>
                                                                    : item?.ruletype && item?.ruletype === 'flat' ?
                                                                        <label>
                                                                            {t("Rule Value ( Flat )")}
                                                                            <span className="mandatory-field">*</span>
                                                                        </label> : ''
                                                        }
                                                        <select
                                                            className={`form-select form-control`}
                                                            name={`ruleGroup[${i}].rule_value`}
                                                            id="floatingSelectGridperiod_end"
                                                        >
                                                            <option>{t(item.rulevalue)}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-sm mt-4" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <div className="form-floating" >
                                                        {t("Contains")}
                                                    </div>

                                                </div>
                                                <div className="col-md">
                                                    <div className="form-floating">
                                                        <label>
                                                            {t("Input value points")}
                                                            <span className="mandatory-field">*</span>
                                                        </label>
                                                        <select
                                                            className={`form-select form-control `}
                                                            name={`ruleGroup[${i}].input_value`}
                                                            id="floatingSelectGridperiod_end"
                                                        >
                                                            <option>{t(item.points)}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                ))}
                            </div>
                        </form>
                    </Col>
                </Row>
            </div>
        </fieldset>
    );
};

const mapStateToProps = (state) => {
    return {
        loading: state.loyalty.isLoading,
        fetchSingleRes: state.loyalty.fetchSingleRule,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        fetchSingle: (rule_name) => dispatch(fetchSingleRuleApi(rule_name)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewSingleRule);
