import React, { useEffect, useMemo } from "react";
import { Row, Col, Card, Container } from "react-bootstrap";
import "react-dates/initialize";
import "react-datepicker/dist/react-datepicker.css";
import Table from "../../reacttable/table";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { FaEdit, FaEye } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { AiOutlineCloseCircle } from "react-icons/ai";
import moment from "moment";
import { activateProgramApi, deactivateProgramApi, fetchAllProgramApi } from "../../../../store/actions/loyaltyAction";
import PureBreadcrumbs from "../../breadcrums";

function ViewProgramList(props) {
  const { t } = useTranslation();

  useEffect(() => {
    props.fetchAllProgram();
  }, [])

  const data = props.allProgramRes && props.allProgramRes.data;
  // console.log(data);
  const total = data && data.length;
  const active = data && data.filter((item) => item.status === "Active").length;
  const draft = data && data.filter((item) => item.status === "Draft").length;
  const inActive = data && data.filter((item) => item.status === "Inactive").length;
  // console.log("total", total);
  // console.log("active", active);
  // console.log("draft", draft);
  // console.log("inActive", inActive);
  const activateProgram = (name) => {
    // console.log(name);
    props.activateApi(name);
  };

  if (props.activateRes.statuscode === 200) {
    window.location.reload();
  } else if (props.activateRes.statuscode === 405) {
    alert(props.activateRes.error)
    window.location.reload();
  }

  const deactivateProgram = (name) => {
    // console.log(name);
    props.deactivateApi(name);
  };

  if (props.deactivateRes.statuscode === 200) {
    window.location.reload();
  } else if (props.deactivateRes.statuscode === 405) {
    alert(props.deactivateRes.error)
    window.location.reload();
  }

  const columns = useMemo(
    () => [
      {
        Header: "Program Name",
        id: "1",
        accessor: "program_name",
      },
      {
        Header: "Rule Name",
        accessor: "rule_name",
      },
      {
        Header: "Start Date",
        id: "startdate",
        accessor: (d) => {
          return moment(d.startdate).format("DD-MM-YYYY");
        },

      },
      {
        Header: "End Date",
        id: "enddate",
        accessor: (d) => {
          return moment(d.enddate).format("DD-MM-YYYY");
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: (status) => {
          return (
            <div>
              <span>
                {status.value === "Active" ? (
                  <span className="text-success">{t("Active")}</span>
                ) : status.value === "Inactive" ? (
                  <span className="text-danger">{t("Inactive")}</span>
                ) : (
                  <span className="text-warning">{t("Draft")}</span>
                )}
              </span>
            </div>
          );
        },
      },
      {
        Header: "Actions",
        accessor: "program_name",
        Cell: (program_name) => {
          return (
            <div>
              <span>
                <a
                  href={`/dashboard/view_program/singleProgram/${program_name.value}`}
                  rel="tooltip"
                  title="View"
                >
                  <FaEye />
                </a>
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <span>
                <a
                  href={`/dashboard/updateProgram/${program_name.value}`}
                  rel="tooltip"
                  title="Edit"
                >
                  <FaEdit />
                </a>
              </span>
              &nbsp;&nbsp;  &nbsp;&nbsp;
              <span style={{ cursor: "pointer" }}>
                <TiTick
                  onClick={() => activateProgram(program_name.value)}
                  title="Activate"
                />
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <span style={{ cursor: "pointer" }}>
                <AiOutlineCloseCircle
                  onClick={() => deactivateProgram(program_name.value)}
                  title="De-Activate"
                />
              </span>
            </div>
          );
        },
      },

    ],
    []
  );
  return (
    <>
      <Container fluid className="pb-4">
        <PureBreadcrumbs />
        <Row className="mt-2">
          <Col className="col-md-3 col-12">
            <Card className="customercard card-custom total-rules">
              <div className="diagonal-line"></div>
              <div style={{ textAlign: "left" }}>
                <Card.Text style={{ fontSize: "16px" }}>{t("TOTAL PROGRAMS")}</Card.Text>
                <Card.Title>
                  <b>{total != undefined ? total : 0}</b>
                </Card.Title>
              </div>
            </Card>
            <br />
          </Col>
          <Col className="col-md-3 col-12">
            <Card className="customercard card-custom total-active-rules">
              <div className="diagonal-line"></div>
              <div style={{ textAlign: "left" }}>
                <Card.Text style={{ fontSize: "16px" }}>{t("TOTAL ACTIVE PROGRAMS")}</Card.Text>
                <Card.Title>
                  <b>{active != undefined ? active : 0}</b>
                </Card.Title>
              </div>
            </Card>
            <br />
          </Col>
          <Col className="col-md-3 col-12">
            <Card className="customercard card-custom total-inactive">
              <div className="diagonal-line"></div>
              <div style={{ textAlign: "left" }}>
                <Card.Text style={{ fontSize: "16px" }}>{t("TOTAL INACTIVE PROGRAMS")}</Card.Text>
                <Card.Title>
                  <b>{inActive != undefined ? inActive : 0}</b>
                </Card.Title>
              </div>
            </Card>
            <br />
          </Col>
          <Col className="col-md-3 col-12">
            <Card className="customercard card-custom total-draft">
              <div className="diagonal-line"></div>
              <div style={{ textAlign: "left" }}>
                <Card.Text style={{ fontSize: "16px" }}>{t("TOTAL DRAFT PROGRAMS")}</Card.Text>
                <Card.Title>
                  <b>{draft != undefined ? draft : 0}</b>
                </Card.Title>
              </div>
            </Card>
            <br />
          </Col>
        </Row>


        <Row className="mt-1">
          <Col lg={12}>
            <Card className="mt-3 customercard ">
              <div className="table-header">{t("View Program List")}</div>

              <Card.Body>
                <div className="evse">
                  {props.loading ? <h3 style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "250px",
                  }}>Loading data...</h3> :
                    props.allProgramRes && props.allProgramRes.data != null ? (
                      <div>
                        <Table data={data} columns={columns} ></Table>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "250px",
                        }}
                      >
                        <h3>{t("No data found")}</h3>
                      </div>
                    )}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )

}
const mapStateToProps = (state) => {
  return {
    loading: state.loyalty.isLoading,
    allProgramRes: state.loyalty.fetchAllProgram,
    activateRes: state.loyalty.activateProgram,
    deactivateRes: state.loyalty.deactivateProgram,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchAllProgram: () => dispatch(fetchAllProgramApi()),
    activateApi: (program_name) => dispatch(activateProgramApi(program_name)),
    deactivateApi: (program_name) => dispatch(deactivateProgramApi(program_name)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewProgramList);

