import { WALLET_URL, FETCH_ALL_WALLET_API, FETCH_ALL_CUSTOMER_TRANSACTION_API, FETCH_CUSTOMER_WALLET_BALANCE,WALLET_ACTIVE_INACTIVE_API,UPDATE_CUSTOMER_WALLET_API, SAVE_SUBSCRIPTION_API, FETCH_ALL_SUBSCRIPTION, FETCH_SINGLE_SUBSCRIPTION, UPDATE_SUBSCRIPTION_API, ACTIVATE_SUBSCRIPTION_API, DEACTIVATE_SUBSCRIPTION_API, FETCH_SINGLE_USER_SUBSCRIPTION_HISTORY } from "../../components/config/config";
import { ApiHandler } from "./apiHandler";

export const allCustomerWalletAction = (start, end) => {
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    const operator = role === 'Admin' ? 'All' : organisation;
    const bearer = 'basic ' + token
    const functionName = 'allCustomerWalletAction'
    return async dispatch => {
        dispatch({ type: 'LOAD_WALLET', payload: '' })
        return fetch(WALLET_URL + FETCH_ALL_WALLET_API + "?start=" + start + "&end=" + end, {
            functionName,
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            console.log("qeuifbuiefbeuiw",data)
            dispatch({ type: 'FETCH_WALLET_DATA', payload: data })
        })
    }
}

export const allCustomerWalletCardCountAction = (page, limit) => {
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    const operator = role === 'Admin' ? 'All' : organisation;
    const bearer = 'basic ' + token
    const functionName = 'allCustomerWalletCardCountAction'
    return async dispatch => {
        dispatch({ type: 'LOAD_WALLET', payload: '' })
        const data = await ApiHandler(WALLET_URL + FETCH_ALL_WALLET_API + "?operator=" + operator + "&page=" + page + "&limit=" + limit, {
            functionName,
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("FETCH_CUSTOMER_WALLET_CARD_COUNT_REDUCER", data)
            dispatch({ type: 'FETCH_CUSTOMER_WALLET_CARD_COUNT_REDUCER', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const allCustomerTransactionAction = () => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_WALLET', payload: '' })
        return fetch(WALLET_URL + FETCH_ALL_CUSTOMER_TRANSACTION_API, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log(data)
            //dispatch({ type: 'FETCH_CUSTOMER_TRANSACTION_DATA', payload: data })
            dispatch({ type: 'FETCH_WALLET_DATA', payload: data })
        })
    }
}


export const SingleCustomerWalletAction = (userid, start, end) => {
    //console.log(userid, start,end);
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_WALLET', payload: '' })
        return fetch(WALLET_URL + FETCH_ALL_CUSTOMER_TRANSACTION_API + "?userid=" + userid + "&start=" + start + "&end=" + end, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        .then(resp => resp.json()).then(data => {
            // console.log(data)
            //dispatch({ type: 'FETCH_CUSTOMER_TRANSACTION_DATA', payload: data })
            dispatch({ type: 'FETCH_SINGLE_WALLET', payload: data })
        })
    }
}

export const SingleCustomerWalletHistoryAction = (userid) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_WALLET', payload: '' })
        return fetch(WALLET_URL + FETCH_CUSTOMER_WALLET_BALANCE + "?userid=" + userid, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log(data);
            dispatch({ type: 'FETCH_SINGLE_WALLET_HISTORY', payload: data })
        })
    }
}

export const updateCustomerWalletAction = (userid, amount, id) => {
    // console.log(userid, amount, id)
    const { token } = JSON.parse(localStorage.getItem('user'));
    // console.log(WALLET_URL + UPDATE_CUSTOMER_WALLET_API)
    const bearer = 'basic ' + token
    const functionName = 'updateCustomerWalletAction'
    return async dispatch => {
        dispatch({ type: 'LOAD_WALLET', payload: '' })
        const data = await ApiHandler(WALLET_URL + UPDATE_CUSTOMER_WALLET_API, {
            functionName,
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "userid": userid,
                "amount": Number(amount),
                // "sessionid": id,
                "status": "Success",

            })
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'UPDATE_CUST_W_DATA', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}


export const walletActivateInactivateAction = (userid, status) => {
    console.log("userid,statususerid,status", userid, status)
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    console.log(WALLET_URL + WALLET_ACTIVE_INACTIVE_API + "?userid=" + userid + "&status=" + status)
    return dispatch => {
        dispatch({ type: 'LOAD_WALLET', payload: '' })
        return fetch(WALLET_URL + WALLET_ACTIVE_INACTIVE_API + "?userid=" + userid + "&status=" + status, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            console.log("hrbvgurbguwrgbkwr,",data)
            dispatch({ type: 'WALLET_ACTIVE_INACTIVE', payload: data })
        })
    }
}


//  SUBSCRIPTION 

export const saveSubscriptionApi = (data) => {
    // console.log("Data from action",data)
    const { subscriptionType, subscriptionName, subscriptionValue, subscriptionDuration, rechargeAmount, description } = data;
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'saveSubscriptionApi'
    return async dispatch => {
        dispatch({ type: 'LOAD_WALLET', payload: '' })
        const data = await ApiHandler(WALLET_URL + SAVE_SUBSCRIPTION_API, {
            functionName,
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "sub_name": subscriptionName,
                "sub_type": subscriptionType,
                "sub_value": Number(subscriptionValue),
                "sub_duration": subscriptionDuration,
                "recharge_amount": Number(rechargeAmount),
                "description": description,
                "role": role,
                "operator": organisation
            })
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'SAVE_SUBSCRIPTION_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}
export const updateSubscriptionApi = (data) => {
    // console.log("Data from action",data)
    const { subscriptionName, subscriptionType, subscriptionValue, subscriptionDuration, rechargeAmount, description } = data;
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'updateSubscriptionApi'
    return async dispatch => {
        dispatch({ type: 'LOAD_WALLET', payload: '' })
        const data = await ApiHandler(WALLET_URL + UPDATE_SUBSCRIPTION_API, {
            functionName,
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "sub_name": subscriptionName,
                "sub_type": subscriptionType,
                "sub_value": Number(subscriptionValue),
                "sub_duration": subscriptionDuration,
                "recharge_amount": Number(rechargeAmount),
                "description": description,
                "role": role,
                "operator": organisation
            })
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'UPDATE_SUBSCRIPTION_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const fetchAllSubscriptionApi = () => {
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'fetchAllSubscriptionApi'
    return async dispatch => {
        dispatch({ type: 'LOAD_WALLET', payload: '' })
        const data = await ApiHandler(WALLET_URL + FETCH_ALL_SUBSCRIPTION + "?role=" + role + "&operator=" + organisation, {
            functionName,
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'FETCH_ALL_SUBSCRIPTION_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}
export const fetchSingleSubscriptionApi = (name) => {
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'fetchSingleSubscriptionApi'
    return async dispatch => {
        dispatch({ type: 'LOAD_WALLET', payload: '' })
        const data = await ApiHandler(WALLET_URL + FETCH_SINGLE_SUBSCRIPTION + "?name=" + name, {
            functionName,
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'FETCH_SINGLE_SUBSCRIPTION_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}
export const activateSubscriptionApi = (sub_name) => {
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'activateSubscriptionApi'
    return async dispatch => {
        dispatch({ type: 'LOAD_WALLET', payload: '' })
        const data = await ApiHandler(WALLET_URL + ACTIVATE_SUBSCRIPTION_API + "?name=" + sub_name, {
            functionName,
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'ACTIVATE_SUBSCRIPTION_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}
export const deactivateSubscriptionApi = (sub_name) => {
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'deactivateSubscriptionApi'
    return async dispatch => {
        dispatch({ type: 'LOAD_WALLET', payload: '' })
        const data = await ApiHandler(WALLET_URL + DEACTIVATE_SUBSCRIPTION_API + "?name=" + sub_name, {
            functionName,
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'DEACTIVATE_SUBSCRIPTION_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}
export const fetchUserSubscriptionApi = (userid) => {
    const { token, organisation } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'fetchUserSubscriptionApi'
    return async dispatch => {
        dispatch({ type: 'LOAD_WALLET', payload: '' })
        const data = await ApiHandler(WALLET_URL + FETCH_SINGLE_USER_SUBSCRIPTION_HISTORY + "?userid=" + userid, {
            functionName,
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'FETCH_USER_SUBSCRIPTION_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}