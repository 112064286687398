import React, { useMemo, useState, useEffect } from "react";
import { Card, Row, Col, Container } from "react-bootstrap";
import { fetchAllRevenueSharingReportDataAction, fetchRevenueSharingReport } from "../../../store/actions/reportActions";
import { connect } from "react-redux";
import PureBreadcrumbs from "../breadcrums";
import "react-dates/initialize";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Table from "../reacttable/table";
import moment from "moment";
import { CSVLink } from "react-csv";
import MyRevenueDocument from "../pdf/revenue_invoice";
import RevenueVendorInvoiceDocument from "../pdf/revenue_vendor_invoice";
import { useTranslation } from "react-i18next";
import CustomReportPaginationTable from "./adminReport/customReportPaginationTable";

function RevenueSharingList(props) {
    const { t } = useTranslation();
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [search, setSearch] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const document_count = props.revenue_sharing_report && props.revenue_sharing_report.document_count;
    const defaultPageSize = 10;
    const pageCount = Math.ceil(document_count / defaultPageSize)

    useEffect(() => {
        props.revenueSharingReport(startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10), currentPage, pageSize)
        props.fetchAllRevenueSharingApi(startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10), 1, 10000000)
    }, [currentPage, pageSize]);

    const [startDate, setStartDate] = useState(
        new Date(Date.now() - 3600 * 1000 * 720)
    );
    const [endDate, setEndDate] = useState(
        new Date(Date.now() + 3600 * 1000 * 24)
    );
    // const [org, setOrg] = useState("All");

    const loadNewData = () => {
        props.revenueSharingReport(startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10), currentPage, pageSize);
        props.fetchAllRevenueSharingApi(startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10), 1, 10000000);
    };

    const data = props.revenue_sharing_report && props.revenue_sharing_report.data
    const allData = props.fetch_all_revenue_sharing_Data_res && props.fetch_all_revenue_sharing_Data_res.data
    // console.log("revenue data", data)

    const sortedData = data && data?.sort((a, b) => new Date(b.start_date) - new Date(a.start_date));
    const allSortedData = allData && allData?.sort((a, b) => new Date(b.start_date) - new Date(a.start_date));
    // console.log("allSortedData", allSortedData)

    const columns = useMemo(
        () => [

            {
                Header: "Location",
                id: "location",
                accessor: "location",
                Cell: (item) => {
                    const value = item?.item?.ID?.location
                    // console.log("value: ", value)
                    return (
                        <span>{value}</span>
                    )

                },
            },
            {
                Header: "City",
                accessor: "city",
                Cell: (item) => {
                    const value = item?.item?.ID?.city
                    // console.log("value: ", value)
                    return (
                        <span>{value}</span>
                    )

                },
            },
            {
                Header: "Operator",
                accessor: "operator",
                Cell: (item) => {
                    const operator = item?.item?.ID?.operator
                    // console.log("operator: ", operator)
                    return (
                        <span>{operator}</span>
                    )
                },
            },
            // {
            //     Header: "Start Date",
            //     id: "start_date",
            //     accessor: "start_date",
            //     Cell: (item) => {
            //         const start_date = item?.item?.ID?.start_date
            //         // console.log("start_date: ", start_date)
            //         return (
            //             <span>{moment(start_date).local().format("DD-MM-YYYY")}</span>
            //         )
            //     },

            // },
            // {
            //     Header: "End Date",
            //     id: "end_date",
            //     accessor: (d) => {
            //         return moment(d.end_date).local().format("DD-MM-YYYY");
            //     },
            // },
            {
                Header: "Per Unit kWh",
                accessor: "per_unit_kwh",
                Cell: (item) => {
                    const per_unit_kwh = item?.item?.ID?.per_unit_kwh
                    // console.log("per_unit_kwh: ", per_unit_kwh)
                    return (
                        <span>{per_unit_kwh}</span>
                    )
                },
            },
            {
                Header: "Unit Consumed",
                accessor: "kwh",
                Cell: (item) => {
                    const kwh = item?.item?.kwh
                    // console.log("kwh: ", kwh)
                    return (
                        <span>{kwh}</span>
                    )
                },
            },
            {
                Header: "Client Revenue",
                accessor: "client_revenue_share",
                Cell: (item) => {
                    const client_revenue_share = item?.item?.ID?.client_revenue_share
                    // console.log("client_revenue_share: ", client_revenue_share)
                    return (
                        <span>{client_revenue_share}</span>
                    )
                },
            },
            {
                Header: "Regional Revenue",
                accessor: "regional_revenue_share",
                Cell: (item) => {
                    const regional_revenue_share = item?.item?.ID?.regional_revenue_share
                    // console.log("regional_revenue_share: ", regional_revenue_share)
                    return (
                        <span>{regional_revenue_share}</span>
                    )
                },
            },
            {
                Header: "Self Revenue",
                accessor: "self_revenue_share",
                Cell: (item) => {
                    const self_revenue_share = item?.item?.ID?.self_revenue_share
                    // console.log("self_revenue_share: ", self_revenue_share)
                    return (
                        <span>{self_revenue_share}</span>
                    )
                },
            },
            {
                Header: "Stripe ID",
                accessor: "stripe_account",
                Cell: (item) => {
                    const stripe_account = item?.item?.stripe_account
                    // console.log("stripe_account: ", stripe_account)
                    return (
                        <span>{stripe_account}</span>
                    )
                },
            },
            {
                Header: "Total Amount",
                accessor: "total_amount",
                Cell: (item) => {
                    const total_amount = item?.item?.total_amount
                    // console.log("total_amount: ", total_amount)
                    return (
                        <span>{total_amount}</span>
                    )
                },
            },

        ],
        []
    );

    const headers = [
        { label: "Location", key: "ID.location" },
        { label: "Operator", key: "ID.operator" },
        { label: "City", key: "ID.city" },
        { label: "Unit Consumed", key: "kwh" },
        { label: "Per Unit kWh", key: "ID.per_unit_kwh" },
        { label: "Client Revenue", key: "ID.client_revenue_share" },
        { label: "Self Revenue", key: "ID.self_revenue_share" },
        { label: "Total Amount", key: "total_amount" },
    ];

    const initialVisibleColumns =
        [
            'location', 'city', 'operator', 'kwh', 'per_unit_kwh',
            'client_revenue_share', 'self_revenue_share', 'total_amount',
            'stripe_account', 'regional_revenue_share', 'start_date', 'end_date'
        ];


    return (
        <Container fluid className="pb-4">
            <PureBreadcrumbs />
            <Row className="mt-4">
                <Col>
                    <Card className="customercard">
                        <Card.Header>
                            <Row>
                                <Col md={4} sm={12}>
                                    {t("Revenue Sharing Report")}
                                </Col>
                                <Col md={8} sm={12}>
                                    <div className="row justify-content-end">
                                        <div className="row justify-content-center">
                                            <div className="col-auto claender">
                                                <DatePicker
                                                    className="gray-border"
                                                    selected={startDate}
                                                    onChange={(date) => setStartDate(date)}
                                                    selectsStart
                                                    startDate={startDate}
                                                    dateFormat="dd/MM/yyyy"
                                                    // maxDate={new Date()}
                                                    minDate={new Date('2024-09-30')}
                                                />
                                            </div>
                                            <span style={{ paddingTop: "7px" }}> To </span>
                                            <div className="col-auto">
                                                <DatePicker
                                                    className="gray-border"
                                                    selected={endDate}
                                                    onChange={(date) => setEndDate(date)}
                                                    selectsEnd
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    // minDate={startDate}
                                                    showDisabledMonthNavigation
                                                    dateFormat="dd/MM/yyyy"
                                                    // minDate={new Date()}
                                                    minDate={new Date('2024-09-30')}
                                                />
                                            </div>
                                        </div>
                                        <div className="csvbutton ml-3 ">
                                            <button className="btn btn-sm" onClick={loadNewData}>
                                                <span
                                                    style={{
                                                        fontSize: "16px",
                                                        color: "#676767",
                                                        fontWeight: "600",
                                                    }}
                                                >
                                                    GO
                                                </span>
                                            </button>
                                        </div>
                                        <div className="mt-1 ">
                                            {props.fetch_all_revenue_sharing_Data_res && props.fetch_all_revenue_sharing_Data_res.data != null ? (
                                                <CSVLink
                                                    data={allSortedData}
                                                    headers={headers}
                                                    filename={`Revenue Sharing Report.csv`}
                                                    target="_blank"
                                                >
                                                    <i className="fas fa-2x fa-file-csv"></i>
                                                </CSVLink>
                                            ) : (
                                                <i className="fas fa-2x fa-file-csv"></i>
                                            )}
                                        </div>
                                        <Col className="col-md-10 col-sm-12 d-flex justify-content-end mt-3">

                                            <div style={{ paddingRight: "15px" }}>
                                                {props.fetch_all_revenue_sharing_Data_res && props.fetch_all_revenue_sharing_Data_res.data != null ? (
                                                    <MyRevenueDocument
                                                        {...props}
                                                        start={startDate}
                                                        end={endDate}
                                                        allSortedData={allSortedData}
                                                    ></MyRevenueDocument>
                                                ) : (
                                                    <i className="fas fa-file-pdf"></i>
                                                )}
                                            </div>
                                            <div>
                                                {props.fetch_all_revenue_sharing_Data_res && props.fetch_all_revenue_sharing_Data_res.data != null ? (
                                                    <RevenueVendorInvoiceDocument
                                                        {...props}
                                                        start={startDate}
                                                        end={endDate}
                                                        allSortedData={allSortedData}
                                                    ></RevenueVendorInvoiceDocument>
                                                ) : (
                                                    <i className="fas fa-file-pdf"></i>
                                                )}
                                            </div>
                                        </Col>
                                    </div>

                                </Col>
                            </Row>
                        </Card.Header>
                        <div className="evse">
                            {props.loading ? <h3 style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "250px",
                            }}>Loading data...</h3> :
                                props.revenue_sharing_report && props.revenue_sharing_report.data != null ? (
                                    <div>
                                        {/* <Table data={sortedData} columns={columns}></Table> */}
                                        <CustomReportPaginationTable
                                            data={sortedData}
                                            allData={allSortedData}
                                            pageCount={pageCount}
                                            columns={columns}
                                            search={search}
                                            setSearch={setSearch}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            pageSize={pageSize}
                                            setPageSize={setPageSize}
                                            filteredData={filteredData}
                                            setFilteredData={setFilteredData}
                                            loading={props.loading}
                                            initialVisibleColumns={initialVisibleColumns}
                                            t={t}
                                        />
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <h3>No Data Found</h3>
                                    </div>
                                )}
                        </div>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

const mapStateToProps = (state) => {
    return {
        loading: state.report.isLoading,
        revenue_sharing_report: state.report.revenueSharingList,
        fetch_all_revenue_sharing_Data_res: state.report.fetchAllRevenueSharingList,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        revenueSharingReport: (start, end, currentPage, pageSize) => dispatch(fetchRevenueSharingReport(start, end, currentPage, pageSize)),
        fetchAllRevenueSharingApi: (start, end, currentPage, pageSize) => dispatch(fetchAllRevenueSharingReportDataAction(start, end, currentPage, pageSize)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(RevenueSharingList);

