// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` .calendar {

     height: 2.5em;
 }

 .csvbutton {
     right: 22px !important;
     margin-right: 10px;
     background-color: #CECECE;
     border: 5px solid;
     border-color: #CECECE;
     border-radius: 20px;
     height: fit-content;
 }

 .fas {
     color: #676767;
 }

 .enterprise-pdf {
     font-size: xx-large;
     margin-top: 4px;
 }

 .custom-checkbox {
     font-size: 10px !important;
 }`, "",{"version":3,"sources":["webpack://./src/components/dashboard/report/report.css"],"names":[],"mappings":"CAAC;;KAEI,aAAa;CACjB;;CAEA;KACI,sBAAsB;KACtB,kBAAkB;KAClB,yBAAyB;KACzB,iBAAiB;KACjB,qBAAqB;KACrB,mBAAmB;KACnB,mBAAmB;CACvB;;CAEA;KACI,cAAc;CAClB;;CAEA;KACI,mBAAmB;KACnB,eAAe;CACnB;;CAEA;KACI,0BAA0B;CAC9B","sourcesContent":[" .calendar {\n\n     height: 2.5em;\n }\n\n .csvbutton {\n     right: 22px !important;\n     margin-right: 10px;\n     background-color: #CECECE;\n     border: 5px solid;\n     border-color: #CECECE;\n     border-radius: 20px;\n     height: fit-content;\n }\n\n .fas {\n     color: #676767;\n }\n\n .enterprise-pdf {\n     font-size: xx-large;\n     margin-top: 4px;\n }\n\n .custom-checkbox {\n     font-size: 10px !important;\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
