import React, { useMemo, useEffect } from "react";
import { connect } from "react-redux";
import PureBreadcrumbs from "../breadcrums";
import "../reacttable/reactTable.css";
import Table from "../reacttable/table";
import { Container, Card } from "react-bootstrap";
import { allEvseAction } from "../../../store/actions/evseAction";
import { useTranslation } from "react-i18next";

function AddBooking(props) {
  const { t } = useTranslation();
  useEffect(() => {
    props.all();
  }, []);
  const data = props.list && props.list.data;
  const filterData = data && data.filter((item) => item.status !== "Discovery")
  // console.log("filterData: " , filterData);

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Address",
        accessor: "address",
      },
      {
        Header: "City",
        accessor: "city",
      },
      {
        Header: "State",
        accessor: "state",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Commission Date",
        id: "date",
        accessor: (d) => {
          const date = new Date(d.date);
          const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
          return date.toLocaleDateString('en-GB', options);
        },
      },
      {
        Header: "Actions",
        accessor: "stationid",
        Cell: (rowinfo) => {
          // console.log("rowinfo",rowinfo)
          const { value } = rowinfo;

          return (
            <div>
              <span>
                <a
                  href={`/dashboard/managebooking/${value}`}
                  rel="tooltip"
                  title="Commands"
                >
                  <button className="btn commandButtons" type="button">
                    {t("Manage Booking")}
                  </button>
                </a>
              </span>
            </div>
          );

        },
      },
    ],
    []
  );

  return (
    <Container fluid>
      <div className="row">
        <div className="col-lg-6 col-sm-12">
          <PureBreadcrumbs />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-lg-12 col-sm-12">
          <Card className="customercard">
            <Card.Header>{t("Add charger booking slot")}</Card.Header>
            {props.loading ? <h3 style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "650px",
            }}>Loading data...</h3> :
              filterData && filterData.length > 0 ? (
                <div>
                  <Table data={filterData} columns={columns}></Table>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3 className="loader">{t("No data found")}</h3>
                </div>
              )}
          </Card>
        </div>
      </div>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.evse.isEvseLoading,
    list: state.evse.allevse,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    all: () => dispatch(allEvseAction()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddBooking);
