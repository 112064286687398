import React, { useEffect, useMemo, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import PureBreadcrumbs from "../../breadcrums";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import { fetchSubscriptionReportAction } from "../../../../store/actions/reportActions";
import Table from "../../reacttable/table";
function Subscription(props) {
  const currentDate = new Date();
  // const tomorrowDate = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000);
  const oneWeekInMilliseconds = 6 * 24 * 60 * 60 * 1000;
  const [selectedStartDate, setSelectedStartDate] = useState(new Date(currentDate.getTime() - oneWeekInMilliseconds));
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  const [Data, SetData] = useState([]);

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
  };

  const onLoad = () => {
    const formattedStartDate = selectedStartDate?.toISOString()?.split("T")[0];
    const formattedEndDate = selectedEndDate?.toISOString()?.split("T")[0];
    props.fetchSubscriptionReportApi(formattedStartDate, formattedEndDate);
  };

  useEffect(() => {
    SetData(props.fetchSubscriptionReportRes.data);
  }, [props.fetchSubscriptionReportRes]);

  // console.log(Data);

  const columns = useMemo(
    () => [
      {
        Header: "User Name",
        accessor: "userid",
      },
      {
        Header: "Subscription Name",
        accessor: "sub_name",
      },
      {
        Header: "Subscription Type",
        accessor: "sub_type",
      },
      {
        Header: "Subscription Value",
        accessor: "sub_value",
      },
      {
        Header: "Subscription Duration",
        accessor: "sub_duration",
      },
      {
        Header: "Recharge Amount",
        accessor: "recharge_amount",
      },
      {
        Header: "Subscription Remaining Value",
        accessor: "balance_value",
      },
    ],
    []
  );

  return (
    <Container fluid>
      <PureBreadcrumbs />
      <br />
      <Col>
        <Card className="tariffcard">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <p>Subscription Report</p>
            <div className="d-flex align-items-center ml-3">
              <DatePicker
                className="gray-border calendar"
                selectsStart
                startDate={selectedStartDate}
                selected={selectedStartDate}
                onChange={handleStartDateChange}
                maxDate={new Date()}
                dateFormat="dd/MM/yyyy"
              />
              <span className="mx-2">To</span>
              <DatePicker
                className="gray-border calendar"
                selectsStart
                startDate={selectedEndDate}
                selected={selectedEndDate}
                onChange={handleEndDateChange}
                minDate={selectedStartDate}
                maxDate={new Date()}
                dateFormat="dd/MM/yyyy"
              />
              <div className="csvbutton ml-3 ">
                <button className="btn btn-sm" onClick={onLoad}>
                  <span
                    style={{
                      fontSize: "16px",
                      color: "#676767",
                      fontWeight: "600",
                    }}
                  >
                    GO
                  </span>
                </button>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            {props.loading ? (
              <h3
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "250px",
                }}
              >
                Loading data...
              </h3>
            ) : Data && Data.length > 0 ? (
              <div>
                {" "}
                <Table data={Data} columns={columns}></Table>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h3 className="loader">No data found</h3>
              </div>
            )}
          </Card.Body>
        </Card>
      </Col>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  loading: state.report.loading,
  fetchSubscriptionReportRes: state.report.fetchSubscriptionReport,
});

const mapDispatchToProps = (dispatch) => ({
  fetchSubscriptionReportApi: (startDate, endDate) => dispatch(fetchSubscriptionReportAction(startDate, endDate)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Subscription);
