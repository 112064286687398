
import React, { useEffect } from "react";
import { useParams, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Card, Row, Col, Container } from "react-bootstrap";
import PureBreadcrumbs from "../breadcrums";
import { singleViewTicketAction } from "../../../store/actions/ticketAction";
import StepProgress from './stepProgressBar';
import { useTranslation } from "react-i18next";

function ViewSingleTicket(props) {
  const { t } = useTranslation();
  const { ticket_id } = useParams()
  // console.log("ticket_id : ", ticket_id);

  useEffect(() => {
    props.getSingleTicketData(ticket_id);
  }, []);

  const ticketData = props.fetchSingleTicketDetails && props.fetchSingleTicketDetails.data;
  // console.log("Ticket data: ", ticketData);

  return (
    <Container fluid className="pb-4">
      <PureBreadcrumbs />
      <Row className="mt-1">
        <Col>
          <Card className="tariffcard">
            <Card.Body>
              <h2 className='mb-'>{t("View Ticket")}</h2>
              <div className="d-flex justify-content-center">
                <StepProgress currentStatus={ticketData.status} />
              </div>
              <form onSubmit={e => e.preventDefault()} >

                <div className="">
                  <label htmlFor="floatingInputGridtitle">{t("Title")} </label>
                  <input className="input  gray-border" type="text" name="title" id="floatingInputGridtitle" placeholder="title" defaultValue={ticketData.title} disabled />
                </div><br />

                <div className="form-group">
                  <label htmlFor="affected_module">{t("Affected Module")}</label>
                  <div className="form-floating">
                    <input className="input  gray-border" type="text" name="affected_module" id="floatingSelectGridAffectedModule" placeholder="affected_module" defaultValue={ticketData.affected_module} disabled />

                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="ticket_type">{t("Issue Type")} </label>
                  <div className="form-floating">
                    <input className="input  gray-border" type="text" name="ticket_type" id="floatingSelectGridIssueType" placeholder="ticket_type" defaultValue={ticketData.ticket_type} disabled />
                  </div>
                </div>

                <div className="form-group">
                  <label>{t("Severity")} </label>
                  <br />
                  <div className="btn-group btn-group-lg btn-group-toggle p-2" data-toggle="buttons">
                    <label className={`btn radio-btn  p-3 pr-3 btn-light`} disabled>
                      <input
                        type="radio"
                        name="severity"
                        value="Low"
                        defaultValue={ticketData.severity}
                        id="severityLow"
                        disabled
                      />
                      {ticketData.severity ? `${ticketData.severity}` : "Unavailable"}

                    </label>
                  </div>

                </div>

                <div className="form-group">
                  <label htmlFor="status">{t("Assign to")} </label>
                  <div className="form-floating">
                    <input className="input  gray-border" type="text" name="assignedto" id="floatingSelectGridA" placeholder="assignedto" defaultValue={ticketData.assignedto} disabled />
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="status">{t("Current Status")} </label>
                  <div className="form-floating">
                    <input className="input  gray-border" type="text" name="status" id="floatingSelectGridS" placeholder="status" defaultValue={ticketData.status} disabled />
                  </div>
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="attachments">{t("Attachment (Image only)")}</label>
                  <div className="">
                    <React.Fragment>
                      {ticketData.attachments !== "" ?

                        <img
                          src={ticketData.attachments}
                          className={`object-fit-contain videoContainer`}
                          alt={ticketData.attachments}
                        />
                        :
                        <b>No advertisement available</b>
                      }
                    </React.Fragment>
                  </div>
                </div>

                <div className='row g-2 mt-3'>
                  <div className='col-md'>
                    <div className="form-floating">
                      <label htmlFor="floatingTextarea">{t("Description")}</label>
                      <textarea className="form-control" placeholder="Description" name="description" id="floatingTextarea" style={{ "height": "100px" }} defaultValue={ticketData.description} disabled></textarea>
                    </div>
                  </div>
                </div>
              </form>
            </Card.Body>

          </Card>
        </Col>
      </Row>
    </Container>
    // <p>a</p>
  )

}


const mapStateToProps = function (state) {
  return {
    fetchSingleTicketDetails: state.ticket.fetch_single_ticket,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    getSingleTicketData: (ticket_id) => dispatch(singleViewTicketAction(ticket_id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ViewSingleTicket));