
import { combineReducers } from 'redux';
import authReducer from "./authReducer";
import customerReducer from './customerReducer';
import evseReducer from './evseReducer';
import insightReducer from './insightReducer';
import mainReducer from './mainReducer';
import organisationReducer from './organisationReducer';
import tariffReducer from './tariffReducer';
import walletReducer from './walletReducer';
import accessReducer from "./accessReducer";
import reportReducer from './reportReducer';
import advertisementReducer from './advertisementReducer';
import commandReducer from './commandReducer';
import campaignReducer from './campaignReducer';
import fleetReducer from './fleetReducer';
import ocpiReducer from './ocpiReducer';
import loadReducer from './loadReducer';
import ticketReducer from './ticketReducer';
import stripeReducer from './stripeReducer';
import loyaltyReducer from './loyaltyReducers';

const rootReducer = combineReducers({
    auth: authReducer,
    insight: insightReducer,
    customer: customerReducer,
    wallet: walletReducer,
    tariff: tariffReducer,
    evse: evseReducer,
    operator: organisationReducer,
    main: mainReducer,
    access: accessReducer,
    report: reportReducer,
    advertise: advertisementReducer,
    command: commandReducer,
    campaign: campaignReducer,
    fleet: fleetReducer,
    ocpi: ocpiReducer,
    load: loadReducer,
    ticket: ticketReducer,
    stripe:stripeReducer,
    loyalty:loyaltyReducer,
});

export default rootReducer;