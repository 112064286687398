import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import rootReducer from "./store/reducers/rootReducers";
import "./i18n";
import TranslationLoader from './TranslationLoader';

const store = createStore(rootReducer, applyMiddleware(thunk));

createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <Suspense fallback={<div>Loading translations...</div>}> {/* Suspense added */}
      <BrowserRouter>
        <TranslationLoader /> {/* TranslationLoader wrapped inside Suspense */}
        <App />
      </BrowserRouter>
    </Suspense>
  </Provider>
);

serviceWorker.register();
