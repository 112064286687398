import React, { useMemo, useEffect } from "react"
import { connect } from "react-redux";
import { FaEdit, FaEye } from "react-icons/fa";
import "../../../reacttable/reactTable.css"
import moment from "moment";
import Table from "../../../reacttable/table";
import { activateNotification, allPushNotifyCampaignAction, deactivateNotification } from "../../../../../store/actions/campaignAction";
import { TiTick } from "react-icons/ti";
import { AiOutlineCloseCircle } from "react-icons/ai";


function NotificationActivityList(props) {
    useEffect(() => {
        props.getPushNotify()
    }, [])

    const data = props.list && props.list.data;
    // console.log(data)

    const activate = (push_campaign_id) => {
      // console.log(push_campaign_id);
      props.activateNotificationAPI(push_campaign_id);
    };
  
    if(props.activate.statuscode === 200){
      window.location.reload();
    }
  
    const deactivate = (push_campaign_id) => {
      // console.log(push_campaign_id);
      props.deactivateNotificationAPI(push_campaign_id);
    };
  
    if(props.deactivate.statuscode === 200){
      window.location.reload();
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Recipients',
                accessor: 'recipients'
            },
            {
                Header: 'Notification Title',
                accessor: 'title'
            },
            {
                Header: 'Message',
                accessor: 'message'
            },
            {
                Header: 'Operator',
                accessor: 'operator'
            },
            {
                Header: 'Start Date',
                id: 'date',
                accessor: d => {
                    return moment(d.date)
                        .local()
                        .format("DD-MM-YYYY");
                }
            },
            {
                Header: 'Updated At',
                id: 'last_updated',
                accessor: d => {
                    return moment(d.last_updated)
                        .local()
                        .format("DD-MM-YYYY");
                }
            },
            {
                Header: "Status",
                accessor: "status",
                Cell: (status) => {
                  // console.log(publish.value)
                  return (
                    <div>
                      <span>
                        {status.value === "Active" ? (
                          <span className="text-success">Active</span>
                        ) : (
                          <span className="text-danger">Inactive</span>
                        )}
                      </span>
                    </div>
                  );
                },
              },
              {
                Header: "Actions",
                accessor: "id",
                Cell: ({ row }) => {
                  const { original } = row;
                  const id = original.id;
                  const push_campaign_id = original.push_campaign_id;
        
                  return (
                    <div>
                      <span>
                        <a
                          href={`/dashboard/viewSingleNotification/${id}`}
                          rel="tooltip"
                          title="View"
                        >
                          <FaEye />
                        </a>
                      </span>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <span>
                        <a
                          href={`/dashboard/updateNotification/${id}`}
                          rel="tooltip"
                          title="Edit"
                        >
                          <FaEdit />
                        </a>
                      </span>
                      &nbsp;&nbsp;  &nbsp;&nbsp;
                      <span style={{cursor:"pointer"}}>
                        <TiTick
                          onClick={() => activate(push_campaign_id)}
                          title="Activate"
                        />
                      </span>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <span style={{cursor:"pointer"}}>
                        <AiOutlineCloseCircle
                          onClick={() => deactivate(push_campaign_id)}
                          title="De-Activate"
                        />
                      </span>
                    </div>
                  );
                },
              },
        ],
        []
    );


    return (
        <div>
         {props.loading  ? <h3  style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "450px",
                }}>Loading data...</h3> :
            data && data.length > 0 ?
                (<div> <Table data={data} columns={columns}></Table></div>)
                :
                (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}><h3 className="loader">No data found</h3></div>)
            }
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        list: state.campaign.allpushnotify,
        loading: state.campaign.isLoading,
        activate: state.campaign.activateNotifications,
        deactivate: state.campaign.deactivateNotifications,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getPushNotify: () => dispatch(allPushNotifyCampaignAction()),
        activateNotificationAPI: (id) => dispatch(activateNotification(id)),
        deactivateNotificationAPI: (id) => dispatch(deactivateNotification(id)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(NotificationActivityList)