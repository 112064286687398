import { Container, Card, Row, Col } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import PureBreadcrumbs from "../../../breadcrums";
import { useTranslation } from 'react-i18next';
import { fetchPartnerFromExternalPartnerTariffAction, viewSingleConsumedTariff } from "../../../../../store/actions/ocpiAction";

function ConsumedTariffView(props) {
    const { t } = useTranslation();
    const { id, party_id } = useParams();
    const [activeTab, setActiveTab] = useState('Self');

    useEffect(() => {
        if (activeTab === 'Self') {
            props.fetch(id, party_id)
        } else if (activeTab === 'Partner') {
            props.fetchPartnerFromExternalPartnerTariffApi(id, party_id); // Fetch partner data
        }
    }, [activeTab, id, party_id]);

    const selfData = props.singleConsumedTariff && props.singleConsumedTariff.data &&
        props.singleConsumedTariff.data?.[0] ? props.singleConsumedTariff.data?.[0] : {};

    const partnerData = props.fetchPartnerFromExternalPartnerTariffRes && props.fetchPartnerFromExternalPartnerTariffRes.data &&
        props.fetchPartnerFromExternalPartnerTariffRes.data?.[0] ? props.fetchPartnerFromExternalPartnerTariffRes.data?.[0] : {};
    const data = activeTab === 'Self' ? selfData : partnerData;

    const selfPriceComponents = (props.singleConsumedTariff && props.singleConsumedTariff.data?.[0]?.elements?.[0]?.price_components) || [];

    const partnerPriceComponents = (props.fetchPartnerFromExternalPartnerTariffRes && props.fetchPartnerFromExternalPartnerTariffRes.data?.[0]?.elements?.[0]?.price_components) || [];

    const priceComponents = activeTab === 'Self' ? selfPriceComponents : partnerPriceComponents;
    // console.log("priceComponents", priceComponents)

    const renderForm = (isPartner) => (
        <Row className="mt-3">
            <Col>
                <Card className="tariffcard">
                    <div>
                        {!isPartner ?
                            <h4 className='text-center p-3'>{t("Tariff Details")}</h4>
                            :
                            <h4 className='text-center p-3'>{t("Tariff Details (Partner Server)")}</h4>
                        }
                    </div>
                    <Card.Body>
                        <div className="row g-2">
                            <div className="col-md p-2">
                                <div className="form-floating">
                                    <label htmlFor='floatingInputGridname'>{t("Party ID")}</label>
                                    <input type="text" className={`form-control gray-border `} name="party_id" id="floatingInputGridparty_id" placeholder={t("Party ID")} value={data?.party_id || 'Not Available'} disabled />
                                </div>
                            </div>
                            <div className="col-md p-2">
                                <div className="form-floating">
                                    <label htmlFor='floatingInputGridAddress'>{t("Country Code")}</label>
                                    <input type="text" className={`form-control gray-border `} name="country_code" id="floatingInputGridCC" placeholder={t("Country Code")} value={data?.country_code || 'Not Available'} disabled />
                                </div>
                            </div>
                            <div className="col-md p-2">
                                <div className="form-floating">
                                    <label htmlFor='floatingInputGridAddress'>{t("Currency")}</label>
                                    <input type="text" className={`form-control gray-border `} name="Currency" id="floatingInputGridCurrency" placeholder={t("Currency")} value={data?.currency || 'Not Available'} disabled />
                                </div>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col">
                                <span style={{ color: "var(--text-primary)", fontWeight: "500", fontSize: 22 }}> {t("Price Component")} </span>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 mx-auto">
                                <div className="table-responsive">
                                    <table className="table table-sm table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">{t("Type")}</th>
                                                <th scope="col">{t("Price")}</th>
                                                <th scope="col">{t("Step Size")}</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Array.isArray(priceComponents) && priceComponents.map((tariff, index) =>
                                                <tr key={index}>
                                                    <td>{tariff.type}</td>
                                                    <td>{tariff.price}</td>
                                                    <td>{tariff.step_size}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );

    return (
        <>
            <Container fluid>
                <PureBreadcrumbs />
                <br />

                <Row>
                    <Col className="mt-4">
                        <span style={{ color: "var(--text-primary)", fontWeight: "bold" }}>Tariff Details</span>
                    </Col>
                </Row>

                <Row className="mt-3">
                    <Col xs={12}>
                        <div style={{
                            border: "1px solid #d9d9d9",
                            borderRadius: "50px",
                            padding: "0px",
                            backgroundColor: "#f3f3f3", // Light background for the switch container
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "20%",
                            margin: "0 auto",
                        }}>
                            <button
                                onClick={() => setActiveTab('Self')}
                                className={`tab-button ${activeTab === 'Self' ? 'active' : ''}`}
                                style={{
                                    backgroundColor: activeTab === 'Self' ? '#000000' : '#f3f3f3',
                                    color: activeTab === 'Self' ? '#f3f3f3' : '#000000',
                                    border: "none",
                                    padding: '10px 20px',
                                    borderRadius: "50px",
                                    fontWeight: "bold",
                                    fontSize: "16px",
                                    cursor: "pointer",
                                    transition: "all 0.3s ease",
                                    width: "55%",
                                }}
                            >
                                Self
                            </button>
                            <button
                                onClick={() => setActiveTab('Partner')}
                                className={`tab-button ${activeTab === 'Partner' ? 'active' : ''}`}
                                style={{
                                    backgroundColor: activeTab === 'Partner' ? '#000000' : '#f3f3f3',
                                    color: activeTab === 'Partner' ? '#f3f3f3' : '#000000',
                                    border: "none",
                                    padding: '10px 20px',
                                    borderRadius: "50px",
                                    fontWeight: "bold",
                                    fontSize: "16px",
                                    cursor: "pointer",
                                    transition: "all 0.3s ease",
                                    width: "55%",
                                }}
                            >
                                Partner
                            </button>
                        </div>
                    </Col>
                </Row>
                {props.loading ? (
                    <Row >
                        <Col lg={12} className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden"></span>
                            </div>
                        </Col>
                    </Row>
                ) : (
                    <>
                        {activeTab === 'Self' && renderForm(false)}
                        {activeTab === 'Partner' && renderForm(true)}
                    </>
                )}
            </Container>
        </>
    )




}


const mapStateToProps = (state) => {
    return {
        loading: state.ocpi.isLoading,
        singleConsumedTariff: state.ocpi.fetchPartnerTariffByID,
        fetchPartnerFromExternalPartnerTariffRes: state.ocpi.fetchPartnerFromExternalPartnerTariff,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        fetch: (id, party_id) => dispatch(viewSingleConsumedTariff(id, party_id)),
        fetchPartnerFromExternalPartnerTariffApi: (id, party_id) => dispatch(fetchPartnerFromExternalPartnerTariffAction(id, party_id)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ConsumedTariffView);

