import React, { useEffect, useMemo, useState } from "react";
import Table from "../../reacttable/table";
import "../../reacttable/reactTable.css";
import { FaDownload, FaEdit, FaEye } from "react-icons/fa";
import { CSVLink } from "react-csv";
import { TiTick } from "react-icons/ti";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import CustomReportPaginationTable from "../../report/adminReport/customReportPaginationTable";
import { fetchAllTimeSlotAction } from "../../../../store/actions/tariffAction";
import { connect } from "react-redux";

const TimeTariffListComponent = (props) => {
  const { t } = useTranslation();
  const { allTariffData, loading } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const document_count = props.fetchList && props.fetchList.document_count;
  const defaultPageSize = 10;
  const pageCount = Math.ceil(document_count / defaultPageSize)
  // console.log(props.fetchList );

  const data = props.fetchList && props.fetchList.data;
  // console.log(data)

  useEffect(() => {
    props.getAllTimeofUseList(currentPage, pageSize)
  }, [currentPage, pageSize])

  const activateStation = (id) => {
    // console.log(id);
    props.activateTariffAPI(id);
  };

  if (props.activate_tariff_api.statuscode === 200) {
    window.location.reload();
  }

  const deactivateStation = (id) => {
    // console.log(id);
    props.deActivateTariff(id);
  };

  if (props.deactivate_tariff_api.statuscode === 200) {
    window.location.reload();
  }
  // console.log(props.deactivate_tariff_api)
  if (props.deactivate_tariff_api.statuscode === 405) {
    alert(props.deactivate_tariff_api.error);
    window.location.reload();
  }

  const columns = useMemo(
    () => [
      {
        Header: "Tariff",
        accessor: "name",
      },
      {
        Header: "Organisation",
        accessor: "operator",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Currency",
        accessor: "currency",
      },
      {
        Header: "Is Peak Hours ?",
        accessor: "ispeak",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: (item) => {
          const value = item.cell
          // console.log(publish.value)
          return (
            <div>
              <span>
                {value === "active" ? (
                  <span className="text-success">{t("Active")}</span>
                ) : value === "inactive" ? (
                  <span className="text-danger">{t("Inactive")}</span>
                ) : (
                  <span className="text-warning">{t("Draft")}</span>
                )}
              </span>
            </div>
          );
        },
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: (item) => {
          const value = item.cell
          return (
            <div>
              <span>
                <a
                  href={`/dashboard/view_tariff/singletariff/${value}`}
                  rel="tooltip"
                  title="View"
                >
                  <FaEye />
                </a>
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <span>
                <a
                  href={`/dashboard/updatetariff/${value}`}
                  rel="tooltip"
                  title="Edit"
                >
                  <FaEdit />
                </a>
              </span>
              &nbsp;&nbsp;  &nbsp;&nbsp;
              <span style={{ cursor: "pointer" }}>
                <TiTick
                  onClick={() => activateStation(value)}
                  title="Activate"
                />
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <span style={{ cursor: "pointer" }}>
                <AiOutlineCloseCircle
                  onClick={() => deactivateStation(value)}
                  title="De-Activate"
                />
              </span>
            </div>
          );
        },
      },
    ],
    []
  );

  const headers = [
    { label: 'ID', key: 'id' },
    { label: 'Peak Hour', key: 'ispeak' },
    // { label: 'Time From', key: 'timeslots[0].from' },
    // { label: 'Time To', key: 'timeslots[0].to' },
    { label: 'Amount', key: 'components[0].amount' },
    { label: 'Tax', key: 'components[0].tax' },
    { label: 'Unit', key: 'components[0].unit' },
    { label: 'Currency', key: 'currency' },
    { label: 'Description', key: 'description' },
    { label: 'Name', key: 'name' },
    // { label: 'Day', key: 'day' },
    { label: 'Role', key: 'role' },
    { label: 'Operator', key: 'operator' },
    { label: 'Created At', key: 'createdat' },
    { label: 'Updated At', key: 'updatedat' },
    { label: 'Status', key: 'status' },
    { label: 'Created By', key: 'created_by' },
  ];

  const initialVisibleColumns =
    [
      'name', 'operator', 'description', 'currency', 'ispeak',
      'status', 'id'
    ];

  return (
    <div>
      {loading ? <h3 style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "250px",
      }}>Loading data...</h3> :
        data && data.length > 0 ? (
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div className="table-header"> {t("Tariff List")}</div>
              <button className="csv-file-btn">
                <CSVLink
                  className="fas"
                  data={allTariffData}
                  headers={headers}
                  filename="Tariff-List.csv"
                  style={{ textDecoration: 'none' }}
                >
                  <FaDownload className="download-icon" />
                </CSVLink>
              </button>
            </div>
            <CustomReportPaginationTable
              data={data}
              allData={allTariffData}
              pageCount={pageCount}
              columns={columns}
              search={search}
              setSearch={setSearch}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              filteredData={filteredData}
              setFilteredData={setFilteredData}
              loading={props.loading}
              initialVisibleColumns={initialVisibleColumns}
              t={t}
            />
            {/* <Table data={data} columns={columns}></Table> */}
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          > <h3>{t("No Data Found")}</h3>
          </div>
        )}
    </div>
  );

}

const mapStateToProps = (state) => {
  return {
    // loading: state.tariff.isLoading,
    fetchList: state.tariff.timeSlotFetch,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    getAllTimeofUseList: (currentPage, pageSize) => dispatch(fetchAllTimeSlotAction(currentPage, pageSize)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(TimeTariffListComponent)