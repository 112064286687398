import React, { useEffect, useState } from 'react'
import { Card, Col, Container } from 'react-bootstrap'
import PureBreadcrumbs from "../../breadcrums";
import { connect } from 'react-redux';
import { getAllFleetName } from '../../../../store/actions/fleetAction';
// import { GoogleMap, Marker, MarkerClusterer } from '@react-google-maps/api';
import FleetLiveCharging from './FleetLiveCharging';
import FleetVehicleStatus from './FleetVehicleStatus';
import ReactApexChart from 'react-apexcharts';
import FleetCharging from './FleetCharging';
import { GoogleMap, Marker, MarkerClusterer, useLoadScript } from '@react-google-maps/api';

const data = [
    {
        lat: 20.5937,
        lng: 78.9629
    },
    {
        lat: 21.1458,
        lng: 79.0882
    },
    {
        lat: 19.0760,
        lng: 72.8777
    },
    {
        lat: 28.7041,
        lng: 77.1025
    },
    {
        lat: 13.0827,
        lng: 80.2707
    },
    {
        lat: 22.5726,
        lng: 88.3639
    },
    {
        lat: 12.9716,
        lng: 77.5946
    },
    {
        lat: 26.9124,
        lng: 75.7873
    },
    {
        lat: 17.3850,
        lng: 78.4867
    },
    {
        lat: 28.8386,
        lng: 78.7733
    }
];
const libraries = ["places"];


const containerStyle = {
    width: '100%',
    height: "800px"
};
const center = {
    lat: 20.5937,
    lng: 78.9629
};

const ClusterIconStyle = [{
    url: "/images/bus1.svg",
    height: 55,
    width: 55
}]


function FleetRealTracking(props) {
    const [fleetDropDown, SetFleetDropDown] = useState([]);
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries,
    });

    useEffect(() => {
        props.getAllFleetNameApi()
    }, [])

    useEffect(() => {
        SetFleetDropDown(props.getAllFleetNameRes?.data?.filter((item) => item.status === "Active"))
    }, [props.getAllFleetNameRes])

    const [chartData] = useState({
        series: [44, 55],
        options: {
            chart: {
                height: 350,
                type: 'radialBar',
            },
            plotOptions: {
                radialBar: {
                    dataLabels: {
                        name: {
                            fontSize: '22px',
                        },
                        value: {
                            fontSize: '16px',
                        },
                        total: {
                            show: true,
                            label: 'Total',
                            formatter: function () {
                                return 249;
                            },
                        },
                    },
                },
            },
            labels: ['Idle', 'Charging'],
        },
    });

    if (loadError) return <div>Error loading map. Please try again later.</div>;
    if (!isLoaded) return <div>Loading map...</div>;

    return (
        <Container fluid>
            <PureBreadcrumbs />
            <br />
            <section className="customercard" style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                <label style={{ marginTop: "5px" }}>
                    Select Fleet :
                </label>
                <select style={{ width: "170px", border: "1px solid #9f9f9f", borderRadius: "5px" }}>
                    {fleetDropDown?.map((item, index) => (
                        <option key={index} value={item.ownername}>{item.ownername}</option>
                    ))}
                </select>
            </section>
            <br />
            <section style={{ display: "flex", gap: "10px", justifyContent: "start" }}>
                <Card className="customercard" style={{ width: "32rem" }}>
                    <Card.Title>Vehicle Status</Card.Title>
                    <div style={{ display: "flex", alignContent: "center", justifyContent: "center" }}>
                        <div id="chart">
                            <ReactApexChart options={chartData.options} series={chartData.series} type="radialBar" height={500} width={500} />
                        </div>
                    </div>
                </Card>
                <Card className="customercard" >
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Card.Title>Vehicle Live Charging</Card.Title>
                        <div style={{ marginBottom: "10px" }}>
                            <a
                                href={`/dashboard/fleet_All_Charger`}
                                className="btn lgn-btn"
                                type="button"
                            >
                                <p style={{ whiteSpace: "nowrap", marginBottom: "10px" }}>View All</p>
                            </a>
                        </div>
                    </div>
                    <br />
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "end" }}>
                        <div>
                            <FleetCharging />
                        </div>
                    </div>
                </Card>
            </section>
            <br />
            <br />
            <section>
                <Col md={12}>
                    <Card className='customercard'>
                        <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={center}
                            zoom={5}
                        >
                            <MarkerClusterer styles={ClusterIconStyle} gridSize={25}
                                minimumClusterSize={1} >
                                {(clusterer) => (
                                    data?.map((location) => (
                                        <Marker position={{ lat: location.lat, lng: location.lng }} clusterer={clusterer} />
                                    )))}
                            </MarkerClusterer>
                        </GoogleMap>
                    </Card>
                </Col>
            </section>
            <br />
            <br />
            <div style={{ display: "flex", gap: "20px", justifyContent: "space-between" }}>
                <Card className="customercard">
                    <Card.Body className="text-center">
                        <Card.Text>
                            <p className="table-header" style={{ textAlign: "left" }}>Live Charging</p>
                        </Card.Text>
                        <div style={{ width: "40rem" }}>
                            <FleetLiveCharging />
                        </div>
                    </Card.Body>
                </Card>
                <Card className="customercard" style={{ width: "41rem" }}>
                    <Card.Body className="text-center">
                        <Card.Text>
                            <p className="table-header" style={{ textAlign: "left" }}>Vehicle Live Status</p>
                        </Card.Text>
                        <div>
                            <FleetVehicleStatus />
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </Container>
    )
}

const mapStateToProps = (state) => {
    return {
        loading: state.fleet.isLoading,
        getAllFleetNameRes: state.fleet.all_fleet_name,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getAllFleetNameApi: () => dispatch(getAllFleetName()),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(FleetRealTracking);
