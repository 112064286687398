import React, { useEffect } from "react";
import { connect } from "react-redux";
import "../../reacttable/reactTable.css";
import ApexCharts from "react-apexcharts";
import { fetchBookingCount } from "../../../../store/actions/evseAction";
import { useTranslation } from "react-i18next";

function CustomerBookingGraph(props) {
  const {t} = useTranslation();
  useEffect(() => {
    props.BookingCount();
  }, []);

  const data = props.bookingData && props.bookingData.data;
  // console.log("booking data",data);

  let monthpush = [];

  const ps_month = props.bookingData.data && props.bookingData.data.map((n) => {
    if (n.id == 1) {
      monthpush.push("January");

    } else if (n.id == 2) {
      monthpush.push("February");

    } else if (n.id == 3) {
      monthpush.push("March");

    } else if (n.id == 4) {
      monthpush.push("April");

    } else if (n.id == 5) {
      monthpush.push("May");

    } else if (n.id == 6) {
      monthpush.push("June");

    } else if (n.id == 7) {
      monthpush.push("July");

    } else if (n.id == 8) {
      monthpush.push("August");

    } else if (n.id == 9) {
      monthpush.push("September");

    } else if (n.id == 10) {
      monthpush.push("October");

    } else if (n.id == 11) {
      monthpush.push("November");

    }
    else if (n.id == 12) {
      monthpush.push("December");

    }
  });
  // console.log("month",monthpush)
  const options = {
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barWidth: "50%",
        borderRadius: 8,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },

    xaxis: {
      categories: monthpush,
    },
    yaxis: {
      title: {
        text: "Number of Bookings",
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "vertical",
        shadeIntensity: 0.25,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      offsetX: 40,
    },
  };

  return (
    <>

      <div>
        {props.bookingData.data &&
          props.bookingData.data.length > 0 ? (
          <ApexCharts
            options={options}
            series={[
              {
                name: "Booking Cancelled",
                data: props.bookingData.data.map(item => item.booking_cancelled_user),
              },
              {
                name: "Booking Recieved",
                data: props.bookingData.data.map(item => item.booking_received),
              },
              {
                name: "Total Bookings Used",
                data: props.bookingData.data.map(item => item.booking_used),
              },
              {
                name: "Total Bookings Expired",
                data: props.bookingData.data.map(item => item.booking_cancelled_unused),
              },
              {
                name: "Total Bookings ",
                data: props.bookingData.data.map(item => item.total_booking),
              },

            ]}
            type="bar"
            height={400}
          />
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "400px",
            }}
          >
            {props.loading ? <h3>Loading data...</h3> : <h3>{t("No data found")}</h3>}
          </div>
        )}
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.evse.isEvseLoading,
    bookingData: state.evse.fetchBookingCount,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    BookingCount: () => dispatch(fetchBookingCount()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CustomerBookingGraph);