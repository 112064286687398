import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { fetchChargerSlots } from "../../../../../store/actions/evseAction";
import { connect } from "react-redux";
import PureBreadcrumbs from "../../../breadcrums";
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

function ViewChargerSlots(props) {
    const { t } = useTranslation();
    const { evse_id } = useParams()
    useEffect(() => {
        props.chargerSlotsData(evse_id);
    }, [evse_id]);

    const evsedata = props.chargerSlots && props.chargerSlots
    // console.log("evsedata", evsedata)
    if (evsedata && evsedata.evse_id === "") {
        return (
            <h4 className="text-center p-4">{t("No Data found for this charger")}</h4>
        )
    }
    return (
        <Container fluid>
            <div className="row">
                <div className="col-lg-6 col-sm-12">
                    <PureBreadcrumbs />
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-lg-12 col-sm-12">
                    <Row>
                        <Col xs={12} md={6}>
                            <div className="breadcrumb-item active" aria-current="page">{t("EVSE ID")} - {evsedata?.evse_id}</div>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xs={12} md={6}>
                            {
                                evsedata && evsedata?.regular_hours?.map((rhrs, index) => {
                                    return (
                                        <div key={index}>
                                            <div className="table-responsive">
                                                <table className="table table-sm table-bordered">
                                                    <thead>
                                                        <tr key={evsedata.evse_id} >
                                                            <th scope="col">{t("Weekday")}</th>
                                                            <th scope="col">{t("Start Time")}</th>
                                                            <th scope="col">{t("End Time")}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr key={evsedata.evse_id}>
                                                            <td>{rhrs.weekday === 1 ? "Monday" :
                                                                rhrs.weekday === 2 ? "Tuesday" :
                                                                    rhrs.weekday === 3 ? "Wednesday" :
                                                                        rhrs.weekday === 4 ? "Thursday" :
                                                                            rhrs.weekday === 5 ? "Friday" :
                                                                                rhrs.weekday === 6 ? "Saturday" :
                                                                                    rhrs.weekday === 7 ? "Sunday" : ""}</td>
                                                            <td>{rhrs.period_begin} </td>
                                                            <td>{rhrs.period_end} </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </Col>
                    </Row>

                </div>
            </div>
        </Container>
    )

}


const mapStateToProps = (state) => {
    return {
        loading: state.evse.isEvseLoading,
        chargerSlots: state.evse.chargerslots,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        chargerSlotsData: (evse_id) => dispatch(fetchChargerSlots(evse_id))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewChargerSlots)

