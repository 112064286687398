import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import ApexCharts from "react-apexcharts";
import { Row, Col, Card, Container } from "react-bootstrap";
import { fetchSingleCustomerApi, fetchSingleCustomerTotalConsumptionApi, fetchSingleGraphApi } from "../../../../store/actions/loyaltyAction";
import moment from "moment";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import PureBreadcrumbs from "../../breadcrums";
import Table from "../../reacttable/table";
import { useTranslation } from "react-i18next";


function ViewSingleCustomerGraph(props) {
    const { t } = useTranslation();
    const { userid } = useParams();
    const today = moment();

    const monthlycurrent = today.subtract(6, "month").format("YYYY-MM-DD");
    const monthlylastDay = today.add(6, "month").format("YYYY-MM-DD");
    // const [arrConsumptionData, setArrCosumptionData] = useState();

    useEffect(() => {
        props.singleGraphData(userid, monthlycurrent, monthlylastDay);
        props.totalCustomerData(userid);
        props.singleCustomerData(userid);
    }, []);

    const graphData = props.singleGraphApiRes && props.singleGraphApiRes.data;
    const consumptionData = props.totalConsumptionApiRes && props.totalConsumptionApiRes.data;
    const customerData = props.singleCustomerDataRes && props.singleCustomerDataRes.data;
    // console.log("customerData", customerData);

    // useEffect(() => {
    //     // setArrCosumptionData([consumptionData])
    // }, [consumptionData])

    let monthpush = [];
    const ps_month = props.singleGraphApiRes && props.singleGraphApiRes.data.map((n) => {
        if (n.number === 1) {
            monthpush.push("January");

        } else if (n.number === 2) {
            monthpush.push("February");

        } else if (n.number === 3) {
            monthpush.push("March");

        } else if (n.number === 4) {
            monthpush.push("April");

        } else if (n.number === 5) {
            monthpush.push("May");

        } else if (n.number === 6) {
            monthpush.push("June");

        } else if (n.number === 7) {
            monthpush.push("July");

        } else if (n.number === 8) {
            monthpush.push("August");

        } else if (n.number === 9) {
            monthpush.push("September");

        } else if (n.number === 10) {
            monthpush.push("October");

        } else if (n.number === 11) {
            monthpush.push("November");

        }
        else if (n.number === 12) {
            monthpush.push("December");

        }
    });
    // console.log(monthpush,"month")

    const options = {
        chart: {
            type: "bar",
            height: 350,
            stacked: false,
        },
        plotOptions: {
            bar: {
                horizontal: false,
                barWidth: "50%",
                borderRadius: 8,
                width: "100%",
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            width: 1,
            colors: ["#fff"],
        },

        xaxis: {
            categories: monthpush,
        },
        yaxis: {
            title: {
                text: t("Earned VS Redeemed"),
            },
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val;
                },
            },
        },
        fill: {
            type: "gradient",
            gradient: {
                shade: "light",
                type: "vertical",
                shadeIntensity: 0.25,
                gradientToColors: undefined,
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100],
            },
        },
        legend: {
            position: "top",
            horizontalAlign: "left",
            offsetX: 40,
        },
    };

    const columns = useMemo(
        () => [
            { Header: "User Name", accessor: "userid" },
            { Header: "Organisation", accessor: "operator" },
            { Header: "Consume Time", accessor: "consume_time" },
            {
                Header: "Consume Unit",
                accessor: "kwh",
                Cell: ({ value }) => (
                    <span>{value ? value.toFixed(2) : ''}</span>
                )
            },
            { Header: "Consume Amount", accessor: "charging_amount" },
            { Header: "Rule Type", accessor: "rule_type" },
            { Header: "Program Name", accessor: "program" },
            {
                Header: "Points",
                accessor: "points",
                Cell: ({ row }) => {
                    const { original } = row;
                    const nature = original.nature;
                    const points = original.points;
                    // console.log("nature , points",nature,points)
                    if (nature === "earn") {
                        return (
                            <div>
                                <span>
                                    <span className="text-success">{points}</span>
                                </span>
                            </div>
                        );
                    } else {
                        return (
                            <div>
                                <span>
                                    <span className="text-danger">{points}</span>
                                </span>
                            </div>
                        );
                    }
                },
            },
            {
                Header: "Redeem & Earned Date",
                id: "createdat",
                accessor: (d) => {
                    return moment(d.createdat, "YYYY-MM-DD").format("DD-MM-YYYY");
                },
            },
            {
                Header: "Nature",
                accessor: "nature",
                Cell: (nature) => {
                    return (
                        <div>
                            <span>
                                {nature.value === "earn" ? (
                                    <span className="text-success">Earn</span>
                                ) : (
                                    <span className="text-danger">Redeem</span>
                                )}
                            </span>
                        </div>
                    );
                },
            },

        ],
        []
    );

    return (
        <Container fluid className="pb-2">
            <PureBreadcrumbs />
            <br />
            <Row>
                <Col lg={6} xs={12} md={4}>
                    <Card className="customercard total_points_card">
                        <Row>
                            <Col lg={12} md={12}>
                                <span>
                                    <img
                                        src={process.env.PUBLIC_URL + "/images/loyalty/total_points.png"}
                                        alt="evse"
                                        className="points_image"
                                    />
                                </span>
                                <span className="customercard_details">
                                    <Card.Text style={{ fontSize: "16px", fontWeight: "600px" }}>
                                        {t("TOTAL EARNED POINTS")}
                                    </Card.Text>
                                    <Card.Title>
                                        <b>{consumptionData?.total_earned_points}</b>
                                    </Card.Title>
                                </span>
                            </Col>
                        </Row>

                    </Card>
                    <br />
                </Col>
                <Col lg={6} xs={12} md={4}>
                    <Card className="customercard total_redemption_card">
                        <Row>
                            <Col lg={12} md={12}>
                                <span>
                                    <img
                                        src={process.env.PUBLIC_URL + "/images/loyalty/redemption_points.png"}
                                        alt="evse"
                                        className="redeem_points_image"
                                    />
                                </span>
                                <span className="customercard_details">
                                    <Card.Text style={{ fontSize: "16px", fontWeight: "600px" }}>
                                        {t("TOTAL REDEEMED POINTS")}
                                    </Card.Text>
                                    <Card.Title style={{ position: "absolute" }}>
                                        <b>{consumptionData?.total_redeemed_points}</b>
                                    </Card.Title>
                                </span>
                            </Col>
                        </Row>

                    </Card>
                    <br />
                </Col>
            </Row>
            <div className="customercard mb-4">
                <Card.Header>
                    <b>{t("Earned VS Redeemed")}</b>
                </Card.Header>
                {props.singleGraphApiRes.data &&
                    props.singleGraphApiRes.data.length > 0 ? (
                    <ApexCharts
                        options={options}
                        series={[
                            {
                                name: t("Total Earned Points"),
                                data: graphData.map((item) => item.total_earned_points),
                            },

                            {
                                name: t("Total Redeemed Points"),
                                data: graphData.map((item) => item.total_redeemed_points)
                            },
                        ]}
                        type="bar"
                        height={400}
                    />
                ) : (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "400px",
                        }}
                    >
                        {props.loading ? <h3>{t("Loading data...")}</h3> : <h3>{t("No data found")}</h3>}
                    </div>
                )}
            </div>
            <Card className="mb-4">
                <Row>
                    <Col>
                        <Card className="customercard">
                            <div className="table-header">{t("Report")}</div>
                            <Card.Body>
                                {props.loading ? <h3 style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "450px",
                                }}>Loading data...</h3> :
                                    customerData && customerData.length > 0 ? (
                                        <div>
                                            {" "}
                                            <Table data={customerData} columns={columns}></Table>
                                        </div>
                                    ) : (
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <h3 className="loader">{t("No data found")}</h3>
                                        </div>
                                    )}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Card>
        </Container>
    )
}
const mapStateToProps = (state) => {
    return {
        loading: state.loyalty.isLoading,
        singleGraphApiRes: state.loyalty.fetchSingleGraph,
        totalConsumptionApiRes: state.loyalty.fetchTotalConsumptionCardApi,
        singleCustomerDataRes: state.loyalty.fetchSingleCustomerApi,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        singleGraphData: (userid, startdate, enddate) => dispatch(fetchSingleGraphApi(userid, startdate, enddate)),
        totalCustomerData: (userid) => dispatch(fetchSingleCustomerTotalConsumptionApi(userid)),
        singleCustomerData: (userid) => dispatch(fetchSingleCustomerApi(userid)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewSingleCustomerGraph);