// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flex-parent-element {
  display: flex;
  width: 50%;
}

.flex-child-element {
  flex: 1 1;
}

.invoice_header {
  background-color: lightgreen;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 100.1%;
  margin-left: 0px;
  font-size: medium;

}

.custom-list {
  list-style-type: none;
  padding: 5px;
}

.middle_section {
  padding: 7px;
  font-size: medium;
}

.footer_section {
  border-top: 1px solid gray;
  padding: 10px;
  width: 100.1%;
  margin-left: 0px;
}

.invoicepayoutcard {
  background: var(--sidebar-topbar);
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  flex: 1 1;
}

.bottom_section {
  border-top: 1px solid gray;
  width: 100.1%;
  margin-left: 0px;
  padding: 7px;
  font-size: medium;
}

.p-viewer-payout-stripepass {
  z-index: 9999;
  position: absolute;
  top: 40%;
  right: 30px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/financeManagement/finance.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,UAAU;AACZ;;AAEA;EACE,SAAO;AACT;;AAEA;EACE,4BAA4B;EAC5B,2BAA2B;EAC3B,4BAA4B;EAC5B,aAAa;EACb,gBAAgB;EAChB,iBAAiB;;AAEnB;;AAEA;EACE,qBAAqB;EACrB,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,0BAA0B;EAC1B,aAAa;EACb,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,iCAAiC;EACjC,2CAA2C;EAC3C,kBAAkB;EAClB,SAAS;AACX;;AAEA;EACE,0BAA0B;EAC1B,aAAa;EACb,gBAAgB;EAChB,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,QAAQ;EACR,WAAW;EACX,eAAe;AACjB","sourcesContent":[".flex-parent-element {\n  display: flex;\n  width: 50%;\n}\n\n.flex-child-element {\n  flex: 1;\n}\n\n.invoice_header {\n  background-color: lightgreen;\n  border-top-left-radius: 5px;\n  border-top-right-radius: 5px;\n  width: 100.1%;\n  margin-left: 0px;\n  font-size: medium;\n\n}\n\n.custom-list {\n  list-style-type: none;\n  padding: 5px;\n}\n\n.middle_section {\n  padding: 7px;\n  font-size: medium;\n}\n\n.footer_section {\n  border-top: 1px solid gray;\n  padding: 10px;\n  width: 100.1%;\n  margin-left: 0px;\n}\n\n.invoicepayoutcard {\n  background: var(--sidebar-topbar);\n  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.25);\n  border-radius: 5px;\n  flex: 1 1;\n}\n\n.bottom_section {\n  border-top: 1px solid gray;\n  width: 100.1%;\n  margin-left: 0px;\n  padding: 7px;\n  font-size: medium;\n}\n\n.p-viewer-payout-stripepass {\n  z-index: 9999;\n  position: absolute;\n  top: 40%;\n  right: 30px;\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
