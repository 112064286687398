import React, { useMemo } from "react";
import { connect } from "react-redux";
import "../../reacttable/reactTable.css";
import Table from "../../reacttable/table";
import { FaEdit, FaEye } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { AiOutlineCloseCircle } from "react-icons/ai";
import {
    activateStation,
    deactivateStation,
    deleteStationApi,
} from "../../../../store/actions/evseAction";
import { useTranslation } from "react-i18next";

function DiscoveryEVSEList(props) {
    const { t } = useTranslation();
    const { role } = JSON.parse(localStorage.getItem("user"));
    // console.log("role: ", role);
    const data = props.list && props.list.data?.filter(en => en.status === "Discovery");
    // console.log("data", data)

    const activateStation = (stationid) => {
        // console.log(stationid);
        props.activateStation(stationid);
        window.location.reload();
    };

    const deactivateStation = (stationid) => {
        // console.log(stationid);
        props.deactivateStation(stationid);
        window.location.reload();
    };
    const deleteStation = (stationid) => {
        const isConfirmed = window.confirm("Are you sure you want to delete the discovery station?");

        if (isConfirmed) {
            props.deleteStation(stationid);
        } else {

        }
    };

    if (props.deleteStationRes.statuscode === 200) {
        window.location.reload();
    }

    const columns = useMemo(
        () => [
            {
                Header: "Name",
                accessor: "name",
            },
            {
                Header: "Address",
                accessor: "address",
            },
            {
                Header: "City",
                accessor: "city",
            },
            {
                Header: "State",
                accessor: "state",
            },
            {
                Header: "Network type",
                accessor: "location_type",
            },
            {
                Header: "Connector type",
                accessor: "evses[0].connectors[0].standard"
            },
            {
                Header: "Connector power type",
                accessor: "evses[0].connectors[0].power_type",
            },
            {
                Header: "Status",
                accessor: "status",
            },

            {
                Header: "Active/Inactive",
                accessor: "publish",
                Cell: (publish) => {
                    // console.log(publish.value)
                    return (
                        <div>
                            <span>
                                {publish.value == true ? (
                                    <span className="text-success">Active</span>
                                ) : (
                                    <span className="text-danger">Inactive</span>
                                )}
                            </span>
                        </div>
                    );
                },
            },
            {
                Header: "Actions",
                accessor: "stationid",
                Cell: (stationid) => {
                    return (
                        <div>
                            <span>
                                <a
                                    href={`/dashboard/viewsingleevse/${stationid.value}`}
                                    rel="tooltip"
                                    title="View"
                                >
                                    <FaEye />
                                </a>
                            </span>
                            &nbsp;&nbsp;
                            <span>
                                <a
                                    href={`/dashboard/updateevse/${stationid.value}`}
                                    rel="tooltip"
                                    title="Edit"
                                >
                                    <FaEdit />
                                </a>
                            </span>
                            &nbsp;&nbsp;
                            <span style={{ cursor: "pointer" }}>
                                <TiTick
                                    onClick={() => activateStation(stationid.value)}
                                    title="Activate"
                                />
                            </span>
                            &nbsp;&nbsp;
                            <span style={{ cursor: "pointer" }}>
                                <AiOutlineCloseCircle
                                    onClick={() => deactivateStation(stationid.value)}
                                    title="De-Activate"
                                />
                            </span>
                            &nbsp;&nbsp;
                            {role === "Admin" &&
                                <span style={{ cursor: "pointer" }}>
                                    <i className="fa fa-trash text-danger"
                                        onClick={() => deleteStation(stationid.value)}
                                        title="Delete">
                                    </i>
                                </span>}
                        </div>
                    );
                },
            },
        ],
        []
    );
    // console.log(props.loading)
    return (
        <div>
            {
                data && data.length > 0 ? (
                    <div>
                        <Table data={data} columns={columns}></Table>
                    </div>
                ) : (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <h3>{t("No data found")}</h3>
                    </div>
                )}
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        activate: state.evse.activatestation,
        loading: state.evse.isEvseLoading,
        deactivate: state.evse.deactivatestation,
        deleteStationRes: state.evse.stationDelete,

    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        activateStation: (stationid) => dispatch(activateStation(stationid)),
        deactivateStation: (stationid) => dispatch(deactivateStation(stationid)),
        deleteStation: (stationid) => dispatch(deleteStationApi(stationid)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(DiscoveryEVSEList);
