const initState = {
    isLoading: false,
    cities: '',
    states: '',
    countries: '',
    amenities: '',
    roles: '',
    privileges: '',
    subprivileges: '',
    allcities: '',
    allmaker: '',
    allmodel: '',
    getNewPrivilages:'',
}
const mainReducer = (state = initState, action) => {
    switch (action.type) {
        case 'LOAD':
            return { ...state, isLoading: true }
        case 'LOAD_CITY':
            return { ...state, cities: action.payload, isLoading: false };
        case 'LOAD_STATE':
            return { ...state, states: action.payload, isLoading: false };
        case 'LOAD_COUNTRY':
            return { ...state, countries: action.payload, isLoading: false };
        case 'LOAD_AMENITY':
            return { ...state, amenities: action.payload, isLoading: false };
        case 'LOAD_ROLE':
            return { ...state, roles: action.payload, isLoading: false };
        case 'LOAD_PRIVILEGE':
            return { ...state, privileges: action.payload, isLoading: false };
        case 'LOAD_SUB_PRIVILEGE':
            return { ...state, subprivileges: action.payload, isLoading: false };
        case 'FETCH_ALL_CITIES':
            return { ...state, allcities: action.payload, isLoading: false };
        case 'FETCH_ALL_MAKER':
            return { ...state, allmaker: action.payload, isLoading: false };
        case 'FETCH_ALL_MODEL':
            return { ...state, allmodel: action.payload, isLoading: false };
        case 'LOAD_NEW_PRIVILEGES':
            return { ...state, getNewPrivilages: action.payload, isLoading: false };
        default:
            return state;
    }
}
export default mainReducer;