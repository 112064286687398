import { Container, Row, Col, Card } from "react-bootstrap";
import React, { useEffect, useState, useMemo } from "react";
import PureBreadcrumbs from "../breadcrums";
import {
  getAllFleetName,
  getFleetReport,
  getMonthlyConsumptionReport,
  getMonthlyRevenueReport,
} from "../../../store/actions/fleetAction";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import "../reacttable/reactTable.css";
import Table from "../reacttable/table";
import Chart from "react-apexcharts";

function FleetReport(props) {
  const currency = process.env.REACT_APP_CURRENCY;

  useEffect(() => {
    props.all_fleet_name();
  }, []);

  const [org, setOrg] = useState("");
  const [startDate, setStartDate] = useState(new Date(Date.now() - 3600 * 1000 * 720));
  const [endDate, setEndDate] = useState(new Date(Date.now() + 3600 * 1000 * 24));

  const handleAddClick = () => {
    window.location.reload(false);
  };

  const firstRecord = props.fleet_list.data && props.fleet_list.data.map((e) => e.ownername);
  const firstid = firstRecord && firstRecord[0];
  // console.log(firstid)
  useEffect(() => {
    props.fleet_report(firstid, startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10));
    props.fleet_consumtion(firstid, startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10));
    props.fleet_revenue(firstid, startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10));
  }, [startDate, endDate, firstid]);

  const handleChange = (e) => {
    let fleet = e.target.value;
    setOrg(fleet);
    if (e.target.value !== "" && e.target.value !== undefined) {
      props.fleet_report(fleet, startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10));
      props.fleet_consumtion(fleet, startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10));
      props.fleet_revenue(fleet, startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10));
    }
  };
  const loadNewData = () => {
    props.fleet_report(org, startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10));
    props.fleet_consumtion(org, startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10));
    props.fleet_revenue(org, startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10));
  };

  // const loadNewData = () => {
  //     props.fleet_report(
  //         org,
  //         startDate.toISOString().substr(0, 10),
  //         endDate.toISOString().substr(0, 10),
  //     );
  // };

  //transaction table //
  const transactionData = props.report && props.report.data;
  console.log(transactionData, "transactionData");
  const transactionDatacolumns = useMemo(
    () => [
      { Header: "Driver Name", accessor: "customer_name" },
      { Header: "Location", accessor: "location" },
      { Header: "EVSE ID", accessor: "evse_id" },
      { Header: "Start Date", accessor: "start_date" },
      { Header: "End Date", accessor: "end_date" },
      { Header: "Consumption", accessor: "kwh" },
      { Header: "Unit", accessor: "kwh_unit" },
      { Header: " Total Charging Amount", accessor: "total_amount" },
      { Header: "SOC", accessor: "soc" },
      { Header: "Status", accessor: "status" },
    ],
    []
  );

  // // Monthly Graph Revenue Start //
  // //------------------------Monthly Graph Start ------------------------//
  let monthpush = [];
  //monthly calculation
  const monthlyrevenue = props.monthly_consumtion_graph && props.monthly_consumtion_graph.data.map((en) => en.amount);
  const monthlyrevenueData = props.monthly_consumtion_graph && props.monthly_consumtion_graph.data;
  // console.log("monthlyrevenue",monthlyrevenueData)
  let monthlyrevenuedata = [];
  for (let i = 0; i < monthlyrevenue.length; i++) {
    monthlyrevenuedata.push(Math.ceil(monthlyrevenue[i]));
  }

  //  console.log("monthlyrevenuedata", monthlyrevenuedata)
  //month label
  const monthlylabel =
    props.monthly_consumtion_graph &&
    props.monthly_consumtion_graph.data &&
    props.monthly_consumtion_graph.data.map((item) => {
      const number = item.ID.number;
      //  console.log( number);
      let month = number;

      if (month === 12) {
        monthpush.push("December");
      } else if (month === 11) {
        monthpush.push("November");
      } else if (month === 10) {
        monthpush.push("October");
      } else if (month === 9) {
        monthpush.push("September");
      } else if (month === 8) {
        monthpush.push("August");
      } else if (month === 7) {
        monthpush.push("July");
      } else if (month === 6) {
        monthpush.push("June");
      } else if (month === 5) {
        monthpush.push("May");
      } else if (month === 4) {
        monthpush.push("April");
      } else if (month === 3) {
        monthpush.push("March");
      } else if (month === 2) {
        monthpush.push("February");
      } else if (month === 1) {
        monthpush.push("January");
      }
      return monthpush;
    });
  //  console.log("monthly ",monthpush);

  const monthdata = {
    chart: {
      type: "bar",
      height: 35,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "60%",
        borderRadius: 5,
      },
    },
    xaxis: {
      categories: monthpush,
    },
    yaxis: [
      {
        title: {
          text: "Monthly Revenue",
        },
        labels: {
          formatter: function (val) {
            return `${currency} ${val.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
          },
        },
      },
    ],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "vertical",
        shadeIntensity: 0.7,
        gradientToColors: ["#5559B2", "#0BCF6B"],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    series: [
      {
        name: "Monthly Revenue",
        data: monthlyrevenuedata,
        // yaxis: 1,
      },
    ],
    tooltip: {
      x: {
        formatter: function (val) {
          return val;
        },
      },
    },
  };
  // // Monthly Graph revenue end //

  //     // // Monthly Graph energy Start //
  // // //------------------------Monthly Graph Start ------------------------//

  //  //monthly calculation

  const monthlyconsumtion =
    props.monthly_consumtion_graph && props.monthly_consumtion_graph.data.map((en) => en.energy);
  //   console.log("monthy consumtion",monthlyconsumtion)
  let monthlyconsumptiondata = [];
  for (let i = 0; i < monthlyconsumtion.length; i++) {
    monthlyconsumptiondata.push(Math.ceil(monthlyconsumtion[i]));
  }

  const consumptiondata = {
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "60%",
        borderRadius: 5,
      },
    },
    xaxis: {
      categories: monthpush,
    },
    yaxis: [
      {
        title: {
          text: "Monthly Consumption",
        },
      },
    ],
    dataLabels: {
      enabled: false,
    },
    //  stroke: {
    //      curve: "smooth",
    //  },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "vertical",
        shadeIntensity: 0.7,
        gradientToColors: ["#0BCF6B"],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    series: [
      {
        name: "Monthly Consumption",
        data: monthlyconsumptiondata,
      },
    ],
    tooltip: {
      x: {
        formatter: function (val) {
          return val;
        },
      },
    },
  };
  // // Monthly Graph energy Start //
  return (
    <Container fluid className="pb-4">
      <PureBreadcrumbs />
      <br></br>

      <Row>
        <Col xs={12} md={12}>
          <Card className="customercard">
            <Row>
              <Col xs={12} md={8}>
                <div className="row  float-left">
                  <img className="fleet-ico" src={"/images/fleet/fleet.svg"} alt="" />
                  Select Fleet :&nbsp;
                  <select
                    className="fleet-select mb-2 mr-3 form-control"
                    name="fleet_name"
                    onChange={handleChange}
                    id="floatingSelectGridU"
                  >
                    {props.fleet_list?.data &&
                      props.fleet_list.data.map((item, index) => (
                        <>
                          <option key={index} value={item.ownername}>
                            {item.ownername}
                          </option>
                        </>
                      ))}
                  </select>
                  <div className="row mb-2">
                    <div className="col-auto claender pr-1">
                      <DatePicker
                        className="gray-border"
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        selectsStart
                        startDate={startDate}
                        dateFormat="dd/MM/yyyy"
                        maxDate={new Date()}
                        onKeyDown={(e) => e.preventDefault()}
                      />
                    </div>
                    <span style={{ paddingTop: "7px" }}> To </span>
                    <div className="col-auto pl-1 mr-2">
                      <DatePicker
                        className="gray-border"
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        showDisabledMonthNavigation
                        maxDate={new Date()}
                        dateFormat="dd/MM/yyyy"
                        onKeyDown={(e) => e.preventDefault()}
                      />
                    </div>
                  </div>
                  <div className=" ">
                    <button className="btn btn-sm fleet-go-btn" onClick={loadNewData}>
                      <span
                        style={{
                          fontSize: "18px",
                          color: "rgb(59 52 52)",
                          fontWeight: "600",
                        }}
                      >
                        GO
                      </span>
                    </button>
                  </div>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="text-right">
                  &nbsp;&nbsp;
                  <a
                    className="refresh"
                    style={{
                      fontSize: "13px",
                      color: "var(--button-color)",
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                    onClick={handleAddClick}
                  >
                    Refresh &nbsp;
                    <img
                      style={{ height: "16px" }}
                      src={process.env.PUBLIC_URL + "/images/insight/refresh.svg"}
                      alt=""
                    />
                  </a>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col xs={12} md={4}>
          <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>TOTAL CONSUMPTION</Card.Text>
              <Card.Title>
                {props.monthly_revenue_graph.total_energy !== undefined ? props.monthly_revenue_graph.total_energy : 0}{" "}
                kWh
              </Card.Title>

              <span style={{ float: "right", marginTop: "-60px", marginRight: "-8px" }}>
                <div style={{ width: "20%" }}>
                  <img src={process.env.PUBLIC_URL + "/images/admin/totaluser.svg"} alt="evse" />
                </div>
              </span>
            </div>
          </Card>
          <br />
        </Col>
        <Col xs={12} md={4}>
          <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>TOTAL REVENUE</Card.Text>
              <Card.Title>
                {currency}{" "}
                {props.monthly_revenue_graph.total_revenue !== undefined
                  ? props.monthly_revenue_graph.total_revenue
                  : 0}
              </Card.Title>

              <span style={{ float: "right", marginTop: "-60px", marginRight: "-8px" }}>
                <div style={{ width: "50%" }}>
                  <img src={process.env.PUBLIC_URL + "/images/admin/approveduser.svg"} alt="evse" />
                </div>
              </span>
            </div>
          </Card>
          <br />
        </Col>
        <Col xs={12} md={4}>
          <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>TOTAL DISTANCE</Card.Text>
              <Card.Title>
                {props.monthly_revenue_graph.total_distance !== undefined
                  ? props.monthly_revenue_graph.total_distance
                  : 0}
              </Card.Title>

              <span style={{ float: "right", marginTop: "-60px", marginRight: "-8px" }}>
                <div style={{ width: "50%" }}>
                  <img src={process.env.PUBLIC_URL + "/images/admin/pendinguser.svg"} alt="evse" />
                </div>
              </span>
            </div>
          </Card>
          <br />
        </Col>
      </Row>

      {/* Graphs for revenue and consumption */}
      <Row>
        <Col xs={12} md={6}>
          <Card className="statuscard">
            <Card.Title className="text-center">Monthly Revenue</Card.Title>
            <Card.Body>
              {props.loading ? (
                <h3
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "250px",
                  }}
                >
                  Loading data...
                </h3>
              ) : monthlyrevenue && monthlyrevenue.length > 0 ? (
                <Chart options={monthdata} series={monthdata.series} type="bar" height={250} />
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "250px",
                  }}
                >
                  <h3>No data found </h3>
                </div>
              )}
              {/* <Chart
                            options={monthdata}
                            series={monthdata.series}
                            type="bar"
                            height={250}
                        /> */}
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={6}>
          <Card className="statuscard">
            <Card.Title className="text-center">Monthly Energy</Card.Title>
            <Card.Body>
              {props.loading ? (
                <h3
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "250px",
                  }}
                >
                  Loading data...
                </h3>
              ) : monthlyconsumtion && monthlyconsumtion.length > 0 ? (
                <Chart options={consumptiondata} series={consumptiondata.series} type="bar" height={250} />
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "250px",
                  }}
                >
                  <h3>No data found </h3>
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/* //transaction list table// */}
      <Row className="mt-4">
        <Col xs={12} md={12}>
          <Card className="customercard">
            <div className="table-header">Transaction Data</div>
            <div>
              {props.loading ? (
                <h3
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "250px",
                  }}
                >
                  Loading data...
                </h3>
              ) : transactionData && transactionData.length > 0 ? (
                <div>
                  {" "}
                  <Table data={transactionData} columns={transactionDatacolumns}></Table>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3 className="loader">No data found</h3>
                </div>
              )}
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    fleet_list: state.fleet.all_fleet_name,
    loading: state.fleet.isFleetLoading,
    report: state.fleet.fleet_report,
    monthly_consumtion_graph: state.fleet.monthly_cosumption_count,
    monthly_revenue_graph: state.fleet.monthly_revenue_count,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    all_fleet_name: () => dispatch(getAllFleetName()),
    fleet_report: (fleet, start, end) => dispatch(getFleetReport(fleet, start, end)),
    fleet_consumtion: (fleet, start, end) => dispatch(getMonthlyConsumptionReport(fleet, start, end)),
    fleet_revenue: (fleet, start, end) => dispatch(getMonthlyRevenueReport(fleet, start, end)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FleetReport);
