import React, { useEffect } from "react";
import PureBreadcrumbs from "../../../breadcrums";
import { Card, Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { fetchOcpiStationAction } from "../../../../../store/actions/ocpiAction";
import { useTranslation } from "react-i18next";
import { FaEye } from "react-icons/fa";
import Table from "../../../reacttable/table";
import OPCIStationMap from "./ocpiStationMap"

function OcpiStationCard(props) {
    const { t } = useTranslation();

    useEffect(() => {
        props.allOcpiStation();
    }, [])

    const data = props.fetchAllOcpiStationRes && props.fetchAllOcpiStationRes.data;
    // console.log("data", data)

    const columns = React.useMemo(
        () => [
            {
                Header: "Name",
                accessor: "name",
                id: "1",
            },
            {
                Header: "Address",
                accessor: "address",
            },
            {
                Header: "City",
                accessor: "city",
            },
            {
                Header: "State",
                accessor: "state",
            },
            {
                Header: "Actions",
                accessor: "id",
                Cell: ({ row }) => {
                    const { original } = row;
                    const { name, party_id } = original;
                    return (
                        <div>
                            <span>
                                <a
                                    href={`/dashboard/viewOcpiCpoStation/${name}`}
                                    rel="tooltip"
                                    title="View"
                                >
                                    <FaEye />
                                </a>
                            </span>
                            &nbsp;&nbsp;
                        </div>
                    );
                },
            },
        ],
        []
    );
    return (
        <Container fluid className="pb-4">
            <PureBreadcrumbs />
            <br />
            <OPCIStationMap {...props} data={data} />
            <Row>
                <Col>
                    <Card className="customercard">
                        <Card.Header>
                            <Row>
                                <div className="table-header">{t("View OCPI Station")}</div>
                            </Row>
                        </Card.Header>

                        {data && data.length > 0 ? (
                            <div>
                                <Table data={data} columns={columns}></Table>
                            </div>
                        ) : (
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <h3 className="loader">{t("No data found")}</h3>
                            </div>
                        )}
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}
const mapStateToProps = (state) => {
    return {
        loading: state.ocpi.isLoading,
        fetchAllOcpiStationRes: state.ocpi.fetchOcpiStation,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        allOcpiStation: () => dispatch(fetchOcpiStationAction()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OcpiStationCard);
