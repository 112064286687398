import React, { useMemo, useEffect } from "react"
import { connect } from "react-redux";
import "../../../reacttable/reactTable.css"
import moment from "moment";
import Table from "../../../reacttable/table";
import { allEmailCampaignAction, activateEmailAction, deactivateEmailAction } from "../../../../../store/actions/campaignAction";
import { FaEdit, FaEye } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { AiOutlineCloseCircle } from "react-icons/ai";


function EmailActivityList(props) {
  useEffect(() => {
    props.allEMail()
  }, [])

  const data = props.list && props.list.data
  console.log(data)

  const activate = (campaign_id) => {
    // console.log(campaign_id);
    props.activateAPI(campaign_id);
  };

  if (props.activate.statuscode === 200) {
    window.location.reload();
  }

  const deactivate = (campaign_id) => {
    // console.log(campaign_id);
    props.deactivateAPI(campaign_id);
  };

  if (props.deactivate.statuscode === 200) {
    window.location.reload();
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Recipients',
        accessor: 'recipients'
      },
      {
        Header: 'Email Subject',
        accessor: 'subject'
      },
      {
        Header: 'Message',
        accessor: 'message'
      },
      {
        Header: 'Operator',
        accessor: 'operator'
      },
      {
        Header: 'Start Date',
        id: 'date',
        accessor: d => {
          return moment(d.date)
            .local()
            .format("DD-MM-YYYY");
        }
      },
      {
        Header: 'Created At',
        id: 'createdat',
        accessor: d => {
          return moment(d.createdat)
            .local()
            .format("DD-MM-YYYY");
        }
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: (status) => {
          // console.log(publish.value)
          return (
            <div>
              <span>
                {status.value === "Active" ? (
                  <span className="text-success">Active</span>
                ) : (
                  <span className="text-danger">Inactive</span>
                )}
              </span>
            </div>
          );
        },
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: ({ row }) => {
          const { original } = row;
          const id = original.id;
          const campaign_id = original.campaign_id
          // console.log("id",id)

          return (
            <div>
              <span>
                <a
                  href={`/dashboard/viewSingleEmail/${id}`}
                  rel="tooltip"
                  title="View"
                >
                  <FaEye />
                </a>
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <span>
                <a
                  href={`/dashboard/updateEmail/${id}`}
                  rel="tooltip"
                  title="Edit"
                >
                  <FaEdit />
                </a>
              </span>
              &nbsp;&nbsp;  &nbsp;&nbsp;
              <span style={{ cursor: "pointer" }}>
                <TiTick
                  onClick={() => activate(campaign_id)}
                  title="Activate"
                />
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <span style={{ cursor: "pointer" }}>
                <AiOutlineCloseCircle
                  onClick={() => deactivate(campaign_id)}
                  title="De-Activate"
                />
              </span>
            </div>
          );
        },
      },
    ],
    []
  );


  return (
    <div>
      {props.loading ? <h3 style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "450px",
      }}>Loading data...</h3> :
        data && data.length > 0 ?
          (<div> <Table data={data} columns={columns}></Table></div>)
          :
          (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}><h3 className="loader">No data found</h3></div>)
      }
    </div>
  )
}


const mapStateToProps = (state) => {
  return {
    loading: state.campaign.isLoading,
    list: state.campaign.allemail,
    activate: state.campaign.activateEmail,
    deactivate: state.campaign.deactivateEmail,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    allEMail: () => dispatch(allEmailCampaignAction()),
    activateAPI: (campaign_id) => dispatch(activateEmailAction(campaign_id)),
    deactivateAPI: (campaign_id) => dispatch(deactivateEmailAction(campaign_id)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(EmailActivityList)