import React from 'react';
import jsPDF from "jspdf";
import "jspdf-autotable";
import "./../../pdf/pdf.css";
import { useTranslation } from 'react-i18next';

const ChargerPdf = (props) => {
    const { t } = useTranslation();
    const { data } = props;

    const generatePDF = (props) => {
        const { role, organisation } = JSON.parse(localStorage.getItem('user'));
        const { status, data } = props;
        const unit = "pt";
        const size = "A4";
        const orientation = "landscape";
        const marginLeft = 40;
        const marginTop = 40;
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(18);

        const title = "Organisation: " + organisation;
        const headers = [["EVSE ID", "Connector ID", "Station Name", "EVSE Status", "Address", "City", "State"]];

        const body_data = data && data
            .flatMap(station =>
                station?.evses?.flatMap(evse =>
                    evse.connectors?.map(item => {
                        if (status === item.status || status === "All") {
                            return [
                                item.evse_id,
                                item.id,
                                station.name,
                                item.status,
                                station.address,
                                station.city,
                                station.state,
                            ];
                        }
                        return null;
                    }).filter(row => row !== null)
                )
            );

        let content = {
            startY: 100,
            head: headers,
            body: body_data,
            didParseCell: function (data) {
                if (data.section === 'body' && data.column.index === 3) { // EVSE Status column (index 3)
                    let status = data.cell.raw; // Get the status text
                    switch (status) {
                        case "Available":
                            data.cell.styles.textColor = [0, 128, 0]; // Green
                            break;
                        case "In use":
                            data.cell.styles.textColor = [0, 30, 255]; // blue
                            break;
                        case "Discovery":
                            data.cell.styles.textColor = [255, 165, 0]; // orange
                            break;
                        case "Coming soon":
                            data.cell.styles.textColor = [128, 0, 128]; // Purple
                            break;
                        case "Maintenance":
                            data.cell.styles.textColor = [255, 0, 0]; // Red
                            break;
                        default:
                            data.cell.styles.textColor = [0, 0, 0]; // Default black
                    }
                }
            }
        };

        doc.text("All charger status report", marginLeft, 40);
        doc.text(title, marginLeft, 80);
        doc.autoTable(content);
        doc.save(`Stations_${new Date().toISOString().substr(0, 10)}.pdf`);
    }

    const handleButtonClick = async () => {
        generatePDF(props);
    };

    return (
        <div>
            <button className='pdfbutton' onClick={handleButtonClick} disabled={props.data === null} type="button">
                {t("Download PDF")}
            </button>
        </div>
    );
}

export default ChargerPdf;
