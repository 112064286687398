import React, { useEffect, useState } from 'react'
import PureBreadcrumbs from "../breadcrums";
import { Container, Button, Col } from 'react-bootstrap';
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { allEvseAction, fetchChargerSlots, fetchEvseStationAction, fetchTimeMasterListAction, saveChargerMasterSlots } from '../../../store/actions/evseAction';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import { Bounce, ToastContainer, toast } from 'react-toastify';

function ManageChargerBooking(props) {
    const { t } = useTranslation();
    const { stationid } = useParams()
    // console.log("stationid", stationid)
    const [selectedStartTimeIndex, setSelectedStartTimeIndex] = useState([]);
    const [evseId, setEvseID] = useState('');
    const [redirect, setRedirect] = useState(false);
    const [isToastVisible, setIsToastVisible] = useState(false);

    useEffect(() => {
        props.fetch(stationid);
        props.chargerSlotsData(stationid);
    }, [stationid]);

    useEffect(() => {
        props.getTimeMaster();
    }, []);

    const evsedata = props.fetchstation && props.fetchstation
    const data = props.chargerSlots && props.chargerSlots.evse_data
    const selectedEvseIdOperator = evsedata?.operator?.name;

    const bookingSchema = Yup.object().shape({
        evse_id: Yup.string().required('EVSE ID is required'),
        regular_hours: Yup.array().of(
            Yup.object().shape({
                weekday: Yup.number().required('Weekday is required'),
                period_begin: Yup.string().required('Start Time is required'),
                period_end: Yup.string().required('End Time is required'),
            }))
    });
    const { register, control, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(bookingSchema), mode: "all" });

    const { fields, append, remove } = useFieldArray({ name: 'regular_hours', control });
    // console.log("fields",fields)

    const onSubmit = async data => {
        // console.log("save", data, stationid,selectedEvseIdOperator)
        props.save(data, stationid, selectedEvseIdOperator)

    };

    const handleChangeEvseId = (e) => {
        const ID = e.target.value;
        setEvseID(ID)
        // console.log("evseid",ID)
    }

    const handleChange = (e, i) => {
        const selectedIndex = e.target.selectedIndex;
        setSelectedStartTimeIndex((prev) => {
            const newStartTimes = [...prev];
            newStartTimes[i] = selectedIndex;
            return newStartTimes;
        });
    }

    useEffect(() => {
        if (props.saveslots.statuscode === 200) {
            setIsToastVisible(true);
            toast.success('Booking slots has been created.', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    window.location.reload();
                },
            });
        } else if (props.saveslots.statuscode === 405) {
            setIsToastVisible(true);
            toast.error(`❌ Booking slots already exist for this charger.To make changes, you'll be redirected to the update page.`, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    setRedirect(true)
                },
            });
        }
    }, [props.saveslots]);

    if (redirect) {
        return <Redirect to={`/dashboard/updateChargerSlots/${evseId}`} />
    }

    return (
        <Container fluid>
            {isToastVisible && <div className="overlay" />}
            <ToastContainer
                position="top-center"
                autoClose={20}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ top: "10%", width: "auto", zIndex: "9999" }}
            />
            <div className="row">
                <div className="col-lg-6 col-sm-12">
                    <PureBreadcrumbs />
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-lg-8 col-sm-12">
                    <h3><b>
                        {t("Charger booking data for")} {evsedata.name}
                    </b></h3>
                </div>
            </div>
            <form autoComplete='off' onSubmit={e => e.preventDefault()}>
                <div className="row mt-3">
                    <Col lg={6} xs={10} className="">
                        <div className="form-floating">
                            <select className={`form-select form-control ${errors.evse_id ? 'is-invalid' : ''}`} name="evse_id" id="floatingSelectGridevse_id" {...register("evse_id")}
                                onChange={(e) => handleChangeEvseId(e)}
                            >
                                <option value={""}>{t("Select EVSE ID")} (*)</option>
                                {evsedata && evsedata.evses.map((v, k) =>
                                    <option key={k} value={v.evse_id}>{v.evse_id}</option>
                                )}
                            </select>
                            <div className="invalid-feedback">{errors.evse_id?.message}</div>
                        </div>
                    </Col>
                    <Col xs={2} className="">
                        <div>
                            <Button onClick={() => { append({ weekday: '', period_begin: '', period_end: '' }) }} > + </Button>
                        </div>
                    </Col>
                </div>
                <div className="row">
                    <div key={2} className="col-lg-6 col-sm-10 col-xs-6">
                        <div className="table-responsive">
                            <table className="table table-sm table-bordered">
                                <thead>
                                    <tr >
                                        <th scope="col">{t("Weekday")} (*)</th>
                                        <th scope="col">{t("Start Time")} (*)</th>
                                        <th scope="col">{t("End Time")} (*)</th>
                                        <th scope="col">{t("Action")}</th>

                                    </tr>
                                </thead>
                                {fields.map((item, i) => (
                                    <tbody key={i}>
                                        <tr>
                                            <td>
                                                <div className="form-floating">
                                                    <select className={`form-select form-control ${errors.regular_hours?.[i]?.weekday ? 'is-invalid' : ''}`} name={`regular_hours[${i}].weekday`} id="floatingSelectGridweekday" {...register(`regular_hours.${i}.weekday`)}
                                                    >
                                                        <option value="">{t("Select Weekday")}</option>
                                                        <option value="1">Monday</option>
                                                        <option value="2">Tuesday</option>
                                                        <option value="3">Wednesday</option>
                                                        <option value="4">Thursday</option>
                                                        <option value="5">Friday</option>
                                                        <option value="6">Saturday</option>
                                                        <option value="7">Sunday</option>

                                                    </select>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="form-floating">
                                                    <select className={`form-select form-control ${errors.regular_hours?.[i]?.period_begin ? 'is-invalid' : ''}`} name={`regular_hours[${i}].period_begin`} id="floatingSelectGridperiod_begin"  {...register(`regular_hours.${i}.period_begin`)}
                                                        onChange={(e) => handleChange(e, i)}>
                                                        <option value={""} >{t("Select Start Time")}</option>
                                                        {props.mastertimelist && props.mastertimelist.hours.map((v, k) =>
                                                            <option key={v.key} value={v.value}>{v.value}</option>
                                                        )}
                                                    </select>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="form-floating">
                                                    <select className={`form-select form-control ${errors.regular_hours?.[i]?.period_end ? 'is-invalid' : ''}`} name={`regular_hours[${i}].period_end`} id="floatingSelectGridperiod_end"  {...register(`regular_hours.${i}.period_end`)}>
                                                        <option value={""} >{t("Select End Time")}</option>
                                                        {props.mastertimelist && props.mastertimelist.hours.slice(selectedStartTimeIndex[i],).map((v, k) =>
                                                            <option key={v.key} value={v.value}>{v.value}</option>
                                                        )}
                                                    </select>
                                                </div>
                                            </td>
                                            <td>
                                                <Button onClick={(e) => { e.preventDefault(); remove(i); }}> -</Button>
                                            </td>
                                        </tr>

                                    </tbody>
                                ))}

                            </table>
                            <br />
                            <div className='d-flex justify-content-center'>
                                {fields.length > 0 && props.loadingevse ?
                                    <button className="btn lgn-btn" type="button" disabled>
                                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                        &nbsp;
                                        {t("Saving...")}
                                    </button>
                                    : <button
                                        className="lgn-btn btn "
                                        type="submit"
                                        onClick={handleSubmit(onSubmit)}
                                        disabled={
                                            fields.length === 0
                                        }
                                    >
                                        {t("Save")}
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </Container >
    )
}



const mapStateToProps = (state) => {
    return {
        loadingevse: state.evse.isEvseLoading,
        list: state.evse.allevse,
        fetchstation: state.evse.viewstation,
        saveslots: state.evse.savechargerslots,
        mastertimelist: state.evse.timemaster,
        chargerSlots: state.evse.chargerslots,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetch: (stationid) => dispatch(fetchEvseStationAction(stationid)),
        save: (data, stationid, selectedEvseIdOperator) => dispatch(saveChargerMasterSlots(data, stationid, selectedEvseIdOperator)),
        getTimeMaster: () => dispatch(fetchTimeMasterListAction()),
        all: () => dispatch(allEvseAction()),
        chargerSlotsData: (stationid) => dispatch(fetchChargerSlots(stationid))

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ManageChargerBooking)