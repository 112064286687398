import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import 'bootstrap/dist/css/bootstrap.min.css';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Card, Col, Container, Row, Button } from 'react-bootstrap';
import PureBreadcrumbs from "../breadcrums";
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { fetchSingleReconcilationApi } from '../../../store/actions/stripeAction';
import moment from 'moment';



const ViewSingleReconcilation = (props) => {
    const { t } = useTranslation();
    const { id } = useParams();

    useEffect(() => {
        props.fetchSingle(id);
    }, [id])

    const data = props.fetchSingleRes && props.fetchSingleRes.data;
    // console.log("data: ", data)

    const schema = yup.object().shape({
        amount: yup.number().required('Amount is required').positive('Amount must be positive').typeError('Amount is required'),
        transferredTo: yup.string().required('Transferred To is required'),
        gst: yup.number(),
        cgst: yup.number(),
        igst: yup.number(),
        sgst: yup.number(),
        paymentMode: yup.string().required('Payment mode is required'),
        utr: yup.string().nullable(),
        transferDate: yup.date().required('Transfer date is required').typeError('Invalid date format'),
        clientName: yup.string(),
        clientAccountNo: yup.string(),
        description: yup.string().nullable()
    });

    const { register, handleSubmit, setValue, getValues, formState: { errors, isValid } } = useForm({
        resolver: yupResolver(schema), mode: "all"
    });

    useEffect(() => {
        const { amount, cgst, sgst, gst, igst, client_name, client_account_number, payment_mode, description, transfer_date, transfered_to, utr } = data;
        if (data) {
            setValue("amount", amount);
            setValue("transferredTo", transfered_to);
            setValue("cgst", cgst);
            setValue("gst", gst);
            setValue("sgst", sgst);
            setValue("igst", igst);
            setValue("utr", utr);
            setValue("clientName", client_name);
            setValue("clientAccountNo", client_account_number);
            setValue("paymentMode", payment_mode);
            setValue("description", description);
            // Convert "15-06-2024" to "2024-06-15"
            const date = moment(transfer_date, 'DD-MM-YYYY').format('YYYY-MM-DD');
            setValue('transferDate', date);
        }

    }, [data, setValue])



    return (
        <Container fluid>
            <PureBreadcrumbs />
            <Row className='mt-5'>
                <Col md={{ span: 12 }}>
                    <Card>
                        <Card.Body>
                            <h3>{t("View Single Manual Reconciliation")}</h3>
                            <div className="mt-4">
                                <form className="row g-3">
                                    <div className="col-md-6 py-1">
                                        <label>{t("Transferred To")} <span className='mandatory-field'>*</span></label>
                                        <input
                                            type="text"
                                            placeholder={t("Enter Transferred To")}
                                            className={`gray-border form-control `}
                                            disabled
                                            {...register('transferredTo')}
                                        />
                                    </div>

                                    <div className="col-md-6 py-1">
                                        <label>{t("Amount")} <span className='mandatory-field'>*</span></label>
                                        <input
                                            type="number"
                                            placeholder={t("Enter Amount")}
                                            className={`gray-border form-control `}
                                            disabled
                                            {...register('amount')}
                                        />
                                    </div>

                                    <div className="col-md-3 py-1">
                                        <label>{t("GST")}</label>
                                        <input
                                            type="number"
                                            placeholder={t("Enter GST")}
                                            className={`gray-border form-control `}
                                            disabled
                                            {...register('gst')}
                                        />
                                    </div>

                                    <div className="col-md-3 py-1">
                                        <label>{t("CGST")}</label>
                                        <input
                                            type="number"
                                            placeholder={t("Enter CGST")}
                                            className={`gray-border form-control `}
                                            disabled
                                            {...register('cgst')}
                                        />
                                    </div>

                                    <div className="col-md-3 py-1">
                                        <label>{t("IGST")}</label>
                                        <input
                                            type="number"
                                            placeholder={t("Enter IGST")}
                                            className={`gray-border form-control `}
                                            disabled
                                            {...register('igst')}
                                        />
                                    </div>

                                    <div className="col-md-3 py-1">
                                        <label>{t("SGST")}</label>
                                        <input
                                            type="number"
                                            placeholder={t("Enter SGST")}
                                            className={`gray-border form-control `}
                                            disabled
                                            {...register('sgst')}
                                        />
                                    </div>

                                    <div className="col-md-4 py-1">
                                        <label>{t("Payment Mode")} <span className='mandatory-field'>*</span></label>
                                        <input
                                            type="text"
                                            placeholder={t("Enter Payment Mode")}
                                            className={`gray-border form-control`}
                                            disabled
                                            {...register('paymentMode')}
                                        />
                                    </div>

                                    <div className="col-md-4 py-1">
                                        <label>{t("UTR")}</label>
                                        <input
                                            type="text"
                                            placeholder={t("Enter UTR")}
                                            className={`gray-border form-control `}
                                            disabled
                                            {...register('utr')}
                                        />
                                    </div>

                                    <div className="col-md-4 py-1">
                                        <label>{t("Transfer Date")} <span className='mandatory-field'>*</span></label>
                                        <input
                                            type="date"
                                            className={`gray-border form-control`}
                                            disabled
                                            {...register('transferDate')}
                                        />
                                    </div>

                                    <div className="col-md-6 py-1">
                                        <label>{t("Client Name")}</label>
                                        <input
                                            type="text"
                                            placeholder={t("Enter Client Name")}
                                            className={`gray-border form-control`}
                                            disabled
                                            {...register('clientName')}
                                        />
                                    </div>
                                    <div className="col-md-6 py-1">
                                        <label>{t("Client Account No.")}</label>
                                        <input
                                            type="text"
                                            placeholder={t("Enter Payment Detail")}
                                            className={`gray-border form-control`}
                                            disabled
                                            {...register('clientAccountNo')}
                                        />
                                    </div>

                                    <div className="col-md-12 py-1">
                                        <label>{t("Description")}</label>
                                        <textarea
                                            placeholder={t("Enter Description")}
                                            className={`gray-border form-control `}
                                            {...register('description')}
                                            disabled
                                        ></textarea>
                                    </div>
                                </form>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

const mapStateToProps = (state) => {
    return {
        isLoading: state.stripe.isLoading,
        fetchSingleRes: state.stripe.fetchSingleReconcilation,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchSingle: (id) => dispatch(fetchSingleReconcilationApi(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewSingleReconcilation);
