import React, { useEffect, useState } from 'react'
import "react-datepicker/dist/react-datepicker.css";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Bounce, ToastContainer, toast } from 'react-toastify';

const ChargingProfileComponent = (props) => {
    const { t } = useTranslation();
    const profileSchema = Yup.object().shape({
        evse_id: Yup.string().required('EVSE ID is required'),
        portno: Yup.string().required('Connector ID is required'),
        id: Yup.string().required('Charging Profile ID is required'),
        stackLevel: Yup.string().required('Stack Level is required'),

    });

    const [evse_id, setevse_id] = useState();
    const [isToastVisible, setIsToastVisible] = useState(false);

    const evsedata = props.fetchstation && props.fetchstation
    const conn = evsedata && evsedata.evses
    const zxc = conn && conn.map(en => en.connectors)
    const data = Object.values(zxc).flatMap(arr => arr)

    const handleChargerChange = (e) => {
        const name = e.target.value
        setevse_id(name)
    };
    const { register, handleSubmit, reset, formState: { errors } } = useForm({ resolver: yupResolver(profileSchema) });

    const onSubmit = async data => {
        // console.log(data)
        props.clearChargingProfile(data)
    };
    // if (props.clearChargingProfile.statuscode === 200) {
    //     alert("Success!!! ")
    // }

    useEffect(() => {
        if (props.clearChargingProfile.statuscode === 200) {
            setIsToastVisible(true);
            toast.success('Success!!!', {
                position: "top-center",
                autoClose: 300,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    window.location.reload();
                },
            });
        }
    }, [props.clearChargingProfile]);

    return (
        <div className='container'>

            {isToastVisible && <div className="overlay" />}
            <ToastContainer
                position="top-center"
                autoClose={20}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ top: "10%", width: "auto", zIndex: "9999" }}
            />
            <form onSubmit={e => e.preventDefault()}>
                <div className="form-floating">
                    <select className={`form-select form-control ${errors.evse_id ? 'is-invalid' : ''}`} name="evse_id" id="floatingSelectGridevse_id" onChange={handleChargerChange} {...register("evse_id")}>
                        <option value={""} >{t("Select EVSE ID")} (*)</option>
                        {evsedata && evsedata.evses.map((own) => (
                            <option key={own.evse_id} value={own.evse_id}>{own.evse_id}</option>
                        ))}
                    </select>
                    <div className="invalid-feedback">{errors.evse_id?.message}</div>
                </div><br />
                <div className="form-floating">
                    <select className={`form-select form-control ${errors.portno ? 'is-invalid' : ''}`} name="portno" id="floatingSelectGridportno" {...register("portno")}>
                        <option value={""} >{t("Select Connector ID")} (*)</option>
                        {data && data.map((own) => (
                            <option key={own.id} value={own.id}> {own.id}</option>
                        ))}
                    </select>
                    <div className="invalid-feedback">{errors.portno?.message}</div>
                </div><br />

                <div className="form-floating">
                    <input type="text" className={`form-control gray-border ${errors.id ? 'is-invalid' : ''}`} name="id" id="floatingInputID" placeholder={t("Charging Profile ID (*)")} {...register("id")} />
                    <div className="invalid-feedback">{errors.id?.message}</div>
                </div><br />
                <div className="form-floating">
                    <input type="text" className={`form-control gray-border ${errors.stackLevel ? 'is-invalid' : ''}`} name="stackLevel" id="floatingInputstackLevel" placeholder={t("Stack Level (*)")} {...register("stackLevel")} />
                    <div className="invalid-feedback">{errors.stackLevel?.message}</div>
                </div><br />
                <div className="col-xl-6 col-sm-6 col-12 float-right p-4">
                    <button className="lgn-btn btn" type="submit" onClick={handleSubmit(onSubmit)}>{t("Clear Charging Profile")}</button>
                </div>
            </form>
        </div>
    )
}

export default ChargingProfileComponent;