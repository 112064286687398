import React, { useEffect } from "react"
import { useParams, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { fetchSingleConnectAccount } from "../../../store/actions/stripeAction";
import { Container } from "react-bootstrap";
import PureBreadcrumbs from "../breadcrums";
import "./finance.css"
import { TiTick } from "react-icons/ti";
import { ImCross } from "react-icons/im";
import { BsBank2 } from "react-icons/bs";
import moment from "moment";
import { useTranslation } from "react-i18next";


function SingleConnectBankInfo(props) {
    const { id } = useParams()
    const { t } = useTranslation();

    useEffect(() => {
        props.fetchSingleConnectAccount(id);
    }, []);
    const data = props.getSingleConnectAccount && props.getSingleConnectAccount.data && props.getSingleConnectAccount.data[0]
    //    console.log(data);
    const cardPayment = data.charges_enabled ? <div style={{ color: "green", marginRight: "10px" }}> Active <TiTick /></div> : <div style={{ color: "red" }}> Inactive <ImCross /> </div>
    const TransferPayment = data.payouts_enabled ? <div style={{ color: "green", marginRight: "10px" }}> Active <TiTick /></div> : <div style={{ color: "red" }}> Inactive <ImCross /> </div>
    const createtDate = moment(data.created * 1000).format('DD/MM/YYYY');
    // console.log("createtDate", createtDate);

    return (
        <Container fluid>
            <PureBreadcrumbs />
            <div className="row">
                <div className="col pad_t2">
                    <span style={{ color: "var(--text-primary)", fontWeight: "bold", fontSize: 32 }}> {t("Account Overview")} </span>
                </div>

            </div>
            <div className="row">
                <div className="col pad_t2">
                    <span style={{ color: "var(--text-primary)", fontWeight: "bold", fontSize: 22 }}> {t("Profile Details")} </span>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-lg-6">
                    <label htmlFor="floatingInputGridEmail">{t("Email")}</label>
                    <input className="form-control gray-border" type="text" name="UserID" id="floatingInputGridEmail" placeholder={t("Email")} defaultValue={data.email} disabled />
                </div>
                <div className="col-lg-6">
                    <label htmlFor="floatingInputGridAccountType">{t("Account Type")}</label>
                    <input className="form-control gray-border" type="text" name="Rating" id="floatingInputGridAccountType" placeholder={t("Account Type")} defaultValue={data.type} disabled />
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-lg-6">
                    <label htmlFor="floatingInputGridCountry">{t("Country")}</label>
                    <input className="form-control gray-border" type="text" name="Country" id="floatingInputGridCountry" placeholder={t("Country")} defaultValue={data.country} disabled />
                </div>
                <div className="col-lg-6">
                    <label htmlFor="floatingInputGridDefaultCurrency">{t("Default Currency")}</label>
                    <input className="form-control gray-border" type="text" name="Default Currency" id="floatingInputGridDefaultCurrency" placeholder={t("Default Currency")} defaultValue={data.default_currency} disabled />
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-lg-6">
                    <label htmlFor="floatingInputGridCreatedDate">{t("Created Date")}</label>
                    <input className="form-control gray-border" type="text" name="created" id="floatingInputGridCreatedDate" placeholder={t("Created Date")} value={createtDate ? createtDate : "Not Available"} disabled />
                </div>
                <div className="col-lg-6">
                    <label htmlFor="floatingInputGridAccountId">{t("Account ID")}</label>
                    <input className="form-control gray-border" type="text" name="Account ID" id="floatingInputGridAccountId" placeholder={t("Account ID")} defaultValue={data.id} disabled />
                </div>
            </div>


            <div className="row">
                <div className="col pad_t2">
                    <span style={{ color: "var(--text-primary)", fontWeight: "bold", fontSize: 22 }}> {t("Capabilities")} </span>
                </div>
            </div>
            <div className="row mt-4">
                <div className="flex-parent-element col-lg-6">
                    <div className="flex-child-element"><label htmlFor="floatingInputCardPayment">{t("Card payments")}</label></div>
                    <div className="flex-child-element"> {cardPayment}</div>
                </div>

                <div className="flex-parent-element col-lg-6">
                    <div className="flex-child-element"><label htmlFor="floatingInputTransferPayment">{t("Transfers")}</label></div>
                    <div className="flex-child-element"> {TransferPayment}</div>
                </div>
            </div>


            <div className="row mb-3">
                <div className="col pad_t2">
                    <span style={{ color: "var(--text-primary)", fontWeight: "bold", fontSize: 22 }}> {t("Payout information")} </span>
                </div>
            </div>


            {data && data.external_accounts && data.external_accounts.data.map((item, index) => (
                <ul>
                    <div style={{ fontWeight: "600" }}><BsBank2 size={25} /> &nbsp;&nbsp;{item.BankAccount.bank_name}</div>
                    <div className="row mt-4">
                        <div className="flex-parent-element col-lg-6">
                            <div className="flex-child-element"><label htmlFor="floatingInputaccount_holder_name">{t("Account holder name")}</label></div>
                            <div className="flex-child-element"> {item.BankAccount.account_holder_name}</div>
                        </div>

                        <div className="flex-parent-element col-lg-6">
                            <div className="flex-child-element"><label htmlFor="floatingInputaccount_holder_type">{t("Account holder type")}</label></div>
                            <div className="flex-child-element"> {item.BankAccount.account_holder_type}</div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="flex-parent-element col-lg-6">
                            <div className="flex-child-element"><label htmlFor="floatingInputGridCountry">{t("Country")}</label></div>
                            <div className="flex-child-element"> {item.BankAccount.country}</div>
                        </div>

                        <div className="flex-parent-element col-lg-6">
                            <div className="flex-child-element"><label htmlFor="floatingInputGridCurrency">{t("Currency")}</label></div>
                            <div className="flex-child-element"> {item.BankAccount.currency}</div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="flex-parent-element col-lg-6">
                            <div className="flex-child-element"><label htmlFor="floatingInputGridFingerprint">{t("Fingerprint")}</label></div>
                            <div className="flex-child-element"> {item.BankAccount.fingerprint}</div>
                        </div>

                        <div className="flex-parent-element col-lg-6">
                            <div className="flex-child-element"><label htmlFor="floatingInputGridRouting">{t("Routing Number")}</label></div>
                            <div className="flex-child-element"> {item.BankAccount.routing_number}</div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="flex-parent-element col-lg-6">
                            <div className="flex-child-element"><label htmlFor="floatingInputGridlast4">{t("Last4")}</label></div>
                            <div className="flex-child-element"> {item.BankAccount.last4}</div>
                        </div>


                    </div>

                </ul>
            ))}
        </Container>

    )
}

const mapStateToProps = (state) => {
    return {
        getSingleConnectAccount: state.stripe.getSingleAcountInfo
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        fetchSingleConnectAccount: (id) => dispatch(fetchSingleConnectAccount(id)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SingleConnectBankInfo));
