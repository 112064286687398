import React, { useEffect } from 'react'
import { useForm } from "react-hook-form";
import { Card, Row, Col, Container } from "react-bootstrap";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { connect } from "react-redux";
import { useState } from "react";
import PureBreadcrumbs from "../../breadcrums";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./../advertisement.css"
import 'react-clock/dist/Clock.css';
import { useTranslation } from 'react-i18next';
import { allEvseAction, fetchEvseStationAction, fetchTimeMasterListAction } from '../../../../store/actions/evseAction';
import { Redirect, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { updateAdvertise, viewSingle } from '../../../../store/actions/advertisementAction';
import { Bounce, ToastContainer, toast } from 'react-toastify';

const UpdateAdvertise = (props) => {
    const { id } = useParams()
    const { t } = useTranslation();
    const { role, organisation } = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        props.all();
        props.getTimeMaster();
        props.getSingleAdvertiseData(id);
    }, []);

    const data = props.getData && props.getData.data
    // console.log(data);
    const advertiseId = data[0]?.advertiseid;
    const [startdate, setstartdate] = useState(null);
    const [enddate, setenddate] = useState(null);
    const [redirect, setRedirect] = useState(false);
    const [isToastVisible, setIsToastVisible] = useState(false);

    const advertiseSchema = Yup.object().shape({
        stationid: Yup.string(),
        evse_id: Yup.string().required('EVSE ID is required'),
        name: Yup.string().required('Advertisement Name is required'),
        description: Yup.string().required('Advertisement Description is required'),
        text: Yup.string().required('Advertisement content is required'),
        advertismentFor: Yup.string().required('AdvertisementFor is required'),
        startTime: Yup.string(),
        endTime: Yup.string(),
        imagevalue: Yup.mixed()
    });
    const stationName = props.list && props.list.data?.filter((station) => station.stationid === data[0]?.stationid)
    //  console.log(stationName)
    const { register, handleSubmit, reset, setValue, formState: { errors, isValid } } = useForm({ resolver: yupResolver(advertiseSchema), mode: "all" });
    useEffect(() => {
        if (data[0]) {

            // setValue('stationid', stationName[0]?.stationid);
            setValue('evse_id', data[0].evse_id, { shouldValidate: true });
            setValue('name', data[0].name);
            setValue('text', data[0].text);
            setValue('advertismentFor', data[0].advertisement_for);
            setValue('description', data[0].description);
            setValue('startTime', data[0].starttime, { shouldValidate: true });
            setValue('endTime', data[0].endtime, { shouldValidate: true });
        }
    }, [data]);

    const previousStartDate = data && data[0]?.startdate;
    const previousEndDate = data && data[0]?.enddate;
    // console.log("previousEndDate",previousEndDate)
    const PrevStartDate = previousStartDate ? new Date(previousStartDate) : null;
    const PrevEndDate = previousEndDate ? new Date(previousEndDate) : null;


    const onSubmit = async data => {
        const formData = new FormData();

        formData.append('stationid', stationName[0]?.stationid);
        formData.append('evse_id', data.evse_id);
        formData.append('name', data.name);
        formData.append('description', data.description);
        formData.append('text', data.text);
        formData.append('advertisement_for', data.advertismentFor);
        formData.append('starttime', data.startTime);
        formData.append('endtime', data.endTime);
        formData.append('startdate', startdate ? startdate.toISOString() : PrevStartDate.toISOString());
        formData.append('enddate', enddate ? enddate.toISOString() : PrevEndDate.toISOString());
        formData.append('role', role);
        formData.append('organisation', organisation);
        formData.append('advertiseid', advertiseId);
        if (data.imagevalue) {
            formData.append('imagevalue', data.imagevalue[0]);
        }

        // console.log('stationid:', formData.get('stationid'));
        // console.log('evse_id:', formData.get('evse_id'));
        // console.log('name:', formData.get('name'));
        // console.log('description:', formData.get('description'));
        // console.log('text:', formData.get('text'));
        // console.log('advertisement_for:', formData.get('advertisement_for'));
        // console.log('starttime:', formData.get('starttime'));
        // console.log('endtime:', formData.get('endtime'));
        // console.log('startdate:', formData.get('startdate'));
        // console.log('enddate:', formData.get('enddate'));
        // console.log('role:', formData.get('role'));
        // console.log('organisation:', formData.get('organisation'));
        // console.log('advertiseid:', formData.get('advertiseid'));

        // if (data.imagevalue) {
        //     console.log('imagevalue:', formData.get('imagevalue'));
        // }

        if (startdate === null && enddate === null) {
            // console.log("data saved with both previous date successfully", formData);
            props.updateAdv(formData)
        } else if (startdate !== null && enddate === null) {
            // console.log("data saved with  startdate, PrevEndDate successfully", formData);
            props.updateAdv(formData)
        } else if (startdate === null && enddate !== null) {
            // console.log("data saved with  PrevStartDate, enddate successfully", formData);
            props.updateAdv(formData)
        }
        else if (PrevStartDate !== null && PrevEndDate !== null) {
            // console.log("data saved with both selected date successfully", formData);
            props.updateAdv(formData)
        }
    }

    useEffect(() => {
        if (props.updateRes.statuscode === 200) {
            setIsToastVisible(true);
            toast.success('Advertisement update successfully', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    setRedirect(true)
                },
            });
        } else if (props.updateRes.statuscode === 405) {
            setIsToastVisible(true);
            toast.error(`❌ Error updating advertisement`, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    window.location.reload();
                },
            });
        }
    }, [props.updateRes]);

    if (redirect) {
        return <Redirect to="/dashboard/view_advertisement" />;
    }

    return (
        <>
            <Container fluid className="pb-4">
                {isToastVisible && <div className="overlay" />}
                <ToastContainer
                    position="top-center"
                    autoClose={20}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                    style={{ top: "10%", width: "auto", zIndex: "9999" }}
                />
                <PureBreadcrumbs />
                <br />
                <Row>
                    <Col xs={12} md={1}>
                    </Col>
                    <Col xs={12} md={10}>
                        <br />
                        <Card className="tariffcard">
                            <Card.Text style={{ fontSize: "14px", padding: "10px", paddingLeft: "15px" }}><b>{t("Update Advertisement")}</b></Card.Text>
                            <Card.Body>
                                <form onSubmit={e => e.preventDefault()}>
                                    <Row>
                                        <Col xs={12} md={6} className='py-2'>
                                            <label>{t("Selected Station")} <span className='mandatory-field'>*</span></label>
                                            <input disabled type="text" className={`form-control gray-border`} name="name" id="floatingInputGridname" placeholder="Advertisement Name" value={stationName[0]?.name || ''} />

                                        </Col>
                                        <Col xs={12} md={6} className='py-2'>
                                            <label>{t("Selected EVSE ID")} <span className='mandatory-field'>*</span></label>
                                            <input disabled type="text" className={`form-control gray-border ${errors.name ? 'is-invalid' : ''}`} name="name" id="floatingInputGridname" placeholder="Advertisement Name" {...register("evse_id")} />
                                        </Col>
                                    </Row>

                                    <div className="form-floating py-2">
                                        <label>{t("Advertisment For")} ? <span className='mandatory-field'>*</span></label>
                                        <select className={`form-select form-control ${errors.advertismentFor ? 'is-invalid' : ''}`} name="advertismentFor" id="floatingSelectGridAdvertismentFor" {...register("advertismentFor")}>
                                            <option value={""}>{t("Select Advertisment For")}</option>
                                            <option value={"app"}>{t("App")}</option>
                                            <option value={"charger"}>{t("Charger")}</option>
                                        </select>
                                        <div className="invalid-feedback">{errors.advertismentFor?.message}</div>
                                    </div>

                                    <div className="form-floating py-2">
                                        <label>{t("Advertisement Name")} <span className='mandatory-field'>*</span></label>
                                        <input type="text" className={`form-control gray-border ${errors.name ? 'is-invalid' : ''}`} name="name" id="floatingInputGridname" placeholder="Advertisement Name" {...register("name")} />
                                        <div className="invalid-feedback">{errors.name?.message}</div>
                                    </div>

                                    <div className="form-floating py-2">
                                        <label>{t("Description")} <span className='mandatory-field'>*</span></label>
                                        <input type="text" className={`form-control gray-border ${errors.description ? 'is-invalid' : ''}`} name="description" id="floatingInputGriddescription" placeholder="Description" {...register("description")} />
                                        <div className="invalid-feedback">{errors.description?.message}</div>
                                    </div><br />

                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <div className="row sa_box">
                                                <div className="col-lg-8 col-sm-12">
                                                    <div className="row breadcrumb-item active" aria-current="page">{t("Schedule Advertisement")}</div>
                                                </div>
                                            </div>
                                        </ol>
                                    </nav>

                                    <div className="row">
                                        <div className="card sh_ad">
                                            <div className="card-body">
                                                <div className="row sh_ad">
                                                    <div className="col-lg-3 col-sm-12">
                                                        <div className="row pad_r1">
                                                            {t("Start date")}  <span className='mandatory-field'>*</span>
                                                        </div>
                                                        <div className="row pad_r1">
                                                            <DatePicker
                                                                className='gray-border'
                                                                selected={startdate || PrevStartDate}
                                                                name="start"
                                                                onChange={(date) => setstartdate(date)}
                                                                selectsStart
                                                                startdate={startdate || PrevStartDate}
                                                                dateFormat="dd/MM/yyyy"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-sm-12">
                                                        <div className="row pad_r1">
                                                            {t("Start Time")}  <span className='mandatory-field'>*</span>
                                                        </div>
                                                        <div className="form-floating">
                                                            <select className={`form-select form-control ${errors.startTime ? 'is-invalid' : ''} `} name={`startTime`} id="floatingSelectGridperiod_begin"  {...register(`startTime`)}>
                                                                <option value={""} >{t("Select Start Time")}</option>
                                                                {props.mastertimelist && props.mastertimelist.hours?.map((v, k) =>
                                                                    <option key={v.key} value={v.value}>{v.value}</option>
                                                                )}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-sm-12">
                                                        <div className="row pad_r1">
                                                            {t("End date")} <span className='mandatory-field'>*</span>
                                                        </div>
                                                        <div className="row pad_r1">
                                                            <DatePicker
                                                                className='gray-border'
                                                                selected={enddate || PrevEndDate}
                                                                onChange={(date) => setenddate(date)}
                                                                selectsEnd
                                                                startdate={startdate || PrevStartDate}
                                                                enddate={enddate || PrevEndDate}
                                                                minDate={startdate || PrevStartDate}
                                                                showDisabledMonthNavigation
                                                                dateFormat="dd/MM/yyyy"
                                                            />
                                                            <div className="invalid-feedback">{errors.enddate?.message}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-sm-12">
                                                        <div className="row pad_r1">
                                                            {t("End time")} <span className='mandatory-field'>*</span>
                                                        </div>
                                                        <div className="form-floating">
                                                            <select className={`form-select form-control ${errors.endTime ? 'is-invalid' : ''} `} name={`endTime`} id="floatingSelectGridperiod_end"  {...register(`endTime`)}>
                                                                <option value={""}>{t("Select End Time")}</option>
                                                                {props.mastertimelist && props.mastertimelist.hours?.map((v, k) =>
                                                                    <option key={v.key} value={v.value}>{v.value}</option>
                                                                )}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row ">
                                                    <div className="col-lg-6 col-sm-12 py-2">
                                                        <label>{t("Choose the file")}</label>
                                                        <input type="file"
                                                            name="imagevalue"
                                                            accept="image/*"
                                                            required
                                                            className={`form-control advertismentimagevalue ${errors.imagevalue ? 'is-invalid' : ''}`}
                                                            style={{ paddingBottom: "2.2rem" }}
                                                            {...register("imagevalue")}
                                                        />
                                                        <div className="invalid-feedback">{errors.imagevalue?.message}</div>
                                                    </div>
                                                    <div className="col-lg-6 col-sm-12 py-2">
                                                        <label>{t("Advertisement Content")}<span className='mandatory-field'>*</span></label>
                                                        <input name="text" {...register("text")} type="text" id="floatingCPText" placeholder="Enter description to be shown on charger" className={`form-control gray-border ${errors.text ? 'is-invalid' : ''}`} />
                                                        <div className="invalid-feedback">{errors.text?.message}</div>
                                                    </div>
                                                </div>
                                                <div className="row sh_ad">
                                                    <div className="col-lg-12 col-sm-12">
                                                        <div className="">{t("Advertisement (Image/Video)")}</div>

                                                        <React.Fragment >
                                                            {data[0]?.imagekey === "" ||
                                                                data[0]?.imagekey === "image" ||
                                                                data[0]?.imagekey === "video" ? (
                                                                data[0]?.imagekey === "image" ? (
                                                                    <img
                                                                        src={data[0]?.imagevalue}
                                                                        className={`mt-3 object-fit-contain videoContainer`}
                                                                        alt={data[0]?.imagekey}
                                                                    />
                                                                ) : data[0]?.imagekey === "video" ? (
                                                                    <video
                                                                        src={data[0]?.imagevalue}
                                                                        className={`mt-3 object-fit-contain videoContainer`}
                                                                        loop
                                                                        autoPlay
                                                                        muted
                                                                    ></video>
                                                                ) : (
                                                                    <b>{t("No advertisement available")}</b>
                                                                )
                                                            ) : null}
                                                        </React.Fragment>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                    <br></br>
                                    <div>
                                        <Row className='d-flex justify-content-end'>
                                            {/* <Col>
                                                <button className="lgn-btn btn" type="button" onClick={() => {
                                                    reset({
                                                        name: '', description: '',
                                                        startDate: setstartdate(null),
                                                        endDate: setenddate(null),
                                                    });
                                                }}>{t("Reset")}</button>
                                            </Col><br /> */}
                                            <Col lg={6} className='d-flex justify-content-end'>
                                                {props.loading ?
                                                    <button className="btn lgn-btn" type="button" disabled>
                                                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                        &nbsp;
                                                        {t("Saving...")}
                                                    </button>
                                                    :
                                                    <button className="lgn-btn btn" type="submit" disabled={!isValid} onClick={handleSubmit(onSubmit)}>{t("UPDATE ADVERTISEMENT")}</button>
                                                }
                                            </Col>
                                        </Row>
                                    </div><br />
                                </form>
                            </Card.Body>
                            <br /> <br />
                        </Card>
                    </Col>
                    <Col xs={12} md={1}>
                    </Col>
                </Row>
            </Container>
        </>
    )
}


const mapStateToProps = (state) => {
    return {
        loading: state.advertise.isLoading,
        list: state.evse.allevse,
        fetchstation: state.evse.viewstation,
        mastertimelist: state.evse.timemaster,
        getData: state.advertise.fetchSingle,
        updateRes: state.advertise.updateAPI,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        all: () => dispatch(allEvseAction()),
        fetch: (stationid) => dispatch(fetchEvseStationAction(stationid)),
        getTimeMaster: () => dispatch(fetchTimeMasterListAction()),
        getSingleAdvertiseData: (id) => dispatch(viewSingle(id)),
        updateAdv: (formData) => dispatch(updateAdvertise(formData)),

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UpdateAdvertise)