import React from 'react';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useTranslation } from 'react-i18next';

const DownloadRfidTransactionPdf = (props) => {
    const { t } = useTranslation();
    const gstNumber = process.env.REACT_APP_CLIENT_GST_NO;
    const { data, rfid, totalAmount, totalKwh } = props;
    // console.log("props", props);

    const generatePDF = () => {
        const unit = "pt";
        const size = "a4"; // Use 'a4', 'letter', or other standard page sizes
        const orientation = "landscape";
        const marginLeft = 40;
        const marginTop = 40;

        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(12);
        const { organisation } = JSON.parse(localStorage.getItem('user'));
        const org_name = organisation === "All" ? "All" : organisation;
        const title = "Title: Rfid Transaction Details";
        const total_amount = "Total amount : " + totalAmount.toString();
        const total_kwh = "Total kWh : " + totalKwh.toString();
        const formatDate = (date) => {
            const day = date.getDate().toString().padStart(2, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const year = date.getFullYear();

            return `${day}/${month}/${year}`;
        };

        const startDateFormatted = formatDate(props.startDate);
        const endDateFormatted = formatDate(props.endDate);

        const range = "Date range: " + startDateFormatted + " to " + endDateFormatted;
        // const total_kwh = "Total kWh consumed: " + props?.total_kwh + "kWh";
        // const total_amount = `Total amount paid: ${props?.total_amount}`;
        // const co2_saved = "Total Co2 saved: " + props?.co2_saved;
        // const gst_number = "GST Number: " + gstNumber;
        const rfid_number = "RFID No : " + rfid;

        const headers = [
            [
                "Charging Id",
                "Date",
                "RFID No.",
                "Station Name",
                "ChargeBox Id",
                "Connector Id",
                "Charging Time",
                "Consume Energy",
                "Total Amount"
            ]
        ];

        const bodyData = data?.map(elt => [
            elt.charging_id,
            elt.start,
            elt.idtag,
            elt.stationid,
            elt.evse_id,
            elt.portno,
            elt.consume_time,
            elt.kwh,
            elt.total_amount
        ]);

        doc.text(title, 40, 20);
        doc.text(rfid_number, marginLeft, marginTop + 20);
        doc.text(range, marginLeft, marginTop);
        doc.text(total_kwh, marginLeft, marginTop + 40);
        doc.text(total_amount, marginLeft, marginTop + 60);

        doc.autoTable({
            startY: marginTop + 70,
            head: headers,
            body: bodyData,
            theme: 'grid', // 'striped', 'grid', 'plain'
            styles: { halign: 'center' },
            columnStyles: {
                0: { halign: 'left' }
            }
        });

        doc.save(`${org_name}_RFID_Transaction_data.pdf`);
    };

    const handleButtonClick = () => {
        generatePDF();
        // props.onClick();
    };
    // console.log(data === null ? true : false)

    return (
        <div>
            <button className='pdfbutton' disabled={data === null ? true : false} onClick={handleButtonClick} type="button">{t("PDF(CUSTOMER)")}</button>
        </div>
    );
};

export default DownloadRfidTransactionPdf;
