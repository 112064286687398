import { Container, Row, Col, Card, Accordion, Button } from "react-bootstrap";
import React, { useEffect, useMemo, useState } from "react";
import { useParams, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getUserAuthLog, getUserChargingHistory, singleCutomersAction } from "../../../../store/actions/customerActions";
import { FcAndroidOs } from "react-icons/fc"
import { AiFillApple } from "react-icons/ai"
import PureBreadcrumbs from "../../breadcrums";
import Table from "../../reacttable/table";
import "../../reacttable/reactTable.css"
import { t } from "i18next";
import { fetchUserSubscriptionApi } from "../../../../store/actions/walletActions";
import moment from "moment";
import { FaChevronDown } from "react-icons/fa";
import MyDocument from "./downloadTransactionData";
import DatePicker from "react-datepicker";


function ViewCustmomerData(props) {
    const { userid } = useParams()
    const currency = process.env.REACT_APP_CURRENCY;
    const [activeKey, setActiveKey] = useState(null);
    const oneWeekInMilliseconds = 6 * 24 * 60 * 60 * 1000; // Number of milliseconds in a week
    const currentDate = new Date(); // Get the current date and time
    const tomorrowDate = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000); // Get tomorrow's date
    const [startDate, setStartDate] = useState(new Date(currentDate.getTime() - oneWeekInMilliseconds)); // Set startDate to one week before currentDate
    const [endDate, setEndDate] = useState(tomorrowDate);

    useEffect(() => {
        props.getSingleUserData(userid);
        props.getSingleUserChargingHistory(userid, startDate, endDate)
        props.getSingleUserAuthLog(userid)
        props.getSingleUserSubscription(userid)
    }, []);

    const loadNewData = () => {
        props.getSingleUserData(userid);
        props.getSingleUserChargingHistory(userid, startDate, endDate)
        props.getSingleUserAuthLog(userid)
        props.getSingleUserSubscription(userid)
    };

    const data = props.fetchSingleUserDetails && props.fetchSingleUserDetails.data;
    const transactionData = props.getUserChargingHistory && props.getUserChargingHistory.history;
    const total_kwh = props.getUserChargingHistory && props.getUserChargingHistory.lifetime_energy;
    const total_amount = props.getUserChargingHistory && props.getUserChargingHistory.lifetime_amount;
    const co2_saved = props.getUserChargingHistory && props.getUserChargingHistory.total_co2;
    const authLogData = props.getUserAuthLog && props.getUserAuthLog.data;
    const subscriptionData = props.getUserSubsApiRes.data && props.getUserSubsApiRes.data;
    // console.log("transactionData", transactionData)

    const toggleAccordion = (index) => {
        setActiveKey(activeKey === index ? null : index);
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Station Name',
                accessor: 'stationid'
            },
            {
                Header: 'Address',
                accessor: 'location'
            },
            {
                Header: 'Charging Date',
                accessor: 'start'
            },
            {
                Header: 'Consumed Time',
                accessor: 'consume_time'
            },
            {
                Header: 'Connector Type',
                accessor: 'standard'
            },
            {
                Header: 'Units consumed',
                accessor: 'kwh'
            },
            {
                Header: 'Total amount',
                accessor: 'total_amount'
            },

        ],
        []
    );

    const authLogcolumns = useMemo(
        () => [
            {
                Header: 'User Name',
                accessor: 'userid'
            },
            {
                Header: 'Event Type', accessor: 'event_type',
                Cell: (event_type) => {
                    return (
                        <div>
                            {event_type.value}
                        </div>
                    )
                }
            },
            {
                Header: 'Device', accessor: 'device',
                Cell: (device) => {
                    return (
                        <div>
                            {device.value === "Android" ? <FcAndroidOs size={30} /> :
                                <AiFillApple size={30} />}
                        </div>
                    )
                }
            },
            {
                Header: 'IP Address',
                accessor: 'ip_address'
            },
            {
                Header: 'Date',
                accessor: 'date'
            },
            {
                Header: 'Time',
                accessor: 'time'
            },
            {
                Header: 'Location',
                accessor: 'location'
            },

        ],
        []
    );

    const subscriptionColumns = [
        {
            Header: 'Transaction ID',
            accessor: 'txnid',
        },
        {
            Header: 'Debit',
            accessor: 'debit',
        },
        {
            Header: 'Tax Amount',
            accessor: 'tax_amount',
        },
        {
            Header: 'Discount',
            accessor: 'discount',
        },
        {
            Header: 'Consumed Balance',
            accessor: 'consumed_balance',
        },
        {
            Header: 'Charging Amount',
            accessor: 'charging_amount',
        },
        {
            Header: 'Start Date',
            accessor: 'startdate',
            Cell: ({ value }) => moment(value).format('DD-MM-YYYY'),
        },
        {
            Header: 'End Date',
            accessor: 'enddate',
            Cell: ({ value }) => moment(value).format('DD-MM-YYYY'),
        },
        {
            Header: 'Created At',
            accessor: 'createdat',
            Cell: ({ value }) => moment(value).format('DD-MM-YYYY'),
        },
    ];

    return (
        <Container fluid className="pb-3">
            <PureBreadcrumbs />
            <div className="row">
                <div className="col-lg-12 col-sm-12 pad_t1">
                    <span style={{ color: "var(--text-primary)", fontWeight: "bold" }}> {t("User Details")} </span>
                </div>
            </div>

            <div className="row pad_t1">
                <div className="col-lg-12 col-sm-12">
                    <Card className="customercard">
                        <Card.Body>
                            <div className="row">
                                <div className="col-lg-4 col-sm-12">
                                    <div>
                                        <label htmlFor="floatingInputGridName">{t("Firstname")}</label>
                                        <input className="input gray-border" type="text" name="first_name" id="floatingInputGridName" placeholder={t("Firstname")} defaultValue={data.firstname} disabled />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-12">
                                    <div>
                                        <label htmlFor="floatingInputGridName">{t("Lastname")}</label>
                                        <input className="input  gray-border " type="text" name="lastname" id="floatingInputGridName" placeholder={t("Lastname")} defaultValue={data.lastname} disabled />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-12">
                                    <div>
                                        <label htmlFor="floatingInputGridName">{t("Username")}</label>
                                        <input className="input  gray-border" type="text" name="userid" id="floatingInputGridName" placeholder={t("Username")} defaultValue={data.userid} disabled />
                                    </div>
                                </div>
                            </div>

                            <div className="row pad_t1">
                                <div className="col-lg-4 col-sm-12">
                                    <div>
                                        <label htmlFor="floatingInputGridName">{t("Email")}</label>
                                        <input className="input  gray-border" type="email" name="email" id="floatingInputGridemail" placeholder={t("Email")} defaultValue={data.email} disabled />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-12">
                                    <div>
                                        <label htmlFor="floatingInputGridName">{t("Mobile")}</label>
                                        <input className="input  gray-border" type="text" name="mobile" id="floatingInputGridMobile" placeholder={t("Mobile")} defaultValue={data.mobile} disabled />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-12">
                                    <div>
                                        <label htmlFor="floatingInputGridName">{t("Account Type")}</label>
                                        <input className="input  gray-border" type="text" name="accounttype" id="floatingInputGridaccounttype" placeholder={t("Account Type")} defaultValue={data.accounttype} disabled />
                                    </div>
                                </div>
                            </div>

                            <div className="row pad_t1">
                                <div className="col-lg-4 col-sm-12">
                                    <div>
                                        <label htmlFor="floatingInputGridName">{t("City")}</label>
                                        <input className="input  gray-border" type="text" name="city" id="floatingInputGridcity" placeholder={t("City")} defaultValue={data.city} disabled />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-12">
                                    <div>
                                        <label htmlFor="floatingInputGridName">{t("State")}</label>
                                        <input className="input  gray-border" type="text" name="state" id="floatingInputGridstate" placeholder={t("State")} defaultValue={data.state} disabled />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-12">
                                    <div>
                                        <label htmlFor="floatingInputGridName">{t("Country")}</label>
                                        <input className="input  gray-border" type="text" name="country" id="floatingInputGridcountry" placeholder={t("Country")} defaultValue={data.country} disabled />
                                    </div>
                                </div>
                            </div>

                            <div className="row pad_t1">
                                <div className="col-lg-4 col-sm-12">
                                    <div>
                                        <label htmlFor="floatingInputGridName">{t("Address")}</label>
                                        <input className="input  gray-border" type="text" name="address" id="floatingInputGridaddress" placeholder={t("Address")} defaultValue={data.address} disabled />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-12">
                                    <div>
                                        <label htmlFor="floatingInputGridName">{t("Zipcode")}</label>
                                        <input className="input  gray-border" type="text" name="postal_code" id="floatingInputGridpostal_code" placeholder={t("Zipcode")} defaultValue={data.postal_code} disabled />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-12">
                                    <div>
                                        <label htmlFor="floatingInputGridName">{t("Corporate Code")}</label>
                                        <input className="input  gray-border" type="text" name="corporate_code" id="floatingInputGridcorporate_code" placeholder={t("Corporate Code")} defaultValue={data.corporate_code} disabled />
                                    </div>
                                </div>
                            </div>
                            <div className="row pad_t1">
                                <div className="col-lg-12 col-sm-12">
                                    <div>
                                        <label htmlFor="floatingInputGridName">{t("Marketing Consent ?")}</label>
                                        <input className="input  gray-border" type="text" name="communication" id="floatingInputGridcommunication"
                                            placeholder={t("Marketing Consent")}
                                            value={data.communication ? "Given" : "Not Given"}
                                            disabled />
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>

            {/* // Authentication log data // */}
            <div className="row mb-2">
                <div className="col-lg-12 col-sm-12 pad_t2">
                    <Card className="customercard">
                        <div className="table-header">
                            <Row>
                                <Col md={4} sm={12}>
                                    {t("User Authenication Details")}
                                </Col>
                            </Row>

                        </div>
                        <div>
                            {props.loading ? <h3 style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "250px",
                            }}>Loading data...</h3> :
                                authLogData && authLogData.length > 0 ?
                                    (<div> <Table data={authLogData} columns={authLogcolumns}></Table></div>)
                                    :
                                    (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}><h3 className="loader">{t("No data found")}</h3></div>)
                            }
                        </div>
                    </Card>
                </div>
            </div>

            {/* // Transaction data // */}
            <div className="row mb-2">
                <div className="col-lg-12 col-sm-12 pad_t2">
                    <Card className="customercard">
                        <div className="table-header">
                            <Row>
                                <Col md={4} sm={12}>
                                    {t("User Transaction Details")}
                                </Col>
                                <Col md={8} sm={12}>
                                    <div className="row justify-content-end pr-4">
                                        <div>
                                            <img
                                                src={process.env.PUBLIC_URL + "/images/reports/energy.svg"}
                                                alt="" style={{ height: "35px", paddingLeft: "25px" }}
                                            />  {props.getUserChargingHistory && props.getUserChargingHistory.lifetime_energy !== undefined
                                                ? props.getUserChargingHistory && props.getUserChargingHistory.lifetime_energy
                                                : 0} kWh
                                        </div>
                                        <div>
                                            <img
                                                // src={process.env.PUBLIC_URL + "/images/reports/revenue.svg"}
                                                alt="" style={{ height: "35px", paddingLeft: "25px" }}
                                            />  {currency} {props.getUserChargingHistory && props.getUserChargingHistory.lifetime_amount !== undefined
                                                ? props.getUserChargingHistory && props.getUserChargingHistory.lifetime_amount
                                                : 0}
                                        </div>
                                        <div>
                                            <img
                                                src={process.env.PUBLIC_URL + "/images/reports/co2reduced.svg"}
                                                alt="" style={{ height: "35px", paddingLeft: "25px" }}
                                            />  {props.getUserChargingHistory && props.getUserChargingHistory.total_co2 !== undefined
                                                ? props.getUserChargingHistory && props.getUserChargingHistory.total_co2
                                                : 0}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row className="d-flex justify-content-end mt-3 mb-1 pr-3">
                                <div className="col-auto">
                                    <DatePicker
                                        className="gray-border calendar"
                                        selected={startDate}
                                        placeholderText="Select start date"
                                        onChange={(date) => setStartDate(date)}
                                        selectsStart
                                        startDate={startDate}
                                        dateFormat="dd/MM/yyyy"
                                        maxDate={new Date()}
                                    />
                                </div>
                                <span style={{ paddingTop: "7px" }}> To </span>
                                <div className="col-auto">
                                    <DatePicker
                                        className="gray-border calendar"
                                        selected={endDate}
                                        placeholderText="Select end date"
                                        onChange={(date) => setEndDate(date)}
                                        selectsEnd
                                        startDate={startDate}
                                        endDate={endDate}
                                        minDate={startDate}
                                        showDisabledMonthNavigation
                                        dateFormat="dd/MM/yyyy"
                                        maxDate={tomorrowDate}
                                    />
                                </div>
                                <div className="csvbutton ml-3 ">
                                    <button className="btn btn-sm" onClick={loadNewData}>
                                        <span
                                            style={{
                                                fontSize: "16px",
                                                color: "#676767",
                                                fontWeight: "600",
                                            }}
                                        >
                                            GO
                                        </span>
                                    </button>
                                </div>
                                <MyDocument
                                    startDate={startDate}
                                    endDate={endDate}
                                    data={transactionData}
                                    total_kwh={total_kwh}
                                    total_amount={total_amount}
                                    co2_saved={co2_saved}
                                    currency={currency}
                                />
                            </Row>
                            {props.loading ? <h3 style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "250px",
                            }}>Loading data...</h3> :
                                transactionData && transactionData.length > 0 ?
                                    (<div> <Table data={transactionData} columns={columns}></Table></div>)
                                    :
                                    (<div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "300px" }}><h3 className="loader">{t("No data found")}</h3></div>)
                            }
                        </div>
                    </Card>
                </div>
            </div>

            {/* Subscription History */}
            <div className="row mb-3">
                <div className="col-lg-12 col-sm-12 pad_t2">
                    <Card className="customercard">
                        <div className="table-header">
                            <Row>
                                <Col md={4} sm={12}>
                                    {t("User Subscription History")}
                                </Col>
                            </Row>

                        </div>
                        <div className="mt-1">
                            {props.loading ? (
                                <h3 style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '250px' }}>
                                    Loading data...
                                </h3>
                            ) : subscriptionData && subscriptionData.length > 0 ? (
                                <Accordion activeKey={String(activeKey)}>
                                    {subscriptionData.map((subscription, index) => (
                                        <Card key={index}>
                                            <Card.Header className="accordianHeader mb-2">
                                                <Accordion.Toggle
                                                    style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}
                                                    as={Button}
                                                    variant="Link"
                                                    eventKey={String(index)}
                                                    onClick={() => toggleAccordion(index)}
                                                >
                                                    <Row style={{ flex: 1 }}>
                                                        <Col lg={3}><strong>{t("Subscription Name")}:</strong> {subscription.sub_name}</Col>
                                                        <Col lg={3}><strong>{t("Subscription Type")}:</strong> {subscription.sub_type}</Col>
                                                        <Col lg={3}><strong>{t("Recharge Amount")}:</strong> {subscription.recharge}</Col>
                                                        <Col lg={3}><strong>{t("Total Amount Charging")}:</strong> {subscription.total_amount}</Col>
                                                    </Row>
                                                    <div className={`accordion-icon ${activeKey === index ? 'open' : ''}`}>
                                                        <FaChevronDown />
                                                    </div>
                                                </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey={String(index)}>
                                                <Card.Body>
                                                    <Table data={subscription.sub_details} columns={subscriptionColumns} />
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    ))}
                                </Accordion>
                            ) : (
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <h3 className="loader">No data found</h3>
                                </div>
                            )}
                        </div>
                    </Card>
                </div>
            </div >

        </Container >
    )
}


const mapStateToProps = function (state) {
    return {
        loading: state.customer.isLoading,
        fetchSingleUserDetails: state.customer.fetchsinglecutomers,
        getUserChargingHistory: state.customer.chargingHistory,
        getUserAuthLog: state.customer.authenicationlog,
        getUserSubsApiRes: state.wallet.fetchUserSubscription,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getSingleUserData: (userid) => dispatch(singleCutomersAction(userid)),
        getSingleUserChargingHistory: (userid, startDate, endDate) => dispatch(getUserChargingHistory(userid, startDate, endDate)),
        getSingleUserAuthLog: (userid) => dispatch(getUserAuthLog(userid)),
        getSingleUserSubscription: (userid) => dispatch(fetchUserSubscriptionApi(userid)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ViewCustmomerData));