import {
  BASE_URL,
  GET_ACCESS_CONTROL_API,
  SAVE_ACCESS_CONTROL_API,
  GET_DASHBOARD_USER_DATA,
  UPDATE_ACCESS_CONTROL_API,
  CHANGE_USER_PASSWORD_API,
  PERCENTAGE_CHANGE_API,
  ADMIN_STATUS_API,
  ADMIN_VALIDATE_STATUS_API,
  GET_NOTIFICATION,
  FETCH_STATE_CODE,
  SAVE_STATE_GROUP,
  FETCH_ALL_STATE_GROUP,
  UPDATE_STATE_GROUP,
  DELETE_USER_ACCOUNT_API,
} from "../../components/config/config";
import { ApiHandler } from "./apiHandler";

export const saveAccessControllAction = (data, selectedRegion, privileges) => {
  // console.log(data,privileges)
  const { components } = data;
  const accessRole = components[0].userRole;
  // console.log("accessRole from action",accessRole);
  const {
    firstName,
    lastName,
    userName,
    password,
    email,
    phone,
    operator,
    organisationType,
    address,
    city,
    country,
    state,
    Zipcode,
  } = data;
  const { token, organisation } = JSON.parse(localStorage.getItem("user"));
  const bearer = "basic " + token;
  const functionName = 'saveAccessControllAction'
  return async (dispatch) => {
    dispatch({ type: "LOAD_USER", payload: "" });
    const data = await ApiHandler(BASE_URL + SAVE_ACCESS_CONTROL_API, {

      functionName,
      method: "POST",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        first_name: firstName,
        region: selectedRegion,
        last_name: lastName,
        userid: userName,
        password: password,
        role: accessRole,
        access: privileges,
        phone: phone,
        email: email,
        organisation: operator,
        organisation_type: organisationType,
        address: address,
        country: country,
        state: state,
        city: city,
        postal_code: Zipcode,
        owner: organisation,
      }),
    });
    if (data) {
      dispatch({ type: "SAVE_USER", payload: data });
    } else {
      console.log("No data found from server");
    }
  };
};

export const validateUserStatus = (userid, status) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const bearer = "basic " + token;
  const functionName = 'validateUserStatus'
  return async (dispatch) => {
    dispatch({ type: "LOAD_USER", payload: "" });
    const data = await ApiHandler(BASE_URL + ADMIN_VALIDATE_STATUS_API, {
      functionName,
      method: "POST",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        userid: userid,
        status: status,
      }),
    });
    if (data) {
      dispatch({ type: "UPDATE_VALIDATE_USER_STATUS", payload: data });
    } else {
      console.log("No data found from server");
    }
  };
};

export const allUserAction = () => {
  const { token, role, organisation } = JSON.parse(localStorage.getItem("user"));
  const bearer = "basic " + token;
  const functionName = 'allUserAction'
  return async (dispatch) => {
    dispatch({ type: "LOAD_USER", payload: "" });
    const data = await ApiHandler(
      BASE_URL + GET_ACCESS_CONTROL_API + "?role=" + role + "&organisation=" + organisation,
      {
        functionName,
        method: "GET",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    if (data) {
      dispatch({ type: "LOAD_ALL_USER", payload: data });
    } else {
      console.log("No data found from server");
    }
  };
};

export const allTicketAssignToUser = () => {
  const { token, role, organisation } = JSON.parse(localStorage.getItem("user"));
  const bearer = "basic " + token;
  const functionName = 'allTicketAssignToUser'
  return async (dispatch) => {
    dispatch({ type: "LOAD_USER", payload: "" });
    const data = await ApiHandler(
      BASE_URL + GET_ACCESS_CONTROL_API + "?role=" + "Admin" + "&organisation=" + organisation,
      {
        functionName,
        method: "GET",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    if (data) {
      dispatch({ type: "ALL_ASSIGNTO_USER", payload: data });
    } else {
      console.log("No data found from the server");
    }
  };
};

export const userAdminStatus = () => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const bearer = "basic " + token;
  const functionName = 'userAdminStatus'
  return async (dispatch) => {
    dispatch({ type: "LOAD_USER", payload: "" });
    const data = await ApiHandler(BASE_URL + ADMIN_STATUS_API, {
      functionName,
      method: "GET",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (data) {
      dispatch({ type: "FETCH_USER_ADMIN_STATUS", payload: data });
    } else {
      console.log("No data found from server");
    }
  };
};

export const singleUserAction = (userid) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const bearer = "basic " + token;
  const functionName = 'singleUserAction'
  return async (dispatch) => {
    dispatch({ type: "LOAD_USER", payload: "" });
    const data = await ApiHandler(BASE_URL + GET_DASHBOARD_USER_DATA + "?userid=" + userid, {
      functionName,
      method: "GET",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (data) {
      dispatch({ type: "LOAD_SINGLE_USER", payload: data });
    } else {
      console.log("No data found  from server");
    }
  };
};

export const updateAccessControllAction = (userid, selectedRegion, data, privileges) => {
  // console.log("updateAccessControllAction",userid,data,privileges)
  const { components } = data;
  const accessRole = components[0].userRole;
  const {
    firstName,
    last_name,
    password,
    email,
    phone,
    organisation,
    organisationType,
    address,
    city,
    country,
    state,
    Zipcode,
  } = data;
  // console.log(address);
  const { token } = JSON.parse(localStorage.getItem("user"));
  const bearer = "basic " + token;
  const functionName = 'updateAccessControllAction'
  return async (dispatch) => {
    dispatch({ type: "LOAD_USER", payload: "" });
    const data = await ApiHandler(BASE_URL + UPDATE_ACCESS_CONTROL_API, {
      functionName,
      method: "POST",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        userid: userid,
        region: selectedRegion,
        first_name: firstName,
        last_name: last_name,
        password: password,
        role: accessRole,
        access: privileges,
        phone: phone,
        email: email,
        organisation: organisation,
        organisation_type: organisationType,
        address: address,
        country: country,
        state: state,
        city: city,
        postal_code: Zipcode,
      }),
    });
    if (data) {
      dispatch({ type: "UPDATE_SINGLE_USER", payload: data });
    } else {
      console.log("No data found from server");
    }
  };
};

export const updateUserPasswordAction = (record) => {
  const { password } = record;
  const { token, userid } = JSON.parse(localStorage.getItem("user"));
  // console.log("userid, password",userid)
  const bearer = "basic " + token;
  const functionName = 'updateUserPasswordAction'
  return async (dispatch) => {
    dispatch({ type: "LOAD_USER", payload: "" });
    const data = await ApiHandler(BASE_URL + CHANGE_USER_PASSWORD_API, {
      functionName,
      method: "POST",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        userid: userid,
        password: password,
      }),
    });
    if (data) {
      dispatch({ type: "CHANGE_USER_PASSWORD_API", payload: data });
    } else {
      console.log("No data found from server");
    }
  };
};

export const fetchAdminPercentageChange = () => {
  const { token, role, organisation } = JSON.parse(localStorage.getItem("user"));
  const bearer = "basic " + token;
  const functionName = 'fetchAdminPercentageChange'
  return async (dispatch) => {
    dispatch({ type: "LOAD_USER", payload: "" });
    const data = await ApiHandler(
      BASE_URL + PERCENTAGE_CHANGE_API + "?role=" + role + "&organisation=" + organisation,
      {
        functionName,
        method: "GET",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    if (data) {
      dispatch({ type: "FETCH_ADMIN_PERCENTAGE_CHANGE", payload: data });
    } else {
      console.log("No data found from server");
    }
  };
};

// state grouping

export const stateApi = (code) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const bearer = "basic " + token;
  const functionName = 'stateApi'
  return async (dispatch) => {
    dispatch({ type: "LOAD_USER", payload: "" });
    const data = await ApiHandler(BASE_URL + FETCH_STATE_CODE + "?country_code=" + code, {
      functionName,
      method: "GET",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (data) {
      dispatch({ type: "GET_COUNTRY_STATE", payload: data });
    } else {
      console.log("No data found from server");
    }
  };
};

export const saveStateGrouping = (data) => {
  const { stateGroup } = data;
  // console.log(stateGroup)
  const transformedData = stateGroup.reduce((acc, item) => {
    const zoneName = item.zone.toLowerCase();
    const states = item.states.filter((state) => state !== "");
    acc[zoneName] = states;
    return acc;
  }, {});
  // console.log("transformedData",transformedData);
  const { north, south, east, west } = transformedData;
  const { token, userid, role, organisation } = JSON.parse(localStorage.getItem("user"));
  const bearer = "basic " + token;
  const functionName = 'saveStateGrouping'
  return async (dispatch) => {
    dispatch({ type: "LOAD_USER", payload: "" });
    const data = await ApiHandler(BASE_URL + SAVE_STATE_GROUP, {
      functionName,
      method: "POST",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        role: role,
        organisation: organisation,
        north: north,
        south: south,
        east: east,
        west: west,
      }),
    });
    if (data) {
      dispatch({ type: "SAVE_STATE_API", payload: data });
    } else {
      console.log("No data found from server");
    }
  };
};

export const updateStateGroupApi = (data) => {
  const { stateGroup } = data;
  // console.log(stateGroup)
  const transformedData = stateGroup.reduce((acc, item) => {
    const zoneName = item.zone.toLowerCase();
    const states = item.states.filter((state) => state !== "");
    acc[zoneName] = states;
    return acc;
  }, {});
  const { north, south, east, west } = transformedData;
  // console.log("north",north);
  // console.log("south",south);
  // console.log("east",east);
  // console.log("west",west);
  const { token, userid, role, organisation } = JSON.parse(localStorage.getItem("user"));
  const bearer = "basic " + token;
  const functionName = 'updateStateGroupApi'
  return async (dispatch) => {
    dispatch({ type: "LOAD_USER", payload: "" });
    const data = await ApiHandler(BASE_URL + UPDATE_STATE_GROUP, {
      functionName,
      method: "POST",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        role: role,
        organisation: organisation,
        north: north,
        south: south,
        east: east,
        west: west,
      }),
    });
    if (data) {
      dispatch({ type: "UPDATE_STATE_API", payload: data });
    } else {
      console.log("No data found from server");
    }
  };
};

export const fetchAllStateGroup = () => {
  const { token, role, organisation } = JSON.parse(localStorage.getItem("user"));
  // console.log("role",role)
  // console.log("organisation",organisation)
  const bearer = "basic " + token;
  const functionName = 'fetchAllStateGroup'
  return async (dispatch) => {
    dispatch({ type: "LOAD_USER", payload: "" });
    const data = await ApiHandler(
      BASE_URL + FETCH_ALL_STATE_GROUP + "?role=" + role + "&organisation=" + organisation,
      {
        functionName,
        method: "GET",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    if (data) {
      dispatch({ type: "FETCH_ALL_STATE_GROUP_API", payload: data });
    } else {
      console.log("No data found from server");
    }
  };
};

export const deleteUserActionApi = (userid) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const bearer = "basic " + token;
  const functionName = 'deleteUserActionApi'
  return async (dispatch) => {
    dispatch({ type: "LOAD_USER", payload: "" });
    const data = await ApiHandler(BASE_URL + DELETE_USER_ACCOUNT_API + "?id=" + userid, {
      functionName,
      method: "GET",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (data) {
      dispatch({ type: "DELETE_USER_API", payload: data });
    } else {
      console.log("No data found from server");
    }
  };
};
