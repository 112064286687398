// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 980px) {
    .first-button{
        position: absolute;
        overflow: hidden;
        width: 150%;
        left: 30px !important;
    }
    .second-button{
        position: absolute;
        overflow: hidden;
        width: 150%;
        margin-top: 7vh !important;
        right: 30px !important;
        font-size: 12px;
    }
    .tariffcard{
        padding-bottom: 8vh !important;
    }
}
.org-name-warning{
    font-size: 80%;
    color: #dc3545;
  }`, "",{"version":3,"sources":["webpack://./src/components/dashboard/organisation/organisation.css"],"names":[],"mappings":"AAAA;IACI;QACI,kBAAkB;QAClB,gBAAgB;QAChB,WAAW;QACX,qBAAqB;IACzB;IACA;QACI,kBAAkB;QAClB,gBAAgB;QAChB,WAAW;QACX,0BAA0B;QAC1B,sBAAsB;QACtB,eAAe;IACnB;IACA;QACI,8BAA8B;IAClC;AACJ;AACA;IACI,cAAc;IACd,cAAc;EAChB","sourcesContent":["@media (max-width: 980px) {\n    .first-button{\n        position: absolute;\n        overflow: hidden;\n        width: 150%;\n        left: 30px !important;\n    }\n    .second-button{\n        position: absolute;\n        overflow: hidden;\n        width: 150%;\n        margin-top: 7vh !important;\n        right: 30px !important;\n        font-size: 12px;\n    }\n    .tariffcard{\n        padding-bottom: 8vh !important;\n    }\n}\n.org-name-warning{\n    font-size: 80%;\n    color: #dc3545;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
