import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import PureBreadcrumbs from "../breadcrums";
import { connect } from "react-redux";
import { fetchRFIDLogsAction } from "../../../store/actions/commandAction";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import CustomPaginationTable from "../report/adminReport/customPaginationTable";

const OCPPLogComponent = (props) => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(new Date(Date.now() - 3600 * 1000 * 24));
  const [endDate, setEndDate] = useState(new Date(Date.now() + 3600 * 1000 * 24));
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const data = props.rfidLogs && props.rfidLogs.data;
  const allData = props.rfidLogs && props.rfidLogs.data
  const reportCount = props.rfidLogs && props.rfidLogs.document_count;
  const defaultPageSize = 10;
  const pageCount = Math.ceil(reportCount / defaultPageSize)


  useEffect(() => {
    // console.log("useEffect is running...")
    props.allLogs(startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10), currentPage, pageSize);
  }, [currentPage, pageSize]);

  const loadNewData = () => {
    props.allLogs(startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10), currentPage, pageSize);
  };

  // console.log("logs", props.rfidLogs)


  const columns = useMemo(
    () => [
      {
        Header: "Charge Box Id",
        accessor: "evse_id",
      },
      {
        Header: "OCPP Command",
        accessor: "eventtype",
      },
      {
        Header: "Date",
        accessor: "date",
      },
      {
        Header: "Tag ID/RFID number",
        id: "tag_id",
        accessor: "payload",
        Cell: (cell) => {
          const item = cell
          const tagid = item?.item?.payload?.[0]?.Value;
          // console.log("item:", item?.item?.payload[0]);
          return (
            <div>
              {tagid}
            </div>
          );
        },
      },
      {
        Header: "Status",
        id: "status",
        accessor: "payload",
        Cell: (cell) => {
          const item = cell
          const status = item?.item?.payload?.[1]?.Value;
          // console.log("status:", status);
          return (
            <div>
              {status}
            </div>
          );
        },
      },



    ],
    []
  );

  return (
    <Container fluid>
      <PureBreadcrumbs />
      <Row className="mt-4">
        <Col>
          <Card className="customercard">
            <Card.Header>
              <Row>
                <Col md={6} sm={12}>
                  <div className="table-header">{t("All RFID Logs")}</div>
                </Col>
                <Col md={6} sm={12}>
                  <div className="row justify-content-between float-right">
                    <div className="row justify-content-center">
                      <div className="col-auto claender">
                        <DatePicker
                          className="gray-border"
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          selectsStart
                          startDate={startDate}
                          dateFormat="dd/MM/yyyy"
                          maxDate={new Date()}
                        />
                      </div>
                      <span style={{ paddingTop: "7px" }}> To </span>
                      <div className="col-auto">
                        <DatePicker
                          className="gray-border"
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                          showDisabledMonthNavigation
                          dateFormat="dd/MM/yyyy"
                          maxDate={new Date()}
                        />
                      </div>
                      <div className="col-auto"></div>
                    </div>
                    <div className="csvbutton ">
                      <button className="btn btn-sm mb-0" onClick={loadNewData}>
                        <span
                          style={{
                            fontSize: "16px",
                            color: "#676767",
                            fontWeight: "600",
                          }}
                        >
                          GO
                        </span>
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Card.Header>
            {props.loadingevse ? <h3 style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "450px",
            }}>Loading data...</h3> :
              props.rfidLogs && props.rfidLogs.data ? (
                <CustomPaginationTable
                  data={data}
                  allData={allData}
                  pageCount={pageCount}
                  columns={columns}
                  search={search}
                  setSearch={setSearch}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  filteredData={filteredData}
                  setFilteredData={setFilteredData}
                  loading={props.loading}
                  t={t}
                />
                // <Table data={allData} columns={columns}></Table>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "450px",
                  }}
                >
                  <h3>No data found</h3>
                </div>
              )}
          </Card>
        </Col>
      </Row>
    </Container >
  );
};

const mapStateToProps = (state) => {
  return {
    loadingevse: state.command.isLoading,
    rfidLogs: state.command.fetchRfidLogs,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    allLogs: (startDate, endDate, page, limit) => dispatch(fetchRFIDLogsAction(startDate, endDate, page, limit)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(OCPPLogComponent);
