import React, { useMemo } from 'react'
import { Card, Col, Container } from 'react-bootstrap'
import PureBreadcrumbs from "../../breadcrums";
import Table from '../../reacttable/table';
function AllMainteance() {
    const maintenance = [
        {
            service_type: "Change Coolant",
            service_overdue: "4",
            service_overdue_next_month: "5",
        },
        {
            service_type: "Change Fuel Filter",
            service_overdue: "3",
            service_overdue_next_month: "5",
        },
        {
            service_type: "Low Battery",
            service_overdue: "4",
            service_overdue_next_month: "5",
        },
        {
            service_type: "Transmission Fault",
            service_overdue: "3",
            service_overdue_next_month: "5",
        },
    ];

    const maintenance_col = useMemo(
        () => [
            {
                Header: "Service Type",
                accessor: "service_type",
            },
            {
                Header: "No. of Vehicles Service Overdue",
                accessor: "service_overdue",
            },
            {
                Header: "No. of Vehicles Service Overdue Next Month",
                accessor: "service_overdue_next_month",
            },
        ],
        []
    );
    return (
        <Container fluid>
            <PureBreadcrumbs />
            <br />
            <Col>
                <Card className="tariffcard">
                    <Card.Title>
                        <b>
                            VEHICLE MAINTENANCE DASHBOARD
                        </b>
                    </Card.Title>
                    <Card.Body>
                        <Table data={maintenance} columns={maintenance_col}></Table>
                    </Card.Body>
                </Card >
            </Col>
        </Container>
    )
}

export default AllMainteance