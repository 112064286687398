import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { allEvseAction, fetchSingleStationGroupWithTariff } from '../../../../store/actions/evseAction';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PureBreadcrumbs from "../../breadcrums";
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { fetchActiveTariffList } from '../../../../store/actions/tariffAction';
import UpdateStationGroupWithTariffTable from './updateStationGroupWithTariffTable';

function UpdateStationGroupWithTariff(props) {
    const { id } = useParams();
    const { t } = useTranslation();
    const [tariffName, setTariffName] = useState('');

    useEffect(() => {
        props.all();
        props.getActiveTariffList();
        props.singleData(id)
    }, [])

    const singledata = props.getSingle && props.getSingle.data && props.getSingle.data[0]
    const station_list = singledata && singledata.station_list
    // console.log(station_list)


    const handleSelectTariff = (event) => {
        setTariffName(event.target.value);
    };
    // console.log(stationGroupName)

    const data = props.list && props.list.data?.filter((item) => item.status !== 'Discovery' && item.publish !== false);
    // console.log("data", data);
    const filteredData = data && data.map(item => ({
        name: item.name,
        address: item.address,
        city: item.city,
    }));
    // console.table(filteredData)

    const columns = useMemo(
        () => [
            {
                Header: 'Station Name',
                accessor: 'name'
            },
            {
                Header: 'City',
                accessor: 'city'
            },
            {
                Header: 'Address',
                accessor: 'address'
            },

        ],
        []
    );


    return (

        <div className="container-fluid pt-2">
            <PureBreadcrumbs />
            <Row className="">
                <Col lg={12} className="">
                    <div className="customercard mt-2">
                        <div className=""><h4>{t("Update Station Grouping With Tariff")}</h4></div>
                        <Row className="mt-3">
                            <Col className="col-lg-6">
                                <div>
                                    <label>{t("Station Group Name")} <span className='mandatory-field'>*</span></label>
                                    <input
                                        autoComplete='off'
                                        type="text"
                                        disabled
                                        value={singledata?.tariff_station_group_name || ''}
                                        className="form-control gray-border"
                                        placeholder="Enter Station Group Name"
                                    />
                                </div>
                            </Col>
                            <Col className="col-lg-6">
                                <div>
                                    <div className="">
                                        <label>{t("Select Tariff")} <span className='mandatory-field'>*</span></label>
                                        <select
                                            autoComplete='off'
                                            className={`form-select form-control`}
                                            name={"tariff"}
                                            id={"floatingSelectGridAdminStatus"}
                                            onChange={handleSelectTariff}
                                        >
                                            <option disabled hidden>{singledata?.tariff ? singledata?.tariff : 'Select Tariff'}</option>
                                            {props.fetchActiveTariffList && props.fetchActiveTariffList.data && props.fetchActiveTariffList.data.map((v, k) =>
                                                <option key={v.id} value={v.name}>{v.name}</option>
                                            )}

                                        </select>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        {props.loading ? <h3 style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "450px",
                        }}>Loading data...</h3> :
                            filteredData && filteredData.length > 0 ?
                                (<div> <UpdateStationGroupWithTariffTable {...props} station_list={station_list} station_group_name={singledata?.tariff_station_group_name} tariffName={tariffName || singledata?.tariff} data={filteredData} columns={columns}></UpdateStationGroupWithTariffTable></div>)
                                :
                                (<div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "450px", }}><h3 className="loader">No data found</h3></div>)
                        }
                    </div>

                </Col>
            </Row>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        loading: state.evse.isEvseLoading,
        list: state.evse.allevse,
        fetchActiveTariffList: state.tariff.fetchActiveTariffName,
        getSingle: state.evse.fetchSingleStationGroupWithTariff,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        all: () => dispatch(allEvseAction()),
        singleData: (id) => dispatch(fetchSingleStationGroupWithTariff(id)),
        getActiveTariffList: () => dispatch(fetchActiveTariffList()),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(UpdateStationGroupWithTariff)
