import React, { useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Card } from "react-bootstrap";
import GoogleMapStyles from "../../GoogleMapStyles";
import "../../ocpi.css";
import { fetchLocationbyPartyId } from "../../../../../store/actions/ocpiAction";
import { GoogleMap, useLoadScript, OverlayView } from "@react-google-maps/api";

const CustomMarker = ({ name, address }) => (
    <div className="custom-marker" style={{ cursor: 'pointer' }} title={`Name: ${name}\nAddress: ${address}`}>
        <div className="pin bounce" />
        <div className="pulse" />
    </div>
);
const libraries = ["places"];

function OPCIMap(props) {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries,
    });

    const [center, setCenter] = useState({ lat: 20.5937, lng: 78.9629 });
    const [zoom, setZoom] = useState(4.8);

    const mapContainerStyle = {
        height: "81vh",
        width: "100%",
    };

    if (loadError) return <div>Error loading map. Please try again later.</div>;
    if (!isLoaded) return <div>Loading map...</div>;

    return (
        <Row className="mt-3 mb-3">
            <Col>
                <Card className="customercard">
                    <div style={mapContainerStyle}>
                        <GoogleMap
                            mapContainerStyle={mapContainerStyle}
                            center={center}
                            zoom={zoom}
                            options={{ styles: GoogleMapStyles }}
                        >
                            {props.fetchLocationbyParty && props.fetchLocationbyParty.data?.map((location, index) => {
                                const evsecordinates = location && location.coordinates;
                                const lat = parseFloat(evsecordinates.latitude);
                                const lng = parseFloat(evsecordinates.longitude);
                                // console.log("evsecordinates", evsecordinates)
                                // console.log("lat", lat)
                                // console.log("lng", lng)

                                return (
                                    <OverlayView
                                        key={index}
                                        position={{ lat, lng }}
                                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                    >
                                        <CustomMarker
                                            name={location.name}
                                            address={location.address}
                                        />
                                    </OverlayView>
                                );
                            })}
                        </GoogleMap>
                    </div>
                </Card>
            </Col>
        </Row>
    );
}

const mapStateToProps = (state) => ({
    loading: state.evse.isEvseLoading,
    fetchLocationbyParty: state.ocpi.fetchLocationByPartyid,
});

const mapDispatchToProps = (dispatch) => ({
    allOcpiList: (partner_name) => dispatch(fetchLocationbyPartyId(partner_name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OPCIMap);
