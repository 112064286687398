import React from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useState, useEffect } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { useParams, withRouter } from "react-router-dom";
import {
  allChargingUnitAction,
  allCurrencyAction,
  fetchCountryCodeApi,
  singleTimeOfUseTariffAction,
  updateTimeSlotTariffAction,
} from "../../../../store/actions/tariffAction";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";
import PureBreadcrumbs from "../../breadcrums";
import { Bounce, ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import DarkThemeStyles from "../../resuableComponent/reactSelectSingleValueStyle";
import { Stepper } from "react-form-stepper";

const UpdateTimeOfUseTariff = (props) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [formStep, setFormStep] = useState(0);
  const [redirect, setRedirect] = useState(false);
  const [isToastVisible, setIsToastVisible] = useState(false);
  const [countryCode, setCountryCode] = useState("");

  const priceSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    description: Yup.string(),
    currency: Yup.string().required("Currency is required"),
    status: Yup.string().required("Status is required"),
    isPeak: Yup.string().required("Select peak hour is required"),
    operator: Yup.string().required("Organisation is required"),
    idleTime: Yup.string(),
    idlePrice: Yup.number(),
    gracePeriod: Yup.number(),
    components: Yup.array().of(
      Yup.object().shape({
        unit: Yup.string().required("Unit  is required"),
        amount: Yup.number().required("Amount is required").typeError("Amount can only be a number"),
        tax: Yup.number().required("Tax is required").typeError("Tax can only be a number"),
      })
    ),
  });
  const {
    register,
    control,
    getValues,
    setValue,
    watch,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ resolver: yupResolver(priceSchema), mode: "all" });
  const {
    fields: fieldsList1,
    remove: removeList1,
    append: appemdList1,
  } = useFieldArray({ control, name: "components" });

  const elements = watch("components");

  useEffect(() => {
    props.getSingleTariffData(id);
    props.getAllCurrency();
    props.getAllChargingUnit();
  }, []);

  const data = props.fetchSingleTariffDetails && props.fetchSingleTariffDetails;
  // console.log("tariff data ", data)
  useEffect(
    () => {
      const {
        name,
        description,
        currency,
        country_code,
        status,
        components,
        gracePeriod,
        ispeak,
        idlePrice,
        idleTime,
        role,
        operator,
      } = data;
      if (data) {
        // console.log(data)
        setValue("name", name, { shouldValidate: true });
        setValue("description", description, { shouldValidate: true });
        setValue("currency", currency, { shouldValidate: true });
        setValue("status", status, { shouldValidate: true });
        setValue("isPeak", ispeak, { shouldValidate: true });
        setValue("components", components, { shouldValidate: true });
        setValue("idleTime", idleTime);
        setValue("idlePrice", idlePrice);
        setValue("gracePeriod", gracePeriod);
        setValue("operator", operator, { shouldValidate: true });
        setValue("role", role, { shouldValidate: true });
        register("operator", operator);
        register("role", role);
        setCountryCode(country_code);
        props.fetchCountryCode(currency);
      }
    },
    [data],
    setValue
  );

  // console.log("country code ", countryCode)

  const handleCurrency = (e) => {
    const code = e.value;
    // console.log("currency", code);
    setValue("currency", code);
    props.fetchCountryCode(code);
  };

  const options =
    props.allcurrency &&
    props.allcurrency.currency?.map((item) => ({
      value: item.currency_code,
      label: item.currency_code,
    }));

  useEffect(() => {
    const countryCodeData = props.fetchCountryCodeRes && props.fetchCountryCodeRes.countrycode.country;
    setCountryCode(countryCodeData);
  }, [props.fetchCountryCodeRes]);

  // console.log("getValues(currency)", getValues("currency"))

  const onSubmit = async (data) => {
    // console.log(data);
    props.updateTimeSlot(countryCode, data);
  };
  const completeFormStep = () => {
    setFormStep((cur) => cur + 1);
  };
  const goToPrevStep = () => {
    setFormStep((cur) => cur - 1);
  };

  const renderButton = () => {
    if (formStep > 3) {
      return undefined;
    } else if (formStep === 2) {
      return (
        <div>
          <div className="d-md-flex  justify-content-md-between  p-3">
            <Col lg={4} md={6} sm={12} xs={12} className="mt-2">
              <button className="btn lgn-btn" type="button" onClick={goToPrevStep}>
                {t("Back")}
              </button>
            </Col>
            <Col lg={8} md={6} sm={12} xs={12} className="d-md-flex justify-content-md-end mt-2">
              {props.loading ? (
                <button className="btn lgn-btn" type="button" disabled>
                  <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                  &nbsp;
                  {t("Saving...")}
                </button>
              ) : (
                <button className="btn  lgn-btn " type="submit" onClick={handleSubmit(onSubmit)} disabled={!isValid}>
                  {t("UPDATE TARIFF")}
                </button>
              )}
            </Col>
          </div>
        </div>
      );
    } else if (formStep === 1) {
      return (
        <>
          {elements && elements.length !== 0 && (
            <div className="d-md-flex justify-content-md-end p-3">
              <Col lg={4} md={6} sm={12} xs={12} className="mt-2">
                {formStep > 0 ? (
                  <button className="btn lgn-btn" type="button" onClick={goToPrevStep}>
                    {t("Back")}
                  </button>
                ) : (
                  ""
                )}
              </Col>
              <Col lg={8} md={6} sm={12} xs={12} className="d-md-flex justify-content-md-end mt-2">
                <button className="btn lgn-btn" type="button" onClick={completeFormStep} disabled={!isValid}>
                  {t("PROCEED TO NEXT STEP")}
                </button>
              </Col>
            </div>
          )}
        </>
      );
    } else {
      return (
        <div className="d-grid gap-2 d-md-flex justify-content-md-end p-3">
          <Col>
            {formStep > 0 ? (
              <button className="btn  lgn-btn" type="button" onClick={goToPrevStep}>
                {t("Back")}
              </button>
            ) : (
              ""
            )}
          </Col>
          <Col>
            <button className="btn lgn-btn" type="button" onClick={completeFormStep} disabled={!isValid}>
              {t("PROCEED TO NEXT STEP")}
            </button>
          </Col>
        </div>
      );
    }
  };

  useEffect(() => {
    if (props.updateTimeResponse.statuscode === 200) {
      setIsToastVisible(true);
      toast.success("Price has been Updated successfully", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        onClose: () => {
          setIsToastVisible(false);
          setRedirect(true);
        },
      });
    }
  }, [props.updateTimeResponse]);

  if (redirect) {
    return <Redirect to="/dashboard/view_tariff" />;
  }

  return (
    <div className="container-fluid">
      {isToastVisible && <div className="overlay" />}
      <ToastContainer
        position="top-center"
        autoClose={20}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{ top: "10%", width: "auto", zIndex: "9999" }}
      />
      <PureBreadcrumbs />
      <Row>
        <Col xs={12} md={1}></Col>
        <Col xs={12} md={10}>
          <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
            <Card className="tariffcard mt-4">
              <div className="mt-3">
                {/* <!-- start step indicators --> */}
                {/* <div className="form-header d-flex mb-4">
                                    <span className="stepIndicator">{t("UPDATE TARIFF")}</span>
                                    <span className="stepIndicator">{t("Update static Tariff Component")}</span>
                                    <span className="stepIndicator">{t("Go live")}</span>
                                </div> */}
                <Stepper
                  steps={[
                    { label: "UPDATE TARIFF" },
                    { label: "Update static Tariff Component" },
                    { label: "Go live" },
                  ]}
                  activeStep={formStep}
                />
                {/* <!-- end step indicators --> */}
              </div>
              {formStep === 0 && (
                <Card.Body>
                  <div className="row g-2">
                    <div className="col-md">
                      <div className="form-floating">
                        <label>
                          {t("Tariff Name")}
                          <span className="mandatory-field">*</span>
                        </label>
                        <input
                          type="text"
                          className={`form-control gray-border ${errors.name ? "is-invalid" : ""}`}
                          name="name"
                          id="floatingInputGridName"
                          disabled
                          placeholder={t("Tariff Name")}
                          {...register("name")}
                        />
                        <div className="invalid-feedback">{errors.name?.message}</div>
                      </div>
                    </div>
                    <div className="col-md">
                      <div className="form-floating">
                        <label>
                          {t("Select Currency")}
                          <span className="mandatory-field">*</span>
                        </label>
                        <Select
                          options={options ? options : []}
                          className={`${errors.currency ? "is-invalid" : ""}`}
                          name="currency"
                          id="floatingInputGridcurrency"
                          placeholder={getValues("currency") ? getValues("currency") : "Select Currency"}
                          styles={DarkThemeStyles}
                          menuPortalTarget={document.body}
                          onChange={handleCurrency}
                        />
                        <div className="invalid-feedback">{errors.currency?.message}</div>
                      </div>
                    </div>
                    <div className="col-md">
                      <div className="form-floating">
                        <label>
                          {t("Tariff Status")}
                          <span className="mandatory-field">*</span>
                        </label>
                        <select
                          className={`form-select form-control ${errors.status ? "is-invalid" : ""}`}
                          name="status"
                          id="floatingSelectGridS"
                          {...register("status")}
                        >
                          <option value="">{t("Tariff Status")}</option>
                          <option value="draft">{t("Draft")}</option>
                          <option value="active">{t("Active")}</option>
                          <option value="inactive">{t("Inactive")}</option>
                        </select>
                        <div className="invalid-feedback">{errors.status?.message}</div>
                      </div>
                    </div>
                    <div className="col-md">
                      <div className="form-floating">
                        <label>
                          {t("Tariff for")}
                          <span className="mandatory-field">*</span>
                        </label>
                        <select
                          className={`form-select form-control ${errors.isPeak ? "is-invalid" : ""}`}
                          name="isPeak"
                          id="floatingSelectGridisPeak"
                          {...register("isPeak")}
                        >
                          <option value="">{t("Select Peak Hours")}</option>
                          <option value="On Peak">{t("On Peak")}</option>
                          <option value="Off Peak">{t("Off Peak")}</option>
                        </select>
                        <div className="invalid-feedback">{errors.isPeak?.message}</div>
                      </div>
                    </div>
                  </div>

                  <div className="row g-2 mt-2">
                    <div className="col-md">
                      <div className="form-floating">
                        <label htmlFor="floatingTextareaUpdateStaticTariff">{t("Description")}</label>
                        <textarea
                          className={`form-control`}
                          placeholder={t("Description")}
                          name="description"
                          id="floatingTextareaUpdateStaticTariff"
                          style={{ height: "100px" }}
                          {...register("description")}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              )}
            </Card>
            {formStep === 1 && (
              <div>
                {fieldsList1.map((item, i) => (
                  <Card key={i}>
                    <h6 className="text-start p-2">
                      <b>{t("Update Price Components")}</b>
                    </h6>
                    <Card.Body>
                      <div className="row g-2 p-2">
                        <div className="col-md">
                          <div className="form-floating">
                            <label>
                              {t("Select Charging Unit")}
                              <span className="mandatory-field">*</span>
                            </label>
                            <select
                              className={`form-select form-control ${errors.components?.[i]?.unit ? "is-invalid" : ""}`}
                              name={`components[${i}].unit`}
                              id="floatingSelectGridU"
                              {...register(`components.${i}.unit`)}
                            >
                              <option value={""}>{t("Select Charging Unit")}</option>
                              {props.allchargingunit &&
                                props.allchargingunit.cunit.map((v, k) => (
                                  <option key={v.unit} value={v.value}>
                                    {t(v.unit)}
                                  </option>
                                ))}
                            </select>
                            <div className="invalid-feedback">{errors.unit?.message}</div>
                          </div>
                          <br />
                        </div>
                        <div className="col-md">
                          <div className="form-floating">
                            {watch(`components.${i}.unit`) === "" && (
                              <label>
                                {t("Price per/unit")}
                                <span className="mandatory-field">*</span>
                              </label>
                            )}
                            {watch(`components.${i}.unit`) === "kWh" && (
                              <label>
                                {t("Price per/kWh")}
                                <span className="mandatory-field">*</span>
                              </label>
                            )}
                            {watch(`components.${i}.unit`) === "minute" && (
                              <label>
                                {t("Minute Price")}
                                <span className="mandatory-field">*</span>
                              </label>
                            )}
                            {watch(`components.${i}.unit`) === "flat" && (
                              <label>
                                {t("Flat Price")}
                                <span className="mandatory-field">*</span>
                              </label>
                            )}

                            <input
                              type="number"
                              className={`form-control gray-border ${errors.components?.[i]?.amount ? "is-invalid" : ""
                                }`}
                              name={`components[${i}].amount`}
                              id="floatingInputAmt"
                              placeholder="Price Per unit"
                              {...register(`components.${i}.amount`)}
                            />
                            <div className="invalid-feedback">{errors.components?.[i]?.amount?.message}</div>
                          </div>
                        </div>
                        <div className="col-md">
                          <div className="form-floating">
                            <label>
                              {t("Tax")} (%)<span className="mandatory-field">*</span>
                            </label>
                            <input
                              type="number"
                              className={`form-control gray-border ${errors.components?.[i]?.tax ? "is-invalid" : ""}`}
                              name={`components[${i}].tax`}
                              id="floatingInputTax"
                              placeholder={t("Tax")}
                              {...register(`components.${i}.tax`)}
                            />
                            <div className="invalid-feedback">{errors.components?.[i]?.tax?.message}</div>
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                    <div className="d-grid gap-2 d-md-flex justify-content-end p-1">
                      <button
                        className="btn  btn-lg"
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          removeList1(i);
                        }}
                      >
                        {" "}
                        <i className="far fa-trash-alt" style={{ color: "red" }}></i>
                      </button>
                    </div>
                  </Card>
                ))}
                {elements.length <= 2 && (
                  <Card.Footer>
                    <div className="d-grid gap-2 d-md-flex justify-content-md-between">
                      <button
                        className="btn btn-dark btn-md"
                        type="button"
                        onClick={() => appemdList1({ unit: "", amount: "", tax: "" })}
                      >
                        {" "}
                        (+) {t("Add Price Component")}
                      </button>
                    </div>
                  </Card.Footer>
                )}
                {elements.length > 0 && (
                  <Card>
                    <h6 className="text-start p-3">
                      <b>Idle/Parking Fee</b>
                    </h6>
                    <Card.Body>
                      <div className="row g-2 ">
                        <div className="col-md">
                          <label>{t("Idle Time")}</label>
                          <input
                            type="text"
                            className={`form-control gray-border`}
                            disabled
                            value={getValues("idleTime")}
                          />
                          <br />
                        </div>
                        <div className="col-md">
                          <div className="form-floating">
                            <label>
                              {t("Idle Price")} <span className="mandatory-field">*</span>
                            </label>
                            <input
                              type="number"
                              className={`form-control gray-border`}
                              name={`idlePrice`}
                              id="floatingInputPrice"
                              placeholder={t("Idle Price")}
                              {...register(`idlePrice`)}
                            />
                          </div>
                        </div>
                        <div className="col-md">
                          <div className="form-floating">
                            <div className="form-floating">
                              <label>
                                {t("Select Grace Period")} <span className="mandatory-field">*</span>
                              </label>
                              <select
                                className={`form-select form-control`}
                                name={`gracePeriod`}
                                id="floatingSelectGrace"
                                {...register(`gracePeriod`)}
                              >
                                <option value={""}>{t("Select Grace Period")}</option>
                                <option value={15}>15 min</option>
                                <option value={30}>30 min</option>
                                <option value={45}>45 min</option>
                                <option value={60}>60 min</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                )}
              </div>
            )}

            {formStep === 2 && (
              <Card>
                <Card.Body>
                  <h6 className="text-start p-0 ">
                    <b>{t("Details")}</b>
                  </h6>
                  <fieldset disabled aria-readonly>
                    <div className="row g-2">
                      <div className="col-md">
                        <div className="form-floating">
                          <label>{t("Tariff Name")}</label>
                          <input
                            type="text"
                            className={`form-control gray-border `}
                            name="name"
                            id="floatingInputGridName"
                            readOnly
                            value={getValues("name")}
                          />
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="form-floating">
                          <label>{t("Selected Currency")}</label>
                          <select
                            className={`form-select form-control`}
                            name="unit"
                            id="floatingSelectGridCurrency"
                            readOnly
                            value={getValues("currency")}
                          >
                            <option value={""}>{t("Selected Currency")}</option>
                            {props.allcurrency &&
                              props.allcurrency.currency.map((v, k) => (
                                <option key={v.country} value={v.currency_code}>
                                  {v.currency_code}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="form-floating">
                          <label>{t("Tariff For")}</label>
                          <input
                            type="text"
                            className={`form-control gray-border `}
                            name="isPeak"
                            id="floatingInputGridisPeak"
                            readOnly
                            value={getValues("isPeak")}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row g-2 mt-2">
                      <div className="col-md">
                        <div className="form-floating">
                          <label htmlFor="floatingTextareaUpdateStaticTariff1">{t("Description")}</label>
                          <textarea
                            className={`form-control`}
                            readOnly
                            placeholder="Description"
                            name="description"
                            id="floatingTextareaUpdateStaticTariff1"
                            style={{ height: "100px" }}
                            value={getValues("description")}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <hr></hr>
                    <div className="row g-2 mt-0">
                      <h6 className="text-start pl-3">
                        <b>{t("Price Components")}</b>
                      </h6>
                      <div className="col-auto mt-5">
                        {fieldsList1.map((item, i) => (
                          <div key={i} className="row g-0 p-0">
                            <div className="col-md">
                              <div className="form-floating">
                                <label>{t("Select Charging Unit")}</label>
                                <select
                                  className={`form-select form-control`}
                                  name={`components[${i}].unit`}
                                  id="floatingSelectGridU"
                                  readOnly
                                  value={getValues(`components.${i}.unit`)}
                                >
                                  {props.allchargingunit &&
                                    props.allchargingunit.cunit.map((v, k) => (
                                      <option key={v.unit} value={v.value}>
                                        {v.unit}
                                      </option>
                                    ))}
                                </select>
                              </div>
                              <br />
                            </div>
                            <div className="col-md">
                              <div className="form-floating">
                                <label>{t("Price per/unit")}</label>
                                <input
                                  type="number"
                                  readOnly
                                  className={`form-control gray-border`}
                                  name={`components[${i}].amount`}
                                  id="floatingInputAmt"
                                  value={getValues(`components.${i}.amount`)}
                                />
                              </div>
                            </div>
                            <div className="col-md">
                              <div className="form-floating">
                                <label>{t("Tax")} (%)</label>
                                <input
                                  type="number"
                                  readOnly
                                  className={`form-control gray-border`}
                                  name={`components[${i}].tax`}
                                  id="floatingInputTax"
                                  value={getValues(`components.${i}.tax`)}
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <hr></hr>
                    <div className="row g-2 mt-0">
                      <h6 className="text-start p-0">
                        <b>{t("Idle/Parking Fee")}</b>
                      </h6>

                      <div className="col-auto mt-5">
                        <div className="row">
                          <div className="col-md">
                            <div className="form-floating">
                              <label>{t("Idle Time")}</label>
                              <input
                                className={`form-control`}
                                readOnly
                                placeholder="Idle Time"
                                name="idleTime"
                                id="floatingTextarea1"
                                value={getValues("idleTime")}
                              ></input>
                            </div>
                          </div>
                          <div className="col-md">
                            <div className="form-floating">
                              <label>{t("Idle Price")}</label>
                              <input
                                className={`form-control`}
                                readOnly
                                placeholder="Idle Price"
                                name="idlePrice"
                                id="floatingInputPrice"
                                value={getValues("idlePrice")}
                              ></input>
                            </div>
                          </div>
                          <div className="col-md">
                            <div className="form-floating">
                              <label>{t("Gress Period")}</label>
                              <input
                                className={`form-control`}
                                readOnly
                                placeholder="Gress Period"
                                name="gracePeriod"
                                id="floatingSelectGress"
                                value={getValues("gracePeriod")}
                              ></input>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </Card.Body>
              </Card>
            )}

            {renderButton()}
            <br />
            <br />
          </form>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = function (state) {
  return {
    loading: state.tariff.isLoading,
    allcurrency: state.tariff.fetchcurrency,
    allchargingunit: state.tariff.fetchchargingunit,
    fetchSingleTariffDetails: state.tariff.timeslotSingleFetch,
    updateTimeResponse: state.tariff.timeSlotUpdate,
    fetchCountryCodeRes: state.tariff.fetchCountryCode,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAllCurrency: () => dispatch(allCurrencyAction()),
    getAllChargingUnit: () => dispatch(allChargingUnitAction()),
    getSingleTariffData: (id) => dispatch(singleTimeOfUseTariffAction(id)),
    updateTimeSlot: (countryCode, data) => dispatch(updateTimeSlotTariffAction(countryCode, data)),
    fetchCountryCode: (code) => dispatch(fetchCountryCodeApi(code)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UpdateTimeOfUseTariff));
