import { Container, Card } from "react-bootstrap";
import React, { useEffect, useMemo } from 'react'
import { connect } from "react-redux";
import Table from "../reacttable/table";
import "../reacttable/reactTable.css"
import { getHeavyVehicleAction } from "../../../store/actions/fleetAction";

function HeavyVehicles(props) {
    useEffect(() => {
        props.all()
    }, []);

    const data = props.list && props.list.result
    const fuels = data && data.map(er => er.fuels)

    const columns = useMemo(
        () => [
            {
                Header: 'Manufacturer Name',
                accessor: 'heavy_duty_manufacturer_name'
            },
            {
                Header: 'model',
                accessor: 'model'
            },
            {
                Header: 'Transmission Make',
                accessor: 'transmission_make'
            },
            {
                Header: 'Fuels',
                id: 'fuels',
                // accessor: fuels && fuels.map ((record,index) =>{
                //     return(
                //         <span key={record.id}>
                //             {(index ? ', ' : '') + record.name}
                //         </span>
                //     )
                // })
            },
            {
                Header: 'Heavy Duty Engines',
                id: 'heavy_duty_engines',
                // accessor: fuels && fuels.map ((record,index) =>{
                //     return(
                //         <span key={record.id}>
                //             {(index ? ', ' : '') + record.name}
                //         </span>
                //     )
                // })
            },
            {
                Header: 'Vehicle Category',
                id: 'vehicle_categories',
                // accessor: fuels && fuels.map ((record,index) =>{
                //     return(
                //         <span key={record.id}>
                //             {(index ? ', ' : '') + record.name}
                //         </span>
                //     )
                // })
            },

        ],
        []
    );


    return (
        <Container fluid>
            <div class="row">
                <div class="col-lg-12 col-sm-12 pad_t2">
                    <Card className="customercard">
                        <Card.Header>Heavy Vehicle List</Card.Header>
                        <div>
                        {data && data.length > 0 ?
                                (<div className="App"> <Table data={data} columns={columns}></Table></div>)
                                :
                                (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}><h3 className="loader">No data found</h3></div>)
                            }
                        </div>
                    </Card>
                </div>
            </div>


        </Container>
    )
}



const mapStateToProps = (state) => {
    return {
        list: state.fleet.heavy,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        all: () => dispatch(getHeavyVehicleAction()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(HeavyVehicles)
