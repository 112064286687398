import React, { useEffect, useState } from 'react';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useTranslation } from 'react-i18next';

// Create Document Component
const VendorInvoiceDocument = (props) => {
  const [isPdfDownloaded, setIsPdfDownloaded] = useState(false);
  const { t } = useTranslation();
  const gstNumber = process.env.REACT_APP_CLIENT_GST_NO;
  const generatePDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(12);
    const org_name = props.org == "All" ? "All" : props.org
    const { organisations } = props.orgdata
    const organisation = organisations && organisations.filter(or => or.name == org_name)
    // const {address,city,email,phone}=organisation[0]
    const range = "Date range: " + props.start.toLocaleString() + " to " + props.end.toLocaleString()
    const title = "Organisation: " + org_name;
    // const org_add="Address: "+address;
    // const org_city="City: "+city
    // const org_email="Email: "+email
    // const org_ph="Phone: "+phone.toString()
    const v_amt = (props.csvDataRes?.total_revenue)
    const tax = ((v_amt * 15) / 100)
    const org_unit = "Consumed kWh: " + props.reportCountRes?.total_energy
    const rfid_free_charging_energy = "Free charging consumed kWh: " + props.reportCountRes?.free_charging_total_energy
    const org_amt = "Total Amount: " + v_amt.toFixed(2)
    const org_tax = "Tax Amount: " + tax.toFixed(2)
    const total_amt = "Total Payable(Amount + Tax): " + "$" + (v_amt + tax).toFixed(2);
    const free_charging_energy = props.reportCountRes?.free_charging_total_energy;
    const total_kwh = `Total kWh ${(props.reportCountRes?.total_energy - free_charging_energy).toFixed(2)}`;
    const gst_number = "GST Number: " + gstNumber;

    const headers = [["Date",
      "Tag ID",
      "Station Name",
      "City",
      "Start time",
      "End time",
      "Charging time (HH:MM:SS)",
      "Is free charging?",
      "Total kWh"
    ]];
    const data = props.csvDataRes?.data.map(elt => [elt.end_date,
    elt.idtag,
    elt.stationid,
    elt.city,
    elt.start_time,
    elt.stop_time,
    elt.consume_time,
    elt.free_charging ? "Yes" : "No",
    elt.kwh
    ]);

    let content = {
      startY: 160,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    doc.text(range, marginLeft, 60);
    doc.text(org_unit, marginLeft, 80);
    doc.text(rfid_free_charging_energy, marginLeft, 100);
    doc.text(total_kwh, marginLeft, 120);
    doc.text(gst_number, marginLeft, 140);
    doc.autoTable(content);
    doc.save(props.org + "_" + props.start.toISOString().substr(0, 10) + "_" + props.end.toISOString().substr(0, 10) + `.pdf`)
  }

  const handleButtonClick = () => {
    // Generate the PDF
    props.csvDataList(props.start?.toISOString()?.substr(0, 10), props.end?.toISOString()?.substr(0, 10), props.org, 1, 10000000);
    setIsPdfDownloaded(true)
    // props.onClick();
  };

  useEffect(()=>{
    if(props.csvDataRes?.statuscode === 200 && isPdfDownloaded){
      generatePDF();
      setIsPdfDownloaded(false);
    }
  },[props.csvDataRes])

  return (
    <div>
      <button onClick={handleButtonClick} className='pdfbutton' type="button">{t("PDF(Partner)")}</button>
    </div>
  )
}

export default VendorInvoiceDocument;