import { t } from "i18next";
import React, { useMemo } from "react";
import ApexCharts from "react-apexcharts";
import { Card } from "react-bootstrap";

function CustomMonthlyRevenue(props) {
  const currency = process.env.REACT_APP_CURRENCY;

  // Helper function for month conversion
  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  // Memoized values for monthly amount, revenue, and month labels
  const { monthlyRevenue, monthLabels } = useMemo(() => {
    if (!props.month_total || !props.month_total.data) return { monthlyRevenue: [], monthLabels: [] };

    const monthlyRevenue = props.month_total.data?.map((amt) => Math.round(amt.amount));
    const monthLabels = props.month_total.data?.map((label) => monthNames[label.ID.number - 1]);

    return { monthlyRevenue, monthLabels };
  }, [props.month_total]);

  // Configuration for ApexCharts
  const monthdata = {
    chart: {
      type: "area",
      height: 350,
    },
    xaxis: {
      categories: monthLabels,
    },
    yaxis: [
      {
        title: {
          text: "Monthly Revenue",
        },
        labels: {
          formatter: (val) => `${currency} ${val.toLocaleString()}`,
        },
      },
    ],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    colors: ["#5559B2"],
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100],
      },
    },
    series: [
      {
        name: "Monthly Revenue",
        data: monthlyRevenue,
      },
    ],
    tooltip: {
      x: {
        formatter: (val) => val,
      },
    },
  };

  return (
    <Card className="statuscard">
      <Card.Title className="text-center">
        {t("Monthly Revenue Graph")}
      </Card.Title>
      <Card.Body>
        {props.loading ? (
          <h3
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "250px",
            }}
          >
            Loading data...
          </h3>
        ) : props.month_total && props.month_total.data.length > 0 ? (
          <ApexCharts options={monthdata} series={monthdata.series} type="area" height={250} />
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "250px",
            }}
          >
            <h3>{t("No data found")}</h3>
          </div>
        )}
      </Card.Body>
    </Card>
  );
}

export default CustomMonthlyRevenue;
