import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { Container, Card, Row, Col } from "react-bootstrap";
import PureBreadcrumbs from "../../breadcrums";
import { fetchSingleSubscriptionApi } from '../../../../store/actions/walletActions';
import { useTranslation } from 'react-i18next';

export const ViewSingleSubscription = (props) => {
    const { name } = useParams();
    const { t } = useTranslation();
    // console.log("name: ", name);

    useEffect(() => {
        props.fetchSingle(name);
    }, [])

    const data = props.fetchSingleRes && props.fetchSingleRes.data;
    // console.log(data);

    return (
        <Container fluid >
            <PureBreadcrumbs />
            <Row className='mt-3'>
                <Col>
                    <Card className='tariffcard'>
                        <div className='table-header'>{t("View Single Subscription")}</div>
                        <Card.Body>
                            <form onSubmit={(e) => e.preventDefault()}>
                                <Row>
                                    <Col lg={12}>
                                        <div className="form-floating">
                                            <label>
                                                {t("Subscription Name")}

                                            </label>
                                            <input
                                                type="text"
                                                className={`form-control gray-border`}
                                                name={`subscriptionName`}
                                                id="floatingInputsubscriptionName"
                                                placeholder={t("Subscription Name")}
                                                disabled
                                                value={data?.sub_name || ''}
                                            />

                                        </div>
                                    </Col>
                                </Row>
                                <div className="row g-2 mt-3 ">
                                    <div className="col-md">
                                        <div className="form-floating">
                                            <label>
                                                {t("Subscription Type")}

                                            </label>
                                            <select
                                                className={`form-select form-control`}
                                                name={`subscriptionType`}
                                                id="floatingSelectGridsubscriptionType"
                                                disabled
                                            // value={data?.sub_type}
                                            >
                                                <option value={data?.sub_type}>{data?.sub_type}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-md'>
                                        <div className="form-floating">
                                            <label>
                                                {t("Subscription Value")}

                                            </label>
                                            <input
                                                type="number"
                                                className={`form-control gray-border`}
                                                name={`subscriptionValue`}
                                                id="floatingInputSubscriptionValue"
                                                placeholder={t("Subscription Value")}
                                                disabled
                                                value={data?.sub_value || 0}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md">
                                        <div className="form-floating">
                                            <label>
                                                {t("Subscription Duration")}

                                            </label>
                                            <select
                                                className={`form-select form-control`}
                                                name={`subscriptionDuration`}
                                                id="floatingSelectGridsubscriptionDuration"
                                                disabled
                                            >
                                                <option value={data?.sub_duration}>{data?.sub_duration}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <Row className='mt-3 '>
                                    <Col lg={12}>
                                        <div className="form-floating">
                                            <label>
                                                {t("Recharge Amount")}

                                            </label>
                                            <input
                                                type="number"
                                                className={`form-control gray-border`}
                                                name={`rechargeAmount`}
                                                id="floatingInputrechargeAmount"
                                                placeholder={t("Recharge Amount")}
                                                disabled
                                                value={data?.recharge_amount || 0}
                                            />

                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <div className='col-12 mt-2'>
                                        <div className="form-floating">
                                            <label>{t("Description")}</label>
                                            <textarea
                                                className={`form-control`}
                                                placeholder={t("Description...")}
                                                aria-label={t("Description")}
                                                name="description"
                                                id="floatingTextarea"
                                                style={{ "height": "120px" }}
                                                disabled
                                                value={data?.description || "Not available"}
                                            >
                                            </textarea>
                                        </div>
                                    </div>
                                </Row>
                            </form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

const mapStateToProps = (state) => {
    return {
        loading: state.wallet.isLoading,
        fetchSingleRes: state.wallet.fetchSingleSub,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchSingle: (name) => dispatch(fetchSingleSubscriptionApi(name)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewSingleSubscription)