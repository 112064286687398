import React, { useMemo, useEffect, useState } from "react"
import { connect } from "react-redux";
import { FaEdit, FaEye } from "react-icons/fa";
import { activateAccount, allCutomersAction, deactivateAccount } from "../../../../store/actions/customerActions";
import Table from "../../reacttable/table";
import "../../reacttable/reactTable.css"
import { TiTick } from "react-icons/ti";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import CustomReportPaginationTable from "../../report/adminReport/customReportPaginationTable";


function AccountList(props) {
    const { t } = useTranslation();
    const { allCustomerData } = props;
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [search, setSearch] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const data = props.list && props.list.customers;
    const document_count = props.list && props.list.document_count;
    const defaultPageSize = 10;
    const pageCount = Math.ceil(document_count / defaultPageSize)
    // console.log("data", data)
    useEffect(() => {
        // console.log("page & limit", currentPage, pageSize)
        props.getCustomers(currentPage, pageSize)
    }, [currentPage, pageSize])

    const activateUserAccount = (userid) => {
        // console.log(userid);
        props.activateAccounts(userid);
    }

    const deactivateUserAccount = (userid) => {
        // console.log(userid);
        props.deactivateAccounts(userid);
    };

    useEffect(() => {
        if (props.activate.statuscode === 200) {
            props.getCustomers(currentPage, pageSize)
            // window.location.reload();
        }
    }, [props.activate])

    useEffect(() => {
        if (props.deactivate.statuscode === 200) {
            props.getCustomers(currentPage, pageSize)
            // window.location.reload();
        }
    }, [props.deactivate])


    const columns = useMemo(
        () => [
            {
                Header: 'First Name',
                accessor: 'firstname'
            },
            {
                Header: 'Last Name',
                accessor: 'lastname'
            },
            {
                Header: 'Email',
                accessor: 'email'
            },
            {
                Header: 'Mobile',
                accessor: 'mobile'
            },
            {
                Header: 'Country',
                accessor: 'country'
            },
            {
                Header: 'City',
                accessor: 'city'
            },
            {
                Header: 'State',
                accessor: 'state'
            },
            {
                Header: 'Account Type',
                accessor: 'accounttype'
            },
            {
                Header: 'Marketing Consent',
                id: "communication",
                accessor: 'communication',
                Cell: (item) => {
                    // console.log("d", item.cell)
                    const value = item.cell
                    return value ? <span className="text-success">Given</span> : <span className="text-danger">Not Given</span>
                }
            },
            {
                Header: 'Create Date',
                accessor: 'createat'
            },
            {
                Header: 'Status',
                id: "status",
                accessor: 'status',
                Cell: item => {
                    const value = item.cell
                    // console.log("value: ", value)
                    return value ? <span className="text-success">Active</span> : <span className="text-danger">Inactive</span>
                }
            },
            {
                Header: 'Actions',
                accessor: 'userid',
                Cell: (item) => {
                    const userid = item.cell
                    return (
                        <div>
                            <span>
                                <a href={`/dashboard/accountcard/viewaccount/${userid}`}
                                    rel="tooltip"
                                    title="View"
                                >
                                    <FaEye />
                                </a></span>&nbsp;&nbsp;
                            <span>
                                <a href={`/dashboard/accountcard/updateaccount/${userid}`}
                                    rel="tooltip"
                                    title="Edit"
                                >
                                    <FaEdit />
                                </a>
                            </span>
                            &nbsp;&nbsp;
                            <span>
                                <TiTick
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => activateUserAccount(userid)}
                                    title="Activate"
                                />
                            </span>
                            &nbsp;&nbsp;
                            <span>
                                <AiOutlineCloseCircle
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => deactivateUserAccount(userid)}
                                    title="De-Activate"
                                />
                            </span>
                        </div>

                    );
                },
            }
        ],
        []
    );

    const initialVisibleColumns =
        [
            'firstname', 'lastname', 'mobile', 'email', 'country',
            'city', 'state', 'accounttype', 'communication', 'createat', 'status',
            'userid',
        ];


    return (

        <div>
            {props.loading ? <h3 style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "250px",
            }}>Loading data...</h3> :
                data && data.length > 0 ?
                    (
                        <div>
                            <CustomReportPaginationTable
                                data={data}
                                allData={allCustomerData}
                                pageCount={pageCount}
                                columns={columns}
                                search={search}
                                setSearch={setSearch}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                pageSize={pageSize}
                                setPageSize={setPageSize}
                                filteredData={filteredData}
                                setFilteredData={setFilteredData}
                                loading={props.loading}
                                initialVisibleColumns={initialVisibleColumns}
                                t={t}
                            />
                            {/* <Table data={data} columns={columns}></Table> */}
                        </div>
                    )
                    :
                    (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}><h3 className="loader">No data found</h3></div>)
            }
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        loading: state.customer.isLoading,
        list: state.customer.fetchcutomers,
        activate: state.customer.activateAccount,
        deactivate: state.customer.deactivateAccount,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getCustomers: (page, limit) => dispatch(allCutomersAction(page, limit)),
        activateAccounts: (userid) => dispatch(activateAccount(userid)),
        deactivateAccounts: (userid) => dispatch(deactivateAccount(userid))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AccountList)