const initState = {
    isLoading: false,
    getStripeBalance: '',
    getCountryAndCurrency: '',
    getAllConnectAcount: '',
    getSingleAcountInfo: '',
    deleteConnectAccount: '',
    createConnectCustomer: '',
    getCustomerToken: '',
    createExternalAccount: '',
    getAllTransactionList: '',
    getSingleTransactionInfo: '',
    saveStripePassword: '',
    triggerOnboardingEmail: '',
    stripePasswordValidations: '',
    stripeTransfers: '',
    stripePaymentIntents: '',
    stripeConfirmPaymentIntents: '',
    saveManualReconcilation: '',
    updateManualReconcilation: '',
    fetchAllReconcilation: '',
    fetchSingleReconcilation: '',
}

const stripeReducer = (state = initState, action) => {
    switch (action.type) {
        case 'STRIPE_LOADING':
            return { ...state, isLoading: true }
        case 'LOAD_STRIPE_BALANCE':
            return { ...state, getStripeBalance: action.payload, isLoading: false };
        case 'GET_COUNTRY_CURRENCY':
            return { ...state, getCountryAndCurrency: action.payload, isLoading: false };
        case 'GET_ALL_CONNECT_ACCOUNT':
            return { ...state, getAllConnectAcount: action.payload, isLoading: false };
        case 'GET_SINGLE_CONNECT_ACCOUNT':
            return { ...state, getSingleAcountInfo: action.payload, isLoading: false };
        case 'DELETE_CONNECT_ACCOUNT':
            return { ...state, deleteConnectAccount: action.payload, isLoading: false };
        case 'CREATE_STRIPE_CUSTOMER':
            return { ...state, createConnectCustomer: action.payload, isLoading: false };
        case 'CREATE_STRIPE_CUSTOMER_TOKEN':
            return { ...state, getCustomerToken: action.payload, isLoading: false };
        case 'CREATE_STRIPE_CUSTOMER_EXT_ACCOUNT':
            return { ...state, createExternalAccount: action.payload, isLoading: false };
        case 'GET_ALL_TRANSFER_DETAILS':
            return { ...state, getAllTransactionList: action.payload, isLoading: false };
        case 'GET_SINGLE_TRANSFER_DETAILS':
            return { ...state, getSingleTransactionInfo: action.payload, isLoading: false };
        case 'SAVE_STRIPE_PASSWORD_API':
            return { ...state, saveStripePassword: action.payload, isLoading: false };
        case 'TRIGGER_ONBOARDING_EMAIL':
            return { ...state, triggerOnboardingEmail: action.payload, isLoading: false };
        case 'VALIDATE_STRIPE_PASSWORD_API':
            return { ...state, stripePasswordValidations: action.payload, isLoading: false };
        case 'STRIPE_TRANSFER_API':
            return { ...state, stripeTransfers: action.payload, isLoading: false };
        case 'STRIPE_PAYMENT_INTENTS_API':
            return { ...state, stripePaymentIntents: action.payload, isLoading: false };
        case 'STRIPE_CONFIRM_PAYMENT_INTENTS_API':
            return { ...state, stripeConfirmPaymentIntents: action.payload, isLoading: false };
        case 'CREATE_MANUAL_RECONCILATION_API':
            return { ...state, saveManualReconcilation: action.payload, isLoading: false };
        case 'UPDATE_MANUAL_RECONCILATION_API':
            return { ...state, updateManualReconcilation: action.payload, isLoading: false };
        case 'FETCH_ALL_MANUAL_RECONCILATION_API':
            return { ...state, fetchAllReconcilation: action.payload, isLoading: false };
        case 'FETCH_SINGLE_RECONCILATION_API':
            return { ...state, fetchSingleReconcilation: action.payload, isLoading: false };
        default:
            return state;
    }
}
export default stripeReducer;