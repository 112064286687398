import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import PureBreadcrumbs from "../breadcrums";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import "./settings.css"
import { useDispatch } from "react-redux";
import { logoutAction } from "../../../store/actions/authAction";
import { updateUserPasswordAction } from '../../../store/actions/accessAction';
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai"
import { useTranslation } from 'react-i18next';
import { saveStripePassword } from '../../../store/actions/stripeAction';


function UserSettings(props) {
    const { t } = useTranslation();
    const { first_name, last_name, city, country, email, organisation, organisation_type, postal_code, phone, role, state, userid } = JSON.parse(localStorage.getItem('user'))

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [stripeModalshow, setStripeModalshow] = useState(false);
    const handleStripeModalClose = () => setStripeModalshow(false);
    const handleStripeModalShow = () => setStripeModalshow(true);

    const dispatch = useDispatch()
    function LogoutHandler() {
        dispatch(logoutAction())
    }

    const changePasswordSchema = Yup.object().shape({
        password: Yup.string().required('New Password is required').min(6, 'Password must be at least 6 characters').max(40, 'Password must not exceed 40 characters'),
        confirmPassword: Yup.string().required('New Password is required').min(6, 'Confirm Password must be at least 6 characters').max(40, 'Confirm Password must not exceed 40 characters'),
    });

    const { register: registerFirst, handleSubmit: handleSubmitFirst, getValues: getValuesFirst, setValue: setValueFirst, formState: { errors: errorsFirst } } = useForm({
        resolver: yupResolver(changePasswordSchema),
    });

    const saveStripePasswordSchema = Yup.object().shape({
        stripe_password: Yup.string()
            .required('Stripe Password is required')
            .min(6, 'Stripe Password must be at least 6 characters')
            .max(40, 'Stripe Password must not exceed 40 characters'),
    });

    const { register: registerSecond, handleSubmit: handleSubmitSecond, getValues: getValuesSecond, setValue: setValueSecond, formState: { errors: errorsSecond } } = useForm({
        resolver: yupResolver(saveStripePasswordSchema),
    });

    const onSubmit1 = (record) => {
        const pass1 = getValuesFirst('password');
        const confirmpass1 = getValuesFirst('confirmPassword');
        if (pass1 === confirmpass1) {
            // console.log("record", record)
            props.updatePassData(record)
        } else {
            alert("Passwords do not match. Please try again")
            // window.location.reload();
        }

    };

    const onSubmit2 = (data) => {
        // console.log("stripe password", data)
        props.saveStripePassData(data)
    };

    if (props.stripeRes.statuscode === 200) {
        alert("Stripe Password saved successfully");
        window.location.reload();
    }

    useEffect(() => {
        if (props.response.statuscode === 405) {
            alert(props.response.error)
            window.location.reload();
        }
        if (props.response.statuscode === 200) {
            alert(props.response.result + ". You will be logged out now.")
            LogoutHandler();
        }

    }, [props.response])

    const [passwordVisible, setPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    return (
        <Container fluid>
            <PureBreadcrumbs />
            <br />

            <Row className="mt-4">
                <Col xs={12} md={12}>
                    <h3> {t("Settings")} </h3>
                    <br />
                    <Row>
                        <Col xs={12} md={4}>
                            <div>
                                <label htmlFor="floatingInputGridName">{t("First Name")}</label>
                                <input className="input gray-border" type="text" name="fullname" id="floatingInputGridName" placeholder="Full Name" defaultValue={first_name} disabled />
                            </div>
                        </Col>
                        <Col xs={12} md={2}>
                        </Col>
                        <Col xs={12} md={4}>
                            <div>
                                <label htmlFor="floatingInputGridName">{t("Last Name")}</label>
                                <input className="input gray-border" type="text" name="lastname" id="floatingInputGridLastName" placeholder="Last Name" defaultValue={last_name} disabled />
                            </div>
                        </Col>
                    </Row>

                    <Row className="mt-4">
                        <Col xs={12} md={4}>
                            <div>
                                <label htmlFor="floatingInputGridName">{t("User Name")}</label>
                                <input className="input gray-border" type="text" name="userid" id="floatingInputGridUserID" placeholder="User ID" defaultValue={userid} disabled />
                            </div>
                        </Col>
                        <Col xs={12} md={2}>
                        </Col>
                        <Col xs={12} md={4}>
                            <div>
                                <label htmlFor="floatingInputGridName">{t("Role")}</label>
                                <input className="input gray-border" type="text" name="role" id="floatingInputGridRole" placeholder="Role" defaultValue={role} disabled />
                            </div>
                        </Col>
                    </Row>

                    <Row className="mt-4">
                        <Col xs={12} md={4}>
                            <div>
                                <label htmlFor="floatingInputGridName">{t("Phone No.")}</label>
                                <input className="input gray-border" type="text" name="phone" id="floatingInputGridmn" placeholder="Phone Number" defaultValue={phone} disabled />
                            </div>
                        </Col>
                        <Col xs={12} md={2}>
                        </Col>
                        <Col xs={12} md={4}>
                            <div>
                                <label htmlFor="floatingInputGridName">{t("Email")}</label>
                                <input className="input gray-border" type="text" name="email" id="floatingInputGridemail" placeholder="Email ID" defaultValue={email} disabled />
                            </div>
                        </Col>
                    </Row>

                    <Row className="mt-4">
                        <Col xs={12} md={4}>
                            <div>
                                <label htmlFor="floatingInputGridName">{t("Organisation Name")}</label>
                                <input className="input gray-border" type="text" name="org" id="floatingInputGridorg" placeholder="Organisation Name" defaultValue={organisation} disabled />
                            </div>
                        </Col>
                        <Col xs={12} md={2}>
                        </Col>
                        <Col xs={12} md={4}>
                            <div>
                                <label htmlFor="floatingInputGridName">{t("Organisation Type")}</label>
                                <input className="input gray-border" type="text" name="org_type" id="floatingInputGridOtype" placeholder="Organisation Type" defaultValue={organisation_type} disabled />
                            </div>
                        </Col>
                    </Row>

                    <Row className="mt-4">
                        <Col xs={12} md={4}>
                            <div>
                                <label htmlFor="floatingInputGridName">{t("City")}</label>
                                <input className="input gray-border" type="text" name="city" id="floatingInputGridCity" placeholder="City" defaultValue={city} disabled />
                            </div>
                        </Col>
                        <Col xs={12} md={2}>
                        </Col>
                        <Col xs={12} md={4}>
                            <div>
                                <label htmlFor="floatingInputGridName">{t("State")}</label>
                                <input className="input gray-border" type="text" name="state" id="floatingInputGridState" placeholder="State" defaultValue={state} disabled />
                            </div>
                        </Col>
                    </Row>

                    <Row className="mt-4">
                        <Col xs={12} md={4}>
                            <div>
                                <label htmlFor="floatingInputGridName">{t("Country")}</label>
                                <input className="input gray-border" type="text" name="country" id="floatingInputGridCountry" placeholder="country" defaultValue={country} disabled />
                            </div>
                        </Col>
                        <Col xs={12} md={2}>
                        </Col>
                        <Col xs={12} md={4}>
                            <div>
                                <label htmlFor="floatingInputGridName">{t("Zipcode")}</label>
                                <input className="input gray-border" type="text" name="postalcode" id="floatingInputGridCode" placeholder="Postal Code" defaultValue={postal_code} disabled />
                            </div>
                        </Col>
                    </Row>

                    {/* Change Password Code */}
                    <Row className="mt-4">
                        <Col xs={12} lg={6} style={{ fontWeight: 600 }}>
                            <i className="fa-solid fa-house"></i>{t("To Change your Current Password")} &nbsp;
                            <Button variant="primary" onClick={handleShow}>
                                {t("Click here")}
                            </Button>
                            <form onSubmit={e => e.preventDefault()}>
                                <Modal show={show} onHide={handleClose}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>{t("Change Password for")} <b>{userid}</b></Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>

                                        <div className="form-floating">
                                            <input type={passwordVisible ? "text" : "password"} className={`form-control gray-border ${errorsFirst.password ? 'is-invalid' : ''}`} name="password" id="floatingInputGridPassword" placeholder={t("Enter the new password")} {...registerFirst("password")} />
                                            <span className="p-viewer-resetpass">
                                                <i className="btn" onClick={togglePasswordVisibility}>
                                                    {passwordVisible ? <AiFillEye /> : <AiFillEyeInvisible />}
                                                </i>
                                            </span>
                                            <div className="invalid-feedback">{errorsFirst.password?.message}</div>
                                        </div><br />

                                        <div className="form-floating">
                                            <input type="password" className={`form-control gray-border ${errorsFirst.confirmPassword ? 'is-invalid' : ''}`} name="confirmPassword" id="floatingInputGridCPassword" placeholder={t("Re-enter the new password")}  {...registerFirst("confirmPassword")} />
                                            <div className="invalid-feedback">{errorsFirst.confirmPassword?.message}</div>
                                        </div><br />

                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleClose}>
                                            {t("Close")}
                                        </Button>
                                        <Button variant="primary" onClick={handleSubmitFirst(onSubmit1)}>{t("Update Password")}
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </form>
                        </Col>

                        {/* Save Stripe Password Code */}

                        {/* {role === "Admin" &&
                            <Col xs={12} lg={6} style={{ fontWeight: 600 }}>
                                <i className="fa-solid fa-house"></i>{t("To Save your Stripe Password")} &nbsp;
                                <Button variant="primary" onClick={handleStripeModalShow}>
                                    {t("Save Password")}
                                </Button>
                                <form onSubmit={e => e.preventDefault()}>
                                    <Modal show={stripeModalshow} onHide={handleStripeModalClose}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>{t("Save stripe password here")} <b>{userid}</b></Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="form-floating">
                                                <label className='mt-1' htmlFor="floatingInputGridStripeUserID">{t("User Name")}</label>
                                                <input className="input gray-border" type="text" name="userid" id="floatingInputGridStripeUserID" placeholder="User ID" defaultValue={userid} disabled />
                                            </div>
                                            <div className="form-floating">
                                                <label className='mt-1' htmlFor="floatingInputGridPhoneNumber">{t("Phone No.")}</label>
                                                <input className="input gray-border" type="text" name="phone" id="floatingInputGridPhoneNumber" placeholder="Phone Number" defaultValue={phone} disabled />
                                            </div>
                                            <div className="form-floating">
                                                <label className='mt-1' htmlFor="floatingInputGridStripeEmail">{t("Email")}</label>
                                                <input className="input gray-border" type="text" name="email" id="floatingInputGridStripeEmail" placeholder="Email ID" defaultValue={email} disabled />
                                            </div>
                                            <div className="form-floating">
                                                <label className='mt-1' htmlFor="floatingInputGridstripe_password">{t("Stripe Password")}</label>
                                                <input type={passwordVisible ? "text" : "password"} className={`form-control gray-border ${errorsSecond.stripe_password ? 'is-invalid' : ''}`} name="stripe_password" id="floatingInputGridstripe_password" placeholder={t("Enter Stripe Password")} {...registerSecond("stripe_password")} />
                                                <span className="p-viewer-stripepass">
                                                    <i className="btn" onClick={togglePasswordVisibility}>
                                                        {passwordVisible ? <AiFillEye /> : <AiFillEyeInvisible />}
                                                    </i>
                                                </span>
                                                <div className="invalid-feedback">{errorsSecond.stripe_password?.message}</div>
                                            </div>
                                            <br />
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={handleStripeModalClose}>
                                                {t("Close")}
                                            </Button>
                                            <Button variant="primary" onClick={handleSubmitSecond(onSubmit2)}>{t("Save Password")}
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                </form>
                            </Col>
                        } */}
                    </Row>
                </Col>
            </Row>

        </Container>
    )

}

const mapStateToProps = (state) => {
    return {
        response: state.access.changePassword,
        stripeRes: state.stripe.saveStripePassword,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        updatePassData: (record) => dispatch(updateUserPasswordAction(record)),
        saveStripePassData: (data) => dispatch(saveStripePassword(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSettings)
