import React, { useEffect, useState, useMemo } from "react";
import ChargingImage from "../../../assets/images/liveCharging.gif";
import NotAvailable from "../../../assets/images/notCharging.png";
import { fetchLiveChargingAction } from "../../../../store/actions/evseAction";
import { connect } from "react-redux";

const cardContainerStyle = {
  display: "flex",
  flexDirection: "row",
  gap: "20px",
  justifyContent: "space-around",
  alignItems: "center",
  width: "100%",
};

// Component for displaying dynamic charging station with details and image
const ChargingCard = ({ item, index }) => (
  <div className="tarrifcard" key={index}>
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <p style={{ fontSize: "14px", fontWeight: "600", wordBreak: "break-word", textAlign: "center" }}>
        {item.evse_id}/{item.portno}
      </p>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        {item.soc !== 0 && (
          <div style={{ fontSize: "20px", fontWeight: "bold", marginLeft: "50px" }}>{item.soc}%</div>
        )}
        <img src={ChargingImage} style={{ width: "120px" }} alt="Charging" />
      </div>
      <br />
      <div className="spinner-container">
        <div className="spinner"></div>
        <div className="spinner-text">{item.kwh}kWh</div>
      </div>
    </div>
  </div>
);

// Component for displaying a Static station image when not charging
const NotChargingCard = ({ index }) => (
  <div key={index} style={{ width: "10%", display: "flex", justifyContent: "center" }}>
    <img src={NotAvailable} style={{ width: "90px" }} alt="Not Available" />
  </div>
);

function LiveCharging({ LiveChargingApi, LiveChargingRes }) {

  const [ChargingData, setChargingData] = useState([]);
  // Memoize an empty array for inactive charging slots to prevent re-creation on every render
  const ChargingArray = useMemo(() => Array(4).fill(""), []);

  useEffect(() => {
    LiveChargingApi();
    const interval = setInterval(() => {
      LiveChargingApi();
    }, 300000);
    return () => clearInterval(interval);
  }, [LiveChargingApi]);

  // Update ChargingData state whenever API response changes
  useEffect(() => {
    const liveData = LiveChargingRes?.data ?? []; // Default to empty array if data is unavailable
    setChargingData([...liveData, ...ChargingArray.slice(liveData.length)]); // Fill up to 4 slots with placeholders
  }, [LiveChargingRes, ChargingArray]);


  return (
    <div style={cardContainerStyle}>
      {ChargingData?.map((item, index) =>
        index <= 3 ? (  // limit to show only 4 items
          item?.status === "Charging" ? (
            <ChargingCard item={item} index={index} key={index} />
          ) : (
            <NotChargingCard index={index} />
          )
        ) : null
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  loading: state.evse.isEvseLoading,
  LiveChargingRes: state.evse.livecharging,
});

const mapDispatchToProps = (dispatch) => ({
  LiveChargingApi: () => dispatch(fetchLiveChargingAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LiveCharging);
