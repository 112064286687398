import React from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import "react-big-calendar/lib/css/react-big-calendar.css"

const localizer = momentLocalizer(moment)

const CustomToolbar = (props) => {
    const { label, onView, view } = props;

    return (
        <div className="rbc-toolbar">
            <span className="rbc-toolbar-label">{label}</span>

            <span className="rbc-btn-group">
                <button
                    type="button"
                    className={view === 'month' ? 'rbc-active' : ''}
                    onClick={() => onView('month')}
                >
                    Month
                </button>
                <button
                    type="button"
                    className={view === 'week' ? 'rbc-active' : ''}
                    onClick={() => onView('week')}
                >
                    Week
                </button>
                <button
                    type="button"
                    className={view === 'day' ? 'rbc-active' : ''}
                    onClick={() => onView('day')}
                >
                    Day
                </button>
            </span>
        </div>
    );
};

const handleSelectEvent = (event) => {
    alert(event.title)
    // console.log(event);
}

function FleetCalendar(props) {
    return (
        <div>
            <Calendar
                {...props}
                localizer={localizer}
                style={{ height: "70vh" }}
                components={{
                    toolbar: CustomToolbar
                }}
                onSelectEvent={handleSelectEvent}
            />
        </div>
    )
}

export default FleetCalendar;
