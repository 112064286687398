import React, { useMemo } from "react"
import { connect } from "react-redux";
import "../../reacttable/reactTable.css"
import Table from "../../reacttable/table";
import { FaEdit, FaEye } from "react-icons/fa";
import { allUserAction, deleteUserActionApi, userAdminStatus, validateUserStatus } from "../../../../store/actions/accessAction";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";


function AccessList(props) {
    const { role } = JSON.parse(localStorage.getItem("user"));
    const { t } = useTranslation();
    useEffect(() => {
        props.getStatus();
    }, [])

    const data = props.userlist && props.userlist.users
    // console.log(data)
    const statusData = props.userAdminStatus && props.userAdminStatus.status;

    const handleStatusChange = (row, e) => {
        const userid = row.userid;
        const status = e.target.value;
        props.statusValidation(userid, status);
    };

    useEffect(() => {
        // console.log(props.userStatusValidations)
        if (props.userStatusValidations.statuscode == 200) {
            props.getUsers()
        }
    }, [props.userStatusValidations])

    const deleteUser = (id) => {
        const isConfirmed = window.confirm("Are you sure you want to delete the Account?");

        if (isConfirmed) {
            props.deleteUser(id);
        } else {
        }
    };

    if (props.deleteUserRes.statuscode === 200) {
        window.location.reload();
    }

    const columns = useMemo(

        () => [
            { Header: 'Date of enrollment', accessor: 'createdat' },
            { Header: 'User Name', accessor: 'userid', id: 'userid1' },
            { Header: 'First Name', accessor: 'first_name' },
            { Header: 'Last Name', accessor: 'last_name' },
            { Header: 'Email', accessor: 'email' },
            { Header: 'Organisation', accessor: 'organisation' },
            { Header: 'Role', accessor: 'role' },
            { Header: 'City', accessor: 'city' },
            {
                Header: 'Status',
                id: 'userid',
                accessor: (userid) => {
                    const statusData = props.userAdminStatus && props.userAdminStatus.status.filter((item) => item.value !== userid.status);
                    // console.log(statusData)
                    return (
                        <div>
                            <select style={{ width: "auto" }} className="form-select form-control" onChange={(e) => handleStatusChange(userid, e)} >
                                <option value={userid.status}>{userid.status}</option>
                                {statusData &&
                                    statusData.map((v, k) => (
                                        <option key={v.key} value={v.value}>
                                            {v.value}
                                        </option>
                                    ))}
                            </select>
                        </div>
                    )
                }
            },
            {
                Header: 'Actions',
                accessor: 'userid',
                id: 'useridd',
                Cell: (userid) => {
                    // const {original} = row;
                    // console.log(original)
                    return (
                        <div>
                            <span>
                                <a href={`/dashboard/view_access/single/${userid.value}`}
                                    rel="tooltip"
                                    title="View"
                                >
                                    <FaEye />
                                </a></span>
                            &nbsp;&nbsp;
                            <span>
                                <a href={`/dashboard/edit_access/${userid.value}`}
                                    rel="tooltip"
                                    title="Edit"
                                >
                                    <FaEdit />
                                </a>
                            </span>
                            &nbsp;&nbsp;
                            {role === "Admin" &&
                                <span style={{ cursor: "pointer" }}>
                                    <i className="fa fa-trash text-danger"
                                        onClick={() => deleteUser(userid.value)}
                                        title="Delete">
                                    </i>
                                </span>}
                        </div>

                    );
                },
            }
        ],
        [statusData]
    );
    // console.log(props.loading);

    return (
        <div>
            {props.loading ? <h3 style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "550px",
            }}>Loading data...</h3> :
                data && data.length > 0 ?
                    (<div> <Table data={data} columns={columns}></Table></div>)
                    :
                    (<div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "550px",
                        }}
                    >
                        <h3>{t("No data found")}</h3>
                    </div>)
            }
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        userlist: state.access.alluser,
        userAdminStatus: state.access.userStatus,
        loading: state.access.isLoading,
        userStatusValidations: state.access.validateStatus,
        deleteUserRes: state.access.deleteUser
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getUsers: () => dispatch(allUserAction()),
        getStatus: () => dispatch(userAdminStatus()),
        statusValidation: (userid, status) => dispatch(validateUserStatus(userid, status)),
        deleteUser: (userid) => dispatch(deleteUserActionApi(userid)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AccessList)
