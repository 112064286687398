import { CUSTOMER_FEEDBACK_API, CUSTOMER_URL, FETCH_CUSTOMER_LIST_API, FETCH_ALL_RFID_API, SAVE_RFID_API, FETCH_SINGLE_CUSTOMER_DATA_API, UPDATE_CUSTOMER_API, CUSTOMER_PERCENTAGE_CHANGE_API, CHARGING_SERVICE_URL, FETCH_USER_CHARGING_HISTORY_API, CUSTOMER_AUTHENTICATOIN_LOG_API, CUSTOMER_ACTIVATE_ACCOUNT_API, CUSTOMER_DEACTIVATE_ACCOUNT_API, UPDATE_ADMIN_STATUS, GET_ADMIN_STATUS, UPDATE_RFID_API, ACTIVATE_RFID, DEACTIVATE_RFID, SAVE_CUSTOMER_GROUP, FETCH_ALL_CUSTOMER_GROUP, FETCH_SINGLE_CUSTOMER_GROUP_DATA, UPDATE_CUSTOMER_GROUP, ACTIVATE_GROUP_API, DEACTIVATE_GROUP_API, FETCH_RFID_LIST_API, CREATE_RFID_BINDING_WITH_CUSTOMER_API, GUEST_USER_SIGNUP_API, FETCH_SINGLE_RFID_API, CUSTOMER_TEMP_URL } from "../../components/config/config";
import { ApiHandler } from "./apiHandler";

export const customerFeedback = () => {
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    const operator = role === 'Admin' ? 'All' : organisation;
    const bearer = 'basic ' + token
    const functionName = 'customerFeedback'
    return async dispatch => {
        dispatch({ type: 'LOAD_CUSTOMER', payload: '' })
        const data = await ApiHandler(CUSTOMER_URL + CUSTOMER_FEEDBACK_API + "?operator=" + operator, {
            method: "GET",
            functionName,
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'CUSTOMER_FEEDBACK_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const allCutomersAction = (page, limit) => {
    // console.log("page & limit", page, limit)
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    const operator = role === 'Admin' ? 'All' : organisation;
    const bearer = 'basic ' + token
    const functionName = 'allCutomersAction'
    return async dispatch => {
        dispatch({ type: 'LOAD_CUSTOMER', payload: '' })
        const data = await ApiHandler(CUSTOMER_URL + FETCH_CUSTOMER_LIST_API + "?page=" + page + "&limit=" + limit, {
            method: "GET",
            functionName,
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'LOAD_CUSTOMER_LIST', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

// All customer list card count action
export const allCutomersCardCountAction = (page, limit) => {
    // console.log("page & limit", page, limit)
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    const operator = role === 'Admin' ? 'All' : organisation;
    const bearer = 'basic ' + token
    const functionName = 'allCutomersCardCountAction'
    return async dispatch => {
        dispatch({ type: 'LOAD_CUSTOMER', payload: '' })
        const data = await ApiHandler(CUSTOMER_URL + FETCH_CUSTOMER_LIST_API + "?page=" + page + "&limit=" + limit, {
            method: "GET",
            functionName,
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'ALL_CUSTOMER_CARD_COUNT_REDUCER', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const activateGroup = (group_name) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'activateGroup'
    return async dispatch => {
        dispatch({ type: 'LOAD_CUSTOMER', payload: '' })
        const data = await ApiHandler(CUSTOMER_URL + ACTIVATE_GROUP_API + "?group_name=" + group_name, {
            method: "GET",
            functionName,
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'ACTIVATE_CUSTOMER_GROUP', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const deactivateGroup = (group_name) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'deactivateGroup'
    return async dispatch => {
        dispatch({ type: 'LOAD_CUSTOMER', payload: '' })
        const data = await ApiHandler(CUSTOMER_URL + DEACTIVATE_GROUP_API + "?group_name=" + group_name, {
            method: "GET",
            functionName,
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'DEACTIVATE_CUSTOMER_GROUP', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const getAllCustomerGroupingList = () => {
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    // const operator = role === 'Admin' ? 'All' : organisation;
    const bearer = 'basic ' + token
    const functionName = 'getAllCustomerGroupingList'
    return async dispatch => {
        dispatch({ type: 'LOAD_CUSTOMER', payload: '' })
        const data = await ApiHandler(CUSTOMER_URL + FETCH_ALL_CUSTOMER_GROUP + "?role=" + role + "&organisation=" + organisation, {
            method: "GET",
            functionName,
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'FETCH_ALL_CUSTOMER_GROUP', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const getSingleCustomerGroupingList = (group_name) => {
    // console.log("group name from action",group_name)
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    // const operator = role === 'Admin' ? 'All' : organisation;
    const bearer = 'basic ' + token
    const functionName = 'getSingleCustomerGroupingList'
    return async dispatch => {
        dispatch({ type: 'LOAD_CUSTOMER', payload: '' })
        const data = await ApiHandler(CUSTOMER_URL + FETCH_SINGLE_CUSTOMER_GROUP_DATA + "?group_name=" + group_name, {
            method: "GET",
            functionName,
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'FETCH_SINGLE_CUSTOMER_GROUP', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const updateCustomerGrouping = (flatRowData, isChecked, sub_name, tariffName, groupName) => {
    // console.log(flatRowData,tariffName,groupName)
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'updateCustomerGrouping'
    return async dispatch => {
        dispatch({ type: 'LOAD_CUSTOMER', payload: '' })
        const data = await ApiHandler(CUSTOMER_URL + UPDATE_CUSTOMER_GROUP, {
            functionName,
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "role": role,
                "operator": organisation,
                "group_name": groupName,
                "tariff": tariffName,
                "customer": flatRowData,
                "is_valid_subscription": isChecked,
                "subscription_name": sub_name,
            })
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'UPDATE_SINGLE_CUSTOMER_GROUP', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const saveCustomerGrouping = (flatRowData, isChecked, sub_name, tariffName, groupName) => {
    // console.log(flatRowData,tariffName,groupName)
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'saveCustomerGrouping'
    return async dispatch => {
        dispatch({ type: 'LOAD_CUSTOMER', payload: '' })
        const data = await ApiHandler(CUSTOMER_URL + SAVE_CUSTOMER_GROUP, {
            functionName,
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "role": role,
                "operator": organisation,
                "group_name": groupName,
                "tariff": tariffName,
                "customer": flatRowData,
                "is_valid_subscription": isChecked,
                "subscription_name": sub_name,
            })
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'SAVE_CUSTOMER_GROUP', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const singleCutomersAction = (userid) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'singleCutomersAction'
    return async dispatch => {
        dispatch({ type: 'LOAD_CUSTOMER', payload: '' })
        const data = await ApiHandler(CUSTOMER_URL + FETCH_SINGLE_CUSTOMER_DATA_API + "?userid=" + userid, {
            method: "GET",
            functionName,
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'LOAD_SINGLE_CUSTOMER', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const allRFIDAction = (currentPage, pageSize) => {
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'allRFIDAction'
    return async dispatch => {
        dispatch({ type: 'LOAD_CUSTOMER', payload: '' })
        const data = await ApiHandler(CUSTOMER_URL + FETCH_ALL_RFID_API + "?role=" + role + "&organisation=" + organisation + "&page=" + currentPage + "&limit=" + pageSize, {
            method: "GET",
            functionName,
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'LOAD_RFID_LIST', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const fetchAllRfidPinDataAction = (currentPage, pageSize) => {
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'fetchAllRfidPinDataAction'
    return async dispatch => {
        dispatch({ type: 'LOAD_CUSTOMER', payload: '' })
        const data = await ApiHandler(CUSTOMER_URL + FETCH_ALL_RFID_API + "?role=" + role + "&organisation=" + organisation + "&page=" + currentPage + "&limit=" + pageSize, {
            method: "GET",
            functionName,
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("FETCH_ALL_RFID_PIN_DATA_REDUCER", data)
            dispatch({ type: 'FETCH_ALL_RFID_PIN_DATA_REDUCER', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const getStatus = (rateId) => {
    // console.log("id from status console",rateId)
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'getStatus'
    return async dispatch => {
        dispatch({ type: 'LOAD_CUSTOMER', payload: '' })
        const data = await ApiHandler(CUSTOMER_URL + GET_ADMIN_STATUS + "?rate_id=" + rateId, {
            method: "GET",
            functionName,
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'FETCH_ADMIN_STATUS', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const saveRfidAction = (data) => {
    const { rfid, commission, startdate, currency, isAmountBased, isPaymentCollectedOffline, isRfidFreeCharger, rfidRechargeAmount, iskWhBased, kWhUsage, kWhAmount, isTimeBased, time, timeRechargeAmount, rfidType, postpaidDuration, station_name } = data
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    const rfidStationData = station_name && station_name[0] === 'All';
    const formattedDate = new Date(commission).toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
    });
    // console.log("selected stations", station_name)
    // console.log("formattedDate", formattedDate)

    const bearer = 'basic ' + token
    const functionName = 'saveRfidAction'
    return async dispatch => {
        dispatch({ type: 'LOAD_CUSTOMER', payload: '' })
        // console.log(CUSTOMER_URL + SAVE_RFID_API)
        const data = await ApiHandler(CUSTOMER_URL + SAVE_RFID_API, {
            // const data = await ApiHandler(`http://192.168.1.26:7005/customer/secure/api/v1/rfid/add`, {
            functionName,
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "rfid": rfid.trim(),
                'commission': formattedDate,
                'postpaid_activation_date': startdate,
                "currency": currency,
                "amount_based": isAmountBased,
                "free_charging": isRfidFreeCharger,
                'amount': Number(rfidRechargeAmount),
                "kwh_based": iskWhBased,
                "kwh": Number(kWhUsage),
                "kwh_amount": Number(kWhAmount),
                "time_based": isTimeBased,
                "time": Number(time),
                "time_amount": Number(timeRechargeAmount),
                "type": isRfidFreeCharger ? "prepaid" : rfidType,
                "sub_duration": postpaidDuration,
                "station": rfidStationData ? station_name && station_name[0] : '',
                "rfid_stations": rfidStationData ? [] : station_name,
                "offline_payment": isPaymentCollectedOffline,
                'role': role,
                "organisation": organisation
            })
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'SAVE_RFID', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const updateRfidAction = (data, id) => {
    // console.log("data from action", data, id)
    const { rfid, commission, currency, isAmountBased, isPaymentCollectedOffline, isRfidFreeCharger, amount, iskWhBased, kWhUsage, kWhAmount, isTimeBased, time, timeRechargeAmount, rfidType, postpaidDuration, station_name } = data
    let rfidStationData = [];
    let rfidAllValue = '';
    if (station_name && station_name[0] === 'All') {
        rfidAllValue = 'All';
    } else {
        rfidStationData = station_name
    }

    const { token, userid, role, organisation } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'updateRfidAction'
    return async dispatch => {
        dispatch({ type: 'LOAD_CUSTOMER', payload: '' })
        const data = await ApiHandler(CUSTOMER_URL + UPDATE_RFID_API, {
            functionName,
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "id": id,
                "rfid": rfid.trim(),
                'commission': commission,
                "currency": currency,
                "amount_based": isAmountBased,
                "free_charging": isRfidFreeCharger,
                'amount': Number(amount),
                "kwh_based": iskWhBased,
                "kwh": Number(kWhUsage),
                "kwh_amount": Number(kWhAmount),
                "time_based": isTimeBased,
                "time": Number(time),
                "time_amount": Number(timeRechargeAmount),
                "type": isRfidFreeCharger ? "prepaid" : rfidType,
                "sub_duration": postpaidDuration,
                "station": rfidAllValue,
                "rfid_stations": rfidStationData,
                "offline_payment": isPaymentCollectedOffline,
                'role': role,
                "organisation": organisation
            })
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'UPDATE_RFID', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const fetchSingleRfidApi = (id) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'fetchSingleRfidApi'
    return async dispatch => {
        dispatch({ type: 'LOAD_CUSTOMER', payload: '' })
        const data = await ApiHandler(CUSTOMER_URL + FETCH_SINGLE_RFID_API + "?id=" + id, {
            method: "GET",
            functionName,
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'FETCH_SINGLE_RFID_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const activateRfid = (id) => {
    console.log("id", id);
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'activateRfid'
    return async dispatch => {
        dispatch({ type: 'LOAD_CUSTOMER', payload: '' })
        const data = await ApiHandler(CUSTOMER_URL + ACTIVATE_RFID + "?id=" + id, {
            method: "GET",
            functionName,
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'ACTIVATE_RFID', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const deactivateRfid = (id) => {
    // console.log("id", id);
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'deactivateRfid'
    return async dispatch => {
        dispatch({ type: 'LOAD_CUSTOMER', payload: '' })
        const data = await ApiHandler(CUSTOMER_URL + DEACTIVATE_RFID + "?id=" + id, {
            method: "GET",
            functionName,
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'DEACTIVATE_RFID', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const saveFeedbackStatusUpdate = (data) => {
    // console.log("data from save status", data)
    const { extraId, adminFeedback, adminStatus } = data
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'saveFeedbackStatusUpdate'
    return async dispatch => {
        dispatch({ type: 'LOAD_CUSTOMER', payload: '' })
        const data = await ApiHandler(CUSTOMER_URL + UPDATE_ADMIN_STATUS, {
            functionName,
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "id": extraId,
                "status": adminStatus,
                "admin_comment": adminFeedback,
            })
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'SAVE_ADMIN_STATUS', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const updateCustomerProfile = (userid, u_data) => {
    // console.log("userid", userid)
    const { firstname, lastname, email, mobile, address, city, state, country, accounttype, postal_code, corporate_code } = u_data
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'updateCustomerProfile'
    return async dispatch => {
        dispatch({ type: 'LOAD_CUSTOMER', payload: '' })
        const data = await ApiHandler(CUSTOMER_URL + UPDATE_CUSTOMER_API, {
            functionName,
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "userid": userid,
                "firstname": firstname,
                "lastname": lastname,
                "email": email,
                "mobile": mobile,
                "address": address,
                "city": city,
                "state": state,
                "country": country,
                "accounttype": accounttype,
                "postal_code": postal_code,
                "corporate_code": corporate_code
            })
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'UPDATE_CUSTOMER_PROFILE', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const getPercentageChangeData = () => {
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'getPercentageChangeData';

    return async dispatch => {
        dispatch({ type: 'LOAD_CUSTOMER', payload: '' });

        try {
            const response = await fetch(CUSTOMER_URL + CUSTOMER_PERCENTAGE_CHANGE_API + "?role=" + role + "&organisation=" + organisation, {
                method: "GET",
                functionName,
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                }
            });

            // Check if the response is OK (status in the range 200-299)
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            dispatch({ type: 'CUSTOMER_PERCENTAGE_CHANGE_API', payload: data });
        } catch (error) {
            // handle errors
            console.error('Error fetching percentage change data:', error);
        }
    }
}

export const getUserChargingHistory = (userid, start_date, end_date) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'getUserChargingHistory';
    const formatDate = (date) => {
        const d = new Date(date);
        const day = String(d.getDate()).padStart(2, '0');   // Ensure 2 digits for day
        const month = String(d.getMonth() + 1).padStart(2, '0'); // Ensure 2 digits for month
        const year = d.getFullYear();
        return `${day}-${month}-${year}`;
    };

    // Convert the start and end dates to dd-mm-yyyy format
    const formattedStartDate = formatDate(start_date);
    const formattedEndDate = formatDate(end_date);

    return async dispatch => {
        dispatch({ type: 'LOAD_CUSTOMER', payload: '' });

        try {
            const response = await fetch(
                // console.log("http://192.168.1.47:7003" + FETCH_USER_CHARGING_HISTORY_API + "?userid=" + userid + "&start_date=" + formattedStartDate + "&end_date=" + formattedEndDate),
                // "http://192.168.1.47:7003" + FETCH_USER_CHARGING_HISTORY_API + "?userid=" + userid + "&start_date=" + formattedStartDate + "&end_date=" + formattedEndDate,
                CHARGING_SERVICE_URL + FETCH_USER_CHARGING_HISTORY_API + "?userid=" + userid + "&start_date=" + formattedStartDate + "&end_date=" + formattedEndDate,
                {
                    method: "GET",
                    functionName,
                    headers: {
                        'Authorization': bearer,
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    }
                }
            );

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            // console.log("Data:", data);
            dispatch({ type: 'FETCH_USER_CHARGING_HISTORY', payload: data });
        } catch (error) {
            console.error("Error fetching user charging history:", error);
        }
    };
};

export const getUserAuthLog = (userid) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'getUserAuthLog'
    return async dispatch => {
        dispatch({ type: 'LOAD_CUSTOMER', payload: '' })
        const data = await ApiHandler(CUSTOMER_URL + CUSTOMER_AUTHENTICATOIN_LOG_API + "?userid=" + userid, {
            method: "GET",
            functionName,
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'FETCH_USER_AUTHENTICATION_LOG', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const activateAccount = (userid) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'activateAccount'
    return async dispatch => {
        dispatch({ type: 'LOAD_CUSTOMER', payload: '' })
        const data = await ApiHandler(CUSTOMER_URL + CUSTOMER_ACTIVATE_ACCOUNT_API + "?userid=" + userid, {
            method: "GET",
            functionName,
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'ACTIVATE_USER_ACCOUNT', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const deactivateAccount = (userid) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'deactivateAccount'
    return async dispatch => {
        dispatch({ type: 'LOAD_CUSTOMER', payload: '' })
        const data = await ApiHandler(CUSTOMER_URL + CUSTOMER_DEACTIVATE_ACCOUNT_API + "?userid=" + userid, {
            method: "GET",
            functionName,
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        });
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'DEACTIVATE_USER_ACCOUNT', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const fetchRfidList = () => {
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    const operator = role === 'Admin' ? 'All' : organisation;
    const bearer = 'basic ' + token
    const functionName = 'fetchRfidList'
    return async dispatch => {
        dispatch({ type: 'LOAD_CUSTOMER', payload: '' })
        const data = await ApiHandler(CUSTOMER_URL + FETCH_RFID_LIST_API, {
            method: "GET",
            functionName,
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'FETCH_ALL_RFID_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const createRfidbindingApi = (data) => {
    const { username, rfidNo } = data
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    const functionName = 'createRfidbindingApi'
    return async dispatch => {
        dispatch({ type: 'LOAD_CUSTOMER', payload: '' })
        const data = await ApiHandler(CUSTOMER_URL + CREATE_RFID_BINDING_WITH_CUSTOMER_API, {
            functionName,
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "userid": username,
                "rfid": rfidNo,
                // "currency": currency,
                "amount": 0,
            })
        })
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'SAVE_RFID_BINDING_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const createGuestUserApi = (data) => {
    const { email, firstName, lastName, mobile, confirmPassword } = data
    // console.log("data from action", data)
    const { token } = JSON.parse(localStorage.getItem('user'));
    // const bearer = 'basic ' + token
    const functionName = 'createGuestUserApi'
    return async dispatch => {
        dispatch({ type: 'LOAD_CUSTOMER', payload: '' })
        const data = await ApiHandler(CUSTOMER_URL + GUEST_USER_SIGNUP_API, {
            functionName,
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "firstname": firstName.trim(),
                "lastname": lastName.trim(),
                "password": confirmPassword,
                "email": email,
                "userid": email,
                "mobile": Number(mobile),
                "agree": true,
                "accounttype": "individual",
                "corporate_code": '',
                "devicetoken": '',
                "communication": true
            })
        });
        if (data) {
            // console.log("dwadada", data)
            dispatch({ type: 'SAVE_CUSTOMER_ACCOUNT', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}