import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { activateGroup, deactivateGroup, getAllCustomerGroupingList } from '../../../../store/actions/customerActions';
import Table from '../../reacttable/table';
import { FaEdit, FaEye } from 'react-icons/fa';
import { TiTick } from 'react-icons/ti';
import { AiOutlineCloseCircle } from 'react-icons/ai';


function CustomerGroupingList(props) {
    useEffect(() => {
        props.all();
    }, [])

    const data = props.getAll && props.getAll.data
    // console.log(data)

    const activateGroup = (groupName) => {
        // console.log(groupName)
        props.activateAPI(groupName)
    }
    const deactivateGroup = (groupName) => {
        // console.log(groupName)
        props.deactivateAPI(groupName)
    }

    if (props.activate.statuscode === 200) {
        window.location.reload()
    }
    if (props.deactivate.statuscode === 200) {
        window.location.reload()
    }
    const columns = useMemo(() => [
        {
            Header: 'Group Name',
            accessor: 'group_name'
        },
        {
            Header: 'Tariff Name',
            accessor: 'tariff'
        },
        {
            Header: 'Is Subscription Added?',
            id: 'is_valid_subscription',
            accessor: d => {
                return d.is_valid_subscription ? <span className="text-success">Yes</span> : <span className="text-danger">No</span>
            }
        },
        {
            Header: 'Status',
            id: "status",
            accessor: d => {
                return d.status ? <span className="text-success">Active</span> : <span className="text-danger">Inactive</span>
            }
        },
        {
            Header: 'Actions',
            accessor: 'id',
            Cell: (cell) => {
                const { row } = cell
                const { original } = row
                const groupName = original.group_name
                const id = original.id
                // console.log(row)
                // console.log(original)
                return (
                    <div>
                        <span>
                            <a href={`/dashboard/accountcard/viewCustomerGroup/${groupName}`}
                                rel="tooltip"
                                title="View"
                            >
                                <FaEye />
                            </a></span>&nbsp;&nbsp;
                        <span>
                            <a href={`/dashboard/accountcard/updateCustomerGroup/${groupName}`}
                                rel="tooltip"
                                title="Edit"
                            >
                                <FaEdit />
                            </a>
                        </span>
                        &nbsp;&nbsp;
                        <span>
                            <TiTick
                                style={{ cursor: 'pointer' }}
                                onClick={() => activateGroup(groupName)}
                                title="Activate"
                            />
                        </span>
                        &nbsp;&nbsp;
                        <span>
                            <AiOutlineCloseCircle
                                style={{ cursor: 'pointer' }}
                                onClick={() => deactivateGroup(groupName)}
                                title="De-Activate"
                            />
                        </span>
                    </div>
                );
            },
        }
    ], [data])
    return (
        <>
            {props.loading ? <h3 style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "250px",
            }}>Loading data...</h3> :
                data && data.length > 0 ?
                    (<div> <Table data={data} columns={columns}></Table></div>)
                    :
                    (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}><h3 className="loader">No data found</h3></div>)
            }
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        loading: state.customer.isLoading,
        getAll: state.customer.fetchAllCustomerGroups,
        activate: state.customer.activateCustomerGroup,
        deactivate: state.customer.deactivateCustomerGroup,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        all: () => dispatch(getAllCustomerGroupingList()),
        activateAPI: (group_name) => dispatch(activateGroup(group_name)),
        deactivateAPI: (group_name) => dispatch(deactivateGroup(group_name)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CustomerGroupingList)