import React, { useEffect, useState } from 'react'
import { Row, Col, Container, Card } from "react-bootstrap";
import PureBreadcrumbs from "../../breadcrums";
import FleetCalendar from './FleetCalendar';
import moment from 'moment';
import { fetchEvent, fetchSchedule, getAllFleetName } from '../../../../store/actions/fleetAction';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RRule } from 'rrule';

function FleetSchedule(props) {
    const [events, setEvents] = useState([]);
    const [fleetDropDown, SetFleetDropDown] = useState([]);
    const history = useHistory();
    useEffect(() => {
        props.fetchScheduleApi()
        props.getAllFleetNameApi()
        props.fetchEventApi()
    }, [])

    // const data = props.fetchEventRes && props.fetchEventRes?.data;
    // console.log(props.fetchEventRes, "data");

    useEffect(() => {
        SetFleetDropDown(props.getAllFleetNameRes?.data?.filter((item) => item.status === "Active"))
    }, [props.getAllFleetNameRes])

    const getWeekday = (day) => {
        switch (day.toLowerCase()) {
            case 'monday':
                return RRule.MO;
            case 'tuesday':
                return RRule.TU;
            case 'wednesday':
                return RRule.WE;
            case 'thursday':
                return RRule.TH;
            case 'friday':
                return RRule.FR;
            case 'saturday':
                return RRule.SA;
            case 'sunday':
                return RRule.SU;
            case 'all':
                return [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR, RRule.SA, RRule.SU];
            case 'we':
                return [RRule.SA, RRule.SU];
            case 'wd':
                return [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR];
            default:
                return null;
        }
    };

    const getWeekdayMorning = (day) => {
        console.log(day, "day");
        switch (day.toLowerCase()) {
            case 'monday':
                return RRule.SU;
            case 'tuesday':
                return RRule.MO;
            case 'wednesday':
                return RRule.TU;
            case 'thursday':
                return RRule.WE;
            case 'friday':
                return RRule.TH;
            case 'saturday':
                return RRule.FR;
            case 'sunday':
                return RRule.SA;
            case 'all':
                return [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR, RRule.SA, RRule.SU];
            case 'we':
                return [RRule.FR, RRule.SA];
            case 'wd':
                return [RRule.SU, RRule.MO, RRule.TU, RRule.WE, RRule.TH];
            default:
                return null;
        }
    };

    const getEvents = (day, starttime) => {
        const startMoment = moment(starttime);
        let weekdayRule;

        if (startMoment.hour() <= 5) {
            weekdayRule = getWeekdayMorning(day);
        } else {
            weekdayRule = getWeekday(day);
        }

        if (!weekdayRule) {
            console.log(`Invalid weekday: ${day}`, "day error");
        }

        return weekdayRule;
    };

    const generateWeeklyOccurrencesByWeekday = (weekday, starttime, endtime) => {
        const events = [];
        const weekdayRule = getEvents(weekday, starttime);

        if (!weekdayRule) {
            console.log(`Invalid weekday: ${weekdayRule}`, "weekdayRule error");
        }

        const startDate = moment().startOf('month').toDate();
        const endDate = moment().endOf('month').toDate();

        const rule = new RRule({
            freq: RRule.WEEKLY,
            interval: 1,
            byweekday: weekdayRule,
            dtstart: new Date(starttime),
            until: endDate,
        });

        rule.all().forEach(date => {
            events.push({
                start: new Date(date),
                end: moment(date).add(moment(endtime).diff(moment(starttime), 'milliseconds')).toDate(),
            });
        });

        return events;
    };
    useEffect(() => {
        const data = props.fetchEventRes?.data;
        const eventList = data?.flatMap((item) => {
            if (item.calendar_type === "single") {
                return {
                    start: moment(item.starttime).toDate(),
                    end: moment(item.endtime).toDate(),
                    title: `${item.vin} - ${item.station_name}`
                }
            }
            if (item.calendar_type === "recurring") {
                const occurrences = generateWeeklyOccurrencesByWeekday(item.day, item.starttime, item.endtime);
                return occurrences.map(occurrence => ({
                    start: occurrence.start,
                    end: occurrence.end,
                    title: `${item.vin} - ${item.station_name}`
                }));
            }
        });
        setEvents(eventList);
        // console.log("event:", event);
    }, [props.fetchEventRes])


    const handleFleet = (e) => {
        const fleet = e.target.value;
        props.fetchScheduleApi(fleet)
        props.fetchEventApi(fleet)
    }

    const handleClick = () => {
        history.push("/dashboard/all_schedule_list")
    }
    return (
        <section>
            <Container fluid>
                <PureBreadcrumbs />
                <br />
                <Row className="mt-3" style={{ display: "flex", justifyContent: "center", gap: "100px" }} >
                    <Col xs={12} md={3} style={{ cursor: "pointer" }} onClick={handleClick}>
                        <Card className="customercard">
                            <div style={{ textAlign: "left" }}>
                                <Card.Text style={{ fontSize: "14px" }}>
                                    ALL
                                </Card.Text>
                                <Card.Title>
                                    <b>
                                        {props.fetchScheduleRes?.data?.length}
                                    </b>
                                </Card.Title>
                                <span
                                    style={{
                                        float: "right",
                                        marginTop: "-60px",
                                        marginRight: "-8px",
                                    }}
                                >
                                    <div style={{ width: "20%" }}>
                                        <img
                                            src={process.env.PUBLIC_URL + "/images/fleet/car1blue.svg"}
                                            className="fleet-ico1"
                                            alt=""
                                        />
                                    </div>
                                </span>
                            </div>
                        </Card>
                        <br />
                    </Col>
                    <Col xs={12} md={3}>
                        <Card className="customercard">
                            <div style={{ textAlign: "left" }}>
                                <Card.Text style={{ fontSize: "14px" }}>
                                    Active
                                </Card.Text>
                                <Card.Title>
                                    <b>
                                        {props.fetchScheduleRes?.data?.filter((item) => item.status === "Active").length}
                                    </b>
                                </Card.Title>
                                <span
                                    style={{
                                        float: "right",
                                        marginTop: "-60px",
                                        marginRight: "-8px",
                                    }}
                                >
                                    <div style={{ width: "20%" }}>
                                        <img
                                            src={process.env.PUBLIC_URL + "/images/fleet/car1blue.svg"}
                                            className="fleet-ico1"
                                            alt=""
                                        />
                                    </div>
                                </span>
                            </div>
                        </Card>
                        <br />
                    </Col>
                    <Col xs={12} md={3}>
                        <Card className="customercard">
                            <div style={{ textAlign: "left" }}>
                                <Card.Text style={{ fontSize: "14px" }}>
                                    Inactive
                                </Card.Text>
                                <Card.Title>
                                    {props.fetchScheduleRes?.data?.filter((item) => item.status === "Inactive").length}
                                </Card.Title>
                                <span
                                    style={{
                                        float: "right",
                                        marginTop: "-60px",
                                        marginRight: "-8px",
                                    }}
                                >
                                    <div style={{ width: "50%" }}>
                                        <img
                                            src={process.env.PUBLIC_URL + "/images/fleet/car2green.svg"}
                                            className="fleet-ico1"
                                            alt=""
                                        />
                                    </div>
                                </span>
                            </div>
                        </Card>
                        <br />
                    </Col>
                </Row>
                <br />
                <Card className='tariffcard'>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                            <div className="table-header">Scheduled</div>
                        </div>
                        {/* <Row className="pb-2" style={{ display: "flex", justifyContent: "flex-end" }}> */}
                        <div style={{ marginRight: "35px", display: "flex" }}>
                            <a
                                href={`/dashboard/create_schedule`}
                                className="btn lgn-btn"
                                type="button"
                                style={{ display: "flex", justifyContent: "center", alignItems: "center", textDecoration: 'none', marginRight: "10px" }}
                            >
                                <p className="" style={{ fontSize: "17px", margin: 0 }}>Create Scheduled</p>
                            </a>
                            <select style={{ width: "170px", border: "1px solid #9f9f9f", borderRadius: "5px" }} onChange={handleFleet}>
                                <option value="All">All</option>
                                {fleetDropDown?.map((item, index) => (
                                    <option key={index} value={item.ownername}>{item.ownername}</option>
                                ))
                                }
                            </select>
                            {/* </Row> */}
                        </div>
                    </div>
                    <br />
                    <div>
                        <FleetCalendar events={events} />
                    </div>
                </Card>
            </Container>
        </section>
    )
}
const mapStateToProps = (state) => {
    return {
        loading: state.fleet.isLoading,
        getAllFleetNameRes: state.fleet.all_fleet_name,
        fetchScheduleRes: state.fleet.fetch_schedule,
        fetchEventRes: state.fleet.fetch_event
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getAllFleetNameApi: () => dispatch(getAllFleetName()),
        fetchScheduleApi: (fleet) => dispatch(fetchSchedule(fleet)),
        fetchEventApi: (fleet) => dispatch(fetchEvent(fleet))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(FleetSchedule);