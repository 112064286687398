import React, { useMemo } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Table from "../FleetTable";

function FleetVehicleStatus(props) {
  const data = [
    {
      vin: "ABC00001",
      driver_name: "John Doe",
      status: "Charging",
      soc: 50,
      total_co2: 10,
    },
    {
      vin: "ABC00002",
      driver_name: "Jane Smith",
      status: "Finishing",
      soc: 80,
      total_co2: 12,
    },
    {
      vin: "ABC00003",
      driver_name: "Mike Johnson",
      status: "Charging",
      soc: 30,
      total_co2: 8,
    },
    {
      vin: "ABC00004",
      driver_name: "Emily Davis",
      status: "Charging",
      soc: 60,
      total_co2: 11,
    },
    {
      vin: "ABC00005",
      driver_name: "Chris Brown",
      status: "Finishing",
      soc: 90,
      total_co2: 13,
    },
    {
      vin: "ABC00006",
      driver_name: "Olivia Wilson",
      status: "Charging",
      soc: 40,
      total_co2: 9,
    },
    {
      vin: "ABC00007",
      driver_name: "Sophia Martinez",
      status: "Charging",
      soc: 55,
      total_co2: 10.5,
    },
    {
      vin: "ABC00008",
      driver_name: "Liam Miller",
      status: "Finishing",
      soc: 75,
      total_co2: 14,
    },
    {
      vin: "ABC00009",
      driver_name: "Noah Garcia",
      status: "Charging",
      soc: 35,
      total_co2: 7.5,
    },
    {
      vin: "ABC00010",
      driver_name: "Mason Rodriguez",
      status: "Finishing",
      soc: 65,
      total_co2: 12.5,
    },
  ];

  const columns = useMemo(
    () => [
      {
        Header: "VIN",
        accessor: "vin",
      },
      {
        Header: "Driver Name",
        accessor: "driver_name",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "SOC",
        accessor: "soc",
      },
      {
        Header: "Total Co2 Reduce",
        accessor: "total_co2",
      },
    ],
    []
  );
  return (
    <Row className="mt-4">
      <Col>
        <Card className="customercard">
          <div>
            {props.loading ? (
              <h3
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "250px",
                }}
              >
                Loading data...
              </h3>
            ) : data && data.length > 0 ? (
              <div>
                {" "}
                <Table data={data} columns={columns}></Table>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h3 className="loader">No data found</h3>
              </div>
            )}
          </div>
        </Card>
      </Col>
    </Row>
  );
}

export default FleetVehicleStatus;
