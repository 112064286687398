import React, { useEffect } from "react";
import { Row, Col, Card, Container } from "react-bootstrap";
import "react-dates/initialize";
import { useMemo, useState } from "react";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";
import "../../report/report.css";
import DatePicker from "react-datepicker";
import PureBreadcrumbs from "../../breadcrums";
import { useTranslation } from "react-i18next";
import { fetchAllReport } from "../../../../store/actions/advertisementAction";
import AdminTable from "../../report/adminReport/adminreportTable";
import { allEvseAction } from "../../../../store/actions/evseAction";


function AdvertiseAllReport(props) {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(new Date(Date.now() - 3600 * 1000 * 720));
  const [endDate, setEndDate] = useState(new Date(Date.now() + 3600 * 1000 * 24));
  const { role, organisation } = JSON.parse(localStorage.getItem('user'));
  const [name, setName] = useState('All')
  const firstIndex = props.list && props.list.data && props.list.data.length > 0 ? props.list.data[0]?.name : null;


  useEffect(() => {
    if (role === "Admin") {
      props.advertiseReportApi(name, startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10))
    } else if ((role === "Network Operator" || role === "Enterprise") && props.list?.data?.length > 0) {
      props.advertiseReportApi(firstIndex, startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10))
    }
    props.all();
  }, [role, startDate, endDate, firstIndex])


  const loadNewData = () => {
    props.advertiseReportApi(name, startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10))
  }

  const handleChange = (e) => {
    const data = e.target.value;
    setName(data);
  };

  const reportData = props.advertise_report && props.advertise_report.data;
  // console.log(reportData)

  const columns = useMemo(
    () => [
      { Header: "Station Name", accessor: "station_name" },
      { Header: "Click Count", accessor: "click_count" },
      { Header: "Impression Count", accessor: "impression_count" },
      { Header: "Click-Through-Rate", accessor: "ctr" },
    ],
    []
  );
  const headers = [

    { label: "Station Name", key: "station_name" },
    { label: "Click Count", key: "click_count" },
    { label: "Impression Count", key: "impression_count" },
    { label: "Click-Through-Rate", key: "ctr" },
  ];
  return (
    <Container fluid className="pb-4">
      <PureBreadcrumbs />
      <Row className="mt-4">
        <Col>
          <Card className="customercard mt-4">
            <Card.Header>
              <Row>
                <Col md={4} sm={12}>
                  <div className="mb-3">
                    {t(role)} {t("Advertisement Report List")}
                  </div>
                </Col>
                <Col md={8} sm={12} className="d-flex justify-content-end align-items-center">
                  <div className="d-flex align-items-center">
                    <div className="mr-2">
                      <select
                        className="form-select"
                        style={{ borderRadius: "5px", borderColor: "#acaaaa" }}
                        name="advertisementName"
                        id="floatingSelectGridAdvertisementFor"
                        onChange={handleChange}
                      >
                        {role === 'Admin' && (
                          <option value="All">{t("All")}</option>
                        )}
                        {props.list && props.list.data?.filter((item) => item.status !== 'Discovery' && item.publish !== false)?.map((v) => (
                          <option key={v.stationid} value={v.name}>
                            {v.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    {/* Date Range Picker */}
                    <div className="d-flex align-items-center mr-2">
                      {/* Start Date Picker */}
                      <DatePicker
                        className="gray-border form-control mr-1"
                        selected={startDate}
                        onChange={setStartDate}
                        selectsStart
                        startDate={startDate}
                        dateFormat="dd/MM/yyyy"
                        maxDate={new Date()}
                      />
                      <span className="ml-1 mr-1">{t("To")}</span>
                      {/* End Date Picker */}
                      <DatePicker
                        className="gray-border form-control"
                        selected={endDate}
                        onChange={setEndDate}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        showDisabledMonthNavigation
                        dateFormat="dd/MM/yyyy"
                        maxDate={new Date()}
                      />
                    </div>

                    {/* Go Button */}
                    <div className="csvbutton ml-3">
                      <button className="btn btn-sm" style={{ borderRadius: "12px" }} onClick={loadNewData}>
                        <span
                          style={{
                            fontSize: "16px",
                            color: "#676767",
                            fontWeight: "600",
                          }}
                        >
                          GO
                        </span>
                      </button>
                    </div>
                  </div>
                </Col>

                {/* CSV Export Button */}
                <Col md={12} sm={12} className="d-flex justify-content-end">
                  <div className="mt-2">
                    {props.advertise_report && props.advertise_report.data != null ? (
                      <CSVLink
                        data={reportData}
                        headers={headers}
                        filename={`${name}_${startDate.toISOString().substr(0, 10)}_${endDate.toISOString().substr(0, 10)}Advertisement Report.csv`}
                        target="_blank"
                      >
                        <i className="fas fa-2x fa-file-csv"></i>
                      </CSVLink>
                    ) : (
                      <i className="fas fa-2x fa-file-csv"></i>
                    )}
                  </div>
                </Col>
              </Row>

            </Card.Header>
            <div className="evse">
              {props.loading ? <h3 style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "450px",
              }}>Loading data...</h3> :
                props.advertise_report && props.advertise_report.data != null ? (
                  <div>
                    <AdminTable data={reportData} columns={columns}></AdminTable>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "450px",
                    }}
                  >
                    <h3>No Data Found</h3>
                  </div>
                )}
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.report.isLoading,
    advertise_report: state.advertise.fetch_all_advertise_report,
    list: state.evse.allevse,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    advertiseReportApi: (name, startDate, endDate) => dispatch(fetchAllReport(name, startDate, endDate)),
    all: () => dispatch(allEvseAction()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AdvertiseAllReport);
