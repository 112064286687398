import React from 'react';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useTranslation } from 'react-i18next';

const DownloadBindRfidDetails = (props) => {
    const { t } = useTranslation();

    const generatePDF = () => {
        // console.log("props", props);
        const unit = "pt";
        const size = "A4";
        const orientation = "landscape";
        const marginLeft = 40;
        const marginTop = 40;
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(12);

        // Define headers with formatted names
        const headers = [
            "Rfid", "User Name", "Currency", "Payment Status",
            "Amount Based", "Amount", "Free Charging", "Kwh Based", "Kwh", "Kwh Amount",
            "Time Based", "Time", "Time Amount", "Rfid Type", "Rfid Duration", "Station", "Offline Payment"
        ];

        // Map data from props to match headers
        const data = props.data?.map(obj => {
            return [
                obj.rfid,
                obj.userid,
                obj.currency,
                obj.payment_status,
                obj.amount_based ? "Yes" : "No",
                obj.amount,
                obj.free_charging ? "Yes" : "No",
                obj.kwh_based ? "Yes" : "No",
                obj.kwh,
                obj.kwh_amount,
                obj.time_based ? "Yes" : "No",
                obj.time,
                obj.time_amount,
                obj.type,
                obj.rfid_duration,
                obj.station === 'All'
                    ? 'All'
                    : Array.isArray(obj.rfid_stations) && obj.rfid_stations.length > 0
                        ? obj.rfid_stations.join(', ')
                        : '',
                obj.offline_payment ? "Yes" : "No"
            ];
        });


        // Configure column styles to set fixed width for the "Station" column
        const columnStyles = {
            15: { cellWidth: 100 }, // Setting the width of the "Station" column (index 15) to 100 pt
        };

        let content = {
            startY: 50,
            head: [headers],
            body: data,
            columnStyles: columnStyles, // Add column styles to the content
        };

        doc.text("RFID Report", marginLeft, 40);
        doc.autoTable(content);
        doc.save("Binded_customer_rfid_list.pdf");
    };

    const handleButtonClick = () => {
        generatePDF();
    };

    return (
        <div>
            <button className='pdfbutton' onClick={handleButtonClick} type="button">{t("Binded RFID List")}</button>
        </div>
    );
};

export default DownloadBindRfidDetails;
