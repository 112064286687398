import React, { useEffect, useMemo, useState } from 'react';
import { connect } from "react-redux";
import { Card, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import PureBreadcrumbs from "../breadcrums";
import StationLoadList from "./loadTable";
import { fetchChargerListForLoadAction, fetchChargerLiveLoadAction, fetchChargerLoadListApi, fetchCurrentLoadGraphApi, fetchSingleDynamicStationApi, fetchStationLoadTypeLisForAction, saveLoadCapacityApi } from '../../../store/actions/loadAction';
import { useTranslation } from 'react-i18next';
import Chart from 'react-apexcharts';
import Popup from "reactjs-popup";
import { ImCross } from "react-icons/im";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import { allEvseAction } from '../../../store/actions/evseAction';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import ReactSelect from 'react-select';
import DarkThemeStyles from '../resuableComponent/reactSelectSingleValueStyle';

function LoadManagement(props) {
      const { t } = useTranslation();
      const [stationName, setStationName] = useState("");
      const [isToastVisible, setIsToastVisible] = useState(false);
      const firstRecord = props.evseList && props.evseList.data;
      const { id = null, name = null } = (firstRecord?.[0] && firstRecord?.[0]) || {};

      const loadManagement = Yup.object().shape({
            loadType: Yup.string().required('Load Type is required'),
            loadConsumption: Yup.number().required('Load consumption is required').typeError("Load consumption is required"),
      });

      const { register, handleSubmit, setValue, formState: { errors } } = useForm({ resolver: yupResolver(loadManagement), mode: "all" });

      const handleChange = (e) => {
            const station_name = e.value;
            if (station_name) {
                  setStationName(station_name);
            }
      };

      useEffect(() => {
            if (name !== '' && name !== null) {
                  setStationName(name);
            }
      }, [name]);

      useEffect(() => {
            props.fetchLoadTypeList();
            props.allEvseStation();
      }, []);

      useEffect(() => {
            if (stationName !== '' && stationName !== null) {
                  props.fetchSingleStationLoadApi(stationName);
                  props.fetchLoadChargerListApi(stationName);
                  props.fetchCurrentLoadGraph(stationName);
            }

      }, [stationName]);

      const graphLoadData = props.graphCurrentLoad && props.graphCurrentLoad.data;
      // console.log("graphLoadData", graphLoadData)

      useEffect(() => {
            const singleLoadData = props.fetchSingleStationLoadRes && props.fetchSingleStationLoadRes.data;
            if (props.fetchSingleStationLoadRes.statuscode === 200) {
                  const { define_load, load_type } = singleLoadData;
                  setValue("loadType", load_type);
                  setValue("loadConsumption", define_load);
            }
      }, [props.fetchSingleStationLoadRes, setValue]);

      const onSubmit = async data => {
            const newData = {
                  ...data,
                  station_name: stationName
            };
            props.saveDynamicLoad(newData);
      };

      useEffect(() => {
            if (props.saveDynamicLoadRes.statuscode === 200) {
                  setIsToastVisible(true);
                  toast.success('Station load is successfully saved', {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce,
                        onClose: () => {
                              setIsToastVisible(false);
                              window.location.reload();
                        },
                  });
            } else if (props.saveDynamicLoadRes.statuscode === 405) {
                  setIsToastVisible(true);
                  toast.error(`❌ ${props.saveDynamicLoadRes.error}`, {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce,
                        onClose: () => {
                              setIsToastVisible(false);
                              window.location.reload();
                        },
                  });
            }
      }, [props.saveDynamicLoadRes]);


      const isDataAvailable = graphLoadData && graphLoadData?.name && graphLoadData.define_load !== undefined && graphLoadData.load_percent !== undefined && graphLoadData.current_load !== undefined;
      // console.log("isDataAvailable", isDataAvailable)

      // Define default chart options
      const chartOptions = useMemo(() => ({
            chart: {
                  id: 'load-chart',
                  type: 'line',  // Change to 'line' for a line chart
                  width: '100%',
                  height: 350,
                  toolbar: { show: false },
                  animations: {
                        enabled: true,
                        easing: 'linear',
                        dynamicAnimation: {
                              speed: 1000
                        }
                  },
            },
            xaxis: {
                  categories: ['Define Load', 'Load Percent', 'Current Load'],
            },
            yaxis: {
                  title: {
                        text: 'Load Values',
                  },
            },
            stroke: {
                  curve: 'smooth',  // Smooth curve for the wave effect
            },
            markers: {
                  size: 5,
                  colors: ['#FFA41B'],
                  strokeColors: '#fff',
                  strokeWidth: 2,
                  hover: {
                        size: 7,
                  },
            },
            colors: ['#FF5733'],
            tooltip: {
                  shared: true,
                  intersect: false,
            },
            legend: {
                  show: false,
            },
            states: {
                  hover: {
                        filter: {
                              type: 'none',
                        },
                  },
            },
      }), []);

      const series = useMemo(() => [{
            name: graphLoadData?.name || 'No Name',
            data: isDataAvailable ? [
                  graphLoadData.define_load,
                  graphLoadData.load_percent,
                  graphLoadData.current_load,
            ] : [0, 0, 0],
      }], [graphLoadData, isDataAvailable]);


      const options = props.evseList && props.evseList.data?.filter((item) => item.status !== 'Discovery' && item.publish !== false)?.map(item => ({
            value: item.name,
            label: item.name
      }));


      return (
            <Container fluid className='pb-4'>
                  <div className="row">
                        <div className="col-lg-6 col-sm-12">
                              <PureBreadcrumbs />
                        </div>
                  </div>
                  <Row className="d-flex justify-content-between mt-4">
                        <Col lg={4} md={6} className=''>
                              <label>{("Select station Name")}</label>
                              <ReactSelect
                                    options={options ? options : []}
                                    // className={`${errors.currency ? 'is-invalid' : ''}`}
                                    name="stationName"
                                    id="floatingInputGridStationName"
                                    placeholder={stationName}
                                    styles={DarkThemeStyles}
                                    menuPortalTarget={document.body}
                                    onChange={handleChange}
                              />
                        </Col>
                        <Col lg={6} md={6} className='pt-md-4 d-flex justify-content-end align-items-center align-items-md-center'>
                              <span className='mr-3'>
                                    Last Updated : {new Date().toLocaleString() + ''}
                                    <a href='##' className="refresh ml-2" style={{ fontSize: "16px", color: "var(--button-color)", fontWeight: "bold", cursor: "pointer" }}><img style={{ height: "20px" }} src={process.env.PUBLIC_URL + '/images/insight/refresh.svg'} alt="" /></a>
                              </span>
                        </Col>
                  </Row>
                  <div className="d-flex justify-content-end mt-1">
                        <div>
                              <Popup
                                    trigger={
                                          <button
                                                className="lgn-btn btn mr-2"
                                                type="button"
                                                style={{ position: "relative", width: "auto", height: "auto" }}
                                          >
                                                {t("Settings")}
                                          </button>
                                    }
                                    modal
                                    contentStyle={{
                                          borderRadius: "10px",
                                          padding: "20px",
                                          width: window.innerWidth > 600 ? "50%" : "80%",
                                          margin: "auto",
                                          maxHeight: "80vh",
                                          overflow: "auto"
                                    }}
                                    className="popup-content"
                              >
                                    {(close) => (
                                          <>
                                                {isToastVisible && <div className="overlay" />}
                                                <ToastContainer
                                                      position="top-center"
                                                      autoClose={20000}
                                                      hideProgressBar={false}
                                                      newestOnTop={false}
                                                      closeOnClick
                                                      rtl={false}
                                                      pauseOnFocusLoss
                                                      draggable
                                                      pauseOnHover
                                                      theme="light"
                                                      style={{ top: "10%", width: "auto", zIndex: "9999" }}
                                                />
                                                <div style={{ textAlign: "right" }}>
                                                      <button className="close-btn" onClick={() => window.location.reload()}>
                                                            <span>
                                                                  <ImCross />
                                                            </span>
                                                      </button>
                                                </div>
                                                <div>
                                                      <h2 style={{ textAlign: "center" }}>{t("Settings")}</h2>
                                                      <Row className="add-roaming-station">
                                                            <Form className="p-3 mt-4" onSubmit={handleSubmit(onSubmit)}>
                                                                  <Row>
                                                                        <Col md={6} className="mb-3">
                                                                              <Form.Group controlId="floatingSelectGridusername">
                                                                                    <Form.Label>{t("Select Load Type")}</Form.Label>
                                                                                    <Form.Control
                                                                                          as="select"
                                                                                          className={errors.loadType ? 'is-invalid' : ''}
                                                                                          {...register("loadType")}
                                                                                    >
                                                                                          <option value={""}>{t("Select Load Type")}</option>
                                                                                          {props.loadTypeMaster && props.loadTypeMaster.data && props.loadTypeMaster.data.map((station) =>
                                                                                                <option key={station.type} value={station.type}>{station.type}</option>
                                                                                          )}
                                                                                    </Form.Control>
                                                                                    {errors.loadType && (
                                                                                          <div className="invalid-feedback">{errors.loadType.message}</div>
                                                                                    )}
                                                                              </Form.Group>
                                                                        </Col>

                                                                        <Col md={6} className="mb-3">
                                                                              <Form.Group controlId="floatingInputGridrfidNo">
                                                                                    <Form.Label>{t("Define Load")}</Form.Label>
                                                                                    <Form.Control
                                                                                          type="number"
                                                                                          placeholder={t("Define Load")}
                                                                                          className={errors.loadConsumption ? 'is-invalid' : ''}
                                                                                          {...register("loadConsumption")}
                                                                                    />
                                                                                    {errors.loadConsumption && (
                                                                                          <div className="invalid-feedback">{errors.loadConsumption.message}</div>
                                                                                    )}
                                                                              </Form.Group>
                                                                        </Col>
                                                                  </Row>

                                                                  <Row className="justify-content-center mt-2 mb-4">
                                                                        <Col lg={6} className="d-flex justify-content-center">
                                                                              <button
                                                                                    className="lgn-btn btn"
                                                                                    type="submit"
                                                                              >
                                                                                    <p>
                                                                                          {t("Save")}
                                                                                    </p>
                                                                              </button>
                                                                        </Col>
                                                                  </Row>
                                                            </Form>
                                                      </Row>
                                                </div>
                                          </>
                                    )}
                              </Popup>
                        </div>
                  </div>
                  <Row>
                        <Col lg={12}>
                              <Card className="mt-4 customercard">
                                    <div className="table-header">{t("Live station load")}</div>
                                    <div>
                                          {!isDataAvailable ? (
                                                <div style={{ textAlign: 'center', padding: '20px' }}>
                                                      <h3>No data available</h3>
                                                </div>
                                          ) : (
                                                <Chart
                                                      options={chartOptions}
                                                      series={series}
                                                      type="line"
                                                      height={350}
                                                />
                                          )}
                                    </div>
                              </Card>
                        </Col>
                  </Row>

                  <div className='row mt-4'>
                        <div className='col-12'>
                              <Card className='customercard'>
                                    <div className="table-header">{t("Charger Load List")}</div>
                                    {props.loadChargerListRes && props.loadChargerListRes.stations && <StationLoadList {...props}></StationLoadList>}
                              </Card>
                        </div>
                  </div>
            </Container>
      );
}

const mapStateToProps = (state) => {
      return {
            loading: state.load.isLoadLoading,
            evseList: state.evse.allevse,
            loadTypeMaster: state.load.loadTypeList,
            saveDynamicLoadRes: state.load.saveDynamicStationLoad,
            fetchSingleStationLoadRes: state.load.fetchSingleStationLoad,
            loadChargerListRes: state.load.fetchChargerLoadList,
            graphCurrentLoad: state.load.fetchCurrentLoadGraph,
      }
}

const mapDispatchToProps = dispatch => {
      return {
            allEvseStation: () => dispatch(allEvseAction()),
            saveDynamicLoad: (data) => dispatch(saveLoadCapacityApi(data)),
            fetchLoadTypeList: () => dispatch(fetchStationLoadTypeLisForAction()),
            fetchSingleStationLoadApi: (stationName) => dispatch(fetchSingleDynamicStationApi(stationName)),
            fetchLoadChargerListApi: (stationName) => dispatch(fetchChargerLoadListApi(stationName)),
            fetchCurrentLoadGraph: (stationName) => dispatch(fetchCurrentLoadGraphApi(stationName)),
      }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoadManagement);
