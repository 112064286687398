import React, { useEffect } from 'react'
import { GoogleMap, Marker, MarkerClusterer } from '@react-google-maps/api';
import { useState, useRef } from 'react';
import { connect } from 'react-redux';
import mapStyles from "./mapStyles"
// import RotatingLines from 'react-loader-spinner'
import Popup from 'reactjs-popup';
import { allFleetLocationAction, getVehicleLocationAction } from "../../../store/actions/fleetAction";

const containerStyle = {
  width: '100%',
  height: '100vh',
  // border-radius:'10px',
};
// const libraries = ["places"];

const options = {
  styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: true,
};



const ClusterIconStyle = [{
  url: "/images/m2.png",
  height: 56,
  width: 55
}]

// function createKey(location) {
//   return location.latitude + location.longitude
// }




// const MapControl = (props) => {
//   const map = useGoogleMap();
//   const ref = useRef();
//   useEffect(() => {
//     if (map && ref) {
//       map.controls[window.google.maps.ControlPosition[props.position]].push(
//         ref.current
//       );
//     }
//   }, [map, ref]);
//   return <div ref={ref}>{props.children}</div>;
// };

// const MapLoadSpingControl = (props) => {
//   const map = useGoogleMap();
//   const ref = useRef();
//   useEffect(() => {
//     if (map && ref) {
//       map.controls[window.google.maps.ControlPosition[props.position]].push(
//         ref.current
//       );
//     }
//   }, [map, ref]);
//   return <div ref={ref}>{props.children}</div>;
// };

// const center = { lat: 56.1304, lng: -106.3468 }
// const destin = { lat: 43.48837, lng: -79.6996283 }

const MapComponent = (props) => {
  const [selectedElement, setSelectedElement] = useState({});
  const [mposition, setMPosition] = useState({})
  // const [zoompoint, setZoomPoint] = useState(11.5)
  // const [showLoader, setShowLoader] = useState(false)
  const [open, setOpen] = useState(false);
  const [timer, setTimer] = useState(10);
  const [index, setIndex] = useState(1)
  // const [bus, setBus] = useState({})
  // const [busLet, setBusLet] = useState()
  // const [busLng, setBusLng] = useState()
  const id = useRef(null);
  // const [response, setResponse] = useState({
  //   response: null,
  //   travelMode: 'DRIVING',
  //   origin: '',
  //   destination: ''
  // })

  const closeModal = () => {
    setOpen(false)
    setSelectedElement({})
  };
  useEffect(() => {
    props.all()
    props.getVehicle(index)
    let myposition = {
      lat: 43.48837, lng: -79.6996283
    }
    setMPosition(myposition)

  }, [])

  useEffect(() => {
    id.current = window.setInterval(() => {
      setTimer((time) => time - 1)
    }, 1000)
    return () => clear();
  }, [])
  const clear = () => { }
  let b = []

  useEffect(() => {
    if (timer === 0) {
      props.getVehicle(index + 1)
      const v_index = props.vdata && props.vdata?.vehicles
      if (v_index && v_index.length > 0 && v_index[0]?.index !== null && v_index[0]?.Latitude !== null && v_index[0]?.Longitude !== null) {
        setIndex(v_index[0].index)
        // setBusLet(v_index[0].Latitude)
        // setBusLng(v_index[0].Longitude)
      }
      setTimer(180)
    }
  }, [timer, index])

  const showInMapClicked = () => {
    const lat = selectedElement.latitude
    const lng = selectedElement.longitude
    window.open("https://maps.google.com?q=" + lat + "," + lng + "&dir_action=navigate");
  };

  // console.log(props.list.data?.fuel_stations);
  return (
    <>
      {props.list !== '' && props.list && <GoogleMap
        id="map"
        className='map-main'
        mapContainerStyle={containerStyle}
        zoom={3}
        center={mposition}
        options={options}
      >


        {/* <MapLoadSpingControl position="TOP_CENTER">
          {showLoader ?
            <div className="text-center map-main" style={{ "top": "50%", "left": "50%" }}>
              <RotatingLines
                strokeColor="#f3c30c"
                strokeWidth="3"
                animationDuration="10"
              />
            </div>
            : ''}

        </MapLoadSpingControl> */}

        <MarkerClusterer styles={ClusterIconStyle}>
          {(clusterer) =>
            props.list !== null && props.list.data?.fuel_stations && props.list.data.fuel_stations?.map((location, idx) => (
              <Marker key={idx} clusterer={clusterer}
                position={{
                  lat: location.latitude,
                  lng: location.longitude
                }}
                onClick={() => {
                  setSelectedElement(location)
                  setOpen(true)
                }}

                icon={{
                  url: '/images/station.jpeg',
                  origin: new window.google.maps.Point(0, 0),
                  anchor: new window.google.maps.Point(15, 15),
                  scaledSize: new window.google.maps.Size(40, 40),
                }}
              />
            ))
          }
        </MarkerClusterer>
        {/* {bus && (
          <>
            <Marker

              position={{ lat: busLet, lng: busLng }}
              icon={{
                url: '/images/bus1.svg',
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(0, 0),
                scaledSize: new window.google.maps.Size(30, 30),
              }}
            />
          </>
        )} */}

        <Popup open={open} onClose={closeModal} overlayStyle={{ overflowY: "scroll" }}>
          {selectedElement &&
            <div className="container card">
              <div className='row mt-2'>
                <div class="col-8"> <div class="text-success  text-center fw-bold  fs-2 text-uppercase">{selectedElement.access_code} Network</div>   </div>
                <div class="col-2 d-flex align-items-center flex-column">
                  <button className='skip btn btn-outline-light' onClick={showInMapClicked}><i class="fas fa-1x fa-directions"></i></button>
                </div>
                <hr />
                <div class="col-12">
                  <h4 class="fw-bold text-center">{selectedElement.station_name}</h4>
                  <h6 class="text-center">{selectedElement.street_address}</h6>
                  <h6 class="text-center">{selectedElement.city}</h6>
                  <h6 class="text-center">{selectedElement.country}</h6>

                </div>
              </div>
            </div>

          }
        </Popup>
      </GoogleMap>
      }
    </>
  )
}



const mapStateToProps = (state) => {
  return {
    loading: state.fleet.isFleetLoading,
    list: state.fleet.allFleetLocation,
    vdata: state.fleet.vehicle
  }
}
const mapDispatchToProps = dispatch => {
  return {
    all: () => dispatch(allFleetLocationAction()),
    getVehicle: (index) => dispatch(getVehicleLocationAction(index))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(MapComponent)
