import React, { useEffect, useMemo } from "react";
import { Row, Col, Card, Container } from "react-bootstrap";
import "react-dates/initialize";
import "react-datepicker/dist/react-datepicker.css";
import Table from "../../reacttable/table";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { FaEdit, FaEye } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { AiOutlineCloseCircle } from "react-icons/ai";
import moment from "moment";
import { activateRedeemApi, deactivateRedeemApi, fetchAllRedeemApi } from "../../../../store/actions/loyaltyAction";
import PureBreadcrumbs from "../../breadcrums";

function ViewRedeemList(props) {
  const { t } = useTranslation();
  useEffect(() => {
    props.fetchAll();
  }, []);

  const data = props.fetchAllRes && props.fetchAllRes.data;
  // console.log(data);
  const total = data && data.length;
  const active = data && data.filter((item) => item.status === "Active").length;
  const draft = data && data.filter((item) => item.status === "Draft").length;
  const inActive = data && data.filter((item) => item.status === "Inactive").length;

  const handleActivateRedeem = (name) => {
    // console.log(name);
    props.activateRedeem(name);
  };

  if (props.activateRes.statuscode === 200) {
    window.location.reload();
  } else if (props.activateRes.statuscode === 405) {
    alert(props.activateRes.error);
    window.location.reload();
  }

  const handledeActivateRedeem = (name) => {
    // console.log(name);
    props.deactivateRedeem(name);
  };

  if (props.deactivateRes.statuscode === 200) {
    window.location.reload();
  } else if (props.deactivateRes.statuscode === 405) {
    alert(props.deactivateRes.error);
    window.location.reload();
  }

  const columns = useMemo(
    () => [
      {
        Header: "Redeem Name",
        id: "1",
        accessor: "redeem_name",
      },
      {
        Header: "Start Date",
        id: "startdate",
        accessor: (d) => {
          return moment(d.startdate).local().format("DD-MM-YYYY");
        },
      },
      {
        Header: "End Date",
        id: "enddate",
        accessor: (d) => {
          return moment(d.enddate).local().format("DD-MM-YYYY");
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: (status) => {
          // console.log(publish.value)
          return (
            <div>
              <span>
                {status.value === "Active" ? (
                  <span className="text-success">{t("Active")}</span>
                ) : status.value === "Inactive" ? (
                  <span className="text-danger">{t("Inactive")}</span>
                ) : (
                  <span className="text-warning">{t("Draft")}</span>
                )}
              </span>
            </div>
          );
        },
      },
      {
        Header: "Actions",
        accessor: "redeem_name",
        Cell: (redeem_name) => {
          return (
            <div>
              <span>
                <a href={`/dashboard/view_redeem/singleRedeemRule/${redeem_name.value}`} rel="tooltip" title="View">
                  <FaEye />
                </a>
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <span>
                <a href={`/dashboard/updateRedeemRule/${redeem_name.value}`} rel="tooltip" title="Edit">
                  <FaEdit />
                </a>
              </span>
              &nbsp;&nbsp; &nbsp;&nbsp;
              <span style={{ cursor: "pointer" }}>
                <TiTick onClick={() => handleActivateRedeem(redeem_name.value)} title="Activate" />
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <span style={{ cursor: "pointer" }}>
                <AiOutlineCloseCircle onClick={() => handledeActivateRedeem(redeem_name.value)} title="De-Activate" />
              </span>
            </div>
          );
        },
      },
    ],
    []
  );
  return (
    <>
      <Container fluid className="pb-4">
        <PureBreadcrumbs />
        <Row className="mt-2">
          <Col className="col-md-3 col-12">
            <Card className="customercard card-custom total-rules cards">
              <div className="diagonal-line"></div>
              <div style={{ textAlign: "left" }}>
                <Card.Text style={{ fontSize: "14px" }}>{t("Total Redeem Rules")}</Card.Text>
                <Card.Title>
                  <b>{total != undefined ? total : 0}</b>
                </Card.Title>
              </div>
            </Card>
            <br />
          </Col>
          <Col className="col-md-3 col-12">
            <Card className="customercard card-custom total-active-rules cards">
              <div className="diagonal-line"></div>
              <div style={{ textAlign: "left" }}>
                <Card.Text style={{ fontSize: "14px" }}>{t("Total Active Redeem Rules")}</Card.Text>
                <Card.Title>
                  <b>{active != undefined ? active : 0}</b>
                </Card.Title>
              </div>
            </Card>
            <br />
          </Col>
          <Col className="col-md-3 col-12">
            <Card className="customercard card-custom total-inactive cards">
              <div className="diagonal-line"></div>
              <div style={{ textAlign: "left" }}>
                <Card.Text style={{ fontSize: "14px" }}>{t("Total Inactive Redeem Rules")}</Card.Text>
                <Card.Title>
                  <b>{inActive != undefined ? inActive : 0}</b>
                </Card.Title>
              </div>
            </Card>
            <br />
          </Col>
          <Col className="col-md-3 col-12">
            <Card className="customercard card-custom total-draft cards">
              <div className="diagonal-line"></div>
              <div style={{ textAlign: "left" }}>
                <Card.Text style={{ fontSize: "14px" }}>{t("Total Draft Redeem Rules")}</Card.Text>
                <Card.Title>
                  <b>{draft != undefined ? draft : 0}</b>
                </Card.Title>
              </div>
            </Card>
            <br />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col lg={12}>
            <Card className="mt-2 customercard ">
              <div className="table-header">{t("View Redeem List")}</div>

              <Card.Body>
                <div className="evse">
                  {props.loading ? (
                    <h3
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "250px",
                      }}
                    >
                      Loading data...
                    </h3>
                  ) : props.fetchAllRes && props.fetchAllRes.data != null ? (
                    <div>
                      <Table data={data} columns={columns}></Table>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "250px",
                      }}
                    >
                      <h3>{t("No data found")}</h3>
                    </div>
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    loading: state.loyalty.isLoading,
    fetchAllRes: state.loyalty.fetchAllRedeem,
    activateRes: state.loyalty.activateRedeem,
    deactivateRes: state.loyalty.deactivateRedeem,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchAll: () => dispatch(fetchAllRedeemApi()),
    activateRedeem: (redeem_name) => dispatch(activateRedeemApi(redeem_name)),
    deactivateRedeem: (redeem_name) => dispatch(deactivateRedeemApi(redeem_name)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewRedeemList);
