import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import 'bootstrap/dist/css/bootstrap.min.css';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Card, Col, Container, Row, Button } from 'react-bootstrap';
import PureBreadcrumbs from "../breadcrums";
import { createManualReconcilationAction } from '../../../store/actions/stripeAction';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import moment from 'moment';



const CreateManualReconciliation = (props) => {
    const { t } = useTranslation();
    const [isToastVisible, setIsToastVisible] = useState(false);

    const schema = yup.object().shape({
        amount: yup.number().required('Amount is required').positive('Amount must be positive').typeError('Amount is required'),
        transferredTo: yup.string().required('Transferred To is required'),
        gst: yup.string(),
        cgst: yup.string(),
        igst: yup.string(),
        sgst: yup.string(),
        paymentMode: yup.string().required('Payment mode is required'),
        utr: yup.string().nullable(),
        transferDate: yup.date().required('Transfer date is required').typeError('Invalid date format'),
        clientName: yup.string(),
        clientAccountNo: yup.string(),
        description: yup.string(),
    });

    const { register, handleSubmit, formState: { errors, isValid } } = useForm({
        resolver: yupResolver(schema), mode: "all"
    });
    // console.log("errors", errors);
    const onSubmit = data => {
        props.saveReconcilation(data);
        // console.log(data);
    };

    useEffect(() => {
        if (props.saveReconcilationRes.statuscode === 200) {
            setIsToastVisible(true);
            toast.success('Manual Reconciliation is created successfully!', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    window.location.reload();
                },
            });
        } else if (props.saveReconcilationRes.statuscode === 405) {
            setIsToastVisible(true);
            toast.error(`❌ ${props.saveReconcilationRes.error}`, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    window.location.reload();
                },
            });
        }
    }, [props.saveReconcilationRes])

    return (
        <Container fluid>
            <PureBreadcrumbs />
            {isToastVisible && <div className="overlay" />}
            <ToastContainer
                position="top-center"
                autoClose={20}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ top: "10%", width: "auto", zIndex: "9999" }}
            />
            <Row className='mt-5'>
                <Col md={{ span: 12 }}>
                    <Card>
                        <Card.Body>
                            <h3>{t("Create Manual Reconciliation")}</h3>
                            <div className="mt-4">
                                <form onSubmit={handleSubmit(onSubmit)} className="row g-3">
                                    <div className="col-md-6 py-1">
                                        <label>{t("Transferred To")} <span className='mandatory-field'>*</span></label>
                                        <input
                                            type="text"
                                            placeholder={t("Enter Transferred To")}
                                            className={`gray-border form-control ${errors.transferredTo ? 'is-invalid' : ''}`}
                                            {...register('transferredTo')}
                                        />
                                        <div className="invalid-feedback">{errors.transferredTo?.message}</div>
                                    </div>

                                    <div className="col-md-6 py-1">
                                        <label>{t("Amount")} <span className='mandatory-field'>*</span></label>
                                        <input
                                            type="number"
                                            placeholder={t("Enter Amount")}
                                            className={`gray-border form-control ${errors.amount ? 'is-invalid' : ''}`}
                                            {...register('amount')}
                                        />
                                        <div className="invalid-feedback">{errors.amount?.message}</div>
                                    </div>

                                    <div className="col-md-3 py-1">
                                        <label>{t("GST")}</label>
                                        <input
                                            type="number"
                                            placeholder={t("Enter GST")}
                                            className={`gray-border form-control `}
                                            {...register('gst')}
                                        />
                                        {/* <div className="invalid-feedback">{errors.gst?.message}</div> */}
                                    </div>

                                    <div className="col-md-3 py-1">
                                        <label>{t("CGST")}</label>
                                        <input
                                            type="number"
                                            placeholder={t("Enter CGST")}
                                            className={`gray-border form-control `}
                                            {...register('cgst')}
                                        />
                                        {/* <div className="invalid-feedback">{errors.cgst?.message}</div> */}
                                    </div>

                                    <div className="col-md-3 py-1">
                                        <label>{t("IGST")}</label>
                                        <input
                                            type="number"
                                            placeholder={t("Enter IGST")}
                                            className={`gray-border form-control `}
                                            {...register('igst')}
                                        />
                                        {/* <div className="invalid-feedback">{errors.igst?.message}</div> */}
                                    </div>

                                    <div className="col-md-3 py-1">
                                        <label>{t("SGST")}</label>
                                        <input
                                            type="number"
                                            placeholder={t("Enter SGST")}
                                            className={`gray-border form-control `}
                                            {...register('sgst')}
                                        />
                                        {/* <div className="invalid-feedback">{errors.sgst?.message}</div> */}
                                    </div>

                                    <div className="col-md-4 py-1">
                                        <label>{t("Payment Mode")} <span className='mandatory-field'>*</span></label>
                                        <select
                                            type="text"
                                            placeholder={t("Enter Payment Mode")}
                                            className={`form-control ${errors.paymentMode ? 'is-invalid' : ''}`}
                                            {...register('paymentMode')}
                                        >
                                            <option value="">{t("Select payment mode")}</option>
                                            <option value="Debit Card">{t("Debit Card")}</option>
                                            <option value="Credit Card">{t("Credit Card")}</option>
                                            <option value="Digital/Mobile wallet">{t("Digital/Mobile wallet")}</option>
                                            <option value="UPI">{t("UPI")}</option>
                                            <option value="Net banking">{t("Net banking")}</option>
                                            <option value="POS">{t("POS")}</option>
                                        </select>
                                        <div className="invalid-feedback">{errors.paymentMode?.message}</div>
                                    </div>

                                    <div className="col-md-4 py-1">
                                        <label>{t("UTR")}</label>
                                        <input
                                            type="text"
                                            placeholder={t("Enter UTR")}
                                            className={`gray-border form-control `}
                                            {...register('utr')}
                                        />
                                        {/* <div className="invalid-feedback">{errors.utr?.message}</div> */}
                                    </div>

                                    <div className="col-md-4 py-1">
                                        <label>{t("Transfer Date")} <span className='mandatory-field'>*</span></label>
                                        <input
                                            type="date"
                                            className={`gray-border form-control ${errors.transferDate ? 'is-invalid' : ''}`}
                                            {...register('transferDate')}
                                        />
                                        <div className="invalid-feedback">{errors.transferDate?.message}</div>
                                    </div>

                                    <div className="col-md-6">
                                        <label>{t("Client Name")}</label>
                                        <input
                                            type="text"
                                            placeholder={t("Enter Client Name")}
                                            className={`gray-border form-control `}
                                            {...register('clientName')}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label>{t("Client Account No.")}</label>
                                        <input
                                            type="text"
                                            placeholder={t("Enter Payment Detail")}
                                            className={`gray-border form-control `}
                                            {...register('clientAccountNo')}
                                        />
                                    </div>

                                    <div className="col-md-12 py-1">
                                        <label>{t("Description")}</label>
                                        <textarea
                                            placeholder={t("Enter Description")}
                                            className={`form-control `}
                                            {...register('description')}
                                        ></textarea>
                                        {/* <div className="invalid-feedback">{errors.additionalDescription?.message}</div> */}
                                    </div>
                                </form>

                                <Row className='d-flex justify-content-end'>
                                    {props.isLoading ?
                                        <Col lg={12} className="d-flex justify-content-center justify-content-md-start" >
                                            <button className="lgn-btn btn mt-3" type="submit" disabled style={{ position: "relative" }}>
                                                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true">
                                                </span>
                                                {t("Saving...")}
                                            </button>
                                        </Col>
                                        :
                                        <Col lg={12} className="d-flex justify-content-center justify-content-md-end" >
                                            <button className="lgn-btn btn mt-3" type="submit" onClick={handleSubmit(onSubmit)} style={{ position: "relative" }}>{t("Submit")}</button>
                                        </Col>
                                    }
                                </Row>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

const mapStateToProps = (state) => {
    return {
        isLoading: state.stripe.isLoading,
        saveReconcilationRes: state.stripe.saveManualReconcilation,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        saveReconcilation: (data) => dispatch(createManualReconcilationAction(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateManualReconciliation);
