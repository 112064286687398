import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Container, Card, Row, Col } from "react-bootstrap";
import PureBreadcrumbs from "../../breadcrums";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { fetchRuleDropdownApi } from "../../../../store/actions/loyaltyAction";
import { saveSubscriptionApi } from "../../../../store/actions/walletActions";
import { Bounce, ToastContainer, toast } from "react-toastify";
import { HandleKeyPress } from "../../resuableComponent/handleKeyPress";

export const CreateSubscription = (props) => {
  const { t } = useTranslation();
  useEffect(() => {
    props.fetchDropdown();
  }, []);

  const subscriptionSchema = Yup.object().shape({
    subscriptionName: Yup.string().required("Subscription Name is required."),
    subscriptionType: Yup.string().required("Subscription Type is required."),
    subscriptionValue: Yup.number()
      .required("Subscription Value is required.")
      .typeError("Subscription Value is required")
      .integer("Subscription Value must be an integer."),
    subscriptionDuration: Yup.string().required(
      "Subscription Duration is required."
    ),
    rechargeAmount: Yup.number()
      .required("Recharge Amount is required.")
      .typeError("Recharge amount is required"),
    description: Yup.string(),
  });
  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(subscriptionSchema), mode: "all" });
  //   console.log("elements", elements);

  const onSubmit = async (data) => {
    props.saveSubApi(data);
    // console.log(data)
  };

  useEffect(() => {
    if (props.saveSubRes.statuscode === 200) {
      toast.success(`${props.saveSubRes.result}`, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        onClose: () => window.location.reload(),
      });
    } else if (props.saveSubRes.statuscode === 405) {
      toast.error(`❌ ${props.saveSubRes.error}`, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        onClose: () => window.location.reload(),
      });
    }
  }, [props.saveSubRes]);


  return (
    <Container fluid >
      <ToastContainer
        position="top-center"
        autoClose={500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{ top: "10%", width: "auto" }}
      />
      <PureBreadcrumbs />

      <Row className="mt-3 mb-5 pt-4">
        <Col>
          <Card className="tariffcard">
            <div className="table-header">{t("Create Subscription")}</div>
            <Card.Body>
              <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                <Row>
                  <Col lg={12}>
                    <div className="form-floating">
                      <label>
                        {t("Subscription Name")}
                        <span className="mandatory-field">*</span>
                      </label>
                      <input
                        type="text"
                        className={`form-control gray-border ${errors.subscriptionName ? "is-invalid" : ""
                          }`}
                        name={`subscriptionName`}
                        id="floatingInputsubscriptionName"
                        placeholder={t("Subscription Name")}
                        {...register(`subscriptionName`)}
                      />
                      <div className="invalid-feedback">
                        {errors.subscriptionName?.message}
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className="row g-2 mt-3 ">
                  <div className="col-md">
                    <div className="form-floating">
                      <label>
                        {t("Subscription Type")}
                        <span className="mandatory-field">*</span>
                      </label>
                      <select
                        className={`form-select form-control ${errors.subscriptionType ? "is-invalid" : ""
                          }`}
                        name={`subscriptionType`}
                        id="floatingSelectGridsubscriptionType"
                        {...register(`subscriptionType`)}
                      >
                        <option value={""}>
                          {t("Select Subscription Type")}
                        </option>
                        {props.dropDownRes &&
                          props.dropDownRes.ruletype?.map((v, k) => (
                            <option key={v.key} value={v.value}>
                              {v.key}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="invalid-feedback">
                      {errors.subscriptionType?.message}
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="form-floating">
                      <label htmlFor="floatingInputSubscriptionValue">
                        {t("Subscription Value")}
                        <span className="mandatory-field">*</span>
                      </label>
                      <input
                        type="number"
                        className={`form-control gray-border ${errors.subscriptionValue ? "is-invalid" : ""
                          }`}
                        name={`subscriptionValue`}
                        id="floatingInputSubscriptionValue"
                        placeholder={t("Subscription Value")}
                        {...register(`subscriptionValue`)}
                        onKeyPress={HandleKeyPress}
                      />
                      {watch(`subscriptionType`) === "" && (
                        <small className="form-text text-muted">
                          {t("")}
                        </small>
                      )}
                      {watch(`subscriptionType`) === "kwh" && (
                        <small className="form-text text-muted">
                          {t("Free Energy")}
                        </small>
                      )}
                      {watch(`subscriptionType`) === "time" && (
                        <small className="form-text text-muted">
                          {t("Free No. Of Hours")}
                        </small>
                      )}
                      {watch(`subscriptionType`) === "flat" && (
                        <small className="form-text text-muted">
                          {t("Discount in ( % ) / Charging")}
                        </small>
                      )}
                      <div className="invalid-feedback">
                        {errors.subscriptionValue?.message}
                      </div>
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="form-floating">
                      <label>
                        {t("Subscription Duration")}
                        <span className="mandatory-field">*</span>
                      </label>
                      <select
                        className={`form-select form-control ${errors.subscriptionDuration ? "is-invalid" : ""
                          }`}
                        name={`subscriptionDuration`}
                        id="floatingSelectGridsubscriptionDuration"
                        {...register(`subscriptionDuration`)}
                      >
                        <option value={""}>
                          {t("Select Subscription Duration")}
                        </option>
                        <option value={"weekly"}>{t("Weekly")}</option>
                        <option value={"monthly"}>{t("Monthly")}</option>
                        <option value={"yearly"}>{t("Yearly")}</option>
                      </select>
                    </div>
                    <div className="invalid-feedback">
                      {errors.subscriptionDuration?.message}
                    </div>
                  </div>
                </div>
                <Row className="mt-3 ">
                  <Col lg={12}>
                    <div className="form-floating">
                      <label>
                        {t("Recharge Amount")}
                        <span className="mandatory-field">*</span>
                      </label>
                      <input
                        type="number"
                        className={`form-control gray-border ${errors.rechargeAmount ? "is-invalid" : ""
                          }`}
                        name={`rechargeAmount`}
                        id="floatingInputrechargeAmount"
                        placeholder={t("Recharge Amount")}
                        {...register(`rechargeAmount`)}
                        onKeyPress={HandleKeyPress}
                      />
                      <div className="invalid-feedback">
                        {errors.rechargeAmount?.message}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <div className='col-12 mt-2'>
                    <div className="form-floating">
                      <label>{t("Description")}</label>
                      <textarea
                        className={`form-control`}
                        placeholder={t("Description...")}
                        aria-label={t("Description")}
                        name="description"
                        id="floatingTextarea"
                        style={{ "height": "120px" }}
                        {...register("description")}>
                      </textarea>
                    </div>
                  </div>
                </Row>
              </form>
            </Card.Body>
          </Card>
          <Card.Footer className="mb-4" >
            <Row className="d-md-flex justify-content-between">
              <Col lg={3} md={3} sm={4} xs={12} className="mb-2">
                <button
                  className="lgn-btn btn w-100"
                  type="button"
                  onClick={() => {
                    reset({
                      subscriptionType: "",
                      subscriptionDuration: "",
                      subscriptionValue: "",
                      subscriptionName: "",
                      rechargeAmount: "",
                      description: "",
                    });
                  }}
                >
                  {t("Reset")}
                </button>
              </Col>
              <Col lg={3} md={3} sm={4} xs={12} className="mb-2 mr-3">
                {props.loading ?
                  <button className="btn lgn-btn" type="button" disabled>
                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                    &nbsp;
                    {t("Saving...")}
                  </button>
                  :
                  <button
                    className="lgn-btn btn w-100"
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                  >
                    {t("CREATE")}
                  </button>
                }
              </Col>
            </Row>
          </Card.Footer>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.wallet.isLoading,
    saveSubRes: state.wallet.saveSub,
    dropDownRes: state.loyalty.fetchRuleDropdown,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDropdown: () => dispatch(fetchRuleDropdownApi()),
    saveSubApi: (data) => dispatch(saveSubscriptionApi(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateSubscription);
