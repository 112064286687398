import React from "react";
import {
    useTable,
    useGlobalFilter,
    usePagination,
    useSortBy,
} from "react-table";
import { Checkbox } from "../reacttable/Checkbox";
import GlobalFilter from "../reacttable/GlobalFilter"
import "../reacttable/reactTable.css"
import { useTranslation } from 'react-i18next';
import { FaFilter } from "react-icons/fa";

export default function FleetTable({ columns, data }) {
    const { t } = useTranslation();
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        gotoPage,
        pageCount,
        setPageSize,
        prepareRow,
        state,
        setGlobalFilter,
        allColumns,
        getToggleHideAllColumnsProps,
        // rows,
    } = useTable(
        {
            columns,
            data,
        },
        useGlobalFilter,
        useSortBy,
        usePagination,
    );
    const { globalFilter, pageIndex, PageSize } = state;
    // console.log("pageIndex", pageIndex)
    return (
        <>


            <div className="container-fluid ">
                <div className="row">
                    <div className="col-md-12">
                        <div className="d-flex justify-content-end align-items-center mb-3">
                            <div className="d-flex align-items-center">
                                <GlobalFilter
                                    filter={globalFilter}
                                    setFilter={setGlobalFilter}
                                />
                                <button
                                    className="btn btn-link ml-2"
                                    data-toggle="collapse"
                                    href="#collapseExample"
                                    aria-expanded="false"
                                    aria-controls="collapseExample"
                                >
                                    <FaFilter size={20} color="var(--text-primary)" />
                                </button>
                            </div>
                        </div>

                        <div className="collapse" id="collapseExample">
                            <div className="d-flex flex-wrap">
                                {allColumns.map((column) => (
                                    <span key={column.id} className="pr-3">
                                        <label className="small-checkbox-label">
                                            <input
                                                type="checkbox"
                                                {...column.getToggleHiddenProps()}
                                                className="small-checkbox" // Apply the class for small checkboxes
                                            />
                                            {column.Header}
                                        </label>
                                    </span>
                                ))}
                            </div>
                        </div>

                    </div>
                </div>

                <div className="table-responsive">
                    <div className="customtable">
                        <table {...getTableProps()} id="User_Details">
                            <thead>
                                {headerGroups.map((headerGroup) => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column) => (
                                            <th
                                                {...column.getHeaderProps(
                                                    {
                                                        style: { minWidth: column.minWidth, width: column.width }
                                                    },
                                                    column.getSortByToggleProps()
                                                )}
                                            >
                                                {t(column.render("Header"))}
                                                {column.canGroupBy ? (
                                                    <span {...column.getGroupByToggleProps()}>
                                                        {column.isGrouped ? "🛑 " : "👊 "}
                                                    </span>
                                                ) : null}
                                                <span>
                                                    {column.isSorted
                                                        ? column.isSortedDesc
                                                            ? " 🔽"
                                                            : " 🔼"
                                                        : ""}
                                                </span>
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>

                            <tbody {...getTableBodyProps()}>
                                {page.map((row, i) => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map((cell) => {
                                                return (
                                                    <td {...cell.getCellProps()}>
                                                        {cell.render("Cell")}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>

                        </table>

                    </div>
                </div>

                {/* <div className="row mt-4" md={5}>
                    <div className="col-md-4 text-left"> */}
                {/* page number */}
                {/* <span style={{ fontSize: "14px", color: "var(--text-primary)" }}>
                            Page{" "}
                            <strong>
                                {pageIndex + 1} of {pageOptions.length}
                            </strong>{" "}
                        </span>
                    </div>
                    <div className="col-md-4 text-center" >
                        <div className="row">
                            <div className="col-md-6 text-right ">
                                <span style={{ fontSize: "14px", color: "var(--text-primary)" }}>
                                    Page: {""}
                                    <input
                                        className="inputPage"
                                        type="number"
                                        // defaultValue={pageIndex + 1}
                                        placeholder={pageIndex + 1}
                                        onChange={(e) => {
                                            const pageNumber = e.target.value
                                                ? Number(e.target.value) - 1
                                                : 0;
                                            gotoPage(pageNumber);
                                        }}
                                        style={{ width: "50px", height: "25px" }}
                                    />
                                </span>
                            </div>
                            <div className="col-md-6 text-left " >
                                <select
                                    className="inputPage"
                                    value={PageSize}
                                    onChange={(e) => setPageSize(Number(e.target.value))}
                                    style={{ height: "25px" }}
                                >
                                    {[10, 200, 500, 1000].map((PageSize) => (
                                        <option key={PageSize} value={PageSize}>
                                            {PageSize} Row
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 text-right">
                        <button
                            className=" columnsBtn btn-sm mr-2"
                            onClick={() => {
                                gotoPage(0);
                            }}
                            disabled={!canPreviousPage}
                        >
                            {"<<"}
                        </button>
                        <button
                            className=" columnsBtn btn-sm mr-2"
                            onClick={() => previousPage()}
                            disabled={!canPreviousPage}
                        >
                            Previous
                        </button>
                        <button
                            className="columnsBtn btn-sm mr-2"
                            onClick={() => nextPage()}
                            disabled={!canNextPage}
                        >
                            Next
                        </button>
                        <button
                            className="columnsBtn btn-sm mr-2"
                            onClick={() => {
                                gotoPage(pageCount - 1);
                            }}
                            disabled={!canNextPage}
                        >
                            {">>"}
                        </button>
                    </div>
                </div> */}
            </div >
        </>
    );
}
