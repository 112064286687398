import React from 'react'
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchEvseStationAction } from "../../../../store/actions/evseAction";
import { triggerGetCompositeSchedule } from "../../../../store/actions/commandAction";
import { useTranslation } from 'react-i18next';
import { Bounce, ToastContainer, toast } from 'react-toastify';


const CompositeScheduleModal = (props) => {
    const { t } = useTranslation();
    const { stationid } = useParams()
    const [isToastVisible, setIsToastVisible] = useState(false);

    useEffect(() => {
        props.fetch(stationid);
    }, []);
    const evsedata = props.fetchstation && props.fetchstation
    const conn = evsedata && evsedata.evses
    const zxc = conn && conn.map(en => en.connectors)
    const data = Object.values(zxc).flatMap(arr => arr)


    //logic for get composite schedule//
    const [c_schedule, set_C_Schedule] = useState();
    const handleC_Schedule_Change = (e) => {
        set_C_Schedule({
            ...c_schedule,
            [e.target.name]: e.target.value.trim(),
        });
    }
    const handleCompositeScheduleSubmit = (e) => {
        e.preventDefault();
        props.triggerGetCompositeSchedule(c_schedule);
    }
    //logic for get composite schedule end//

    // if (props.getCompositeSchedule.statuscode === 200) {
    //     alert("Success!!! ")
    //     // window.location.reload();
    // }

    useEffect(() => {
        if (props.getCompositeSchedule.statuscode === 200) {
            setIsToastVisible(true);
            toast.success('Success!!!', {
                position: "top-center",
                autoClose: 300,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    window.location.reload();
                },
            });
        }
    }, [props.getCompositeSchedule]);


    return (
        <div className='container'>

            {isToastVisible && <div className="overlay" />}
            <ToastContainer
                position="top-center"
                autoClose={20}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ top: "10%", width: "auto", zIndex: "9999" }}
            />
            <form onSubmit={handleCompositeScheduleSubmit} >
                <div className="form-label-group required">
                    <select
                        className="form-control border-radius"
                        name="evse_id"
                        onChange={handleC_Schedule_Change}
                        required
                        autoFocus
                    >
                        <option value="">{t("Select Charger")} (*)</option>
                        {evsedata && evsedata.evses.map((own) => (
                            <option key={own.evse_id} value={own.evse_id}>
                                {own.evse_id}
                            </option>
                        ))}
                    </select>
                </div><br />


                <div className="form-label-group required">
                    <select
                        className="form-control border-radius"
                        name="connectorId"
                        onChange={handleC_Schedule_Change}
                        required
                    >
                        <option value="">{t("Select Connector")} (*)</option>
                        {data && data.map((own) => (
                            <option key={own.id} value={own.id}>
                                {own.id}
                            </option>
                        ))}
                    </select>
                </div><br />


                <div className="form-label-group">
                    <input type="text" id="charging_rate_unit" name="charging_rate_unit" className="form-control gray-border control-label" placeholder={t("Charging Rate Unit")} onChange={handleC_Schedule_Change} />
                </div><br />



                <div className="form-label-group">
                    <input type="number" id="duration" name="duration" className="form-control gray-border control-label" placeholder={t("Duration")} onChange={handleC_Schedule_Change} />
                </div><br />

                <div className="col-xl-6 col-sm-6 col-12 float-right">
                    {/* <button className="lgn-btn second-button">
                        {!props.loading ? "Get Composite Schedule" : "Please wait..."}
                    </button> */}
                    <button className="lgn-btn btn">
                        {t("Get Composite Schedule")}
                    </button>
                </div>

            </form>
        </div>
    )

}

const mapStateToProps = (state) => {
    return {
        loadingevse: state.evse.isEvseLoading,
        fetchstation: state.evse.viewstation,
        getCompositeSchedule: state.command.getCompositeSchedule,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetch: (stationid) => dispatch(fetchEvseStationAction(stationid)),
        triggerGetCompositeSchedule: (c_schedule) => dispatch(triggerGetCompositeSchedule(c_schedule)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompositeScheduleModal)