import React, { useMemo, useEffect } from "react"
import { connect } from "react-redux";
import { FaEdit, FaEye } from "react-icons/fa";
import "../../../reacttable/reactTable.css"
import moment from "moment";
import Table from "../../../reacttable/table";
import { activateSms, allSMSCampaignAction, deactivateSms } from "../../../../../store/actions/campaignAction";
import { TiTick } from "react-icons/ti";
import { AiOutlineCloseCircle } from "react-icons/ai";


function SMSActivityList(props) {
    useEffect(() => {
        props.allSMS()
    }, [])

    const data = props.list && props.list.data
    // console.log("sms list data", data);

    const activate = (id) => {
        // console.log(id);
        props.activateSMS(id);
      };
    
      if(props.activate.statuscode === 200){
        window.location.reload();
      }
    
      const deactivate = (id) => {
        // console.log(id);
        props.deactivateSMS(id);
      };
    
      if(props.deactivate.statuscode === 200){
        window.location.reload();
      }

    const columns = useMemo(
        () => [
            {
                Header: 'Recipients',
                accessor: 'recipients'
            },
            {
                Header: 'SMS Title',
                accessor: 'title'
            },
            {
                Header: 'Message',
                accessor: 'message'
            },
            {
                Header: 'Operator',
                accessor: 'operator'
            },
            {
                Header: 'Start Date',
                id: 'date',
                accessor: d => {
                    return moment(d.date)
                        .local()
                        .format("DD-MM-YYYY");
                }
            },
            {
                Header: 'Updated At',
                id: 'last_updated',
                accessor: d => {
                    return moment(d.last_updated)
                        .local()
                        .format("DD-MM-YYYY");
                }
            },
            {
                Header: "Status",
                accessor: "status",
                Cell: (status) => {
                  // console.log(publish.value)
                  return (
                    <div>
                      <span>
                        {status.value === "Active" ? (
                          <span className="text-success">Active</span>
                        ) : (
                          <span className="text-danger">Inactive</span>
                        )}
                      </span>
                    </div>
                  );
                },
              },
              {
                Header: "Actions",
                accessor: "id",
                Cell: ({ row }) => {
                  const { original } = row;
                  const id = original.id;
                  const sms_campaign_id = original.sms_campaign_id;
                  // console.log("sms_campaign_id",sms_campaign_id)
        
                  return (
                    <div>
                      <span>
                        <a
                          href={`/dashboard/viewSingleSms/${id}`}
                          rel="tooltip"
                          title="View"
                        >
                          <FaEye />
                        </a>
                      </span>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <span>
                        <a
                          href={`/dashboard/updateSms/${id}`}
                          rel="tooltip"
                          title="Edit"
                        >
                          <FaEdit />
                        </a>
                      </span>
                      &nbsp;&nbsp;  &nbsp;&nbsp;
                      <span style={{cursor:"pointer"}}>
                        <TiTick
                          onClick={() => activate(sms_campaign_id)}
                          title="Activate"
                        />
                      </span>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <span style={{cursor:"pointer"}}>
                        <AiOutlineCloseCircle
                          onClick={() => deactivate(sms_campaign_id)}
                          title="De-Activate"
                        />
                      </span>
                    </div>
                  );
                },
              },
        ],
        []
    );


    return (
        <div>
         {props.loading  ? <h3  style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "450px",
                }}>Loading data...</h3> :
            data && data.length > 0 ?
                (<div> <Table data={data} columns={columns}></Table></div>)
                :
                (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}><h3 className="loader">No data found</h3></div>)
            }
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        loading: state.campaign.isLoading,
        list: state.campaign.allsms,
        activate: state.campaign.activateSms,
        deactivate: state.campaign.deactivateSms,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        allSMS: () => dispatch(allSMSCampaignAction()),
        activateSMS: (id) => dispatch(activateSms(id)),
        deactivateSMS: (id) => dispatch(deactivateSms(id)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SMSActivityList)