import React, { useEffect } from 'react'
import { useForm } from "react-hook-form";
import { Card, Row, Col, Container } from "react-bootstrap";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { connect } from "react-redux";
import { useState } from "react";
import PureBreadcrumbs from "../breadcrums";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./advertisement.css"
import 'react-clock/dist/Clock.css';
import { createAdvertisementAction } from "../../../store/actions/advertisementAction";
import { allEvseAction, fetchEvseStationAction, fetchTimeMasterListAction } from '../../../store/actions/evseAction';
import { useTranslation } from 'react-i18next';
import { Bounce, ToastContainer, toast } from 'react-toastify';

const AdvertisementComponent = (props) => {
    // const [isCharger, setIsCharger] = useState('');
    const { role, organisation } = JSON.parse(localStorage.getItem('user'));
    const [isToastVisible, setIsToastVisible] = useState(false);

    const { t } = useTranslation();
    useEffect(() => {
        props.all();
        props.getTimeMaster();
    }, []);

    const handleChange = (e) => {
        let stationid = e.target.value
        // console.log(stationid)
        if (e.target.value !== "" && e.target.value !== undefined) {
            props.fetch(stationid);
        }
    };

    const evsedata = props.fetchstation && props.fetchstation
    // console.log(evsedata);

    const advertiseSchema = Yup.object().shape({
        stationid: Yup.string().required('Station Name is required'),
        evse_id: Yup.string().required('EVSE ID is required'),
        name: Yup.string().required('Advertisement Name is required'),
        description: Yup.string().required('Advertisement Description is required'),
        text: Yup.string().required('Advertisement content is required'),
        advertismentFor: Yup.string().required('Advertisement For is required'),
        startTime: Yup.string().required('Start time is required'),
        endTime: Yup.string().required('End time is required'),
        imagevalue: Yup.mixed(),
    });

    const [startDate, setStartDate] = useState(new Date(Date.now() + (3600 * 1000 * 1)));
    const [enddate, setEndDate] = useState(new Date(Date.now() + (3600 * 1000 * 720)));
    const { register, handleSubmit, setValue, getValues, reset, formState: { errors, isValid } } = useForm({ resolver: yupResolver(advertiseSchema), mode: "all" });

    // const handleAdvertisementFor = (event) => {
    //     setIsCharger(event.target.value);
    // }

    const onSubmit = async data => {
        const formData = new FormData();

        formData.append('stationid', data.stationid);
        formData.append('evse_id', data.evse_id);
        formData.append('name', data.name);
        formData.append('description', data.description);
        formData.append('text', data.text);
        formData.append('advertisement_for', data.advertismentFor);
        formData.append('starttime', data.startTime);
        formData.append('endtime', data.endTime);
        formData.append('startdate', startDate.toISOString());
        formData.append('enddate', enddate.toISOString());
        formData.append('role', role);
        formData.append('organisation', organisation);
        if (data.imagevalue) {
            formData.append('imagevalue', data.imagevalue[0]);
        }
        props.saveadvertise(formData)
    }

    useEffect(() => {
        if (props.save.status === 200) {
            setIsToastVisible(true);
            toast.success('Advertisement has been created successfully', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    window.location.reload();
                },
            });
        } else if (props.save.status === 405) {
            setIsToastVisible(true);
            toast.error(`❌ Error creating advertisement`, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    window.location.reload();
                },
            });
        }
    }, [props.save]);


    return (
        <>
            <Container fluid className="pb-4">
                {isToastVisible && <div className="overlay" />}
                <ToastContainer
                    position="top-center"
                    autoClose={20}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                    style={{ top: "10%", width: "auto", zIndex: "9999" }}
                />
                <PureBreadcrumbs />
                <br />
                <Row>
                    <Col xs={12} md={1}>
                    </Col>
                    <Col xs={12} md={10}>
                        <br />
                        <Card className="tariffcard">
                            <Card.Text style={{ fontSize: "14px", padding: "10px", paddingLeft: "15px" }}><b>{t("Create New Advertisement")}</b></Card.Text>
                            <Card.Body>
                                <form onSubmit={e => e.preventDefault()}>
                                    <div className="form-floating">
                                        <label>{t("Advertisment For")}<span className='mandatory-field'>*</span></label>
                                        <select autoFocus className={`form-select form-control ${errors.advertismentFor ? 'is-invalid' : ''}`} name="advertismentFor" id="floatingSelectGridAdvertismentFor" {...register("advertismentFor")} >
                                            <option value={""}>{t("Select Advertisment For")}</option>
                                            <option value={"app"}>{t("App")}</option>
                                            <option value={"charger"}>{t("Charger")}</option>
                                        </select>
                                        <div className="invalid-feedback">{errors.advertismentFor?.message}</div>
                                    </div>

                                    <>
                                        <Row className='py-2'>
                                            <Col xs={12} md={6}>
                                                <label>{t("Select Station")}<span className='mandatory-field'>*</span></label>
                                                <select className={`form-select form-control ${errors.stationid ? 'is-invalid' : ''}`} name="stationid" id="floatingSelectGridstationid" {...register("stationid")} onChange={(e) => handleChange(e)}>
                                                    <option value="">{t("Select Station")}</option>
                                                    {props.list && props.list.data?.filter((item) => item.status !== 'Discovery' && item.publish !== false)?.map((station) =>
                                                        <option key={station.stationid} value={station.stationid}>{station.name}</option>
                                                    )}
                                                </select>
                                                <div className="invalid-feedback">{errors.stationid?.message}</div>
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <label>{t("Select EVSE ID")}<span className='mandatory-field'>*</span></label>
                                                <select className={`form-select form-control ${errors.evse_id ? 'is-invalid' : ''}`} name="evse_id" id="floatingSelectGridevse_id" {...register("evse_id")}>
                                                    <option value="">{t("Select EVSE ID")}</option>
                                                    {evsedata && evsedata.evses.map((charger) =>
                                                        <option key={charger.evse_id} value={charger.evse_id}>{charger.evse_id}</option>
                                                    )}
                                                </select>
                                                <div className="invalid-feedback">{errors.evse_id?.message}</div>
                                            </Col>
                                        </Row>

                                    </>
                                    <div className="form-floating py-2">
                                        <label>{t("Advertisement Name")}<span className='mandatory-field'>*</span></label>
                                        <input type="text" className={`form-control gray-border ${errors.name ? 'is-invalid' : ''}`} name="name" id="floatingInputGridname" placeholder={t("Advertisement Name")} {...register("name")} />
                                        <div className="invalid-feedback">{errors.name?.message}</div>
                                    </div>
                                    <div className="form-floating">
                                        <label>{t("Description")}<span className='mandatory-field'>*</span></label>
                                        <input type="text" className={`form-control gray-border ${errors.description ? 'is-invalid' : ''}`} name="description" id="floatingInputGriddescription" placeholder={t("Description")} {...register("description")} />
                                        <div className="invalid-feedback">{errors.description?.message}</div>
                                    </div><br />
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            {/* <li className="breadcrumb-item active" aria-current="page">Schedule Advertisement</li> */}
                                            <div className="row sa_box">
                                                <div className="col-lg-8 col-sm-12">
                                                    <div className="row breadcrumb-item active" aria-current="page">{t("Schedule Advertisement")}</div>
                                                </div>
                                            </div>
                                        </ol>
                                    </nav>

                                    <div className="row">
                                        <div className="card sh_ad">
                                            <div className="card-body">
                                                <div className="row sh_ad">
                                                    <div className="col-lg-3 col-sm-12">
                                                        <div className="row pad_r1">
                                                            {t("Start date")}  <span className='mandatory-field'>*</span>
                                                        </div>
                                                        <div className="row pad_r1">
                                                            <DatePicker
                                                                className='gray-border'
                                                                selected={startDate}
                                                                name="start"
                                                                onChange={(date) => setStartDate(date)}
                                                                selectsStart
                                                                startDate={startDate}
                                                                dateFormat="dd/MM/yyyy"
                                                            />
                                                            {startDate === null && <div className='invalid-feedback fa-1x'>{t("Start Date is required")}</div>}

                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-sm-12">
                                                        <div className="row pad_r1">
                                                            {t("Start Time")}  <span className='mandatory-field'>*</span>
                                                        </div>
                                                        <div className="form-floating">
                                                            <select className={`form-select form-control ${errors.startTime ? 'is-invalid' : ''} `} name={`startTime`} id="floatingSelectGridperiod_begin"  {...register(`startTime`)}>
                                                                <option value={""} >{t("Select Start Time")}</option>
                                                                {props.mastertimelist && props.mastertimelist.hours?.map((v, k) =>
                                                                    <option key={v.key} value={v.value}>{v.value}</option>
                                                                )}
                                                            </select>
                                                            <div className="invalid-feedback">{errors.startTime?.message}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-sm-12">
                                                        <div className="row pad_r1">
                                                            {t("End date")} <span className='mandatory-field'>*</span>
                                                        </div>
                                                        <div className="row pad_r1">
                                                            <DatePicker
                                                                className='gray-border'
                                                                selected={enddate}
                                                                name="enddate"
                                                                onChange={(date) => setEndDate(date)}
                                                                selectsStart
                                                                startDate={startDate}
                                                                dateFormat="dd/MM/yyyy"
                                                            />
                                                            {enddate === null && <div className='invalid-feedback fa-1x'>{t("End Date is required")}</div>}

                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-sm-12">
                                                        <div className="row pad_r1">
                                                            {t("End time")} <span className='mandatory-field'>*</span>
                                                        </div>
                                                        <div className="form-floating">
                                                            <select className={`form-select form-control ${errors.endTime ? 'is-invalid' : ''} `} name={`endTime`} id="floatingSelectGridperiod_end"  {...register(`endTime`)}>
                                                                <option value={""} >{t("Select End Time")}</option>
                                                                {props.mastertimelist && props.mastertimelist.hours?.map((v, k) =>
                                                                    <option key={v.key} value={v.value}>{v.value}</option>
                                                                )}
                                                            </select>
                                                            <div className="invalid-feedback">{errors.endTime?.message}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row ">
                                                    <div className="col-lg-6 col-sm-12">
                                                        <label>{t("Choose the file")}</label>
                                                        <input type="file" name="imagevalue"
                                                            accept="image/*,video/*"
                                                            required
                                                            className={`form-control advertismentImagekey ${errors.imagevalue ? 'is-invalid' : ''}`}
                                                            {...register("imagevalue")}
                                                            style={{ paddingBottom: "2.2rem" }}
                                                        />
                                                        <div className="invalid-feedback">{errors.imagevalue?.message}</div>
                                                    </div>
                                                    <div className="col-lg-6 col-sm-12">
                                                        <label>{t("Advertisement Content")}<span className='mandatory-field'>*</span></label>
                                                        <input name="text" {...register("text")} type="text" id="floatingCPText" placeholder={t("Enter description to be shown on charger")} className={`form-control gray-border ${errors.text ? 'is-invalid' : ''}`} />
                                                        <div className="invalid-feedback">{errors.text?.message}</div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                    <br></br>
                                    <div>
                                        <Row className="">
                                            <Col lg={6} md={3} sm={12} xs={12} className="mt-2 d-flex justify-content-center justify-content-md-start">
                                                <button className="lgn-btn btn" type="button" onClick={() => {
                                                    reset({
                                                        name: '',
                                                        description: '',
                                                        startDate: setStartDate(new Date(Date.now())),
                                                        endDate: setEndDate(new Date(Date.now() + (3600 * 1000 * 720))),
                                                    });
                                                }}>{t("Reset")}</button>
                                            </Col>
                                            <Col lg={6} md={9} sm={12} xs={12} className="d-flex justify-content-center justify-content-md-end mt-2">
                                                {props.loading ? (
                                                    <button className="btn lgn-btn" type="button" disabled>
                                                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                        &nbsp;
                                                        {t("Saving...")}
                                                    </button>
                                                ) : (
                                                    <button className="lgn-btn btn" type="submit" disabled={startDate === null || enddate === null} onClick={handleSubmit(onSubmit)}>
                                                        {t("CREATE NEW ADVERTISEMENT")}
                                                    </button>
                                                )}
                                            </Col>
                                        </Row>

                                    </div><br />
                                </form>
                            </Card.Body>
                            <br /> <br />
                        </Card>
                    </Col>
                    <Col xs={12} md={1}>
                    </Col>
                </Row>
            </Container>
        </>
    )
}


const mapStateToProps = (state) => {
    return {
        loading: state.advertise.isLoading,
        list: state.evse.allevse,
        fetchstation: state.evse.viewstation,
        save: state.advertise.create,
        mastertimelist: state.evse.timemaster,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        all: () => dispatch(allEvseAction()),
        fetch: (stationid) => dispatch(fetchEvseStationAction(stationid)),
        saveadvertise: (formData) => dispatch(createAdvertisementAction(formData)),
        getTimeMaster: () => dispatch(fetchTimeMasterListAction()),

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AdvertisementComponent)