import React from 'react';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { MdOutlinePictureAsPdf } from "react-icons/md";
import { useTranslation } from 'react-i18next';

const ChargerReportPdf = (props) => {
    const { t } = useTranslation();
    const { allData } = props;

    const generatePDF = () => {
        // console.log("props", props);
        const { allData } = props;
        const unit = "pt";
        const size = "A4";
        const orientation = "landscape";
        const marginLeft = 40;
        const marginTop = 40;
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(12);
        const range = "Date range: " + props.startDate.toLocaleString() + " to " + props.endDate.toLocaleString()


        // Define headers with formatted names
        const headers = [
            "Evse I'd",
            "Station Name",
            "Location",
            "City",
            "State",
            "Total Energy",
            "Total Amount",

        ];

        // Map data from props to match headers
        const data = allData?.map(obj => {
            return [
                obj.evse_id,
                obj.stationid,
                obj.location,
                obj.city,
                obj.state,
                obj.energy,
                obj.total,
            ];
        });


        let content = {
            startY: 80,
            head: [headers],
            body: data
        };

        doc.text("Charger Report", marginLeft, 40);
        doc.text(range, marginLeft, 60);
        doc.autoTable(content);
        doc.save("Charger Report.pdf");
    };

    const handleButtonClick = () => {
        generatePDF();
    };

    return (
        <div>
            <MdOutlinePictureAsPdf size={30} style={{ "cursor": 'pointer', 'color': 'var(--text-primary)' }} onClick={handleButtonClick} disabled={!allData?.length > 0} />
        </div>
    );
};

export default ChargerReportPdf;
