import { connect } from "react-redux";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useState, useEffect } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { saveStateGrouping, stateApi } from "../../../../store/actions/accessAction";
import PureBreadcrumbs from "../../breadcrums";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { Bounce, ToastContainer, toast } from "react-toastify";
import DarkThemeStyles from "../../resuableComponent/reactSelectSingleValueStyle";
import { allEvseAction } from "../../../../store/actions/evseAction";

const CreateStateGroup = (props) => {
  const { t } = useTranslation();
  const countryCode = process.env.REACT_APP_COUNTRY_CODE;
  // console.log("country code", countryCode);
  const [isArrayMatch, setIsArrayMatch] = useState(false);
  const [isZoneMatch, setIsZoneMatch] = useState(false);
  const [isToastVisible, setIsToastVisible] = useState(false);
  const [ViewStations, setViewStations] = useState(false);

  useEffect(() => {
    props.getState(countryCode);
    appendList1({
      zone: "",
      states: [""],
    });
    props.all();
  }, []);

  const state = props.stateRes && props.stateRes.state;
  // console.log(state);

  const stateSchema = Yup.object().shape({
    stateGroup: Yup.array().of(
      Yup.object().shape({
        zone: Yup.string().required("Zone is required"),
        states: Yup.array().of(Yup.string()),
      })
    ),
  });

  const {
    register,
    control,
    getValues,
    setValue,
    watch,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({ resolver: yupResolver(stateSchema), mode: "all" });
  const {
    fields: fieldsList1,
    remove: removeList1,
    append: appendList1,
  } = useFieldArray({ control, name: "stateGroup" });
  const stateGroupWatch = watch("stateGroup");

  let statesOnly = stateGroupWatch && stateGroupWatch.map((item) => item.states).flat();
  let zoneOnly = stateGroupWatch && stateGroupWatch.map((item) => item.zone).flat();
  // console.log("zoneOnly",zoneOnly)

  const onSubmit = async (data) => {
    props.saveGrouping(data);
    // console.log(data)
  };

  useEffect(() => {
    if (props.saveStateGroupRes.statuscode === 200) {
      setIsToastVisible(true);
      toast.success("State group is saved successfully", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        onClose: () => {
          setIsToastVisible(false);
          window.location.reload();
        },
      });
    } else if (props.saveStateGroupRes.statuscode === 405) {
      setIsToastVisible(true);
      toast.error(`❌ ${props.saveStateGroupRes.error}`, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        onClose: () => {
          setIsToastVisible(false);
          window.location.reload();
        },
      });
    }
  }, [props.saveStateGroupRes]);

  const options = state && state.map((state) => ({ value: state.state_name, label: state.state_name }));
  // console.log("option", options)

  const handleDayChange = (e) => {
    const value = e.target.value;
    if (value === "stations") {
      setViewStations(true);
    } else {
      const isPresent = zoneOnly.includes(value);
      setIsZoneMatch(isPresent);
      setViewStations(false);
    }
  };

  const handleRegionOnchange = (selectedOption, i) => {
    // console.log("selectedOption", selectedOption);
    if (selectedOption) {
      const selectedValues = selectedOption.map((option) => option.value);
      // console.log("selectedValues", selectedValues);
      setValue(`stateGroup[${i}].states`, selectedValues);
      const isPresent = selectedValues.every((state) => statesOnly.includes(state));
      setIsArrayMatch(isPresent);
    }
  };

  const stationNameOptions = [
    // { value: "All", label: "All" }, // Static option
    ...(props.list && props.list.data
      ? props.list.data
          .filter((item) => item.status !== "Discovery" && item.publish !== false)
          .map((item) => ({
            value: item.name,
            label: item.name,
          }))
      : []), // Dynamic options
  ];

  return (
    <>
      <div className="container-fluid">
        {isToastVisible && <div className="overlay" />}
        <ToastContainer
          position="top-center"
          autoClose={20}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          style={{ top: "10%", width: "auto", zIndex: "9999" }}
        />
        <PureBreadcrumbs />
        <Row className="d-flex justify-content-center align-items-center pt-5">
          <Col xs={12} md={12} lg={12}>
            <form onSubmit={(e) => e.preventDefault()}>
              <div>
                <Card className="tariffcard d-flex justify-content-center align-items-center">
                  <Card.Body>
                    <h6 className="text-center"> {t("Create Zone")}</h6>
                  </Card.Body>
                </Card>

                {fieldsList1.map((item, i) => (
                  <Card className="tariffcard" key={i}>
                    {i === 0 ? (
                      <h6 className="text-start p-3">
                        <b>{t("Add Zone")}</b>
                      </h6>
                    ) : (
                      ""
                    )}
                    <Card.Body>
                      <div className="row g-2 mt-3 ">
                        <div className="col-md">
                          <div className="form-floating">
                            <label>
                              {t("Select Zones")}
                              <span className="mandatory-field">*</span>
                            </label>
                            <select
                              className={`form-select form-control ${errors.stateGroup?.[i]?.zone ? "is-invalid" : ""}`}
                              name={`stateGroup[${i}].zone`}
                              id="floatingSelectGridday"
                              {...register(`stateGroup[${i}].zone`)}
                              onChange={(e) => handleDayChange(e, i)}
                            >
                              <option value={""}>{t("Select Zones")}</option>
                              {<option value={"north"}>{t("North Zone")}</option>}
                              {<option value={"south"}>{t("South Zone")}</option>}
                              {<option value={"east"}>{t("East Zones")}</option>}
                              {<option value={"west"}>{t("West Zones")}</option>}
                              {<option value={"stations"}>{t("Station Zones")}</option>}
                            </select>
                          </div>
                          <div className="invalid-feedback">{errors.stateGroup?.[i]?.zone?.message}</div>
                          {isZoneMatch && <div className="text-danger">Same Zone already exist</div>}
                        </div>

                        {ViewStations == false ? (
                          <div className="col-md">
                            <label>
                              {t("Select States")}
                              <span className="mandatory-field">*</span>
                            </label>
                            <div className="form-floating">
                              <Select
                                closeMenuOnSelect={true}
                                options={options || []}
                                isMulti
                                onChange={(selectedOption) => handleRegionOnchange(selectedOption, i)}
                                placeholder="select States"
                                noOptionsMessage={() => "No state is available"}
                                styles={DarkThemeStyles}
                                menuPortalTarget={document.body}
                              />
                            </div>
                            {isArrayMatch && <div className="text-danger">Same States already exist</div>}
                          </div>
                        ) : (
                          <div className="col-md">
                            <label>
                              {t("Select Stations")}
                              <span className="mandatory-field">*</span>
                            </label>
                            <div className="col-md">
                              <div className="form-floating">
                                <Controller
                                  name="station_name"
                                  control={control}
                                  className={`${errors.station_name ? "is-invalid" : ""}`}
                                  render={({ field: { onChange, value } }) => (
                                    <Select
                                      options={stationNameOptions || []}
                                      isMulti
                                      placeholder="Select Station Name"
                                      styles={DarkThemeStyles}
                                      menuPortalTarget={document.body}
                                      onChange={(selectedOptions) => {
                                        onChange(selectedOptions ? selectedOptions.map((option) => option?.value) : []); // Update form value
                                      }}
                                      // value={stationNameOptions.filter(option => value?.includes(option.value))}
                                      isOptionDisabled={(option) => {
                                        const isAnyStationSelected =
                                          value && value.length > 0 && !value.includes("All");
                                        const isAllSelected = value && value.includes("All");
                                        if (option.value === "All") {
                                          return isAnyStationSelected;
                                        }
                                        return isAllSelected;
                                      }}
                                    />
                                  )}
                                />

                                {/* Display validation error message */}
                                {errors.station_name && (
                                  <div className="invalid-feedback">{errors.station_name.message}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </Card.Body>
                    {ViewStations == false && (
                      <div className="d-grid gap-2 d-flex justify-content-end p-1">
                        <button
                          className="btn  btn-lg"
                          type="button"
                          onClick={(e) => {
                            e.preventDefault();
                            removeList1(i);
                            setIsZoneMatch(false);
                            setIsArrayMatch(false);
                          }}
                        >
                          {" "}
                          <i className="far fa-trash-alt" style={{ color: "red" }}></i>
                        </button>
                      </div>
                    )}
                  </Card>
                ))}

                <Card.Footer className="d-grid gap-2">
                  <Row
                    className={
                      !isZoneMatch
                        ? stateGroupWatch && stateGroupWatch.length > 3
                          ? "d-flex justify-content-end"
                          : "d-flex justify-content-between"
                        : "d-flex justify-content-end"
                    }
                  >
                    {ViewStations == false && stateGroupWatch && stateGroupWatch.length <= 3 && !isZoneMatch && (
                      <Col lg={6} md={6} className="d-flex justify-content-center justify-content-md-start mt-2">
                        <button
                          className="btn btn-dark"
                          type="button"
                          onClick={() =>
                            appendList1({
                              zone: "",
                              states: [""],
                            })
                          }
                        >
                          {" "}
                          (+) {t("Add More Zones")}
                        </button>
                      </Col>
                    )}
                    {stateGroupWatch && stateGroupWatch.length > 0 && props.loading ? (
                      <Col
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        className="d-flex justify-content-center justify-content-md-end mt-2"
                      >
                        <button className="btn lgn-btn" type="button" disabled>
                          <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                          {t("Saving...")}
                        </button>
                      </Col>
                    ) : (
                      <Col
                        lg={ViewStations == false ? 6 : 12}
                        md={6}
                        sm={12}
                        xs={12}
                        className="d-flex justify-content-end mt-2"
                        style={{ float: "right" }}
                      >
                        <button
                          className="lgn-btn btn "
                          type="submit"
                          onClick={handleSubmit(onSubmit)}
                          disabled={isZoneMatch}
                        >
                          {t("Submit")}
                        </button>
                      </Col>
                    )}
                  </Row>
                </Card.Footer>
              </div>
            </form>
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.access.isLoading,
    stateRes: state.access.getState,
    saveStateGroupRes: state.access.saveState,
    list: state.evse.allevse,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    saveGrouping: (data) => dispatch(saveStateGrouping(data)),
    getState: (code) => dispatch(stateApi(code)),
    all: () => dispatch(allEvseAction()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateStateGroup);
