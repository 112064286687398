import { useState, useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import ReactJson from "react-json-view";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import PureBreadcrumbs from "../../../breadcrums";
import { fetchExternalViewSinglePartnerAction, fetchExternalViewSinglePartnerTokensAction, singleRoamingPartner } from "../../../../../store/actions/ocpiAction";

function ViewSinglePartner(props) {
    const { party_id } = useParams();
    const [activeTab, setActiveTab] = useState('Self');

    useEffect(() => {
        if (activeTab === 'Self') {
            props.getSinglerData(party_id); // Fetch Self data
        } else if (activeTab === 'Partner') {
            props.getSinglePartnerData(party_id); // Fetch partner data
        }
    }, [activeTab, party_id]);

    const selfData = props.fetchSingleDetails?.data && props.fetchSingleDetails?.data;
    const partnerData = props.externalViewSinglePartnerRes?.data && props.externalViewSinglePartnerRes?.data;

    const data = activeTab === 'Self' ? selfData : partnerData;
    // console.log("data: ", data);
    const token = data?.token;
    const tokenA = props.externalViewSinglePartnerTokensRes && props.externalViewSinglePartnerTokensRes.data?.token_a
    const tokenB = props.externalViewSinglePartnerTokensRes && props.externalViewSinglePartnerTokensRes.data?.token_b
    const tokenC = props.externalViewSinglePartnerTokensRes && props.externalViewSinglePartnerTokensRes.data?.token_c

    useEffect(() => {
        props.getTokensAandB(party_id, token);
    }, [activeTab, party_id, token]);

    const businessName = data?.roles?.[0]?.business_details?.name;
    const business_website = data?.roles?.[0]?.business_details?.website;
    const business_logo_url = data?.roles?.[0]?.business_details?.logo?.url;
    const partyId = data?.roles?.[0]?.party_id;
    const countrycode = data?.roles?.[0]?.country_code;
    const version = data?.endpoint_details?.version;
    const endpoints = data?.endpoint_details?.endpoints;
    const roles = data?.roles?.[0];

    const renderForm = (isPartner) => (
        <Row className="mt-3">
            <Col>
                <Card className="tariffcard">
                    <Card.Body>
                        <Row>
                            <Col xs={12} md={6}>
                                <div className="py-1">
                                    <label htmlFor="floatingInputGridName">Partner Name</label>
                                    <input
                                        className="input gray-border"
                                        type="text"
                                        name="partner_name"
                                        id="floatingInputGridName"
                                        placeholder="Firstname"
                                        defaultValue={businessName}
                                        disabled
                                    />
                                </div>
                                <div className="py-1">
                                    <label htmlFor="floatingInputGridPID">Party ID</label>
                                    <input
                                        className="input gray-border"
                                        type="text"
                                        name="party_id"
                                        id="floatingInputGridparty_id"
                                        placeholder="Party ID"
                                        defaultValue={partyId}
                                        disabled
                                    />
                                </div>
                                <div className="py-1">
                                    <label htmlFor="floatingInputGridCC">Country Code</label>
                                    <input
                                        className="input gray-border"
                                        type="text"
                                        name="country_code"
                                        id="floatingInputGridcountry_code"
                                        placeholder="Country Code"
                                        defaultValue={countrycode}
                                        disabled
                                    />
                                </div>
                                <div className="py-1">
                                    <label htmlFor="floatingInputGridName">URL</label>
                                    <input
                                        className="input gray-border"
                                        type="text"
                                        name="url"
                                        id="floatingInputGridURL"
                                        placeholder="URL"
                                        defaultValue={data?.url}
                                        disabled
                                    />
                                </div>
                            </Col>
                            {!isPartner && (
                                <Col xs={12} md={6}>
                                    <div className="py-1">
                                        <label htmlFor="tokenA">Token A</label>
                                        <input
                                            className="input gray-border"
                                            type="text"
                                            name="tokenA"
                                            id="tokenA"
                                            placeholder="Token A"
                                            defaultValue={tokenA}
                                            disabled
                                        />
                                    </div>
                                    <div className="py-1">
                                        <label htmlFor="tokenB">Token B</label>
                                        <input
                                            className="input gray-border"
                                            type="text"
                                            name="tokenB"
                                            id="tokenB"
                                            placeholder="Token B"
                                            defaultValue={tokenB}
                                            disabled
                                        />
                                    </div>
                                    <div className="py-1">
                                        <label htmlFor="tokenC">Token C</label>
                                        <input
                                            className="input gray-border"
                                            type="text"
                                            name="tokenC"
                                            id="tokenC"
                                            placeholder="Token C"
                                            defaultValue={tokenC}
                                            disabled
                                        />
                                    </div>
                                </Col>
                            )}
                        </Row>

                        {/* Role */}
                        <Row>
                            <Col>
                                <span style={{ color: "var(--text-primary)", fontWeight: "bold", fontSize: 18 }}>Role -</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="my_Privilagecontainer d-flex align-items-center">
                                    <div className="form-check form-switch mx-4">
                                        <input
                                            className={`form-check-input`}
                                            type="checkbox"
                                            checked={roles?.role === 'EMSP'}
                                            style={{ width: "30%" }}
                                        />
                                        <label className="form-check-label mx-2">EMSP</label>
                                    </div>
                                    <div className="form-check form-switch mx-4 mt-1">
                                        <input
                                            className={`form-check-input`}
                                            type="checkbox"
                                            checked={roles?.role === 'CPO'}
                                            style={{ width: "30%" }}
                                        />
                                        <label className="form-check-label mx-2 mt-1">CPO</label>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        {/* Business Details */}
                        <Row className="mt-3">
                            <Col>
                                <span style={{ color: "var(--text-primary)", fontWeight: "bold", fontSize: 18 }}>Business Details -</span>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={4}>
                                <div className="py-1">
                                    <label htmlFor="business_name">Business Name</label>
                                    <input
                                        className="input gray-border"
                                        type="text"
                                        name="business_name"
                                        id="business_name"
                                        placeholder="Business Name"
                                        defaultValue={businessName || ''}
                                        disabled
                                    />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="py-1">
                                    <label htmlFor="websites">Website</label>
                                    <input
                                        className="input gray-border"
                                        type="text"
                                        name="websites"
                                        id="websites"
                                        placeholder="Website URL"
                                        defaultValue={business_website || ''}
                                        disabled
                                    />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="py-1">
                                    <label htmlFor="logo_url">Logo URL</label>
                                    <input
                                        className="input gray-border"
                                        type="text"
                                        name="logo_url"
                                        id="logo_url"
                                        placeholder="Logo URL"
                                        defaultValue={business_logo_url || ''}
                                        disabled
                                    />
                                </div>
                            </Col>
                        </Row>

                        <Row className="mt-4">
                            <Col className="">
                                <span style={{ color: "var(--text-primary)", fontWeight: "bold", fontSize: 18 }}>Endpoint Version - {version} </span>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col className="">
                                <span style={{ color: "var(--text-primary)", fontWeight: "bold", fontSize: 18 }}>Endpoint Details  </span>
                                <ReactJson src={endpoints} />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );

    console.log("loading", props.loading);

    return (
        <Container fluid>
            <PureBreadcrumbs />
            <Row>
                <Col className="mt-4">
                    <span style={{ color: "var(--text-primary)", fontWeight: "bold" }}>Tariff Details</span>
                </Col>
            </Row>

            <Row className="mt-3">
                <Col xs={12}>
                    <div style={{
                        border: "1px solid #d9d9d9",
                        borderRadius: "50px",
                        padding: "0px",
                        backgroundColor: "#f3f3f3", // Light background for the switch container
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "20%",
                        margin: "0 auto",
                    }}>
                        <button
                            onClick={() => setActiveTab('Self')}
                            className={`tab-button ${activeTab === 'Self' ? 'active' : ''}`}
                            style={{
                                backgroundColor: activeTab === 'Self' ? '#000000' : '#f3f3f3',
                                color: activeTab === 'Self' ? '#f3f3f3' : '#000000',
                                border: "none",
                                padding: '10px 20px',
                                borderRadius: "50px",
                                fontWeight: "bold",
                                fontSize: "16px",
                                cursor: "pointer",
                                transition: "all 0.3s ease",
                                width: "55%",
                            }}
                        >
                            Self
                        </button>
                        <button
                            onClick={() => setActiveTab('Partner')}
                            className={`tab-button ${activeTab === 'Partner' ? 'active' : ''}`}
                            style={{
                                backgroundColor: activeTab === 'Partner' ? '#000000' : '#f3f3f3',
                                color: activeTab === 'Partner' ? '#f3f3f3' : '#000000',
                                border: "none",
                                padding: '10px 20px',
                                borderRadius: "50px",
                                fontWeight: "bold",
                                fontSize: "16px",
                                cursor: "pointer",
                                transition: "all 0.3s ease",
                                width: "55%",
                            }}
                        >
                            Partner
                        </button>
                    </div>
                </Col>
            </Row>
            {props.loading ? (
                <Row >
                    <Col lg={12} className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden"></span>
                        </div>
                    </Col>
                </Row>
            ) : (
                <>
                    {activeTab === 'Self' && renderForm(false)}
                    {activeTab === 'Partner' && renderForm(true)}
                </>
            )}
        </Container>
    );
}

const mapStateToProps = (state) => ({
    loading: state.ocpi.isLoading,
    fetchSingleDetails: state.ocpi.fetchsinglepartner,
    externalViewSinglePartnerRes: state.ocpi.externalViewSinglePartner,
    externalViewSinglePartnerTokensRes: state.ocpi.externalViewSinglePartnerTokens,
});

const mapDispatchToProps = (dispatch) => ({
    getSinglerData: (party_id) => dispatch(singleRoamingPartner(party_id)),
    getSinglePartnerData: (party_id) => dispatch(fetchExternalViewSinglePartnerAction(party_id)),
    getTokensAandB: (party_id, tokenC) => dispatch(fetchExternalViewSinglePartnerTokensAction(party_id, tokenC)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewSinglePartner);
