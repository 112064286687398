import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchRfidChargingHistoryAction, fetchSingleReportRfidAction } from '../../../../store/actions/reportActions';
import { Card, Col, Container, Row } from 'react-bootstrap';
import moment from 'moment';
import PureBreadcrumbs from "../../breadcrums";
import Table from '../../reacttable/table';
import DownloadRfidTransactionPdf from './downloadRfidTransaction';
import DatePicker from "react-datepicker";
import { CSVLink } from 'react-csv';

const ViewReportSingleRfid = (props) => {
    const { rfid } = useParams();
    const currency = process.env.REACT_APP_CURRENCY;
    const oneWeekInMilliseconds = 6 * 24 * 60 * 60 * 1000; // Number of milliseconds in a week
    const currentDate = new Date(); // Get the current date and time
    const tomorrowDate = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000); // Get tomorrow's date
    const [startDate, setStartDate] = useState(new Date(currentDate.getTime() - oneWeekInMilliseconds)); // Set startDate to one week before currentDate
    const [endDate, setEndDate] = useState(tomorrowDate);

    useEffect(() => {
        props.fetchSingleRfid(rfid);
        props.fetchSingleRfidChargingHistory(rfid, startDate, endDate);
    }, [rfid]);

    const data = props.fetchSingleRfidRes?.data || {};
    // console.log("firstRfid: ", data)
    const chargingTransactionData = props.fetchSingleRfidChargingHistoryRes?.history;
    const totalKwh = props.fetchSingleRfidChargingHistoryRes?.lifetime_energy
    const totalAmount = props.fetchSingleRfidChargingHistoryRes?.lifetime_amount

    const loadNewData = () => {
        props.fetchSingleRfidChargingHistory(rfid, startDate, endDate);
    };

    // const prepareTableData = () => {
    //     const tableData = [];

    //     if (data.amount_based) {
    //         data.amount_transactions?.forEach(transaction => {
    //             tableData.push({
    //                 type: "Amount Transactions",
    //                 amount: transaction.amount,
    //                 date: moment(transaction.date).format('MMMM Do YYYY'),
    //                 status: transaction.status,
    //             });
    //         });
    //     }

    //     if (data.kwh_based) {
    //         data.kwh_transactions?.forEach(transaction => {
    //             tableData.push({
    //                 type: "KWh Transactions",
    //                 kwh_amount: transaction.kwh_amount,
    //                 kwh: transaction.kwh,
    //                 date: moment(transaction.date).format('Do MMMM YYYY'),
    //                 status: transaction.status,
    //             });
    //         });
    //     }

    //     if (data.time_based) {
    //         data.time_transactions?.forEach(transaction => {
    //             tableData.push({
    //                 type: "Time Transactions",
    //                 time_amount: transaction.time_amount,
    //                 time: transaction.time,
    //                 date: moment(transaction.date).format('Do MMMM YYYY'),
    //                 status: transaction.status,
    //             });
    //         });
    //     }

    //     return tableData;
    // };

    const columns = useMemo(() => [
        {
            Header: "RFID No.",
            accessor: "rfid",
        },
        {
            Header: "Amount",
            accessor: "amount_recharge",
        },
        {
            Header: "Remaining Amount",
            accessor: "amount",
        },
        {
            Header: "kWh",
            accessor: "kwh_recharge",
        },
        {
            Header: "Remaining kWh",
            accessor: "kwh",
        },
        {
            Header: "Time (in Hrs)",
            accessor: "time_recharge",
        },
        {
            Header: "Remaining Time (in Min)",
            accessor: "time",
        },
        {
            Header: "IsFreeCharging?",
            accessor: "free_charging",
            Cell: ({ value }) => {
                return (
                    <div>
                        {value ? "Yes" : "No"}
                    </div>
                );
            }
        }
    ], []);

    const transactionColumn = useMemo(() => [
        {
            Header: "Charging ID",
            accessor: "charging_id",
        },
        {
            Header: "Date",
            accessor: "start",
        },
        {
            Header: "RFID No.",
            accessor: "idtag",
        },
        {
            Header: "Station Name",
            accessor: "stationid",
        },
        {
            Header: "ChargeBox Id",
            accessor: "evse_id",
        },
        {
            Header: "Connector Id",
            accessor: "portno",
        },
        {
            Header: "Charging Time",
            accessor: "consume_time",
        },
        {
            Header: "Consume Energy",
            accessor: "kwh",
            Cell: ({ value }) => value.toFixed(2),
        },
        {
            Header: "Total Amount",
            accessor: "total_amount",
            Cell: ({ value }) => `${value.toFixed(2)}`,
        },
    ], []);

    const headers = [
        { label: "Charging ID", key: "charging_id" },
        { label: "Date", key: "start" },
        { label: "RFID No.", key: "idtag" },
        { label: "Station Name", key: "stationid" },
        { label: "ChargeBox Id", key: "evse_id" },
        { label: "Connector Id", key: "portno" },
        { label: "Charging Time", key: "consume_time" },
        { label: "Consume Energy", key: "kwh" },
        { label: "Total Amount", key: "total_amount" },
    ];

    return (
        <Container fluid className="">
            <PureBreadcrumbs />

            {/* // Rfid Single Wallet History Table */}

            <Row className='mt-5'>
                <Col lg={12} md={12}>
                    <Card className="customercard">
                        <Card.Header>User RFID Wallet Details</Card.Header>
                        <Card.Body>
                            {props.loading ? (
                                <h3 style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "250px",
                                }}>Loading data...</h3>
                            ) : data && data ? (
                                <div>
                                    <Table data={[data]} columns={columns} />
                                </div>
                            ) : (
                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "300px"
                                }}>
                                    <h3 className="loader">No data found</h3>
                                </div>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* // RFID Single Transaction table */}

            <Row className='mt-3'>
                <Col lg={12}>
                    <Card className="customercard">
                        <Row>
                            <Col>
                                <Card.Header>
                                    <Row>
                                        <Col md={6} sm={12}>
                                            <span>RFID Transaction History</span>
                                        </Col>
                                        <Col md={6} sm={12}>
                                            <div className="row d-flex justify-content-end">
                                                <div>
                                                    <img
                                                        src={process.env.PUBLIC_URL + "/images/reports/energy.svg"}
                                                        alt="" style={{ height: "35px", paddingLeft: "25px" }}
                                                    />  {props.fetchSingleRfidChargingHistoryRes && props.fetchSingleRfidChargingHistoryRes.lifetime_energy !== undefined
                                                        ? props.fetchSingleRfidChargingHistoryRes && props.fetchSingleRfidChargingHistoryRes.lifetime_energy.toFixed(2)
                                                        : 0} kWh
                                                </div>
                                                <div>
                                                    <img
                                                        alt="" style={{ height: "35px", paddingLeft: "25px" }}
                                                    />  {currency} {props.fetchSingleRfidChargingHistoryRes && props.fetchSingleRfidChargingHistoryRes.lifetime_amount !== undefined
                                                        ? props.fetchSingleRfidChargingHistoryRes && props.fetchSingleRfidChargingHistoryRes.lifetime_amount.toFixed(2)
                                                        : 0}
                                                </div>
                                                <div>
                                                    <img
                                                        src={process.env.PUBLIC_URL + "/images/reports/co2reduced.svg"}
                                                        alt="" style={{ height: "35px", paddingLeft: "25px" }}
                                                    />  {props.fetchSingleRfidChargingHistoryRes && props.fetchSingleRfidChargingHistoryRes.total_co2 !== undefined
                                                        ? `${props.fetchSingleRfidChargingHistoryRes && props.fetchSingleRfidChargingHistoryRes.total_co2?.toFixed(2)}Kg`
                                                        : `${0}Kg`}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Header>
                            </Col>
                        </Row>


                        <Row className="d-flex justify-content-end mt-3 mb-1 pr-3">
                            <div className="col-auto">
                                <DatePicker
                                    className="gray-border calendar"
                                    selected={startDate}
                                    placeholderText="Select start date"
                                    onChange={(date) => setStartDate(date)}
                                    selectsStart
                                    startDate={startDate}
                                    dateFormat="dd/MM/yyyy"
                                    maxDate={new Date()}
                                />
                            </div>
                            <span style={{ paddingTop: "7px" }}> To </span>
                            <div className="col-auto">
                                <DatePicker
                                    className="gray-border calendar"
                                    selected={endDate}
                                    placeholderText="Select end date"
                                    onChange={(date) => setEndDate(date)}
                                    selectsEnd
                                    startDate={startDate}
                                    endDate={endDate}
                                    minDate={startDate}
                                    showDisabledMonthNavigation
                                    dateFormat="dd/MM/yyyy"
                                    maxDate={tomorrowDate}
                                />
                            </div>
                            <div className="csvbutton">
                                <button className="btn btn-sm" onClick={loadNewData}>
                                    <span
                                        style={{
                                            fontSize: "16px",
                                            color: "#676767",
                                            fontWeight: "600",
                                        }}
                                    >
                                        GO
                                    </span>
                                </button>
                            </div>
                            <div className="mx-2">
                                {chargingTransactionData && chargingTransactionData != null ? (
                                    <CSVLink
                                        data={chargingTransactionData}
                                        headers={headers}
                                        filename={
                                            "RFID Transaction Data_"
                                            +
                                            startDate?.toISOString()?.substr(0, 10) +
                                            "_" +
                                            endDate?.toISOString()?.substr(0, 10) +
                                            `.csv`
                                        }
                                        target="_blank"
                                    // onClick={handleCSVLinkClick}

                                    >
                                        <i className="fas fa-2x fa-file-csv"></i>
                                    </CSVLink>
                                ) : (
                                    <i className="fas fa-2x fa-file-csv"></i>
                                )}
                            </div>
                            <DownloadRfidTransactionPdf
                                startDate={startDate}
                                endDate={endDate}
                                data={chargingTransactionData}
                                rfid={rfid}
                                totalAmount={totalAmount}
                                totalKwh={totalKwh}
                            />
                        </Row>
                        <Card.Body>
                            {props.loading ? (
                                <h3 style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "250px",
                                }}>Loading data...</h3>
                            ) : chargingTransactionData ? (
                                <div>
                                    <Table data={chargingTransactionData} columns={transactionColumn} />
                                </div>
                            ) : (
                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "300px"
                                }}>
                                    <h3 className="loader">No data found</h3>
                                </div>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container >
    );
};

const mapStateToProps = (state) => ({
    loading: state.report.isLoading,
    fetchSingleRfidRes: state.report.fetchSingleReportRfid,
    fetchSingleRfidChargingHistoryRes: state.report.fetchSingleRfidChargingHistory,
});

const mapDispatchToProps = (dispatch) => ({
    fetchSingleRfid: (rfid) => dispatch(fetchSingleReportRfidAction(rfid)),
    fetchSingleRfidChargingHistory: (rfid, startDate, endDate) => dispatch(fetchRfidChargingHistoryAction(rfid, startDate, endDate)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewReportSingleRfid);
