import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Row, Card } from 'react-bootstrap';
import "../forgetPassword/forgetpassword.css";
import { useHistory } from 'react-router-dom';
import { getReSendOTP, getSendOTP, validateOTP } from '../../../store/actions/authAction';
import { useTranslation } from 'react-i18next';


function ForgetOtp(props) {
    const { t } = useTranslation();
    let history = useHistory()
    const validateData = props.validateOtp && props.validateOtp
    // console.log("validateData",validateData)

    // console.log(localStorage)

    useEffect(() => {
        if (props.sendOTP && props.sendOTP.statuscode === 200) {
            alert("OTP has been sent to your registered email id");
        } else if (props.sendOTP && props.sendOTP.statuscode === 404) {
            alert("The UserID or Email Id provided does not match our records. Please check your input and try again.");
        }

    }, [props.sendOTP]);

    useEffect(() => {
        if (props.reSendOTP && props.reSendOTP.statuscode === 200) {
            alert("OTP has been sent.");
        }
    }, [props.reSendOTP]);

    const usernameSchema = Yup.object().shape({
        userid: Yup.string().required('UserID or Email Id is required'),
    });
    const { register: registerForm1, handleSubmit: handleSubmitForm1, formState: { errors: errorForm1 } } = useForm({ resolver: yupResolver(usernameSchema), mode: "all" });

    const data = props.sendOTP && props.sendOTP;
    // console.log(data);
    // localStorage.setItem('userid',data.userid );

    const onOTPPreSubmit = (data) => {
        props.getUserOTP(data);

    };

    const handleResendOTP = () => {
        props.getUserResendOTP(data);
    }


    const otpSchema = Yup.object().shape({
        otp: Yup.number().required('OTP is required').test('len', 'OTP must be exactly 6 digits', (val) => val && val.toString().length === 6),
        userid: Yup.string(),
    });


    const { register: registerForm2, setValue, handleSubmit: handleSubmitForm2, formState: { errors: errorForm2 } } = useForm({ resolver: yupResolver(otpSchema), mode: "all" });
    setValue("userid", data.userid);

    const onOTPPostSubmit = async data2 => {
        props.validateUserOTP(data2);

    };

    useEffect(() => {
        if (props.validateOtp && props.validateOtp.statuscode === 200) {
            alert(props.validateOtp.result);
            history.push('/forget_password');
            window.location.reload();

        } else if (props.validateOtp && props.validateOtp.statuscode === 405) {
            alert(props.validateOtp.error);
        }

    }, [props.validateOtp])


    return (
        <>
            <div className='login-background'>
                <Card className='forgetpass-box-form'>
                    <Card.Body>
                        <Row className='d-flex justify-content-center'>
                            <span className='forget_otp_header'>
                                {t("Forget Password")} ?
                            </span>
                        </Row>
                        <br />
                        <br />
                        <form onSubmit={e => e.preventDefault()}>
                            <div className="otp_input_box">
                                <div className="form-floating">
                                    <label className='mb-3' htmlFor="floatingInputUserId">{t("Please enter your registered userid or email id to get an OTP")}</label>
                                    <input type="text" autoFocus className={`form-control ${errorForm1.userid ? 'is-invalid' : ''}`} id="floatingInputUserId" name="userid" aria-label={t("Enter UserId/Email Id")} placeholder={t("Enter UserId/Email Id")} {...registerForm1("userid")} />
                                    <div className="invalid-feedback">{errorForm1.userid?.message}</div>
                                </div>
                                <br />
                                <br />
                                <div>
                                    <Row className='d-flex justify-content-center'>
                                        <button className={`lgn-btn ${props.sendOTP.statuscode === 200 ? 'blurred-btn' : ''}`} type="submit" onClick={handleSubmitForm1(onOTPPreSubmit)} disabled={props.sendOTP.statuscode === 200 ? true : false} >
                                            {t("Send OTP")}
                                        </button>
                                    </Row>
                                </div>

                            </div>
                        </form>
                        <br />
                        <br />
                        {props.sendOTP.statuscode === 200 && <form onSubmit={e => e.preventDefault()}>
                            <div className="otp_input_box2">
                                <div className="form-floating">
                                    <label className='' htmlFor="floatingInputOtp">{t("Please enter your OTP")}</label>
                                    <input type="number" autoFocus className={`form-control ${errorForm2.otp ? 'is-invalid' : ''}`} id="floatingInputOtp" name="otp" aria-label={t("Enter otp")} placeholder={t("Enter otp")} {...registerForm2("otp")} />
                                    <div className="invalid-feedback">{t("OTP must be exactly 6 digits")}</div>
                                </div>
                                {/* <Row className='d-flex justify-content-center'>
                                    <span className='' >{t(props.validateOtp && props.validateOtp.statuscode === 405 ? props.validateOtp.error : "")} <span style={{ "color": "red", "display": "contents", "cursor": "pointer" }}></span></span>
                                </Row> */}
                                <br />
                                <br />
                                <Row className='d-flex justify-content-center'>
                                    <button className="lgn-btn" type="submit" onClick={handleSubmitForm2(onOTPPostSubmit)}>
                                        {t("Verify")}
                                    </button>
                                </Row>
                                <br />
                                <br />
                                <Row className='d-flex justify-content-center'>
                                    <span className='' >{t("Didn't recieve the OTP?")} <span style={{ "color": "red", "display": "contents", "cursor": "pointer" }} onClick={handleResendOTP}> {t("RESEND OTP")}</span></span>
                                </Row>

                            </div>
                        </form>
                        }
                    </Card.Body>
                </Card>
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.isLoading,
        sendOTP: state.auth.getSendOTP,
        reSendOTP: state.auth.getReSendOTP,
        validateOtp: state.auth.validateOTP,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUserOTP: (data) => dispatch(getSendOTP(data)),
        getUserResendOTP: (data) => dispatch(getReSendOTP(data)),
        validateUserOTP: (data2) => dispatch(validateOTP(data2))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgetOtp);
