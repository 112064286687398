import React from 'react';
import jsPDF from "jspdf";
import "jspdf-autotable";
import "../pdf/pdf.css";
import { useTranslation } from 'react-i18next';

// Function to format date as DD-MM-YYYY
const formatDate = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero indexed
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
};

const ManualReconciliationPdf = (props) => {
    const { t } = useTranslation();

    const generatePDF = () => {
        const { organisation } = JSON.parse(localStorage.getItem('user')) || { organisation: "Unknown" };
        // console.log("props", props);

        const unit = "pt";
        const size = "A4";
        const orientation = "landscape";
        const marginLeft = 40;
        const marginTop = 40;
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(12);

        const org_name = props.org === "All" ? "All" : props.org;
        const title = "Organisation: " + organisation;

        const headers = [["Transfered to", "Amount", "GST", "CGST", "IGST", "SGST", "Payment Mode", "UTR", "Transfer Date", "Client Name", "Client Account No."]];
        const data = props.data?.map(elt => [
            elt.transfered_to,
            elt.amount,
            elt.gst,
            elt.cgst,
            elt.igst,
            elt.sgst,
            elt.payment_mode,
            elt.utr,
            elt.transfer_date,
            elt.client_name,
            elt.client_account_number
        ]) || [];

        // console.log("PDF data:", data);

        if (data.length === 0) {
            console.error("No data available to generate the PDF.");
            return;
        }

        let content = {
            startY: 60,
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, marginTop);
        doc.autoTable(content);

        try {
            const today = formatDate(new Date());
            const filename = `${organisation}_${today}.pdf`;
            doc.save(filename);
            console.log("PDF saved as:", filename);
        } catch (error) {
            console.error("Error saving the PDF:", error);
        }
    };

    const handleButtonClick = async () => {
        generatePDF();
    };

    return (
        <div>
            <button className='pdfbutton' onClick={handleButtonClick} disabled={props.data?.length === 0 ? true : false} type="button">
                {t("DOWNLOAD PDF")}
            </button>
        </div>
    );
};

export default ManualReconciliationPdf;
