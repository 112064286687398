import React, { useMemo, useState, useEffect } from "react";
import { Card, Row, Col, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PureBreadcrumbs from "../breadcrums";
import { fetchStripeBalance } from "../../../store/actions/stripeAction";
import { connect } from "react-redux";
import { fetchRevenueSharingReport } from "../../../store/actions/reportActions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Table from "../reacttable/table";

function FinanceReport(props) {
    const { t } = useTranslation();
    useEffect(() => {
        props.getStripebalance()
        props.revenueSharingReport(startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10))
    }, []);

    const accountBalance =
        props.fetchStripeBalance &&
        props.fetchStripeBalance.data &&
        props.fetchStripeBalance.data[0] &&
        props.fetchStripeBalance.data[0].available &&
        props.fetchStripeBalance.data[0].available[0] &&
        props.fetchStripeBalance.data[0].available[0].amount;

    const accountCurrency =
        props.fetchStripeBalance &&
        props.fetchStripeBalance.data &&
        props.fetchStripeBalance.data[0] &&
        props.fetchStripeBalance.data[0].available &&
        props.fetchStripeBalance.data[0].available[0] &&
        props.fetchStripeBalance.data[0].available[0].currency;


    const [startDate, setStartDate] = useState(
        new Date(Date.now() - 3600 * 1000 * 720)
    );
    const [endDate, setEndDate] = useState(
        new Date(Date.now() + 3600 * 1000 * 24)
    );

    // console.log(startDate)
    localStorage.setItem("startDate", startDate)
    localStorage.setItem("endDate", endDate)

    const loadNewData = () => {
        props.revenueSharingReport(
            startDate.toISOString().substr(0, 10),
            endDate.toISOString().substr(0, 10),
        );
    };
    const data = props.revenue_sharing_report && props.revenue_sharing_report.data

    const sortedData = data && data.sort((a, b) => new Date(b.start_date) - new Date(a.start_date));
    // console.log("sorted data", sortedData)

    const columns = useMemo(
        () => [

            {
                Header: "Location",
                id: "location",
                accessor: (d) => {
                    return (d.ID.location);
                },
            },
            {
                Header: "City",
                accessor: (d) => {
                    return (d.ID.city);
                },
            },
            {
                Header: "Operator",
                accessor: (d) => {
                    return (d.ID.operator);
                },
            },
            {
                Header: "Per Unit kWh",
                accessor: (d) => {
                    return (d.ID.per_unit_kwh);
                },
            },
            {
                Header: "Unit Consumed",
                accessor: (d) => {
                    return (d.kwh)
                }
            },
            {
                Header: "Client Revenue",
                accessor: (d) => {
                    return (d.ID.client_revenue_share);
                },
            },
            {
                Header: "Self Revenue",
                accessor: (d) => {
                    return (d.ID.self_revenue_share);
                },
            },
            {
                Header: "Total Amount",
                accessor: (d) => {
                    return (d.total_amount);
                },
            },
            {
                Header: "Actions",
                accessor: "id",
                Cell: (rowinfo) => {
                    const { value, row } = rowinfo;
                    const { original } = row;
                    const { ID } = original
                    // console.log("ID",ID.stripe_account)

                    return (
                        <div>
                            <span>
                                <a
                                    href={`/dashboard/release/${ID.location}/${ID.operator}`}
                                    rel="tooltip"
                                    title="Commands"
                                >
                                    <button disabled={ID.stripe_account === '' ? true : false} className="btn commandButtons" type="button">
                                        {t("PAYOUT")}
                                    </button>
                                </a>
                            </span>
                        </div>
                    );
                },
            }
        ],
        []
    );



    return (
        <Container fluid>
            <PureBreadcrumbs />
            <br />
            <Row>
                <Col xs={12} md={4}>
                    <Card className="customercard">
                        <div style={{ textAlign: "left" }}>
                            <Card.Text style={{ fontSize: "14px" }}> {process.env.REACT_APP_CLIENT_NAME}{t(" Balance")}</Card.Text>
                            <Card.Title>
                                <b>{accountBalance && accountBalance != undefined ? accountBalance : 0}</b>
                            </Card.Title>
                            <span style={{ float: "right", marginTop: "-55px", marginRight: "-8px" }}>
                                <div >
                                    <img src={process.env.PUBLIC_URL + '/images/finance/total amount.svg'} alt="evse" />
                                </div>

                            </span>
                        </div>
                    </Card><br />
                </Col>
                <Col xs={12} md={4}>
                    <Card className="customercard">
                        <div style={{ textAlign: "left" }}>
                            <Card.Text style={{ fontSize: "14px" }}>{t("CURRENCY")}</Card.Text>
                            <Card.Title>
                                <b>{accountCurrency && accountCurrency != undefined ? accountCurrency : 0}</b>
                            </Card.Title>
                            <span style={{ float: "right", marginTop: "-55px", marginRight: "-8px" }}>
                                <div style={{ width: "50%" }} >
                                    <img src={process.env.PUBLIC_URL + '/images/admin/approveduser.svg'} alt="evse" />
                                </div>

                            </span>
                        </div>
                    </Card><br />
                </Col>
            </Row>

            <Row className="mt-4">
                <Col>
                    <Card className="customercard">
                        <Card.Header>
                            <Row>
                                <Col md={4} sm={12}>
                                    {t("Client Payouts")}
                                </Col>
                                <Col md={8} sm={12}>
                                    <div className="row justify-content-end">
                                        <div className="row justify-content-center">
                                            <div className="col-auto claender">
                                                <DatePicker
                                                    className="gray-border"
                                                    selected={startDate}
                                                    onChange={(date) => setStartDate(date)}
                                                    selectsStart
                                                    startDate={startDate}
                                                    dateFormat="dd/MM/yyyy"
                                                    maxDate={new Date()}
                                                />
                                            </div>
                                            <span style={{ paddingTop: "7px" }}> {t("To")} </span>
                                            <div className="col-auto">
                                                <DatePicker
                                                    className="gray-border"
                                                    selected={endDate}
                                                    onChange={(date) => setEndDate(date)}
                                                    selectsEnd
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    minDate={startDate}
                                                    showDisabledMonthNavigation
                                                    dateFormat="dd/MM/yyyy"
                                                    maxDate={new Date()}
                                                />
                                            </div>
                                        </div>
                                        <div className="csvbutton ml-3 ">
                                            <button className="btn btn-sm" onClick={loadNewData}>
                                                <span
                                                    style={{
                                                        fontSize: "16px",
                                                        color: "#676767",
                                                        fontWeight: "600",
                                                    }}
                                                >
                                                    GO
                                                </span>
                                            </button>
                                        </div>
                                    </div>

                                </Col>
                            </Row>
                        </Card.Header>
                        <div className="evse">
                            {props.loading ? <h3 style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "250px",
                            }}>Loading data...</h3> :
                                props.revenue_sharing_report && props.revenue_sharing_report.data != null ? (
                                    <div>
                                        <Table data={sortedData} columns={columns}></Table>
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <h3>{t("No Data Found")}</h3>
                                    </div>
                                )}
                        </div>
                    </Card>
                </Col>
            </Row>

            <br />
        </Container>
    )

}


const mapStateToProps = (state) => {
    return {
        fetchStripeBalance: state.stripe.getStripeBalance,
        revenue_sharing_report: state.report.revenueSharingList,

    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getStripebalance: () => dispatch(fetchStripeBalance()),
        revenueSharingReport: (start, end) => dispatch(fetchRevenueSharingReport(start, end)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(FinanceReport);
