import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import PureBreadcrumbs from "../../breadcrums";
import { connect } from "react-redux";
import EmailActivityList from "./viewEmail/emailActivity";
import EmailGraph from "./viewEmail/emailGraph";
import NotificationGraph from "./viewNotification/notificationGraph";
import NotificationActivityList from "./viewNotification/notificationActivity";
import SMSGraph from "./viewSMS/smsGraph";
import SMSActivityList from "./viewSMS/smsActivity";
import {
  allEmailCampaignAction,
  allPushNotifyCampaignAction,
  allSMSCampaignAction,
  getCampaignPercentageChange,
} from "../../../../store/actions/campaignAction";

function ViewCampaign(props) {
  useEffect(() => {
    props.getPushNotify();
    props.allEMail();
    props.allSMS();
    props.percentageChangeData();
  }, []);
  const Total_email_percentage =
    props.percent_change && props.percent_change.email_percentage_change;
  const Total_email_change_symbol =
    props.percent_change && props.percent_change.email_change_symbol;

  const Total_sms_percentage =
    props.percent_change && props.percent_change.sms_percentage_change;
  const Total_sms_symbol =
    props.percent_change && props.percent_change.sms_change_symbol;

  const Total_notification_percentage =
    props.percent_change && props.percent_change.notification_percentage_change;
  const Total_notification_symbol =
    props.percent_change && props.percent_change.notification_change_symbol;
  return (
    <Container fluid className="pb-4">
      <PureBreadcrumbs />
      <br />
      <Row>
        <Col xs={12} md={4}>
          <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>TOTAL EMAIL</Card.Text>
              <Card.Title>
                <b>
                  {props.emaillist.data != undefined
                    ? props.emaillist.data.length
                    : 0}
                </b>
              </Card.Title>
              <span style={{ fontSize: "12px" }}>
                {Total_email_change_symbol === "+" ? (
                  <img
                    src={process.env.PUBLIC_URL + "/images/admin/profit.svg"}
                    className="profitloss"
                    alt=""
                  />
                ) : (
                  <img
                    src={process.env.PUBLIC_URL + "/images/admin/loss.svg"}
                    className="profitloss"
                    alt=""
                  />
                )}
                &nbsp;&nbsp;{Total_email_percentage}% &nbsp;change in last 30
                days
              </span>
              <span
                style={{
                  float: "right",
                  marginTop: "-50px",
                  marginRight: "-5px",
                }}
              >
                <div style={{ width: "20%" }}>
                  <img
                    src={process.env.PUBLIC_URL + "/images/campaign/mail.svg"}
                    alt="customer"
                    style={{ width: "80px" }}
                  />
                </div>
              </span>
            </div>
          </Card>
          <br />
        </Col>
        <Col xs={12} md={4}>
          <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>TOTAL SMS</Card.Text>
              <Card.Title>
                <b>
                  {props.smslist.data != undefined
                    ? props.smslist.data.length
                    : 0}
                </b>
              </Card.Title>
              <span style={{ fontSize: "12px" }}>
                {Total_sms_symbol === "+" ? (
                  <img
                    src={process.env.PUBLIC_URL + "/images/admin/profit.svg"}
                    className="profitloss"
                    alt=""
                  />
                ) : (
                  <img
                    src={process.env.PUBLIC_URL + "/images/admin/loss.svg"}
                    className="profitloss"
                    alt=""
                  />
                )}
                &nbsp;&nbsp;{Total_sms_percentage}% &nbsp;change in last 30 days
              </span>
              <span
                style={{
                  float: "right",
                  marginTop: "-50px",
                  marginRight: "-5px",
                }}
              >
                <div style={{ width: "50%" }}>
                  <img
                    src={process.env.PUBLIC_URL + "/images/campaign/sms.svg"}
                    alt="customer"
                    style={{ width: "80px" }}
                  />
                </div>
              </span>
            </div>
          </Card>
          <br />
        </Col>
        <Col xs={12} md={4}>
          <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>
                TOTAL NOTIFICATION
              </Card.Text>
              <Card.Title>
                <b>
                  {props.pushlist.data != undefined
                    ? props.pushlist.data.length
                    : 0}
                </b>
              </Card.Title>
              <span style={{ fontSize: "12px" }}>
                {Total_notification_symbol === "+" ? (
                  <img
                    src={process.env.PUBLIC_URL + "/images/admin/profit.svg"}
                    className="profitloss"
                    alt=""
                  />
                ) : (
                  <img
                    src={process.env.PUBLIC_URL + "/images/admin/loss.svg"}
                    className="profitloss"
                    alt=""
                  />
                )}
                &nbsp;&nbsp;{Total_notification_percentage}% &nbsp;change in
                last 30 days
              </span>
              <span
                style={{
                  float: "right",
                  marginTop: "-50px",
                  marginRight: "-5px",
                }}
              >
                <div style={{ width: "50%" }}>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/campaign/notification.svg"
                    }
                    alt="customer"
                    style={{ width: "80px" }}
                  />
                </div>
              </span>
            </div>
          </Card>
          <br />
        </Col>
      </Row>

      <Row>
        <Col lg={4}>
          <Card className="customercard mb-1">
            <div className="table-header"> Monthly Email Activity</div>
            <br />
            <EmailGraph />
          </Card>
        </Col>
        <Col lg={4}>
          <Card className="customercard">
            <div className="table-header"> Monthly SMS Activity</div>
            <br />
            <SMSGraph />
          </Card>
        </Col>
        <Col lg={4}>
          <Card className="customercard">
            <div className="table-header"> Monthly Notification Activity</div>
            <br />
            <NotificationGraph />
          </Card>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col>
          <Card className="customercard">
            <div className="table-header"> Email Activity</div>
            <br />
            <EmailActivityList />
          </Card>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col>
          <Card className="customercard">
            <div className="table-header"> SMS Activity</div>
            <br />
            <SMSActivityList />
          </Card>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col>
          <Card className="customercard">
            <div className="table-header"> Notification Activity</div>
            <br />
            <NotificationActivityList />
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    emaillist: state.campaign.allemail,
    pushlist: state.campaign.allpushnotify,
    smslist: state.campaign.allsms,
    percent_change: state.campaign.percentageChange,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPushNotify: () => dispatch(allPushNotifyCampaignAction()),
    allEMail: () => dispatch(allEmailCampaignAction()),
    allSMS: () => dispatch(allSMSCampaignAction()),
    percentageChangeData: () => dispatch(getCampaignPercentageChange()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewCampaign);
