import { LOAD_URL, FETCH_LOAD_STATION_LIST, UPDATE_LOAD_DYNAMIC_MANAGEMENT_API, UPDATE_LOAD_CHARGING_PROFILE_API, FETCH_LOAD_CHARGER_STATUS, FETCH_LIVE_LOAD, FETCH_INDIVIDUAL_CHARGER_TOTAL_LIVE_LOAD, FETCH_LOAD_CHARGER_LIST, FETCH_LOAD_TYPE_API_LIST, FETCH_LOAD_CHARGING_PROFILE_API_LIST, FETCH_LOAD_CHARGING_PROFILE_KIND_API, FETCH_LOAD_CHARGING_PROFILE_LIMT_API, SAVE_LOAD_CAPACITY_API, FETCH_SINGLE_STATION_LOAD_API, CHARGER_LOAD_LIST_API, CURRENT_LOAD_GRAPH_API } from "../../components/config/config"
import { ApiHandler } from "./apiHandler";

export const fetchStationsForLoadAction = () => {
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  const functionName = 'fetchStationsForLoadAction'
  return async dispatch => {
    dispatch({ type: 'LOAD_MANAGEMENT', payload: '' })
    const data = await ApiHandler(LOAD_URL + FETCH_LOAD_STATION_LIST + "?role=" + role + "&operator=" + organisation, {
      functionName,
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'LOAD_MANAGEMENT_STATION_LIST', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchSingleStationForLoadAction = (stationid) => {
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  const functionName = 'fetchSingleStationForLoadAction'
  return async dispatch => {
    dispatch({ type: 'LOAD_MANAGEMENT', payload: '' })
    const data = await ApiHandler(LOAD_URL + FETCH_LOAD_CHARGER_STATUS + "?role=" + role + "&operator=" + organisation + "&stationid=" + stationid, {
      functionName,
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'LOAD_MANAGEMENT_STATION_STATUS', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchChargerLiveLoadAction = (stationid) => {
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  const functionName = 'fetchChargerLiveLoadAction'
  return async dispatch => {
    dispatch({ type: 'LOAD_MANAGEMENT', payload: '' })
    const data = await ApiHandler(LOAD_URL + FETCH_LIVE_LOAD + "?role=" + role + "&operator=" + organisation + "&stationid=" + stationid, {
      functionName,
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'LOAD_MANAGEMENT_STATION_LIVE_LOAD', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchSingleChargerTotalLoadForLoadAction = (stationid) => {
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  const functionName = 'fetchSingleChargerTotalLoadForLoadAction'
  return async dispatch => {
    dispatch({ type: 'LOAD_MANAGEMENT', payload: '' })
    const data = await ApiHandler(LOAD_URL + FETCH_INDIVIDUAL_CHARGER_TOTAL_LIVE_LOAD + "?role=" + role + "&operator=" + organisation + "&stationid=" + stationid, {
      functionName,
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'LOAD_MANAGEMENT_STATION_INDIVIDUAL_LIVE_LOAD', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchChargerListForLoadAction = (stationid) => {
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  const functionName = 'fetchChargerListForLoadAction'
  return async dispatch => {
    dispatch({ type: 'LOAD_MANAGEMENT', payload: '' })
    const data = await ApiHandler(LOAD_URL + FETCH_LOAD_CHARGER_LIST + "?role=" + role + "&operator=" + organisation + "&stationid=" + stationid, {
      functionName,
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'LOAD_MANAGEMENT_STATION_CHARGER_LIST', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchStationLoadTypeLisForAction = () => {
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  const functionName = 'fetchStationLoadTypeLisForAction'
  return async dispatch => {
    dispatch({ type: 'LOAD_MANAGEMENT', payload: '' })
    const data = await ApiHandler(LOAD_URL + FETCH_LOAD_TYPE_API_LIST + "?role=" + role + "&operator=" + organisation, {
      functionName,
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'LOAD_MANAGEMENT_STATION_LOAD_TYPE_LIST', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchChargerChargingProfileLisLoadForAction = () => {
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  const functionName = 'fetchChargerChargingProfileLisLoadForAction'
  return async dispatch => {
    dispatch({ type: 'LOAD_MANAGEMENT', payload: '' })
    const data = await ApiHandler(LOAD_URL + FETCH_LOAD_CHARGING_PROFILE_API_LIST + "?role=" + role + "&operator=" + organisation, {
      functionName,
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'LOAD_MANAGEMENT_CHARGING_PROFILE_LIST', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchChargerChargingProfileKindLoadForAction = () => {
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  const functionName = 'fetchChargerChargingProfileKindLoadForAction'
  return async dispatch => {
    dispatch({ type: 'LOAD_MANAGEMENT', payload: '' })
    const data = await ApiHandler(LOAD_URL + FETCH_LOAD_CHARGING_PROFILE_KIND_API + "?role=" + role + "&operator=" + organisation, {
      functionName,
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'LOAD_MANAGEMENT_CHARGING_PROFILE_KIND_LIST', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchChargerChargingProfileLimitLoadForAction = () => {
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  const functionName = 'fetchChargerChargingProfileLimitLoadForAction'
  return async dispatch => {
    dispatch({ type: 'LOAD_MANAGEMENT', payload: '' })
    const data = await ApiHandler(LOAD_URL + FETCH_LOAD_CHARGING_PROFILE_LIMT_API + "?role=" + role + "&operator=" + organisation, {
      functionName,
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'LOAD_MANAGEMENT_CHARGING_PROFILE_LIMT_LIST', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const updateDynamicLoadAction = (stationid, dynamicload) => {
  const { token, organisation, role } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  const functionName = 'updateDynamicLoadAction'
  return async dispatch => {
    dispatch({ type: 'LOAD_MANAGEMENT', payload: '' })
    const data = await ApiHandler(LOAD_URL + UPDATE_LOAD_DYNAMIC_MANAGEMENT_API, {
      functionName,
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "stationid": stationid,
        'dynamicload': dynamicload,
      })
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'UPDATE_DYNAMIC_STATION_LOAD', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const updateChargingProfileLoadAction = (data) => {
  // console.log(data);
  const { chargingrateunit, charging_profile_purpose, charging_profile_kind, start_period, limit, evseid, portno } = data
  const { token, organisation, role } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  const functionName = 'updateChargingProfileLoadAction'
  return async dispatch => {
    dispatch({ type: 'LOAD_MANAGEMENT', payload: '' })
    const data = await ApiHandler(LOAD_URL + UPDATE_LOAD_CHARGING_PROFILE_API, {
      functionName,
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "evse_id": evseid,
        'portno': Number(portno),
        "stack_level": 0,
        "charging_rate_unit": chargingrateunit,
        "charging_profile_purpose": charging_profile_purpose,
        "charging_profile_kind": charging_profile_kind,
        "start_period": start_period,
        "limit": limit
      })
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'LOAD_MANAGEMENT_CHARGING_PROFILE_UPDATE_DATA', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

// NEW LOADMANAGEMENT API's

export const saveLoadCapacityApi = (data) => {
  // console.log(data);
  const { loadConsumption, loadType, station_name } = data
  const { token, organisation, role } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  const functionName = 'saveLoadCapacityApi'
  return async dispatch => {
    dispatch({ type: 'LOAD_MANAGEMENT', payload: '' })
    const data = await ApiHandler(LOAD_URL + SAVE_LOAD_CAPACITY_API, {
      functionName,
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "station_name": station_name,
        "load_type": loadType,
        "define_load": loadConsumption

      })
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'SAVE_DYNAMIC_STATION_LOAD_API', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchSingleDynamicStationApi = (station_name) => {
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  const functionName = 'fetchSingleDynamicStationApi'
  return async dispatch => {
    dispatch({ type: 'LOAD_MANAGEMENT', payload: '' })
    const data = await ApiHandler(LOAD_URL + FETCH_SINGLE_STATION_LOAD_API + "?station_name=" + station_name, {
      functionName,
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'FETCH_SINGLE_STATION_LOAD_API', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchChargerLoadListApi = (station_name) => {
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  const functionName = 'fetchChargerLoadListApi'
  return async dispatch => {
    dispatch({ type: 'LOAD_MANAGEMENT', payload: '' })
    const data = await ApiHandler(LOAD_URL + CHARGER_LOAD_LIST_API + "?station_name=" + station_name, {
      functionName,
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'FETCH_ALL_LOAD_CHARGER_LIST_API', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchCurrentLoadGraphApi = (station_name) => {
  // console.log(station_name)
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  const functionName = 'fetchCurrentLoadGraphApi'
  return async dispatch => {
    dispatch({ type: 'LOAD_MANAGEMENT', payload: '' })
    const data = await ApiHandler(LOAD_URL + CURRENT_LOAD_GRAPH_API + "?station_name=" + station_name, {
      functionName,
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'FETCH_CURRENT_LOAD_GRAPH_API', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}